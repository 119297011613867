import { Checkbox, FormControl, FormControlLabel, FormGroup } from '@mui/material';
import React from 'react';

type CheckboxProps = {
  title: string;
  checked?: boolean;
  disabled?: boolean;
  onChange: (event: React.ChangeEvent<{ name?: string; value: unknown }>, value: any) => void | Promise<void>;
  color?: 'primary' | 'secondary' | 'default';
};

export const StandardCheckbox = (props: CheckboxProps) => {
  return (
    <FormControlLabel
      control={<Checkbox checked={props.checked} onChange={props.onChange} disabled={props.disabled} />}
      label={props.title}
    />
  );
};

type Props = {
  items: any[];
  title: string;
  selectedItems: any[];
  emptyTitle?: string;
  allowEmpty?: boolean;
  getItemKey: (item: any) => string | number;
  getItemTitle: (item: any) => string;
  onChange: (selectedItems: any[], event: React.ChangeEvent<{ name?: string; value: unknown }>) => void | Promise<void>;
  itemSorter?: (a: any, b: any) => number;
  itemIsDisabled?: (item: any) => boolean;
  getItemColor?: (item: any) => 'primary' | 'secondary' | 'default';
  disabled?: boolean;
};

export const CheckboxGroup = (props: Props) => {
  return (
    <FormControl required component="fieldset">
      <FormGroup>
        {(props.itemSorter ? props.items.sort(props.itemSorter) : props.items)?.map((item, k) => (
          <StandardCheckbox
            key={k}
            color={props.getItemColor ? props.getItemColor(item) : 'default'}
            checked={!!props.selectedItems.filter((i) => props.getItemKey(i) === props.getItemKey(item)).length}
            disabled={props.disabled || (props.itemIsDisabled && props.itemIsDisabled(item))}
            onChange={(event) => {
              if (props.selectedItems.filter((i) => props.getItemKey(i) === props.getItemKey(item)).length) {
                const newSelection = props.selectedItems.filter((i) => props.getItemKey(i) === props.getItemKey(item));
                props.onChange(newSelection, event);
              } else {
                const newSelection = [...props.selectedItems, item];
                props.onChange(newSelection, event);
              }
            }}
            title={props.getItemTitle(item)}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
};
