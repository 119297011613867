import { ILocationDto, IModuleCategoryDto, IModuleDto, IModuleExecutionDto, IModuleStructureDto } from 'app/clients/services';
import { REQUEST, FAILURE, SUCCESS } from '../../../shared/util/action-type.util';
import ACTION_TYPES from './types';

const initialState = {
  loading: false,
  isLoaded: false,
  errorMessage: null as string,
  moduleCategories: null as IModuleCategoryDto[],
  modules: null as IModuleDto[],
  executions: {} as { [semesterId: number]: IModuleExecutionDto[] },
  selectedCategory: null as IModuleCategoryDto,
  selectedModule: null as IModuleDto,
  selectedExecution: null as IModuleExecutionDto,
  locations: [] as ILocationDto[],
};

export type ModuleSelectionState = Readonly<typeof initialState>;

// Reducer

export default (state: ModuleSelectionState = initialState, action): ModuleSelectionState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.GET_MODULE_FILTER):
      return {
        ...state,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.GET_MODULE_FILTER):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.GET_MODULE_FILTER): {
      const moduleFilter = action.payload as IModuleStructureDto;
      return {
        ...state,
        loading: false,
        isLoaded: true,
        moduleCategories: moduleFilter.categories,
        modules: moduleFilter.modules,
        selectedCategory: moduleFilter.categories.find(c => c.id === state.selectedCategory?.id) ?? moduleFilter.categories[0],
        selectedModule: moduleFilter.modules.find(c => c.id === state.selectedModule?.id),
        locations: moduleFilter.locations,
      };
    }
    case REQUEST(ACTION_TYPES.FETCH_MODULE_EXECUTIONS):
      return {
        ...state,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_MODULE_EXECUTIONS):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_MODULE_EXECUTIONS): {
      const executions = { ...state.executions };
      const result = action.payload as { academicYearId: number; executions: IModuleExecutionDto[] };
      executions[result.academicYearId] = result.executions;

      return {
        ...state,
        loading: false,
        executions,
      };
    }
    case ACTION_TYPES.SELECT_MODULE_CATEGORY: {
      return {
        ...state,
        selectedCategory: action.payload,
      };
    }
    case ACTION_TYPES.SELECT_MODULE: {
      return {
        ...state,
        selectedModule: action.payload,
      };
    }
    case ACTION_TYPES.SELECT_MODULE_EXECUTION: {
      return {
        ...state,
        selectedExecution: action.payload,
      };
    }
    case ACTION_TYPES.RESET_EXECUTIONS: {
      return {
        ...state,
        executions: [],
      };
    }
    default:
      return state;
  }
};
