import { ICommentDto, IModuleRegistrationsDto, IPhaseCapacityDto } from 'app/clients/services';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/util/action-type.util';
import { addCommentToRegistrationInfo, removeCommentFromRegistrationInfo } from 'app/shared/util/reducer-helpers';
import ACTION_TYPES from './types';

const initialState = {
  loading: false,
  errorMessage: null,
  moduleRegistrationInfo: null as IModuleRegistrationsDto,
  selectedRequested: [] as number[],
  selectedConfirmed: [] as number[],
  selectedRejected: [] as number[],
  selectedWithdrawn: [] as number[],
  selectedAbsentex: [] as number[],
  selectedOthers: [] as number[],
  capacities: [] as IPhaseCapacityDto[],
  mainContentLoading: true,
};

export type ModuleRegistrationState = Readonly<typeof initialState>;

// Reducer
export default (state: ModuleRegistrationState = initialState, action): ModuleRegistrationState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_CAPACITIES):
      return {
        ...state,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_CAPACITIES):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_CAPACITIES): {
      return {
        ...state,
        loading: false,
        capacities: action.payload,
      };
    }
    case REQUEST(ACTION_TYPES.FETCH_MODULE_REGISTRATIONS):
      return {
        ...state,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_MODULE_REGISTRATIONS):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_MODULE_REGISTRATIONS): {
      const payload = action.payload as IModuleRegistrationsDto;
      return {
        ...state,
        loading: false,
        moduleRegistrationInfo: payload,
        selectedRequested: payload.requested?.filter((r) => r.isSelected).map((r) => r.id) ?? [],
        selectedConfirmed: [],
        selectedRejected: [],
        selectedWithdrawn: [],
        selectedOthers: [],
        selectedAbsentex: [],
      };
    }
    case REQUEST(ACTION_TYPES.CHANGE_STATES):
      return {
        ...state,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.CHANGE_STATES):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.CHANGE_STATES): {
      return {
        ...state,
        loading: false,
      };
    }
    case REQUEST(ACTION_TYPES.LOAD_DATA):
      return {
        ...state,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.LOAD_DATA):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.LOAD_DATA): {
      return {
        ...state,
        loading: false,
      };
    }
    case ACTION_TYPES.SET_REQUESTED_SELECTED: {
      return {
        ...state,
        selectedRequested: action.payload,
      };
    }
    case ACTION_TYPES.SET_CONFIRMED_SELECTED: {
      return {
        ...state,
        selectedConfirmed: action.payload,
      };
    }
    case ACTION_TYPES.SET_REJECTED_SELECTED: {
      return {
        ...state,
        selectedRejected: action.payload,
      };
    }
    case ACTION_TYPES.SET_WITHDRAWN_SELECTED: {
      return {
        ...state,
        selectedWithdrawn: action.payload,
      };
    }
    case ACTION_TYPES.SET_ABSENTEX_SELECTED: {
      return {
        ...state,
        selectedWithdrawn: action.payload,
      };
    }
    case ACTION_TYPES.SET_OTHERS_SELECTED: {
      return {
        ...state,
        selectedOthers: action.payload,
      };
    }
    case ACTION_TYPES.SET_MAIN_CONTENT_LOADING: {
      return {
        ...state,
        mainContentLoading: action.payload,
      };
    }
    case ACTION_TYPES.ADD_REGISTRATION_COMMENT: {
      const newComment = action.payload as ICommentDto;

      if (!newComment.registrationId) {
        return state;
      }

      return {
        ...state,
        moduleRegistrationInfo: {
          ...state.moduleRegistrationInfo,
          confirmed: addCommentToRegistrationInfo(state.moduleRegistrationInfo.confirmed, newComment),
          requested: addCommentToRegistrationInfo(state.moduleRegistrationInfo.requested, newComment),
          others: addCommentToRegistrationInfo(state.moduleRegistrationInfo.others, newComment),
        },
      };
    }
    case ACTION_TYPES.REMOVE_REGISTRATION_COMMENT: {
      const comment = action.payload as ICommentDto;

      if (!comment.registrationId) {
        return state;
      }

      return {
        ...state,
        moduleRegistrationInfo: {
          ...state.moduleRegistrationInfo,
          confirmed: removeCommentFromRegistrationInfo(state.moduleRegistrationInfo.confirmed, comment),
          requested: removeCommentFromRegistrationInfo(state.moduleRegistrationInfo.requested, comment),
          others: removeCommentFromRegistrationInfo(state.moduleRegistrationInfo.others, comment),
        },
      };
    }
    default:
      return state;
  }
};
