import DeleteIcon from '@mui/icons-material/DeleteForever';
import SaveIcon from '@mui/icons-material/Save';
import { Button, Grid } from '@mui/material';
import { IUasDto, ProfileDto } from 'app/clients/services';
import { ConfirmationDialog } from 'app/shared/layout/ui-elements/confirmation-dialog';
import { Field, Form, Formik } from 'formik';
import { CheckboxWithLabel as FormikCheckbox, TextField as FormikTextField } from 'formik-mui';
import React, { useState } from 'react';

type Props = {
  uas: IUasDto;
  allUas: IUasDto[];
  onSubmit: (uas: IUasDto) => Promise<void>;
  onDelete: (uas: IUasDto) => Promise<void>;
  onCancel: () => void;
  loading: boolean;
};

export const UasForm = (props: Props) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  return (
    <Formik
      enableReinitialize
      initialValues={props.uas}
      validate={(values) => {
        const errors: Partial<ProfileDto> = {};
        if (!values.code || values.code === 'untitled') {
          errors.code = 'Required';
        }
        if (props.allUas.filter((p) => p.code === values.code && p.id !== values.id).length > 0) {
          errors.code = 'Code already exists!';
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        props.onSubmit(values).then(() => setSubmitting(false));
      }}
    >
      {({ values, submitForm, isSubmitting }) => (
        <Form>
          <Grid container direction="row" justifyContent="flex-start" alignItems="flex-end" spacing={5}>
            <Grid item xs={12} md={3}>
              <Field component={FormikTextField} name="code" type="text" label="UAS Code" variant="standard" />
            </Grid>
            <Grid item xs={12} md={2}>
              <Field
                type="checkbox"
                component={FormikCheckbox}
                label="Is Active"
                name="isActive"
                Label={{ label: 'Is Active' }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isSubmitting || props.loading}
                onClick={submitForm}
                startIcon={<SaveIcon />}
              >
                Save
              </Button>
              {!props.uas.hasDependentData && (
                <>
                  {props.uas.id ? (
                    <Button
                      variant="contained"
                      color="inherit"
                      disabled={isSubmitting || props.loading}
                      onClick={() => setDialogOpen(true)}
                      startIcon={<DeleteIcon />}
                    >
                      Delete
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="inherit"
                      disabled={isSubmitting || props.loading}
                      onClick={() => props.onCancel()}
                      startIcon={<DeleteIcon />}
                    >
                      Cancel
                    </Button>
                  )}
                  <ConfirmationDialog
                    title="Delete Uas"
                    onConfirm={() => props.onDelete(values)}
                    open={dialogOpen}
                    onClose={() => setDialogOpen(false)}
                  >
                    Do you really want to delete the uas <strong>{props.uas.code}</strong>?
                  </ConfirmationDialog>
                </>
              )}
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
