import initActions from './actions';
import types from './types';
import reducer from './reducer';

import { IAdvisorClient } from 'app/clients/services';
import { resolver } from 'app/clients/service-registry';

export type { AdvisorSelectionState } from './reducer';
export default {
  initActions: (dispatch: any) => initActions(dispatch, resolver.resolve<IAdvisorClient>('IAdvisorClient')),
  types,
  reducer,
};
