import React, { useEffect } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { IRootState } from 'app/config/root.reducer';
import { MainContent, SideBar, SplitLayout } from 'app/shared/layout/ui-elements/component-layout';
import { CloseReason } from 'notistack';

import { Paper, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { IAcademicYearDto, IPhaseDto, SemesterSummaryFilterDto, SummaryFilterDto } from 'app/clients/services';
import { DefaultButton } from 'app/shared/layout/ui-elements/buttons';
import { StandardCheckbox } from 'app/shared/layout/ui-elements/checkbox';
import { DatePicker } from 'app/shared/layout/ui-elements/date-picker';
import { SelectBox } from 'app/shared/layout/ui-elements/select-box';
import { addExceptionNotification } from '../notification';
import semesterSelectionModule from '../selections/semester-selection';
import summaryModule from './summary/index';

export type Props = PropsFromRedux;

const Summary = (props: Props) => {
  const handleAcademicYearChange = (year: IAcademicYearDto) => {
    props.selectAcademicYear(year);
  };

  useEffect(() => {
    props.setSummaryFilter(
      new SummaryFilterDto({
        semesters: props.semesterFilter.semesters
          .filter((s) => s.academicYearId === props.academicYear.id)
          .map((s) => new SemesterSummaryFilterDto({ id: s.id })),
      }),
    );
  }, [props.academicYear]);

  return (
    <SplitLayout>
      <SideBar>
        <SelectBox
          items={props.semesterFilter.academicYears}
          selectedItem={props.academicYear}
          onChange={handleAcademicYearChange}
          title="Academic Year"
          getItemKey={(academicYear: IAcademicYearDto) => academicYear.id}
          getItemTitle={(academicYear: IAcademicYearDto) => academicYear.name}
        />
      </SideBar>
      <MainContent loading={props.loading}>
        <Typography variant="h1">Summary Sheet</Typography>
        <Grid container component={Paper} sx={{ padding: 2, margin: 0.5 }} spacing={1}>
          {props.selectableSemesters.map((s) => (
            <Grid item md={4} key={s.id}>
              <StandardCheckbox
                checked={props.filter.semesters?.some((fs) => fs.id === s.id) ?? false}
                onChange={(_, value) => {
                  const newFilter = {
                    ...props.filter,
                    semesters: value
                      ? [...(props.filter.semesters ?? []), new SemesterSummaryFilterDto({ id: s.id })]
                      : (props.filter.semesters ?? []).filter((fs) => s.id !== fs.id),
                  };
                  props.setSummaryFilter(newFilter);
                }}
                title={s.name}
              />
              <SelectBox
                items={s.phases}
                selectedItem={
                  s.phases.find((p) => p.id === props.filter.semesters?.find((fs) => fs.id === s.id)?.phaseId) ?? null
                }
                disabled={!props.filter.semesters?.some((fs) => fs.id === s.id)}
                onChange={(value) => {
                  const semesters = [...(props.filter.semesters ?? [])];
                  const semesterFilter = semesters.find((fs) => fs.id === s.id);
                  semesterFilter.phaseId = value?.id;
                  const newFilter = {
                    ...props.filter,
                    semesters,
                  };
                  props.setSummaryFilter(newFilter);
                }}
                title="Until Phase"
                getItemKey={(phase: IPhaseDto) => phase.id}
                getItemTitle={(phase: IPhaseDto) => phase.name}
                allowEmpty
              />
            </Grid>
          ))}
          <Grid item md={4}></Grid>
          <Grid item md={12}>
            <DatePicker
              clearable
              value={props.filter.untilDate}
              label={'Until Date'}
              onChange={(untilDate) => {
                const newFilter = {
                  ...props.filter,
                  untilDate,
                };
                props.setSummaryFilter(newFilter);
              }}
            ></DatePicker>
          </Grid>
          <Grid item md={12}>
            <DefaultButton
              primary
              sx={{ marginTop: 2 }}
              disabled={(props.filter.semesters?.length ?? 0) < 1}
              onClick={() => props.loadReportAsync(props.filter)}
            >
              Download
            </DefaultButton>
          </Grid>
        </Grid>
      </MainContent>
    </SplitLayout>
  );
};

function mapStateToProps({ semesterSelection, summary }: IRootState) {
  return {
    semesterFilter: semesterSelection.semesterFilter,
    academicYear: semesterSelection.selectedAcademicYear,
    selectableSemesters: semesterSelection.semesterFilter.semesters.filter(
      (s) => s.academicYearId === semesterSelection.selectedAcademicYear?.id,
    ),
    filter: summary.filter,
    loading: semesterSelection.loading || summary.loading,
  };
}

const mapDispatchToProps = (dispatch) => {
  const summaryActions = summaryModule.initActions(dispatch);
  const semesterSelection = semesterSelectionModule.initActions();
  return {
    ...summaryActions,
    selectAcademicYear: (academicYear: IAcademicYearDto) =>
      dispatch(semesterSelection.selectAcademicYear(academicYear)),
    addExceptionNotification: (
      e: any,
      message?: string,
      onClose?: (event: React.SyntheticEvent<any, Event>, reason: CloseReason) => void,
    ) => dispatch(addExceptionNotification(e, message, onClose)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Summary);
