export default {
  SET_LOADING: 'uasManagement/SET_LOADING',
  SET_SELECTED_UAS: 'uasManagement/SET_SELECTED_UAS',
  SET_DOMAINS: 'uasManagement/SET_DOMAINS',
  SET_SELECTED_DOMAIN: 'uasManagement/SET_SELECTED_DOMAIN',
  SET_DOMAINS_FOR_UAS: 'uasManagement/SET_DOMAINS_FOR_UAS',
  SET_DOMAINS_LOADING: 'uasManagement/SET_DOMAINS_LOADING',
  SET_ADVISORS_WITHOUT_UAS: 'uasManagement/SET_WITHOUT_UAS',
  SET_SELECTED_ADVISOR: 'uasManagement/SET_SELECTED_ADVISOR',
};
