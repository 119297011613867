import initActions from './actions';
import reducer from './reducer';
import types from './types';

import { resolver } from 'app/clients/service-registry';
import { IImportClient } from 'app/clients/services';

import semesterSelectionModule from '../../selections/semester-selection';

export type { ImportRequestsState } from './reducer';
export default {
  initActions: () => initActions(resolver.resolve<IImportClient>('IImportClient'), semesterSelectionModule.initActions()),
  types,
  reducer,
};
