import { registry } from 'app/clients/service-registry';
import {
  AccountClient,
  AdvisorClient,
  ApplicationProfileClient,
  AuthClient,
  EmailClient,
  ExcelTemplateClient,
  ImportClient,
  LabelClient,
  ModuleManagementClient,
  ModuleSelectionClient,
  PriorityClient,
  ProfileClient,
  RegistrationClient,
  RegistrationEditClient,
  RegistrationExportClient,
  RegistrationFilterPresetClient,
  RoleSelectionClient,
  SemesterManagementClient,
  SemesterSelectionClient,
  StudentClient,
  SummarySheetClient,
  UasClient,
  UasDomainClient,
} from 'app/clients/services';
import axios, { AxiosInstance } from 'axios';

export function configureServices(): void {
  registry.addSingleton('Axios', () => axios);
  registry.addTransient(
    'IRegistrationClient',
    (resolver) => new RegistrationClient(undefined, resolver.resolve<AxiosInstance>('Axios')),
  );
  registry.addTransient(
    'IModuleSelectionClient',
    (resolver) => new ModuleSelectionClient(undefined, resolver.resolve<AxiosInstance>('Axios')),
  );
  registry.addTransient(
    'IModuleManagementClient',
    (resolver) => new ModuleManagementClient(undefined, resolver.resolve<AxiosInstance>('Axios')),
  );
  registry.addTransient(
    'IPriorityClient',
    (resolver) => new PriorityClient(undefined, resolver.resolve<AxiosInstance>('Axios')),
  );
  registry.addTransient(
    'IProfileClient',
    (resolver) => new ProfileClient(undefined, resolver.resolve<AxiosInstance>('Axios')),
  );
  registry.addTransient(
    'ILabelClient',
    (resolver) => new LabelClient(undefined, resolver.resolve<AxiosInstance>('Axios')),
  );
  registry.addTransient(
    'IApplicationProfileClient',
    (resolver) => new ApplicationProfileClient(undefined, resolver.resolve<AxiosInstance>('Axios')),
  );
  registry.addTransient(
    'IImportClient',
    (resolver) => new ImportClient(undefined, resolver.resolve<AxiosInstance>('Axios')),
  );
  registry.addTransient(
    'IAuthClient',
    (resolver) => new AuthClient(undefined, resolver.resolve<AxiosInstance>('Axios')),
  );
  registry.addTransient(
    'IAccountClient',
    (resolver) => new AccountClient(undefined, resolver.resolve<AxiosInstance>('Axios')),
  );
  registry.addTransient(
    'ISemesterManagementClient',
    (resolver) => new SemesterManagementClient(undefined, resolver.resolve<AxiosInstance>('Axios')),
  );
  registry.addTransient(
    'ISemesterSelectionClient',
    (resolver) => new SemesterSelectionClient(undefined, resolver.resolve<AxiosInstance>('Axios')),
  );
  registry.addTransient(
    'IRegistrationExportClient',
    (resolver) => new RegistrationExportClient(undefined, resolver.resolve<AxiosInstance>('Axios')),
  );
  registry.addTransient(
    'IRoleSelectionClient',
    (resolver) => new RoleSelectionClient(undefined, resolver.resolve<AxiosInstance>('Axios')),
  );
  registry.addTransient(
    'IStudentClient',
    (resolver) => new StudentClient(undefined, resolver.resolve<AxiosInstance>('Axios')),
  );
  registry.addTransient(
    'IAdvisorClient',
    (resolver) => new AdvisorClient(undefined, resolver.resolve<AxiosInstance>('Axios')),
  );
  registry.addTransient(
    'IRegistrationEditClient',
    (resolver) => new RegistrationEditClient(undefined, resolver.resolve<AxiosInstance>('Axios')),
  );
  registry.addTransient(
    'IEmailClient',
    (resolver) => new EmailClient(undefined, resolver.resolve<AxiosInstance>('Axios')),
  );
  registry.addTransient('IUasClient', (resolver) => new UasClient(undefined, resolver.resolve<AxiosInstance>('Axios')));
  registry.addTransient(
    'IUasDomainClient',
    (resolver) => new UasDomainClient(undefined, resolver.resolve<AxiosInstance>('Axios')),
  );
  registry.addTransient(
    'IExcelTemplateClient',
    (resolver) => new ExcelTemplateClient(undefined, resolver.resolve<AxiosInstance>('Axios')),
  );
  registry.addTransient(
    'IRegistrationFilterPresetClient',
    (resolver) => new RegistrationFilterPresetClient(undefined, resolver.resolve<AxiosInstance>('Axios')),
  );
  registry.addTransient(
    'ISummarySheetClient',
    (resolver) => new SummarySheetClient(undefined, resolver.resolve<AxiosInstance>('Axios')),
  );
}
