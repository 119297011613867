import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { Button, LinearProgress } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridOverlay,
  GridRowId,
  GridRowSelectionModel,
  GridSlotsComponent,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import React from 'react';
export interface IStandardDataGridProps {
  pageSize?: number;
  selectionModel: GridRowId[];
  onSelectionModelChange: (newSelection: GridRowSelectionModel) => void;
  loading: boolean;
  rows: any[];
  columns: GridColDef[];
  multiSelect?: boolean;
  excelExport?: () => Promise<void> | void;
  components?: GridSlotsComponent;
  disabled?: boolean;
}

const LoadingBar = () => {
  return (
    <GridOverlay>
      <div style={{ position: 'absolute', top: 0, width: '100%' }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
};

export const MultiselectDataGrid = (props: IStandardDataGridProps) => {
  const DefaultToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        {props.excelExport && (
          <Button color="primary" size={'small'} startIcon={<SaveAltIcon />} onClick={props.excelExport}>
            Excel Export
          </Button>
        )}
      </GridToolbarContainer>
    );
  };

  return (
    <DataGrid
      sx={{ backgroundColor: !props.loading ? '#fff' : 'none' }}
      rows={props.rows}
      columns={props.columns}
      initialState={{
        pagination: { paginationModel: { pageSize: props.pageSize ? props.pageSize : 20 } },
      }}
      loading={props.loading}
      slots={{
        toolbar: DefaultToolbar,
        loadingOverlay: LoadingBar,
        ...props.components,
      }}
      checkboxSelection={!props.disabled}
      onRowSelectionModelChange={(newSelection) => {
        if (props.onSelectionModelChange) {
          props.onSelectionModelChange(newSelection);
        }
      }}
      rowSelectionModel={props.selectionModel}
      density={'compact'}
    />
  );
};
