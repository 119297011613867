import React, { useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { Alert, AlertTitle, Box, CssBaseline, Typography, useTheme } from '@mui/material';
import { IRootState } from 'app/config/root.reducer';
import applicationModule from 'app/modules/application';
import authModule from 'app/modules/login/auth/index';
import moduleSelectionModule from 'app/modules/selections/module-selection';
import profileSelectionModule from 'app/modules/selections/profile-selection';
import semesterSelectionModule from 'app/modules/selections/semester-selection';
import ErrorBoundary from 'app/shared/error/error-boundary';
import Footer from 'app/shared/layout/footer/footer';
import Header from 'app/shared/layout/header/header';
import { LoadingBar } from 'react-redux-loading-bar';
import { ISemesterInfoDto, RoleType } from './clients/services';
import { DevRibbon } from './dev-ribbon';
import AppDrawer from './modules/drawer';
import { Notifier } from './notifier';
import AppRoutes from './routes';
import { hasAnyRole } from './shared/auth/roles';
import { Spinner } from './shared/layout/spinner';
import { TextContent } from './shared/layout/ui-elements/component-layout';

export const App = (props: PropsFromRedux) => {
  const theme = useTheme();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadInitialData = async () => {
      setLoading(true);
      await props.getSessionAsync();
      await props.getSemesterFilterAsync();
      await Promise.all([
        props.getModuleStructureAsync(),
        props.getApplicationProfileAsync(),
        props.getRolesAsync(),
        props.fetchLabelsAsync(),
        props.fetchProfilesAsync(),
      ]);

      if (!props.selectedSemester) {
        props.selectSemester(props.getDefaultSemester());
      }
      setLoading(false);
    };

    loadInitialData();
  }, [props.isLoggedIn]);

  const [open, setOpen] = React.useState(localStorage.getItem('drawerOpen') === 'true');

  const toggleDrawer = () => {
    localStorage.setItem('drawerOpen', open ? 'false' : 'true');
    setOpen(!open);
  };

  return (
    <Box className="app-root" sx={{ display: 'flex' }}>
      <CssBaseline />
      <Notifier />
      <DevRibbon profiles={props.profiles} />
      <Header hasAuthSession={props.isLoggedIn} drawerOpen={open} toggleDrawer={toggleDrawer} />
      <AppDrawer drawerOpen={open} toggleDrawer={toggleDrawer} />
      <Box
        component={'main'}
        sx={{
          backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
          paddingTop: '70px',
          flexGrow: 1,
          height: '100vh',
          backgroundSize: 'cover',
          overflow: 'auto',
        }}
      >
        <LoadingBar
          style={{
            height: 3,
            backgroundColor: '#009cd8',
          }}
        />
        {props.applicationError ? (
          <TextContent>
            <Alert severity="error">
              <AlertTitle>An Error has occurred while loading the application!</AlertTitle>
              <Typography>{props.applicationError} </Typography>
              <br />
              <br />
              <Typography>
                Please try to refresh by pressing F5. If this does not help, contact your administrator.
              </Typography>
            </Alert>
          </TextContent>
        ) : (
          <Spinner isLoading={loading || !props.isLoaded}>
            <ErrorBoundary>
              <AppRoutes />
            </ErrorBoundary>
          </Spinner>
        )}
        <Footer />
      </Box>
    </Box>
  );
};

const mapStateToProps = ({ auth, application, semesterSelection, moduleSelection }: IRootState) => ({
  isLoggedIn: auth.isLoggedIn,
  isAdmin: hasAnyRole(auth.account?.roles, [RoleType.ADMIN]),
  ribbonEnv: application.ribbonEnv,
  isInProduction: application.isProduction,
  isSwaggerEnabled: application.isSwaggerEnabled,
  profiles: application.profiles,
  applicationError: application.applicationError,
  selectedSemester: semesterSelection.selectedSemester,
  isLoaded:
    semesterSelection.isLoaded && auth.sessionHasBeenFetched && application.isLoaded && moduleSelection.isLoaded,
});

const mapDispatchToProps = (dispatch) => {
  const authActions = authModule.initActions();
  const semesterSelectionActions = semesterSelectionModule.initActions();
  const applicationActions = applicationModule.initActions();
  const profileSelectionActions = profileSelectionModule.initProfileActions();
  const labelSelectionActions = profileSelectionModule.initLabelActions();
  const moduleSelectionActions = moduleSelectionModule.initActions();
  return {
    getSessionAsync: async () => await dispatch(authActions.getSessionAsync()),
    getModuleStructureAsync: async () => await dispatch(moduleSelectionActions.loadStructureAsync()),
    getApplicationProfileAsync: async () => await dispatch(applicationActions.getProfileAsync()),
    getRolesAsync: async () => await dispatch(applicationActions.getRolesAsync()),
    getSemesterFilterAsync: async () => await dispatch(semesterSelectionActions.getSemesterFilterAsync()),
    selectSemester: async (semester: ISemesterInfoDto) =>
      await dispatch(semesterSelectionActions.selectSemester(semester)),
    getDefaultSemester: () => dispatch(semesterSelectionActions.getDefaultSemester()),
    addApplicationError: (errorMessage: string) => dispatch(applicationActions.addApplicationError(errorMessage)),
    fetchProfilesAsync: () => dispatch(profileSelectionActions.fetchProfilesAsync()),
    fetchLabelsAsync: () => dispatch(labelSelectionActions.fetchLabelsAsync()),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(App);
