import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import NoMeetingRoomIcon from '@mui/icons-material/NoMeetingRoom';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Button, Grid, LinearProgress, Typography } from '@mui/material';
import { IPhaseDto, ISemesterDto, ISemesterInfoDto, PhaseDto, SemesterInfoDto } from 'app/clients/services';
import { COLOR_SUCCESS } from 'app/config/constants';
import { ConfirmationDialog } from 'app/shared/layout/ui-elements/confirmation-dialog';
import { FormikDatePicker } from 'app/shared/layout/ui-elements/date-picker';
import { FastField, Field, FieldArray, Form, Formik } from 'formik';
import { TextField as FormikTextField } from 'formik-mui';
import { DateTime } from 'luxon';
import React, { useState } from 'react';

type SemesterFormProps = {
  semester: ISemesterInfoDto;
  phaseLoading: number;
  semesterLoading: number;
  loading: boolean;
  semesters: ISemesterDto[];
  onSubmit: (semester: ISemesterInfoDto) => Promise<void>;
  closePhaseAsync: (phase: IPhaseDto) => Promise<void> | void;
  reopenPhaseAsync: (phase: IPhaseDto) => Promise<void> | void;
  deletePhaseAsync: (phase: IPhaseDto) => Promise<void> | void;
  cancelAddingSemester: () => Promise<void> | void;
  deleteSemesterAsync: (semester: ISemesterDto) => Promise<void> | void;
};

export const SemesterForm = (props: SemesterFormProps) => {
  const [deleteSemesterOpen, setDialogDeleteSemesterOpen] = useState<boolean>(false);
  const [deletePhaseOpen, setDialogDeletePhaseOpen] = useState<number>(0);
  return (
    <Formik
      enableReinitialize
      initialValues={
        new SemesterInfoDto({
          ...props.semester,
          phases: props.semester.phases?.sort((p1, p2) => (p1.keyDate > p2.keyDate ? 1 : -1)) ?? [],
        })
      }
      validate={(values) => {
        const errors: {
          start?: string;
          end?: string;
          earliestRegistrationDate?: string;
          name?: string;
          nameLong?: string;
          phases?: { name?: string; reservePercentage?: string; keyDate?: string }[];
        } = {};
        if (!values.name) {
          errors.name = 'Required';
        } else if (props.semesters.filter((p) => p.name === values.name && p.id !== values.id).length > 0) {
          errors.name = 'Name already exists';
        }

        if (!values.nameLong) {
          errors.nameLong = 'Required';
        } else if (props.semesters.filter((p) => p.nameLong === values.nameLong && p.id !== values.id).length > 0) {
          errors.nameLong = 'Name already exists';
        }

        if (!values.start) {
          errors.start = 'Required';
        }

        if (!values.end) {
          errors.end = 'Required';
        }

        if (values.start && values.earliestRegistrationDate && values.earliestRegistrationDate > values.start) {
          errors.earliestRegistrationDate = 'Needs to be before semester start!';
        }

        if (values.start && values.end && values.start > values.end) {
          errors.end = 'Needs to be after start.';
        }

        const phaseErrors = [];
        values.phases.map((phase, key) => {
          if (!phase.name) {
            phaseErrors[key] = { name: 'Required' };
          } else if (values.phases.filter((p2) => p2.name === phase.name && p2 !== phase).length > 0) {
            phaseErrors[key] = { name: 'Name already exists' };
          }

          if (
            isNaN(phase.reservePercentage) ||
            Number(phase.reservePercentage) < 0 ||
            Number(phase.reservePercentage) > 100
          ) {
            phaseErrors[key] = { ...(phaseErrors[key] ?? {}), reservePercentage: 'Value has to be between 0 and 100' };
          }

          if (!phase.keyDate) {
            phaseErrors[key] = { ...(phaseErrors[key] ?? {}), keyDate: 'Required' };
          }

          if (phase.keyDate > values.end) {
            phaseErrors[key] = { ...(phaseErrors[key] ?? {}), keyDate: 'Key date needs to be before semester end.' };
          }
        });
        if (phaseErrors.length > 0) {
          errors.phases = phaseErrors;
        }

        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        props.onSubmit(values).then(() => setSubmitting(false));
      }}
    >
      {({ values, submitForm, isSubmitting }) =>
        isSubmitting || values.id === props.semesterLoading ? (
          <LinearProgress></LinearProgress>
        ) : (
          <Form>
            <Grid container direction="row" spacing={5}>
              <Grid item md={3} xs={12}>
                <Field component={FormikTextField} name="name" type="text" label="Name" variant="standard" />
              </Grid>
              <Grid item md={9} xs={12}>
                <Field
                  style={{ width: '100%' }}
                  component={FormikTextField}
                  name="nameLong"
                  type="text"
                  label="Name Long"
                  variant="standard"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Field
                  component={FormikDatePicker}
                  name="start"
                  label="Semester Start Date"
                  inputProps={{ textField: { variant: 'standard' } }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Field component={FormikDatePicker} name="end" label="Semester End Date" variant="standard" />
              </Grid>
              <Grid item xs={12} md={3}>
                <FastField
                  component={FormikDatePicker}
                  name="earliestRegistrationDate"
                  label="Earliest Registration Date"
                  variant="standard"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h2">Phases</Typography>
                <FieldArray
                  name="phases"
                  render={(arrayHelpers) => (
                    <Box>
                      {values.phases.map((p, k) => (
                        <Box
                          key={'phase-' + k}
                          sx={{
                            border: { xs: '1px solid lightgray', md: 'none' },
                            backgroundColor:
                              p.isOpen && props.semester.currentPhaseId && p.id === props.semester.currentPhaseId
                                ? COLOR_SUCCESS
                                : 'white',
                            padding: 1,
                          }}
                        >
                          {props.phaseLoading === p.id ? (
                            <LinearProgress></LinearProgress>
                          ) : (
                            <Grid container direction="row" spacing={5}>
                              <Grid item xs={12} md={2}>
                                <FastField
                                  component={FormikTextField}
                                  name={`phases.${k}.name`}
                                  type="text"
                                  label="Name"
                                  variant="standard"
                                />
                              </Grid>
                              <Grid item xs={12} md={3}>
                                <FastField
                                  component={FormikDatePicker}
                                  name={`phases.${k}.keyDate`}
                                  label="Key Date"
                                  variant="standard"
                                />
                              </Grid>
                              <Grid item xs={12} md={2}>
                                <FastField
                                  component={FormikTextField}
                                  name={`phases.${k}.reservePercentage`}
                                  type="text"
                                  label="Reserve Percentage"
                                  variant="standard"
                                />
                              </Grid>

                              <Grid item xs={12} md={5}>
                                {!p.id && (
                                  <Button
                                    variant="contained"
                                    color="inherit"
                                    disabled={isSubmitting || props.loading}
                                    onClick={() => arrayHelpers.remove(k)}
                                    startIcon={<DeleteIcon />}
                                  >
                                    Cancel
                                  </Button>
                                )}
                                {p.id && !p.hasRelatedData && (
                                  <>
                                    <Button
                                      variant="contained"
                                      disabled={isSubmitting || props.loading}
                                      onClick={() => setDialogDeletePhaseOpen(p.id)}
                                      color="inherit"
                                      startIcon={<DeleteIcon />}
                                    >
                                      Delete
                                    </Button>
                                    <ConfirmationDialog
                                      title="Delete Phase"
                                      onConfirm={async () => {
                                        await props.deletePhaseAsync(p);
                                        arrayHelpers.remove(k);
                                      }}
                                      open={deletePhaseOpen === p.id}
                                      onClose={() => setDialogDeletePhaseOpen(0)}
                                    >
                                      Do you really want to delete the phase <strong>{p.name}</strong>?
                                    </ConfirmationDialog>
                                  </>
                                )}
                                {p.id && !p.dateClosed && !(p.keyDate >= DateTime.local().endOf('day')) && (
                                  <>
                                    <Button
                                      color="inherit"
                                      variant="contained"
                                      disabled={isSubmitting || props.loading}
                                      onClick={() => props.closePhaseAsync(p)}
                                      startIcon={<NoMeetingRoomIcon />}
                                    >
                                      Close
                                    </Button>
                                  </>
                                )}
                                {p.id && p.dateClosed && (
                                  <>
                                    <Button
                                      color="inherit"
                                      variant="contained"
                                      disabled={isSubmitting || props.loading}
                                      onClick={() => props.reopenPhaseAsync(p)}
                                      startIcon={<MeetingRoomIcon />}
                                    >
                                      Reopen
                                    </Button>
                                  </>
                                )}
                              </Grid>
                            </Grid>
                          )}
                        </Box>
                      ))}
                      <Button
                        variant="contained"
                        color="inherit"
                        disabled={props.loading}
                        startIcon={<AddIcon />}
                        style={{ marginTop: 20 }}
                        onClick={() =>
                          arrayHelpers.push(
                            new PhaseDto({
                              name: '',
                              keyDate: values.earliestRegistrationDate?.plus({ months: 5 }).startOf('week'),
                              reservePercentage: 0,
                              semesterId: values.id,
                            } as IPhaseDto),
                          )
                        }
                      >
                        Add Phase
                      </Button>
                    </Box>
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={submitForm}
                  startIcon={<SaveIcon />}
                  disabled={isSubmitting || props.loading}
                >
                  Save
                </Button>
                {!props.semester.hasRelatedData && (
                  <>
                    {props.semester.id > 0 ? (
                      <Button
                        variant="contained"
                        color="inherit"
                        disabled={isSubmitting || props.loading}
                        onClick={() => setDialogDeleteSemesterOpen(true)}
                        startIcon={<DeleteIcon />}
                      >
                        Delete
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="inherit"
                        disabled={isSubmitting || props.loading}
                        onClick={() => props.cancelAddingSemester()}
                        startIcon={<DeleteIcon />}
                      >
                        Cancel
                      </Button>
                    )}
                  </>
                )}
                <ConfirmationDialog
                  title="Delete Semester"
                  onConfirm={() => props.deleteSemesterAsync(props.semester)}
                  open={deleteSemesterOpen}
                  onClose={() => setDialogDeleteSemesterOpen(false)}
                >
                  Do you really want to delete the semester <strong>{props.semester.nameLong}</strong>?<br />
                  There are no registrations in this semester, but keep in mind that all module executions associated to
                  this semesters will be deleted too.
                </ConfirmationDialog>
              </Grid>
            </Grid>
          </Form>
        )
      }
    </Formik>
  );
};
