import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { Settings } from 'luxon';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter } from 'react-router-dom';
import AppComponent from './app';
import setupAxiosInterceptors from './clients/axios-interceptor';
import { configureServices } from './clients/configure-services';
import { resolver } from './clients/service-registry';
import configureLogging from './config/configure-logging';
import initStore from './config/store';
import ErrorBoundary from './shared/error/error-boundary';
import { ServiceProvider } from './shared/hooks/use-service';
import theme from './shared/layout/theme';

configureLogging();

const store = initStore();
configureServices();
setupAxiosInterceptors(store);

const rootEl = document.getElementById('root');
const baseHref = document.querySelector('base')?.getAttribute('href')?.replace(/\/$/, '');

Settings.defaultZone = 'Europe/Zurich';

createRoot(rootEl).render(
  <BrowserRouter basename={baseHref}>
    <ServiceProvider resolver={resolver}>
      <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="en">
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ErrorBoundary>
            <Provider store={store}>
              <SnackbarProvider
                maxSnack={3}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <AppComponent />
              </SnackbarProvider>
            </Provider>
          </ErrorBoundary>
        </ThemeProvider>
      </LocalizationProvider>
    </ServiceProvider>
  </BrowserRouter>,
);
