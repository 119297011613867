import {
  IFileImportDto,
  IModuleRegistrationInfoDto,
  IPhaseDto,
  IRegistrationValidationDto,
} from 'app/clients/services';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/util/action-type.util';
import ACTION_TYPES from './types';

type IImportsState = {
  loading: boolean;
  errorMessage: string;
  imports: IFileImportDto[];
  selectedResult: IRegistrationValidationDto;
  selectedImportId: number;
  mainContentLoading: boolean;
  selectedPhase: IPhaseDto;
  previewLoading: boolean;
  selectedRegistration: IModuleRegistrationInfoDto | null;
  moduleRegistrationLoading: boolean;
};

const initialState: IImportsState = {
  loading: false,
  errorMessage: null,
  imports: [],
  selectedResult: null,
  selectedImportId: 0,
  selectedPhase: null,
  mainContentLoading: true,
  previewLoading: false,
  selectedRegistration: null,
  moduleRegistrationLoading: false,
};

export type ImportsState = Readonly<typeof initialState>;

// Reducer
export default (state: IImportsState = initialState, action): ImportsState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.GET_IMPORTS):
      return {
        ...state,
        errorMessage: null,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.GET_IMPORTS):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.GET_IMPORTS):
      return {
        ...state,
        loading: false,
        imports: action.payload,
      };
    case REQUEST(ACTION_TYPES.DOWNLOAD):
      return {
        ...state,
        errorMessage: null,
        previewLoading: true,
      };
    case FAILURE(ACTION_TYPES.DOWNLOAD):
      return {
        ...state,
        previewLoading: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.DOWNLOAD):
      return {
        ...state,
        previewLoading: false,
      };
    case REQUEST(ACTION_TYPES.CHANGE_STATE):
      return {
        ...state,
        errorMessage: null,
        previewLoading: true,
      };
    case FAILURE(ACTION_TYPES.CHANGE_STATE):
      return {
        ...state,
        previewLoading: false,
      };
    case SUCCESS(ACTION_TYPES.CHANGE_STATE):
      return {
        ...state,
        previewLoading: false,
      };
    case REQUEST(ACTION_TYPES.GET_VALIDATION):
      return {
        ...state,
        errorMessage: null,
        previewLoading: true,
      };
    case FAILURE(ACTION_TYPES.GET_VALIDATION):
      return {
        ...state,
        previewLoading: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.GET_VALIDATION):
      return {
        ...state,
        previewLoading: false,
        selectedResult: action.payload,
        selectedImportId: action.payload?.fileImport?.id,
      };
    case ACTION_TYPES.SELECT_IMPORT:
      return {
        ...state,
        selectedImportId: action.payload,
        selectedResult: null,
      };
    case ACTION_TYPES.SELECT_PHASE:
      return {
        ...state,
        selectedPhase: action.payload,
      };
    case ACTION_TYPES.SET_MAIN_CONTENT_LOADING:
      return {
        ...state,
        mainContentLoading: action.payload,
      };
    case REQUEST(ACTION_TYPES.LOAD_MODULE_REGISTRATION):
      return {
        ...state,
        errorMessage: null,
        moduleRegistrationLoading: true,
      };
    case FAILURE(ACTION_TYPES.LOAD_MODULE_REGISTRATION):
      return {
        ...state,
        loading: false,
      };
    case SUCCESS(ACTION_TYPES.LOAD_MODULE_REGISTRATION):
      return {
        ...state,
        moduleRegistrationLoading: false,
        selectedRegistration: action.payload,
      };
    case ACTION_TYPES.CLEAR_MODULE_REGISTRATION:
      return {
        ...state,
        moduleRegistrationLoading: false,
        selectedRegistration: null,
      };
    default:
      return state;
  }
};
