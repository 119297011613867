const config = {
  VERSION: process.env.VERSION,
};

export default config;

export const SERVER_API_URL = process.env.SERVER_API_URL;

export const messages = {
  DATA_ERROR_ALERT: 'Internal Error',
};

export const APP_DATE_FORMAT = 'dd.MM.yyyy';
export const APP_DATETIME_FORMAT = 'dd.MM.yyyy HH:mm';
export const APP_TIMESTAMP_FORMAT = 'dd.MM.yyyy HH:mm:ss';
export const APP_WHOLE_NUMBER_FORMAT = '0,0';
export const APP_TWO_DIGITS_AFTER_POINT_NUMBER_FORMAT = '0,0.[00]';

export const COLOR_ERROR = '#FDECEA';
export const COLOR_WARNING = '#FFF4E5';
export const COLOR_INFO = '#E8F4FD';
export const COLOR_SUCCESS = '#EDF7ED';
