import { ISemesterInfoDto, ISemesterSelectionFilter } from 'app/clients/services';
import { SemesterSelectionState } from './reducer';

export const getAcademicYear = (state: SemesterSelectionState, academicYearId?: number) => {
  if (academicYearId) {
    return state.semesterFilter?.academicYears?.find(a => a.id === academicYearId);
  }
  return state.semesterFilter?.academicYears?.find(a => a.id === state.semesterFilter?.currentAcademicYearId);
};

export const getSemester = (state: SemesterSelectionState, semesterId: number) => {
  return state.semesterFilter?.semesters?.find(s => s.id === semesterId);
};

export const getPhase = (state: SemesterSelectionState, phaseId: number) => {
  const semester = state.semesterFilter?.semesters?.find(s => s.phases?.filter(p => p.id === phaseId).length > 0);
  return semester?.phases?.find(p => p.id === phaseId);
};

export const getSemesterForAcademicYear = (state: SemesterSelectionState, academicYearId: number) => {
  if (state.selectedSemester?.academicYearId === academicYearId) {
    return state.selectedSemester;
  }

  const defaultSemester = getSemester(state, state.semesterFilter?.defaultSemesterId);
  if (defaultSemester?.id === academicYearId) {
    return defaultSemester;
  }

  return state.semesterFilter?.semesters?.filter(s => s.academicYearId === academicYearId)[0];
};

export const getPhaseForSemester = (state: SemesterSelectionState, semester: ISemesterInfoDto) => {
  if (!state.selectedPhase || semester?.phases?.filter(p => p.id === state.selectedPhase.id).length === 0) {
    return semester?.phases?.find(p => p.id === semester.currentPhaseId);
  }

  return state.selectedPhase;
};

export const getOpenSemesters = (filter: ISemesterSelectionFilter) => {
  return filter?.semesters?.filter(s => !s.isPast && s.registrationOpen) ?? [];
};

export const getDefaultSemesterId = (state: SemesterSelectionState) => {
  return state.selectedSemester ? state.selectedSemester?.id : state.semesterFilter?.defaultSemesterId;
};

export const getDefaultSemester = (state: SemesterSelectionState) => {
  return state.selectedSemester
    ? state.selectedSemester
    : state.semesterFilter.semesters.find(s => s.id === state.semesterFilter.defaultSemesterId);
};

export const getSelectedPhase = (state: SemesterSelectionState, semesterId?: number) => {
  if (!semesterId) {
    semesterId = getDefaultSemesterId(state);
  }
  if (state.selectedPhase && state.selectedPhase.semesterId === semesterId) {
    return state.selectedPhase;
  } else {
    const semester = state.semesterFilter?.semesters?.find(s => s.id === semesterId);
    const phaseId = semester?.currentPhaseId;
    return semester?.phases.find(p => p.id === phaseId);
  }
};
