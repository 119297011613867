import {
  CommentDto,
  ICommentDto,
  IEmailClient,
  IEmailDto,
  IModuleRegistrationDto,
  IProfileDto,
  IRegistrationClient,
  IRegistrationEditClient,
  IRegistrationStateChangeDto,
  ISemesterDto,
  ISemesterRegistrationDto,
  IStudentClient,
  IStudentDto,
  IUasDto,
  ModuleRegistrationDto,
  SemesterRegistrationDto,
  StudentDto,
  StudentType,
} from 'app/clients/services';
import { addExceptionNotification, addSuccess } from 'app/modules/notification';
import { AsyncThunk, Thunk } from 'app/shared/util/action-type.util';
import ACTION_TYPES from './types';

const initActions = (
  componentDispatch: any,
  studentManagementClient: IStudentClient,
  emailClient: IEmailClient,
  registrationEditClient: IRegistrationEditClient,
  registrationClient: IRegistrationClient,
  studentTabsActions: {
    selectStudent: (student: IStudentDto) => void;
    removeTab: (student: IStudentDto) => void;
  }
) => {
  const addSemesterRegistration = (semesterRegistration: ISemesterRegistrationDto) => ({
    type: ACTION_TYPES.ADD_SEMESTER_REGISTRATION,
    payload: semesterRegistration,
  });

  const updateSemesterRegistration = (semesterRegistration: ISemesterRegistrationDto) => ({
    type: ACTION_TYPES.UPDATE_SEMESTER_REGISTRATION,
    payload: semesterRegistration,
  });

  const removeSemesterRegistration = (semesterRegistration: ISemesterRegistrationDto) => ({
    type: ACTION_TYPES.REMOVE_SEMESTER_REGISTRATION,
    payload: semesterRegistration,
  });

  const addModuleRegistration = (moduleRegistration: IModuleRegistrationDto) => ({
    type: ACTION_TYPES.ADD_MODULE_REGISTRATION,
    payload: moduleRegistration,
  });

  const updateModuleRegistration = (moduleRegistration: IModuleRegistrationDto) => ({
    type: ACTION_TYPES.UPDATE_MODULE_REGISTRATION,
    payload: moduleRegistration,
  });

  const removeModuleRegistration = (moduleRegistration: IModuleRegistrationDto) => ({
    type: ACTION_TYPES.REMOVE_MODULE_REGISTRATION,
    payload: moduleRegistration,
  });

  const cancelAddingSemesterRegistration = () => ({
    type: ACTION_TYPES.CANCEL_ADDING_SEMESTER_REGISTRATION,
  });

  const setStudentLoading = (loading: boolean) => ({
    type: ACTION_TYPES.SET_STUDENT_LOADING,
    payload: loading,
  });

  const setStudentInfo: (student: IStudentDto) => Thunk = (student) => (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SET_STUDENT_INFO,
      payload: student,
    });
    studentTabsActions.selectStudent(student);
  };

  const getHistoryAsync = async (id: number): Promise<IRegistrationStateChangeDto[]> => {
    return await registrationClient.getHistory(id);
  };

  const getSemestersForNewRegistration = (semesters: ISemesterDto[], semesterRegistrations: ISemesterRegistrationDto[]) => {
    const usedSemesters = semesterRegistrations.map((u) => u.semesterId);
    return semesters.filter((s) => !usedSemesters.includes(s.id)).sort((s1, s2) => (s1.start > s2.start ? -1 : 1));
  };

  const getDefaultSemester = (semesters: ISemesterDto[], semesterRegistrations: ISemesterRegistrationDto[]) => {
    const possibleSemesters = getSemestersForNewRegistration(semesters, semesterRegistrations);
    return possibleSemesters[0];
  };

  const getLastRegistration = (semesterRegistrations: ISemesterRegistrationDto[]) => {
    return semesterRegistrations.sort((s1, s2) => (s1.semesterStart > s2.semesterStart ? -1 : 1))[0];
  };

  const generateNewSemesterRegistration = (student: IStudentDto, semesters: ISemesterDto[], uas: IUasDto[], profiles: IProfileDto[]) => {
    const defaultSemester = getDefaultSemester(semesters, student.semesterRegistrations);
    const lastRegistration = getLastRegistration(student.semesterRegistrations);
    return new SemesterRegistrationDto({
      id: 0,
      isActive: true,
      semesterId: defaultSemester?.id ?? 0,
      registeredAtId: lastRegistration?.registeredAtId ?? uas[0]?.id,
      moduleRegistrations: [],
      studentId: student.id,
      advisorId: lastRegistration?.advisorId ?? null,
      profileId: lastRegistration?.profileId ?? profiles[0]?.id,
      labelId: lastRegistration?.labelId ?? null,
      semesterStart: defaultSemester?.start,
      studentType: lastRegistration?.studentType ?? StudentType.Student,
    });
  };

  const generateNewStudent = (semesters: ISemesterDto[], uas: IUasDto[], profiles: IProfileDto[]) => {
    const student = new StudentDto({
      id: '',
      firstName: '',
      familyName: '',
      hasHiddenInformation: false,
      previousDegree: '',
      matriculationNr: '',
      birthDate: null,
      semesterRegistrations: [],
      emails: [],
    });
    student.semesterRegistrations = [generateNewSemesterRegistration(student, semesters, uas, profiles)];
    return student;
  };

  const addNewSemesterRegistration: (student: IStudentDto, semesters: ISemesterDto[], uas: IUasDto[], profiles: IProfileDto[]) => Thunk<ISemesterRegistrationDto> =
    (student, semesters, uas, profiles) => (dispatch) => {
      const semesterRegistration = generateNewSemesterRegistration(student, semesters, uas, profiles);
      dispatch(addSemesterRegistration(semesterRegistration));
      return semesterRegistration;
    };

  const addNewStudent: (semesters: ISemesterDto[], uas: IUasDto[], profiles: IProfileDto[]) => Thunk<IStudentDto> = (semesters, uas, profiles) => (dispatch) => {
    const student = generateNewStudent(semesters, uas, profiles);
    dispatch(setStudentInfo(student));
    return student;
  };

  const loadStudentAsync: (studentId: string) => AsyncThunk<IStudentDto> = (studentId) => async (dispatch) => {
    dispatch(setStudentLoading(true));
    try {
      const studentInfo = await studentManagementClient.getStudent(studentId);
      dispatch(setStudentInfo(studentInfo));
      dispatch(setStudentLoading(false));
      return studentInfo;
    } catch (e) {
      dispatch(addExceptionNotification(e, 'Error while loading student.'));
      dispatch(setStudentLoading(false));
      return null;
    }
  };

  const deleteEmailAsync: (email: IEmailDto) => AsyncThunk<IStudentDto> = (email) => async (dispatch) => {
    dispatch(setStudentLoading(true));
    try {
      await emailClient.delete(email.id);
      const studentInfo = await studentManagementClient.getStudent(email.userId);
      dispatch(setStudentInfo(studentInfo));
      dispatch(addSuccess(`Successfully deleted the email ${email.name}.`));
      dispatch(setStudentLoading(false));
      return studentInfo;
    } catch (e) {
      dispatch(addExceptionNotification(e, 'Error while deleting email.'));
      dispatch(setStudentLoading(false));
      return null;
    }
  };

  const updateStudentAsync: (student: IStudentDto) => AsyncThunk<IStudentDto> = (student) => async (dispatch) => {
    dispatch(setStudentLoading(true));
    try {
      await studentManagementClient.updateStudent(new StudentDto(student));
      const updatedStudent = await studentManagementClient.getStudent(student.id);
      dispatch(setStudentInfo(updatedStudent));
      dispatch(setStudentLoading(false));
      dispatch(addSuccess(`Successfully updated student ${updatedStudent.firstName} ${updatedStudent.familyName}`));
      return updatedStudent;
    } catch (e) {
      dispatch(addExceptionNotification(e, 'An unknown error occured while updating student.'));
      dispatch(setStudentLoading(false));
      return null;
    }
  };

  const addStudentAsync: (student: IStudentDto) => AsyncThunk = (student) => async (dispatch) => {
    dispatch(setStudentLoading(true));
    try {
      const id = await studentManagementClient.addStudent(new StudentDto(student));
      studentTabsActions.removeTab(student);
      const addedStudent = await studentManagementClient.getStudent(id);
      dispatch(setStudentInfo(addedStudent));
      studentTabsActions.selectStudent(addedStudent);
      dispatch(setStudentLoading(false));
      dispatch(addSuccess(`Successfully added student ${addedStudent.firstName} ${addedStudent.familyName}`));
    } catch (e) {
      dispatch(addExceptionNotification(e, 'An unknown error occured while adding student.'));
      if (student?.semesterRegistrations) {
        for (const semesterRegistration of student.semesterRegistrations) {
          dispatch(updateSemesterRegistration(semesterRegistration));
        }
      }

      dispatch(setStudentLoading(false));
    }
  };

  const cancelAddingStudent: () => Thunk = () => (dispatch) => {
    dispatch(setStudentInfo(null));
  };

  const deleteStudentAsync: (student: IStudentDto) => AsyncThunk = (student) => async (dispatch) => {
    dispatch(setStudentLoading(true));
    try {
      await studentManagementClient.deleteStudent(student.id);

      dispatch(setStudentInfo(null));
      dispatch(addSuccess(`Successfully deleted student ${student.firstName} ${student.familyName}`));
      dispatch(setStudentLoading(false));
    } catch (e) {
      dispatch(addExceptionNotification(e, 'An unknown error occured while deleting student.'));
      dispatch(setStudentLoading(false));
    }
  };

  const updateSemesterRegistrationAsync: (semesterRegistration: ISemesterRegistrationDto) => AsyncThunk = (semesterRegistration) => async (dispatch) => {
    try {
      const result = await registrationEditClient.updateSemesterRegistration(new SemesterRegistrationDto(semesterRegistration));
      dispatch(updateSemesterRegistration(result));
      dispatch(addSuccess(`Successfully updated the semester registration '${semesterRegistration.semester}'.`));
    } catch (e) {
      dispatch(updateSemesterRegistration(semesterRegistration));
      dispatch(addExceptionNotification(e, 'An unknown error occured while updating the semester registration.'));
    }
  };

  const addSemesterRegistrationAsync: (semesterRegistration: ISemesterRegistrationDto) => AsyncThunk = (semesterRegistration) => async (dispatch) => {
    try {
      const result = await registrationEditClient.addSemesterRegistration(new SemesterRegistrationDto(semesterRegistration));
      dispatch(addSemesterRegistration(result));
      dispatch(removeSemesterRegistration(semesterRegistration));
      dispatch(addSuccess(`Successfully added the semester registration '${result?.semester}'.`));
    } catch (e) {
      dispatch(updateSemesterRegistration(semesterRegistration));
      dispatch(addExceptionNotification(e, 'An unknown error occured while adding the semester registration.'));
    }
  };

  const deleteSemesterRegistrationAsync: (semesterRegistration: ISemesterRegistrationDto) => AsyncThunk = (semesterRegistration) => async (dispatch) => {
    try {
      await registrationEditClient.deleteSemesterRegistration(semesterRegistration.id);
      dispatch(removeSemesterRegistration(semesterRegistration));
      dispatch(addSuccess(`Successfully deleted the semester registration ${semesterRegistration.semester}.`));
    } catch (e) {
      dispatch(addExceptionNotification(e, 'An unknown error occured while deleting the semester registration.'));
    }
  };

  const updateModuleRegistrationAsync: (moduleRegistration: IModuleRegistrationDto, comment: ICommentDto) => AsyncThunk = (moduleRegistration, comment) => async (dispatch) => {
    try {
      const updatedRegistration = new ModuleRegistrationDto(moduleRegistration);
      if (comment) {
        updatedRegistration.comments = updatedRegistration.comments ?? [];
        updatedRegistration.comments.push(new CommentDto(comment));
      }

      const result = await registrationEditClient.updateModuleRegistration(updatedRegistration);
      dispatch(updateModuleRegistration(result));
      dispatch(addSuccess(`Successfully updated the module registration.`));
    } catch (e) {
      dispatch(addExceptionNotification(e, 'An unknown error occured while updating the module registration.'));
    }
  };

  const addModuleRegistrationAsync: (moduleRegistration: IModuleRegistrationDto, comment: ICommentDto) => AsyncThunk = (moduleRegistration, comment) => async (dispatch) => {
    try {
      const newRegistration = new ModuleRegistrationDto(moduleRegistration);
      if (comment) {
        newRegistration.comments = newRegistration.comments ?? [];
        newRegistration.comments.push(new CommentDto(comment));
      }
      const result = await registrationEditClient.addModuleRegistration(new ModuleRegistrationDto(newRegistration));
      dispatch(addModuleRegistration(result));
      dispatch(addSuccess(`Successfully added the module registration.`));
    } catch (e) {
      dispatch(addExceptionNotification(e, 'An unknown error occured while adding the module registration.'));
    }
  };

  const deleteModuleRegistrationAsync: (moduleRegistration: IModuleRegistrationDto) => AsyncThunk = (moduleRegistration) => async (dispatch) => {
    try {
      await registrationEditClient.deleteModuleRegistration(moduleRegistration.id);
      dispatch(removeModuleRegistration(moduleRegistration));
      dispatch(addSuccess(`Successfully deleted the module registration.`));
    } catch (e) {
      dispatch(addExceptionNotification(e, 'An unknown error occured while deleting the module registration.'));
    }
  };

  const addCommentAsync: (comment: ICommentDto) => AsyncThunk = (comment) => async (dispatch) => {
    try {
      const id = await registrationEditClient.addComment(new CommentDto(comment));
      const newComment = new CommentDto({ ...comment, id });
      dispatch({
        type: ACTION_TYPES.ADD_REGISTRATION_COMMENT,
        payload: newComment,
      });
      dispatch(addSuccess(`Successfully added comment.`));
    } catch (e) {
      dispatch(addExceptionNotification(e, 'An unknown error occured while saving comment.'));
    }
  };

  const removeCommentAsync: (comment: ICommentDto) => AsyncThunk = (comment) => async (dispatch) => {
    try {
      await registrationEditClient.deleteComment(comment.id);
      dispatch({
        type: ACTION_TYPES.REMOVE_REGISTRATION_COMMENT,
        payload: comment,
      });
      dispatch(addSuccess(`Successfully deleted comment.`));
    } catch (e) {
      dispatch(addExceptionNotification(e, 'An unknown error occured while deleting comment.'));
    }
  };

  const withdrawModuleRegistrationAsync: (moduleRegistration: IModuleRegistrationDto, comment: string) => AsyncThunk = (moduleRegistration, comment) => async (dispatch) => {
    try {
      const result = await registrationEditClient.withdrawModuleRegistration(moduleRegistration.id, comment);
      dispatch(updateModuleRegistration(result));
      dispatch(addSuccess(`Successfully changed the state of the module registration.`));
    } catch (e) {
      dispatch(addExceptionNotification(e, 'An unknown error occured while changing the state of the registration.'));
    }
  };

  const requestModuleRegistrationAsync: (moduleRegistration: IModuleRegistrationDto, comment: string) => AsyncThunk = (moduleRegistration, comment) => async (dispatch) => {
    try {
      const result = await registrationEditClient.requestModuleRegistration(moduleRegistration.id, comment);
      dispatch(updateModuleRegistration(result));
      dispatch(addSuccess(`Successfully changed the state of the module registration.`));
    } catch (e) {
      dispatch(addExceptionNotification(e, 'An unknown error occured while changing the state of the registration.'));
    }
  };

  return Object.freeze({
    cancelAddingStudent: (): void => componentDispatch(cancelAddingStudent()),
    updateSemesterRegistration: (semesterRegistration: ISemesterRegistrationDto): void => componentDispatch(updateSemesterRegistration(semesterRegistration)),
    cancelAddingSemesterRegistration: (): void => componentDispatch(cancelAddingSemesterRegistration()),
    addNewSemesterRegistration: (student: IStudentDto, semesters: ISemesterDto[], uas: IUasDto[], profiles: IProfileDto[]): ISemesterRegistrationDto =>
      componentDispatch(addNewSemesterRegistration(student, semesters, uas, profiles)),
    addNewStudent: (semesters: ISemesterDto[], uas: IUasDto[], profiles: IProfileDto[]): IStudentDto => componentDispatch(addNewStudent(semesters, uas, profiles)),
    deleteSemesterRegistrationAsync: async (semesterRegistration: ISemesterRegistrationDto): Promise<void> =>
      await componentDispatch(deleteSemesterRegistrationAsync(semesterRegistration)),
    addSemesterRegistrationAsync: async (semesterRegistration: ISemesterRegistrationDto): Promise<IStudentDto> =>
      await componentDispatch(addSemesterRegistrationAsync(semesterRegistration)),
    updateSemesterRegistrationAsync: async (semesterRegistration: ISemesterRegistrationDto): Promise<void> =>
      await componentDispatch(updateSemesterRegistrationAsync(semesterRegistration)),
    deleteModuleRegistrationAsync: async (moduleRegistration: IModuleRegistrationDto): Promise<void> => await componentDispatch(deleteModuleRegistrationAsync(moduleRegistration)),
    addModuleRegistrationAsync: async (moduleRegistration: IModuleRegistrationDto, comment: ICommentDto): Promise<void> =>
      await componentDispatch(addModuleRegistrationAsync(moduleRegistration, comment)),
    updateModuleRegistrationAsync: async (moduleRegistration: IModuleRegistrationDto, comment: ICommentDto): Promise<void> =>
      await componentDispatch(updateModuleRegistrationAsync(moduleRegistration, comment)),
    addCommentAsync: async (comment: ICommentDto): Promise<void> => await componentDispatch(addCommentAsync(comment)),
    removeCommentAsync: async (comment: ICommentDto): Promise<void> => await componentDispatch(removeCommentAsync(comment)),
    deleteEmailAsync: async (email: IEmailDto): Promise<IStudentDto> => await componentDispatch(deleteEmailAsync(email)),
    loadStudentAsync: async (studentId: string): Promise<IStudentDto> => await componentDispatch(loadStudentAsync(studentId)),
    deleteStudentAsync: async (student: IStudentDto): Promise<void> => await componentDispatch(deleteStudentAsync(student)),
    addStudentAsync: async (student: IStudentDto): Promise<IStudentDto> => await componentDispatch(addStudentAsync(student)),
    updateStudentAsync: async (student: IStudentDto): Promise<IStudentDto> => await componentDispatch(updateStudentAsync(student)),
    requestModuleRegistrationAsync: async (moduleRegistration: IModuleRegistrationDto, comment: string): Promise<IStudentDto> =>
      await componentDispatch(requestModuleRegistrationAsync(moduleRegistration, comment)),
    withdrawModuleRegistrationAsync: async (moduleRegistration: IModuleRegistrationDto, comment: string): Promise<IStudentDto> =>
      await componentDispatch(withdrawModuleRegistrationAsync(moduleRegistration, comment)),
    getHistoryAsync,
  });
};
export default initActions;

export type StudentManagementActions = ReturnType<typeof initActions>;
