import { resolver } from 'app/clients/service-registry';
import { ISummarySheetClient } from 'app/clients/services';
import initActions from './actions';
import reducer from './reducer';
import types from './types';

export type { SummaryState } from './reducer';
export default {
  initActions: (dispatch) => initActions(dispatch, resolver.resolve<ISummarySheetClient>('ISummarySheetClient')),
  types,
  reducer,
};
