/* eslint-disable complexity */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  ButtonGroup,
  FormControl,
  Grid,
  Input,
  InputLabel,
  LinearProgress,
  Paper,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import {
  IFilterPresetDto,
  IModuleCategoryDto,
  IModuleDto,
  IModuleRegistrationInfoDto,
  IPhaseDto,
  IRoleDto,
  ISelectionOfLong,
  RoleType,
} from 'app/clients/services';
import { APP_DATE_FORMAT } from 'app/config/constants';
import { IRootState } from 'app/config/root.reducer';
import { AdvisorPreview } from 'app/shared/layout/parts/advisor-preview';
import { StudentPreview } from 'app/shared/layout/parts/student-preview';
import { StandardCheckbox } from 'app/shared/layout/ui-elements/checkbox';
import { StandardChip } from 'app/shared/layout/ui-elements/chip';
import { ChipSelectionRow } from 'app/shared/layout/ui-elements/chip-selection';
import { MainContent, SideBar, SplitLayout } from 'app/shared/layout/ui-elements/component-layout';
import { Search } from 'app/shared/layout/ui-elements/search';
import { SelectBox } from 'app/shared/layout/ui-elements/select-box';
import React, { useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import moduleRegistrationExportModule from './registration-export';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { studentTypes } from 'app/clients/data/student-types';
import { hasAnyRole } from 'app/shared/auth/roles';
import { ModuleRegistrations } from 'app/shared/layout/parts/module-registrations';
import { ModuleRegistrationInfoProperties } from 'app/shared/layout/parts/registration-cols';
import { DefaultButton } from 'app/shared/layout/ui-elements/buttons';
import { ConfirmationDialog } from 'app/shared/layout/ui-elements/confirmation-dialog';
import { NavigationList } from 'app/shared/layout/ui-elements/navigation-list';
import { toCamelCase } from 'app/shared/util/string-utils';
import { combineUrlParams, getBasePath } from 'app/shared/util/url-utils';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export type Props = PropsFromRedux;

export const RegistrationExport = (props: Props) => {
  const [moduleFilterOpen, setModuleFilterOpen] = useState<boolean>(false);
  const [columnSelectionOpen, setColumnSelectionOpen] = useState<boolean>(false);
  const [studentFilterOpen, setStudentFilterOpen] = useState<boolean>(false);
  const [advisorFilterOpen, setAdvisorFilterOpen] = useState<boolean>(false);

  const [filterPresetSaveOpen, setFilterPresetSaveOpen] = useState<boolean>(false);
  const [filterPresetName, setFilterPresetName] = useState<string>(null);
  const [filterPresetRoles, setFilterPresetRoles] = useState<IRoleDto[]>([]);

  const [registrationCommentOpen, setRegistrationCommentOpen] = useState<IModuleRegistrationInfoDto>(null);

  const params = useParams<{ id: string }>();
  const { id } = params;
  const navigate = useNavigate();
  const basePath = getBasePath(useLocation(), params);

  useEffect(() => {
    const loadInitialData = async () => {
      const presets = await props.loadPresetsAsync();
      if (id) {
        const preset = presets.find((p) => p.id === Number(id));
        if (preset) {
          setFilterPresetName(preset.name);
          setFilterPresetRoles(props.roles.filter((r) => preset.forRoles.includes(r.roleType)));
          await props.setPresetAsync(preset);
        } else {
          setFilterPresetName(null);
          setFilterPresetRoles([]);
        }
      } else {
        setFilterPresetName(null);
        setFilterPresetRoles([]);
      }
      await props.loadFiltersAsync();
    };

    loadInitialData();
  }, []);

  const handlePresetChange = async (preset: IFilterPresetDto) => {
    if (preset) {
      navigate(basePath + combineUrlParams({ id: preset.id }));
      setFilterPresetName(preset.name);
      setFilterPresetRoles(props.roles.filter((r) => preset.forRoles.includes(r.roleType)));
      await props.setPresetAsync(preset);
    } else {
      navigate(basePath);
      setFilterPresetName(null);
      setFilterPresetRoles([]);
      await props.setPresetAsync(null);
    }
  };

  return (
    <SplitLayout loading={props.staticFiltersLoading || props.presetsLoading}>
      <SideBar>
        {props.presets?.length > 0 && (
          <NavigationList
            createUrl={(e: IFilterPresetDto) => basePath + combineUrlParams({ id: e.id })}
            title="Saved Filters"
            items={props.presets ?? []}
            selectedItem={props.selectedPreset}
            onClick={handlePresetChange}
            getItemKey={(e: IFilterPresetDto) => e.id}
            getItemTitle={(e: IFilterPresetDto) => e.name}
          />
        )}
      </SideBar>
      <MainContent>
        <Typography variant="h1">Registration Export</Typography>
        <Accordion
          expanded={columnSelectionOpen || props.columnSelection.length !== props.selectableColumns.length}
          onChange={(_event, expanded) => {
            setColumnSelectionOpen(expanded);
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            Column Selection
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              <ChipSelectionRow
                selectBoxSize={3}
                items={props.selectableColumns}
                selectedItems={props.columnSelection}
                actionTitle={'Add Column'}
                getItemKey={(u) => u.propertyName}
                getItemTitle={(u) => u.fullName}
                onDelete={(c) => {
                  props.removeColumn(c);
                }}
                onAdd={(c) => {
                  props.addColumn(c);
                }}
              ></ChipSelectionRow>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded>
          <AccordionSummary aria-controls="panel2a-content" id="panel2a-header">
            Main Filters
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              <ChipSelectionRow
                selectBoxSize={3}
                items={props.selectableSemesters}
                selectedItems={props.selectedSemesters}
                actionTitle={'Add Semester Filter'}
                getItemKey={(u) => u.id}
                getItemTitle={(u) => u.name}
                onDelete={async (u) => {
                  await props.removeSemesterFilterAsync(u);
                }}
                onAdd={async (u) => {
                  await props.addSemesterFilterAsync(u);
                }}
              ></ChipSelectionRow>

              {props.selectedSemesters?.length === 1 && (
                <>
                  <Grid item xs={12} md={3}>
                    <SelectBox
                      title="Phase"
                      items={props.selectablePhases}
                      selectedItem={props.selectedPhase}
                      getItemTitle={(c) => c.name}
                      getItemKey={(c) => c.id}
                      allowEmpty
                      onChange={async (item: IPhaseDto) => {
                        await props.setPhaseFilterAsync(item);
                      }}
                    />
                  </Grid>
                  {props.selectedPhase ? (
                    <Grid item xs={12}>
                      <Alert severity="warning">
                        Only registrations changed during phase{' '}
                        <strong>
                          {'"'}
                          {props.selectedPhase.name}
                          {'"'}
                        </strong>{' '}
                        will be shown. <br />
                        The last state of a registration during phase{' '}
                        <strong>
                          {'"'}
                          {props.selectedPhase.name}
                          {'"'}
                        </strong>{' '}
                        is displayed instead of the current state!
                      </Alert>
                    </Grid>
                  ) : (
                    <Grid item xs={9}></Grid>
                  )}
                </>
              )}
              {props.allowedUas.length > 1 && (
                <ChipSelectionRow
                  selectBoxSize={3}
                  items={props.allowedUas}
                  selectedItems={props.uasSelection}
                  actionTitle={'Add UAS Filter'}
                  getItemKey={(u) => u.id}
                  getItemTitle={(u) => u.code}
                  onDelete={async (u) => {
                    await props.removeUasFilterAsync(u);
                  }}
                  onAdd={async (u) => {
                    await props.addUasFilterAsync(u);
                  }}
                ></ChipSelectionRow>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion
          disabled={!!props.selectedStudent || !!props.selectedAdvisor}
          expanded={moduleFilterOpen || props.hasModuleFilter}
          onChange={(_event, expanded) => {
            if (expanded) {
              setStudentFilterOpen(false);
              setAdvisorFilterOpen(false);
            }
            setModuleFilterOpen(expanded);
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
            Module Filter
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              <Grid item xs={12} md={3}>
                <SelectBox
                  title="Module Category"
                  items={props.selectableModuleCategories}
                  selectedItem={props.selectedModuleCategory}
                  getItemTitle={(c) => c.code}
                  getItemKey={(c) => c.id}
                  allowEmpty
                  onChange={async (item: IModuleCategoryDto) => {
                    await props.setModuleCategoryAsync(item);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <SelectBox
                  title="Module"
                  items={props.selectableModules}
                  selectedItem={props.selectedModule}
                  getItemTitle={(c) => c.code}
                  getItemKey={(c) => c.id}
                  allowEmpty
                  onChange={async (item: IModuleDto) => {
                    await props.setModuleAsync(item);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <SelectBox
                  title="Module Execution"
                  items={props.selectableModuleExecutionCodes}
                  selectedItem={props.selectedModuleExecutionCode}
                  getItemTitle={(c) => c}
                  getItemKey={(c) => c}
                  allowEmpty
                  disabled={props.moduleFiltersLoading}
                  loading={props.moduleFiltersLoading}
                  onChange={(code: string) => {
                    props.setModuleExecutionCode(code);
                  }}
                />
              </Grid>
              <Grid item md={3}></Grid>
              <ChipSelectionRow
                selectBoxSize={3}
                items={props.selectableLocations}
                selectedItems={props.locationSelection}
                actionTitle={'Add Location Filter'}
                getItemKey={(s) => s.id}
                getItemTitle={(s) => s.display}
                onDelete={(s) => {
                  props.removeLocationFilter(s);
                }}
                onAdd={(s) => {
                  props.addLocationFilter(s);
                }}
              ></ChipSelectionRow>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion
          disabled={!!props.selectedAdvisor || props.hasModuleFilter}
          expanded={studentFilterOpen || !!props.selectedStudent}
          onChange={(_event, expanded) => {
            if (expanded) {
              setModuleFilterOpen(false);
              setAdvisorFilterOpen(false);
            }
            setStudentFilterOpen(expanded);
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4a-content" id="panel4a-header">
            Filter by Student
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              <Grid item xs={12}>
                <Search
                  onSearch={(searchString) => props.searchStudentAsync(searchString)}
                  onChange={(searchString) => props.changeStudentSearchString(searchString)}
                  title="Search Student"
                ></Search>
              </Grid>
              <Grid item xs={9}>
                {props.selectedStudent && (
                  <StandardChip
                    onDelete={() => props.setStudent(null)}
                    label={`${props.selectedStudent.display}`}
                    sx={{ padding: 1 }}
                  ></StandardChip>
                )}
              </Grid>
              {props.studentSearchLoading ? (
                <Grid item xs={12}>
                  <LinearProgress></LinearProgress>
                </Grid>
              ) : (
                props.studentSearchLoaded &&
                props.studentSearchResult && (
                  <Grid item xs={12}>
                    {props.studentSearchResult.length > 0 ? (
                      props.studentSearchResult.map((s, k) => (
                        <Paper
                          key={k}
                          style={{ margin: 5, padding: 5, cursor: 'pointer' }}
                          onClick={() => props.setStudent(s)}
                        >
                          <StudentPreview student={s}></StudentPreview>
                        </Paper>
                      ))
                    ) : (
                      <Typography>No Student found.</Typography>
                    )}
                  </Grid>
                )
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={advisorFilterOpen || !!props.selectedAdvisor}
          disabled={!!props.selectedStudent || props.hasModuleFilter}
          onChange={(_event, expanded) => {
            if (expanded) {
              setModuleFilterOpen(false);
              setStudentFilterOpen(false);
            }
            setAdvisorFilterOpen(expanded);
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel5a-content" id="panel5a-header">
            Filter by Advisor
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              <Grid item xs={12}>
                <Search
                  onSearch={(searchString) => props.searchAdvisorAsync(searchString)}
                  onChange={(searchString) => props.changeAdvisorSearchString(searchString)}
                  title="Search Advisors"
                ></Search>
              </Grid>
              <Grid item xs={9}>
                {props.selectedAdvisor && (
                  <StandardChip
                    onDelete={() => props.setAdvisor(null)}
                    label={`${props.selectedAdvisor.display}`}
                  ></StandardChip>
                )}
              </Grid>
              {props.advisorSearchLoading ? (
                <Grid item xs={12}>
                  <LinearProgress></LinearProgress>
                </Grid>
              ) : (
                props.advisorSearchLoaded &&
                props.advisorSearchResult && (
                  <Grid item xs={12}>
                    {props.advisorSearchResult.length > 0 ? (
                      props.advisorSearchResult.map((a, k) => (
                        <Paper
                          key={k}
                          style={{ margin: 5, padding: 5, cursor: 'pointer' }}
                          onClick={() => props.setAdvisor(a)}
                        >
                          <AdvisorPreview advisor={a}></AdvisorPreview>
                        </Paper>
                      ))
                    ) : (
                      <Typography>No Advisor found.</Typography>
                    )}
                  </Grid>
                )
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel6a-content" id="panel6a-header">
            Filter by State/Date
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              <>
                <ChipSelectionRow
                  selectBoxSize={3}
                  items={props.selectableStates}
                  selectedItems={props.stateSelection}
                  actionTitle={'Show States Only'}
                  getItemKey={(s) => s}
                  getItemTitle={(s) => s}
                  onDelete={(s) => {
                    props.removeStateFilter(s);
                  }}
                  onAdd={(s) => {
                    props.addStateFilter(s);
                  }}
                ></ChipSelectionRow>
                <Grid item xs={12} md={3} sx={{ padding: 1 }}>
                  <DatePicker
                    slotProps={{
                      actionBar: {
                        actions: ['clear'],
                      },
                      textField: { variant: 'standard' },
                    }}
                    label="Starting From"
                    format={APP_DATE_FORMAT}
                    onChange={(date) => props.setNewerThen(date)}
                    value={props.filterSelection?.newerThen ?? null}
                    disableFuture
                  />
                </Grid>

                <Grid item xs={12} md={3} sx={{ padding: 1 }}>
                  <DatePicker
                    slotProps={{
                      actionBar: {
                        actions: ['clear'],
                      },
                      textField: { variant: 'standard' },
                    }}
                    label="State At"
                    format={APP_DATE_FORMAT}
                    onChange={(date) => props.setStateAt(date)}
                    value={props.filterSelection?.stateAt ?? null}
                    disableFuture
                  />
                </Grid>
                <Grid item xs={6}></Grid>
              </>
              {props.selectedSemesters.length > 0 && (
                <Grid item xs={12} md={3}>
                  <SelectBox
                    title="Import"
                    items={props.selectableFileImports}
                    selectedItem={props.selectedImport}
                    getItemTitle={(c) => c.display}
                    getItemKey={(c) => c.id}
                    allowEmpty
                    onChange={(item: ISelectionOfLong) => {
                      props.setFileImport(item);
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>Filter by Additional Info</AccordionSummary>
          <AccordionDetails>
            <Grid container>
              <ChipSelectionRow
                selectBoxSize={3}
                items={props.selectableProfiles}
                selectedItems={props.profileSelection}
                actionTitle={'Add Profile Filter'}
                getItemKey={(s) => s.id}
                getItemTitle={(s) => s.display}
                onDelete={(s) => {
                  props.removeProfileFilter(s);
                }}
                onAdd={(s) => {
                  props.addProfileFilter(s);
                }}
              ></ChipSelectionRow>
              {props.selectableLabels.length > 0 && (
                <ChipSelectionRow
                  items={props.selectableLabels}
                  selectedItems={props.labelSelection}
                  actionTitle={'Add Label Filter'}
                  getItemKey={(s) => s.id}
                  getItemTitle={(s) => s.title}
                  onDelete={(s) => {
                    props.removeLabelFilter(s);
                  }}
                  onAdd={(s) => {
                    props.addLabelFilter(s);
                  }}
                ></ChipSelectionRow>
              )}
              <Grid item xs={12} md={3} sx={{ padding: 1 }}>
                {' '}
                <StandardCheckbox
                  title="Has comp. disadv."
                  checked={props.filterSelection.hasCompensationForDisadvantages}
                  onChange={() => {
                    props.setHasCompensationForDisadvantages(!props.filterSelection?.hasCompensationForDisadvantages);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3} sx={{ padding: 1 }}>
                <StandardCheckbox
                  title="Has Comment"
                  checked={props.filterSelection.hasComment}
                  onChange={() => {
                    props.setHasComment(!props.filterSelection?.hasComment);
                  }}
                />
              </Grid>
              {props.isAdmin && (
                <Grid item xs={12} md={3} sx={{ padding: 1 }}>
                  <StandardCheckbox
                    title="Has Internal Comment"
                    checked={props.filterSelection.hasInternalComment}
                    onChange={() => {
                      props.setHasInternalComment(!props.filterSelection?.hasInternalComment);
                    }}
                  />
                </Grid>
              )}
              <Grid item xs={12} md={3} sx={{ padding: 1 }}>
                <StandardCheckbox
                  title="Has No UAS Email"
                  checked={props.filterSelection.hasNoUasEmail}
                  onChange={() => {
                    props.setHasNoUasEmail(!props.filterSelection?.hasNoUasEmail);
                  }}
                />
              </Grid>
              <ChipSelectionRow
                selectBoxSize={3}
                items={studentTypes}
                selectedItems={props.selectedStudentTypes}
                actionTitle={'Add Student Type Filter'}
                getItemKey={(p) => p.key}
                getItemTitle={(p) => p.title}
                onDelete={async (p) => {
                  await props.removeStudentTypeFilter(p.key);
                }}
                onAdd={async (p) => {
                  await props.addStudentTypeFilter(p.key);
                }}
              ></ChipSelectionRow>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <div style={{ margin: 5, marginTop: 20 }}>
          <ButtonGroup>
            <DefaultButton onClick={() => props.loadPreviewAsync(props.filterSelection)} disabled={props.loading}>
              Preview
            </DefaultButton>
            <DefaultButton disabled={props.loading} onClick={() => props.exportAsync(props.filterSelection)} primary>
              Export
            </DefaultButton>
          </ButtonGroup>
          <ButtonGroup style={{ marginLeft: 20 }}>
            <DefaultButton
              onClick={async () => {
                await props.resetFiltersAsync();
                handlePresetChange(null);
              }}
              disabled={props.loading}
            >
              Unselect Filters
            </DefaultButton>
          </ButtonGroup>
          <ButtonGroup style={{ marginLeft: 20 }}>
            {props.selectedPreset ? (
              <>
                <DefaultButton disabled={props.loading} onClick={() => setFilterPresetSaveOpen(true)}>
                  Save Filter
                </DefaultButton>
                {props.selectedPreset.isOwner && (
                  <>
                    <DefaultButton
                      disabled={props.loading}
                      onClick={async () => {
                        await props.deleteFilterPresetAsync(props.selectedPreset);
                        handlePresetChange(null);
                      }}
                    >
                      Delete Filter
                    </DefaultButton>
                  </>
                )}
              </>
            ) : (
              <DefaultButton disabled={props.loading} onClick={() => setFilterPresetSaveOpen(true)}>
                Create New Filter Preset
              </DefaultButton>
            )}
          </ButtonGroup>
          {props.exportLoading && <LinearProgress></LinearProgress>}
        </div>
        {(props.previewLoaded || props.previewLoading) && (
          <div style={{ margin: 5, marginTop: 20 }}>
            <ModuleRegistrations
              isAdmin={props.isAdmin}
              rows={props.preview ?? []}
              showInfos
              columnFilter={{
                unhideAll: true,
                isAdmin: props.isAdmin,
                sortable: true,
                takeColumns:
                  props.columnSelection?.length > 0
                    ? props.columnSelection.map((s) =>
                        s.propertyName === 'StateName'
                          ? 'state'
                          : (toCamelCase(s.propertyName) as ModuleRegistrationInfoProperties),
                      )
                    : props.selectableColumns.map((s) =>
                        s.propertyName === 'StateName'
                          ? 'state'
                          : (toCamelCase(s.propertyName) as ModuleRegistrationInfoProperties),
                      ),
              }}
              loading={props.previewLoading}
              onAddComment={async (c) => {
                await props.addCommentAsync(c);
                setRegistrationCommentOpen(null);
              }}
              onRemoveComment={async (c) => {
                await props.removeCommentAsync(c);
                setRegistrationCommentOpen(null);
              }}
              currentUser={props.currentUser}
              getHistory={async () => await props.getHistoryAsync(registrationCommentOpen.id)}
            />
          </div>
        )}
        <ConfirmationDialog
          open={filterPresetSaveOpen}
          title="Save Filter"
          confirmation={!props.selectedPreset ? 'Save' : 'Update'}
          confirmDisabled={!!props.selectedPreset && !props.selectedPreset.isOwner}
          onConfirm={async () => {
            setFilterPresetSaveOpen(false);
            if (props.selectedPreset) {
              await props.updateFilterPresetAsync(props.selectedPreset, filterPresetName, filterPresetRoles);
            } else {
              const preset = await props.createFilterPresetAsync(filterPresetName, filterPresetRoles);
              handlePresetChange(preset);
            }
          }}
          onClose={() => setFilterPresetSaveOpen(false)}
          additionalActions={
            <>
              {props.selectedPreset && (
                <DefaultButton
                  onClick={async () => {
                    const presetName =
                      filterPresetName === props.selectedPreset.name ? filterPresetName + ' copy' : filterPresetName;
                    const preset = await props.createFilterPresetAsync(presetName, filterPresetRoles);
                    if (preset) {
                      setFilterPresetSaveOpen(false);
                      handlePresetChange(preset);
                    }
                  }}
                >
                  Save As New Preset
                </DefaultButton>
              )}
            </>
          }
        >
          <Grid container>
            <Grid item xs={12}>
              <FormControl style={{ margin: 5, width: '100%' }}>
                <InputLabel htmlFor="filter-name">Filter Name</InputLabel>
                <Input
                  id={'filter-name'}
                  value={filterPresetName}
                  onChange={(event) => {
                    setFilterPresetName(event.target.value);
                  }}
                />
              </FormControl>
            </Grid>
            {props.isAdmin && (
              <>
                <Grid item xs={12} style={{ marginTop: 20 }}>
                  <Typography>
                    {filterPresetRoles.length === 0 ? (
                      <>
                        This filter is only visible for you. <br />
                        If you want to make it usable for other people, please add roles you want to share the filter
                        with:{' '}
                      </>
                    ) : (
                      <>
                        This filter is visible for the owner{' '}
                        {props.selectedPreset?.owner ? <>({props.selectedPreset.owner}</> : <>you </>}) and the
                        following roles:
                      </>
                    )}
                  </Typography>
                </Grid>
                <ChipSelectionRow
                  hideSelectBox={props.selectedPreset && !props.selectedPreset?.isOwner}
                  disabled={props.selectedPreset && !props.selectedPreset?.isOwner}
                  actionTitle="Add Role"
                  items={props.roles}
                  selectedItems={filterPresetRoles}
                  getItemTitle={(r) => r.name}
                  getItemKey={(r) => r.id}
                  onAdd={(item) => setFilterPresetRoles([...filterPresetRoles, item])}
                  onDelete={(item) => setFilterPresetRoles(filterPresetRoles.filter((r) => r.id !== item.id))}
                />
                {props.selectedPreset && (
                  <>
                    {!props.selectedPreset.isOwner ? (
                      <Grid item xs={12} style={{ marginTop: 20 }}>
                        <Typography>
                          Only the owner ({props.selectedPreset.owner}) can make changes to the filter. Save it as new
                          preset instead.
                        </Typography>
                      </Grid>
                    ) : (
                      <Grid item xs={12} style={{ marginTop: 20 }}>
                        <Typography>
                          The Filter is visible for you, because you created it. Only you can make changes to the
                          filter.
                        </Typography>
                      </Grid>
                    )}
                  </>
                )}
              </>
            )}
          </Grid>
        </ConfirmationDialog>
      </MainContent>
    </SplitLayout>
  );
};

function mapStateToProps({
  moduleRegistrationExport,
  auth,
  semesterSelection,
  moduleSelection,
  application,
}: IRootState) {
  const allowedUas = auth.allowedUas ?? [];
  const selectableSemesters = semesterSelection.semesterFilter.semesters ?? [];

  const columnSelection =
    moduleRegistrationExport.selectableColumns?.filter((c) =>
      moduleRegistrationExport.filterSelection?.columnNames?.includes(c.propertyName),
    ) ?? [];
  const selectableModuleCategories = moduleSelection.moduleCategories ?? [];
  const selectableModules = moduleRegistrationExport.filterSelection?.moduleCategoryId
    ? moduleSelection.modules?.filter(
        (m) => m.categoryId === moduleRegistrationExport.filterSelection?.moduleCategoryId,
      )
    : moduleSelection.modules ?? [];

  columnSelection.sort((c1, c2) => (c1.columnNumber > c2.columnNumber ? 1 : -1));

  const selectedSemesters = selectableSemesters.filter((s) =>
    moduleRegistrationExport.filterSelection?.semesterIds?.includes(s.id),
  );

  const selectablePhases = selectedSemesters.length === 1 ? selectedSemesters[0].phases : [];
  const selectedPhase = selectablePhases.find((p) => moduleRegistrationExport.filterSelection?.phaseId === p.id);

  return {
    roles: application.roles,
    loading:
      moduleRegistrationExport.loading ||
      moduleRegistrationExport.previewLoading ||
      moduleRegistrationExport.presetsLoading ||
      moduleRegistrationExport.staticFiltersLoading ||
      moduleRegistrationExport.moduleFiltersLoading ||
      moduleRegistrationExport.fileImportFiltersLoading ||
      moduleRegistrationExport.advisorSearchLoading ||
      moduleRegistrationExport.studentSearchLoading,
    exportLoading: moduleRegistrationExport.loading,
    presets: moduleRegistrationExport.presets,
    selectedPreset: moduleRegistrationExport.selectedPreset,
    presetsLoading: moduleRegistrationExport.presetsLoading,
    staticFiltersLoading: moduleRegistrationExport.staticFiltersLoading,
    moduleFiltersLoading: moduleRegistrationExport.moduleFiltersLoading,
    fileImportFiltersLoading: moduleRegistrationExport.fileImportFiltersLoading,
    filterSelection: moduleRegistrationExport.filterSelection,
    allowedUas,
    uasSelection: allowedUas.filter((u) => moduleRegistrationExport.filterSelection?.uasIds?.includes(u.id)) ?? [],
    selectableSemesters,
    selectedSemesters,
    selectablePhases,
    selectedPhase,
    selectableColumns: moduleRegistrationExport.selectableColumns,
    columnSelection,
    selectableStates: moduleRegistrationExport.selectableStates,
    stateSelection:
      moduleRegistrationExport.selectableStates?.filter((s) =>
        moduleRegistrationExport.filterSelection?.states?.includes(s),
      ) ?? [],
    selectableLabels: moduleRegistrationExport.selectableLabels ?? [],
    labelSelection:
      moduleRegistrationExport.selectableLabels?.filter((l) =>
        moduleRegistrationExport.filterSelection?.labelIds?.includes(l.id),
      ) ?? [],
    selectableProfiles: moduleRegistrationExport.selectableProfiles,
    profileSelection:
      moduleRegistrationExport.selectableProfiles?.filter((p) =>
        moduleRegistrationExport.filterSelection?.profileIds?.includes(p.id),
      ) ?? [],
    selectableModuleCategories,
    selectedModuleCategory:
      selectableModuleCategories?.find((c) => moduleRegistrationExport.filterSelection?.moduleCategoryId === c.id) ??
      null,
    selectableModules,
    selectedModule: selectableModules?.find((m) => moduleRegistrationExport.filterSelection?.moduleId === m.id) ?? null,
    selectableModuleExecutionCodes: moduleRegistrationExport.selectableModuleExecutionCodes,
    selectedModuleExecutionCode: moduleRegistrationExport.filterSelection?.moduleExecutionCode,
    selectableLocations: moduleRegistrationExport.selectableLocations,
    locationSelection:
      moduleRegistrationExport.selectableLocations?.filter((l) =>
        moduleRegistrationExport.filterSelection?.locationIds?.includes(l.id),
      ) ?? [],
    selectedStudentTypes: studentTypes.filter((t) =>
      moduleRegistrationExport.filterSelection?.studentTypes?.includes(t.key),
    ),
    selectableFileImports: moduleRegistrationExport.selectableFileImports,
    selectedImport: moduleRegistrationExport.filterSelection.fileImport,
    preview: moduleRegistrationExport.preview,
    previewLoading: moduleRegistrationExport.previewLoading,
    previewLoaded: moduleRegistrationExport.previewLoaded,
    studentSearchResult: moduleRegistrationExport.studentSearchResult,
    studentSearchLoading: moduleRegistrationExport.studentSearchLoading,
    selectedStudent: moduleRegistrationExport.filterSelection.student,
    advisorSearchResult: moduleRegistrationExport.advisorSearchResult,
    advisorSearchLoading: moduleRegistrationExport.advisorSearchLoading,
    selectedAdvisor: moduleRegistrationExport.filterSelection.advisor,
    hasModuleFilter:
      moduleRegistrationExport.filterSelection?.moduleCategoryId > 0 ||
      moduleRegistrationExport.filterSelection?.moduleId > 0 ||
      !!moduleRegistrationExport.filterSelection?.moduleExecutionCode ||
      moduleRegistrationExport.filterSelection?.locationIds?.length > 0,
    hasStateFilter:
      moduleRegistrationExport.filterSelection?.fileImport?.id > 0 ||
      moduleRegistrationExport.filterSelection?.states?.length > 0 ||
      moduleRegistrationExport.filterSelection?.stateAt ||
      moduleRegistrationExport.filterSelection?.newerThen ||
      moduleRegistrationExport.filterSelection?.phaseId,
    hasAdditionalInfoFilter:
      moduleRegistrationExport.filterSelection?.profileIds?.length > 0 ||
      moduleRegistrationExport.filterSelection?.hasCompensationForDisadvantages ||
      moduleRegistrationExport.filterSelection?.hasComment ||
      moduleRegistrationExport.filterSelection?.hasInternalComment ||
      moduleRegistrationExport.filterSelection?.hasNoUasEmail,
    advisorSearchLoaded: moduleRegistrationExport.advisorSearchLoaded,
    studentSearchLoaded: moduleRegistrationExport.studentSearchLoaded,
    isAdmin: hasAnyRole(auth.account?.roles, [RoleType.REGISTRATIONADMIN]),
    currentUser: auth.account,
  };
}

const mapDispatchToProps = (dispatch) => {
  const actions = moduleRegistrationExportModule.initActions(dispatch);
  return actions;
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(RegistrationExport);
