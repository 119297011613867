import * as Sentry from '@sentry/react';
import config from '../config/constants';

const configureLogging = () => {
  const isDev = process.env.NODE_ENV === 'development';

  const loggerConfig = {
    dsn: !isDev ? 'https://da5d1da8173d412591a56f24596d9fed@o509322.ingest.sentry.io/5785961' : '',
    integrations: [],
    tracesSampleRate: 1.0,
    debug: isDev,
    environment: process.env.NODE_ENV,
    release: 'mse-reg@' + config.VERSION,
    maxBreadcrumbs: 50,
    attachStackTrace: true,
    beforeSend(event, hint) {
      if (isDev) {
        console.error(hint.originalException || hint.syntheticException);
        return null;
      }
      return event;
    },
  };

  Sentry.init(loggerConfig);
};

export default configureLogging;
