import initActions from './actions';
import types from './types';
import reducer from './reducer';

import { IAccountClient, IAdvisorClient, IUasClient, IUasDomainClient } from 'app/clients/services';
import { resolver } from 'app/clients/service-registry';
import advisorSelectionModule from '../../selections/advisor-selection';
import authModule from '../../login/auth';

export type { UasManagementState } from './reducer';
export default {
  initActions(dispatch: any) {
    const authActions = authModule.initActions();
    return initActions(
      dispatch,
      resolver.resolve<IUasClient>('IUasClient'),
      resolver.resolve<IUasDomainClient>('IUasDomainClient'),
      resolver.resolve<IAdvisorClient>('IAdvisorClient'),
      resolver.resolve<IAccountClient>('IAccountClient'),
      advisorSelectionModule.initActions(dispatch),
      async () => await dispatch(authActions.refreshAllowedUasAsync())
    );
  },
  types,
  reducer,
};
