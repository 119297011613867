import { Alert, AlertTitle } from '@mui/material';
import React from 'react';
import { TextContent } from '../layout/ui-elements/component-layout';

const PageNotFound = () => {
  return (
    <TextContent>
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        The page does not exist.
      </Alert>
    </TextContent>
  );
};

export default PageNotFound;
