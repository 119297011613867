import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from './config/root.reducer';
import { NotificationObject, removeNotification } from './modules/notification';

let displayed: string[] = [];

export const Notifier = () => {
  const dispatch = useDispatch();
  const notifications = useSelector<IRootState>(
    (store) => store.notification.notifications || [],
  ) as NotificationObject[];
  const snackbar = useSnackbar();

  const storeDisplayed = (id) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id) => {
    displayed = [...displayed.filter((key) => id !== key)];
  };

  React.useEffect(() => {
    notifications.map((n) => {
      const key = n.options.key;
      if (n.dismissed) {
        snackbar.closeSnackbar(key);
        return;
      }

      if (displayed.includes(key)) {
        return;
      }

      snackbar.enqueueSnackbar(n.message, {
        ...n.options,
        key,
        action: (myKey) => (
          <Button onClick={() => snackbar.closeSnackbar(myKey)}>
            <CloseIcon style={{ fill: '#ffffff' }} />
          </Button>
        ),
        onClose(event, reason) {
          if (n.options.onClose && event) {
            n.options.onClose(event, reason);
          }
        },
        onExited() {
          dispatch(removeNotification(key));
          removeDisplayed(key);
        },
      });

      storeDisplayed(key);
    });
  }, [notifications, snackbar.closeSnackbar, snackbar.enqueueSnackbar, dispatch]);

  return <></>;
};
