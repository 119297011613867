import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { RoleType } from 'app/clients/services';
import Guarded from 'app/shared/auth/guarded-component';
import FileUpload from './file-upload';
import ImportRequests from './import-requests';
import Imports from './imports';
import RegistrationImportTabs from './registration-import-tabs';

const RegistrationImportRoutes = ({ baseUrl }) => (
  <>
    <RegistrationImportTabs baseUrl={baseUrl}></RegistrationImportTabs>
    <Routes>
      <Route path={`file-upload`} element={<FileUpload />} />
      <Route
        path={`requests/:importId?`}
        element={
          <Guarded requiresRole={[RoleType.ADMIN, RoleType.REGISTRATIONADMIN]}>
            <ImportRequests />
          </Guarded>
        }
      />
      <Route
        path={`list/:importId?`}
        element={
          <Guarded requiresRole={[RoleType.ADMIN, RoleType.REGISTRATIONADMIN, RoleType.MASTEROFFICE]}>
            <Imports />
          </Guarded>
        }
      />
    </Routes>
  </>
);

export default RegistrationImportRoutes;
