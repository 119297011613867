import { IApplicationProfileClient, IApplicationProfileDto, IRoleSelectionClient } from 'app/clients/services';
import { ITabModel } from 'app/shared/layout/ui-elements/navigation-tabs';
import { AsyncThunk } from 'app/shared/util/action-type.util';
import ACTION_TYPES from './types';

export const addApplicationError = (error: string) => ({
  type: ACTION_TYPES.ADD_APPLICATION_ERROR,
  payload: error,
});

export const clearApplicationError = () => ({
  type: ACTION_TYPES.CLEAR_APPLICATION_ERROR,
});

export const rememberOpenTab = (componentRoute: string, selectedTab: ITabModel | undefined) => ({
  type: ACTION_TYPES.REMEMBER_OPEN_TAB,
  payload: {
    componentRoute,
    selectedTab,
  },
});

const initActions = (profileInfoClient: IApplicationProfileClient, roleSelectionClient: IRoleSelectionClient) => {
  const getProfileAsync: () => AsyncThunk<IApplicationProfileDto | null> = () => async (dispatch) => {
    const result = await dispatch({
      type: ACTION_TYPES.GET_PROFILE,
      payload: profileInfoClient.getApplicationProfile(),
    });
    return result.value;
  };

  const getRolesAsync: () => AsyncThunk = () => async (dispatch) => {
    await dispatch({
      type: ACTION_TYPES.GET_ROLES,
      payload: roleSelectionClient.getRoles(),
    });
  };

  return Object.freeze({
    addApplicationError,
    clearApplicationError,
    getProfileAsync,
    getRolesAsync,
  });
};

export default initActions;

export type ApplicaitonActions = ReturnType<typeof initActions>;
