import { Link } from '@mui/material';
import React from 'react';

const Footer = () => (
  <div style={{ height: 50, marginTop: 10 }}>
    <div style={{ width: '100%', textAlign: 'center', fontSize: '10pt', marginTop: '20px' }}>
      © {new Date(process.env.BUILD_DATE).getFullYear()} by{' '}
      <Link href="https://www.msengineering.ch/" target="_blank" style={{ textDecoration: 'none', color: '#999999' }}>
        MSEngineering
      </Link>
    </div>
  </div>
);

export default Footer;
