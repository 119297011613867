export default {
  LOAD_PAGE: 'moduleRegistrations/LOAD_PAGE',
  FETCH_CAPACITIES: 'moduleRegistrations/FETCH_CAPACITIES',
  FETCH_MODULE_REGISTRATIONS: 'moduleRegistrations/FETCH_MODULE_REGISTRATIONS',
  SET_REQUESTED_SELECTED: 'moduleRegistrations/SET_REQUESTED_SELECTED',
  SET_CONFIRMED_SELECTED: 'moduleRegistrations/SET_CONFIRMED_SELECTED',
  SET_REJECTED_SELECTED: 'moduleRegistrations/SET_REJECTED_SELECTED',
  SET_WITHDRAWN_SELECTED: 'moduleRegistrations/SET_WITHDRAWN_SELECTED',
  SET_ABSENTEX_SELECTED: 'moduleRegistrations/SET_ABSENTEX_SELECTED',
  SET_OTHERS_SELECTED: 'moduleRegistrations/SET_OTHERS_SELECTED',
  SET_MAIN_CONTENT_LOADING: 'moduleRegistrations/SET_MAIN_CONTENT_LOADING',
  CHANGE_STATES: 'moduleRegistrations/CHANGE_STATES',
  LOAD_DATA: 'moduleRegistrations/LOAD_DATA',
  ADD_REGISTRATION_COMMENT: 'moduleRegistrations/ADD_REGISTRATION_COMMENT',
  REMOVE_REGISTRATION_COMMENT: 'moduleRegistrations/REMOVE_REGISTRATION_COMMENT',
};
