import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import CreateIcon from '@mui/icons-material/Create';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import {
  ICommentDto,
  IModuleRegistrationImport,
  IStudentImport,
  IValueChange,
  ModuleRegistrationImport,
  RegistrationState,
  ValidationLevel,
  ValueChangeOfRegistrationState,
  ValueChangeType,
} from 'app/clients/services';
import { APP_DATE_FORMAT } from 'app/config/constants';
import { getLevelClass, isHigherOrEqual } from 'app/shared/util/validation-level.util';
import React, { useState } from 'react';
import { ImportRegistrationInfoDialog } from './import-registration-info-dialog';
import { ValueChangeForm } from './value-change-form';

type RegistrationImportPreviewProps = {
  onRemoveComment: (comment: ICommentDto) => Promise<void>;
  onAddComment: (comment: ICommentDto) => Promise<void>;
  registration: ModuleRegistrationImport;
  onConfirm: (newImport: IModuleRegistrationImport) => void | Promise<void>;
  disabled: boolean;
  isLoading: boolean;
  levelFilter: ValidationLevel;
  hasOpenDialog?: boolean;
  studentImport: IStudentImport;
  noRights: boolean;
  closeRegistrationDialog?: () => void;
  refreshRegistrationInfo?: (moduleRegistrationId?: number | undefined) => Promise<void>;
};

type RegistrationStateChangeProps = {
  valueChange: ValueChangeOfRegistrationState;
  isDeregistration: boolean;
  moduleCode: string;
  noRights: boolean;
  registrationId: number | undefined;
};

const RegistrationStateChange = (props: RegistrationStateChangeProps) => {
  if (props.noRights) {
    return props.isDeregistration ? <RemoveCircleOutlineIcon fontSize="small" /> : <DoneOutlineIcon fontSize="small" />;
  }

  if (!props.valueChange) {
    return (
      <Tooltip
        title={
          <Typography>
            No change! {props.moduleCode} is already {props.isDeregistration ? 'unregistered' : 'registered'}.
          </Typography>
        }
      >
        <DoneOutlineIcon fontSize="small" />
      </Tooltip>
    );
  }

  if (props.valueChange.confirmationType === ValueChangeType.Choice) {
    return (
      <Tooltip title={<Typography>{props.valueChange.conflictMessage}</Typography>}>
        <BrokenImageIcon fontSize="small" />
      </Tooltip>
    );
  }

  const state =
    props.valueChange.confirmationType === ValueChangeType.TakeNewValue
      ? props.valueChange.newValue
      : props.valueChange.oldValue;

  if (state === RegistrationState.NOT_REGISTERED) {
    const message = !props.valueChange.isUserConfirmed
      ? props.valueChange.conflictMessage
      : props.valueChange.confirmationComment ?? 'Ignored deregistration of unregistered registration.';
    return (
      <Tooltip title={<Typography>{message}</Typography>}>
        <BrokenImageIcon fontSize="small" />
      </Tooltip>
    );
  }

  if (state === props.valueChange.oldValue) {
    return (
      <Tooltip
        title={
          <Typography>
            No change! {props.moduleCode} is already {props.isDeregistration ? 'unregistered' : 'registered'}.
          </Typography>
        }
      >
        <DoneOutlineIcon fontSize="small" />
      </Tooltip>
    );
  }

  if (state === RegistrationState.REQUESTED) {
    if (props.valueChange.oldValue === RegistrationState.NOT_REGISTERED) {
      return (
        <Tooltip
          title={<Typography>{props.valueChange.confirmationComment ?? props.valueChange.conflictMessage}</Typography>}
        >
          <FiberNewIcon fontSize="small" />
        </Tooltip>
      );
    }
    return (
      <Tooltip
        title={<Typography>{props.valueChange.confirmationComment ?? props.valueChange.conflictMessage}</Typography>}
      >
        <CreateIcon fontSize="small" />
      </Tooltip>
    );
  }

  if (state === RegistrationState.WITHDRAWN) {
    return (
      <Tooltip
        title={<Typography>{props.valueChange.confirmationComment ?? props.valueChange.conflictMessage}</Typography>}
      >
        <RemoveCircleOutlineIcon fontSize="small" />
      </Tooltip>
    );
  }

  return (
    <Tooltip
      title={<Typography>{props.valueChange.confirmationComment ?? props.valueChange.conflictMessage}</Typography>}
    >
      <DoneOutlineIcon fontSize="small" />
    </Tooltip>
  );
};

const getOpacity = (valueChange: ValueChangeOfRegistrationState, noRights: boolean) => {
  if (noRights) {
    return 1;
  }

  if (!valueChange) {
    return 0.5;
  }

  if (valueChange.confirmationType === ValueChangeType.Choice) {
    return 1;
  }

  const state =
    valueChange.confirmationType === ValueChangeType.TakeNewValue ? valueChange.newValue : valueChange.oldValue;

  if (state === RegistrationState.NOT_REGISTERED) {
    return 0.5;
  }

  if (state === valueChange.oldValue) {
    return 0.5;
  }

  return 1;
};

const getRegistrationClass = (registration: ModuleRegistrationImport, noRights: boolean) => {
  if (noRights) {
    if (registration.isDeregistration) {
      return getLevelClass(ValidationLevel.Error);
    }
    return getLevelClass(ValidationLevel.Success);
  }

  if (registration.registrationStateChange) {
    const stateChange = registration.registrationStateChange;
    const state =
      stateChange.confirmationType === ValueChangeType.TakeNewValue ? stateChange.newValue : stateChange.oldValue;

    if (stateChange.confirmationType === ValueChangeType.Choice) {
      return getLevelClass(ValidationLevel.Warning);
    }

    if (
      state === RegistrationState.WITHDRAWN ||
      state === RegistrationState.CANCELED ||
      state === RegistrationState.NOT_REGISTERED
    ) {
      return getLevelClass(ValidationLevel.Error);
    }

    if (state === RegistrationState.REQUESTED) {
      return getLevelClass(ValidationLevel.Info);
    }
  }

  if (registration.isDeregistration) {
    return getLevelClass(ValidationLevel.Error);
  }
  return getLevelClass(ValidationLevel.Success);
};

export const RegistrationImportPreview = (props: RegistrationImportPreviewProps) => {
  const [infoOpen, setInfoOpen] = useState(false);
  return (
    <>
      <TableRow
        className={getRegistrationClass(props.registration, props.noRights)}
        style={{ opacity: getOpacity(props.registration.registrationStateChange, props.noRights) }}
      >
        <TableCell>
          <div
            onClick={async () => {
              if (props.registration.databaseId) {
                await props.refreshRegistrationInfo(0);
                setInfoOpen(true);
                await props.refreshRegistrationInfo(props.registration.databaseId);
              }
            }}
          >
            <RegistrationStateChange
              noRights={props.noRights}
              valueChange={props.registration.registrationStateChange}
              moduleCode={props.registration.moduleExecutionCode}
              isDeregistration={props.registration.isDeregistration}
              registrationId={props.registration.databaseId}
            />
          </div>

          <ImportRegistrationInfoDialog
            registrationImport={props.registration}
            isOpen={infoOpen}
            close={() => {
              setInfoOpen(false);
              props.refreshRegistrationInfo(0);
            }}
            studentImport={props.studentImport}
            onAddComment={props.onAddComment}
            onRemoveComment={props.onRemoveComment}
            refreshRegistrationInfo={async (moduleRegistrationId: number | undefined) => {
              await props.refreshRegistrationInfo(moduleRegistrationId);
            }}
          />
        </TableCell>
        <TableCell>{props.registration.rowNumber}</TableCell>
        <TableCell>{props.registration.dateOfRegistration.toFormat(APP_DATE_FORMAT)}</TableCell>
        <TableCell>{props.registration.moduleExecutionCode}</TableCell>
      </TableRow>
      {props.registration.registrationStateChange &&
        props.registration.registrationStateChange.solutions &&
        props.registration.registrationStateChange.solutions.length > 0 &&
        isHigherOrEqual(props.registration.registrationStateChange.validationLevel, props.levelFilter) && (
          <TableRow>
            <TableCell colSpan={4}>
              <ValueChangeForm
                id={props.registration.rowNumber + '-change'}
                change={props.registration.registrationStateChange}
                isLoading={props.isLoading}
                disabled={props.disabled}
                filter={props.levelFilter}
                onConfirm={(change: IValueChange) => {
                  const newStudent = new ModuleRegistrationImport({
                    ...props.registration,
                    registrationStateChange: new ValueChangeOfRegistrationState({
                      ...props.registration.registrationStateChange,
                      ...change,
                    }),
                  });
                  props.onConfirm(newStudent);
                }}
                title="Registration State Change"
              ></ValueChangeForm>
            </TableCell>
          </TableRow>
        )}
    </>
  );
};
