import React, { useLayoutEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { Alert, AlertTitle } from '@mui/material';
import { IRootState } from 'app/config/root.reducer';
import authModule from 'app/modules/login/auth/index';
import { TextContent } from 'app/shared/layout/ui-elements/component-layout';

export type Props = PropsFromRedux & { idToken: string; logoutUrl: string };

export const Logout = (props: Props) => {
  useLayoutEffect(() => {
    const loadInitialData = async () => {
      if (props.isLoggedIn) {
        const logoutUrl = await props.logoutAsync();
        if (logoutUrl) {
          window.location.href = logoutUrl.includes('/protocol')
            ? logoutUrl + '?redirect_uri=' + window.location.origin
            : logoutUrl + '?id_token_hint=' + props.idToken + '&post_logout_redirect_uri=' + window.location.origin;
        }
      }
    };

    loadInitialData();
  });

  return (
    <TextContent>
      <Alert color="success">
        <AlertTitle>Bye Bye! :(</AlertTitle>
        Hope to see you back here soon! ;)
      </Alert>
    </TextContent>
  );
};

const mapStateToProps = ({ auth }: IRootState) => ({
  isLoggedIn: auth.isLoggedIn,
  logoutUrl: auth.logoutUrl,
  idToken: auth.idToken,
});

const mapDispatchToProps = (dispatch) => {
  const authActions = authModule.initActions();
  return {
    logoutAsync: async () => await dispatch(authActions.logoutAsync()),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Logout);
