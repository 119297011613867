import initActions from './actions';
import types from './types';
import reducer from './reducer';
import { IRegistrationClient, IRegistrationEditClient, IRegistrationExportClient } from 'app/clients/services';
import semesterSelectionModule from '../../selections/semester-selection';
import { resolver } from 'app/clients/service-registry';

export type { UasRegistrationState } from './reducer';
export default {
  initActions: dispatch =>
    initActions(
      dispatch,
      resolver.resolve<IRegistrationClient>('IRegistrationClient'),
      resolver.resolve<IRegistrationExportClient>('IRegistrationExportClient'),
      resolver.resolve<IRegistrationEditClient>('IRegistrationEditClient'),
      semesterSelectionModule.initActions()
    ),
  types,
  reducer,
};
