import { PriorityDto } from 'app/clients/services';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/util/action-type.util';
import { insertItem, removeItem, updateItem } from 'app/shared/util/immutable.util';
import ACTION_TYPES from './types';

// State
const initialState = {
  loading: false,
  errorMessage: null as string,
  priorities: [] as PriorityDto[],
  selectedPriority: null as PriorityDto,
};

export type PriorityManagementState = Readonly<typeof initialState>;

// Reducer
export default (state: PriorityManagementState = initialState, action): PriorityManagementState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_PRIORITIES):
      return {
        ...state,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_PRIORITIES):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_PRIORITIES): {
      return {
        ...state,
        loading: false,
        priorities: action.payload,
      };
    }
    case REQUEST(ACTION_TYPES.ADD_PRIORITY):
      return {
        ...state,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.ADD_PRIORITY):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.ADD_PRIORITY): {
      return {
        ...state,
        loading: false,
        priorities: state.priorities.map((p) => (p.id === 0 ? action.payload : p)),
      };
    }
    case REQUEST(ACTION_TYPES.UPDATE_PRIORITY):
      return {
        ...state,
        loading: true,
        priorities: state.priorities.map((p) => (p.id === 0 ? action.payload : p)),
      };
    case FAILURE(ACTION_TYPES.UPDATE_PRIORITY):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.UPDATE_PRIORITY): {
      return {
        ...state,
        loading: false,
      };
    }
    case REQUEST(ACTION_TYPES.DELETE_PRIORITY):
      return {
        ...state,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.DELETE_PRIORITY):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.DELETE_PRIORITY): {
      const priorities = removeItem(state.priorities, action.payload);
      return {
        ...state,
        priorities,
        loading: false,
      };
    }
    case ACTION_TYPES.MEMORIZE_CHANGES: {
      const priorities = updateItem(state.priorities, action.payload);
      return {
        ...state,
        priorities,
      };
    }
    case ACTION_TYPES.CREATE_NEW_PRIORITY: {
      const priorities = insertItem(state.priorities, action.payload);
      return {
        ...state,
        priorities,
      };
    }
    case ACTION_TYPES.SELECT_PRIORITY: {
      if (state.selectedPriority?.id === action.payload) {
        return state;
      }
      const priority = state.priorities.find((p) => p.id === action.payload);
      return {
        ...state,
        selectedPriority: priority,
      };
    }
    default:
      return state;
  }
};
