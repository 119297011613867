import { Grid, Typography } from '@mui/material';
import { IAdvisorDto } from 'app/clients/services';
import React from 'react';
import { ChipList } from '../ui-elements/chip-list';

type Props = {
  advisor: IAdvisorDto;
};

export const AdvisorPreview = (props: Props) => {
  return (
    <Grid container>
      <Grid item xs={12} md={3}>
        <Typography>
          First Name: <strong>{props.advisor.firstName}</strong>
          <br />
          Family Name: <strong>{props.advisor.familyName}</strong>
          <br />
        </Typography>
      </Grid>
      <Grid item xs={12} md={3}>
        <Typography>
          Emails:
          <br />
          {props.advisor.emails &&
            props.advisor.emails.map((e, k) => {
              return (
                <span key={k}>
                  {e.name} {e.isPrivate && <>(private)</>}
                  <br />
                </span>
              );
            })}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <ChipList items={props.advisor.uas ?? []} getItemTitle={(u) => u.code} />
      </Grid>
    </Grid>
  );
};
