import React, { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { Box, Button, LinearProgress, Paper, Step, StepContent, StepLabel, Stepper, Typography } from '@mui/material';
import { ICommentDto, ISemesterDto, ISemesterInfoDto, RegistrationImport, RoleType } from 'app/clients/services';
import { IRootState } from 'app/config/root.reducer';
import semesterSelectionModule from 'app/modules/selections/semester-selection';
import { hasAnyRole } from 'app/shared/auth/roles';
import { DefaultButton } from 'app/shared/layout/ui-elements/buttons';
import { TextContent } from 'app/shared/layout/ui-elements/component-layout';
import { SelectBox } from 'app/shared/layout/ui-elements/select-box';
import { isHigherOrEqual } from 'app/shared/util/validation-level.util';
import { ValidationLevel } from '../../clients/services';
import fileUploadModule from './file-upload/index';
import importRequestsModule from './import-requests/index';
import importAdminModule from './imports/index';
import { ImportPreview } from './parts/import-preview/import-preview';
import ValidationMessage from './parts/validation-message';

export type Props = PropsFromRedux;

export const FileUpload = (props: Props) => {
  const [selectedSemester, setSelectedSemester] = useState<ISemesterDto>(
    props.semesters && props.semesters.length > 0 ? props.semesters[0] : null,
  );

  const handleBackToFileSelection = () => {
    props.resetFileSelection();
    props.goToStep(1);
  };

  const handleBackToSemesterSelection = () => {
    props.resetFileSelection();
    props.goToStep(0);
  };

  const handleSubmit = async () => {
    await props.submitAsync(props.validationResult?.registrationImport, props.file);
    if (props.isRegistrationAdmin) {
      await props.countPendingImportsAsync();
    }
  };

  const handleSemesterChange = (newSemester: ISemesterDto) => {
    setSelectedSemester(newSemester);
  };

  return (
    <TextContent>
      <Stepper activeStep={props.activeStep} orientation="vertical">
        <Step key={1}>
          <StepLabel>Select the semester</StepLabel>
          <StepContent>
            <Typography sx={{ paddingLeft: 1 }}>
              Please choose the semester you want to import registrations for.
            </Typography>
            <Box sx={{ width: 400 }}>
              <SelectBox
                items={props.semesters ?? []}
                selectedItem={selectedSemester}
                onChange={handleSemesterChange}
                title="Semester"
                getItemKey={(semester: ISemesterDto) => semester.id}
                getItemTitle={(semester: ISemesterDto) => semester.nameLong}
              />
            </Box>
            <Box sx={{ marginTop: 2 }}>
              <DefaultButton onClick={props.reset} disabled={props.loading}>
                Reset
              </DefaultButton>
              <DefaultButton onClick={props.nextStep} disabled={!selectedSemester?.id || props.loading} primary>
                Next Step
              </DefaultButton>
            </Box>
          </StepContent>
        </Step>
        <Step key={2}>
          <StepLabel>Choose the excel-file</StepLabel>
          <StepContent>
            <Typography sx={{ marginBottom: 2 }}>
              Please choose the registration excel-file from your computer, that you want to upload or validate.
            </Typography>

            <Box style={{ marginBottom: 20 }}>
              <Button color={props.file ? 'inherit' : 'primary'} variant="contained" component="label">
                Choose File
                <input type="file" onChange={(event) => props.selectFile(event.target.files[0])} hidden />
              </Button>
              {props.file && <span style={{ paddingLeft: 20 }}>{props.file.name}</span>}
            </Box>
            <DefaultButton onClick={handleBackToSemesterSelection}>Back</DefaultButton>
            <DefaultButton
              disabled={!props.file || props.loading}
              onClick={() => props.validateAsync(props.activeStep, selectedSemester?.id, props.file)}
              primary
            >
              Validate
            </DefaultButton>
            {props.validationResult &&
              !props.validationResult.fileIsReadable &&
              props.validationResult.fileResultMessage && (
                <Paper style={{ marginTop: 20, marginBottom: 20 }}>
                  <ValidationMessage
                    message={props.validationResult.fileResultMessage}
                    hideTitle
                    openInfo
                  ></ValidationMessage>
                </Paper>
              )}
          </StepContent>
        </Step>
        <Step key={3}>
          <StepLabel>Review the validation results</StepLabel>
          <StepContent>
            <div>
              <div>
                {props.validationResult && (
                  <>
                    {props.validationResult && props.validationResult.fileResultMessage && (
                      <Paper style={{ marginTop: 20, marginBottom: 20 }}>
                        <ValidationMessage
                          message={props.validationResult.fileResultMessage}
                          hideTitle
                          columnInfo={props.validationResult.columnInformation}
                          registrations={props.validationResult.resultedSheet}
                        ></ValidationMessage>
                      </Paper>
                    )}
                    <ImportPreview
                      isLoading={props.loading}
                      onUpdate={(registrationImport) => {
                        props.updateRegistrationImport(registrationImport);
                        props.reduceWarningCount();
                      }}
                      uasImport={props.validationResult.registrationImport}
                      fileImport={null}
                      defaultLevelFilter={ValidationLevel.Info}
                      defaultChangeFilter={['unconfirmed']}
                      validation={props.validationResult}
                      refreshRegistrationInfo={props.loadModuleRegistrationAsync}
                      onRemoveComment={props.removeCommentAsync}
                      onAddComment={props.addCommentAsync}
                    />
                  </>
                )}
                <DefaultButton
                  primary={!props.canSubmit && !props.needsCorrections}
                  onClick={handleBackToFileSelection}
                >
                  Back
                </DefaultButton>
                {props.needsCorrections && (
                  <DefaultButton
                    primary
                    onClick={async () =>
                      await props.revalidateAsync(props.activeStep, props.validationResult?.registrationImport)
                    }
                    disabled={!props.validationResult?.registrationImport || props.loading}
                  >
                    Validate with corrections
                  </DefaultButton>
                )}

                <DefaultButton
                  primary
                  onClick={props.nextStep}
                  disabled={!props.canSubmit || !props.isAuthenticated || props.loading || props.needsCorrections}
                >
                  Next Step
                </DefaultButton>
              </div>
            </div>
          </StepContent>
        </Step>
        <Step key={4}>
          <StepLabel>Submit the registration</StepLabel>
          <StepContent>
            <Paper square elevation={0} /* className={classes.resetContainer} */>
              <Typography>Please press on the submit button to send the registration to mse.ch.</Typography>
              <div style={{ marginTop: 20 }}>
                <DefaultButton onClick={props.lastStep}>Back</DefaultButton>
                <DefaultButton
                  primary
                  onClick={handleSubmit}
                  disabled={!props.canSubmit || !props.isAuthenticated || props.loading}
                >
                  Submit
                </DefaultButton>
              </div>
            </Paper>
          </StepContent>
        </Step>
        <Step key={5}>
          <StepLabel>Done</StepLabel>
          <StepContent>
            <Paper square elevation={0} /* className={classes.resetContainer} */>
              <Typography>Thank you for submitting your registrations!</Typography>
              <Box sx={{ marginTop: '20px' }}>
                <DefaultButton onClick={props.reset} primary disabled={props.loading}>
                  OK
                </DefaultButton>
              </Box>
            </Paper>
          </StepContent>
        </Step>
      </Stepper>
      {props.loading && <LinearProgress style={{ width: '100%' }} />}
    </TextContent>
  );
};

const mapStateToProps = ({ registrationImport, auth, semesterSelection, importsManagement }: IRootState) => ({
  isAuthenticated: auth.isLoggedIn && auth.account?.isAuthenticated,
  selectedRegistration: importsManagement.selectedRegistration,
  isRegistrationAdmin: hasAnyRole(auth.account?.roles, [RoleType.REGISTRATIONADMIN]),
  file: registrationImport.file,
  loading: registrationImport.loading,
  activeStep: registrationImport.activeStep,
  validationResult: registrationImport.validationResult,
  semesters: semesterSelection.openSemesters,
  uas: registrationImport.uas,
  selectedUasId: registrationImport.selectedUasId,
  canSubmit: registrationImport.validationResult?.canSubmit ?? false,
  needsCorrections:
    registrationImport.validationResult?.registrationImport &&
    isHigherOrEqual(registrationImport.validationResult?.registrationImport?.validationLevel, ValidationLevel.Warning),
});

const mapDispatchToProps = (dispatch) => {
  const fileUploadActions = fileUploadModule.initActions();
  const importAdminActions = importAdminModule.initActions();
  const selectionActions = semesterSelectionModule.initActions();
  const imortRequestsModule = importRequestsModule.initActions();
  return {
    resetFileSelection: () => dispatch(fileUploadActions.resetFileSelection()),
    loadModuleRegistrationAsync: (id: number) => dispatch(importAdminActions.loadModuleRegistrationAsync(id)),
    addCommentAsync: (comment: ICommentDto) => dispatch(importAdminActions.addCommentAsync(comment)),
    removeCommentAsync: (comment: ICommentDto) => dispatch(importAdminActions.removeCommentAsync(comment)),
    goToStep: (step: number) => dispatch(fileUploadActions.goToStep(step)),
    nextStep: () => dispatch(fileUploadActions.nextStep()),
    lastStep: () => dispatch(fileUploadActions.lastStep()),
    reset: () => dispatch(fileUploadActions.reset()),
    selectSemester: (semester: ISemesterInfoDto) => dispatch(selectionActions.selectSemester(semester)),
    selectFile: (file: File) => dispatch(fileUploadActions.selectFile(file)),
    validateAsync: async (currentStep: number, semesterId: number, file: File) =>
      await dispatch(fileUploadActions.validateAsync(currentStep, semesterId, file)),
    revalidateAsync: async (currentStep: number, registrationImport: RegistrationImport) =>
      await dispatch(fileUploadActions.revalidateAsync(currentStep, registrationImport)),
    submitAsync: async (input: RegistrationImport, file: File) =>
      await dispatch(fileUploadActions.submitAsync(input, file)),
    countPendingImportsAsync: async () => await dispatch(imortRequestsModule.countPendingImportsAsync()),
    updateRegistrationImport: (registrationImport) =>
      dispatch(fileUploadActions.updateRegistrationImport(registrationImport)),
    reduceWarningCount: () => dispatch(fileUploadActions.reduceWarningCount()),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(FileUpload);
