import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { Button, LinearProgress } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridColumnVisibilityModel,
  GridRowParams,
  GridRowSelectionModel,
  GridSlotsComponent,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import { APP_DATETIME_FORMAT, APP_DATE_FORMAT } from 'app/config/constants';
import { DateTime } from 'luxon';
import React, { useEffect } from 'react';

export interface IStandardDataGridProps {
  pageSize?: number;
  onSelectionModelChange?: (newSelection: GridRowSelectionModel) => void;
  loading: boolean;
  rows: any[];
  columns: GridColDef[];
  multiSelect?: boolean;
  selectionModel?: GridRowSelectionModel;
  columnVisibilityModel: GridColumnVisibilityModel;
  components?: GridSlotsComponent;
  toolbarContent?: React.ReactNode;
  excelExport?: () => Promise<void> | void;
  getRowClassName?: (params: GridRowParams) => string;
  hideColumnsToolbar?: boolean;
}

export const StandardDataGrid = (props: IStandardDataGridProps) => {
  const [selectionModel, setSelectionModel] = React.useState<GridRowSelectionModel | undefined>(
    props.selectionModel ?? undefined,
  );

  useEffect(() => {
    setSelectionModel(props.selectionModel);
  }, [props.selectionModel]);

  const DefaultToolbar = () => {
    return (
      <GridToolbarContainer>
        {!props.hideColumnsToolbar && <GridToolbarColumnsButton />}
        <GridToolbarFilterButton />
        {props.excelExport && (
          <Button color="primary" size={'small'} startIcon={<SaveAltIcon />} onClick={props.excelExport}>
            Excel Export
          </Button>
        )}
      </GridToolbarContainer>
    );
  };

  return (
    <DataGrid
      sx={{ backgroundColor: !props.loading ? '#fff' : 'none' }}
      autoHeight
      rows={props.rows ?? []}
      columns={props.columns}
      initialState={{
        pagination: { paginationModel: { pageSize: props.pageSize ? props.pageSize : 20 } },
      }}
      loading={props.loading}
      slots={{ toolbar: DefaultToolbar, loadingOverlay: LinearProgress, ...props.components }}
      onRowSelectionModelChange={(newSelection) => {
        setSelectionModel(newSelection);
        if (props.onSelectionModelChange) {
          props.onSelectionModelChange(newSelection);
        }
      }}
      rowSelectionModel={selectionModel}
      columnVisibilityModel={props.columnVisibilityModel}
      density={'compact'}
    />
  );
};

export const dateSorter = (v1: DateTime, v2: DateTime) => (!!v1 && !!v2 && v1 > v2 ? 1 : -1);
export const dateTimeSorter = (v1: DateTime, v2: DateTime) => (!!v1 && !!v2 && v1 > v2 ? 1 : -1);
export const dateFormatter = (params: GridValueFormatterParams) =>
  (params.value as DateTime)?.toFormat(APP_DATE_FORMAT);
export const dateTimeFormatter = (params: GridValueFormatterParams) =>
  (params.value as DateTime)?.toFormat(APP_DATETIME_FORMAT);
