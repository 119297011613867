/* eslint-disable complexity */
import {
  Alert,
  AlertTitle,
  Button,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { IValueChange, ValidationLevel, ValueChangeType } from 'app/clients/services';
import { APP_DATE_FORMAT } from 'app/config/constants';
import { getValidationLevelColor, isHigherOrEqual } from 'app/shared/util/validation-level.util';
import React, { useState } from 'react';

type ValueChangeProps = {
  filter: ValidationLevel;
  change?: IValueChange;
  onConfirm?: (valueChange: IValueChange) => void;
  disabled?: boolean;
  isLoading: boolean;
  id: string;
  title: string;
};

export const ValueChangeForm = (props: ValueChangeProps) => {
  const [comment, setComment] = useState(props.change?.confirmationComment ?? '');
  const [confirmationType, setConfirmationType] = useState(props.change?.confirmationType);

  const adjustConfirmationType = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    if (value === ValueChangeType.TakeNewValue.toString()) {
      setConfirmationType(ValueChangeType.TakeNewValue);
      return;
    }

    setConfirmationType(ValueChangeType.KeepOldValue);
  };

  const handleConfirm = () => {
    if (confirmationType === ValueChangeType.Choice) {
      return;
    }
    const newValueChange = {
      ...props.change,
      wasJustConfirmed: true,
      confirmationComment: comment,
      validationLevel: ValidationLevel.Info,
      confirmationType,
    };

    props.onConfirm(newValueChange);
  };

  if (
    !props.change ||
    !props.change.solutions ||
    props.change.solutions.length === 0 ||
    (!(props.change.wasJustConfirmed || props.change.isUserConfirmed) &&
      !isHigherOrEqual(props.change.validationLevel, props.filter))
  ) {
    return <></>;
  }

  return (
    <>
      <Paper>
        <Alert
          severity={getValidationLevelColor(
            props.change.wasJustConfirmed ? ValidationLevel.Info : props.change.validationLevel,
          )}
        >
          <AlertTitle>
            {props.title}:{' '}
            {props.change.code > 0 && (
              <>
                {props.change.wasJustConfirmed ? 'Confirmation' : props.change.validationLevel.toString()}{' '}
                {props.change.code}
              </>
            )}
          </AlertTitle>
          {props.change.conflictMessage ?? props.change.confirmationComment}
        </Alert>
        <div style={{ margin: 5 }}>
          {!props.change.wasJustConfirmed && !props.change.isUserConfirmed && (
            <>
              {props.change.confirmationType !== ValueChangeType.Choice && (
                <>
                  <Typography style={{ margin: 5 }}>
                    Please write a comment (optional) and press on the confirm button to confirm this change.
                  </Typography>
                  <br />
                </>
              )}{' '}
            </>
          )}
          {props.change.solutions && props.change.solutions?.length > 0 && (
            <>
              <FormControl component="fieldset" disabled>
                <RadioGroup
                  id={props.id + '-change-options'}
                  value={confirmationType === ValueChangeType.Choice ? '' : confirmationType}
                  onChange={adjustConfirmationType}
                >
                  {props.change.confirmationType !== ValueChangeType.Choice ? (
                    <FormControlLabel
                      value={props.change.confirmationType}
                      control={<Radio disabled id={props.id + '-change-option-' + confirmationType} />}
                      label={props.change.solutions.find((c) => c.type === props.change.confirmationType)?.message}
                      disabled={true}
                    />
                  ) : (
                    props.change.solutions.map((s, k) => (
                      <FormControlLabel
                        key={k}
                        value={s.type}
                        control={<Radio disabled id={props.id + '-change-option-' + s?.type} />}
                        label={s.message}
                        disabled={
                          props.disabled ||
                          props.change.wasJustConfirmed ||
                          props.change.isUserConfirmed ||
                          props.isLoading
                        }
                      />
                    ))
                  )}
                </RadioGroup>
              </FormControl>
            </>
          )}
          {(!props.change.isUserConfirmed || props.change.confirmationComment) && (
            <FormControl
              style={{ margin: 5, width: '100%' }}
              disabled={
                props.disabled || props.change.wasJustConfirmed || props.change.isUserConfirmed || props.isLoading
              }
            >
              <InputLabel htmlFor={props.id + '-value-change-comment'}>Comment (optional)</InputLabel>
              <Input
                id={props.id + '-value-change-comment'}
                value={comment}
                onChange={(event) => {
                  setComment(event.target.value);
                }}
                disabled={
                  props.disabled || props.change.wasJustConfirmed || props.change.isUserConfirmed || props.isLoading
                }
              />
            </FormControl>
          )}
          {props.change.isUserConfirmed && props.change.confirmedByName ? (
            <div>
              <Typography>
                Confirmed By: {props.change.confirmedByName}
                <br />
                Confirmed Date: {props.change.confirmedDate?.toFormat(APP_DATE_FORMAT)}
              </Typography>
            </div>
          ) : (
            <>
              {!props.change.wasJustConfirmed && !props.change.isUserConfirmed && (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={
                    props.disabled || props.change.wasJustConfirmed || props.change.isUserConfirmed || props.isLoading
                  }
                  onClick={handleConfirm}
                  style={{ margin: 5 }}
                >
                  Confirm
                </Button>
              )}
            </>
          )}
        </div>
      </Paper>
    </>
  );
};
