import actions from './actions';
import types from './types';
import reducer from './reducer';

import { ILabelClient, IProfileClient } from 'app/clients/services';
import { resolver } from 'app/clients/service-registry';

export type { ProfileSelectionState } from './reducer';
export default {
  initLabelActions: () => actions.initLabelActions(resolver.resolve<ILabelClient>('ILabelClient')),
  initProfileActions: () => actions.initProfileActions(resolver.resolve<IProfileClient>('IProfileClient')),
  types,
  reducer,
};
