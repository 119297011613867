// Types
export default {
  SET_SEARCH_LOADING: 'studentSearch/SET_SEARCH_LOADING',
  SET_SEARCH_RESULT: 'studentSearch/SET_SEARCH_RESULT',
  ADD_UAS_FILTER: 'studentSearch/ADD_UAS_FILTER',
  REMOVE_UAS_FILTER: 'studentSearch/REMOVE_UAS_FILTER',
  ADD_SEMESTER_FILTER: 'studentSearch/ADD_SEMESTER_FILTER',
  REMOVE_SEMESTER_FILTER: 'studentSearch/REMOVE_SEMESTER_FILTER',
  SET_SEARCH_STRING: 'studentSearch/SET_SEARCH_STRING',
  SET_RESULT_SEARCH_STRING: 'studentSearch/SET_RESULT_SEARCH_STRING',
};
