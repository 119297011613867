import { CloseReason } from 'notistack';

export default {
  ADD: 'notification/ADD',
  REMOVE: 'notification/REMOVE',
  CLOSE_ALL: 'notification/CLOSE_ALL',
  CLOSE: 'notification/CLOSE',
  CLEAR: 'notification/CLEAR',
};

export type NotificationLevel = 'success' | 'info' | 'warning' | 'error';

export interface NotificationObject {
  message: string;
  level: NotificationLevel;
  options: {
    onClose?: (event: React.SyntheticEvent<any, Event>, reason: CloseReason) => void;
    variant: NotificationLevel;
    key: string;
  };
  dismissed?: boolean;
}
