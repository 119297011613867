import { RoleType } from 'app/clients/services';
import { IRootState } from 'app/config/root.reducer';
import { hasAnyRole } from 'app/shared/auth/roles';
import { ITabModel, NavigationTabs } from 'app/shared/layout/ui-elements/navigation-tabs';
import React, { useEffect } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { rememberOpenTab } from '../application/actions';

export type Props = PropsFromRedux & { baseUrl: string };

const ModuleRegistrationTabs = (props: Props) => {
  useEffect(() => {}, []);

  const tabs: ITabModel[] = [];

  if (props.isAdmin) {
    tabs.push({
      label: 'Prioritization',
      url: 'modules',
      isDefault: true,
    } as ITabModel);
  }

  if (props.allowedUas?.length) {
    tabs.push({
      label: 'UAS',
      url: 'uas',
    } as ITabModel);
  }

  if (props.isAdmin || props.isMasterOffice) {
    tabs.push({
      label: 'Export',
      url: 'export',
    } as ITabModel);
  }

  if (props.isAdmin) {
    tabs.push({
      label: 'Summary',
      url: 'summary',
    } as ITabModel);
  }

  return (
    <NavigationTabs
      tabs={tabs}
      baseUrl={props.baseUrl}
      openTab={props.openTabs[props.baseUrl]}
      onChange={(tab: ITabModel) => props.rememberOpenTab(props.baseUrl, tab)}
    />
  );
};

function mapStateToProps({ auth, application }: IRootState) {
  return {
    isAdmin: hasAnyRole(auth.account?.roles, [RoleType.REGISTRATIONADMIN]),
    isMasterOffice: hasAnyRole(auth.account?.roles, [RoleType.MASTEROFFICE]),
    allowedUas: auth.allowedUas,
    openTabs: application.openTabs,
  };
}

const mapDispatchToProps = (dispatch) => ({
  rememberOpenTab: (baseUrl: string, tab: ITabModel) => dispatch(rememberOpenTab(baseUrl, tab)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ModuleRegistrationTabs);
