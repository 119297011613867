import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React from 'react';
import { DefaultButton } from './buttons';
export interface IButtonAction {
  name: string;
  primary?: boolean;
  disabled?: boolean;
  action: () => void;
}
export interface IStandardDialogProps {
  open: boolean;
  actions?: IButtonAction[];
  onClose?: () => void;
  onConfirm?: () => void;
  onCancel?: () => void;
  confirmDisabled?: boolean;
  confirmName?: string;
  cancelName?: string;
  title: string;
  children?: React.ReactNode;
}

export const StandardDialog = (props: IStandardDialogProps) => {
  return (
    <Dialog
      open={props.open}
      onClose={() => (props.onClose ? props.onClose() : {})}
      aria-labelledby="standard-dialog-title"
      aria-describedby="standard-dialog-description"
    >
      <DialogTitle id="standard-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="standard-dialog-content">{props.children}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {props.actions && props.actions.length ? (
          props.actions.map((a) => (
            <DefaultButton key={a.name} onClick={a.action} primary={a.primary}>
              {a.name}
            </DefaultButton>
          ))
        ) : (
          <>
            {(props.confirmName || props.onConfirm) && (
              <Button
                key={props.confirmName ?? 'OK'}
                onClick={() => (props.onConfirm ? props.onConfirm() : {})}
                color="primary"
                variant="contained"
                disabled={props.confirmDisabled}
              >
                {props.confirmName ? props.confirmName : 'OK'}
              </Button>
            )}
            {(props.confirmName || props.onCancel) && (
              <Button
                key={props.cancelName ?? 'Cancel'}
                onClick={() => (props.onCancel ? props.onCancel() : {})}
                color="inherit"
                variant="contained"
              >
                {props.cancelName ? props.cancelName : 'Cancel'}
              </Button>
            )}
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};
