import { IAdvisorClient, IAdvisorDto } from 'app/clients/services';
import { addExceptionNotification } from 'app/modules/notification';
import { AsyncThunk } from 'app/shared/util/action-type.util';
import ACTION_TYPES from './types';

const initActions = (componentDispatch: any, advisorClient: IAdvisorClient) => {
  const setAdvisors = (uasId: number, advisors: IAdvisorDto[]) => ({
    type: ACTION_TYPES.SET_ADVISORS,
    payload: { uasId, advisors },
  });

  const setLoading = (loading: boolean) => ({
    type: ACTION_TYPES.SET_LOADING,
    payload: loading,
  });

  const loadAdvisorsAsync: (uasId: number) => AsyncThunk<IAdvisorDto[]> = uasId => async dispatch => {
    dispatch(setLoading(true));
    try {
      const advisors = await advisorClient.list(uasId);
      dispatch(
        setAdvisors(
          uasId,
          advisors.sort((a1, a2) =>
            a1.familyName === a2.familyName ? (a1.firstName < a2.firstName ? -1 : 1) : a1.familyName < a2.familyName ? -1 : 1
          )
        )
      );
      dispatch(setLoading(false));
      return advisors;
    } catch (e) {
      dispatch(addExceptionNotification(e, 'Error while loading advisors'));
      dispatch(setLoading(false));
      return [];
    }
  };

  const getAdvisorsAsync: (uasId: number) => AsyncThunk<IAdvisorDto[]> = uasId => async (dispatch, getState) => {
    const advisors = getState().advisorSelection.advisors[uasId];
    if (advisors) {
      return advisors;
    }
    return await dispatch(loadAdvisorsAsync(uasId));
  };

  return Object.freeze({
    loadAdvisorsAsync: async (uasId: number): Promise<IAdvisorDto[]> => await componentDispatch(loadAdvisorsAsync(uasId)),
    getAdvisorsAsync: async (uasId: number): Promise<IAdvisorDto[]> => await componentDispatch(getAdvisorsAsync(uasId)),
  });
};
export default initActions;

export type AdvisorSelectionActions = ReturnType<typeof initActions>;
