import { Typography } from '@mui/material';
import { IFileImportDto } from 'app/clients/services';
import { APP_DATETIME_FORMAT } from 'app/config/constants';
import React from 'react';

type FileImportProps = {
  fileImport: IFileImportDto;
};

export const FileImportInfo = ({ fileImport }: FileImportProps) => {
  return (
    <>
      <div>
        <Typography variant="h4">
          Excel Import: {fileImport.uas} - {fileImport.semester} - {fileImport.phase}
        </Typography>
        <Typography>
          <strong>Filename</strong>: {fileImport.fileName}
          <br />
          <strong>State</strong>: {fileImport.importState}
          <br />
          <strong>Uploaded By</strong>: {fileImport.uploadedByName}
          <br />
          <strong>Uploaded</strong>: {fileImport.uploadedDate?.toFormat(APP_DATETIME_FORMAT)}
          <br />
        </Typography>
        {fileImport.importedDate && (
          <Typography>
            <strong>Imported By</strong>: {fileImport.importedByName}
            <br />
            <strong>Imported</strong>: {fileImport.importedDate.toFormat(APP_DATETIME_FORMAT)}
          </Typography>
        )}
      </div>
    </>
  );
};
