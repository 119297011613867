import BackspaceIcon from '@mui/icons-material/Backspace';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PublishIcon from '@mui/icons-material/Publish';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import React from 'react';

import { Tooltip, Typography } from '@mui/material';

type IconProps = {
  title: string;
  className?: string;
};

export const RequestedIcon = (props: IconProps) => {
  return (
    <RenderIcon title={props.title}>
      <ContactMailIcon fontSize="small" />
    </RenderIcon>
  );
};

export const ConfirmedIcon = (props: IconProps) => {
  return (
    <RenderIcon title={props.title}>
      <DoneOutlineIcon fontSize="small" />
    </RenderIcon>
  );
};

export const CanceledIcon = (props: IconProps) => {
  return (
    <RenderIcon title={props.title}>
      <CancelPresentationIcon fontSize="small"></CancelPresentationIcon>
    </RenderIcon>
  );
};

export const NotRegisteredIcon = (props: IconProps) => {
  return (
    <RenderIcon title={props.title}>
      <HelpOutlineIcon fontSize="small"></HelpOutlineIcon>
    </RenderIcon>
  );
};

export const WithdrawnIcon = (props: IconProps) => {
  return (
    <RenderIcon title={props.title}>
      <BackspaceIcon fontSize="small"></BackspaceIcon>
    </RenderIcon>
  );
};

export const UploadedIcon = (props: IconProps) => {
  return (
    <RenderIcon title={props.title}>
      <PublishIcon fontSize="small"></PublishIcon>
    </RenderIcon>
  );
};

export const ImportedIcon = (props: IconProps) => {
  return (
    <RenderIcon title={props.title}>
      <SaveAltIcon fontSize="small"></SaveAltIcon>
    </RenderIcon>
  );
};

export const RejectedIcon = (props: IconProps) => {
  return (
    <RenderIcon title={props.title}>
      <ThumbDownIcon fontSize="small"></ThumbDownIcon>
    </RenderIcon>
  );
};

export const DeletedIcon = (props: IconProps) => {
  return (
    <RenderIcon title={props.title}>
      <DeleteIcon fontSize="small"></DeleteIcon>
    </RenderIcon>
  );
};

type RenderIconProps = {
  children: React.ReactNode;
  title: string;
  className?: string;
};
const RenderIcon = (props: RenderIconProps) => {
  return (
    <Tooltip title={<Typography>{props.title}</Typography>}>
      <span className={props.className} style={{ paddingLeft: 5 }}>
        {props.children}
      </span>
    </Tooltip>
  );
};
