import initActions from './actions';
import reducer from './reducer';
import types from './types';

import { resolver } from 'app/clients/service-registry';
import { IImportClient, IRegistrationEditClient } from 'app/clients/services';
import semesterSelectionModule from 'app/modules/selections/semester-selection';

export type { ImportsState } from './reducer';
export default {
  initActions: () =>
    initActions(resolver.resolve<IImportClient>('IImportClient'), resolver.resolve<IRegistrationEditClient>('IRegistrationEditClient'), semesterSelectionModule.initActions()),
  types,
  reducer,
};
