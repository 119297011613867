import { Popover } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { SketchPicker } from 'react-color';

type Color = { r: number; g: number; b: number; a: number };

type Props = {
  onChange: (rgbaColor: string) => void;
  rgbaColor: string;
};

type ColorButtonProps = {
  rgbaColor: string;
};

export const ColorButton = (props: ColorButtonProps) => {
  return (
    <div
      style={{
        width: '36px',
        height: '14px',
        borderRadius: '2px',
        background: props.rgbaColor,
      }}
    />
  );
};

export const ColorPicker = (props: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const [open, setOpen] = React.useState<boolean>(false);

  const convertToString: (color: Color) => string = (color) => {
    return `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a === undefined ? 1 : color.a})`;
  };

  const convertToColor: (rgbaString: string) => Color = (rgbaString: string) => {
    const parts = rgbaString?.substring(rgbaString.indexOf('(') + 1).split(',') ?? [];
    return {
      r: parts.length > 0 ? Number(parts[0].trim()) : 255,
      g: parts.length > 1 ? Number(parts[1].trim()) : 255,
      b: parts.length > 2 ? Number(parts[2].trim()) : 255,
      a: parts.length > 3 ? Number(parts[3].replace(')', '').trim()) : 1,
    };
  };

  const [color, setColor] = useState(convertToColor(props.rgbaColor));

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
    const colorString = convertToString(color);
    if (props.rgbaColor !== colorString) {
      props.onChange(colorString);
    }
  };

  useEffect(() => {
    if (!open) {
      handleClose();
    }
  }, [open]);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (open) {
      handleClose();
      return;
    }

    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleChange = (newColor) => {
    setColor(newColor.rgb);
  };

  return (
    <>
      <div
        style={{
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          cursor: 'pointer',
        }}
        onClick={handleClick}
      >
        <ColorButton rgbaColor={convertToString(color)} />
      </div>
      <Popover open={open} anchorEl={anchorEl} onClose={handleClose}>
        <SketchPicker color={color} onChange={handleChange} />
      </Popover>
    </>
  );
};

export default ColorPicker;
