import { Alert, AlertTitle, Typography } from '@mui/material';
import * as Sentry from '@sentry/react';
import React from 'react';
import { TextContent } from '../layout/ui-elements/component-layout';

const Fallback = (props: { error: Error; componentStack: string; resetError: () => void }) => {
  if (props.error && process.env.NODE_ENV === 'development') {
    return (
      <TextContent>
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          <Typography style={{ whiteSpace: 'pre-wrap' }}>{props.error.toString()}</Typography>
        </Alert>
        <Typography style={{ marginTop: 20 }} variant="h5">
          Stack Trace
        </Typography>
        <Typography style={{ whiteSpace: 'pre-wrap' }}>{props.error.stack}</Typography>
        <Typography style={{ marginTop: 20 }} variant="h5">
          Component Stack
        </Typography>
        <Typography style={{ whiteSpace: 'pre-wrap' }}>{props.componentStack}</Typography>
      </TextContent>
    );
  }
  return (
    <TextContent>
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        <Typography>An unexpected error has occurred.</Typography>
      </Alert>
    </TextContent>
  );
};

const ErrorBoundary = (props: React.PropsWithChildren) => (
  <Sentry.ErrorBoundary
    fallback={({ error, componentStack, resetError }) => (
      <Fallback error={error} componentStack={componentStack} resetError={resetError} />
    )}
  >
    {props.children}
  </Sentry.ErrorBoundary>
);

export default ErrorBoundary;
