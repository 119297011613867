import { IAdvisorDto } from 'app/clients/services';
import ACTION_TYPES from './types';

// State
const initialState = {
  loading: false,
  advisors: {} as { [uasId: number]: IAdvisorDto[] },
};

export type AdvisorSelectionState = Readonly<typeof initialState>;

// Reducer
export default (state: AdvisorSelectionState = initialState, action): AdvisorSelectionState => {
  switch (action.type) {
    case ACTION_TYPES.SET_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case ACTION_TYPES.SET_ADVISORS: {
      if (action.payload) {
        const advisors = {
          ...state.advisors,
        };
        advisors[action.payload.uasId] = action.payload.advisors;
        return {
          ...state,
          advisors,
        };
      }
      return {
        ...state,
      };
    }
    default:
      return state;
  }
};
