import { Tooltip, Typography } from '@mui/material';
import {
  IValueChange,
  IValueChangeOfNullableDateTime,
  IValueChangeOfString,
  ValueChangeType,
} from 'app/clients/services';
import { APP_DATE_FORMAT } from 'app/config/constants';
import { DateTime } from 'luxon';
import React from 'react';
import ReactDiffViewer from 'react-diff-viewer-continued';

const getMessage = (valueChange: IValueChange) => {
  if (valueChange.confirmationType === ValueChangeType.Choice) {
    return valueChange.conflictMessage;
  }

  const user = valueChange.confirmedByName;
  if (valueChange.confirmationComment) {
    return !user ? valueChange.confirmationComment : `${user}: ${valueChange.confirmationComment}`;
  }

  const message =
    valueChange.solutions?.find((s) => s.type === valueChange.confirmationType)?.message ?? valueChange.conflictMessage;
  return !user ? message : `${user}: ${message}`;
};

export const StringValueChange = (props: { valueChange: IValueChangeOfString; value: string }) => {
  return (
    <>
      {props.valueChange ? (
        <Tooltip
          title={
            <>
              <Typography>{getMessage(props.valueChange)}</Typography>
              <ReactDiffViewer
                hideLineNumbers={true}
                oldValue={props.valueChange.oldValue}
                newValue={
                  props.valueChange.confirmationType === ValueChangeType.KeepOldValue
                    ? props.valueChange.oldValue
                    : props.valueChange.newValue
                }
                splitView={false}
              />
            </>
          }
        >
          <span className={'MuiAlert-standard' + props.valueChange.validationLevel}>
            {props.valueChange.confirmationType === ValueChangeType.KeepOldValue
              ? props.valueChange.oldValue
              : props.valueChange.newValue}
          </span>
        </Tooltip>
      ) : (
        <>{props.value}</>
      )}
    </>
  );
};

export const DateValueChange = (props: { valueChange: IValueChangeOfNullableDateTime; value: DateTime }) => {
  return (
    <>
      {props.valueChange ? (
        <Tooltip
          title={
            <>
              <Typography>{getMessage(props.valueChange)}</Typography>
              <ReactDiffViewer
                hideLineNumbers={true}
                oldValue={props.valueChange.oldValue?.toFormat(APP_DATE_FORMAT)}
                newValue={(props.valueChange.confirmationType === ValueChangeType.KeepOldValue
                  ? props.valueChange.oldValue
                  : props.valueChange.newValue
                )?.toFormat(APP_DATE_FORMAT)}
                splitView={false}
              />
            </>
          }
        >
          <span className={'MuiAlert-standard' + props.valueChange.validationLevel}>
            {(props.valueChange.confirmationType === ValueChangeType.KeepOldValue
              ? props.valueChange.oldValue
              : props.valueChange.newValue
            )?.toFormat(APP_DATE_FORMAT)}
          </span>
        </Tooltip>
      ) : (
        <>{props.value?.toFormat(APP_DATE_FORMAT)}</>
      )}
    </>
  );
};
