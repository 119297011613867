import { Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import {
  DatabaseMappingOfNullableLong,
  DatabaseMappingOfString,
  EmailImport,
  IAdvisorImport,
  ICommentDto,
  IDatabaseMappingOfNullableLong,
  IDatabaseMappingOfString,
  IModuleRegistrationImport,
  IStudentImport,
  IValueChange,
  ModuleRegistrationImport,
  RoleType,
  StudentImport,
  ValidationLevel,
  ValueChangeOfNullableDateTime,
  ValueChangeOfString,
} from 'app/clients/services';
import { useHasRole } from 'app/modules/login/hooks';
import React from 'react';
import { DbMappingForm } from './db-mapping-form';
import { RegistrationImportPreview } from './registration-import-preview';
import { StudentInfo } from './student-info';
import { ValueChangeForm } from './value-change-form';

type StudentImportPreviewProps = {
  student: IStudentImport;
  advisor: IAdvisorImport;
  levelFilter: ValidationLevel;
  onConfirm: (student: StudentImport) => void | Promise<void>;
  disabled?: boolean;
  isLoading: boolean;
  noRights: boolean;
  refreshRegistrationInfo: (moduleRegistrationId: number) => Promise<void>;
  onRemoveComment?: (comment: ICommentDto) => Promise<void>;
  onAddComment?: (comment: ICommentDto) => Promise<void>;
};

export const StudentImportPreview = (props: StudentImportPreviewProps) => {
  const isRegistrationAdmin = useHasRole(RoleType.REGISTRATIONADMIN);

  return (
    <div style={{ borderColor: 'lightgrey' }}>
      <Paper style={{ width: '100%', margin: 5, padding: 20 }}>
        <Grid container direction={'row'}>
          <Grid item xs={4}>
            <StudentInfo student={props.student} advisor={props.advisor} noRights={props.noRights} />
          </Grid>
          <Grid item xs={8}>
            <Table size="small">
              <TableHead style={{ backgroundColor: 'lightgrey' }}>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Row</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Module</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.student.moduleRegistrations &&
                  props.student.moduleRegistrations.map((r, k) => (
                    <RegistrationImportPreview
                      key={k}
                      disabled={props.disabled}
                      registration={r}
                      noRights={props.noRights}
                      isLoading={props.isLoading}
                      levelFilter={props.levelFilter}
                      onConfirm={(newRegistration: IModuleRegistrationImport) => {
                        const newStudent = new StudentImport({
                          ...props.student,
                          moduleRegistrations: props.student.moduleRegistrations.map((mr) =>
                            mr.rowNumber === r.rowNumber ? new ModuleRegistrationImport(newRegistration) : mr,
                          ),
                        });
                        props.onConfirm(newStudent);
                      }}
                      onRemoveComment={props.onRemoveComment}
                      onAddComment={props.onAddComment}
                      refreshRegistrationInfo={props.refreshRegistrationInfo}
                      studentImport={props.student}
                    />
                  ))}
              </TableBody>
            </Table>
          </Grid>
          {!props.noRights && (
            <Grid item xs={12} style={{ marginTop: 5 }}>
              <DbMappingForm
                id={props.student.guid + '-student'}
                isLoading={props.isLoading}
                dbMapping={props.student.dbMapping}
                isRegistrationAdmin={isRegistrationAdmin}
                filter={props.levelFilter}
                onConfirmStringMapping={(mapping: IDatabaseMappingOfString) => {
                  const newStudent = new StudentImport({
                    ...props.student,
                    dbMapping: new DatabaseMappingOfString({
                      ...props.student.dbMapping,
                      ...mapping,
                    }),
                  });
                  props.onConfirm(newStudent);
                }}
                title="Student"
              />
              <ValueChangeForm
                id={props.student.guid + '-firstname'}
                change={props.student.firstNameChange}
                isLoading={props.isLoading}
                disabled={props.disabled}
                filter={props.levelFilter}
                onConfirm={(change: IValueChange) => {
                  const newStudent = new StudentImport({
                    ...props.student,
                    firstNameChange: new ValueChangeOfString({ ...props.student.firstNameChange, ...change }),
                  });
                  props.onConfirm(newStudent);
                }}
                title="First Name Change"
              />
              <ValueChangeForm
                id={props.student.guid + '-familyname'}
                change={props.student.familyNameChange}
                isLoading={props.isLoading}
                disabled={props.disabled}
                filter={props.levelFilter}
                onConfirm={(change: IValueChange) => {
                  const newStudent = new StudentImport({
                    ...props.student,
                    familyNameChange: new ValueChangeOfString({ ...props.student.familyNameChange, ...change }),
                  });
                  props.onConfirm(newStudent);
                }}
                title="Familiy Name Change"
              />
              <ValueChangeForm
                id={props.student.guid + '-birthdate'}
                change={props.student.birthDateChange}
                isLoading={props.isLoading}
                disabled={props.disabled}
                filter={props.levelFilter}
                onConfirm={(change: IValueChange) => {
                  const newStudent = new StudentImport({
                    ...props.student,
                    birthDateChange: new ValueChangeOfNullableDateTime({ ...props.student.birthDateChange, ...change }),
                  });
                  props.onConfirm(newStudent);
                }}
                title="Birth Date Change"
              />
              <ValueChangeForm
                id={props.student.guid + '-matriculationnumber'}
                change={props.student.matriculationNumberChange}
                isLoading={props.isLoading}
                disabled={props.disabled}
                filter={props.levelFilter}
                onConfirm={(change: IValueChange) => {
                  const newStudent = new StudentImport({
                    ...props.student,
                    matriculationNumberChange: new ValueChangeOfString({
                      ...props.student.matriculationNumberChange,
                      ...change,
                    }),
                  });
                  props.onConfirm(newStudent);
                }}
                title="Matriculation Number Change"
              />
              <DbMappingForm
                id={props.student.guid + '-uas-email'}
                isLoading={props.isLoading}
                dbMapping={props.student.uasEmail?.dbMapping}
                isRegistrationAdmin={isRegistrationAdmin}
                disabled={props.disabled}
                filter={props.levelFilter}
                onConfirmLongMapping={(dbMapping: IDatabaseMappingOfNullableLong) => {
                  const newStudent = new StudentImport({
                    ...props.student,
                    uasEmail: new EmailImport({
                      ...props.student.uasEmail,
                      dbMapping: new DatabaseMappingOfNullableLong({
                        ...props.student.uasEmail.dbMapping,
                        ...dbMapping,
                      }),
                    }),
                  });
                  props.onConfirm(newStudent);
                }}
                title="UAS Email"
              />
              <ValueChangeForm
                id={props.student.guid + '-uas-email'}
                change={props.student.uasEmail?.nameChange}
                isLoading={props.isLoading}
                disabled={props.disabled}
                filter={props.levelFilter}
                onConfirm={(change: IValueChange) => {
                  const newStudent = new StudentImport({
                    ...props.student,
                    uasEmail: new EmailImport({
                      ...props.student.uasEmail,
                      nameChange: new ValueChangeOfString({ ...props.student.uasEmail?.nameChange, ...change }),
                    }),
                  });
                  props.onConfirm(newStudent);
                }}
                title="UAS Email Change"
              />
              <DbMappingForm
                id={props.student.guid + '-private-email'}
                isLoading={props.isLoading}
                dbMapping={props.student.privateEmail?.dbMapping}
                isRegistrationAdmin={isRegistrationAdmin}
                disabled={props.disabled}
                filter={props.levelFilter}
                onConfirmLongMapping={(dbMapping: IDatabaseMappingOfNullableLong) => {
                  const newStudent = new StudentImport({
                    ...props.student,
                    privateEmail: new EmailImport({
                      ...props.student.privateEmail,
                      dbMapping: new DatabaseMappingOfNullableLong({
                        ...props.student.privateEmail.dbMapping,
                        ...dbMapping,
                      }),
                    }),
                  });
                  props.onConfirm(newStudent);
                }}
                title="Private Email"
              />
              <ValueChangeForm
                id={props.student.guid + '-private-email'}
                change={props.student.privateEmail?.nameChange}
                isLoading={props.isLoading}
                disabled={props.disabled}
                filter={props.levelFilter}
                onConfirm={(change: IValueChange) => {
                  const newStudent = new StudentImport({
                    ...props.student,
                    privateEmail: new EmailImport({
                      ...props.student.privateEmail,
                      nameChange: new ValueChangeOfString({ ...props.student.privateEmail?.nameChange, ...change }),
                    }),
                  });
                  props.onConfirm(newStudent);
                }}
                title="Private Email Change"
              />
              <ValueChangeForm
                id={props.student.guid + '-previous-degree'}
                change={props.student.previousDegreeChange}
                isLoading={props.isLoading}
                disabled={props.disabled}
                filter={props.levelFilter}
                onConfirm={(change: IValueChange) => {
                  const newStudent = new StudentImport({
                    ...props.student,
                    previousDegreeChange: new ValueChangeOfString({ ...props.student.previousDegreeChange, ...change }),
                  });
                  props.onConfirm(newStudent);
                }}
                title="Previous Degree Change"
              />
              <DbMappingForm
                id={props.student.guid + '-profile-change'}
                isLoading={props.isLoading}
                dbMapping={props.student.profileMapping}
                isRegistrationAdmin={isRegistrationAdmin}
                disabled={props.disabled}
                filter={props.levelFilter}
                onConfirmLongMapping={(dbMapping: IDatabaseMappingOfNullableLong) => {
                  const newStudent = new StudentImport({
                    ...props.student,
                    profileMapping: new DatabaseMappingOfNullableLong({
                      ...props.student.profileMapping,
                      ...dbMapping,
                    }),
                  });
                  props.onConfirm(newStudent);
                }}
                title="Profile Change"
              />
            </Grid>
          )}
        </Grid>
      </Paper>
    </div>
  );
};
