import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Paper, Typography } from '@mui/material';
import { ISemesterDto, ISemesterRegistrationDto, RoleType } from 'app/clients/services';
import { IRootState } from 'app/config/root.reducer';
import advisorSelectionModule from 'app/modules/selections/advisor-selection';
import moduleSelectionModule from 'app/modules/selections/module-selection';
import { hasAnyRole } from 'app/shared/auth/roles';
import { FullSizeLayout } from 'app/shared/layout/ui-elements/component-layout';
import React, { useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { SemesterRegistrationForm } from './parts/semester-registration-form';
import { StudentForm } from './parts/student-form';
import studentsModule from './students';

import { DefaultButton } from 'app/shared/layout/ui-elements/buttons';
export type Props = PropsFromRedux;

export const Student = (props: Props) => {
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState<boolean>(true);
  const [expandedSemesterRegistrations, setExpandedSemesterRegistrations] = useState<ISemesterRegistrationDto[]>([]);

  const loadStudent = async (studentId: string) => {
    let student = props.student;
    if (id === 'new') {
      if (!props.student && !props.tabs.find((t) => !t.id)) {
        student = props.addNewStudent(props.semesters, props.uas, props.profiles);
      }
    } else if (props.student?.id !== id) {
      setLoading(true);
      student = await props.loadStudentAsync(studentId);
    }

    const uasIds = student.semesterRegistrations
      .map((s) => s.registeredAtId)
      .filter((value, index, self) => self.indexOf(value) === index);

    for (const uasId of uasIds) {
      await props.getAdvisorsAsync(uasId);
    }
    const semesters = student.semesterRegistrations
      .map((s) => s.semesterId)
      .filter((value, index, self) => self.indexOf(value) === index)
      .map((sId) => props.semesters.find((se) => se.id === sId));

    for (const semester of semesters) {
      await props.getExecutionsForSemesterAsync(semester);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadStudent(id);
  }, []);

  return (
    <FullSizeLayout loading={props.loading || loading}>
      {!props.loading && !loading && props.student && (
        <>
          <Typography variant="h1">
            Student: {props.student.firstName} {props.student.familyName}
          </Typography>
          <Paper sx={{ width: '100%', padding: 2 }}>
            <StudentForm
              student={props.student}
              loading={props.loading}
              onSubmit={async (s) => {
                if (!s.id) {
                  await props.addStudentAsync(s);
                  return;
                }
                await props.updateStudentAsync(s);
              }}
              onDelete={async (s) => {
                if (!s.id) {
                  props.cancelAddingStudent();
                  return;
                }
                await props.deleteStudentAsync(s);
              }}
              onDeleteEmail={async (e) => {
                await props.deleteEmailAsync(e);
              }}
              onCancel={() => {}}
              disabled={!props.isRegistrationAdmin && !props.isStudentAdmin}
            ></StudentForm>
          </Paper>
          {!props.loading && !loading && props.uas && props.advisors && props.student.semesterRegistrations && (
            <Grid container>
              {props.student.semesterRegistrations.length > 0 && (
                <Grid item xs={12} sx={{ paddingTop: 1 }}>
                  <Typography variant="h3">Semester Registrations:</Typography>
                </Grid>
              )}
              <Grid item xs={12}>
                {props.student.semesterRegistrations
                  .sort((s1, s2) => (s1.semesterStart > s2.semesterStart ? 1 : 0))
                  .map((r, k) => {
                    return (
                      <Accordion
                        key={k}
                        expanded={expandedSemesterRegistrations.filter((s) => s.id === r.id).length > 0}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={`panel${k}a-content}`}
                          id={`panel${k}a-header}`}
                          onClick={() => {
                            expandedSemesterRegistrations.filter((s) => s.id === r.id).length > 0
                              ? setExpandedSemesterRegistrations(
                                  expandedSemesterRegistrations.filter((s) => s.id !== r.id),
                                )
                              : setExpandedSemesterRegistrations([...expandedSemesterRegistrations, r]);
                          }}
                        >
                          <Typography variant="h3">
                            {r.semester} - {r.registeredAt}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ width: '100%' }}>
                          <SemesterRegistrationForm
                            loading={props.loading}
                            isAdmin={props.isRegistrationAdmin}
                            semesterRegistration={r}
                            moduleRegistrationErrors={props.moduleRegistrationErrors}
                            onSubmit={async (s) => {
                              if (!props.student.id) {
                                props.updateSemesterRegistration(s);
                                return;
                              }
                              if (s.id === 0) {
                                await props.addSemesterRegistrationAsync(s);
                                return;
                              }
                              await props.updateSemesterRegistrationAsync(s);
                            }}
                            onDelete={async (s) => {
                              await props.deleteSemesterRegistrationAsync(s);
                            }}
                            onCancel={props.cancelAddingSemesterRegistration}
                            semesters={props.semesters}
                            fetchAdvisors={props.getAdvisorsAsync}
                            uas={props.uas}
                            profiles={props.profiles}
                            labels={props.labels}
                            fetchModuleExecutions={async (sId: number) =>
                              props.getExecutionsForSemesterAsync(props.semesters.find((s) => s.id === sId))
                            }
                            onAddComment={(s) => props.addCommentAsync(s)}
                            onRemoveComment={(s) => props.removeCommentAsync(s)}
                            onSaveModuleRegistration={async (m, c) => {
                              if (m.id > 0) {
                                await props.updateModuleRegistrationAsync(m, c);
                              } else {
                                await props.addModuleRegistrationAsync(m, c);
                              }
                            }}
                            onDeleteModuleRegistration={props.deleteModuleRegistrationAsync}
                            getHistoryAsync={props.getHistoryAsync}
                            currentUser={props.currentUser}
                          />
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
              </Grid>
              {(props.isStudentAdmin || props.isRegistrationAdmin) &&
                props.student.semesterRegistrations.filter((s) => s.id === 0).length === 0 && (
                  <Grid>
                    <DefaultButton
                      sx={{ marginTop: 2 }}
                      disabled={props.loading}
                      onClick={async () => {
                        const newRegistration = props.addNewSemesterRegistration(
                          props.student,
                          props.semesters,
                          props.uas,
                          props.profiles,
                        );
                        setExpandedSemesterRegistrations([...expandedSemesterRegistrations, newRegistration]);
                        await props.getAdvisorsAsync(newRegistration.registeredAtId);
                      }}
                      startIcon={<AddIcon />}
                    >
                      Add new semester registration
                    </DefaultButton>
                  </Grid>
                )}
            </Grid>
          )}
        </>
      )}
    </FullSizeLayout>
  );
};

const mapStateToProps = ({
  auth,
  studentManagement,
  studentTabs,
  advisorSelection,
  semesterSelection,
  profileSelection,
  moduleSelection,
}: IRootState) => ({
  tabs: studentTabs.tabs,
  loading: studentManagement.studentLoading,
  student: studentManagement.loadedStudent,
  advisors: advisorSelection.advisors,
  semesters: semesterSelection.semesterFilter?.semesters ?? [],
  executions: moduleSelection.executions,
  moduleRegistrationErrors: studentManagement.moduleRegistrationErrors,
  isStudentAdmin: hasAnyRole(auth.account?.roles, [RoleType.STUDENTADMIN]),
  isRegistrationAdmin: hasAnyRole(auth.account?.roles, [RoleType.REGISTRATIONADMIN]),
  uas: auth.allowedUas,
  profiles: profileSelection.profiles,
  labels: profileSelection.labels,
  currentUser: auth.account,
});

const mapDispatchToProps = (dispatch) => {
  const moduleSelectionActions = moduleSelectionModule.initActions();
  const studentsActions = studentsModule.initActions(dispatch);
  const advisorSelectionActions = advisorSelectionModule.initActions(dispatch);
  return {
    ...studentsActions,
    getAdvisorsAsync: advisorSelectionActions.getAdvisorsAsync,
    getExecutionsForSemesterAsync: async (semester: ISemesterDto) =>
      await dispatch(moduleSelectionActions.getExecutionsForSemesterAsync(semester)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Student);
