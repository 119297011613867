import AddIcon from '@mui/icons-material/Add';
import { Paper, TableBody, TableCell, TableContainer, TableFooter, TableRow } from '@mui/material';
import { IPriorityDto, PriorityDto } from 'app/clients/services';
import { IRootState } from 'app/config/root.reducer';
import { FullSizeLayout } from 'app/shared/layout/ui-elements/component-layout';
import React, { useEffect } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import priorityModule from './priorities/index';
import {} from './priorities/reducer';

import { DefaultButton } from 'app/shared/layout/ui-elements/buttons';
import { useSnackbar } from 'notistack';
import { PriorityRow } from './priority-row';

export type Props = PropsFromRedux;

export const Priorities = (props: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    const loadInitialData = async () => {
      await props.fetchPrioritiesAsync();
    };
    loadInitialData();
  }, []);

  const saveChanges = async (priority: IPriorityDto) => {
    if (priority?.id > 0) {
      await props.updatePriorityAsync(new PriorityDto({ ...priority }));
      await props.fetchPrioritiesAsync();
      enqueueSnackbar(`Priority ${priority.name} successfully updated.`, { variant: 'success' });
    } else {
      await props.addPriorityAsync(new PriorityDto({ ...priority }));
      enqueueSnackbar(`Priority ${priority.name} successfully added.`, { variant: 'success' });
    }
  };

  return (
    <FullSizeLayout>
      <TableContainer component={Paper} sx={{ padding: 1, width: '100%' }}>
        <TableBody>
          {props.priorities.map((row) => (
            <PriorityRow
              key={row.id ?? 'newPrio'}
              priority={row}
              onSubmit={saveChanges}
              onDelete={async (prio: IPriorityDto) => {
                const isNew = !prio.id;
                await props.deletePriorityAsync(prio.id);
                enqueueSnackbar(isNew ? 'Priority removed.' : 'Priority deleted.', { variant: 'success' });
              }}
              disabled={props.loading}
            />
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={5}>
              <DefaultButton disabled={props.loading} startIcon={<AddIcon />} onClick={() => props.createNewPriority()}>
                Add
              </DefaultButton>
            </TableCell>
          </TableRow>
        </TableFooter>
      </TableContainer>
    </FullSizeLayout>
  );
};

const mapStateToProps = ({ priorityManagement }: IRootState) => ({
  loading: priorityManagement.loading,
  priorities: priorityManagement.priorities,
  selectedPriority: priorityManagement.selectedPriority,
});

const mapDispatchToProps = (dispatch) => {
  const actions = priorityModule.initActions();
  return {
    fetchPrioritiesAsync: async () => await dispatch(actions.fetchPrioritiesAsync()),
    updatePriorityAsync: async (priority: PriorityDto) => await dispatch(actions.updatePriorityAsync(priority)),
    deletePriorityAsync: async (id: number) => await dispatch(actions.deletePriorityAsync(id)),
    addPriorityAsync: async (priority: PriorityDto) => await dispatch(actions.addPriorityAsync(priority)),
    createNewPriority: () => dispatch(actions.createNewPriority()),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Priorities);
