import React from 'react';

import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { AccountMenu } from '../menus';
import { Brand } from './header-components';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

import MenuIcon from '@mui/icons-material/Menu';
import { IconButton, Toolbar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { drawerWidth } from '../theme';

export interface IHeaderProps {
  hasAuthSession: boolean;
  toggleDrawer?: () => void;
  drawerOpen: boolean;
}

export const StyledAppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  overflow: 'hidden',
  backgroundColor: '#666666',
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Header = (props: IHeaderProps) => {
  return (
    <StyledAppBar open={props.drawerOpen}>
      <Toolbar>
        {!props.drawerOpen && (
          <IconButton color="inherit" aria-label="open drawer" onClick={() => props.toggleDrawer()} edge="start">
            <MenuIcon />
          </IconButton>
        )}
        <Typography
          variant="h6"
          color="inherit"
          sx={{
            flexGrow: 1,
          }}
        >
          <Brand />
        </Typography>
        <AccountMenu isAuthenticated={props.hasAuthSession} />
      </Toolbar>
    </StyledAppBar>
  );
};

export default Header;
