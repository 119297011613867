export default {
  GET_PENDING_IMPORTS: 'registration-import-requests/admin/GET_PENDING_IMPORTS',
  IMPORT: 'registration-import-requests/admin/IMPORT',
  DOWNLOAD: 'registration-import-requests/admin/DOWNLOAD',
  GET_VALIDATION: 'registration-import-requests/admin/GET_VALIDATION',
  SET_IS_UNSAVED: 'registration-import-requests/admin/SET_IS_UNSAVED',
  SELECT_IMPORT: 'registration-import-requests/admin/SELECT_IMPORT',
  SET_MAIN_CONTENT_LOADING: 'registration-import-requests/admin/SET_MAIN_CONTENT_LOADING',
  UPDATE_VALIDATION_PREVIEW: 'registration-import-requests/admin/UPDATE_VALIDATION_PREVIEW',
  UPDATE_PENDING_COUNT: 'registration-import-requests/admin/UPDATE_PENDING_COUNT',
  REDUCE_WARNING_COUNT: 'registration-import-requests/admin/REDUCE_WARNING_COUNT',
  SELECT_PHASE: 'registration-import-requests/admin/SELECT_PHASE',
};
