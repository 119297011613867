import { ICommentDto, IModuleRegistrationImport, IStudentImport } from 'app/clients/services';
import { useRootSelector } from 'app/config/root.reducer';
import { CommentDialog } from 'app/shared/layout/parts/comment-dialog';
import React from 'react';

export type DialogProps = {
  isOpen: boolean;
  close: () => void;
  registrationImport: IModuleRegistrationImport;
  studentImport: IStudentImport;
  onAddComment: (comment: ICommentDto) => Promise<void>;
  onRemoveComment: (comment: ICommentDto) => Promise<void>;
  refreshRegistrationInfo: (moduleRegistrationId: number) => Promise<void>;
};

export const ImportRegistrationInfoDialog = (props: DialogProps) => {
  const selectedRegistration = useRootSelector((s) => s.importsManagement.selectedRegistration);
  const isLoading = useRootSelector((s) => s.importsManagement.moduleRegistrationLoading);

  return (
    props.isOpen && (
      <CommentDialog
        registrationInfo={{
          moduleExecutionId: props.registrationImport.moduleExecutionId,
          id: props.registrationImport.databaseId,
          moduleExecutionCode: props.registrationImport.moduleExecutionCode,
          comments: selectedRegistration?.comments,
          history: selectedRegistration?.history,
        }}
        open={props.isOpen}
        onClose={props.close}
        onAddComment={props.onAddComment}
        onRemoveComment={props.onRemoveComment}
        student={{
          id: props.studentImport.dbMapping?.databaseId,
          firstName: props.studentImport.firstName,
          familyName: props.studentImport.familyName,
        }}
        loading={isLoading}
      />
    )
  );
};
