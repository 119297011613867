import { Badge, Tab, Tabs } from '@mui/material';
import { blue } from '@mui/material/colors';
import { trimSlashes } from 'app/shared/util/url-utils';
import React, { useEffect } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

export interface ITabModel {
  label: string;
  url: string;
  useBatch?: boolean;
  batchCount?: number;
  isDefault?: boolean;
}
export interface ITabsProps {
  baseUrl: string;
  tabs: ITabModel[];
  onChange: (tabModel: ITabModel | undefined) => void;
  openTab?: ITabModel;
}

export const NavigationTabs = (props: ITabsProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  const getDefaultTab = () => {
    if (props.openTab) {
      return props.tabs.find((t) => t.url === props.openTab?.url);
    }

    const defaultTab = props.tabs.filter((t) => t.isDefault)[0];
    return defaultTab ?? props.tabs[0];
  };

  const getTabUrl = (tab: ITabModel | undefined): string => {
    if (!tab) {
      return `/${props.baseUrl}`;
    }
    return `${props.baseUrl}/${trimSlashes(tab.url)}`;
  };

  const getCurrentTab = () => {
    return props.tabs.filter((t) => location.pathname.startsWith(getTabUrl(t)))[0] ?? getDefaultTab();
  };

  const getCurrentTabUrl = () => {
    return getTabUrl(getCurrentTab());
  };

  useEffect(() => {
    const currentUrl = trimSlashes(location.pathname);
    const baseUrl = trimSlashes(props.baseUrl);
    if (currentUrl === baseUrl) {
      const defaultTab = getDefaultTab();
      navigate(defaultTab.url);
    }
  }, []);

  return (
    <>
      {props.tabs && props.tabs.length > 0 && (
        <Tabs
          key={location.pathname}
          value={getCurrentTabUrl()}
          onChange={
            props.onChange
              ? (_event, value: string) => {
                  const tab = props.tabs.find((t) => trimSlashes(t.url) === value);
                  props.onChange(tab);
                }
              : () => {}
          }
          color="secondary"
          aria-label="navigation-tabs"
          sx={{ backgroundColor: 'white' }}
        >
          {props.tabs &&
            props.tabs.map((t) => (
              <Tab
                key={t.label}
                label={
                  <Badge badgeContent={t.batchCount ?? 0} color="primary">
                    {t.label}
                  </Badge>
                }
                value={t.url}
                component={NavLink}
                to={t.url}
                sx={{ backgroundColor: getCurrentTab() === t ? blue[50] : 'white' }}
              />
            ))}
          ;
        </Tabs>
      )}
    </>
  );
};
