import CachedIcon from '@mui/icons-material/Cached';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import { Tooltip, Typography } from '@mui/material';
import { ValidationLevel } from 'app/clients/services';
import { getLevelClass } from 'app/shared/util/validation-level.util';
import React from 'react';
import { MappingConfirmation } from './types';

export const MappingInfoIcon = (props: { dbMapping: MappingConfirmation; entity: string }) => {
  const renderIcon = () => {
    if (
      props.dbMapping.validationLevel === ValidationLevel.Error ||
      props.dbMapping.validationLevel === ValidationLevel.Warning
    ) {
      return <FlashOnIcon fontSize="small" />;
    }
    if (props.dbMapping.isUserConfirmed || props.dbMapping.wasJustConfirmed) {
      if (props.dbMapping.isNew) {
        return <FiberNewIcon fontSize="small" />;
      }
      return <ConfirmationNumberIcon fontSize="small" />;
    }

    if (props.dbMapping.isNew) {
      return <FiberNewIcon fontSize="small" />;
    }

    if (props.dbMapping.mappingChanged) {
      return <CachedIcon fontSize="small" />;
    }

    return <DoneOutlineIcon fontSize="small" />;
  };

  const getMessage = () => {
    if (
      props.dbMapping.validationLevel === ValidationLevel.Error ||
      props.dbMapping.validationLevel === ValidationLevel.Warning
    ) {
      return props.dbMapping.conflictMessage;
    }

    if (props.dbMapping.isUserConfirmed || props.dbMapping.wasJustConfirmed) {
      if (props.dbMapping.isNew) {
        return props.dbMapping.wasJustConfirmed
          ? `${props.entity} was just confirmed as new by you.`
          : `${props.entity} was confirmed as new by ${props.dbMapping.confirmedByName}.`;
      }

      if (props.dbMapping.identifier) {
        return props.dbMapping.wasJustConfirmed
          ? `${props.entity} relation to '${props.dbMapping.identifier}' was just confirmed by you.`
          : `${props.entity} relation to '${props.dbMapping.identifier}' was confirmed by ${props.dbMapping.confirmedByName}.`;
      }

      if (props.dbMapping.oldIdentifier) {
        return props.dbMapping.wasJustConfirmed
          ? `${props.entity} relation to '${props.dbMapping.oldIdentifier}' was just confirmed by you.`
          : `${props.entity} relation to '${props.dbMapping.oldIdentifier}' was confirmed by ${props.dbMapping.confirmedByName}.`;
      }

      return props.dbMapping.wasJustConfirmed
        ? `${props.entity} relation was just confirmed by you.`
        : `${props.entity} relation was confirmed by ${props.dbMapping.confirmedByName}.`;
    }

    if (props.dbMapping.isNew) {
      return `${props.entity} is not in the database yet.`;
    }

    if (props.dbMapping.mappingChanged) {
      return `${props.entity} relation changed from '${props.dbMapping.oldIdentifier}' to '${props.dbMapping.identifier}`;
    }

    return `${props.entity} '${
      props.dbMapping.oldIdentifier ?? props.dbMapping.identifier
    }' is already in the database.`;
  };

  if (!props.dbMapping) {
    return <></>;
  }

  return (
    <Tooltip title={<Typography>{getMessage()}</Typography>}>
      <span className={getLevelClass(props.dbMapping.validationLevel)} style={{ paddingLeft: 5 }}>
        {renderIcon()}
      </span>
    </Tooltip>
  );
};
