/* eslint-disable unused-imports/no-unused-imports */
import DeleteIcon from '@mui/icons-material/DeleteForever';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import {
  ICommentDto,
  ILabelDto,
  IModuleExecutionDto,
  IModuleRegistrationDto,
  IRegistrationStateChangeDto,
  IUserDto,
  RegistrationState,
} from 'app/clients/services';
import { CommentDialog } from 'app/shared/layout/parts/comment-dialog';
import {
  RegistrationColumnFilter,
  getModuleRegistrationCols,
  getModuleRegistrationInfoCols,
} from 'app/shared/layout/parts/registration-cols';
import { RegistrationCommentIcon } from 'app/shared/layout/parts/registration-comment-icon';
import { RegistrationEditIcon } from 'app/shared/layout/parts/registration-edit-icon';
import { ConfirmationDialog } from 'app/shared/layout/ui-elements/confirmation-dialog';
import { StandardDataGrid } from 'app/shared/layout/ui-elements/standard-data-grid';
import React, { useState } from 'react';
import { EditModuleRegistrationDialog } from './edit-module-registration-dialog';

type ModulRegistrationsProps = {
  isAdmin: boolean;
  currentUser: IUserDto;
  labels?: ILabelDto[];
  moduleExecutions?: IModuleExecutionDto[];
  rows: IModuleRegistrationDto[];
  coveredModules?: number[];
  loading: boolean;
  columnFilter: RegistrationColumnFilter;
  showInfos?: boolean;
  delete?: (m: IModuleRegistrationDto) => Promise<void>;
  onAddComment: (m: ICommentDto) => Promise<void>;
  onRemoveComment: (m: ICommentDto) => Promise<void>;
  save?: (m: IModuleRegistrationDto, comment: ICommentDto) => Promise<void>;
  getHistory: (registrationId: number) => Promise<IRegistrationStateChangeDto[]>;
  children?: (columns: GridColDef[]) => React.ReactNode;
};

export const ModuleRegistrations = (props: ModulRegistrationsProps) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<IModuleRegistrationDto>(null);
  const [editDialogOpen, setEditDialogOpen] = useState<IModuleRegistrationDto>(null);
  const [commentDialogOpen, setCommentDialogOpen] = useState<IModuleRegistrationDto>(null);

  const states = [
    RegistrationState.CONFIRMED,
    RegistrationState.REJECTED,
    RegistrationState.REQUESTED,
    RegistrationState.WITHDRAWN,
    RegistrationState.CANCELED,
    RegistrationState.ABSENTEX,
  ];

  const cols = props.showInfos
    ? getModuleRegistrationInfoCols(props.columnFilter)
    : getModuleRegistrationCols(props.columnFilter);

  const finalColumns: GridColDef[] = [
    {
      field: 'id',
      headerName: props.isAdmin && props.save && props.delete ? 'Actions' : 'Comments',
      width: props.isAdmin && props.save && props.delete ? 180 : 80,
      renderCell(params: GridCellParams) {
        const m = params.row as IModuleRegistrationDto;
        return (
          <>
            <RegistrationCommentIcon
              data={m}
              onClick={() => setCommentDialogOpen(m)}
              loading={props.loading}
              isAdmin={props.isAdmin}
            />
            {props.isAdmin && (
              <>
                {props.save && (
                  <RegistrationEditIcon loading={props.loading} onClick={() => setEditDialogOpen(m)} data={m} />
                )}
                {props.delete && (
                  <Tooltip title={<Typography>Delete</Typography>}>
                    <IconButton
                      style={{ display: 'inline-block' }}
                      disabled={props.loading}
                      onClick={(event) => {
                        setDeleteDialogOpen(m);
                        event.stopPropagation();
                      }}
                      color="secondary"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            )}
          </>
        );
      },
      sortable: false,
    },
    ...cols.columns,
  ];

  const getSelectableModuleExecutions = (me: IModuleRegistrationDto) => {
    if (!me) {
      return [];
    }
    if (me.id === 0) {
      if (props.coveredModules) {
        const coveredModuleIds = [
          ...props.coveredModules,
          props.moduleExecutions?.filter((e) => e.moduleId !== me.moduleId).map((m) => m.moduleId) ?? [],
        ];
        return (
          props.moduleExecutions?.filter((e) => coveredModuleIds.filter((m) => m === e.moduleId).length === 0) ?? []
        );
      }
      return props.moduleExecutions ?? [];
    }
    return props.moduleExecutions?.filter((m) => m.moduleId === me.moduleId) ?? [];
  };

  return (
    <>
      {commentDialogOpen && (
        <CommentDialog
          registrationInfo={{
            id: commentDialogOpen.id,
            moduleExecutionId: commentDialogOpen.moduleExecutionId,
            history: commentDialogOpen.history,
            comments: commentDialogOpen.comments,
            moduleExecutionCode: commentDialogOpen.moduleExecution,
          }}
          open={commentDialogOpen !== null}
          onAddComment={props.onAddComment}
          onRemoveComment={props.onRemoveComment}
          student={
            commentDialogOpen && commentDialogOpen.studentId
              ? {
                  id: commentDialogOpen.studentId,
                  firstName: commentDialogOpen.studentFirstName,
                  familyName: commentDialogOpen.studentFamilyName,
                }
              : null
          }
          onClose={() => setCommentDialogOpen(null)}
          loading={false}
        />
      )}
      {props.isAdmin && (
        <>
          {props.delete && (
            <ConfirmationDialog
              title="Delete Module Registration"
              onConfirm={() => props.delete(deleteDialogOpen)}
              open={deleteDialogOpen !== null}
              onClose={() => setDeleteDialogOpen(null)}
            >
              Please consider changing the state instead of deleting! Do you really want to delete the module
              registration for <strong>{deleteDialogOpen?.moduleExecution}</strong>?
            </ConfirmationDialog>
          )}
          {props.save && (
            <EditModuleRegistrationDialog
              open={editDialogOpen !== null}
              onClose={() => {
                setEditDialogOpen(null);
              }}
              onSubmit={async (c, comment) => {
                await props.save(c, comment);
              }}
              isAdmin={props.isAdmin}
              labels={props.labels}
              states={states}
              moduleExecutions={getSelectableModuleExecutions(editDialogOpen)}
              moduleRegistration={editDialogOpen}
              student={null}
              getHistory={props.getHistory}
            />
          )}
        </>
      )}
      {props.children ? (
        props.children(finalColumns)
      ) : (
        <StandardDataGrid
          columns={finalColumns}
          rows={props.rows}
          loading={props.loading}
          pageSize={100}
          columnVisibilityModel={cols.visibilityModel}
        ></StandardDataGrid>
      )}
    </>
  );
};
