import DeleteIcon from '@mui/icons-material/Delete';
import GetAppIcon from '@mui/icons-material/GetApp';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Button, SxProps, Tooltip, Typography } from '@mui/material';
import React, { ReactNode } from 'react';

export type ButtonAction = 'preview' | 'delete' | 'download';

type Props = {
  color?: 'primary' | 'secondary' | 'inherit' | 'success' | 'error' | 'info' | 'warning';
  onClick: () => void;
  caption?: string;
  title?: string;
  hideLabel?: boolean;
  buttonAction: ButtonAction;
};

const getIcon = (buttonAction: ButtonAction) => {
  switch (buttonAction) {
    case 'preview':
      return <VisibilityIcon></VisibilityIcon>;
    case 'delete':
      return <DeleteIcon></DeleteIcon>;
    case 'download':
      return <GetAppIcon></GetAppIcon>;
    default:
      return <></>;
  }
};

const getActionTitle = (buttonAction: ButtonAction) => {
  switch (buttonAction) {
    case 'preview':
      return 'Preview';
    case 'delete':
      return 'Delete';
    case 'download':
      return 'Download';
    default:
      return <></>;
  }
};

export const ActionButton = (props: Props) => {
  return (
    <Tooltip title={<Typography>{props.caption ?? props.title ?? getActionTitle(props.buttonAction)}</Typography>}>
      <Button
        variant="contained"
        component="label"
        color={props.color ?? 'inherit'}
        onClick={props.onClick}
        startIcon={getIcon(props.buttonAction)}
      >
        {!props.hideLabel && (props.title ?? getActionTitle(props.buttonAction))}
      </Button>
    </Tooltip>
  );
};

type ButtonPlacement = 'default' | 'side-bar' | 'shifted-right' | 'top-margin';

type ButtonProps = {
  primary?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  caption?: ReactNode;
  children: ReactNode;
  startIcon?: JSX.Element;
  placement?: ButtonPlacement;
  sx?: SxProps;
};

const getSxProps = (originalSx: SxProps, placement?: string) => {
  switch (placement) {
    case 'side-bar':
      return { ...originalSx, marginLeft: 2, marginBottom: 2 };
    case 'shifted-right':
      return { ...originalSx, marginLeft: 2 };
    case 'top-margin':
      return { ...originalSx, marginTop: 5 };
    default:
      return originalSx;
  }
};

export const DefaultButton = (props: ButtonProps) => {
  const sx = getSxProps(props.sx, props.placement);

  const MyButton = () => (
    <Button
      variant="contained"
      color={props.primary ? 'primary' : 'inherit'}
      onClick={() => {
        props.onClick();
      }}
      startIcon={props.startIcon}
      disabled={props.disabled}
      sx={sx}
    >
      {props.children}
    </Button>
  );

  if (props.caption) {
    return (
      <Tooltip title={<Typography>{props.caption}</Typography>}>
        <span>
          <MyButton />
        </span>
      </Tooltip>
    );
  }
  return <MyButton />;
};
