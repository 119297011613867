import initActions from './actions';
import types from './types';
import reducer from './reducer';

import { IModuleManagementClient } from 'app/clients/services';
import { resolver } from 'app/clients/service-registry';

import moduleSelectionModule from '../../selections/module-selection';

export type { ModuleManagementState } from './reducer';
export default {
  initActions: () => initActions(resolver.resolve<IModuleManagementClient>('IModuleManagementClient'), moduleSelectionModule.initActions()),
  types,
  reducer,
};
