import { IPriorityClient } from 'app/clients/services';
import initActions from './actions';
import types from './types';
import reducer from './reducer';
import { resolver } from 'app/clients/service-registry';

export type { PriorityManagementState } from './reducer';
export default {
  initActions: () => initActions(resolver.resolve<IPriorityClient>('IPriorityClient')),
  types,
  reducer,
};
