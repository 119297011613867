import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
} from '@mui/material';
import {
  CommentDto,
  ICommentDto,
  ILabelDto,
  IModuleExecutionDto,
  IModuleRegistrationDto,
  IRegistrationStateChangeDto,
  RegistrationState,
} from 'app/clients/services';
import { APP_DATE_FORMAT } from 'app/config/constants';
import { FastField, Field, Form, Formik } from 'formik';
import { CheckboxWithLabel, TextField as FormikTextField, Select } from 'formik-mui';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { FormikDatePicker } from '../ui-elements/date-picker';
import { StateChanges } from './state-changes';

// const useStyles = makeStyles((_theme: Theme) =>
//   createStyles({
//     paper: {
//       width: '80%',
//       maxHeight: 435,
//     },
//   }),
// );

type EditModuleRegistrationProps = {
  open: boolean;
  moduleRegistration: IModuleRegistrationDto;
  moduleExecutions: IModuleExecutionDto[];
  states: RegistrationState[];
  onSubmit: (moduleRegistration: IModuleRegistrationDto, comment: ICommentDto | null) => Promise<void>;
  onClose: () => void;
  getHistory: (id: number) => Promise<IRegistrationStateChangeDto[]>;
  isAdmin: boolean;
  labels: ILabelDto[];
  student?: {
    id: string;
    firstName: string;
    familyName: string;
  };
};

export const EditModuleRegistrationDialog = (props: EditModuleRegistrationProps) => {
  // const classes = useStyles();

  const [loading, setLoading] = useState<boolean>(false);
  const [stateChanges, setStateChanges] = useState<IRegistrationStateChangeDto[]>([]);
  useEffect(() => {
    const loadStateChanges = async () => {
      setLoading(true);
      try {
        const result = await props.getHistory(props.moduleRegistration?.id);
        setStateChanges(result ?? []);
        setLoading(false);
      } catch {
        setStateChanges([]);
        setLoading(false);
      }
    };

    if (props.open) {
      loadStateChanges();
    }
  }, [props.open]);

  const getModuleExecutions = (r: IModuleRegistrationDto) => {
    if (r.id > 0) {
      return props.moduleExecutions.filter((m) => m.moduleId === r.moduleId);
    }

    return props.moduleExecutions;
  };

  return (
    <>
      {props.moduleRegistration && (
        <Formik
          enableReinitialize
          initialValues={{ ...props.moduleRegistration, comment: '', isInternal: false, showInOverview: false }}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            props
              .onSubmit(
                values as IModuleRegistrationDto,
                values.comment
                  ? new CommentDto({
                      id: 0,
                      registrationId: props.moduleRegistration.id,
                      comment: values.comment,
                      isInternal: props.isAdmin ? values.isInternal : false,
                      createdDate: DateTime.local(),
                      showInOverview: values.showInOverview,
                    })
                  : null,
              )
              .then(() => {
                setSubmitting(false);
                resetForm();
                props.onClose();
              });
          }}
          validationSchema={Yup.object({
            moduleExecutionId: Yup.number().required('required').moreThan(0),
            dateOfRegistration: Yup.date().required('required'),
            comment: Yup.string()
              .ensure()
              .when(['state', 'moduleExecutionId'], {
                is: (state: RegistrationState, moduleExecutionId: number) =>
                  state !== props.moduleRegistration.state ||
                  moduleExecutionId !== props.moduleRegistration.moduleExecutionId ||
                  props.moduleRegistration.id === 0,
                then: () =>
                  Yup.string().required(
                    props.moduleRegistration?.id === 0
                      ? 'Please enter a comment to explain why you want to add this module registration manually.'
                      : 'Please enter a comment to explain why you want to change the registration.',
                  ),
              }),
          })}
        >
          {({ submitForm, isSubmitting, values, resetForm }) => (
            <Form>
              <Dialog
                disableEscapeKeyDown
                maxWidth="lg"
                open={props.open}
                /* className={classes.paper} */
                style={{ minWidth: '200px', minHeight: '800px' }}
              >
                <DialogTitle>
                  {props.moduleRegistration.id > 0 ? 'Edit Module Registration' : 'Add Module Registration'}
                </DialogTitle>
                <DialogContent dividers>
                  {isSubmitting || loading ? (
                    <LinearProgress></LinearProgress>
                  ) : (
                    <>
                      {props.moduleRegistration.moduleExecutionId > 0 && (
                        <div>
                          Module registration{' '}
                          <strong>{props.moduleExecutions.find((m) => m.id === values.moduleExecutionId)?.code}</strong>
                          {props.student && (
                            <>
                              {' '}
                              of student{' '}
                              <strong>
                                <Link to={`/student-management/student/${props.student.id}`}>
                                  {props.student.firstName} {props.student.familyName}
                                </Link>
                              </strong>
                            </>
                          )}
                          .
                        </div>
                      )}
                      {props.moduleRegistration.id > 0 && stateChanges?.length > 0 && (
                        <>
                          <strong>History:</strong>
                          <StateChanges
                            changes={stateChanges}
                            moduleExecutionId={props.moduleRegistration.moduleExecutionId}
                          />
                        </>
                      )}
                      <Grid container sx={{ marginTop: 3, padding: 1 }} spacing={2}>
                        <Grid item xs={4}>
                          <FastField
                            variant="standard"
                            component={FormikDatePicker}
                            name={`dateOfRegistration`}
                            label="Date of Registration"
                            format={APP_DATE_FORMAT}
                            autoOk
                            disabled={!props.isAdmin}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <FormControl style={{ width: '100%' }}>
                            <InputLabel htmlFor={`moduleExecutionId`} shrink>
                              Module Execution
                            </InputLabel>
                            <FastField
                              component={Select}
                              variant="standard"
                              name={`moduleExecutionId`}
                              inputProps={{
                                id: `moduleExecutionId`,
                              }}
                              disabled={!props.isAdmin || getModuleExecutions(props.moduleRegistration).length <= 1}
                            >
                              <MenuItem value={0}></MenuItem>
                              {getModuleExecutions(props.moduleRegistration).map((m, k) => (
                                <MenuItem key={k} value={m.id}>
                                  {m.code}
                                </MenuItem>
                              ))}
                            </FastField>
                          </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                          <FormControl style={{ width: '100%' }}>
                            <InputLabel htmlFor={`state`} shrink>
                              Registration State
                            </InputLabel>
                            <FastField
                              variant="standard"
                              component={Select}
                              name={`state`}
                              inputProps={{
                                id: `state`,
                              }}
                              disabled={!props.isAdmin}
                            >
                              {props.states.map((s, sk) => (
                                <MenuItem key={sk} value={s}>
                                  {s.toString()}
                                </MenuItem>
                              ))}
                            </FastField>
                          </FormControl>
                        </Grid>
                        {props.isAdmin && props.labels?.length > 0 && (
                          <Grid item xs={4} sx={{ padding: 1 }}>
                            <FormControl style={{ width: '100%' }}>
                              <InputLabel htmlFor={`specialLabelId`} shrink>
                                Special Label
                              </InputLabel>
                              <Field
                                variant="standard"
                                component={Select}
                                name={`specialLabelId`}
                                inputProps={{
                                  id: `specialLabelId`,
                                }}
                              >
                                <MenuItem key={-1} value={''}>
                                  None
                                </MenuItem>
                                {props.labels.map((l, k) => (
                                  <MenuItem key={k} value={l.id}>
                                    {l.name}
                                  </MenuItem>
                                ))}
                              </Field>
                            </FormControl>
                          </Grid>
                        )}
                        {props.isAdmin && (
                          <Grid item xs={6}>
                            <FastField
                              component={CheckboxWithLabel}
                              type="checkbox"
                              name="compensationForDisadvantages"
                              Label={{ label: 'Compensation for Disadvantages' }}
                              disabled={!props.isAdmin}
                            />
                          </Grid>
                        )}
                        {(props.moduleRegistration.state !== values.state ||
                          props.moduleRegistration.moduleExecutionId !== values.moduleExecutionId ||
                          props.moduleRegistration.id === 0) && (
                          <Grid item xs={12}>
                            <FastField
                              variant="standard"
                              component={FormikTextField}
                              name="comment"
                              disabled={isSubmitting}
                              label={
                                props.moduleRegistration.id !== 0
                                  ? props.moduleRegistration.state !== values.state
                                    ? 'Reason for State Change'
                                    : 'Reason for Module Execution Change'
                                  : 'Reason for manually adding Registration'
                              }
                              multiline
                              rows={4}
                              style={{ width: '100%' }}
                              type="text"
                            />
                          </Grid>
                        )}
                      </Grid>
                    </>
                  )}
                </DialogContent>
                <DialogActions>
                  <Button
                    autoFocus
                    onClick={() => {
                      resetForm();
                      props.onClose();
                    }}
                    variant="contained"
                    color="inherit"
                    disabled={isSubmitting}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={submitForm}
                    color="primary"
                    variant="contained"
                    disabled={isSubmitting}
                    type="submit"
                  >
                    OK
                  </Button>
                </DialogActions>
              </Dialog>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};
