/* eslint-disable complexity */
import { ISummaryFilterDto, SummaryFilterDto } from 'app/clients/services';
import ACTION_TYPES from './types';

const initialState = {
  loading: false,
  filter: new SummaryFilterDto() as ISummaryFilterDto,
};

export type SummaryState = Readonly<typeof initialState>;

// Reducer
export default (state: SummaryState = initialState, action): SummaryState => {
  switch (action.type) {
    case ACTION_TYPES.SET_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case ACTION_TYPES.SET_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default:
      return state;
  }
};
