import { ImportState, IValidationCountDto, IValidationMessage, ValidationLevel, ValidationMessage } from 'app/clients/services';

const getValue = (level: ValidationLevel) => {
  if (level === ValidationLevel.Success) {
    return 1;
  }

  if (level === ValidationLevel.Info) {
    return 2;
  }

  if (level === ValidationLevel.Warning) {
    return 3;
  }

  return 4;
};

export const isLowerOrEqual = (first: ValidationLevel, second: ValidationLevel) => {
  return getValue(first) <= getValue(second);
};

export const isHigherOrEqual = (first: ValidationLevel, second: ValidationLevel) => {
  return getValue(first) >= getValue(second);
};

export const getLevelClass = (level: ValidationLevel) => {
  return 'MuiAlert-standard' + level;
};

export const getValidationLevelColor = (level: ValidationLevel) => {
  if (level === ValidationLevel.Success) {
    return 'success';
  }

  if (level === ValidationLevel.Info) {
    return 'info';
  }

  if (level === ValidationLevel.Warning) {
    return 'warning';
  }

  return 'error';
};

const prepend = (newPart: string, message: string, partsCount: number) => {
  if (partsCount === 1) {
    return newPart + ' and ' + message;
  }
  if (partsCount > 1) {
    return newPart + ', ' + message;
  }
  return newPart;
};

const summary = (count: IValidationCountDto, usePast: boolean) => {
  let message = '';
  let countParts = 0;
  if (count.advisorChanges > 0) {
    if (count.advisorChanges === 1) {
      message = prepend(`${count.advisorChanges} ${count.advisorChanges === 1 ? 'advisor' : 'advisors'} `, message, countParts);
    } else {
      message = prepend(`${count.advisorChanges} ${count.advisorChanges === 1 ? 'advisor' : 'advisors'} `, message, countParts);
    }
    countParts++;
  }

  if (count.studentChanges > 0) {
    if (count.studentChanges === 1) {
      message = prepend(`${count.studentChanges} ${count.studentChanges === 1 ? 'student' : 'students'} `, message, countParts);
    } else {
      message = prepend(`${count.studentChanges} ${count.studentChanges === 1 ? 'student' : 'students'} `, message, countParts);
    }

    countParts++;
  }

  if (count.registrationChanges > 0) {
    message = prepend(
      `${count.registrationChanges} ${count.registrationChanges === 1 ? 'registration' : 'registrations'} `,
      message,
      countParts
    );
  }

  const sumChanges = count.advisorChanges + count.studentChanges + count.registrationChanges;
  if (sumChanges > 0) {
    if (usePast) {
      message = sumChanges === 1 ? message + ' was added or has changed. ' : message + ' were added or have changed. ';
    } else {
      message = message + ' will be added or changed. ';
    }
  }

  if (count.userConfirmations > 0) {
    return count.userConfirmations === 1
      ? `${count.userConfirmations} questions was confirmed. ` + message
      : `${count.userConfirmations} questions were confirmed. ` + message;
  }

  return message;
};

const getLevel = (count: IValidationCountDto, noRights: boolean) => {
  if (count.errors > 0) {
    return ValidationLevel.Error;
  }
  if (count.warnings > 0 || noRights) {
    return ValidationLevel.Warning;
  }
  if (count.infos > 0 || count.userConfirmations > 0) {
    return ValidationLevel.Info;
  }
  return ValidationLevel.Success;
};

export const createResultMessage = (count: IValidationCountDto, noRights: boolean, state?: ImportState): IValidationMessage => {
  const hasChanges = count.studentChanges > 0 || count.advisorChanges > 0 || count.registrationChanges > 0;

  if (count.errors > 0) {
    return new ValidationMessage({
      message: count.errors === 1 ? `An error occured during the validation.` : `${count.errors} errors occured during the validation.`,
      correctionHint: `Please correct your excel file and try again or try to press 'validate with corrections'.`,
      level: getLevel(count, noRights),
      noRights: false,
      code: 0,
    });
  }

  if (count.warnings > 0) {
    if (count.warnings === 1 && noRights) {
      return new ValidationMessage({
        message: `Your excel file was not compared with any registration data from the database.`,
        correctionHint: `Please log in for further validation.`,
        level: getLevel(count, noRights),
        noRights: true,
        code: 0,
      });
    }

    if (count.unconfirmedConfirmations > 0) {
      return new ValidationMessage({
        message:
          count.unconfirmedConfirmations === 1
            ? `There is an unconfirmed question that needs to be confirmed.`
            : `There are ${count.unconfirmedConfirmations} unconfirmed questions that need to be confirmed.`,
        correctionHint: `Please confirm all questions and before you continue.`,
        level: getLevel(count, noRights),
        noRights: false,
        code: 0,
      });
    }

    return new ValidationMessage({
      message:
        count.warnings === 1
          ? `There is a warning that needs to be corrected.`
          : `There are ${count.warnings} warnings that need to be corrected.`,
      correctionHint: `Please correct the data and try again.`,
      level: getLevel(count, noRights),
      noRights: false,
      code: 0,
    });
  }

  if (noRights) {
    return new ValidationMessage({
      message: `Your excel file was not compared with any registration data from the database.`,
      correctionHint: `Please log in for further validation.`,
      level: getLevel(count, noRights),
      noRights: true,
      code: 0,
    });
  }

  if (state === ImportState.REQUESTED) {
    if (!hasChanges) {
      return new ValidationMessage({
        message: `The validation was successful but there are no changes to the current data. ${summary(count, false)}
        An import will not cause any changes.`,
        correctionHint: `Please press on import to import the file.`,
        level: getLevel(count, noRights),
        noRights: false,
        code: 0,
      });
    }

    return new ValidationMessage({
      message: `The validation was successful. ${summary(count, false)}
        You can now start the import.`,
      correctionHint: `Please press on import to import the file.`,
      level: getLevel(count, noRights),
      noRights: false,
      code: 0,
    });
  }

  if (
    state === ImportState.IMPORTED ||
    state === ImportState.REJECTED ||
    state === ImportState.DELETED ||
    state === ImportState.WITHDRAWN
  ) {
    if (!hasChanges) {
      return new ValidationMessage({
        message: `The validation was successful but there were no changes to the data at that time. ${summary(count, true)}`,
        level: getLevel(count, noRights),
        noRights: false,
        code: 0,
      });
    }

    return new ValidationMessage({
      message: `The validation was successful. ${summary(count, false)}`,
      correctionHint: `Please press on submit to submit your registrations.`,
      level: getLevel(count, noRights),
      noRights: false,
      code: 0,
    });
  }

  if (!hasChanges) {
    return new ValidationMessage({
      message: `The validation was successful but there are no changes to the current data. ${summary(count, false)}`,
      correctionHint: `Please press on submit to submit your registrations.`,
      level: getLevel(count, noRights),
      noRights: false,
      code: 0,
    });
  }

  return new ValidationMessage({
    message: `The validation was successful. ${summary(count, false)}
    You can now submit your registrations.`,
    correctionHint: `Please press on submit to submit your registrations.`,
    level: getLevel(count, noRights),
    noRights: false,
    code: 0,
  });
};
