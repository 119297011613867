export default {
  GOTO_STEP: 'registration-import/GOTO_STEP',
  NEXT_STEP: 'registration-import/NEXT_STEP',
  LAST_STEP: 'registration-import/LAST_STEP',
  RESET: 'registration-import/RESET',
  GET_UAS: 'registration-import/GET_UAS',
  VALIDATE: 'registration-import/VALIDATE',
  VALIDATE_NORMALIZED: 'registration-import/VALIDATE_NORMALIZED',
  UPDATE_REGISTRATION_IMPORT: 'registration-import/UPDATE_REGISTRATION_IMPORT',
  SUBMIT: 'registration-import/SUBMIT',
  ADD_FILE: 'registration-import/ADD_FILE',
  SELECT_FILE: 'registration-import/SELECT_FILE',
  SELECT_UAS: 'registration-import/SELECT_UAS',
  REDUCE_WARNING_COUNT: 'registration-import/REDUCE_WARNING_COUNT',
};
