import Icon from '@mui/icons-material/Comment';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { ChangeType } from 'app/clients/services';
import React from 'react';

type Props = {
  loading: boolean;
  onClick: () => void;
  isAdmin: boolean;
  data: {
    id: number;
    changeType: ChangeType;
    comment?: string;
    internalComment?: string;
    lastStateUpdateComment?: string;
    fileImport?: string;
  };
};

export const RegistrationCommentIcon = (props: Props) => {
  const getTooltipComment = (allProps: Props) => {
    if (allProps.data.id === 0) {
      return 'not saved yet';
    }

    if (allProps.data.lastStateUpdateComment) {
      return allProps.data.lastStateUpdateComment;
    }

    if (allProps.data.comment) {
      return allProps.data.comment;
    }

    if (allProps.isAdmin && allProps.data.internalComment) {
      return allProps.data.internalComment;
    }

    if (allProps.data.changeType === ChangeType.Prioritization) {
      return `Updated in Registration Process`;
    }

    if (allProps.data.changeType === ChangeType.FileImport) {
      return `File Import '${allProps.data.fileImport}'`;
    }

    if (allProps.data.changeType === ChangeType.Manual) {
      return `Changed Manually`;
    }

    return `Changed Manually by an Administrator`;
  };

  return (
    <Tooltip title={<Typography>{getTooltipComment(props)}</Typography>}>
      <IconButton
        disabled={props.loading}
        onClick={(event) => {
          props.onClick();
          event.stopPropagation();
        }}
        color={
          props.data.changeType === ChangeType.Manual ||
          props.data.changeType === ChangeType.ManualByAdmin ||
          !!props.data.comment ||
          !!props.data.lastStateUpdateComment ||
          (props.isAdmin && !!props.data.internalComment)
            ? 'primary'
            : 'secondary'
        }
      >
        <Icon />
      </IconButton>
    </Tooltip>
  );
};
