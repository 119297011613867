import initActions from './actions';
import types from './types';
import reducer from './reducer';
import { IRegistrationClient, IRegistrationEditClient, IRegistrationExportClient } from 'app/clients/services';
import { resolver } from 'app/clients/service-registry';

import moduleSelectionModule from '../../selections/module-selection';

export type { ModuleRegistrationState } from './reducer';
export default {
  initActions: dispatch =>
    initActions(
      dispatch,
      resolver.resolve<IRegistrationClient>('IRegistrationClient'),
      resolver.resolve<IRegistrationExportClient>('IRegistrationExportClient'),
      resolver.resolve<IRegistrationEditClient>('IRegistrationEditClient'),
      moduleSelectionModule.initActions()
    ),
  types,
  reducer,
};
