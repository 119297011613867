import { AsyncThunk } from 'app/shared/util/action-type.util';

import ACTION_TYPES from './types';
import {
  IAcademicYearDto,
  IModuleCategoryDto,
  IModuleDto,
  IModuleExecutionDto,
  IModuleSelectionClient,
  IModuleStructureDto,
  ISemesterDto,
  ISemesterInfoDto,
} from 'app/clients/services';
import { SemesterSelectionActions } from '../semester-selection/actions';

const initActions = (moduleSelectionClient: IModuleSelectionClient, semesterSelectionActions: SemesterSelectionActions) => {
  // Thunks
  const loadExecutionsAsync: (academicYearId?: number) => AsyncThunk<{ academicYearId: number; executions: IModuleExecutionDto[] }> =
    academicYearId => async dispatch => {
      const getModuleExecutionsAsync = async () => {
        const executions = await moduleSelectionClient.getModuleExecutions(academicYearId);
        return {
          academicYearId,
          executions,
        };
      };
      const result = await dispatch({
        type: ACTION_TYPES.FETCH_MODULE_EXECUTIONS,
        payload: getModuleExecutionsAsync(),
      });
      return result.value;
    };

  const resetExecutions = () => ({ type: ACTION_TYPES.RESET_EXECUTIONS });

  const getExecutionsForSemesterIdAsync: (semesterId: number) => AsyncThunk<IModuleExecutionDto[]> = semesterId => async dispatch => {
    const semester = dispatch(semesterSelectionActions.getSemester(semesterId));
    const forYear = await dispatch(getExecutionsForAcademicYearAsync(semester.academicYearId));
    return forYear.filter(e => e.semesterId === semester.id);
  };

  const getExecutionsForSemesterAsync: (semester: ISemesterDto) => AsyncThunk<IModuleExecutionDto[]> = semester => async dispatch => {
    const forYear = await dispatch(getExecutionsForAcademicYearAsync(semester.academicYearId));
    return forYear.filter(e => e.semesterId === semester.id);
  };

  const getExecutionsForAcademicYearAsync: (academicYearId?: number) => AsyncThunk<IModuleExecutionDto[]> =
    academicYearId => async (dispatch, getState) => {
      const state = getState().moduleSelection;
      return state.executions[academicYearId]
        ? state.executions[academicYearId]
        : (await dispatch(loadExecutionsAsync(academicYearId))).executions;
    };

  const loadExecutionAsync: (executionId?: number) => AsyncThunk<IModuleExecutionDto> = executionId => async dispatch => {
    const result = await dispatch({
      type: ACTION_TYPES.LOAD_MODULE_EXECUTION,
      payload: moduleSelectionClient.getModuleExecution(executionId),
    });
    return result.value;
  };

  const loadStructureAsync: () => AsyncThunk<IModuleStructureDto> = () => async dispatch => {
    const result = await dispatch({
      type: ACTION_TYPES.GET_MODULE_FILTER,
      payload: moduleSelectionClient.getModuleStructure(),
    });
    return result.value;
  };

  const changeSemesterFilterAsync: (
    academicYear?: IAcademicYearDto,
    semester?: ISemesterInfoDto
  ) => AsyncThunk<{
    category: IModuleCategoryDto;
    module?: IModuleDto;
    execution?: IModuleExecutionDto;
    semester: ISemesterInfoDto;
    executions: IModuleExecutionDto[];
  }> = (academicYear, semester) => async (dispatch, getState) => {
    const selection = getState().moduleSelection;
    const result = semester
      ? dispatch(semesterSelectionActions.selectSemester(semester))
      : dispatch(semesterSelectionActions.selectAcademicYear(academicYear));
    const executions = await dispatch(getExecutionsForAcademicYearAsync(result.semester?.academicYearId ?? result.academicYear?.id));
    dispatch({ type: ACTION_TYPES.SELECT_MODULE_EXECUTION, payload: null });
    return {
      category: selection.selectedCategory,
      module: selection.selectedModule,
      execution: null,
      semester: result.semester,
      executions,
    };
  };

  const selectExecutionAsync: (moduleExecuion?: IModuleExecutionDto) => AsyncThunk<{
    category: IModuleCategoryDto;
    module?: IModuleDto;
    execution?: IModuleExecutionDto;
    semester: ISemesterInfoDto;
    executions: IModuleExecutionDto[];
  }> = moduleExecuion => async (dispatch, getState) => {
    const semesterSelectionState = getState().semesterSelection;
    if (!moduleExecuion) {
      const moduleSelectionState = getState().moduleSelection;
      dispatch({ type: ACTION_TYPES.SELECT_MODULE_EXECUTION, payload: null });
      const executions = await dispatch(getExecutionsForAcademicYearAsync(semesterSelectionState.selectedSemester.academicYearId));
      return {
        semester: semesterSelectionState.selectedSemester,
        module: moduleSelectionState.selectedModule,
        category: moduleSelectionState.selectedCategory,
        execution: null,
        executions,
      };
    }

    const semesterResult = dispatch(semesterSelectionActions.selectSemesterById(moduleExecuion.semesterId));
    const semester = semesterResult.semester;
    const executions = await dispatch(getExecutionsForAcademicYearAsync(semester.academicYearId));
    const selection = getState().moduleSelection;
    const category = selection.moduleCategories?.find(a => a.id === moduleExecuion.categoryId);
    if (selection.selectedCategory?.id !== moduleExecuion.categoryId) {
      dispatch({ type: ACTION_TYPES.SELECT_MODULE_CATEGORY, payload: category });
    }

    const module = selection.modules?.find(a => a.id === moduleExecuion.moduleId);
    if (selection.selectedModule?.id !== moduleExecuion.moduleId) {
      dispatch({ type: ACTION_TYPES.SELECT_MODULE, payload: module });
    }

    dispatch({ type: ACTION_TYPES.SELECT_MODULE_EXECUTION, payload: moduleExecuion });

    return {
      semester,
      module,
      category,
      execution: moduleExecuion,
      executions,
    };
  };

  const selectModuleAsync: (module?: IModuleDto) => AsyncThunk<{
    category: IModuleCategoryDto;
    module?: IModuleDto;
    execution?: IModuleExecutionDto;
    semester: ISemesterInfoDto;
    executions: IModuleExecutionDto[];
  }> = module => async (dispatch, getState) => {
    const selection = getState().moduleSelection;
    const semester = getState().semesterSelection.selectedSemester;
    const executions = await dispatch(getExecutionsForAcademicYearAsync(semester.academicYearId));
    if (!module) {
      dispatch({ type: ACTION_TYPES.SELECT_MODULE, payload: null });
      dispatch({ type: ACTION_TYPES.SELECT_MODULE_EXECUTION, payload: null });
      return {
        category: selection.selectedCategory,
        module: null,
        execution: null,
        semester,
        executions,
      };
    }

    const category = selection.moduleCategories?.find(a => a.id === module.categoryId);
    if (selection.selectedCategory?.id !== module.categoryId) {
      dispatch({ type: ACTION_TYPES.SELECT_MODULE_CATEGORY, payload: category });
    }

    if (selection.selectedModule?.id !== module.id) {
      dispatch({ type: ACTION_TYPES.SELECT_MODULE, payload: module });
    }

    const execution = selection.selectedExecution?.moduleId !== module.id ? selection.selectedExecution : null;
    if (selection.selectedExecution?.moduleId !== module.id) {
      dispatch({ type: ACTION_TYPES.SELECT_MODULE_EXECUTION, payload: null });
    }

    return {
      category,
      module,
      execution,
      semester,
      executions,
    };
  };

  const selectModuleCategoryAsync: (category: IModuleCategoryDto) => AsyncThunk<{
    category: IModuleCategoryDto;
    module?: IModuleDto;
    execution?: IModuleExecutionDto;
    semester: ISemesterInfoDto;
    executions: IModuleExecutionDto[];
  }> = category => async (dispatch, getState) => {
    const semester = getState().semesterSelection.selectedSemester;
    const executions = await dispatch(getExecutionsForAcademicYearAsync(semester.academicYearId));
    const selection = getState().moduleSelection;
    if (selection.selectedCategory?.id !== category?.id) {
      dispatch({ type: ACTION_TYPES.SELECT_MODULE_CATEGORY, payload: category });
    }

    const module = selection.selectedModule?.categoryId !== category.id ? selection.selectedModule : null;
    if (selection.selectedModule?.categoryId !== category?.id) {
      dispatch({ type: ACTION_TYPES.SELECT_MODULE, payload: null });
    }

    const execution = selection.selectedExecution?.categoryId !== category.id ? selection.selectedExecution : null;
    if (selection.selectedExecution?.categoryId !== category?.id) {
      dispatch({ type: ACTION_TYPES.SELECT_MODULE_EXECUTION, payload: null });
    }

    return {
      category,
      module,
      execution,
      semester,
      executions,
    };
  };

  const setFilterForExecutionIdAsync: (executionId?: number) => AsyncThunk<{
    category: IModuleCategoryDto;
    module?: IModuleDto;
    execution?: IModuleExecutionDto;
    semester: ISemesterInfoDto;
    executions: IModuleExecutionDto[];
  }> = executionId => async dispatch => {
    const execution = await dispatch(loadExecutionAsync(executionId));
    return await dispatch(selectExecutionAsync(execution));
  };

  const setFilterAsync: (
    category?: IModuleCategoryDto,
    module?: IModuleDto,
    execution?: IModuleExecutionDto,
    academicYear?: IAcademicYearDto,
    semester?: ISemesterInfoDto
  ) => AsyncThunk<{
    category: IModuleCategoryDto;
    module?: IModuleDto;
    execution?: IModuleExecutionDto;
    executions: IModuleExecutionDto[];
    semester: ISemesterInfoDto;
  }> = (category, module, execution, academicYear, semester) => async dispatch => {
    if (execution) {
      return await dispatch(selectExecutionAsync(execution));
    }
    const semesterResult = await dispatch(changeSemesterFilterAsync(academicYear, semester));

    if (module) {
      const moduleSelectionResult = await dispatch(selectModuleAsync(module));
      return {
        ...moduleSelectionResult,
        semester: semesterResult.semester,
        executions: semesterResult.executions,
      };
    }
    const categorySelectionResult = await dispatch(selectModuleCategoryAsync(category));
    return {
      ...categorySelectionResult,
      semester: semesterResult.semester,
      executions: semesterResult.executions,
    };
  };

  return Object.freeze({
    getExecutionsForSemesterIdAsync,
    getExecutionsForSemesterAsync,
    resetExecutions,
    setFilterAsync,
    setFilterForExecutionIdAsync,
    selectModuleCategoryAsync,
    selectModuleAsync,
    selectExecutionAsync,
    changeSemesterFilterAsync,
    loadStructureAsync,
    getExecutionsForAcademicYearAsync,
  });
};

export default initActions;

export type ModuleSelectionActions = ReturnType<typeof initActions>;
