import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { ButtonGroup, Paper, Tooltip } from '@mui/material';
import { IRootState } from 'app/config/root.reducer';

import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import {
  IAcademicYearDto,
  ICommentDto,
  IPhaseDto,
  ISemesterInfoDto,
  RegistrationImport,
  RoleType,
  ValidationLevel,
} from 'app/clients/services';
import { hasAnyRole } from 'app/shared/auth/roles';
import { DefaultButton } from 'app/shared/layout/ui-elements/buttons';
import { MainContent, SideBar, SplitLayout } from 'app/shared/layout/ui-elements/component-layout';
import { dateTimeFormatter, dateTimeSorter, StandardDataGrid } from 'app/shared/layout/ui-elements/standard-data-grid';
import { isHigherOrEqual } from 'app/shared/util/validation-level.util';
import { SemesterSelection } from '../../shared/layout/semester-selection';
import importRequestModule from './import-requests/index';
import importAdminModule from './imports/index';
import { ImportPreview } from './parts/import-preview/import-preview';

export type Props = PropsFromRedux;

export const ImportRequests = (props: Props) => {
  useEffect(() => {
    const loadInitialData = async () => {
      if (props.isRegistrationAdmin) {
        await Promise.all([
          props.countPendingImportsAsync(),
          props.getPendingImportsAsync(props.academicYear, props.semester, props.phase),
        ]);
      } else {
        await props.getPendingImportsAsync(props.academicYear, props.semester, props.phase);
      }
    };

    loadInitialData();
  }, []);

  const columns: GridColDef[] = [
    { field: 'uas', headerName: 'UAS', width: 120 },
    { field: 'semester', headerName: 'Semester', width: 180 },
    { field: 'phase', headerName: 'Phase', width: 180 },
    {
      field: 'uploadedDate',
      headerName: 'Uploaded',
      width: 160,
      sortComparator: dateTimeSorter,
      valueFormatter: dateTimeFormatter,
    },
    {
      field: 'uploadedByName',
      headerName: 'Uploaded By',
      width: 200,
    },
  ];

  const handleSelectionChanged = (newSelection: GridRowSelectionModel) => {
    props.selectImportAsync(Number(newSelection[0]) > 0 ? Number(newSelection[0]) : null);
  };

  return (
    <SplitLayout>
      <SideBar>
        <SemesterSelection
          onChange={async (academicYear, semester, phase) => {
            await props.setFilterAsync(academicYear, semester, phase);
          }}
          academicYear={props.academicYear}
          semester={props.semester}
          phase={props.phase}
          allowEmpty="phase"
          filter={props.semesterFilter}
        />
      </SideBar>
      <MainContent>
        <StandardDataGrid
          columns={columns}
          rows={props.pendingImports}
          selectionModel={props.selectedImport ? [props.selectedImport.id] : []}
          loading={props.loading}
          onSelectionModelChange={handleSelectionChanged}
          columnVisibilityModel={{}}
        ></StandardDataGrid>
        {props.selectedImport && (
          <div>
            <Paper style={{ padding: 5, marginTop: 20 }}>
              <ButtonGroup aria-label="view-actions" style={{ marginRight: 20 }}>
                <DefaultButton
                  primary
                  onClick={() => props.downloadAsync(props.selectedImport?.id)}
                  disabled={props.loading || props.previewLoading}
                >
                  Download
                </DefaultButton>
              </ButtonGroup>

              <ButtonGroup aria-label="view-actions" style={{ marginRight: 20 }}>
                <Tooltip
                  title={
                    <span>
                      Validate the import request again <br />
                      against the current data in the database <br />
                      without importing (import can be done later).
                    </span>
                  }
                >
                  <DefaultButton
                    onClick={() => props.revalidateSubmittedAsync(props.selectedResult?.registrationImport)}
                    disabled={
                      !props.selectedResult?.registrationImport ||
                      !props.selectedResult?.canSubmit ||
                      props.loading ||
                      props.previewLoading
                    }
                  >
                    Revalidate
                  </DefaultButton>
                </Tooltip>

                {props.selectedResult?.registrationImport &&
                  (props.selectedResult?.registrationImport?.nrOfUserConfirmations > 0 ||
                    isHigherOrEqual(
                      props.selectedResult?.registrationImport.validationLevel,
                      ValidationLevel.Warning,
                    )) && (
                    <Tooltip
                      title={
                        <span>
                          Reset all confirmations that were already confirmed during the import. <br />
                          This makes sense, if some confirmations were made before changing existing data in the
                          database. <br />
                          without importing (import can be done later).
                        </span>
                      }
                    >
                      <DefaultButton
                        onClick={() => props.resetValidationAsync(props.selectedImport?.id)}
                        disabled={!props.selectedResult || props.loading || props.previewLoading}
                      >
                        Reset Confirmations
                      </DefaultButton>
                    </Tooltip>
                  )}
              </ButtonGroup>
              <ButtonGroup aria-label="view-actions" style={{ marginRight: 20 }}>
                <DefaultButton
                  onClick={() => props.rejectAsync(props.selectedImport?.id)}
                  disabled={!props.selectedImport || props.loading || props.previewLoading}
                >
                  Reject
                </DefaultButton>
                <DefaultButton
                  onClick={() => {
                    props.importAsync(props.selectedImport.id);
                  }}
                  primary
                  disabled={
                    props.isUnsaved ||
                    (props.selectedResult?.registrationImport && !props.selectedResult?.canSubmit) ||
                    props.loading ||
                    props.previewLoading
                  }
                >
                  Import
                </DefaultButton>
              </ButtonGroup>
            </Paper>
            {props.selectedImport && (
              <>
                <ImportPreview
                  uasImport={props.selectedResult?.registrationImport}
                  fileImport={props.selectedImport}
                  isLoading={props.previewLoading}
                  onUpdate={(r: RegistrationImport) => {
                    props.updateRegistrationImport(r);
                    props.reduceWarningCount();
                  }}
                  disabled={props.loading}
                  validation={props.selectedResult}
                  refreshRegistrationInfo={props.loadModuleRegistrationAsync}
                  onRemoveComment={props.removeCommentAsync}
                  onAddComment={props.addCommentAsync}
                  showConclusionAtStart
                ></ImportPreview>
              </>
            )}
          </div>
        )}
      </MainContent>
    </SplitLayout>
  );
};

const mapDispatchToProps = (dispatch) => {
  const adminActions = importAdminModule.initActions();
  const actions = importRequestModule.initActions();

  return {
    loadModuleRegistrationAsync: (id: number) => dispatch(adminActions.loadModuleRegistrationAsync(id)),
    addCommentAsync: (comment: ICommentDto) => dispatch(adminActions.addCommentAsync(comment)),
    removeCommentAsync: (comment: ICommentDto) => dispatch(adminActions.removeCommentAsync(comment)),
    countPendingImportsAsync: async () => await dispatch(actions.countPendingImportsAsync()),
    getPendingImportsAsync: async (academicYear?: IAcademicYearDto, semester?: ISemesterInfoDto, phase?: IPhaseDto) =>
      await dispatch(actions.getPendingImportsAsync(academicYear, semester, phase)),
    downloadAsync: async (id: number) => await dispatch(actions.downloadAsync(id)),
    importAsync: async (id: number) => await dispatch(actions.importAsync(id)),
    rejectAsync: async (id: number) => await dispatch(actions.rejectAsync(id)),
    resetValidationAsync: async (id: number) => await dispatch(actions.resetValidationAsync(id)),
    revalidateSubmittedAsync: async (registrationImport: RegistrationImport) =>
      await dispatch(actions.revalidateSubmittedAsync(registrationImport)),
    selectImportAsync: async (id: number) => await dispatch(actions.selectImportAsync(id)),
    reduceWarningCount: () => dispatch(actions.reduceWarningCount()),
    updateRegistrationImport: (registrationImport: RegistrationImport) =>
      dispatch(actions.updateRegistrationImport(registrationImport)),
    setFilterAsync: async (academicYear: IAcademicYearDto, semester: ISemesterInfoDto, phase: IPhaseDto) =>
      await dispatch(actions.setFilterAsync(academicYear, semester, phase)),
  };
};

const mapStateToProps = ({ importRequests, auth, semesterSelection }: IRootState) => ({
  isRegistrationAdmin:
    auth.isLoggedIn &&
    auth.account?.isAuthenticated &&
    hasAnyRole(auth.account?.roles, [RoleType.ADMIN, RoleType.REGISTRATIONADMIN]),
  pendingImports: importRequests.pendingImports,
  loading: importRequests.loading,
  previewLoading: importRequests.previewLoading,
  selectedResult: importRequests.selectedResult,
  selectedImport: importRequests.selectedImport,
  semester: semesterSelection.selectedSemester,
  academicYear: semesterSelection.selectedAcademicYear,
  semesterFilter: semesterSelection.semesterFilter,
  isUnsaved: importRequests.isUnsaved,
  phase: importRequests.phase,
  selectedRegistration: importRequests.selectedRegistration,
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ImportRequests);
