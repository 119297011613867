import { IAccountDto, IAccountInfoDto, IAccountRequestDto, IRoleInfoDto, RoleType } from 'app/clients/services';
import ACTION_TYPES from './types';

const initialState = {
  accountLoading: false,
  accountsLoading: false,
  requestsLoading: false,
  pendingRequests: [] as IAccountRequestDto[],
  rejectedRequests: [] as IAccountRequestDto[],
  countPendingAccountRequests: 0,
  userAccounts: [] as IAccountDto[],
  selectedAccount: undefined as IAccountInfoDto | undefined,
  roleInfo: undefined as { accountId: string; roleInfos: IRoleInfoDto } | undefined,
  roleLoading: undefined as RoleType | undefined,
  existingUserLoading: false,
  existingUser: undefined as IAccountDto | undefined,
};

export type UserManagementState = Readonly<typeof initialState>;

// Reducer
export default (state: UserManagementState = initialState, action): UserManagementState => {
  switch (action.type) {
    case ACTION_TYPES.SET_ROLE_LOADING:
      return {
        ...state,
        roleLoading: action.payload,
      };
    case ACTION_TYPES.SET_ACCOUNT_LOADING:
      return {
        ...state,
        accountLoading: action.payload,
      };
    case ACTION_TYPES.SET_ACCOUNTS_LOADING:
      return {
        ...state,
        accountsLoading: !!action.payload,
      };
    case ACTION_TYPES.SET_REQUESTS_LOADING:
      return {
        ...state,
        requestsLoading: action.payload,
      };
    case ACTION_TYPES.SET_ACCOUNTS:
      return {
        ...state,
        userAccounts: action.payload,
      };
    case ACTION_TYPES.SET_PENDING_REQUESTS:
      return {
        ...state,
        pendingRequests: action.payload,
        countPendingAccountRequests: action.payload?.length ?? 0,
      };
    case ACTION_TYPES.SET_REJECTED_REQUESTS:
      return {
        ...state,
        rejectedRequests: action.payload,
      };
    case ACTION_TYPES.RESET:
      return {
        ...state,
        pendingRequests: [],
      };
    case ACTION_TYPES.SET_SELECTED_ACCOUNT:
      return {
        ...state,
        selectedAccount: action.payload,
        roleInfo: undefined,
      };
    case ACTION_TYPES.UPDATE_ROLES: {
      return {
        ...state,
        roleInfo: action.payload,
      };
    }
    case ACTION_TYPES.SET_EXISTING_USER_LOADING: {
      return {
        ...state,
        existingUserLoading: action.payload,
      };
    }
    case ACTION_TYPES.SET_EXISTING_USER: {
      return {
        ...state,
        existingUser: action.payload,
      };
    }
    default:
      return state;
  }
};
