import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import EventIcon from '@mui/icons-material/Event';
import ExtensionIcon from '@mui/icons-material/Extension';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import SchoolIcon from '@mui/icons-material/School';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import { Drawer as MuiDrawer, Toolbar } from '@mui/material';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import { styled } from '@mui/material/styles';
import { RoleType } from 'app/clients/services';
import { IRootState } from 'app/config/root.reducer';
import importsModule from 'app/modules/registration-import/import-requests/index';
import userManagementModule from 'app/modules/user-management/user-management';
import {
  ModuleManagementRoute,
  ModuleRegistrationsRoute,
  RegistrationImportRoute,
  SemesterManagementRoute,
  StudentManagementRoute,
  UasManagementRoute,
  UserManagementRoute,
} from 'app/routes';
import { hasAnyRole } from 'app/shared/auth/roles';
import { drawerWidth } from 'app/shared/layout/theme';
import React, { useEffect } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ProfileManagementRoute } from '../routes';
import { DrawerItem } from '../shared/layout/drawer/drawer-link';

export type Props = PropsFromRedux & {
  toggleDrawer: () => void;
  drawerOpen: boolean;
};

const StyledDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    width: drawerWidth,
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const AppDrawer = (props: Props) => {
  const location = useLocation();

  useEffect(() => {
    if (hasAnyRole(props.roles, [RoleType.ADMIN, RoleType.REGISTRATIONADMIN])) {
      props.countPendingImportsAsync();
    }
    if (hasAnyRole(props.roles, [RoleType.ADMIN, RoleType.USERADMIN])) {
      props.loadPendingRequestsAsync();
    }
  }, [props, props.isAuthenticated]);

  return (
    <StyledDrawer variant="permanent" open={props.drawerOpen}>
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          px: [1],
        }}
      >
        <IconButton onClick={props.toggleDrawer}>
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>
      <Divider />
      {(props.isUserAdmin || props.isModuleAdmin || props.isProcessAdmin) && (
        <>
          <List component="nav">
            {props.isUserAdmin && (
              <DrawerItem
                url={UserManagementRoute}
                title="Users"
                icon={SupervisorAccountIcon}
                useBatch
                batchCount={props.countPendingUserRequests}
                key={0}
                isActive={location.pathname.startsWith(UserManagementRoute)}
              />
            )}
            {props.isProcessAdmin && (
              <DrawerItem
                key={1}
                url={SemesterManagementRoute}
                title="Semesters"
                icon={EventIcon}
                isActive={location.pathname.startsWith(SemesterManagementRoute)}
              />
            )}
            {props.isModuleAdmin && (
              <DrawerItem
                key={2}
                url={ProfileManagementRoute}
                title="Profiles"
                icon={RecentActorsIcon}
                isActive={location.pathname.startsWith(ProfileManagementRoute)}
              />
            )}
            {props.isModuleAdmin && (
              <DrawerItem
                key={3}
                url={ModuleManagementRoute}
                title="Modules"
                icon={ExtensionIcon}
                isActive={location.pathname.startsWith(ModuleManagementRoute)}
              />
            )}
          </List>
          <Divider />
        </>
      )}
      {props.isStudentAdmin && (
        <List component="nav">
          <DrawerItem
            key={4}
            url={UasManagementRoute}
            title="UAS"
            icon={SchoolIcon}
            isActive={location.pathname.startsWith(UasManagementRoute)}
          />
          <DrawerItem
            key={5}
            url={StudentManagementRoute}
            title="Students"
            icon={AssignmentIndIcon}
            isActive={location.pathname.startsWith(StudentManagementRoute)}
          />
        </List>
      )}
      <Divider />
      <List component="nav">
        <DrawerItem
          key={6}
          url={RegistrationImportRoute}
          title="Registration Import"
          icon={InboxIcon}
          useBatch
          batchCount={props.pendingImportsCount}
          isActive={location.pathname.startsWith(RegistrationImportRoute)}
        />
        {(props.isRegistrationAdmin || props.isStudentAdmin) && (
          <DrawerItem
            key={7}
            url={ModuleRegistrationsRoute}
            title="Module Registrations"
            icon={PlaylistAddCheckIcon}
            isActive={location.pathname.startsWith(ModuleRegistrationsRoute)}
          />
        )}
      </List>
      <Divider />
    </StyledDrawer>
  );
};

function mapStateToProps({ importRequests, auth, userManagement }: IRootState) {
  return {
    isAuthenticated: auth.isLoggedIn && auth.account?.isAuthenticated,
    roles: auth.account?.roles ?? [],
    pendingImportsCount: importRequests.pendingImportsCount,
    loading: importRequests.loading,
    countPendingUserRequests: userManagement.countPendingAccountRequests,
    isRegistrationAdmin: hasAnyRole(auth.account?.roles ?? [], [RoleType.REGISTRATIONADMIN]),
    isUserAdmin: hasAnyRole(auth.account?.roles ?? [], [RoleType.USERADMIN]),
    isModuleAdmin: hasAnyRole(auth.account?.roles ?? [], [RoleType.MODULEADMIN]),
    isProcessAdmin: hasAnyRole(auth.account?.roles ?? [], [RoleType.PROCESSADMIN]),
    isStudentAdmin: hasAnyRole(auth.account?.roles ?? [], [RoleType.STUDENTADMIN, RoleType.MASTEROFFICE]),
  };
}

const mapDispatchToProps = (dispatch) => {
  const importActions = importsModule.initActions();
  const userManagementActions = userManagementModule.initActions(dispatch);
  return {
    countPendingImportsAsync: async () => await dispatch(importActions.countPendingImportsAsync()),
    loadPendingRequestsAsync: userManagementActions.loadPendingRequestsAsync,
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AppDrawer);
