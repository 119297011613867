import { IUasDto } from 'app/clients/services';
import React from 'react';
import { ChipSelectionSection } from 'app/shared/layout/ui-elements/chip-selection';

type Props = {
  loading: boolean;
  selectedUas: IUasDto[];
  allUas: IUasDto[];
  disabled: boolean;
  removeUasAsync: (uas: IUasDto) => Promise<void>;
  addUasAsync: (uas: IUasDto) => Promise<void>;
};

export const UasRelationsForm = (props: Props) => {
  return (
    <ChipSelectionSection
      title="UAS"
      actionTitle="Add UAS"
      getItemKey={u => u.id}
      getItemTitle={u => u.code}
      loading={props.loading}
      selectedItems={props.selectedUas}
      items={props.allUas}
      onDelete={u => props.removeUasAsync(u)}
      onAdd={u => props.addUasAsync(u as unknown as IUasDto)}
      getColor={() => 'primary'}
    />
  );
};
