import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import SaveIcon from '@mui/icons-material/Save';
import {
  Alert,
  Autocomplete,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import {
  IPriorityAssignmentDto,
  IPriorityDto,
  IProfileDto,
  ISemesterDto,
  ISemesterInfoDto,
  PriorityAssignmentDto,
  PriorityDto,
  ProfileDto,
  SemesterDto,
} from 'app/clients/services';
import { IRootState } from 'app/config/root.reducer';
import semesterSelectionModule from 'app/modules/selections/semester-selection';
import { DefaultButton } from 'app/shared/layout/ui-elements/buttons';
import { MainContent, SideBar, SplitLayout } from 'app/shared/layout/ui-elements/component-layout';
import { ConfirmationDialog } from 'app/shared/layout/ui-elements/confirmation-dialog';
import { NavigationList } from 'app/shared/layout/ui-elements/navigation-list';
import { SelectBox } from 'app/shared/layout/ui-elements/select-box';
import { combineUrlParams, getBasePath } from 'app/shared/util/url-utils';
import { Field, Form, Formik } from 'formik';
import { CheckboxWithLabel as FormikCheckbox, TextField as FormikTextField } from 'formik-mui';
import React, { useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import priorityModule from './priorities/index';
import profileModule from './profiles/index';

export type Props = PropsFromRedux;

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     root: {
//       width: '100%',
//       flexGrow: 1,
//     },
//     formControl: {
//       margin: theme.spacing(1),
//     },
//     selectEmpty: {
//       marginTop: theme.spacing(2),
//     },
//     dataGrid: {
//       '& .MuiDataGrid-cellEditing': {
//         backgroundColor: 'rgb(255,215,115, 0.19)',
//         color: '#1a3e72',
//       },
//       '& .Mui-error': {
//         backgroundColor: `rgb(126,10,15, 0.1)`,
//         color: '#750f0f',
//       },
//     },
//     table: {
//       minWidth: 650,
//     },
//     form: {
//       '& > *': {
//         margin: theme.spacing(1),
//         width: '25ch',
//       },
//     },
//     button: {
//       margin: theme.spacing(1),
//     },
//   }),
// );

type ProfileFormProps = {
  profile: ProfileDto;
  onChange: (profile: ProfileDto) => Promise<void>;
};

export const Profiles = (props: Props) => {
  const { profileId, semesterId } = useParams<{ profileId: string; semesterId: string }>();
  const navigate = useNavigate();
  const basePath = getBasePath(useLocation(), useParams());

  // const classes = useStyles();

  useEffect(() => {
    const loadInitialData = async () => {
      if (!props.priorities || props.priorities.length === 0) {
        await props.fetchPrioritiesAsync();
      }

      if (!props.profiles || props.profiles.length === 0) {
        await props.fetchProfilesAsync();
      }

      if (profileId && semesterId) {
        if (props.selectedProfile?.id !== Number(profileId)) {
          props.selectProfile(props.profiles.find((p) => p.id === Number(profileId)));

          if (props.selectedSemester?.id !== Number(semesterId)) {
            props.selectSemesterById(Number(semesterId));
          }

          await props.fetchPriorityAssignmentsAsync(Number(profileId), Number(semesterId));
          return;
        }

        if (props.selectedSemester?.id !== Number(semesterId)) {
          props.selectSemesterById(Number(semesterId));
          await props.fetchPriorityAssignmentsAsync(Number(profileId), Number(semesterId));
        }
      } else if (props.selectedProfile && props.selectedSemester) {
        navigate(
          basePath + combineUrlParams({ profileId: props.selectedProfile.id, semesterId: props.selectedSemester.id }),
        );
        await props.fetchPriorityAssignmentsAsync(props.selectedProfile.id, props.selectedSemester.id);
      }
    };
    loadInitialData();
  }, []);

  const handleSemesterChange = (semester: ISemesterInfoDto) => {
    if (props.selectedProfile && semester) {
      props.fetchPriorityAssignmentsAsync(props.selectedProfile.id, semester.id);
      navigate(basePath + combineUrlParams({ profileId: props.selectedProfile.id, semesterId: semester.id }));
    }
    props.selectSemester(semester);
  };

  const handleProfileSelectionChange = async (id: number) => {
    props.selectProfile(props.profiles.find((p) => p.id === id));
    if (props.selectedSemester) {
      await props.fetchPriorityAssignmentsAsync(id, props.selectedSemester.id);
      navigate(basePath + combineUrlParams({ profileId: id, semesterId: props.selectedSemester.id }));
    }
  };

  const handlePriorityChange = async (item: PriorityAssignmentDto, priority: PriorityDto) => {
    if (priority) {
      await props.changePriorityAssignmentAsync(item, priority);
    }
  };

  const handleIncompletePriorityValueChange = async (
    item: PriorityAssignmentDto,
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const prio =
      props.priorities.filter((p) => p.name === event.target.value)[0] ??
      props.priorities.filter((p) => p.name?.includes(event.target.value) && p?.name !== event.target.value)[0];
    if (prio && item?.priorityId !== prio.id) {
      await props.changePriorityAssignmentAsync(item, prio);
      return;
    }
    const prioByWeighting = props.priorities.filter((p) => p.weighting === Number(event.target.value))[0];
    if (prioByWeighting && item?.priorityId !== prioByWeighting.id) {
      await props.changePriorityAssignmentAsync(item, prio);
      return;
    }
  };

  const handleDeletePriority = async () => {
    return props.deleteProfileAsync(props.selectedProfile);
  };

  const ProfileForm = (formProps: ProfileFormProps) => {
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);

    return (
      <Formik
        enableReinitialize
        initialValues={formProps.profile}
        validate={(values) => {
          const errors: Partial<ProfileDto> = {};
          if (!values.code || values.code === 'untitled') {
            errors.code = 'Required';
          }
          if (props.profiles.filter((p) => p.code === values.code && p.id !== values.id).length > 0) {
            errors.code = 'Code already exists!';
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          formProps.onChange(values).then(() => setSubmitting(false));
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-end" spacing={5}>
              <Grid item>
                <Field component={FormikTextField} name="code" type="text" label="Profile Code" variant="standard" />
              </Grid>
              <Grid item>
                <Field
                  type="checkbox"
                  component={FormikCheckbox}
                  label="Is Active"
                  name="isActive"
                  Label={{ label: 'Is Active' }}
                />
              </Grid>
              <Grid item>
                <DefaultButton
                  primary
                  disabled={isSubmitting || props.loading}
                  onClick={submitForm}
                  startIcon={<SaveIcon />}
                >
                  Save
                </DefaultButton>
                <DefaultButton
                  disabled={isSubmitting || props.loading}
                  onClick={() => setDialogOpen(true)}
                  startIcon={<DeleteIcon />}
                >
                  Delete
                </DefaultButton>
                <ConfirmationDialog
                  title="Delete Profile"
                  onConfirm={handleDeletePriority}
                  open={dialogOpen}
                  onClose={() => setDialogOpen(false)}
                >
                  Do you really want to delete the profile <strong>{props.selectedProfile.code}</strong>?
                </ConfirmationDialog>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    );
  };

  const standardPrio = props.priorities?.find((p) => p.weighting === 5);
  const [copyFromSemesterOpen, setCopyFromSemesterOpen] = useState<boolean>(false);
  const [copyFromSemester, setCopyFromSemester] = useState<ISemesterDto>(null);
  const [writePriorityOpen, setWritePriorityOpen] = useState<boolean>(false);
  const [removePriorityOpen, setRemovePriorityOpen] = useState<boolean>(false);
  const [writePriority, setWritePriority] = useState<IPriorityDto>(standardPrio);

  return (
    <SplitLayout>
      <SideBar>
        <NavigationList
          title="Profiles"
          selectedItem={props.selectedProfile}
          items={props.profiles?.filter((p) => p.isActive) ?? []}
          createUrl={(item) =>
            basePath + combineUrlParams({ profileId: item.id, semesterId: props.selectedSemester?.id })
          }
          onClick={(item) => handleProfileSelectionChange(item.id)}
          getItemKey={(item) => item.id}
          getItemTitle={(item) => item.code}
        />
        {props.profiles && props.profiles.filter((p) => !p.isActive).length > 0 && (
          <NavigationList
            title="Inactive Profiles"
            selectedItem={props.selectedProfile}
            items={props.profiles.filter((p) => !p.isActive)}
            createUrl={(item) =>
              basePath + combineUrlParams({ profileId: item.id, semesterId: props.selectedSemester?.id })
            }
            onClick={(item) => handleProfileSelectionChange(item.id)}
            getItemKey={(item) => item.id}
            getItemTitle={(item) => item.code}
          />
        )}
        <DefaultButton
          placement="side-bar"
          disabled={props.loading}
          startIcon={<AddIcon />}
          onClick={() => props.createNewProfile()}
        >
          Add
        </DefaultButton>
      </SideBar>
      <MainContent loading={props.loading}>
        {props.selectedProfile && (
          <>
            {props.selectedProfile.id ? (
              <Typography variant={'h1'}>Profile: {props.selectedProfile.code}</Typography>
            ) : (
              <Typography variant={'h1'}>New Profile</Typography>
            )}

            <Grid container>
              <Grid item xs={12} component={Paper} style={{ marginBottom: 20, padding: 10 }}>
                <ProfileForm
                  profile={props.selectedProfile}
                  onChange={(changedProfile) =>
                    props.selectedProfile.id
                      ? props.updateProfileAsync(changedProfile)
                      : props.addProfileAsync(changedProfile)
                  }
                ></ProfileForm>
              </Grid>
              {props.selectedProfile.id > 0 && (
                <>
                  <Grid item xs={12}>
                    <Typography variant="h2">Priority Assignments</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container component={Paper} sx={{ padding: 1 }}>
                      <Grid item xs={6}>
                        <SelectBox
                          items={props.semesterFilter?.semesters ?? []}
                          selectedItem={props.selectedSemester}
                          onChange={handleSemesterChange}
                          title="Semester"
                          getItemKey={(semester: SemesterDto) => semester.id}
                          getItemTitle={(semester: SemesterDto) => semester.name}
                        />
                      </Grid>
                      <Grid item xs={6} style={{ textAlign: 'right' }}>
                        <DefaultButton
                          onClick={() => {
                            setCopyFromSemesterOpen(true);
                          }}
                          disabled={props.loading}
                          startIcon={<FileCopyIcon />}
                          caption="Copy all priorities from a semester, which you can select in the dialog that will open."
                        >
                          Copy
                        </DefaultButton>
                        {props.selectedProfile && props.selectedSemester && (
                          <ConfirmationDialog
                            id="copy semester"
                            title={'Copy Priorities'}
                            open={copyFromSemesterOpen}
                            onClose={() => setCopyFromSemesterOpen(false)}
                            onConfirm={async () => {
                              await props.copySemesterAsync(
                                props.selectedProfile,
                                copyFromSemester,
                                props.selectedSemester,
                              );
                            }}
                            confirmDisabled={!copyFromSemester}
                          >
                            This action will update (or create) all priorities for the profile{' '}
                            <strong>{props.selectedProfile.code}</strong> in the semester{' '}
                            <strong>{props.selectedSemester.name}</strong> with values from another semester.
                            <br />
                            Please select the semester you want to copy the priority assignments from.
                            <SelectBox
                              items={props.semesterFilter?.semesters.filter((s) => s.id !== props.selectedSemester?.id)}
                              selectedItem={copyFromSemester}
                              onChange={(s) => setCopyFromSemester(s)}
                              title="Semester"
                              allowEmpty
                              getItemKey={(semester: SemesterDto) => semester?.id}
                              getItemTitle={(semester: SemesterDto) => semester?.name}
                            />
                          </ConfirmationDialog>
                        )}

                        <DefaultButton
                          onClick={() => setWritePriorityOpen(true)}
                          disabled={props.loading}
                          startIcon={<DoneAllIcon />}
                          caption="Set all priorities to a value, which you can select in the dialog that will open."
                        >
                          Set All
                        </DefaultButton>

                        <DefaultButton
                          onClick={() => setRemovePriorityOpen(true)}
                          disabled={props.loading}
                          startIcon={<DeleteIcon />}
                          caption="Remove all priorities for the selected semester to make it inactive for the semester."
                        >
                          Remove All
                        </DefaultButton>
                        {props.selectedProfile && props.selectedSemester && (
                          <ConfirmationDialog
                            id="set priorities"
                            title={'Set Priorities'}
                            open={writePriorityOpen}
                            onClose={() => setWritePriorityOpen(false)}
                            onConfirm={async () => {
                              await props.assignAllPrioritiesAsync(
                                props.selectedProfile,
                                props.selectedSemester,
                                writePriority,
                              );
                            }}
                          >
                            This action will update (or create) all priorities for the profile{' '}
                            <strong>{props.selectedProfile.code}</strong> in the semester{' '}
                            <strong>{props.selectedSemester.name}</strong> with the default priority you select.
                            <br />
                            <SelectBox
                              items={props.priorities}
                              selectedItem={writePriority}
                              onChange={(p) => setWritePriority(p)}
                              title="Priority"
                              allowEmpty
                              getItemKey={(prio: IPriorityDto) => prio.id}
                              getItemTitle={(prio: IPriorityDto) => prio.name}
                            />
                          </ConfirmationDialog>
                        )}
                        {props.selectedProfile && props.selectedSemester && (
                          <ConfirmationDialog
                            id="remove priorities"
                            title={'Remove Priorities'}
                            open={removePriorityOpen}
                            onClose={() => setRemovePriorityOpen(false)}
                            onConfirm={async () => {
                              await props.removeAllPrioritiesAsync(props.selectedProfile, props.selectedSemester);
                            }}
                          >
                            This action will remove all priorities for the profile{' '}
                            <strong>{props.selectedProfile.code}</strong> in the semester{' '}
                            <strong>{props.selectedSemester.name}</strong> which makes the profile inactive for semester{' '}
                            <strong>{props.selectedSemester.name}</strong>.
                          </ConfirmationDialog>
                        )}
                      </Grid>
                      <Grid xs={12}>
                        {(!props.priorityAssignments ||
                          props.priorityAssignments.filter((p) => !!p.priority).length === 0) && (
                          <Alert severity="warning">
                            Profile <strong>{props.selectedProfile.code}</strong> is deactivated for semester{' '}
                            <strong>{props.selectedSemester?.name}</strong>. There are no priorities assigned.
                            <br /> If you want all priorities to be the default priority, please use the {'"'}SET ALL
                            {'"'} button.
                          </Alert>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <TableContainer component={Paper} style={{ marginTop: 20 }}>
                      <Table /* className={classes.table} */ aria-label="priority assignments" size="small">
                        <TableHead style={{ backgroundColor: '#EEEEEE' }}>
                          <TableRow>
                            <TableCell style={{ width: 200 }}>
                              <strong>Module Code</strong>
                            </TableCell>
                            <TableCell align="left">
                              <strong>Priority</strong>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {(props.priorityAssignments ?? []).map((a, key) => (
                            <TableRow key={key}>
                              <TableCell align="left">{a.moduleCode}</TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  backgroundColor: ((a.priority ?? standardPrio) as IPriorityDto)?.colorCode ?? 'white',
                                }}
                              >
                                {
                                  <Autocomplete
                                    id={'combo-box' + key}
                                    size="small"
                                    style={{ width: 200 }}
                                    options={props.priorities}
                                    getOptionLabel={(option) => (option as IPriorityDto)?.name ?? ''}
                                    isOptionEqualToValue={(option) =>
                                      ((a.priority ?? standardPrio) as IPriorityDto).id === (option as IPriorityDto).id
                                    }
                                    onChange={(e, v) => handlePriorityChange(a, v)}
                                    value={a.priority ?? standardPrio}
                                    renderInput={(input) => (
                                      <TextField
                                        {...input}
                                        variant="standard"
                                        size="small"
                                        onBlur={(event) => handleIncompletePriorityValueChange(a, event)}
                                      />
                                    )}
                                  />
                                }
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </>
              )}
            </Grid>
          </>
        )}
      </MainContent>
    </SplitLayout>
  );
};

const mapStateToProps = ({
  profileManagement,
  priorityManagement,
  semesterSelection,
  profileSelection,
}: IRootState) => ({
  loading: profileManagement.loading,
  profiles: profileSelection.profiles,
  priorities: priorityManagement.priorities,
  selectedProfile: profileManagement.selectedProfile,
  selectedSemester: semesterSelection.selectedSemester,
  semesterFilter: semesterSelection.semesterFilter,
  priorityAssignments: profileManagement.priorityAssignments,
});

const mapDispatchToProps = (dispatch) => {
  const priorityActions = priorityModule.initActions();
  const profileActions = profileModule.initActions();
  const selectionActions = semesterSelectionModule.initActions();
  return {
    fetchProfilesAsync: async () => await dispatch(profileActions.fetchProfilesAsync()),
    fetchPrioritiesAsync: async () => await dispatch(priorityActions.fetchPrioritiesAsync()),
    fetchPriorityAssignmentsAsync: async (profileId: number, semesterId: number) =>
      await dispatch(profileActions.fetchPriorityAssignmentsAsync(profileId, semesterId)),
    selectProfile: (profile: IProfileDto) => dispatch(profileActions.selectProfile(profile)),
    selectSemester: (semester: ISemesterInfoDto) => dispatch(selectionActions.selectSemester(semester)),
    selectSemesterById: (semesterId: number) => dispatch(selectionActions.selectSemesterById(semesterId)),
    updateProfileAsync: async (profile: IProfileDto) => await dispatch(profileActions.updateProfileAsync(profile)),
    deleteProfileAsync: async (profile: IProfileDto) => await dispatch(profileActions.deleteProfileAsync(profile)),
    addProfileAsync: async (profile: IProfileDto) => await dispatch(profileActions.addProfileAsync(profile)),
    changePriorityAssignmentAsync: async (assignment: IPriorityAssignmentDto, priority: IPriorityDto) =>
      await dispatch(profileActions.changePriorityAssignmentAsync(assignment, priority)),
    createNewProfile: () => dispatch(profileActions.createNewProfile()),
    copySemesterAsync: async (profile: IProfileDto, fromSemester: ISemesterDto, toSemester: ISemesterDto) =>
      await dispatch(profileActions.copySemesterAsync(profile, fromSemester, toSemester)),
    assignAllPrioritiesAsync: async (profile: IProfileDto, ofSemester: ISemesterDto, priority: IPriorityDto) =>
      await dispatch(profileActions.assignAllPrioritiesAsync(profile, ofSemester, priority)),
    removeAllPrioritiesAsync: async (profile: IProfileDto, ofSemester: ISemesterDto) =>
      await dispatch(profileActions.removeAllPrioritiesAsync(profile, ofSemester)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Profiles);
