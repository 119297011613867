import initActions from './actions';
import types from './types';
import reducer from './reducer';
import { resolver } from 'app/clients/service-registry';
import { ISemesterSelectionClient } from 'app/clients/services';

export type { SemesterSelectionState } from './reducer';
export default {
  initActions: () => initActions(resolver.resolve<ISemesterSelectionClient>('ISemesterSelectionClient')),
  types,
  reducer,
};
