import { IApplicationProfileDto, IRoleDto } from 'app/clients/services';
import ACTION_TYPES from './types';
import { SUCCESS } from 'app/shared/util/action-type.util';
import { ITabModel } from 'app/shared/layout/ui-elements/navigation-tabs';

type ApplicationProfile = 'prod' | 'dev' | 'stag' | 'swagger';
const initialState = {
  ribbonEnv: '',
  isProduction: true,
  profiles: [] as ApplicationProfile[],
  isSwaggerEnabled: false,
  isLoaded: false,
  applicationError: '',
  roles: [] as IRoleDto[],
  openTabs: {} as { [componentRoute: string]: ITabModel },
};

export type ApplicationState = Readonly<typeof initialState>;

export default (state: ApplicationState = initialState, action): ApplicationState => {
  switch (action.type) {
    case SUCCESS(ACTION_TYPES.GET_PROFILE): {
      const data = action.payload as IApplicationProfileDto;
      return {
        ...state,
        ribbonEnv: data.displayRibbonOnProfiles,
        isProduction: data.activeProfiles.includes('prod'),
        profiles: data.activeProfiles as ApplicationProfile[],
        isLoaded: true,
        isSwaggerEnabled: data.activeProfiles.includes('swagger'),
      };
    }
    case SUCCESS(ACTION_TYPES.GET_ROLES): {
      return {
        ...state,
        roles: action.payload,
      };
    }
    case ACTION_TYPES.ADD_APPLICATION_ERROR: {
      return {
        ...state,
        applicationError: action.payload,
      };
    }
    case ACTION_TYPES.CLEAR_APPLICATION_ERROR: {
      return {
        ...state,
        applicationError: '',
      };
    }
    case ACTION_TYPES.REMEMBER_OPEN_TAB: {
      const openTabs = { ...state.openTabs };
      openTabs[action.payload.componentRoute] = action.payload.selectedTab;
      return {
        ...state,
        openTabs,
      };
    }
    default:
      return state;
  }
};
