import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ModulesTabs from './module-management-tabs';
import Modules from './modules-component';

const ModuleManagementRoutes = ({ baseUrl }) => {
  return (
    <>
      <ModulesTabs baseUrl={baseUrl} />
      <Routes>
        <Route path={`modules/:categoryId?/:moduleId?/:academicYearId?`} element={<Modules />} />
      </Routes>
    </>
  );
};

export default ModuleManagementRoutes;
