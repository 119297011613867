import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import SaveIcon from '@mui/icons-material/Save';
import { FormControl, Grid, InputLabel, LinearProgress, MenuItem, Paper, Typography } from '@mui/material';
import { ILocationDto, IModuleCategoryDto, IModuleDto, IModuleExecutionDto, ISemesterDto } from 'app/clients/services';
import { DefaultButton } from 'app/shared/layout/ui-elements/buttons';
import { ConfirmationDialog } from 'app/shared/layout/ui-elements/confirmation-dialog';
import { FastField, Field, FieldArray, Form, Formik } from 'formik';
import { CheckboxWithLabel, TextField as FormikTextField, Select } from 'formik-mui';
import React, { useState } from 'react';

type ModuleExecutionsFormProps = {
  moduleLoading: boolean;
  moduleExecutionLoading: number;
  executions: IModuleExecutionDto[];
  category: IModuleCategoryDto;
  module: IModuleDto;
  onSubmit: (values: { executions: IModuleExecutionDto[] }) => Promise<void>;
  onDelete: (execution: IModuleExecutionDto) => Promise<void>;
  semesters: ISemesterDto[];
  locations: ILocationDto[];
};

export const ModuleExecutionsForm = (props: ModuleExecutionsFormProps) => {
  const [deletePhaseOpen, setDeleteExecutionDialogOpen] = useState<boolean>(false);
  return (
    <Formik
      enableReinitialize
      initialValues={{ executions: props.executions }}
      validate={(values) => {
        const errors: { executions?: { partialCode?: string }[] } = {};
        const executionErrors: { partialCode?: string }[] = [];
        values.executions.map((execution, key) => {
          if (
            values.executions.filter((p2) => p2.partialCode === execution.partialCode && p2 !== execution).length > 0
          ) {
            executionErrors[key] = { partialCode: 'Code already exists' };
          }
          if (values.executions.length > 1 && !execution.partialCode) {
            executionErrors[key] = { partialCode: 'Code can not be empty if more than one execution for one module.' };
          }
        });
        if (executionErrors.length) {
          errors.executions = executionErrors;
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        props.onSubmit(values).then(() => setSubmitting(false));
      }}
    >
      {({ values, submitForm, isSubmitting }) =>
        isSubmitting || props.moduleLoading ? (
          <LinearProgress></LinearProgress>
        ) : (
          <Form>
            <FieldArray
              name="executions"
              render={(arrayHelpers) => (
                <div>
                  {values.executions.map((e, k) => (
                    <Paper key={k} style={{ margin: 10, padding: 10 }}>
                      {props.moduleExecutionLoading === e.id ? (
                        <LinearProgress key={'execution-' + k}></LinearProgress>
                      ) : (
                        <Grid
                          key={'execution-' + k}
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="flex-end"
                          spacing={2}
                        >
                          <Grid item xs={12} md={4}>
                            <div style={{ marginTop: 18, padding: 2, display: 'inline-block' }}>
                              <Typography>
                                {props.module.code}
                                {e.partialCode ? '_' : ''}
                              </Typography>
                            </div>
                            <Field
                              component={FormikTextField}
                              name={`executions[${k}].partialCode`}
                              type="text"
                              label="Code"
                              variant="standard"
                            />
                          </Grid>
                          <Grid item xs={12} md={2}>
                            <Field
                              component={CheckboxWithLabel}
                              type="checkbox"
                              name={`executions[${k}].isActive`}
                              Label={{ label: 'Is Active' }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Field
                              component={CheckboxWithLabel}
                              type="checkbox"
                              name={`executions[${k}].registrationIsBlocked`}
                              Label={{ label: 'Block Registration' }}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <FormControl style={{ width: '100%' }} variant="standard">
                              <InputLabel htmlFor={`executions[${k}].semesterId`} shrink>
                                Semester
                              </InputLabel>
                              <FastField
                                component={Select}
                                variant="standard"
                                name={`executions[${k}].semesterId`}
                                inputProps={{
                                  id: `${k}.semesterId`,
                                }}
                                selected={e.semesterId}
                              >
                                {props.semesters.map((s, sk) => (
                                  <MenuItem key={sk} value={s.id}>
                                    {s.name}
                                  </MenuItem>
                                ))}
                              </FastField>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <FormControl style={{ width: '100%' }} variant="standard">
                              <InputLabel htmlFor={`executions[${k}].locationId`} shrink>
                                Location
                              </InputLabel>
                              <FastField
                                component={Select}
                                variant="standard"
                                name={`executions[${k}].locationId`}
                                inputProps={{
                                  id: `${k}.locationId`,
                                }}
                                selected={e.locationId}
                              >
                                {props.locations.map((l, lk) => (
                                  <MenuItem key={lk} value={l.id}>
                                    {l.name}
                                  </MenuItem>
                                ))}
                              </FastField>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <Field
                              variant="standard"
                              component={FormikTextField}
                              name={`executions[${k}].maxParticipants`}
                              type="text"
                              label="Max Participants"
                            />
                          </Grid>

                          <Grid item xs={12}>
                            {!e.id ? (
                              <DefaultButton
                                disabled={isSubmitting || props.moduleLoading}
                                onClick={() => arrayHelpers.remove(k)}
                                startIcon={<DeleteIcon />}
                              >
                                Cancel
                              </DefaultButton>
                            ) : (
                              !e.hasRegistrations && (
                                <>
                                  <DefaultButton
                                    disabled={isSubmitting || props.moduleLoading}
                                    onClick={() => setDeleteExecutionDialogOpen(true)}
                                    startIcon={<DeleteIcon />}
                                  >
                                    Delete
                                  </DefaultButton>
                                </>
                              )
                            )}
                            <ConfirmationDialog
                              title="Delete Execution"
                              onConfirm={async () => {
                                await props.onDelete(e);
                              }}
                              open={deletePhaseOpen}
                              onClose={() => setDeleteExecutionDialogOpen(false)}
                            >
                              Do you really want to delete the module execution{' '}
                              <strong>
                                {props.module.code}_{e.partialCode}
                              </strong>
                              ?
                            </ConfirmationDialog>
                          </Grid>
                        </Grid>
                      )}
                    </Paper>
                  ))}
                  <DefaultButton
                    primary
                    disabled={isSubmitting || props.moduleLoading}
                    onClick={submitForm}
                    placement="top-margin"
                    startIcon={<SaveIcon />}
                  >
                    Save Executions
                  </DefaultButton>
                  <DefaultButton
                    disabled={isSubmitting || props.moduleLoading}
                    startIcon={<AddIcon />}
                    placement="top-margin"
                    onClick={() => {
                      arrayHelpers.push({
                        id: 0,
                        moduleId: props.module.id,
                        categoryId: props.module.categoryId,
                        semesterId: props.semesters[0]?.id,
                        semester: '',
                        locationId: props.locations[0]?.id,
                        location: '',
                        maxParticipants: props.module.recommendedMaxParticipants ?? 120,
                        partialCode: '',
                        code: '',
                        isActive: true,
                        hasRegistrations: false,
                        registrationIsBlocked: false,
                      });
                    }}
                  >
                    Add Execution
                  </DefaultButton>
                </div>
              )}
            />
          </Form>
        )
      }
    </Formik>
  );
};
