import { IFileImportDto, IModuleRegistrationInfoDto, IPhaseDto, IRegistrationValidationDto, RegistrationValidationDto, ValidationCountDto } from 'app/clients/services';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/util/action-type.util';
import ACTION_TYPES from './types';

type IImportRequestsState = {
  loading: boolean;
  errorMessage: string;
  pendingImports: IFileImportDto[];
  selectedResult: IRegistrationValidationDto;
  pendingImportsCount: number;
  selectedImport: IFileImportDto;
  mainContentLoading: boolean;
  isUnsaved: boolean;
  previewLoading: boolean;
  phase: IPhaseDto;
  selectedRegistration: IModuleRegistrationInfoDto | null;
  moduleRegistrationLoading: boolean;
};

const initialState: IImportRequestsState = {
  loading: false,
  errorMessage: null,
  pendingImports: [],
  selectedResult: null,
  isUnsaved: false,
  pendingImportsCount: 0,
  selectedImport: null,
  mainContentLoading: true,
  previewLoading: false,
  phase: null,
  selectedRegistration: null,
  moduleRegistrationLoading: false,
};

export type ImportRequestsState = Readonly<typeof initialState>;

// Reducer
export default (state: ImportRequestsState = initialState, action): ImportRequestsState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.GET_PENDING_IMPORTS):
      return {
        ...state,
        errorMessage: null,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.GET_PENDING_IMPORTS):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.GET_PENDING_IMPORTS): {
      return {
        ...state,
        loading: false,
        pendingImports: action.payload,
      };
    }
    case REQUEST(ACTION_TYPES.IMPORT):
      return {
        ...state,
        errorMessage: null,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.IMPORT):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.IMPORT):
      return {
        ...state,
        loading: false,
        selectedResult: action.payload,
      };
    case REQUEST(ACTION_TYPES.DOWNLOAD):
      return {
        ...state,
        errorMessage: null,
        previewLoading: true,
      };
    case FAILURE(ACTION_TYPES.DOWNLOAD):
      return {
        ...state,
        previewLoading: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.DOWNLOAD):
      return {
        ...state,
        previewLoading: false,
      };
    case REQUEST(ACTION_TYPES.GET_VALIDATION):
      return {
        ...state,
        errorMessage: null,
        previewLoading: true,
      };
    case FAILURE(ACTION_TYPES.GET_VALIDATION):
      return {
        ...state,
        previewLoading: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.GET_VALIDATION):
      return {
        ...state,
        previewLoading: false,
        selectedResult: action.payload,
        isUnsaved: false,
      };
    case ACTION_TYPES.SET_IS_UNSAVED:
      return {
        ...state,
        isUnsaved: action.payload,
      };
    case ACTION_TYPES.UPDATE_PENDING_COUNT:
      return {
        ...state,
        pendingImportsCount: action.payload,
      };
    case ACTION_TYPES.UPDATE_VALIDATION_PREVIEW:
      return {
        ...state,
        selectedResult: {
          ...state.selectedResult,
          registrationImport: action.payload,
        },
      };
    case ACTION_TYPES.SELECT_IMPORT: {
      return {
        ...state,
        selectedImport: state.pendingImports?.find((p) => p.id === action.payload) ?? null,
        selectedResult: null,
      };
    }

    case ACTION_TYPES.SELECT_PHASE:
      return {
        ...state,
        phase: action.payload,
      };
    case ACTION_TYPES.SET_MAIN_CONTENT_LOADING:
      return {
        ...state,
        mainContentLoading: action.payload,
      };
    case ACTION_TYPES.REDUCE_WARNING_COUNT:
      return {
        ...state,
        selectedResult: state.selectedResult
          ? new RegistrationValidationDto({
              ...state.selectedResult,
              count: state.selectedResult.count
                ? new ValidationCountDto({
                    ...state.selectedResult.count,
                    warnings: state.selectedResult.count.warnings - 1,
                    infos: state.selectedResult.count.infos + 1,
                    userConfirmations: state.selectedResult.count.userConfirmations + 1,
                  })
                : null,
              canSubmit: state.selectedResult.count.warnings - 1 + state.selectedResult.count.errors === 0,
            })
          : null,
      };
    default:
      return state;
  }
};
