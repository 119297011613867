import { IStudentDto } from 'app/clients/services';
import ACTION_TYPES from './types';

const initActions = (componentDispatch: any) => {
  const selectStudent = (student: IStudentDto) => ({
    type: ACTION_TYPES.SELECT_TAB,
    payload: student,
  });

  const removeTab = (student: IStudentDto) => ({
    type: ACTION_TYPES.REMOVE_TAB,
    payload: student,
  });

  const addTab = (student: IStudentDto) => ({
    type: ACTION_TYPES.ADD_TAB,
    payload: student,
  });

  const closeAllTabs = () => ({
    type: ACTION_TYPES.CLOSE_ALL_TABS,
  });

  return Object.freeze({
    selectStudent: (student: IStudentDto): void => componentDispatch(selectStudent(student)),
    removeTab: (student: IStudentDto): void => componentDispatch(removeTab(student)),
    addTab: (student: IStudentDto): void => componentDispatch(addTab(student)),
    closeAllTabs: (): void => componentDispatch(closeAllTabs()),
  });
};
export default initActions;

export type StudentTabActions = ReturnType<typeof initActions>;
