import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Tab, Tabs, Tooltip } from '@mui/material';
import { blue } from '@mui/material/colors';
import { IStudentDto, RoleType } from 'app/clients/services';
import { IRootState } from 'app/config/root.reducer';
import { hasAnyRole } from 'app/shared/auth/roles';
import { trimSlashes } from 'app/shared/util/url-utils';
import React, { useEffect } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import studentsModule from './students';
import studentTabsModule from './tabs';

export type Props = PropsFromRedux & { baseUrl: string };

const StudentManagementTabs = (props: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!props.selectedTab) {
      if (location.pathname !== getTabUrl('/search')) {
        navigate(getTabUrl('/search'));
      }
      return;
    } else {
      if (!props.selectedTab.id) {
        if (location.pathname !== getTabUrl(`/student/new`)) {
          navigate(getTabUrl(`/student/new`));
        }
        return;
      }

      if (location.pathname !== getTabUrl(`/student/${props.selectedTab.id}`)) {
        navigate(getTabUrl(`/student/${props.selectedTab.id}`));
      }
      return;
    }
  }, [props.selectedTab]);

  const getTabUrl = (tabUrl: string) => {
    return `/${trimSlashes(props.baseUrl)}/${trimSlashes(tabUrl)}`;
  };

  const selectStudent = (event: React.ChangeEvent, newValue: string) => {
    const student = props.tabs.find((t) => t.id === newValue) ?? null;
    props.selectStudent(student);
    if (!student) {
      navigate(getTabUrl('/search'));
    }
  };

  const removeTab = (event: React.MouseEvent, tab: IStudentDto) => {
    props.removeTab(tab);
    navigate(getTabUrl('/search'));
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Tabs
      key={location.pathname}
      value={props.tabs.length > 0 && props.selectedTab ? props.selectedTab.id : 'search'}
      color="secondary"
      aria-label="navigation-tabs"
      style={{ marginBottom: 5 }}
      onChange={selectStudent}
      variant="scrollable"
      sx={{ backgroundColor: 'white' }}
    >
      <Tab
        key={'search'}
        label={'Search'}
        value={'search'}
        component={Link}
        to={getTabUrl('/search')}
        sx={{ backgroundColor: props.selectedTab ? 'white' : blue[50] }}
      />
      {props.tabs &&
        props.tabs.map((t) => (
          <Tab
            key={t.id}
            label={
              <div>
                {!t.id ? (
                  <>New Student</>
                ) : (
                  <>
                    {t.firstName} {t.familyName}
                  </>
                )}
                <Tooltip title="close">
                  <IconButton
                    component="div"
                    onClick={(e) => removeTab(e, t)}
                    sx={{ width: 20, height: 20, paddingLeft: 2 }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </div>
            }
            sx={{ backgroundColor: props.selectedTab?.id === t.id ? blue[50] : 'white' }}
            value={t.id}
            component={Link}
            to={!t.id ? `student/${t.id}` : `student/new`}
          />
        ))}

      {props.isAdmin && (
        <Tooltip title="add new student">
          <IconButton
            component="div"
            onClick={() => props.addNewStudent(props.semesters, props.uas, props.profiles)}
            sx={{ width: 20, height: 20, padding: 2, marginTop: 1 }}
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      )}
    </Tabs>
  );
};

function mapStateToProps({ studentTabs, semesterSelection, auth, profileSelection }: IRootState) {
  return {
    selectedTab: studentTabs.selectedTab,
    semesters: semesterSelection.semesterFilter?.semesters ?? [],
    profiles: profileSelection.profiles,
    uas: auth.allowedUas,
    tabs: studentTabs.tabs,
    isAdmin: hasAnyRole(auth.account?.roles, [RoleType.REGISTRATIONADMIN, RoleType.STUDENTADMIN]),
  };
}

const mapDispatchToProps = (dispatch) => {
  const studentActions = studentsModule.initActions(dispatch);
  const studentTabActions = studentTabsModule.initActions(dispatch);
  return {
    addNewStudent: studentActions.addNewStudent,
    removeTab: studentTabActions.removeTab,
    addTab: studentTabActions.addTab,
    closeAllTabs: studentTabActions.closeAllTabs,
    selectStudent: studentTabActions.selectStudent,
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(StudentManagementTabs);
