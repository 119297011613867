/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable no-console */
import axios from 'axios';

import * as Sentry from '@sentry/browser';
import { ApiException } from 'app/clients/services';
import { SERVER_API_URL } from 'app/config/constants';
import { addApplicationError } from 'app/modules/application/actions';
import authModule from 'app/modules/login/auth/index';
import { addError, addExceptionNotification } from 'app/modules/notification';

const TIMEOUT = 2 * 60 * 1000;
axios.defaults.timeout = TIMEOUT;
axios.defaults.baseURL = SERVER_API_URL;
const isDev = process.env.NODE_ENV === 'development';

class IdentifiableApiException extends ApiException {
  level: 'error' | 'warning' | 'info' | 'success';
  type: string;
  id: number;
  constructor(message: string, status: number, response: string, headers: { [key: string]: any }, result: any) {
    super(message, status, response, headers, result);
    this.level = 'error';
    this.type = 'IdentifiableApiException';
    this.id = new Date().getTime() + Math.random();
  }
}

class MessageException extends IdentifiableApiException {
  additionalData: { [key: string]: any };
  constructor(
    message: string,
    level: 'error' | 'warning' | 'info' | 'success',
    status: number,
    response: string,
    headers: { [key: string]: any },
    result: any,
    additionalData: { [key: string]: any },
  ) {
    super(message, status, response, headers, result);
    this.level = level;
    this.type = 'MessageException';
    this.id = new Date().getTime() + Math.random();
    this.additionalData = additionalData;
  }
}

const readBlobData: (data: Blob) => Promise<MessageException> = (data) => {
  return new Promise<MessageException>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      try {
        // eslint-disable-next-line @typescript-eslint/no-base-to-string
        const dataObject = JSON.parse(reader.result.toString());
        if (dataObject.type === 'Message') {
          const message = new MessageException(
            dataObject.message,
            dataObject.level,
            dataObject.status,
            null,
            null,
            null,
            dataObject.additionalData,
          );
          resolve(message);
        } else {
          resolve(null);
        }
      } catch {
        reject(new Error('Could not parse the message object!'));
      }
    };

    reader.onerror = () => {
      reject(new Error('Could not parse the message object! Invalid blob data!'));
    };

    reader.readAsText(data);
  });
};

const setupAxiosInterceptors = (store) => {
  const authActions = authModule.initActions();
  const onRequestSuccess = (config) => {
    return config;
  };
  const onResponseSuccess = (response) => {
    return response;
  };
  const onResponseError = async (err: any) => {
    if (err.response?.data) {
      if (err.response?.data?.type === 'Message') {
        const message = err.response?.data;
        const messageException = new MessageException(
          message.message,
          message.level,
          message.statusCode,
          err.response,
          err.headers,
          err.result,
          [],
        );
        store.dispatch(addExceptionNotification(messageException));
        throw err;
      }
      if (err.response?.data?.type?.toString() === 'application/problem+json') {
        try {
          const message = await readBlobData(err.response.data);
          if (message !== null) {
            message.response = err.response;
            message.headers = err.headers;
            message.result = err.result;
            isDev && console.log(message.level, err);
            store.dispatch(addExceptionNotification(message));
            if (message.level !== 'error') {
              return;
            }
            throw message;
          }
        } catch (parseError) {
          isDev ? console.log('Error', parseError) : Sentry.captureException(parseError);
        }
        isDev ? console.log('Error', err) : Sentry.captureException(err);
        const messageException = new MessageException(
          'An unknown error occurred!',
          'error',
          0,
          err.response,
          err.headers,
          err.result,
          [],
        );
        store.dispatch(addExceptionNotification(messageException));
        throw err;
      }
      if (err.response.data.error) {
        if (isDev) {
          console.log('Error', err);
        }
        const exception = new MessageException(
          err.response.data.error,
          'error',
          err.response.status,
          err.response,
          err.headers,
          err.result,
          err.response.data.additionalData,
        );
        store.dispatch(addExceptionNotification(exception));
        throw exception;
      }
    }

    if (isDev) {
      console.log('Error', err);
    }

    const status = err.status || (err.response ? err.response.status : 0);
    switch (status) {
      case 403:
      case 401:
        store.dispatch(authActions.clearAuthenticationThunk('You are not authorized!'));
        throw new IdentifiableApiException('You are not authorized!', status, err.response, err.headers, err.result);
      case 404:
        store.dispatch(addError(`Resource not found!`));
        return null;
      case 504:
        Sentry.captureException(err);
        store.dispatch(addApplicationError(`Server not reachable!`));
        throw new IdentifiableApiException('Server not reachable!', status, err.response, err.headers, err.result);
      case 500: {
        Sentry.captureException(err);
        const messageException = new IdentifiableApiException(
          'An unknown error occurred on the server!',
          status,
          err.response,
          err.headers,
          err.result,
        );
        store.dispatch(addExceptionNotification(messageException));
        throw messageException;
      }
      default: {
        Sentry.captureException(err);
        const messageException = new IdentifiableApiException(
          'An unknown error occurred!',
          status,
          err.response,
          err.headers,
          err.result,
        );
        store.dispatch(addExceptionNotification(messageException));
        throw messageException;
      }
    }
  };
  axios.interceptors.request.use(onRequestSuccess);
  axios.interceptors.response.use(onResponseSuccess, onResponseError);
};

export default setupAxiosInterceptors;
