import { RoleType } from 'app/clients/services';

export const hasAnyRole = (roles: RoleType[] | undefined, rolesToTest: RoleType[] | undefined) => {
  if (roles && roles.length !== 0) {
    if (roles.includes(RoleType.ADMIN)) {
      return true;
    }
    if (!rolesToTest || rolesToTest?.length === 0) {
      return true;
    }
    return rolesToTest.some((auth) => roles.includes(auth)) ?? false;
  }
  return false;
};
