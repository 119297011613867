import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Advisors from './advisors';
import Uas from './uas-component';
import UasDomains from './uas-domains';
import UserManagementTabs from './uas-management-tabs';

const UserManagementRoutes = ({ baseUrl }) => {
  return (
    <>
      <UserManagementTabs baseUrl={baseUrl} />
      <Routes>
        <Route path={`uas/:id?`} element={<Uas />} />
        <Route path={`advisors/:uasId?/:advisorId?`} element={<Advisors />} />
        <Route path={`domains/:id?`} element={<UasDomains />} />
      </Routes>
    </>
  );
};

export default UserManagementRoutes;
