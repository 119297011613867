import { IAcademicYearDto, IPhaseDto, ISemesterInfoDto, ISemesterSelectionClient, ISemesterSelectionFilter } from "app/clients/services";
import { AsyncThunk, Thunk } from "app/shared/util/action-type.util";
import ACTION_TYPES from "./types";

const initActions = (semesterSelectionClient: ISemesterSelectionClient) => {
  const getSemester: (semesterId: number) => Thunk<ISemesterInfoDto> = (semesterId) => (_dispatch, getState) => {
    return getState().semesterSelection.semesterFilter?.semesters?.find((s) => s.id === semesterId);
  };

  const getAcademicYear: (academicYearId?: number) => Thunk<IAcademicYearDto> = (academicYearId) => (_dispatch, getState) => {
    if (academicYearId) {
      return getState().semesterSelection.semesterFilter?.academicYears?.find((a) => a.id === academicYearId);
    }
    return getState().semesterSelection.semesterFilter?.academicYears?.find((a) => a.id === getState().semesterSelection.semesterFilter?.currentAcademicYearId);
  };

  const getPhase: (phaseId: number) => Thunk<IPhaseDto> = (phaseId) => (_dispatch, getState) => {
    const semester = getState().semesterSelection.semesterFilter?.semesters?.find((s) => s.phases?.filter((p) => p.id === phaseId).length > 0);
    return semester?.phases?.find((p) => p.id === phaseId);
  };

  const getSemesterForAcademicYear: (academicYearId: number) => Thunk<ISemesterInfoDto> = (academicYearId) => (dispatch, getState) => {
    const state = getState().semesterSelection;
    if (state.selectedSemester?.academicYearId === academicYearId) {
      return state.selectedSemester;
    }
    const defaultSemester = dispatch(getDefaultSemester());
    if (defaultSemester?.id === academicYearId) {
      return defaultSemester;
    }

    return state.semesterFilter?.semesters?.filter((s) => s.academicYearId === academicYearId)[0];
  };

  const getPhaseForSemester: (semester: ISemesterInfoDto) => Thunk<IPhaseDto> = (semester) => (_dispatch, getState) => {
    const state = getState().semesterSelection;
    if (!state.selectedPhase || semester?.phases?.filter((p) => p.id === state.selectedPhase.id).length === 0) {
      return semester?.phases?.find((p) => p.id === semester.currentPhaseId);
    }

    return state.selectedPhase;
  };

  const getDefaultSemester: () => Thunk<ISemesterInfoDto> = () => (_dispatch, getState) => {
    const state = getState().semesterSelection;
    const semester = state.selectedSemester ? state.selectedSemester : state.semesterFilter?.semesters.find((s) => s.id === state.semesterFilter?.defaultSemesterId);
    return semester;
  };

  // Thunks

  const setFilter: (
    academicYear?: IAcademicYearDto,
    semester?: ISemesterInfoDto,
    phase?: IPhaseDto,
  ) => Thunk<{ academicYear: IAcademicYearDto; semester: ISemesterInfoDto; phase: IPhaseDto }> = (academicYear, semester, phase) => (dispatch, getState) => {
    if (phase) {
      dispatch(selectPhase(phase));
      return;
    }
    if (semester) {
      dispatch(selectSemester(semester));
      return;
    }
    if (academicYear) {
      return dispatch(selectAcademicYear(academicYear));
    }
    const selectionState = getState().semesterSelection;
    return {
      academicYear: selectionState.selectedAcademicYear,
      semester: selectionState.selectedSemester,
      phase: selectionState.selectedPhase,
    };
  };

  const selectAcademicYear: (academicYear?: IAcademicYearDto) => Thunk<{ academicYear: IAcademicYearDto; semester: ISemesterInfoDto; phase: IPhaseDto }> =
    (academicYear) => (dispatch, getState) => {
      const selectionState = getState().semesterSelection;
      const theAcademicYear = academicYear ?? dispatch(getAcademicYear(selectionState.semesterFilter?.currentAcademicYearId));
      const semester = dispatch(getSemesterForAcademicYear(theAcademicYear?.id));
      const phase = dispatch(getPhaseForSemester(semester));
      dispatch({
        type: ACTION_TYPES.SELECT_ACADEMIC_YEAR,
        payload: academicYear,
      });
      dispatch({
        type: ACTION_TYPES.SELECT_SEMESTER,
        payload: semester,
      });
      dispatch({
        type: ACTION_TYPES.SELECT_PHASE,
        payload: phase,
      });
      return {
        academicYear,
        semester,
        phase,
      };
    };

  const selectAcademicYearById: (academicYearId?: number) => Thunk<{ academicYear: IAcademicYearDto; semester: ISemesterInfoDto; phase: IPhaseDto }> =
    (academicYearId) => (dispatch) => {
      const academicYear = dispatch(getAcademicYear(academicYearId));
      return dispatch(selectAcademicYear(academicYear));
    };

  const selectSemester: (semester: ISemesterInfoDto) => Thunk<{ academicYear: IAcademicYearDto; semester: ISemesterInfoDto; phase: IPhaseDto }> =
    (semester) => (dispatch, getState) => {
      const selectionState = getState().semesterSelection;
      const academicYear = dispatch(getAcademicYear(semester?.academicYearId));
      if (!semester) {
        semester = selectionState.selectedSemester;
      }
      if (semester?.academicYearId !== selectionState.selectedAcademicYear?.id) {
        dispatch({
          type: ACTION_TYPES.SELECT_ACADEMIC_YEAR,
          payload: academicYear,
        });
      }
      dispatch({
        type: ACTION_TYPES.SELECT_SEMESTER,
        payload: semester,
      });
      const phase = dispatch(getPhaseForSemester(semester));
      dispatch({
        type: ACTION_TYPES.SELECT_PHASE,
        payload: phase,
      });
      return {
        academicYear,
        semester,
        phase,
      };
    };

  const selectSemesterById: (semesterId?: number) => Thunk<{ academicYear: IAcademicYearDto; semester: ISemesterInfoDto; phase: IPhaseDto }> = (semesterId) => (dispatch) => {
    const semester = dispatch(getSemester(semesterId));
    return dispatch(selectSemester(semester));
  };

  const selectPhase: (phase: IPhaseDto) => Thunk<{ academicYear: IAcademicYearDto; semester: ISemesterInfoDto; phase: IPhaseDto }> = (phase) => (dispatch, getState) => {
    const selectionState = getState().semesterSelection;

    const semester = dispatch(getSemester(phase?.semesterId));
    const academicYear = dispatch(getAcademicYear(semester.academicYearId));
    if (phase) {
      if (academicYear && selectionState.selectedAcademicYear?.id !== academicYear?.id) {
        dispatch({
          type: ACTION_TYPES.SELECT_ACADEMIC_YEAR,
          payload: academicYear,
        });
      }

      if (semester && selectionState.selectedSemester?.id !== semester?.id) {
        dispatch({
          type: ACTION_TYPES.SELECT_ACADEMIC_YEAR,
          payload: semester,
        });
      }
    }

    dispatch({
      type: ACTION_TYPES.SELECT_PHASE,
      payload: phase,
    });

    return {
      academicYear,
      semester,
      phase,
    };
  };

  const selectPhaseById: (phaseId?: number) => Thunk<{ academicYear: IAcademicYearDto; semester: ISemesterInfoDto; phase: IPhaseDto }> = (phaseId) => (dispatch) => {
    const phase = dispatch(getPhase(phaseId));
    return dispatch(selectPhase(phase));
  };

  const getSemesterFilterAsync: () => AsyncThunk<ISemesterSelectionFilter> = () => async (dispatch) => {
    const result = await dispatch({
      type: ACTION_TYPES.GET_SEMESTER_FILTER,
      payload: semesterSelectionClient.getSemesterSelectionFilter(),
    });
    return result.value;
  };

  return Object.freeze({
    getSemester,
    getAcademicYear,
    getPhase,
    setFilter,
    selectAcademicYear,
    selectAcademicYearById,
    selectSemester,
    selectSemesterById,
    selectPhase,
    selectPhaseById,
    getSemesterFilterAsync,
    getDefaultSemester,
  });
};

export default initActions;

export type SemesterSelectionActions = ReturnType<typeof initActions>;
