export type ServiceIdentifier<TInterfaces> = Extract<keyof TInterfaces, string>;

export interface IResolver<TInterfaces> {
  resolve<T>(name: Extract<keyof TInterfaces, string>): T;
}

export interface IServiceRegistry<TInterfaces> {
  addTransient<T>(typeName: Extract<keyof TInterfaces, string>, creator: (resolver: IResolver<TInterfaces>) => T);
  addSingleton<T>(typeName: Extract<keyof TInterfaces, string>, creator: (resolver: IResolver<TInterfaces>) => T);
}

const nameof = <T>(name: Extract<keyof T, string>): string => name;

export class ServiceRegistry<TInterfaces> implements IResolver<TInterfaces>, IServiceRegistry<TInterfaces> {
  private creators: { [key: string]: any } = {};
  private instances: { [key: string]: any } = {};
  private isSingleton: { [key: string]: boolean } = {};

  private constructor() {}

  static init<TInterfaces>() {
    return new ServiceRegistry<TInterfaces>();
  }

  addTransient<T>(identifier: ServiceIdentifier<TInterfaces>, creator: (resolver: IResolver<TInterfaces>) => T) {
    const name = nameof<TInterfaces>(identifier);
    this.creators[name] = creator;
    return this;
  }

  addSingleton<T>(identifier: ServiceIdentifier<TInterfaces>, creator: (resolver: IResolver<TInterfaces>) => T) {
    const name = nameof<TInterfaces>(identifier);
    this.creators[name] = creator;
    this.isSingleton[name] = true;
    return this;
  }

  resolve<T>(identifier: ServiceIdentifier<TInterfaces>): T {
    const name = nameof<TInterfaces>(identifier);
    if (this.instances[name]) {
      return this.instances[name];
    }
    const instance = this.creators[name](this);

    if (this.isSingleton[name]) {
      this.instances[name] = instance;
    }

    return instance;
  }
}
