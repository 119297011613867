/* eslint-disable no-debugger */
import { FormControl, InputLabel, LinearProgress, MenuItem, Select, SelectChangeEvent, useTheme } from '@mui/material';
import * as Sentry from '@sentry/browser';
import { toCamelCase } from 'app/shared/util/string-utils';
import React from 'react';

type Props = {
  items: any[];
  title: string;
  selectedItem: any;
  emptyTitle?: string;
  allowEmpty?: boolean;
  getItemKey: (item: any) => string | number;
  getItemTitle: (item: any) => string | undefined;
  onChange: (selectedItem: any) => void | Promise<void>;
  itemSorter?: (a: any, b: any) => number;
  disabled?: boolean;
  loading?: boolean;
};

export const SelectBox = (props: Props) => {
  const theme = useTheme();
  const name = toCamelCase(props.title);
  const id = name + '-selection';
  const [value, setValue] = React.useState<string | number>(
    props.selectedItem ? props.getItemKey(props.selectedItem) : '',
  );

  const getItemKey = (item: any) => {
    try {
      if (!item) {
        return {
          value: '',
          name: '',
        };
      }

      return {
        value: props.getItemKey(item),
        name: props.getItemTitle(item),
      };
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  const getItemTitle = (item: any) => {
    try {
      if (!item) {
        return props.emptyTitle ?? 'no selection';
      }

      return props.getItemTitle(item);
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  const handleChange = (event: SelectChangeEvent<string | number>) => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-base-to-string
      const eventValue = isNaN(event.target.value as any)
        ? event.target?.value?.toString()
        : Number(event.target.value);
      setValue(eventValue);
      const item = props.items.find((i) => props.getItemKey(i) === eventValue);
      props.onChange(item);
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  const items = props.itemSorter !== null ? props.items.sort(props.itemSorter) : props.items;

  return (
    <FormControl
      sx={{
        margin: theme.spacing(1),
        minWidth: '90%',
        minHeight: 40,
      }}
      variant="standard"
      disabled={props.disabled}
    >
      <InputLabel id={id + '-label'} htmlFor={id} shrink>
        {props.title}
      </InputLabel>
      <Select
        variant="standard"
        labelId={id + '-label'}
        title={props.title}
        id={id}
        name={name}
        displayEmpty={props.allowEmpty}
        value={value}
        onChange={handleChange}
        disabled={props.disabled}
      >
        {props.allowEmpty && (
          <MenuItem key={'empty'} value="">
            {props.emptyTitle ?? ''}
          </MenuItem>
        )}
        {items.map((item) => (
          <MenuItem key={getItemKey(item)?.value} value={getItemKey(item)?.value ?? ''}>
            {getItemTitle(item)}
          </MenuItem>
        ))}
      </Select>
      {props.loading && <LinearProgress></LinearProgress>}
    </FormControl>
  );
};
