import * as Sentry from '@sentry/react';
import reducer, { IRootState } from 'app/config/root.reducer';
import { loadingBarMiddleware } from 'react-redux-loading-bar';
import { Middleware, applyMiddleware, compose, createStore } from 'redux';
import promiseMiddleware from 'redux-promise-middleware';
import { thunk as thunkMiddleware } from 'redux-thunk';
import loggerMiddleware from './logger-middleware';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

const defaultMiddlewares: Middleware[] = [thunkMiddleware, promiseMiddleware, loadingBarMiddleware(), loggerMiddleware];
const composedMiddlewares = (middlewares) =>
  compose(applyMiddleware(...defaultMiddlewares, ...middlewares), sentryReduxEnhancer);

const initialize = (initialState?: IRootState, middlewares = []) =>
  createStore(reducer, initialState, composedMiddlewares(middlewares));

export default initialize;
