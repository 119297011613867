import AddIcon from '@mui/icons-material/Add';
import { Checkbox, FormControlLabel, Grid, Paper, Typography } from '@mui/material';
import {
  CopyAcademicYearOptionsDto,
  IAcademicYearDto,
  ICopyAcademicYearOptionsDto,
  ICopySemesterOptionsDto,
  IPhaseDto,
  ISemesterInfoDto,
} from 'app/clients/services';
import { IRootState } from 'app/config/root.reducer';
import { DefaultButton } from 'app/shared/layout/ui-elements/buttons';
import { MainContent, SideBar, SplitLayout } from 'app/shared/layout/ui-elements/component-layout';
import { ConfirmationDialog } from 'app/shared/layout/ui-elements/confirmation-dialog';
import { NavigationList } from 'app/shared/layout/ui-elements/navigation-list';
import { SelectBox } from 'app/shared/layout/ui-elements/select-box';
import { combineUrlParams, getBasePath } from 'app/shared/util/url-utils';
import React, { useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AcademicYearForm } from './forms/academic-year';
import { SemesterForm } from './forms/semester';
import semesterManagementModule from './semesters/index';

export type Props = PropsFromRedux;

export const Semesters = (props: Props) => {
  const { academicYearId } = useParams<{ academicYearId: string }>();
  const navigate = useNavigate();
  const basePath = getBasePath(useLocation(), useParams());
  const [copyFromYear, setCopyFromYear] = useState<IAcademicYearDto>();
  const [copyModuleStructure, setCopyModuleStructure] = useState<boolean>(true);
  const [copyPriorities, setCopyPriorities] = useState<boolean>(true);
  const [copyLabels] = useState<boolean>(true);
  const [copySemesterOpen, setCopySemesterOpen] = useState<boolean>(false);
  const [copyFromSemester, setCopyFromSemester] = useState<ISemesterInfoDto | null>(null);
  const [semestersToCopyFrom, setSemestersToCopyFrom] = useState<ISemesterInfoDto[]>([]);

  useEffect(() => {
    const newest = props.academicYears?.sort((a1, a2) => (a1.startYear < a2.startYear ? 1 : -1))[0] ?? null;
    setCopyFromYear(newest);
  }, [props.academicYears, props.semesters]);

  useEffect(() => {
    if (copyFromYear) {
      const filtered = props.allSemesters.filter((s) => s.academicYearId === copyFromYear.id);
      setSemestersToCopyFrom(filtered);
      setCopyFromSemester(filtered[0]);
    }
  }, [copyFromYear]);

  useEffect(() => {
    if (academicYearId && (!props.selectedAcademicYear || !!props.selectedAcademicYear.id)) {
      if (Number(academicYearId) !== props.selectedAcademicYear?.id) {
        props.selectAcademicYear(props.academicYears.find((a) => a.id === Number(academicYearId)) ?? null);
      }
    } else if (props.selectedAcademicYear && !!props.selectedAcademicYear?.id) {
      navigate(basePath + combineUrlParams({ academicYearId: props.selectedAcademicYear.id }));
    } else if (!props.selectedAcademicYear && props.globallySelectedAcademicYear) {
      props.selectAcademicYear(props.globallySelectedAcademicYear);
      navigate(basePath + combineUrlParams({ academicYearId: props.globallySelectedAcademicYear.id }));
    } else {
      navigate(basePath);
    }
  }, [props.selectedAcademicYear, props.academicYears, props.semesters, props.semesterLoading, academicYearId]);

  const handleSave = async (
    academicYear: IAcademicYearDto,
    doCopyFromYear?: IAcademicYearDto,
    doCopyModuleStructure?: boolean,
    doCopyPriorities?: boolean,
    doCopyLabels?: boolean,
  ) => {
    if (doCopyFromYear && doCopyFromYear.id !== academicYear.id) {
      await props.copyAcademicYearAsync(
        new CopyAcademicYearOptionsDto({
          startYear: academicYear.startYear,
          name: academicYear.name,
          copyFromId: doCopyFromYear.id,
          copyPriorities: doCopyPriorities,
          copyModuleExececutions: doCopyModuleStructure,
          copyLabels: doCopyLabels,
        }),
      );
      return;
    }
    await props.saveAcademicYearAsync(academicYear);
  };

  const CopyOptions = () => (
    <>
      {copyFromYear && (
        <>
          <Grid item xs={3}>
            <FormControlLabel
              style={{ marginTop: 15 }}
              control={
                <Checkbox
                  checked={copyModuleStructure}
                  onChange={(_event, value) => setCopyModuleStructure(value)}
                  name="copyModuleStructure"
                  color="primary"
                />
              }
              label="Copy Module Structure"
            />
          </Grid>
          {copyModuleStructure && (
            <>
              <Grid item xs={3}>
                <FormControlLabel
                  style={{ marginTop: 15 }}
                  control={
                    <Checkbox
                      checked={copyPriorities}
                      onChange={(_event, value) => setCopyPriorities(value)}
                      name="copyPriorities"
                      color="primary"
                    />
                  }
                  label="Copy Priorities"
                />
              </Grid>
              {/* <Grid item xs={3}>
                <FormControlLabel
                  style={{ marginTop: 15 }}
                  control={<Checkbox checked={copyLabels} onChange={(_event, value) => setCopyLabels(value)} name="copyLabels" color="primary" />}
                  label="Copy Labels"
                />
              </Grid> */}
            </>
          )}
        </>
      )}
    </>
  );

  return (
    <SplitLayout>
      <SideBar>
        <NavigationList
          title="Academic Years"
          selectedItem={props.selectedAcademicYear}
          items={props.academicYears}
          createUrl={(item) => basePath + combineUrlParams({ academicYearId: item.id })}
          onClick={(item) => props.selectAcademicYear(item)}
          getItemKey={(item) => item.id}
          getItemTitle={(item) => item.name}
        />
        <DefaultButton
          placement="side-bar"
          disabled={props.loading}
          startIcon={<AddIcon />}
          onClick={() => {
            props.addAcademicYear();
          }}
        >
          Add
        </DefaultButton>
      </SideBar>
      <MainContent loading={props.loading}>
        {props.selectedAcademicYear && (
          <>
            <Typography variant={'h1'}>
              Academic Year: {props.selectedAcademicYear?.name ? <>{props.selectedAcademicYear?.name}</> : <>New</>}
            </Typography>
            <Grid container>
              {!props.selectedAcademicYear.id && (
                <>
                  <Grid item xs={3}>
                    <SelectBox
                      items={props.academicYears}
                      selectedItem={copyFromYear ?? null}
                      allowEmpty
                      emptyTitle="-"
                      getItemKey={(a) => a?.id ?? 0}
                      getItemTitle={(a) => a.name}
                      onChange={(item) => {
                        setCopyFromYear(item);
                      }}
                      title="Copy from year"
                    />
                  </Grid>
                  <CopyOptions />
                </>
              )}
              <Grid item xs={12} component={Paper} style={{ marginBottom: 20, padding: 10 }}>
                <AcademicYearForm
                  academicYear={props.selectedAcademicYear}
                  loading={props.loading}
                  academicYears={props.academicYears}
                  deleteAcademicYearAsync={async (academicYear) => {
                    await props.deleteAcademicYearAsync(academicYear);
                    props.selectAcademicYear(null);
                  }}
                  selectAcademicYear={props.selectAcademicYear}
                  semesters={props.semesters.filter((s) => s.academicYearId === props.selectedAcademicYear.id)}
                  onSubmit={(a) => {
                    return handleSave(a, copyFromYear, copyModuleStructure, copyPriorities, copyLabels);
                  }}
                ></AcademicYearForm>
              </Grid>
              {props.semesters && props.selectedAcademicYear.id > 0 && (
                <>
                  <Grid item xs={12}>
                    <Typography variant="h2">Semesters</Typography>
                  </Grid>
                  {props.semesters
                    .filter((s) => s.academicYearId === props.selectedAcademicYear.id)
                    .map((s, k) => (
                      <Grid item xs={12} key={'semester-' + k}>
                        <Paper style={{ padding: 20, margin: 10 }}>
                          <SemesterForm
                            semester={s}
                            semesters={props.semesters}
                            loading={props.loading}
                            phaseLoading={props.phaseLoading}
                            semesterLoading={props.semesterLoading}
                            onSubmit={props.updateSemesterAsync}
                            deleteSemesterAsync={props.deleteSemesterAsync}
                            cancelAddingSemester={props.cancelAddingSemester}
                            deletePhaseAsync={props.deletePhaseAsync}
                            reopenPhaseAsync={props.reopenPhaseAsync}
                            closePhaseAsync={props.closePhaseAsync}
                          ></SemesterForm>
                        </Paper>
                      </Grid>
                    ))}
                  <ConfirmationDialog
                    title="Create Semester"
                    open={copySemesterOpen}
                    onClose={() => setCopySemesterOpen(false)}
                    onConfirm={async () => {
                      await props.copySemesterAsync({
                        toAcademicYearId: props.selectedAcademicYear.id,
                        copyFromId: copyFromSemester.id,
                        copyPriorities,
                        copyModuleExececutions: copyModuleStructure,
                        copyLabels,
                      });
                    }}
                    additionalActions={
                      <DefaultButton
                        onClick={() => {
                          props.addSemester(props.selectedAcademicYear);
                          setCopySemesterOpen(false);
                        }}
                      >
                        Create an empty semester
                      </DefaultButton>
                    }
                    confirmDisabled={!copyFromSemester}
                    confirmation="Copy"
                  >
                    Creating a new semester means also creating all the module executions and all priority assignments.
                    <br />
                    Therefore it is recommended to copy an existing semester.
                    <br />
                    <br />
                    Please select a semester you want to copy.
                    <Grid container>
                      <Grid item xs={6}>
                        <SelectBox
                          items={props.academicYears}
                          selectedItem={copyFromYear ?? null}
                          getItemKey={(a) => a?.id ?? 0}
                          getItemTitle={(a) => a.name}
                          onChange={(item) => {
                            setCopyFromYear(item);
                          }}
                          title="Copy from year"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        {copyFromYear && (
                          <SelectBox
                            items={semestersToCopyFrom}
                            selectedItem={copyFromSemester ?? null}
                            getItemKey={(a) => a?.id ?? 0}
                            getItemTitle={(a) => a.name}
                            onChange={(item) => {
                              setCopyFromSemester(item);
                            }}
                            title="Copy from semester"
                          />
                        )}
                      </Grid>
                      <CopyOptions />
                    </Grid>
                  </ConfirmationDialog>
                  {props.newSemester ? (
                    <>
                      <Grid item xs={12} key={'newSemester'}>
                        <Paper style={{ padding: 20, margin: 10 }}>
                          <SemesterForm
                            semester={props.newSemester}
                            semesters={props.semesters}
                            loading={props.loading}
                            phaseLoading={props.phaseLoading}
                            semesterLoading={props.semesterLoading}
                            onSubmit={props.updateSemesterAsync}
                            deleteSemesterAsync={props.deleteSemesterAsync}
                            cancelAddingSemester={props.cancelAddingSemester}
                            deletePhaseAsync={props.deletePhaseAsync}
                            reopenPhaseAsync={props.reopenPhaseAsync}
                            closePhaseAsync={props.closePhaseAsync}
                          ></SemesterForm>
                        </Paper>
                      </Grid>
                    </>
                  ) : (
                    <DefaultButton
                      disabled={props.loading}
                      startIcon={<AddIcon />}
                      onClick={() => setCopySemesterOpen(true)}
                    >
                      Add Semester
                    </DefaultButton>
                  )}
                </>
              )}
            </Grid>
          </>
        )}
      </MainContent>
    </SplitLayout>
  );
};

const mapStateToProps = ({ semesterManagement, semesterSelection }: IRootState) => {
  const selectedAcademicYear = semesterManagement.selectedAcademicYear ?? semesterSelection.selectedAcademicYear;
  return {
    loading:
      semesterSelection.loading ||
      semesterManagement.loading ||
      semesterManagement.semesterLoading > 0 ||
      semesterManagement.phaseLoading > 0,
    globallySelectedAcademicYear: semesterSelection.selectedAcademicYear,
    selectedAcademicYear,
    academicYears:
      semesterSelection.semesterFilter?.academicYears?.sort((a1, a2) => (a1.startYear < a2.startYear ? 1 : -1)) ?? [],
    semesters: selectedAcademicYear
      ? semesterSelection.semesterFilter?.semesters?.filter((s) => s.academicYearId === selectedAcademicYear?.id) ?? []
      : [],
    allSemesters: semesterSelection.semesterFilter?.semesters ?? [],
    newSemester: semesterManagement.newSemester,
    phaseLoading: semesterManagement.phaseLoading,
    semesterLoading: semesterManagement.semesterLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = semesterManagementModule.initActions();
  return {
    selectAcademicYear: (academicYear: IAcademicYearDto) => dispatch(actions.selectAcademicYear(academicYear)),
    addAcademicYear: () => dispatch(actions.addAcademicYear()),
    addSemester: (academicYear: IAcademicYearDto) => dispatch(actions.addSemester(academicYear)),
    saveAcademicYearAsync: async (academicYear: IAcademicYearDto) =>
      await dispatch(actions.saveAcademicYearAsync(academicYear)),
    copyAcademicYearAsync: async (copyOptions?: ICopyAcademicYearOptionsDto) =>
      await dispatch(actions.copyAcademicYearAsync(copyOptions)),
    copySemesterAsync: async (copyOptions?: ICopySemesterOptionsDto) =>
      await dispatch(actions.copySemesterAsync(copyOptions)),
    deleteAcademicYearAsync: async (academicYear: IAcademicYearDto) =>
      await dispatch(actions.deleteAcademicYearAsync(academicYear)),
    cancelAddingSemester: () => dispatch(actions.cancelAddingSemester()),
    updateSemesterAsync: async (semester: ISemesterInfoDto) => await dispatch(actions.updateSemesterAsync(semester)),
    deleteSemesterAsync: async (semester: ISemesterInfoDto) => await dispatch(actions.deleteSemesterAsync(semester)),
    deletePhaseAsync: async (phase: IPhaseDto) => await dispatch(actions.deletePhaseAsync(phase)),
    closePhaseAsync: async (phase: IPhaseDto) => await dispatch(actions.closePhaseAsync(phase)),
    reopenPhaseAsync: async (phase: IPhaseDto) => await dispatch(actions.reopenPhaseAsync(phase)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Semesters);
