import { IRegistrationValidationDto, IUasDto, RegistrationImport, RegistrationValidationDto, ValidationCountDto } from 'app/clients/services';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/util/action-type.util';
import ACTION_TYPES from './types';

type IRegistrationImportState = {
  loading: boolean;

  errorMessage: string;
  file: File;
  validationResult: IRegistrationValidationDto | null;
  activeStep: number;
  uas: IUasDto[];
  selectedUasId: number | '';
};

const initialState: IRegistrationImportState = {
  loading: false,
  errorMessage: null,
  file: null,
  validationResult: null,
  activeStep: 0,
  uas: [],
  selectedUasId: '',
};

export type RegistrationImportState = Readonly<typeof initialState>;

// Reducer

export default (state: RegistrationImportState = initialState, action): RegistrationImportState => {
  switch (action.type) {
    case ACTION_TYPES.GOTO_STEP:
      return {
        ...state,
        activeStep: action.payload,
      };
    case ACTION_TYPES.NEXT_STEP:
      return {
        ...state,
        activeStep: state.activeStep >= 5 ? 5 : state.activeStep + 1,
      };
    case ACTION_TYPES.LAST_STEP:
      return {
        ...state,
        activeStep: state.activeStep <= 1 ? 0 : state.activeStep - 1,
      };
    case ACTION_TYPES.SELECT_FILE:
      return {
        ...state,
        file: action.payload,
      };
    case ACTION_TYPES.SELECT_UAS:
      return {
        ...state,
        selectedUasId: action.payload,
      };
    case REQUEST(ACTION_TYPES.VALIDATE):
      return {
        ...state,
        errorMessage: null,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.VALIDATE):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.VALIDATE):
      return {
        ...state,
        loading: false,
        validationResult: action.payload,
      };
    case REQUEST(ACTION_TYPES.VALIDATE_NORMALIZED):
      return {
        ...state,
        errorMessage: null,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.VALIDATE_NORMALIZED):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.VALIDATE_NORMALIZED):
      return {
        ...state,
        loading: false,
        validationResult: action.payload,
      };
    case ACTION_TYPES.UPDATE_REGISTRATION_IMPORT: {
      return {
        ...state,
        validationResult: state.validationResult
          ? new RegistrationValidationDto({
              ...state.validationResult,
              registrationImport: new RegistrationImport(action.payload),
            })
          : null,
      };
    }
    case REQUEST(ACTION_TYPES.SUBMIT):
      return {
        ...state,
        errorMessage: null,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.SUBMIT):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.SUBMIT):
      return {
        ...state,
        loading: false,
        validationResult: action.payload,
      };
    case REQUEST(ACTION_TYPES.ADD_FILE):
      return {
        ...state,
        errorMessage: null,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.ADD_FILE):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.ADD_FILE):
      return {
        ...state,
        loading: false,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    case ACTION_TYPES.REDUCE_WARNING_COUNT:
      return {
        ...state,
        validationResult: state.validationResult
          ? new RegistrationValidationDto({
              ...state.validationResult,
              count: state.validationResult?.count
                ? new ValidationCountDto({
                    ...state.validationResult.count,
                    warnings: state.validationResult.count.warnings - 1,
                    infos: state.validationResult.count.infos + 1,
                    userConfirmations: state.validationResult.count.userConfirmations + 1,
                  })
                : null,
              canSubmit: state.validationResult.count.warnings - 1 + state.validationResult.count.errors === 0,
            })
          : null,
      };
    default:
      return state;
  }
};
