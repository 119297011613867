import React, { useEffect } from 'react';
import { IRootState } from 'app/config/root.reducer';
import { connect, ConnectedProps } from 'react-redux';
import { NavigationTabs, ITabModel } from 'app/shared/layout/ui-elements/navigation-tabs';
import importRequestsModule from './import-requests/index';
import { RoleType } from 'app/clients/services';
import { hasAnyRole } from 'app/shared/auth/roles';
import { rememberOpenTab } from '../application/actions';

export type Props = PropsFromRedux & {
  baseUrl: string;
};

const RegistrationImportTabs = (props: Props) => {
  useEffect(() => {
    const loadInitialData = async () => {
      if (props.isRegistrationAdmin) {
        await props.countPendingImports();
      }
    };

    loadInitialData();
  }, [props.allowedUas, props.isRegistrationAdmin]);

  const tabs: ITabModel[] = [];

  if (props.isRegistrationAdmin) {
    tabs.push({
      label: 'Import Requests',
      url: 'requests',
      isDefault: props.isRegistrationAdmin,
      useBatch: props.isRegistrationAdmin,
      batchCount: props.pendingImportsCount,
    });
  }

  if (props.isRegistrationAdmin || props.allowedUas.length > 0) {
    tabs.push({ label: 'Imports', url: 'list', isDefault: !props.isRegistrationAdmin } as ITabModel);
  }

  tabs.push({ label: 'Excel Upload', url: 'file-upload', isDefault: !props.isRegistrationAdmin } as ITabModel);

  return (
    <NavigationTabs
      tabs={tabs}
      baseUrl={props.baseUrl}
      openTab={props.openTabs[props.baseUrl]}
      onChange={(tab: ITabModel) => props.rememberOpenTab(props.baseUrl, tab)}
    />
  );
};

const mapStateToProps = ({ importRequests, auth, application }: IRootState) => ({
  pendingImportsCount: importRequests.pendingImportsCount,
  isRegistrationAdmin:
    auth.isLoggedIn && auth.account?.isAuthenticated && hasAnyRole(auth.account?.roles, [RoleType.ADMIN, RoleType.REGISTRATIONADMIN]),
  allowedUas: auth.allowedUas,
  openTabs: application.openTabs,
});

const mapDispatchToProps = dispatch => {
  const actions = importRequestsModule.initActions();
  return {
    countPendingImports: async () => await dispatch(actions.countPendingImportsAsync()),
    rememberOpenTab: (baseUrl: string, tab: ITabModel) => dispatch(rememberOpenTab(baseUrl, tab)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(RegistrationImportTabs);
