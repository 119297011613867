import { ILabelDto, IProfileDto } from 'app/clients/services';
import ACTION_TYPES from './types';

// State
const initialState = {
  labels: [] as ILabelDto[],
  profiles: [] as IProfileDto[],
};

export type ProfileSelectionState = Readonly<typeof initialState>;

// Reducer
export default (state: ProfileSelectionState = initialState, action): ProfileSelectionState => {
  switch (action.type) {
    case ACTION_TYPES.SET_LABELS:
      return {
        ...state,
        labels: action.payload,
      };
    case ACTION_TYPES.SET_PROFILES:
      return {
        ...state,
        profiles: action.payload,
      };
    default:
      return state;
  }
};
