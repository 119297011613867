import {
  CommentDto,
  FileResponse,
  IAcademicYearDto,
  ICommentDto,
  IFileImportDto,
  IImportClient,
  IPhaseDto,
  IRegistrationEditClient,
  ISemesterInfoDto,
  RegistrationValidationDto,
} from 'app/clients/services';
import { addExceptionNotification, addSuccess } from 'app/modules/notification';
import { AsyncThunk } from 'app/shared/util/action-type.util';
import { saveAs } from 'file-saver';
import ACTION_TYPES from './types';

const initActions = (
  importClient: IImportClient,
  registrationEditClient: IRegistrationEditClient,
  selectionActions: {
    setFilter: (academicYear: IAcademicYearDto, semester: ISemesterInfoDto, phase: IPhaseDto) => void;
  }
) => {
  const selectImport = (id: number | null) => ({
    type: ACTION_TYPES.SELECT_IMPORT,
    payload: id,
  });

  const selectImportAsync: (id: number) => AsyncThunk<RegistrationValidationDto | null> =
    (id) => async (dispatch, getState) => {
      if (id > 0) {
        dispatch(selectImport(id));

        const result = await dispatch({
          type: ACTION_TYPES.GET_VALIDATION,
          payload: importClient.getImport(id),
        });

        const imported = result.value?.registrationImport;
        if (imported?.semesterId && imported.semesterId !== getState().semesterSelection?.selectedSemester?.id) {
          const semester = getState().semesterSelection.semesterFilter?.semesters?.find(
            (s) => s.id === imported.semesterId
          );
          const academicYear = getState().semesterSelection.semesterFilter?.academicYears?.find(
            (s) => s.id === semester?.academicYearId
          );

          await dispatch(setFilterAsync(academicYear, semester, null));
          if (
            getState().importsManagement.selectedPhase &&
            getState().importsManagement.selectedPhase.id !== result.value.phaseId
          ) {
            const phase = semester.phases.find((p) => p.id === result.value.phaseId);
            dispatch({ type: ACTION_TYPES.SELECT_PHASE, payload: phase });
          }
        }

        return result.value;
      }

      return null;
    };

  const getImportsAsync: (
    academicYear?: IAcademicYearDto,
    semester?: ISemesterInfoDto,
    phase?: IPhaseDto
  ) => AsyncThunk<IFileImportDto[]> = (academicYear, semester, phase) => async (dispatch, getState) => {
    setMainContentLoading(true);
    const semesterSelection = getState().semesterSelection;
    const result = await dispatch({
      type: ACTION_TYPES.GET_IMPORTS,
      payload: importClient.getImports(
        (academicYear ?? semesterSelection.selectedAcademicYear)?.id,
        (semester ?? semesterSelection.selectedSemester)?.id,
        phase?.id ?? 0
      ),
    });
    setMainContentLoading(false);
    return result.value;
  };

  const downloadAsync: (id: number) => AsyncThunk<FileResponse> = (id) => async (dispatch) => {
    const result = await dispatch({
      type: ACTION_TYPES.DOWNLOAD,
      payload: importClient.getOriginalFile(id),
    });

    const fileResponse = result.value;
    saveAs(fileResponse.data, fileResponse.fileName);
    return fileResponse;
  };

  const rejectAsync: (id: number) => AsyncThunk<boolean> = (id) => async (dispatch) => {
    const result = await dispatch({
      type: ACTION_TYPES.CHANGE_STATE,
      payload: importClient.reject(id),
    });

    await dispatch(getImportsAsync());
    return result.value;
  };

  const deleteAsync: (id: number) => AsyncThunk<boolean> = (id) => async (dispatch) => {
    const result = await dispatch({
      type: ACTION_TYPES.CHANGE_STATE,
      payload: importClient.delete(id),
    });
    dispatch(selectImport(null));
    await dispatch(getImportsAsync());
    return result.value;
  };

  const withdrawAsync: (id: number) => AsyncThunk<boolean> = (id) => async (dispatch) => {
    const result = await dispatch({
      type: ACTION_TYPES.CHANGE_STATE,
      payload: importClient.withdraw(id),
    });

    dispatch(selectImport(null));
    await dispatch(getImportsAsync());
    return result.value;
  };

  const moveToPhaseAsync: (id: number, phaseId: number) => AsyncThunk<boolean> = (id, phaseId) => async (dispatch) => {
    const result = await dispatch({
      type: ACTION_TYPES.CHANGE_STATE,
      payload: importClient.moveToPhase(id, phaseId),
    });

    await dispatch(getImportsAsync());
    dispatch(selectImport(null));
    return result.value;
  };

  const setMainContentLoading = (loading: boolean) => ({
    type: ACTION_TYPES.SET_MAIN_CONTENT_LOADING,
    payload: loading,
  });

  const setFilterAsync: (academicYear: IAcademicYearDto, semester: ISemesterInfoDto, phase: IPhaseDto) => AsyncThunk =
    (academicYear, semester, phase) => async (dispatch) => {
      dispatch({ type: ACTION_TYPES.SELECT_PHASE, payload: phase });
      dispatch(selectionActions.setFilter(academicYear, semester, phase));
      await dispatch(getImportsAsync(academicYear, semester, phase));
    };

  const addCommentAsync: (comment: ICommentDto) => AsyncThunk = (comment) => async (dispatch) => {
    try {
      await registrationEditClient.addComment(new CommentDto(comment));
      await dispatch({
        type: ACTION_TYPES.LOAD_MODULE_REGISTRATION,
        payload: registrationEditClient.getModuleRegistration(comment.registrationId),
      });
      dispatch(addSuccess(`Successfully added comment.`));
    } catch (e) {
      dispatch(addExceptionNotification(e, 'An unknown error occured while saving comment.'));
    }
  };

  const removeCommentAsync: (comment: ICommentDto) => AsyncThunk = (comment) => async (dispatch) => {
    try {
      await registrationEditClient.deleteComment(comment.id);
      await dispatch({
        type: ACTION_TYPES.LOAD_MODULE_REGISTRATION,
        payload: registrationEditClient.getModuleRegistration(comment.registrationId),
      });
      dispatch(addSuccess(`Successfully deleted comment.`));
    } catch (e) {
      dispatch(addExceptionNotification(e, 'An unknown error occured while deleting comment.'));
    }
  };

  const loadModuleRegistrationAsync: (registrationId: number) => AsyncThunk =
    (registrationId) => async (dispatch, getState) => {
      if (!registrationId) {
        const registration = getState().importsManagement.selectedRegistration;
        if (registration && !getState().importsManagement.moduleRegistrationLoading) {
          dispatch({
            type: ACTION_TYPES.CLEAR_MODULE_REGISTRATION,
          });
        }

        return Promise.resolve();
      }

      await dispatch({
        type: ACTION_TYPES.LOAD_MODULE_REGISTRATION,
        payload: registrationEditClient.getModuleRegistration(registrationId),
      });
    };

  return Object.freeze({
    loadModuleRegistrationAsync,
    addCommentAsync,
    removeCommentAsync,
    selectImportAsync,
    getImportsAsync,
    downloadAsync,
    rejectAsync,
    withdrawAsync,
    moveToPhaseAsync,
    deleteAsync,
    setFilterAsync,
  });
};

export default initActions;

export type RegistrationImportActions = ReturnType<typeof initActions>;
