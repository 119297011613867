import { ISemesterRegistrationDto } from 'app/clients/services';
import { ConfirmationDialog } from 'app/shared/layout/ui-elements/confirmation-dialog';
import React from 'react';

type ConfirmDeleteSemesterProps = {
  semesterRegistration: ISemesterRegistrationDto;
  open: boolean;
  onDelete: (semesterRegistration: ISemesterRegistrationDto) => Promise<void> | void;
  onClose: () => void;
};

export const ConfirmDeleteSemesterRegistration = (props: ConfirmDeleteSemesterProps) => {
  return (
    <ConfirmationDialog title="Delete semester registration" onConfirm={() => props.onDelete(props.semesterRegistration)} open={props.open} onClose={props.onClose}>
      {props.semesterRegistration?.moduleRegistrations?.length > 0 && (
        <>
          The semester registration has {props.semesterRegistration?.moduleRegistrations?.length} module registration(s). <br />
        </>
      )}
      Do you really want to delete the semester registration{' '}
      <strong>
        {props.semesterRegistration?.semester} at {props.semesterRegistration?.registeredAt}
      </strong>
      ?
    </ConfirmationDialog>
  );
};
