import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Priorities from './priorities';
import ProfilesTabs from './profile-management-tabs';
import Profiles from './profiles';

const ProfileManagementRoutes = ({ baseUrl }) => {
  return (
    <>
      <ProfilesTabs baseUrl={baseUrl} />
      <Routes>
        <Route path={`profiles/:profileId?/:semesterId?`} element={<Profiles />} />
        <Route path={`priorities`} element={<Priorities />} />
      </Routes>
    </>
  );
};

export default ProfileManagementRoutes;
