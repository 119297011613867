import initActions from './actions';
import types from './types';
import reducer from './reducer';
import { IEmailClient, IRegistrationClient, IRegistrationEditClient, IStudentClient } from 'app/clients/services';
import { resolver } from 'app/clients/service-registry';
import studentTabsModule from '../tabs';

export type { StudentManagementState } from './reducer';
export type { StudentManagementActions } from './actions';

export default {
  initActions: dispatch =>
    initActions(
      dispatch,
      resolver.resolve<IStudentClient>('IStudentClient'),
      resolver.resolve<IEmailClient>('IEmailClient'),
      resolver.resolve<IRegistrationEditClient>('IRegistrationEditClient'),
      resolver.resolve<IRegistrationClient>('IRegistrationClient'),
      studentTabsModule.initActions(dispatch)
    ),
  types,
  reducer,
};
