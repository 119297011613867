import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { Box, ButtonGroup, Paper, Typography } from '@mui/material';
import { IRootState } from 'app/config/root.reducer';

import { GridCellParams, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import {
  IAcademicYearDto,
  ICommentDto,
  ImportState,
  IPhaseDto,
  ISemesterInfoDto,
  RoleType,
} from 'app/clients/services';
import { hasAnyRole } from 'app/shared/auth/roles';
import { DefaultButton } from 'app/shared/layout/ui-elements/buttons';
import { MainContent, SideBar, SplitLayout } from 'app/shared/layout/ui-elements/component-layout';
import { ImportStateIcon } from 'app/shared/layout/ui-elements/import-state-icon';
import { dateTimeFormatter, dateTimeSorter, StandardDataGrid } from 'app/shared/layout/ui-elements/standard-data-grid';
import { StandardDialog } from 'app/shared/layout/ui-elements/standard-dialog';
import { combineUrlParams, getBasePath } from 'app/shared/util/url-utils';
import { CloseReason } from 'notistack';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { SemesterSelection } from '../../shared/layout/semester-selection';
import { addExceptionNotification } from '../notification';
import importsModule from './imports/index';
import { ImportPreview } from './parts/import-preview/import-preview';

export type Props = PropsFromRedux;

export const Imports = (props: Props) => {
  const navigate = useNavigate();

  const { importId } = useParams<{ importId: string }>();
  const basePath = getBasePath(useLocation(), { importId });

  const [moveToPhaseDialogOpen, setMoveToPhaseDialogOpen] = React.useState(false);
  const [moveToAcademicYear, setMoveToAcademicYear] = React.useState<IAcademicYearDto>(props.academicYear);
  const [moveToSemester, setMoveToSemester] = React.useState<ISemesterInfoDto>(props.semester);
  const [moveToPhase, setMoveToPhase] = React.useState<IPhaseDto>(
    props.semester?.phases?.find((p) => p.id === props.semester.currentPhaseId),
  );

  useEffect(() => {
    const loadInitialData = async () => {
      if (importId && Number(importId) > 0 && props.selectedImportId !== Number(importId)) {
        await props.selectImportAsync(Number(importId));
      } else if (props.selectedImportId) {
        navigate(basePath + combineUrlParams({ importId: props.selectedImportId }));
      } else {
        navigate(basePath);
      }

      try {
        await props.getImportsAsync();
      } catch (err) {
        props.addExceptionNotification(err);
      }
    };
    loadInitialData();
  }, []);

  useEffect(() => {}, []);

  const columns: GridColDef[] = [
    {
      field: 'importState',
      headerName: 'State',
      width: 60,
      renderCell: (params: GridCellParams) => <ImportStateIcon state={params.value as ImportState} />,
    },
    { field: 'uas', headerName: 'UAS', width: 120 },
    { field: 'semester', headerName: 'Semester', width: 130 },
    { field: 'phase', headerName: 'Phase', width: 130 },
    {
      field: 'uploadedDate',
      headerName: 'Submitted',
      width: 140,
      sortComparator: dateTimeSorter,
      valueFormatter: dateTimeFormatter,
    },
    {
      field: 'uploadedByName',
      headerName: 'Submitted By',
      width: 150,
    },
    {
      field: 'importedDate',
      headerName: 'Imported/Rejected',
      width: 140,
      sortComparator: dateTimeSorter,
      valueFormatter: dateTimeFormatter,
    },
    {
      field: 'importedByName',
      headerName: 'Imported/Rejected By',
      width: 150,
    },
  ];

  const handleSelectionChanged = (newSelection: GridRowSelectionModel) => {
    const selectedId = newSelection[0] ? Number(newSelection[0]) : 0;
    props.selectImportAsync(selectedId);
    if (selectedId > 0) {
      navigate(basePath + combineUrlParams({ importId: selectedId }));
    } else {
      navigate(basePath);
    }
  };

  const handleMoveToChange = (academicYear: IAcademicYearDto, semester: ISemesterInfoDto, phase: IPhaseDto) => {
    setMoveToAcademicYear(academicYear);
    setMoveToSemester(semester);
    setMoveToPhase(phase);
  };

  return (
    <SplitLayout>
      <SideBar>
        <SemesterSelection
          onChange={async (academicYear, semester, phase) => {
            await props.setFilterAsync(academicYear, semester, phase);
          }}
          academicYear={props.academicYear}
          semester={props.semester}
          phase={props.phase}
          filter={props.semesterFilter}
          allowEmpty="phase"
        />
      </SideBar>
      <MainContent>
        <Box hidden={!props.canViewImports}>
          <StandardDataGrid
            columns={columns}
            rows={props.imports}
            loading={props.loading}
            onSelectionModelChange={handleSelectionChanged}
            columnVisibilityModel={{}}
          ></StandardDataGrid>
        </Box>
        {props.selectedImport && (
          <Box style={{ width: '100%' }}>
            <StandardDialog
              title="Move To Phase"
              open={moveToPhaseDialogOpen}
              onConfirm={async () => {
                await props.moveToPhaseAsync(props.selectedImportId, moveToPhase?.id);
                setMoveToPhaseDialogOpen(false);
              }}
              confirmName="Move To Phase"
              onCancel={() => {
                setMoveToPhaseDialogOpen(false);
              }}
            >
              <Typography>
                Please select the Phase you want to move the import
                <br /> {props.selectedImport?.uas} - {props.selectedImport?.semester} - {props.selectedImport?.phaseId}{' '}
                to.
              </Typography>
              <SemesterSelection
                filter={props.semesterFilter}
                academicYear={moveToAcademicYear}
                semester={moveToSemester}
                phase={moveToPhase}
                onChange={handleMoveToChange}
              />
            </StandardDialog>
            <Paper style={{ padding: 5, marginTop: 20 }}>
              <ButtonGroup aria-label="view-actions" style={{ marginRight: 20 }}>
                <DefaultButton
                  primary
                  disabled={props.previewLoading || props.loading}
                  onClick={() => props.downloadAsync(props.selectedImport?.id)}
                >
                  Download
                </DefaultButton>
              </ButtonGroup>

              {props.isRegistrationAdmin && props.selectedImport.importState !== ImportState.IMPORTED && (
                <ButtonGroup aria-label="view-actions" style={{ marginRight: 20 }}>
                  <DefaultButton
                    disabled={props.previewLoading || props.loading}
                    onClick={() => setMoveToPhaseDialogOpen(true)}
                  >
                    Move To Phase
                  </DefaultButton>
                  {props.selectedImport.importState !== ImportState.REJECTED &&
                    props.selectedImport.importState !== ImportState.DELETED &&
                    props.selectedImport.importState !== ImportState.WITHDRAWN && (
                      <DefaultButton
                        disabled={props.previewLoading || props.loading}
                        onClick={() => props.rejectAsync(props.selectedImport?.id)}
                      >
                        Reject
                      </DefaultButton>
                    )}
                  {props.isRegistrationAdmin &&
                    (props.selectedImport.importState === ImportState.REJECTED ||
                      props.selectedImport.importState === ImportState.WITHDRAWN) && (
                      <DefaultButton
                        disabled={props.previewLoading || props.loading}
                        onClick={() => props.deleteAsync(props.selectedImport?.id)}
                      >
                        Delete
                      </DefaultButton>
                    )}
                </ButtonGroup>
              )}
              {props.isMasterOffice && props.selectedImport.importState !== ImportState.IMPORTED && (
                <ButtonGroup aria-label="view-actions" style={{ marginRight: 20 }}>
                  {props.selectedImport.importState === ImportState.REQUESTED && (
                    <DefaultButton
                      disabled={props.previewLoading || props.loading}
                      onClick={() => props.withdrawAsync(props.selectedImport?.id)}
                    >
                      Withdraw
                    </DefaultButton>
                  )}
                  {props.selectedImport.importState === ImportState.WITHDRAWN && (
                    <DefaultButton
                      disabled={props.previewLoading || props.loading}
                      onClick={() => props.deleteAsync(props.selectedImport?.id)}
                    >
                      Delete
                    </DefaultButton>
                  )}
                </ButtonGroup>
              )}
            </Paper>
            {props.selectedImport && (
              <ImportPreview
                uasImport={props.selectedResult?.registrationImport}
                fileImport={props.selectedImport}
                isLoading={props.previewLoading}
                disabled={props.previewLoading}
                validation={props.selectedResult}
                refreshRegistrationInfo={props.loadModuleRegistrationAsync}
                onRemoveComment={props.removeCommentAsync}
                onAddComment={props.addCommentAsync}
              />
            )}
          </Box>
        )}
      </MainContent>
    </SplitLayout>
  );
};

const mapStateToProps = ({ importsManagement, auth, semesterSelection }: IRootState) => ({
  canViewImports:
    (auth.isLoggedIn &&
      auth.account?.isAuthenticated &&
      hasAnyRole(auth.account?.roles, [RoleType.ADMIN, RoleType.REGISTRATIONADMIN])) ||
    auth.allowedUas?.length > 0,
  isMasterOffice:
    auth.isLoggedIn &&
    auth.account?.isAuthenticated &&
    auth.account?.roles.filter((r) => r === RoleType.MASTEROFFICE) &&
    !hasAnyRole(auth.account?.roles, [RoleType.ADMIN, RoleType.REGISTRATIONADMIN]) &&
    auth.allowedUas?.length > 0,
  isRegistrationAdmin:
    auth.isLoggedIn &&
    auth.account?.isAuthenticated &&
    hasAnyRole(auth.account?.roles, [RoleType.ADMIN, RoleType.REGISTRATIONADMIN]),
  loading: importsManagement.loading,
  academicYear: semesterSelection.selectedAcademicYear,
  semester: semesterSelection.selectedSemester,
  imports: importsManagement.imports,
  selectedImportId: importsManagement.selectedImportId,
  selectedImport:
    importsManagement.imports?.find((i) => i.id === importsManagement.selectedImportId) ??
    importsManagement.selectedResult?.fileImport,
  selectedResult: importsManagement.selectedResult,
  previewLoading: importsManagement.previewLoading,
  semesterFilter: semesterSelection.semesterFilter,
  phase: importsManagement.selectedPhase,
  selectedRegistration: importsManagement.selectedRegistration,
});

const mapDispatchToProps = (dispatch) => {
  const actions = importsModule.initActions();
  return {
    loadModuleRegistrationAsync: (id: number) => dispatch(actions.loadModuleRegistrationAsync(id)),
    addCommentAsync: (comment: ICommentDto) => dispatch(actions.addCommentAsync(comment)),
    removeCommentAsync: (comment: ICommentDto) => dispatch(actions.removeCommentAsync(comment)),
    addExceptionNotification: (
      e: any,
      message?: string,
      onClose?: (event: React.SyntheticEvent<any, Event>, reason: CloseReason) => void,
    ) => dispatch(addExceptionNotification(e, message, onClose)),
    downloadAsync: async (id: number) => await dispatch(actions.downloadAsync(id)),
    rejectAsync: async (id: number) => await dispatch(actions.rejectAsync(id)),
    deleteAsync: async (id: number) => await dispatch(actions.deleteAsync(id)),
    withdrawAsync: async (id: number) => await dispatch(actions.withdrawAsync(id)),
    moveToPhaseAsync: async (id: number, phaseId: number) => await dispatch(actions.moveToPhaseAsync(id, phaseId)),
    selectImportAsync: async (id: number) => await dispatch(actions.selectImportAsync(id)),
    getImportsAsync: async (academicYear?: IAcademicYearDto, semester?: ISemesterInfoDto, phase?: IPhaseDto) =>
      await dispatch(actions.getImportsAsync(academicYear, semester, phase)),
    setFilterAsync: async (academicYear: IAcademicYearDto, semester: ISemesterInfoDto, phase: IPhaseDto) =>
      await dispatch(actions.setFilterAsync(academicYear, semester, phase)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Imports);
