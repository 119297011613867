import { Chip, SxProps } from '@mui/material';
import React from 'react';

type Props = {
  label: string;
  url?: string;
  color?: 'primary' | 'secondary' | 'default';
  onClick?: () => void | Promise<void>;
  onDelete?: () => void | Promise<void>;
  sx?: SxProps;
};

export const StandardChip = (props: Props) => {
  return (
    <Chip
      label={props.label}
      component={props.url ? 'a' : 'span'}
      href={props.url ? props.url : undefined}
      color={props.color}
      onClick={
        props.onClick
          ? (event) => {
              props.onClick();
              event.stopPropagation();
            }
          : undefined
      }
      onDelete={
        props.onDelete
          ? (event) => {
              props.onDelete();
              event.stopPropagation();
            }
          : undefined
      }
      style={props.url ? { cursor: 'pointer' } : {}}
      sx={props.sx}
    />
  );
};
