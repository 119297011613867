import { ISemesterDto, IStudentDto, IStudentPreviewDto, IUasDto } from 'app/clients/services';
import ACTION_TYPES from './types';

// State
const initialState = {
  searchLoading: false,
  loadedStudent: null as IStudentDto,
  searchResult: [] as IStudentPreviewDto[],
  filterByUas: [] as IUasDto[],
  filterBySemester: [] as ISemesterDto[],
  searchString: null as string,
  resultSearchString: null as string,
};

export type StudentSearchState = Readonly<typeof initialState>;

// Reducer
export default (state: StudentSearchState = initialState, action): StudentSearchState => {
  switch (action.type) {
    case ACTION_TYPES.SET_SEARCH_LOADING:
      return {
        ...state,
        searchLoading: action.payload,
      };
    case ACTION_TYPES.ADD_UAS_FILTER:
      return {
        ...state,
        filterByUas: [...state.filterByUas.filter(t => t.id !== action.payload.id), action.payload],
      };
    case ACTION_TYPES.REMOVE_UAS_FILTER:
      return {
        ...state,
        filterByUas: state.filterByUas.filter(t => t.id !== action.payload.id),
      };
    case ACTION_TYPES.ADD_SEMESTER_FILTER:
      return {
        ...state,
        filterBySemester: [...state.filterBySemester.filter(t => t.id !== action.payload.id), action.payload],
      };
    case ACTION_TYPES.REMOVE_SEMESTER_FILTER:
      return {
        ...state,
        filterBySemester: state.filterBySemester.filter(t => t.id !== action.payload.id),
      };
    case ACTION_TYPES.SET_SEARCH_RESULT:
      return {
        ...state,
        searchResult: action.payload,
      };
    case ACTION_TYPES.SET_SEARCH_STRING:
      return {
        ...state,
        searchString: action.payload,
      };
    case ACTION_TYPES.SET_RESULT_SEARCH_STRING:
      return {
        ...state,
        resultSearchString: action.payload,
      };
    default:
      return state;
  }
};
