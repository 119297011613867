export default {
  SET_ACCOUNTS_LOADING: 'userManagement/SET_ACCOUNTS_LOADING',
  SET_ROLE_LOADING: 'userManagement/SET_ROLE_LOADING',
  SET_ACCOUNT_LOADING: 'userManagement/SET_ACCOUNT_LOADING',
  SET_REQUESTS_LOADING: 'userManagement/SET_PENDING_REQUESTS_LOADING',
  SET_PENDING_REQUESTS: 'userManagement/SET_PENDING_REQUESTS',
  SET_REJECTED_REQUESTS_LOADING: 'userManagement/SET_REJECTED_REQUESTS_LOADING',
  SET_REJECTED_REQUESTS: 'userManagement/SET_REJECTED_REQUESTS',
  SET_ACCOUNTS: 'userManagement/SET_ACCOUNTS',
  SET_SELECTED_ACCOUNT: 'userManagement/SET_SELECTED_ACCOUNT',
  RESET: 'userManagement/RESET',
  UPDATE_ROLES: 'userManagement/UPDATE_ROLES',
  SET_EXISTING_USER: 'userManagement/SET_EXISTING_USER',
  SET_EXISTING_USER_LOADING: 'userManagement/SET_EXISTING_USER_LOADING',
};
