import DeleteIcon from '@mui/icons-material/DeleteForever';
import { FormControlLabel, TableCell, TableRow } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import { IPriorityDto } from 'app/clients/services';
import { DefaultButton } from 'app/shared/layout/ui-elements/buttons';
import ColorPicker from 'app/shared/layout/ui-elements/color-picker';
import { ErrorMessage, Field, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  weighting: Yup.number().required('Please enter a weighting larger than or equal to 0!'),
  name: Yup.string().required('The name is required'),
  description: Yup.string().optional(),
  colorCode: Yup.string().required(),
  isActive: Yup.boolean().required(),
});

interface Params {
  priority: IPriorityDto;
  onSubmit: (priority: IPriorityDto) => Promise<void>;
  onDelete: (priority: IPriorityDto) => Promise<void>;
  disabled: boolean;
}

type FormType = {
  weighting: number;
  name: string;
  description: string;
  colorCode: string;
  isActive: boolean;
  canBeDeleted: boolean;
};

export const PriorityRow = (props: Params) => {
  const hasChanges = (values: FormType) => {
    return (
      props.priority.weighting !== values.weighting ||
      props.priority.name !== values.name ||
      props.priority.description !== values.description ||
      props.priority.colorCode !== values.colorCode ||
      props.priority.isActive !== values.isActive
    );
  };

  return (
    <TableRow>
      <Formik
        initialValues={{
          weighting: props.priority.weighting,
          name: props.priority.name,
          description: props.priority.description,
          colorCode: props.priority.colorCode,
          isActive: props.priority.isActive,
          canBeDeleted: props.priority.canBeDeleted,
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          props.onSubmit({
            id: props.priority.id,
            canBeDeleted: props.priority.canBeDeleted,
            ...values,
          });
        }}
      >
        {({ setFieldValue, values, submitForm }) => (
          <>
            <TableCell>
              <Field
                variant="standard"
                as={TextField}
                name="weighting"
                label="Weighting"
                type="number"
                disabled={props.disabled}
                onChange={(event) => {
                  setFieldValue('weighting', event.target.value);
                }}
                onBlur={() => {
                  if (hasChanges(values)) {
                    submitForm();
                  }
                }}
              />
              <ErrorMessage name="weighting" component="div" />
            </TableCell>
            <TableCell>
              <Field
                variant="standard"
                as={TextField}
                name="name"
                label="Name"
                type="text"
                disabled={props.disabled}
                onChange={(event) => {
                  setFieldValue('name', event.target.value);
                }}
                onBlur={() => {
                  if (hasChanges(values)) {
                    submitForm();
                  }
                }}
              />
              <ErrorMessage name="name" component="div" />
            </TableCell>
            <TableCell>
              <Field
                variant="standard"
                as={TextField}
                name="description"
                label="Description"
                type="text"
                disabled={props.disabled}
                onChange={(event) => {
                  setFieldValue('description', event.target.value);
                }}
                onBlur={() => {
                  if (hasChanges(values)) {
                    submitForm();
                  }
                }}
              />
              <ErrorMessage name="description" component="div" />
            </TableCell>
            <TableCell>
              <ColorPicker
                rgbaColor={values['colorCode']}
                onChange={(color: string) => {
                  setFieldValue('colorCode', color);
                  if (hasChanges({ ...values, colorCode: color })) {
                    submitForm();
                  }
                }}
              />
              <ErrorMessage name="colorCode" component="div" />
            </TableCell>
            <TableCell>
              <FormControlLabel
                control={
                  <Field
                    as={Checkbox}
                    lable="Is Active"
                    name="isActive"
                    value={values['isActive']}
                    checked={values['isActive']}
                    disabled={props.disabled}
                    onChange={(event) => {
                      setFieldValue('isActive', event.target.checked);
                      if (hasChanges({ ...values, isActive: event.target.checked })) {
                        submitForm();
                      }
                    }}
                  />
                }
                label="Is Active"
              />

              <ErrorMessage name="isActive" component="div" />
            </TableCell>
            <TableCell>
              {props.priority.canBeDeleted && (
                <DefaultButton
                  disabled={props.disabled}
                  startIcon={<DeleteIcon />}
                  onClick={() => props.onDelete(props.priority)}
                >
                  Delete
                </DefaultButton>
              )}
            </TableCell>
          </>
        )}
      </Formik>
    </TableRow>
  );
};
