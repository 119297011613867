import React from 'react';
import { NavLink as Link } from 'react-router-dom';

import { Box, Grid } from '@mui/material';
import appConfig from 'app/config/constants';

export const BrandIcon = () => {
  return (
    <Box
      sx={{
        width: 'auto',
        display: 'inline-block',
      }}
    >
      <img src="./content/images/mse-full-white.svg" alt="Logo" style={{ height: 60 }} />
    </Box>
  );
};

export const Brand = () => {
  return (
    <Link to="/" style={{ textDecoration: 'none' }}>
      <Grid container spacing={0} alignItems="center">
        <Grid
          item
          sx={{
            visibility: { xs: 'hidden', md: 'visible' },
            display: { xs: 'none', md: 'inherit' },
          }}
        >
          <BrandIcon />
        </Grid>
        <Grid
          item
          sx={{
            '&:hover': {
              textDecoration: 'none',
            },
            color: '#fff',
            fontSize: { xs: 10, md: 24 },
          }}
        >
          MSE Admin Engine
        </Grid>
        <Grid
          item
          sx={{
            fontSize: 12,
            color: '#fff',
            marginLeft: 2,
          }}
        >
          <>v{appConfig.VERSION}</>
        </Grid>
      </Grid>
    </Link>
  );
};
