import initActions from './actions';
import types from './types';
import reducer from './reducer';

import { IStudentClient } from 'app/clients/services';
import { resolver } from 'app/clients/service-registry';

export type { StudentSearchState } from './reducer';
export default {
  initActions: dispatch => initActions(dispatch, resolver.resolve<IStudentClient>('IStudentClient')),
  types,
  reducer,
};
