import {
  AccountInfoDto,
  IAccountClient,
  IAccountDto,
  IAccountInfoDto,
  IAccountRequestDto,
  IEmailClient,
  IEmailDto,
  IModuleExecutionDto,
  IRoleInfoDto,
  IUasDto,
  IUserRoleDto,
  RoleType,
} from 'app/clients/services';
import { addExceptionNotification } from 'app/modules/notification';
import { AsyncThunk } from 'app/shared/util/action-type.util';
import ACTION_TYPES from './types';

const initActions = (componentDispatch: any, accountClient: IAccountClient, emailClient: IEmailClient) => {
  // Actions
  const reset = () => ({
    type: ACTION_TYPES.RESET,
  });

  const setExistingUserLoading = (loading: boolean) => ({
    type: ACTION_TYPES.SET_EXISTING_USER_LOADING,
    payload: loading,
  });

  const setExistingUser = (user: IAccountDto | null) => ({
    type: ACTION_TYPES.SET_EXISTING_USER,
    payload: user,
  });

  const setAccountLoading = (loading: boolean) => ({
    type: ACTION_TYPES.SET_ACCOUNT_LOADING,
    payload: loading,
  });

  const setRoleLoading = (role: RoleType | null) => ({
    type: ACTION_TYPES.SET_ROLE_LOADING,
    payload: role,
  });

  const selectAccount = (account: IAccountInfoDto | null) => ({
    type: ACTION_TYPES.SET_SELECTED_ACCOUNT,
    payload: account,
  });

  const updateRoles = (accountId: string, roleInfos: IRoleInfoDto | null) => ({
    type: ACTION_TYPES.UPDATE_ROLES,
    payload: { accountId, roleInfos },
  });

  const setAccountsLoading = (loading: boolean) => ({
    type: ACTION_TYPES.SET_ACCOUNTS_LOADING,
    payload: loading,
  });

  const setAccounts = (accounts: IAccountDto[]) => ({
    type: ACTION_TYPES.SET_ACCOUNTS,
    payload: accounts,
  });

  const setRequestsLoading = (loading: boolean) => ({
    type: ACTION_TYPES.SET_REQUESTS_LOADING,
    payload: loading,
  });

  const setPendingRequests = (accountRequests: IAccountRequestDto[]) => ({
    type: ACTION_TYPES.SET_PENDING_REQUESTS,
    payload: accountRequests,
  });

  const setRejectedRequests = (accountRequests: IAccountRequestDto[]) => ({
    type: ACTION_TYPES.SET_REJECTED_REQUESTS,
    payload: accountRequests,
  });

  const loadPendingRequestsAsync: () => AsyncThunk = () => async (dispatch) => {
    dispatch(setRequestsLoading(true));
    try {
      const result = (await accountClient.getRequests()) ?? [];
      dispatch(setPendingRequests(result));
      dispatch(setRequestsLoading(false));
    } catch (e) {
      dispatch(setRequestsLoading(false));
      dispatch(addExceptionNotification(e, 'An error occured while loading account requests.'));
    }
  };

  const loadRejectedRequestsAsync: () => AsyncThunk = () => async (dispatch) => {
    dispatch(setRequestsLoading(true));
    try {
      const result = (await accountClient.getBlockedRequests()) ?? [];
      dispatch(setRejectedRequests(result));
      dispatch(setRequestsLoading(false));
    } catch (e) {
      dispatch(setRequestsLoading(false));
      dispatch(addExceptionNotification(e, 'An error occured while loading accounts.'));
    }
  };

  const loadAccountsAsync: () => AsyncThunk = () => async (dispatch) => {
    dispatch(setAccountsLoading(true));
    try {
      const result = (await accountClient.getAccounts(true)) ?? [];
      dispatch(setAccounts(result));
      dispatch(setAccountsLoading(false));
    } catch (e) {
      dispatch(setAccountsLoading(false));
      dispatch(addExceptionNotification(e, 'An error occured while loading accounts.'));
    }
  };

  const loadAccountAsync: (id: string) => AsyncThunk = (id) => async (dispatch) => {
    dispatch(setAccountLoading(true));
    try {
      const result = await accountClient.getAccountInfo(id);
      const roleInfo = await accountClient.getRoleInfos(id);
      dispatch(selectAccount(result));
      dispatch(updateRoles(id, roleInfo));
      dispatch(setAccountLoading(false));
    } catch (e) {
      dispatch(setAccountLoading(false));
      dispatch(addExceptionNotification(e, 'An error occured while loading the account.'));
    }
  };

  const deleteEmailAsync: (emailDto: IEmailDto) => AsyncThunk = (emailDto) => async (dispatch) => {
    dispatch(setAccountLoading(true));
    try {
      await emailClient.delete(emailDto.id);
      await dispatch(loadAccountAsync(emailDto.userId));
      dispatch(setAccountLoading(false));
    } catch (e) {
      dispatch(setAccountLoading(false));
      dispatch(addExceptionNotification(e, 'An error occured while deleting the email.'));
    }
  };

  const updateAsync: (account: IAccountInfoDto) => AsyncThunk = (account) => async (dispatch) => {
    dispatch(setAccountLoading(true));
    try {
      await accountClient.update(new AccountInfoDto(account));
      await dispatch(loadAccountAsync(account.id));
      await dispatch(loadAccountsAsync());
      dispatch(setAccountLoading(false));
    } catch (e) {
      dispatch(setAccountLoading(false));
      dispatch(addExceptionNotification(e, 'An error occured while updating the account.'));
    }
  };

  const deleteAsync: (account: IAccountInfoDto) => AsyncThunk = (account) => async (dispatch) => {
    dispatch(setAccountLoading(true));
    try {
      await accountClient.delete(account.id);
      dispatch(selectAccount(null));
      await dispatch(loadAccountsAsync());
      dispatch(setAccountLoading(false));
    } catch (e) {
      dispatch(setAccountLoading(false));
      dispatch(addExceptionNotification(e, 'An error occured while deleting the account.'));
    }
  };

  const addRoleAsync: (account: IAccountInfoDto, roleType: RoleType) => AsyncThunk = (account, roleType) => async (dispatch) => {
    dispatch(setRoleLoading(roleType));
    dispatch(setAccountLoading(true));
    try {
      await accountClient.addRole(account.id, roleType);
      await dispatch(loadAccountsAsync());
      const roleInfo = await accountClient.getRoleInfos(account.id);
      dispatch(updateRoles(account.id, roleInfo));
      dispatch(setAccountLoading(false));
      dispatch(setRoleLoading(null));
    } catch (e) {
      dispatch(setAccountLoading(false));
      dispatch(setRoleLoading(null));
      dispatch(addExceptionNotification(e, 'An error occured while activating the account.'));
    }
  };

  const deleteRoleAsync: (account: IAccountInfoDto, roleType: RoleType) => AsyncThunk = (account, roleType) => async (dispatch) => {
    dispatch(setRoleLoading(roleType));
    dispatch(setAccountLoading(true));
    try {
      await accountClient.deleteRole(account.id, roleType);
      await dispatch(loadAccountsAsync());
      const roleInfo = await accountClient.getRoleInfos(account.id);
      dispatch(updateRoles(account.id, roleInfo));
      dispatch(setRoleLoading(null));
      dispatch(setAccountLoading(false));
    } catch (e) {
      dispatch(setAccountLoading(false));
      dispatch(setRoleLoading(null));
      dispatch(addExceptionNotification(e, 'An error occured while activating the account.'));
    }
  };

  const activateRoleAsync: (role: IUserRoleDto) => AsyncThunk = (role) => async (dispatch) => {
    dispatch(setRoleLoading(role.roleType));
    try {
      await accountClient.activateRole(role.id);
      dispatch(loadAccountsAsync());
      const roleInfo = await accountClient.getRoleInfos(role.userId);
      dispatch(updateRoles(role.userId, roleInfo));
      dispatch(setRoleLoading(null));
    } catch (e) {
      dispatch(setRoleLoading(null));
      dispatch(addExceptionNotification(e, `An error occured while activating the role ${role.roleType}.`));
    }
  };

  const deactivateRoleAsync: (role: IUserRoleDto) => AsyncThunk = (role) => async (dispatch) => {
    dispatch(setRoleLoading(role.roleType));
    try {
      await accountClient.deactivateRole(role.id);
      await dispatch(loadAccountsAsync());
      const roleInfo = await accountClient.getRoleInfos(role.userId);
      dispatch(updateRoles(role.userId, roleInfo));
      dispatch(setRoleLoading(null));
    } catch (e) {
      dispatch(setRoleLoading(null));
      dispatch(addExceptionNotification(e, `An error occured while deactivating the role ${role.roleType}.`));
    }
  };

  const addUasAsync: (role: IUserRoleDto, uas: IUasDto) => AsyncThunk = (role, uas) => async (dispatch) => {
    dispatch(setRoleLoading(role.roleType));
    try {
      await accountClient.addUas(role.id, uas.id);
      const roleInfo = await accountClient.getRoleInfos(role.userId);
      dispatch(updateRoles(role.userId, roleInfo));
      dispatch(setRoleLoading(null));
    } catch (e) {
      dispatch(setRoleLoading(null));
      dispatch(addExceptionNotification(e, `An error occured while adding the uas ${uas.code} to the role ${role.roleType}.`));
    }
  };

  const removeUasAsync: (role: IUserRoleDto, uas: IUasDto) => AsyncThunk = (role, uas) => async (dispatch) => {
    dispatch(setRoleLoading(role.roleType));
    try {
      await accountClient.removeUas(role.id, uas.id);
      const roleInfo = await accountClient.getRoleInfos(role.userId);
      dispatch(updateRoles(role.userId, roleInfo));
      dispatch(setRoleLoading(null));
    } catch (e) {
      dispatch(setRoleLoading(null));
      dispatch(addExceptionNotification(e, `An error occured while removing the uas ${uas.code} from the role ${role.roleType}.`));
    }
  };

  const addLecturerForAsync: (role: IUserRoleDto, moduleExecution: IModuleExecutionDto) => AsyncThunk = (role, moduleExecution) => async (dispatch) => {
    dispatch(setRoleLoading(role.roleType));
    try {
      await accountClient.addLecturerFor(role.id, moduleExecution.id);
      const roleInfo = await accountClient.getRoleInfos(role.userId);
      dispatch(updateRoles(role.userId, roleInfo));
      dispatch(setRoleLoading(null));
    } catch (e) {
      dispatch(setRoleLoading(null));
      dispatch(addExceptionNotification(e, `An error occured while adding the module execution ${moduleExecution.code} to the role ${role.roleType}.`));
    }
  };

  const removeLecturerForAsync: (role: IUserRoleDto, moduleExecution: IModuleExecutionDto) => AsyncThunk = (role, moduleExecution) => async (dispatch) => {
    dispatch(setRoleLoading(role.roleType));
    try {
      await accountClient.removeLecturerFor(role.id, moduleExecution.id);
      const roleInfo = await accountClient.getRoleInfos(role.userId);
      dispatch(updateRoles(role.userId, roleInfo));
      dispatch(setRoleLoading(null));
    } catch (e) {
      dispatch(setRoleLoading(null));
      dispatch(addExceptionNotification(e, `An error occured while removing the module execution ${moduleExecution.code} from the role ${role.roleType}.`));
    }
  };

  const activateAsync: (activationId: number, roleTypes: RoleType[]) => AsyncThunk = (activationId: number, roleTypes: RoleType[]) => async (dispatch) => {
    dispatch(setRequestsLoading(true));
    try {
      await accountClient.activate(activationId, roleTypes);
      const accounts = (await accountClient.getAccounts(true)) ?? [];
      dispatch(setAccounts(accounts));
      const pending = (await accountClient.getRequests()) ?? [];
      dispatch(setPendingRequests(pending));
      dispatch(setRequestsLoading(false));
    } catch (e) {
      dispatch(setRequestsLoading(false));
      dispatch(addExceptionNotification(e, 'An error occured while activating the account.'));
    }
  };

  const blockRequestAsync: (activationId: number) => AsyncThunk = (activationId: number) => async (dispatch) => {
    dispatch(setAccountsLoading(true));
    try {
      await accountClient.blockRequest(activationId);
      const blocked = (await accountClient.getBlockedRequests()) ?? [];
      dispatch(setRejectedRequests(blocked));
      const pending = (await accountClient.getRequests()) ?? [];
      dispatch(setPendingRequests(pending));
      dispatch(setAccountsLoading(false));
    } catch (e) {
      dispatch(setAccountsLoading(false));
      dispatch(addExceptionNotification(e, 'An error occured while blocking the account request.'));
    }
  };

  const deleteRequestAsync: (activationId: number) => AsyncThunk = (activationId: number) => async (dispatch) => {
    dispatch(setAccountsLoading(true));
    try {
      await accountClient.deleteRequest(activationId);
      const blocked = (await accountClient.getBlockedRequests()) ?? [];
      dispatch(setRejectedRequests(blocked));
      const pending = (await accountClient.getRequests()) ?? [];
      dispatch(setPendingRequests(pending));
      dispatch(setAccountsLoading(false));
    } catch (e) {
      dispatch(setAccountsLoading(false));
      dispatch(addExceptionNotification(e, 'An error occured while deleting the account request.'));
    }
  };

  const unblockRequestAsync: (activationId: number) => AsyncThunk = (activationId: number) => async (dispatch) => {
    dispatch(setAccountsLoading(true));
    try {
      await accountClient.unblockRequest(activationId);
      const blocked = (await accountClient.getBlockedRequests()) ?? [];
      dispatch(setRejectedRequests(blocked));
      const pending = (await accountClient.getRequests()) ?? [];
      dispatch(setPendingRequests(pending));
      dispatch(setAccountsLoading(false));
    } catch (e) {
      dispatch(setAccountsLoading(false));
      dispatch(addExceptionNotification(e, 'An error occured while deleting the account request.'));
    }
  };

  const emailAlreadyExistsAsync = async (userId: string, email: string) => {
    return await accountClient.checkEmail(userId, email);
  };

  const registerAsync = async () => {
    await accountClient.register();
  };

  const loadExistingUserAsync: (activationId: number) => AsyncThunk = (activationId: number) => async (dispatch) => {
    dispatch(setExistingUserLoading(true));
    try {
      const user = await accountClient.getExistingUser(activationId);
      dispatch(setExistingUser(user));
      dispatch(setExistingUserLoading(false));
    } catch (e) {
      dispatch(setExistingUserLoading(false));
      dispatch(addExceptionNotification(e, 'An error occured while checking for existing account.'));
    }
  };

  return Object.freeze({
    emailAlreadyExistsAsync,
    loadExistingUserAsync: async (activationId: number) => await componentDispatch(loadExistingUserAsync(activationId)),
    deleteRoleAsync: async (accountDto: IAccountInfoDto, roleType: RoleType) => await componentDispatch(deleteRoleAsync(accountDto, roleType)),
    addRoleAsync: async (accountDto: IAccountInfoDto, roleType: RoleType) => await componentDispatch(addRoleAsync(accountDto, roleType)),
    reset: () => componentDispatch(reset()),
    deleteEmailAsync: async (emailDto: IEmailDto): Promise<void> => await componentDispatch(deleteEmailAsync(emailDto)),
    loadAccountAsync: async (id: string): Promise<IAccountInfoDto> => await componentDispatch(loadAccountAsync(id)),
    updateAsync: async (accountDto: IAccountInfoDto): Promise<void> => await componentDispatch(updateAsync(accountDto)),
    addUasAsync: async (userRole: IUserRoleDto, uas: IUasDto) => await componentDispatch(addUasAsync(userRole, uas)),
    removeUasAsync: async (userRole: IUserRoleDto, uas: IUasDto) => await componentDispatch(removeUasAsync(userRole, uas)),
    addLecturerForAsync: async (userRole: IUserRoleDto, moduleExecution: IModuleExecutionDto) => await componentDispatch(addLecturerForAsync(userRole, moduleExecution)),
    removeLecturerForAsync: async (userRole: IUserRoleDto, moduleExecution: IModuleExecutionDto) => await componentDispatch(removeLecturerForAsync(userRole, moduleExecution)),
    activateRoleAsync: async (userRole: IUserRoleDto) => await componentDispatch(activateRoleAsync(userRole)),
    deactivateRoleAsync: async (userRole: IUserRoleDto) => await componentDispatch(deactivateRoleAsync(userRole)),
    selectAccount: (account: IAccountInfoDto) => componentDispatch(selectAccount(account)),
    loadPendingRequestsAsync: async (): Promise<IAccountRequestDto[]> => await componentDispatch(loadPendingRequestsAsync()),
    loadAccountsAsync: async (): Promise<IAccountDto[]> => await componentDispatch(loadAccountsAsync()),
    loadRejectedRequestsAsync: async (): Promise<IAccountRequestDto[]> => await componentDispatch(loadRejectedRequestsAsync()),
    activateAsync: async (activationId: number, roleTypes: RoleType[]) => await componentDispatch(activateAsync(activationId, roleTypes)),
    blockRequestAsync: async (activationId: number) => await componentDispatch(blockRequestAsync(activationId)),
    unblockRequestAsync: async (activationId: number) => await componentDispatch(unblockRequestAsync(activationId)),
    deleteRequestAsync: async (activationId: number) => await componentDispatch(deleteRequestAsync(activationId)),
    deleteAsync: async (account: IAccountInfoDto) => await componentDispatch(deleteAsync(account)),
    registerAsync,
  });
};

export default initActions;

export type UserManagementActions = ReturnType<typeof initActions>;
