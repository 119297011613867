import DeleteIcon from '@mui/icons-material/DeleteForever';
import SaveIcon from '@mui/icons-material/Save';
import { Button, Grid } from '@mui/material';
import { IAcademicYearDto, ISemesterDto } from 'app/clients/services';
import { ConfirmationDialog } from 'app/shared/layout/ui-elements/confirmation-dialog';
import { Field, Form, Formik } from 'formik';
import { TextField as FormikTextField } from 'formik-mui';
import React, { useState } from 'react';

type AcademicYearFormProps = {
  academicYear: IAcademicYearDto;
  academicYears: IAcademicYearDto[];
  loading: boolean;
  semesters: ISemesterDto[];
  selectAcademicYear: (year: IAcademicYearDto) => Promise<void> | void;
  deleteAcademicYearAsync: (year: IAcademicYearDto) => Promise<void> | void;
  onSubmit: (academicYear: IAcademicYearDto) => Promise<void>;
  onCancel?: () => void;
};

export const AcademicYearForm = (props: AcademicYearFormProps) => {
  const [deleteAcademicYearOpen, setDialogDeleteAcademicYearOpen] = useState<boolean>(false);
  return (
    <Formik
      enableReinitialize
      initialValues={props.academicYear}
      validate={(values) => {
        const errors: { name?: string; startYear?: string } = {};
        if (!values.name || values.name === 'untitled') {
          errors.name = 'Required';
        }
        if (props.academicYears.filter((p) => p.name === values.name && p.id !== values.id).length > 0) {
          errors.name = 'Name already exists!';
        }
        if (!values.startYear) {
          errors.startYear = 'Required';
        } else if (isNaN(values.startYear)) {
          errors.startYear = 'The start year needs to be numeric!';
        } else {
          values.startYear = Number(values.startYear);
          if (values.startYear && values.startYear < 2000) {
            errors.startYear = 'Please enter the start year in 4 digits!';
          }
        }

        if (props.academicYears.filter((p) => p.startYear === values.startYear && p.id !== values.id).length > 0) {
          errors.startYear = 'Start year already exists!';
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        props.onSubmit(values).then(() => setSubmitting(false));
      }}
    >
      {({ submitForm, isSubmitting }) => (
        <Form>
          <Grid container direction="row" spacing={5}>
            <Grid item xs={12} md={3}>
              <Field component={FormikTextField} name="name" type="text" label="Name" variant="standard" />
            </Grid>
            <Grid item xs={12} md={3}>
              <Field component={FormikTextField} name="startYear" type="text" label="Start Year" variant="standard" />
            </Grid>
            <Grid item xs={12} md={3}>
              <Button
                variant="contained"
                color="primary"
                disabled={isSubmitting || props.loading}
                onClick={submitForm}
                startIcon={<SaveIcon />}
              >
                {props.academicYear.id ? <>Save</> : <>Add</>}
              </Button>

              {props.semesters && props.semesters.length === 0 && (
                <>
                  {props.academicYear.id ? (
                    <Button
                      variant="contained"
                      disabled={isSubmitting || props.loading}
                      onClick={() => setDialogDeleteAcademicYearOpen(true)}
                      startIcon={<DeleteIcon />}
                    >
                      Delete
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      disabled={isSubmitting || props.loading}
                      onClick={() => {
                        props.onCancel && props.onCancel();
                        props.selectAcademicYear(null);
                      }}
                      startIcon={<DeleteIcon />}
                    >
                      Cancel
                    </Button>
                  )}
                  <ConfirmationDialog
                    title="Delete Academic Year"
                    onConfirm={() => {
                      props.deleteAcademicYearAsync(props.academicYear);
                    }}
                    open={deleteAcademicYearOpen}
                    onClose={() => setDialogDeleteAcademicYearOpen(false)}
                  >
                    Do you really want to delete the academic year <strong>{props.academicYear.name}</strong>?
                  </ConfirmationDialog>
                </>
              )}
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
