import { IRootState } from 'app/config/root.reducer';
import { ITabModel, NavigationTabs } from 'app/shared/layout/ui-elements/navigation-tabs';
import React, { useEffect } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { rememberOpenTab } from '../application/actions';
import userManagementModule from './user-management';

export type Props = PropsFromRedux & { baseUrl: string };

const UserManagementTabs = (props: Props) => {
  useEffect(() => {
    props.loadPendingRequestsAsync();
    props.loadRejectedRequestsAsync();
  }, []);

  const tabs = [
    { label: 'User Accounts', url: 'accounts' } as ITabModel,
    {
      label: 'Account Requests',
      url: 'account-requests',
      isDefault: props.nrRequests > 0,
      useBatch: true,
      batchCount: props.nrRequests,
    },
  ];

  return (
    <NavigationTabs
      tabs={tabs}
      baseUrl={props.baseUrl}
      openTab={props.openTabs[props.baseUrl]}
      onChange={(tab: ITabModel | undefined) => props.rememberOpenTab(props.baseUrl, tab)}
    />
  );
};

function mapStateToProps({ userManagement, application }: IRootState) {
  return {
    nrRequests: userManagement.pendingRequests?.length ?? 0,
    openTabs: application.openTabs,
  };
}

const mapDispatchToProps = (dispatch) => {
  const actions = userManagementModule.initActions(dispatch);
  return {
    loadPendingRequestsAsync: actions.loadPendingRequestsAsync,
    loadRejectedRequestsAsync: actions.loadRejectedRequestsAsync,
    rememberOpenTab: (baseUrl: string, tab: ITabModel | undefined) => dispatch(rememberOpenTab(baseUrl, tab)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(UserManagementTabs);
