import {
  AdvisorDto,
  IAccountClient,
  IAdvisorClient,
  IAdvisorDto,
  IUasClient,
  IUasDomainClient,
  IUasDomainDto,
  IUasDto,
  RoleType,
  UasDomainDto,
  UasDto,
} from 'app/clients/services';
import { addExceptionNotification, addSuccess } from 'app/modules/notification';
import { AdvisorSelectionActions } from 'app/modules/selections/advisor-selection/actions';
import { hasAnyRole } from 'app/shared/auth/roles';
import { AsyncThunk, Thunk } from 'app/shared/util/action-type.util';
import ACTION_TYPES from './types';

const initActions = (
  componentDispatch: any,
  uasClient: IUasClient,
  domainClient: IUasDomainClient,
  advisorClient: IAdvisorClient,
  accountClient: IAccountClient,
  advisorSelectionActions: AdvisorSelectionActions,
  refreshUas: () => Promise<IUasDto[]>,
) => {
  // Actions

  const setLoading = (loading: boolean) => ({
    type: ACTION_TYPES.SET_LOADING,
    payload: loading,
  });

  const setDomainsLoading = (loading: boolean) => ({
    type: ACTION_TYPES.SET_DOMAINS_LOADING,
    payload: loading,
  });

  const setDomains = (domains: IUasDomainDto[]) => ({
    type: ACTION_TYPES.SET_DOMAINS,
    payload: domains,
  });

  const setDomainsForUas = (uas: IUasDto, domains: IUasDomainDto[]) => ({
    type: ACTION_TYPES.SET_DOMAINS_FOR_UAS,
    payload: {
      uas,
      domains,
    },
  });

  const setAdvisorsWithoutUas = (advisors: IAdvisorDto[]) => ({
    type: ACTION_TYPES.SET_ADVISORS_WITHOUT_UAS,
    payload: advisors,
  });

  const selectUas = (uas: IUasDto | null) => ({
    type: ACTION_TYPES.SET_SELECTED_UAS,
    payload: uas,
  });

  const selectDomain = (domain: IUasDomainDto | null) => ({
    type: ACTION_TYPES.SET_SELECTED_DOMAIN,
    payload: domain,
  });

  const selectAdvisor = (advisor: IAdvisorDto | null) => ({
    type: ACTION_TYPES.SET_SELECTED_ADVISOR,
    payload: advisor,
  });

  const isAdmin: () => Thunk<boolean> = () => (dispatch, getState) => {
    const roles = getState().auth.account?.roles ?? [];
    return hasAnyRole(roles, [RoleType.REGISTRATIONADMIN, RoleType.STUDENTADMIN]);
  };

  const loadAllUasAsync: () => AsyncThunk<IUasDto[]> = () => async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const result = await refreshUas();
      dispatch(setLoading(false));
      return result;
    } catch (e) {
      dispatch(setLoading(false));
      dispatch(addExceptionNotification(e, `An error occured while loading uas!`));
      return [];
    }
  };

  const refreshDomainsAsync: () => AsyncThunk<IUasDomainDto[]> = () => async (dispatch) => {
    const domains = (await domainClient.list()) ?? [];
    const sortedDomains = domains.sort((d1, d2) => ((d1.name ?? '') > (d2.name ?? '') ? 1 : -1));
    dispatch(setDomains(sortedDomains));
    return sortedDomains;
  };

  const loadAdvisorsWithNoUasAsync: () => AsyncThunk<IAdvisorDto[]> = () => async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const advisors = (await advisorClient.getAdvisorsWithNoUasRelation()) ?? [];
      dispatch(setAdvisorsWithoutUas(advisors));
      dispatch(setLoading(false));
      return advisors;
    } catch (e) {
      dispatch(addExceptionNotification(e, `An error occured while loading advisors.`));
      dispatch(setLoading(false));
      return [];
    }
  };

  const refreshAdvisorsAsync: (uas?: IUasDto, changedAdvisor?: IAdvisorDto) => AsyncThunk<IAdvisorDto[]> =
    (uas, changedAdvisor) => async (dispatch) => {
      if (changedAdvisor && changedAdvisor.uas) {
        for (const advisorUas of changedAdvisor.uas.filter((u) => u.id !== uas?.id)) {
          await advisorSelectionActions.loadAdvisorsAsync(advisorUas.id);
        }
      }

      if (uas) {
        const result = await advisorSelectionActions.loadAdvisorsAsync(uas.id);
        const updatedAdvisor = result.find((a) => a.id === changedAdvisor?.id);
        if ((!updatedAdvisor || !changedAdvisor?.uas?.length) && dispatch(isAdmin())) {
          const advisors = (await advisorClient.getAdvisorsWithNoUasRelation()) ?? [];
          dispatch(setAdvisorsWithoutUas(advisors));
        }

        return result;
      }

      if (dispatch(isAdmin())) {
        const advisors = (await advisorClient.getAdvisorsWithNoUasRelation()) ?? [];
        dispatch(setAdvisorsWithoutUas(advisors));
      }

      return [];
    };

  const loadAdvisorsAsync: (uas?: IUasDto) => AsyncThunk<IAdvisorDto[]> = (uas) => async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const result = await dispatch(refreshAdvisorsAsync(uas));
      dispatch(setLoading(false));
      return result;
    } catch (e) {
      dispatch(addExceptionNotification(e, `An error occured while loading advisors.`));
      dispatch(setLoading(false));
      return [];
    }
  };

  const loadAllDomainsAsync: () => AsyncThunk<IUasDomainDto[]> = () => async (dispatch) => {
    dispatch(setDomainsLoading(true));
    try {
      const result = await dispatch(refreshDomainsAsync());
      dispatch(setDomainsLoading(false));
      return result;
    } catch (e) {
      dispatch(setDomainsLoading(false));
      dispatch(addExceptionNotification(e, `An error occured while loading domains!`));
      return [];
    }
  };

  const loadDomainsForUasAsync: (uas: IUasDto) => AsyncThunk<IUasDomainDto[]> = (uas) => async (dispatch) => {
    if (!uas.id) {
      return [];
    }
    dispatch(setDomainsLoading(true));
    try {
      const result = (await domainClient.getForUas(uas.id)) ?? [];
      dispatch(setDomainsForUas(uas, result));
      dispatch(setDomainsLoading(false));
      return result;
    } catch (e) {
      dispatch(setDomainsLoading(false));
      dispatch(addExceptionNotification(e, `An error occured while loading domains!`));
      return [];
    }
  };

  const updateUasAsync: (uas: IUasDto) => AsyncThunk<IUasDto> = (uas) => async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await uasClient.update(new UasDto(uas));
      const result = await refreshUas();
      const updatedUas = result.find((u) => u.id === uas.id);
      dispatch(selectUas(updatedUas));

      dispatch(addSuccess(`Successfully updated uas ${uas.code}.`));
      dispatch(setLoading(false));
      return updatedUas;
    } catch (e) {
      dispatch(setLoading(false));
      dispatch(addExceptionNotification(e, `An error occured while updating uas ${uas.code}`));
      return uas;
    }
  };

  const addUasAsync: (uas: IUasDto) => AsyncThunk<IUasDto> = (uas) => async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const id = await uasClient.add(new UasDto(uas));
      const result = await refreshUas();
      const updatedUas = result.find((u) => u.id === id);
      dispatch(selectUas(updatedUas));
      dispatch(addSuccess(`Successfully added uas ${uas.code}.`));
      dispatch(setLoading(false));
      return updatedUas;
    } catch (e) {
      dispatch(setLoading(false));
      dispatch(addExceptionNotification(e, `An error occured while adding uas ${uas.code}`));
      return uas;
    }
  };

  const deleteUasAsync: (uas: IUasDto) => AsyncThunk = (uas) => async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await uasClient.delete(uas.id);
      await refreshUas();
      dispatch(selectUas(null));
      dispatch(addSuccess(`Successfully deleted uas ${uas.code}.`));
      dispatch(setLoading(false));
    } catch (e) {
      dispatch(setLoading(false));
      dispatch(addExceptionNotification(e, `An error occured while deleting uas ${uas.code}`));
    }
  };

  const saveUasAsync: (uas: IUasDto) => AsyncThunk<IUasDto> = (uas) => async (dispatch) => {
    if (uas.id) {
      return await dispatch(updateUasAsync(uas));
    }
    return await dispatch(addUasAsync(uas));
  };

  const addDomainAsync: (uas: IUasDto, domain: IUasDomainDto) => AsyncThunk = (uas, domain) => async (dispatch) => {
    dispatch(setDomainsLoading(true));
    try {
      await domainClient.addToUas(uas.id, domain.id);
      const result = (await domainClient.getForUas(uas.id)) ?? [];
      dispatch(setDomainsForUas(uas, result));
      await dispatch(refreshDomainsAsync());
      dispatch(addSuccess(`Successfully added domain ${domain.name} to uas ${uas.code}.`));
      dispatch(setDomainsLoading(false));
    } catch (e) {
      dispatch(setDomainsLoading(false));
      dispatch(addExceptionNotification(e, `An error occured while adding ${domain.name} to uas ${uas.code}.`));
    }
  };

  const removeDomainAsync: (uas: IUasDto, domain: IUasDomainDto) => AsyncThunk = (uas, domain) => async (dispatch) => {
    dispatch(setDomainsLoading(true));
    try {
      await domainClient.removeFromUas(uas.id, domain.id);
      const result = (await domainClient.getForUas(uas.id)) ?? [];
      dispatch(setDomainsForUas(uas, result));
      await dispatch(refreshDomainsAsync());
      dispatch(addSuccess(`Successfully removed domain ${domain.name} from uas ${uas.code}.`));
      dispatch(setDomainsLoading(false));
    } catch (e) {
      dispatch(setDomainsLoading(false));
      dispatch(
        addExceptionNotification(e, `An error occured while removing domain ${domain.name} to uas ${uas.code}.`),
      );
    }
  };

  const addNewDomainAsync: (domain: IUasDomainDto) => AsyncThunk<IUasDomainDto> = (domain) => async (dispatch) => {
    dispatch(setDomainsLoading(true));
    try {
      const id = await domainClient.addDomain(new UasDomainDto(domain));
      const result = await dispatch(refreshDomainsAsync());
      const addedDomain = result.find((d) => d.id === id);
      dispatch(selectDomain(addedDomain));
      dispatch(addSuccess(`Successfully added domain ${addedDomain.name}`));
      dispatch(setDomainsLoading(false));
      return addedDomain;
    } catch (e) {
      dispatch(setDomainsLoading(false));
      dispatch(addExceptionNotification(e, `An error occured while adding domain ${domain.name}`));
      return domain;
    }
  };

  const updateDomainAsync: (domain: IUasDomainDto) => AsyncThunk<IUasDomainDto> = (domain) => async (dispatch) => {
    dispatch(setDomainsLoading(true));
    try {
      await domainClient.updateDomain(new UasDomainDto(domain));
      const result = await dispatch(refreshDomainsAsync());
      const updatedDomain = result.find((d) => d.id === domain.id);
      dispatch(addSuccess(`Successfully updated domain ${updatedDomain.name}`));
      dispatch(setDomainsLoading(false));
      return updatedDomain;
    } catch (e) {
      dispatch(setDomainsLoading(false));
      dispatch(addExceptionNotification(e, `An error occured while adding domain ${domain.name}`));
      return domain;
    }
  };

  const deleteDomainAsync: (domain: IUasDomainDto) => AsyncThunk = (domain) => async (dispatch) => {
    dispatch(setDomainsLoading(true));
    try {
      await domainClient.deleteDomain(domain.id);
      await dispatch(refreshDomainsAsync());
      dispatch(addSuccess(`Successfully deleted domain ${domain.name}`));
      dispatch(setDomainsLoading(false));
    } catch (e) {
      dispatch(setDomainsLoading(false));
      dispatch(addExceptionNotification(e, `An error occured while deleting domain ${domain.name}`));
    }
  };

  const saveDomainAsync: (domain: IUasDomainDto) => AsyncThunk<IUasDomainDto> = (domain) => async (dispatch) => {
    if (domain.id) {
      return await dispatch(updateDomainAsync(domain));
    } else {
      return await dispatch(addNewDomainAsync(domain));
    }
  };

  const addAdvisorAsync: (uas: IUasDto, advisor: IAdvisorDto) => AsyncThunk<IAdvisorDto> =
    (uas, advisor) => async (dispatch) => {
      dispatch(setDomainsLoading(true));
      try {
        const id = await advisorClient.add(uas?.id ?? 0, new AdvisorDto(advisor));
        const advisors = await dispatch(refreshAdvisorsAsync(uas, advisor));
        const newAdvisor = advisors.find((a) => a.id === id);
        dispatch(selectAdvisor(newAdvisor));
        dispatch(
          addSuccess(`Successfully added new advisor '${advisor.firstName} ${advisor.familyName}' to uas ${uas.code}.`),
        );
        dispatch(setDomainsLoading(false));
        return newAdvisor;
      } catch (e) {
        dispatch(setDomainsLoading(false));
        dispatch(
          addExceptionNotification(
            e,
            `An error occured while adding new advisor '${advisor.firstName} ${advisor.familyName}' to uas ${uas.code}.`,
          ),
        );
        return advisor;
      }
    };

  const addUasRelationToAdvisorAsync: (uas: IUasDto, advisor: IAdvisorDto) => AsyncThunk<IAdvisorDto> =
    (uas, advisor) => async (dispatch) => {
      dispatch(setDomainsLoading(true));
      try {
        await advisorClient.addUasRelation(advisor.id, uas.id);
        const advisors = await dispatch(refreshAdvisorsAsync(uas, advisor));
        const newAdvisor = advisors.find((a) => a.id === advisor.id);
        dispatch(selectAdvisor(newAdvisor));
        dispatch(
          addSuccess(`Successfully added advisor '${advisor.firstName} ${advisor.familyName}' to uas ${uas.code}.`),
        );
        dispatch(setDomainsLoading(false));
        return newAdvisor;
      } catch (e) {
        dispatch(setDomainsLoading(false));
        dispatch(
          addExceptionNotification(
            e,
            `An error occured while adding advisor '${advisor.firstName} ${advisor.familyName}' to uas ${uas.code}.`,
          ),
        );
        return advisor;
      }
    };

  const removeUasRelationFromAdvisorAsync: (uas: IUasDto, advisor: IAdvisorDto) => AsyncThunk<IAdvisorDto> =
    (uas, advisor) => async (dispatch) => {
      dispatch(setDomainsLoading(true));
      try {
        await advisorClient.removeUasRelation(advisor.id, uas.id);
        const advisors = await dispatch(refreshAdvisorsAsync(uas, advisor));
        const newAdvisor = advisors.find((a) => a.id === advisor.id);
        dispatch(selectAdvisor(newAdvisor));
        dispatch(
          addSuccess(`Successfully removed advisor '${advisor.firstName} ${advisor.familyName}' from uas ${uas.code}.`),
        );
        dispatch(setDomainsLoading(false));
        return newAdvisor;
      } catch (e) {
        dispatch(setDomainsLoading(false));
        dispatch(
          addExceptionNotification(
            e,
            `An error occured while removing advisor '${advisor.firstName} ${advisor.familyName}' from uas ${uas.code}.`,
          ),
        );
        return advisor;
      }
    };

  const updateAdvisorAsync: (uas: IUasDto, advisor: IAdvisorDto) => AsyncThunk<IAdvisorDto> =
    (uas, advisor) => async (dispatch) => {
      dispatch(setLoading(true));
      try {
        await advisorClient.update(new AdvisorDto(advisor));
        const advisors = await dispatch(refreshAdvisorsAsync(uas, advisor));
        const updatedAdvisor = advisors.find((a) => a.id === advisor.id);
        dispatch(selectAdvisor(updatedAdvisor));
        dispatch(addSuccess(`Successfully updated advisor '${advisor.firstName} ${advisor.familyName}'.`));
        dispatch(setLoading(false));
        return updatedAdvisor;
      } catch (e) {
        dispatch(setLoading(false));
        dispatch(
          addExceptionNotification(
            e,
            `An error occured while updating advisor '${advisor.firstName} ${advisor.familyName}'.`,
          ),
        );
        return advisor;
      }
    };

  const saveAdvisorAsync: (uas: IUasDto, advisor: IAdvisorDto) => AsyncThunk<IAdvisorDto> =
    (uas, advisor) => async (dispatch) => {
      if (advisor.id) {
        return await dispatch(updateAdvisorAsync(uas, advisor));
      }
      return await dispatch(addAdvisorAsync(uas, advisor));
    };

  const deleteAdvisorAsync: (uas: IUasDto, advisor: IAdvisorDto) => AsyncThunk<IAdvisorDto> =
    (uas, advisor) => async (dispatch) => {
      dispatch(setLoading(true));
      try {
        await advisorClient.delete(advisor.id, uas?.id ?? null);
        const advisors = await dispatch(refreshAdvisorsAsync(uas, advisor));
        const updatedAdvisor = advisors.find((a) => a.id === advisor.id);
        dispatch(selectAdvisor(updatedAdvisor));
        dispatch(addSuccess(`Successfully deleted advisor '${advisor.firstName} ${advisor.familyName}'.`));
        dispatch(setLoading(false));
        return updatedAdvisor;
      } catch (e) {
        dispatch(setLoading(false));
        return advisor;
      }
    };

  const emailAlreadyExistsAsync = async (userId: string, email: string) => {
    return await accountClient.checkEmail(userId, email);
  };

  return Object.freeze({
    loadAllUasAsync: async (): Promise<IUasDto[]> => await componentDispatch(loadAllUasAsync()),
    loadDomainsForUasAsync: async (uas: IUasDto): Promise<IUasDomainDto[]> =>
      await componentDispatch(loadDomainsForUasAsync(uas)),

    loadAllDomainsAsync: async (): Promise<IUasDomainDto[]> => await componentDispatch(loadAllDomainsAsync()),
    saveUasAsync: async (uas: IUasDto): Promise<IUasDto> => await componentDispatch(saveUasAsync(uas)),
    deleteUasAsync: async (uas: IUasDto): Promise<void> => await componentDispatch(deleteUasAsync(uas)),
    selectUas: (uas: IUasDto | null) => componentDispatch(selectUas(uas)),

    deleteDomainAsync: async (domain: IUasDomainDto): Promise<void> =>
      await componentDispatch(deleteDomainAsync(domain)),
    saveDomainAsync: async (domain: IUasDomainDto): Promise<IUasDomainDto> =>
      await componentDispatch(saveDomainAsync(domain)),
    addDomainAsync: async (uas: IUasDto, domain: IUasDomainDto): Promise<void> =>
      await componentDispatch(addDomainAsync(uas, domain)),
    removeDomainAsync: async (uas: IUasDto, domain: IUasDomainDto): Promise<void> =>
      await componentDispatch(removeDomainAsync(uas, domain)),
    selectDomain: (domain: IUasDomainDto | null) => componentDispatch(selectDomain(domain)),

    selectAdvisor: (advisor: IAdvisorDto | null) => componentDispatch(selectAdvisor(advisor)),
    saveAdvisorAsync: async (uas: IUasDto, advisor: IAdvisorDto): Promise<IAdvisorDto> =>
      await componentDispatch(saveAdvisorAsync(uas, advisor)),
    deleteAdvisorAsync: async (uas: IUasDto, advisor: IAdvisorDto): Promise<IAdvisorDto> =>
      await componentDispatch(deleteAdvisorAsync(uas, advisor)),
    loadAdvisorsWithNoUasAsync: async (): Promise<IAdvisorDto[]> =>
      await componentDispatch(loadAdvisorsWithNoUasAsync()),

    addUasRelationToAdvisorAsync: async (uas: IUasDto, advisor: IAdvisorDto): Promise<IAdvisorDto> =>
      await componentDispatch(addUasRelationToAdvisorAsync(uas, advisor)),
    removeUasRelationFromAdvisorAsync: async (uas: IUasDto, advisor: IAdvisorDto): Promise<IAdvisorDto> =>
      await componentDispatch(removeUasRelationFromAdvisorAsync(uas, advisor)),

    emailAlreadyExistsAsync,
    loadAdvisorsAsync: async (uas?: IUasDto): Promise<IAdvisorDto[]> => await componentDispatch(loadAdvisorsAsync(uas)),
  });
};

export default initActions;

export type UserManagementActions = ReturnType<typeof initActions>;
