import { FileResponse, ISummaryFilterDto, ISummarySheetClient, SummaryFilterDto } from 'app/clients/services';
import { addExceptionNotification } from 'app/modules/notification/actions';
import { AsyncThunk } from 'app/shared/util/action-type.util';
import { saveAs } from 'file-saver';
import ACTION_TYPES from './types';

export const initActions = (componentDispatch, summarySheetClient: ISummarySheetClient) => {
  const setLoading = (loading: boolean) => ({
    type: ACTION_TYPES.SET_LOADING,
    payload: loading,
  });

  const setSummaryFilter = (summaryFilter: ISummaryFilterDto) => ({
    type: ACTION_TYPES.SET_FILTER,
    payload: summaryFilter,
  });

  // Thunks
  const loadReportAsync: (summaryFilter: ISummaryFilterDto) => AsyncThunk<FileResponse> =
    (summaryFilter) => async (dispatch) => {
      dispatch(setLoading(true));
      try {
        const result = await summarySheetClient.getSummarySheet(new SummaryFilterDto(summaryFilter));
        saveAs(result.data, result.fileName);
        dispatch(setLoading(false));
        return result;
      } catch (e) {
        dispatch(addExceptionNotification(e));
        dispatch(setLoading(false));
      }

      return null;
    };

  return Object.freeze({
    setSummaryFilter: async (summaryFilter: ISummaryFilterDto): Promise<void> =>
      await componentDispatch(setSummaryFilter(summaryFilter)),
    loadReportAsync: async (summaryFilter: ISummaryFilterDto): Promise<void> =>
      await componentDispatch(loadReportAsync(summaryFilter)),
  });
};

export default initActions;

export type ModuleRegistrationExportActions = ReturnType<typeof initActions>;
