import SearchIcon from '@mui/icons-material/Search';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputBase,
  LinearProgress,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import {
  ICommentDto,
  IExcelColumn,
  IFileImportDto,
  IRegistrationImport,
  IRegistrationSheet,
  IStudentImport,
  IValidationCountDto,
  IValidationMessage,
  RegistrationImport,
  ValidationLevel,
} from 'app/clients/services';
import { createResultMessage, isHigherOrEqual } from 'app/shared/util/validation-level.util';
import React, { useEffect, useState } from 'react';
import { ChangeFilter, MessageFilter } from '../message-filter';
import Messages from '../messages';
import ValidationMessage from '../validation-message';
import { AdvisorImportPreview } from './advisor-import-preview';
import { FileImportInfo } from './file-import-info';
import { StudentImportPreview } from './student-import-preview';

type ImportPreviewProps = {
  uasImport: IRegistrationImport;
  fileImport: IFileImportDto;
  defaultLevelFilter?: ValidationLevel;
  defaultChangeFilter?: ChangeFilter[];
  onUpdate?: (registrationImport: IRegistrationImport) => void | Promise<void>;
  disabled?: boolean;
  isLoading: boolean;
  showConclusionAtStart?: boolean;
  validation?: {
    detailedMessages?: IValidationMessage[];
    columnInformation?: IExcelColumn[];
    resultedSheet?: IRegistrationSheet;
    count?: IValidationCountDto;
  };
  refreshRegistrationInfo: (moduleRegistrationId?: number) => Promise<void>;
  onRemoveComment: (comment: ICommentDto) => Promise<void>;
  onAddComment: (comment: ICommentDto) => Promise<void>;
};

const showPerson = (
  person: {
    validationLevel: ValidationLevel;
    nrOfUserConfirmations: number;
    nrOfChanges: number;
    nrOfUnconfirmedConfirmations: number;
  },
  isStudent: boolean,
  levelFilter: ValidationLevel,
  changeFilter: ChangeFilter[],
) => {
  if (levelFilter === ValidationLevel.Success) {
    return true;
  }

  if (isHigherOrEqual(levelFilter, ValidationLevel.Warning)) {
    return isHigherOrEqual(person.validationLevel, levelFilter);
  }

  if (isHigherOrEqual(person.validationLevel, ValidationLevel.Warning)) {
    return true;
  }

  if (changeFilter.filter((f) => f === 'confirmations').length > 0 && person.nrOfUserConfirmations > 0) {
    return true;
  }

  if (isStudent) {
    if (changeFilter.filter((f) => f === 'student-changes').length > 0 && person.nrOfChanges > 0) {
      return true;
    }
    return false;
  }

  if (changeFilter.filter((f) => f === 'advisor-changes').length > 0 && person.nrOfChanges > 0) {
    return true;
  }
  return false;
};

const showStudent = (student: IStudentImport, levelFilter: ValidationLevel, changeFilter: ChangeFilter[]) => {
  return (
    showPerson(student, true, levelFilter, changeFilter) ||
    isHigherOrEqual(student.registrationValidationLevel, levelFilter) ||
    (changeFilter.filter((f) => f === 'registration-changes').length > 0 && student.nrOfRegistrationChanges > 0)
  );
};

type Order = 'firstName' | 'familyName' | 'row';

export const ImportPreview = (props: ImportPreviewProps) => {
  const [order, setOrder] = useState<Order>('familyName');
  const [orderDesc, setOrderDesc] = useState<boolean>(false);
  const [levelFilter, setLevelFilter] = useState<ValidationLevel>(props.defaultLevelFilter ?? ValidationLevel.Warning);
  const [changeFilter, setChangeFilter] = useState<ChangeFilter[]>(props.defaultChangeFilter ?? ['unconfirmed']);
  const [showTableView, setShowTableView] = useState<boolean>(true);
  const [searchText, setSearchText] = useState<string>('');
  const [finalSearchText, setFinalSearchText] = useState<string[]>([]);

  const handleSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const startSearch = () => {
    if (searchText?.length > 0) {
      setFinalSearchText(searchText.split(' '));
      setLevelFilter(ValidationLevel.Success);
      setChangeFilter(['unconfirmed', 'confirmations', 'student-changes', 'advisor-changes', 'registration-changes']);
    } else {
      setFinalSearchText([]);
      setLevelFilter(ValidationLevel.Success);
      setChangeFilter(['unconfirmed', 'confirmations', 'student-changes', 'advisor-changes', 'registration-changes']);
    }
  };

  const getAdvisor = (s: IStudentImport) => {
    if (s.advisorGuid) {
      return props.uasImport.advisors.find((a) => a.guid === s.advisorGuid);
    }
    return null;
  };

  const filterStudents = (
    theFilter: ValidationLevel,
    theChangeFilter: ChangeFilter[],
    theOrder: Order,
    doOrderDesc: boolean,
    search: string[],
  ) => {
    let filtered = props.uasImport?.students.filter((s) => showStudent(s, theFilter, theChangeFilter)) ?? [];
    if (search?.length > 0) {
      filtered = filtered.filter(
        (student) =>
          search.filter((s) =>
            !isNaN(s as any)
              ? student.moduleRegistrations?.filter((m) => m.rowNumber === Number(s)).length > 0
              : student.firstName?.toLowerCase().includes(s.toLowerCase()) ||
                student.familyName?.toLowerCase().includes(s.toLowerCase()) ||
                student.privateEmail?.name?.toLowerCase().includes(s.toLowerCase()) ||
                student.uasEmail?.name?.toLowerCase().includes(s.toLowerCase()) ||
                student.matriculationNumber?.toLowerCase().includes(s.toLowerCase()) ||
                student.birthDate?.toString()?.toLowerCase().includes(s.toLowerCase()) ||
                student.profileMapping?.identifier?.toLowerCase().includes(s.toLowerCase()) ||
                student.previousDegree?.toLowerCase().includes(s.toLowerCase()) ||
                getAdvisor(student)?.firstName?.toLowerCase().includes(s.toLowerCase()) ||
                getAdvisor(student)?.familyName?.toLowerCase().includes(s.toLowerCase()) ||
                getAdvisor(student)?.uasEmail?.name?.toLowerCase().includes(s.toLowerCase()) ||
                student.moduleRegistrations?.filter((m) =>
                  m.moduleExecutionCode.toLowerCase().includes(s.toLowerCase()),
                ).length > 0,
          ).length === search.length,
      );
    }

    if (theOrder === 'firstName') {
      return filtered.sort((s1, s2) => (s1.firstName > s2.firstName ? (doOrderDesc ? -1 : 1) : doOrderDesc ? 1 : -1));
    }
    if (theOrder === 'familyName') {
      return filtered.sort((s1, s2) => (s1.familyName > s2.familyName ? (doOrderDesc ? -1 : 1) : doOrderDesc ? 1 : -1));
    }
    return filtered.sort((s1, s2) =>
      Math.min(...(s1.moduleRegistrations?.map((r) => r.rowNumber) ?? [0])) >
      Math.min(...(s2.moduleRegistrations?.map((r) => r.rowNumber) ?? [0]))
        ? doOrderDesc
          ? -1
          : 1
        : doOrderDesc
          ? 1
          : -1,
    );
  };

  const filterAdvisors = (
    theFilter: ValidationLevel,
    theChangeFilter: ChangeFilter[],
    theOrder: Order,
    doOrderDesc: boolean,
    search: string[],
  ) => {
    let filtered = props.uasImport?.advisors.filter((s) => showPerson(s, false, theFilter, theChangeFilter)) ?? [];
    if (search?.length > 0) {
      filtered = filtered.filter(
        (advisor) =>
          search.filter(
            (s) =>
              advisor.firstName?.toLowerCase().includes(s.toLowerCase()) ||
              advisor.familyName?.toLowerCase().includes(s.toLowerCase()) ||
              advisor.uasEmail?.name?.toLowerCase().includes(s.toLowerCase()),
          ).length === search.length,
      );
    }

    if (theOrder === 'firstName') {
      return filtered.sort((s1, s2) => (s1.firstName > s2.firstName ? (doOrderDesc ? 1 : -1) : doOrderDesc ? -1 : 1));
    }
    if (theOrder === 'familyName') {
      return filtered.sort((s1, s2) => (s1.familyName > s2.familyName ? (doOrderDesc ? 1 : -1) : doOrderDesc ? -1 : 1));
    }
    return filtered;
  };

  const [filteredStudents, setFilteredStudents] = useState(
    filterStudents(levelFilter, changeFilter, order, orderDesc, finalSearchText),
  );
  const [filteredAdvisors, setFilteredAdvisors] = useState(
    filterAdvisors(levelFilter, changeFilter, order, orderDesc, finalSearchText),
  );

  useEffect(() => {
    setFilteredStudents(filterStudents(levelFilter, changeFilter, order, orderDesc, finalSearchText));
    setFilteredAdvisors(filterAdvisors(levelFilter, changeFilter, order, orderDesc, finalSearchText));
  }, [props.uasImport, levelFilter, changeFilter, order, orderDesc, finalSearchText]);

  const noRights = props.validation?.detailedMessages?.filter((m) => m.noRights).length > 0;

  return (
    <>
      {props.isLoading ? (
        props.fileImport ? (
          <Paper style={{ padding: 10, marginTop: 20, marginBottom: 20 }}>
            <FileImportInfo fileImport={props.fileImport}></FileImportInfo>
            <LinearProgress />
          </Paper>
        ) : (
          <LinearProgress />
        )
      ) : (
        <Paper style={{ padding: 10, marginTop: 20, marginBottom: 20 }}>
          {props.fileImport && <FileImportInfo fileImport={props.fileImport}></FileImportInfo>}

          {props.validation && props.showConclusionAtStart && (
            <ValidationMessage
              message={createResultMessage(props.validation.count, noRights, props.fileImport?.importState)}
              openInfo
              columnInfo={props.validation.columnInformation}
              registrations={props.validation.resultedSheet}
            ></ValidationMessage>
          )}
          <div style={{ marginTop: 10, marginBottom: 10 }}>
            {props.validation && (props.validation.detailedMessages?.length > 0 || props.uasImport) && (
              <MessageFilter
                levelFilter={levelFilter}
                changeFilter={changeFilter}
                showTableView={showTableView}
                onViewChange={(newShowTableView) => setShowTableView(newShowTableView)}
                onFilterChange={(newLevelFilter, newChangeFilter) => {
                  setLevelFilter(newLevelFilter);
                  setChangeFilter(newChangeFilter);
                }}
                count={
                  noRights
                    ? {
                        errors: props.validation.count?.errors ?? 0,
                        warnings: props.validation.count?.warnings ?? 0,
                        infos: props.validation.count?.infos ?? 0,
                        studentChanges: 0,
                        advisorChanges: 0,
                        unconfirmedConfirmations: 0,
                        registrationChanges: 0,
                        userConfirmations: 0,
                      }
                    : props.validation.count
                }
                hasTableInfo={
                  props.validation.detailedMessages
                    ?.filter((m) => isHigherOrEqual(m.level, levelFilter))
                    ?.filter((m) => m.cells?.length > 0).length > 0
                }
              />
            )}
            {props.validation &&
              props.validation.detailedMessages &&
              props.validation.detailedMessages.filter((m) => isHigherOrEqual(m.level, levelFilter)).length > 0 && (
                <Messages
                  filter={levelFilter}
                  showTableView={showTableView}
                  messages={props.validation.detailedMessages?.filter((m) => isHigherOrEqual(m.level, levelFilter))}
                  columnInfo={props.validation.columnInformation}
                  registrations={props.validation.resultedSheet}
                  showEverything={levelFilter === ValidationLevel.Success}
                />
              )}
          </div>
          {props.uasImport && (
            <>
              <Paper style={{ margin: 5, padding: 5 }}>
                <Grid container>
                  <Grid item xs={4}>
                    <Paper style={{ padding: 5 }}>
                      <Typography style={{ margin: 5, marginTop: 20 }}>
                        <InputBase
                          onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                              startSearch();
                              event.preventDefault();
                            }
                          }}
                          style={{
                            paddingLeft: 5,
                            flex: 1,
                            width: '80%',
                          }}
                          placeholder="Search"
                          inputProps={{ 'aria-label': 'search' }}
                          onChange={handleSearchTextChange}
                          value={searchText}
                        />
                        <IconButton type="submit" style={{ padding: 10 }} aria-label="search" onClick={startSearch}>
                          <SearchIcon />
                        </IconButton>
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography style={{ margin: 5, paddingLeft: 20 }}>
                      <span>
                        <FormControl component="fieldset">
                          <FormLabel component="legend">Order By</FormLabel>
                          <RadioGroup
                            aria-label="orderby"
                            name="orderby"
                            value={order}
                            onChange={(_event, value) => setOrder(value as Order)}
                            row
                          >
                            <FormControlLabel value="row" control={<Radio />} label="Row Number" />
                            <FormControlLabel value="familyName" control={<Radio />} label="Family Name" />
                            <FormControlLabel value="firstName" control={<Radio />} label="First Name" />
                          </RadioGroup>
                        </FormControl>
                      </span>
                      <span>
                        <FormControlLabel
                          sx={{ marginTop: 3 }}
                          control={
                            <Checkbox
                              checked={orderDesc}
                              onChange={(_event, value) => setOrderDesc(value)}
                              name="orderdesc"
                              color="primary"
                            />
                          }
                          label="descending"
                        />
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </>
          )}
          {props.uasImport && filteredStudents?.length > 0 && (
            <div style={{ marginTop: 10 }}>
              <Typography variant="h5">Students</Typography>
              {filteredStudents.map((s, k) => (
                <StudentImportPreview
                  isLoading={props.isLoading}
                  key={k}
                  noRights={noRights}
                  student={s}
                  advisor={getAdvisor(s)}
                  levelFilter={levelFilter}
                  onConfirm={(newStudent) => {
                    const newUasImport = new RegistrationImport({
                      ...props.uasImport,
                      students: props.uasImport.students.map((st) => (st.guid === s.guid ? newStudent : st)),
                    });
                    props.onUpdate(newUasImport);
                  }}
                  disabled={props.disabled}
                  refreshRegistrationInfo={props.refreshRegistrationInfo}
                  onAddComment={props.onAddComment}
                  onRemoveComment={props.onRemoveComment}
                />
              ))}
            </div>
          )}
          {props.uasImport && filteredAdvisors?.length > 0 && (
            <div style={{ marginTop: 10 }}>
              <Typography variant="h5">Advisors</Typography>
              {filteredAdvisors.map((a, k) => (
                <AdvisorImportPreview
                  isLoading={props.isLoading}
                  noRights={noRights}
                  key={k}
                  advisor={a}
                  levelFilter={levelFilter}
                  onUpdate={(newAdvisor) => {
                    const newUasImport = new RegistrationImport({
                      ...props.uasImport,
                      advisors: props.uasImport.advisors.map((ad) => (ad.guid === a.guid ? newAdvisor : ad)),
                    } as IRegistrationImport);
                    props.onUpdate(newUasImport);
                  }}
                  disabled={props.disabled}
                />
              ))}
            </div>
          )}
          {props.validation && !props.showConclusionAtStart && (
            <ValidationMessage
              message={createResultMessage(props.validation.count, noRights, props.fileImport?.importState)}
              openInfo
              columnInfo={props.validation.columnInformation}
              registrations={props.validation.resultedSheet}
            ></ValidationMessage>
          )}
        </Paper>
      )}
    </>
  );
};
