// Types
export default {
  SET_LOADING: 'profiles/SET_LOADING',
  FETCH_PRIORITY_ASSIGNMENTS: 'profiles/FETCH_PRIORITY_ASSIGNMENTS',
  ASSIGN_PRIORITY: 'profiles/ASSIGN_PRIORITY',
  COPY_FROM_SEMESTER: 'profiles/COPY_FROM_SEMESTER',
  REMOVE_ALL_PRIORITIES: 'profiles/REMOVE_ALL_PRIORITIES',
  SELECT_PROFILE: 'profiles/SELECT_PROFILE',
  CREATE_PROFILE: 'profiles/CREATE_PROFILE',
  MEMORIZE_PROFILE_CHANGES: 'profiles/MEMORIZE_PROFILE_CHANGES',
  CHANGE_PRIORITYASSIGNMENT: 'profiles/CHANGE_PRIORITYASSIGNMENT',
};
