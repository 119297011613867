import ACTION_TYPES from './types';
import { ILabelClient, ILabelDto, IProfileClient, IProfileDto } from 'app/clients/services';
import { AsyncThunk } from 'app/shared/util/action-type.util';
import { addExceptionNotification } from 'app/modules/notification';

const initLabelActions = (labelClient: ILabelClient) => {
  // Thunks
  const fetchLabelsAsync: () => AsyncThunk<ILabelDto[]> = () => async dispatch => {
    try {
      const result = await labelClient.list();
      dispatch({
        type: ACTION_TYPES.SET_LABELS,
        payload: result,
      });
      return result;
    } catch (e) {
      dispatch(addExceptionNotification(e, 'An error occured while trying to fetch labels.'));
      return [];
    }
  };

  return Object.freeze({
    fetchLabelsAsync,
  });
};

const initProfileActions = (profileClient: IProfileClient) => {
  // Thunks
  const fetchProfilesAsync: () => AsyncThunk<IProfileDto[]> = () => async dispatch => {
    try {
      const result = await profileClient.list();
      dispatch({
        type: ACTION_TYPES.SET_PROFILES,
        payload: result,
      });
      return result;
    } catch (e) {
      dispatch(addExceptionNotification(e, 'An error occured while trying to fetch profiles.'));
      return [];
    }
  };

  return Object.freeze({
    fetchProfilesAsync,
  });
};

export default { initLabelActions, initProfileActions };

export type LabelSelectionActions = ReturnType<typeof initLabelActions>;
export type ProfileSelectionActions = ReturnType<typeof initProfileActions>;
