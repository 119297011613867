import React, { useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Alert, AlertTitle, Grid, Paper, Typography } from '@mui/material';
import { IRootState } from 'app/config/root.reducer';
import { hasAnyRole } from 'app/shared/auth/roles';
import { CommentDialog } from 'app/shared/layout/parts/comment-dialog';
import { ModuleRegistrations } from 'app/shared/layout/parts/module-registrations';
import { SemesterSelection } from 'app/shared/layout/semester-selection';
import { DefaultButton } from 'app/shared/layout/ui-elements/buttons';
import { MainContent, SideBar, SplitLayout } from 'app/shared/layout/ui-elements/component-layout';
import { MultiselectDataGrid } from 'app/shared/layout/ui-elements/multiselect-data-grid';
import { NavigationList } from 'app/shared/layout/ui-elements/navigation-list';
import { SelectBox } from 'app/shared/layout/ui-elements/select-box';
import { combineUrlParams, getBasePath } from 'app/shared/util/url-utils';
import { CloseReason } from 'notistack';
import {
  IAcademicYearDto,
  IModuleExecutionDto,
  IModuleRegistrationInfoDto,
  IPhaseDto,
  ISemesterInfoDto,
  ModuleCategoryDto,
  ModuleExecutionDto,
  RegistrationState,
  RoleType,
} from '../../clients/services';
import { addExceptionNotification } from '../notification';
import prioritizationModule from './prioritization/index';

export type Props = PropsFromRedux;

const Modules = (props: Props) => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const basePath = getBasePath(useLocation(), useParams());
  const [registrationCommentOpen, setRegistrationCommentOpen] = useState<IModuleRegistrationInfoDto>(null);

  useEffect(() => {
    const loadInitialData = async () => {
      try {
        props.setMainContentLoading(true);
        if (id) {
          await props.setModuleExecutionIdAsync(Number(id));
        } else {
          await props.setFiltersAsync(props.selectedCategory, props.selectedExecution, props.selectedSemester);
        }

        props.setMainContentLoading(false);
      } catch (err) {
        props.setMainContentLoading(false);
        props.addExceptionNotification(err);
      }
    };

    loadInitialData();
  }, []);

  const handleSemesterSelectionChange = async (
    _academicYear: IAcademicYearDto,
    semester: ISemesterInfoDto,
    _phase: IPhaseDto,
  ) => {
    props.setMainContentLoading(true);
    if (semester) {
      if (props.selectedSemester?.id !== semester?.id) {
        navigate(`/module-registrations/modules`);
        await props.selectSemesterAsync(semester);
      }
    }

    props.setMainContentLoading(false);
  };

  const handleModuleCategoryChange = async (moduleCategory: ModuleCategoryDto) => {
    navigate(`/module-registrations/modules`);
    await props.selectCategoryAsync(moduleCategory);
  };

  const handleModuleExecutionChange = async (moduleExecution?: IModuleExecutionDto) => {
    if (moduleExecution && moduleExecution.id !== props.selectedExecution?.id) {
      await props.selectModuleExecutionAsync(moduleExecution);
    }
  };

  const handleRequestedSelectionChanged = (selectionModel) => {
    props.setRequestedSelected(selectionModel);
  };

  const handleConfirmedSelectionChanged = (selectionModel) => {
    props.setConfirmedSelected(selectionModel);
  };

  const handleRejectedSelectionChanged = (selectionModel) => {
    props.setRejectedSelected(selectionModel);
  };

  const handleWithdrawnSelectionChanged = (selectionModel) => {
    props.setWithdrawnSelected(selectionModel);
  };

  const handleOthersSelectionChanged = (selectionModel) => {
    props.setWithdrawnSelected(selectionModel);
  };

  const handleToRequestedConfirmed = async () => {
    await props.changeRegistrationStatesAsync(RegistrationState.REQUESTED, null, props.selectedConfirmed);
  };

  const handleRejectConfirmed = async () => {
    await props.changeRegistrationStatesAsync(RegistrationState.REJECTED, null, props.selectedConfirmed);
  };

  const handleConfirmRequested = async () => {
    await props.changeRegistrationStatesAsync(RegistrationState.CONFIRMED, null, props.selectedRequested);
  };

  const handleRejectRequested = async () => {
    await props.changeRegistrationStatesAsync(RegistrationState.REJECTED, null, props.selectedRequested);
  };

  const handleToRequestedRejected = async () => {
    await props.changeRegistrationStatesAsync(RegistrationState.REQUESTED, null, props.selectedRejected);
  };

  const handleToRequestedWithdrawn = async () => {
    await props.changeRegistrationStatesAsync(RegistrationState.REQUESTED, null, props.selectedWithdrawn);
  };

  const handleToRequestedOthers = async () => {
    await props.changeRegistrationStatesAsync(RegistrationState.REQUESTED, null, props.selectedOthers);
  };

  return (
    <SplitLayout>
      <SideBar>
        <SemesterSelection
          filter={props.semesterFilter}
          semester={props.selectedSemester}
          academicYear={props.selectedAcademicYear}
          hidePhase
          onChange={handleSemesterSelectionChange}
        ></SemesterSelection>

        <SelectBox
          items={props.moduleCategories}
          selectedItem={props.selectedCategory}
          onChange={handleModuleCategoryChange}
          title="Category"
          getItemKey={(category: ModuleCategoryDto) => category.id}
          getItemTitle={(category: ModuleCategoryDto) => category.code}
        />
        {props.moduleExecutions && props.moduleExecutions.length > 0 && (
          <NavigationList
            createUrl={(e: ModuleExecutionDto) => basePath + combineUrlParams({ id: e.id })}
            title="Module Execution"
            items={props.moduleExecutions}
            selectedItem={props.selectedExecution}
            onClick={handleModuleExecutionChange}
            getItemKey={(e: ModuleExecutionDto) => e.id}
            getItemTitle={(e: ModuleExecutionDto) => e.code}
            badges={[
              {
                getContent(e: ModuleExecutionDto) {
                  const capacity = props.capacities?.find((c) => c.moduleExecutionId === e.id);
                  return (capacity?.requestedCount ?? 0) - (capacity?.waitingCount ?? 0);
                },
                color: 'secondary',
              },
              {
                getContent: (e: ModuleExecutionDto) =>
                  props.capacities?.find((c) => c.moduleExecutionId === e.id)?.waitingCount ?? 0,
                color: 'primary',
              },
            ]}
          />
        )}
        <DefaultButton
          primary
          placement="side-bar"
          onClick={() => props.autoConfirmAsync(props.selectedSemester.id, props.selectedCategory.id)}
          disabled={props.loading || !props.currentPhaseIsOpen}
        >
          Auto Confirm
        </DefaultButton>
      </SideBar>
      <MainContent loading={props.mainContentLoading}>
        {props.selectedExecution && props.moduleRegistrationInfo && (
          <>
            <Typography variant="h1">
              Prioritization - {props.currentPhase?.name}: {props.selectedExecution.code}
            </Typography>
            <Grid container spacing={1} component={Paper} sx={{ padding: 1, margin: 0.5 }}>
              <Grid item xs={4}>
                <strong>Module Capacity</strong>: {props.moduleRegistrationInfo.fullCapacity}
                <br />
                Capacity in Current Phase:
                {' ' + props.moduleRegistrationInfo.currentCapacity}
                <br />
                Reserve in Current Phase: {props.moduleRegistrationInfo.reservePercentage}% (
                {props.moduleRegistrationInfo.fullCapacity - props.moduleRegistrationInfo.currentCapacity})
                <br />
              </Grid>
              <Grid item xs={4}>
                Confirmed: {props.confirmed.length}
                <br />
                Requested: {props.requested.length}
                <br />
                Rejected: {props.rejected.length}
                <br />
                Withdrawn: {props.withdrawn.length}
                <br />
                Absentex: {props.absentex.length}
                <br />
                {props.others.length > 0 && <>Others: {props.others.length}</>}
              </Grid>
              <Grid item xs={4}>
                Current Phase:{' '}
                <strong>
                  {props.selectedSemester?.phases?.find((p) => p.id === props.selectedSemester?.currentPhaseId)?.name} -{' '}
                  {props.selectedSemester?.name}
                </strong>
              </Grid>
              {!props.currentPhaseIsOpen && (
                <Grid item xs={12}>
                  <Alert severity="error">
                    <AlertTitle>Prioritization blocked!</AlertTitle>
                    Because the current phase is not open, the prioritization is blocked. Please open at least one of
                    the phases in the selected semester.
                  </Alert>
                </Grid>
              )}
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} style={{ borderTop: '1px solid', marginTop: 20, paddingTop: 10 }}>
                <strong>Confirmed: </strong>
                {props.moduleRegistrationInfo.confirmed?.length ?? 0}
                <br />
                <DefaultButton
                  primary
                  onClick={handleToRequestedConfirmed}
                  disabled={props.selectedConfirmed?.length === 0 || props.loading || !props.currentPhaseIsOpen}
                >
                  Back To Requested Queue ({props.selectedConfirmed.length})
                </DefaultButton>
                <DefaultButton
                  onClick={handleRejectConfirmed}
                  disabled={props.selectedConfirmed?.length === 0 || props.loading || !props.currentPhaseIsOpen}
                >
                  Reject ({props.selectedConfirmed?.length})
                </DefaultButton>
              </Grid>
              <Grid item xs={12}>
                <ModuleRegistrations
                  isAdmin={props.isAdmin}
                  rows={props.moduleRegistrationInfo?.confirmed}
                  showInfos
                  loading={props.loading}
                  columnFilter={{
                    isAdmin: props.isAdmin,
                    removeColumns: ['state', 'moduleCategory', 'module', 'moduleExecution', 'semester', 'academicYear'],
                  }}
                  onAddComment={props.addCommentAsync}
                  onRemoveComment={props.removeCommentAsync}
                  currentUser={props.currentUser}
                  getHistory={props.getHistoryAsync}
                >
                  {(cols) => (
                    <MultiselectDataGrid
                      columns={cols}
                      rows={props.moduleRegistrationInfo.confirmed}
                      loading={props.loading}
                      pageSize={100}
                      selectionModel={props.selectedConfirmed}
                      onSelectionModelChange={handleConfirmedSelectionChanged}
                      disabled={!props.currentPhaseIsOpen}
                    ></MultiselectDataGrid>
                  )}
                </ModuleRegistrations>
              </Grid>
              <Grid item xs={12} style={{ borderTop: '1px solid', marginTop: 20, paddingTop: 10 }}>
                <strong>Requested: </strong>
                {props.moduleRegistrationInfo.requested?.length ?? 0}
                <br />
                <DefaultButton
                  primary
                  onClick={handleConfirmRequested}
                  disabled={props.selectedRequested.length === 0 || props.loading || !props.currentPhaseIsOpen}
                >
                  Confirm ({props.selectedRequested.length})
                </DefaultButton>
                <DefaultButton
                  onClick={handleRejectRequested}
                  disabled={props.selectedRequested.length === 0 || props.loading || !props.currentPhaseIsOpen}
                >
                  Reject ({props.selectedRequested.length})
                </DefaultButton>
              </Grid>
              <Grid item xs={12}>
                <ModuleRegistrations
                  isAdmin={props.isAdmin}
                  labels={props.labels}
                  rows={props.requested}
                  moduleExecutions={[]}
                  showInfos
                  loading={props.loading}
                  columnFilter={{
                    isAdmin: props.isAdmin,
                    removeColumns: ['state', 'moduleCategory', 'module', 'moduleExecution', 'semester', 'academicYear'],
                  }}
                  onAddComment={props.addCommentAsync}
                  onRemoveComment={props.removeCommentAsync}
                  currentUser={props.currentUser}
                  getHistory={props.getHistoryAsync}
                >
                  {(cols) => (
                    <MultiselectDataGrid
                      columns={cols}
                      rows={props.requested}
                      loading={props.loading}
                      selectionModel={props.selectedRequested}
                      pageSize={100}
                      onSelectionModelChange={handleRequestedSelectionChanged}
                      disabled={!props.currentPhaseIsOpen}
                    ></MultiselectDataGrid>
                  )}
                </ModuleRegistrations>
              </Grid>

              <>
                <Grid item xs={12} style={{ borderTop: '1px solid', marginTop: 20, paddingTop: 10 }}>
                  <strong>Rejected: </strong>
                  {props.rejected.length}
                  <br />
                  <DefaultButton
                    primary
                    onClick={handleToRequestedRejected}
                    disabled={props.selectedRejected.length === 0 || props.loading || !props.currentPhaseIsOpen}
                  >
                    To Requested Queue: ({props.selectedRejected.length})
                  </DefaultButton>
                </Grid>
                <Grid item xs={12}>
                  <ModuleRegistrations
                    isAdmin={props.isAdmin}
                    labels={props.labels}
                    rows={props.rejected}
                    moduleExecutions={[]}
                    loading={props.loading}
                    showInfos
                    columnFilter={{
                      isAdmin: props.isAdmin,
                      removeColumns: ['moduleCategory', 'module', 'moduleExecution', 'semester', 'academicYear'],
                    }}
                    onAddComment={async (c) => {
                      await props.addCommentAsync(c);
                      setRegistrationCommentOpen(null);
                    }}
                    onRemoveComment={async (c) => {
                      await props.removeCommentAsync(c);
                      setRegistrationCommentOpen(null);
                    }}
                    currentUser={props.currentUser}
                    getHistory={props.getHistoryAsync}
                  >
                    {(cols) => (
                      <MultiselectDataGrid
                        columns={cols}
                        pageSize={100}
                        selectionModel={props.selectedRejected}
                        rows={props.rejected}
                        loading={props.loading}
                        onSelectionModelChange={handleRejectedSelectionChanged}
                        disabled={!props.currentPhaseIsOpen}
                      ></MultiselectDataGrid>
                    )}
                  </ModuleRegistrations>
                </Grid>
              </>

              <>
                <Grid item xs={12} style={{ borderTop: '1px solid', marginTop: 20, paddingTop: 10 }}>
                  <strong>Withdrawn: </strong>
                  {props.withdrawn.length}
                  <br />
                  <DefaultButton
                    primary
                    onClick={handleToRequestedWithdrawn}
                    disabled={props.selectedWithdrawn.length === 0 || props.loading || !props.currentPhaseIsOpen}
                  >
                    To Requested Queue: ({props.selectedWithdrawn.length})
                  </DefaultButton>
                </Grid>
                <Grid item xs={12}>
                  <ModuleRegistrations
                    isAdmin={props.isAdmin}
                    labels={props.labels}
                    rows={props.withdrawn}
                    moduleExecutions={[]}
                    loading={props.loading}
                    showInfos
                    columnFilter={{
                      isAdmin: props.isAdmin,
                      removeColumns: ['moduleCategory', 'module', 'moduleExecution', 'semester', 'academicYear'],
                    }}
                    onAddComment={async (c) => {
                      await props.addCommentAsync(c);
                      setRegistrationCommentOpen(null);
                    }}
                    onRemoveComment={async (c) => {
                      await props.removeCommentAsync(c);
                      setRegistrationCommentOpen(null);
                    }}
                    currentUser={props.currentUser}
                    getHistory={props.getHistoryAsync}
                  >
                    {(cols) => (
                      <MultiselectDataGrid
                        columns={cols}
                        pageSize={100}
                        selectionModel={props.selectedWithdrawn}
                        rows={props.moduleRegistrationInfo?.others?.filter(
                          (r) => r.state === RegistrationState.WITHDRAWN,
                        )}
                        loading={props.loading}
                        onSelectionModelChange={handleWithdrawnSelectionChanged}
                        disabled={!props.currentPhaseIsOpen}
                      ></MultiselectDataGrid>
                    )}
                  </ModuleRegistrations>
                </Grid>
              </>
              <>
                <Grid item xs={12} style={{ borderTop: '1px solid', marginTop: 20, paddingTop: 10 }}>
                  <strong>Absentex: </strong>
                  {props.absentex.length}
                  <br />
                </Grid>
                <Grid item xs={12}>
                  <ModuleRegistrations
                    isAdmin={props.isAdmin}
                    labels={props.labels}
                    rows={props.absentex}
                    moduleExecutions={[]}
                    loading={props.loading}
                    showInfos
                    columnFilter={{
                      isAdmin: props.isAdmin,
                      removeColumns: ['moduleCategory', 'module', 'moduleExecution', 'semester', 'academicYear'],
                    }}
                    onAddComment={async (c) => {
                      await props.addCommentAsync(c);
                      setRegistrationCommentOpen(null);
                    }}
                    onRemoveComment={async (c) => {
                      await props.removeCommentAsync(c);
                      setRegistrationCommentOpen(null);
                    }}
                    currentUser={props.currentUser}
                    getHistory={props.getHistoryAsync}
                  >
                    {(cols) => (
                      <MultiselectDataGrid
                        columns={cols}
                        pageSize={100}
                        selectionModel={props.selectedAbsentex}
                        rows={props.moduleRegistrationInfo?.others?.filter(
                          (r) => r.state === RegistrationState.ABSENTEX,
                        )}
                        loading={props.loading}
                        onSelectionModelChange={() => {}}
                        disabled={true}
                      ></MultiselectDataGrid>
                    )}
                  </ModuleRegistrations>
                </Grid>
              </>
              {props.moduleRegistrationInfo.others &&
                props.moduleRegistrationInfo.others.filter(
                  (r) =>
                    r.state !== RegistrationState.WITHDRAWN &&
                    r.state !== RegistrationState.REJECTED &&
                    r.state !== RegistrationState.ABSENTEX,
                ).length > 0 && (
                  <>
                    <Grid item xs={12} style={{ borderTop: '1px solid', marginTop: 20, paddingTop: 10 }}>
                      <strong>Others: </strong>
                      {
                        props.moduleRegistrationInfo.others?.filter(
                          (r) =>
                            r.state !== RegistrationState.WITHDRAWN &&
                            r.state !== RegistrationState.REJECTED &&
                            r.state !== RegistrationState.ABSENTEX,
                        ).length
                      }
                      <br />
                      <DefaultButton
                        primary
                        onClick={handleToRequestedOthers}
                        disabled={props.selectedOthers.length === 0 || props.loading || !props.currentPhaseIsOpen}
                      >
                        To Requested Queue: ({props.selectedOthers.length})
                      </DefaultButton>
                    </Grid>
                    <Grid item xs={12}>
                      <ModuleRegistrations
                        isAdmin={props.isAdmin}
                        labels={props.labels}
                        rows={
                          props.moduleRegistrationInfo?.others?.filter(
                            (r) => r.state !== RegistrationState.WITHDRAWN && r.state !== RegistrationState.REJECTED,
                          ) ?? []
                        }
                        moduleExecutions={[]}
                        loading={props.loading}
                        showInfos
                        columnFilter={{
                          isAdmin: props.isAdmin,
                          removeColumns: ['moduleCategory', 'module', 'moduleExecution', 'semester', 'academicYear'],
                        }}
                        onAddComment={async (c) => {
                          await props.addCommentAsync(c);
                          setRegistrationCommentOpen(null);
                        }}
                        onRemoveComment={async (c) => {
                          await props.removeCommentAsync(c);
                          setRegistrationCommentOpen(null);
                        }}
                        currentUser={props.currentUser}
                        getHistory={props.getHistoryAsync}
                      >
                        {(cols) => (
                          <MultiselectDataGrid
                            columns={cols}
                            pageSize={100}
                            selectionModel={props.selectedOthers}
                            rows={
                              props.moduleRegistrationInfo?.others?.filter(
                                (r) =>
                                  r.state !== RegistrationState.WITHDRAWN &&
                                  r.state !== RegistrationState.REJECTED &&
                                  r.state !== RegistrationState.ABSENTEX,
                              ) ?? []
                            }
                            loading={props.loading}
                            onSelectionModelChange={handleOthersSelectionChanged}
                            disabled={!props.currentPhaseIsOpen}
                          ></MultiselectDataGrid>
                        )}
                      </ModuleRegistrations>
                    </Grid>
                  </>
                )}
              {registrationCommentOpen && (
                <CommentDialog
                  registrationInfo={{
                    moduleExecutionId: props.selectedExecution.id,
                    id: registrationCommentOpen.id,
                    moduleExecutionCode: props.selectedExecution.code,
                    comments: registrationCommentOpen.comments,
                    history: registrationCommentOpen.history,
                  }}
                  open={!!registrationCommentOpen}
                  onClose={() => {
                    setRegistrationCommentOpen(null);
                  }}
                  onAddComment={props.addCommentAsync}
                  onRemoveComment={props.removeCommentAsync}
                  student={{
                    firstName: registrationCommentOpen.studentFirstName,
                    familyName: registrationCommentOpen.studentFamilyName,
                    id: registrationCommentOpen.studentId,
                  }}
                  loading={false}
                />
              )}
            </Grid>
          </>
        )}
      </MainContent>
    </SplitLayout>
  );
};

function mapStateToProps({
  semesterSelection,
  profileSelection,
  moduleRegistration,
  moduleSelection,
  auth,
}: IRootState) {
  const executions =
    (moduleSelection.executions ?? [])[semesterSelection.selectedSemester?.academicYearId ?? 0]?.filter(
      (e) =>
        e.categoryId === moduleSelection.selectedCategory?.id && semesterSelection.selectedSemester.id === e.semesterId,
    ) ?? [];
  const currentPhase = semesterSelection.selectedSemester?.phases.find(
    (p) => p.id === semesterSelection.selectedSemester?.currentPhaseId,
  );
  return {
    selectedAcademicYear: semesterSelection.selectedAcademicYear,
    selectedSemester: semesterSelection.selectedSemester,
    selectedCategory: moduleSelection.selectedCategory,
    selectedExecution: moduleSelection.selectedExecution,
    moduleCategories: moduleSelection.moduleCategories?.filter((c) => c.isActive) ?? [],
    moduleExecutions: executions,
    moduleRegistrationInfo: moduleRegistration.moduleRegistrationInfo,
    semesterFilter: semesterSelection.semesterFilter,
    loading: moduleRegistration.loading,
    mainContentLoading: moduleRegistration.mainContentLoading,
    isAdmin: hasAnyRole(auth.account?.roles, [RoleType.REGISTRATIONADMIN]),
    requested: moduleRegistration.moduleRegistrationInfo?.requested ?? [],
    confirmed: moduleRegistration.moduleRegistrationInfo?.confirmed ?? [],
    withdrawn:
      moduleRegistration.moduleRegistrationInfo?.others?.filter((o) => o.state === RegistrationState.WITHDRAWN) ?? [],
    rejected:
      moduleRegistration.moduleRegistrationInfo?.others?.filter((o) => o.state === RegistrationState.REJECTED) ?? [],
    absentex:
      moduleRegistration.moduleRegistrationInfo?.others?.filter((o) => o.state === RegistrationState.ABSENTEX) ?? [],
    others:
      moduleRegistration.moduleRegistrationInfo?.others?.filter(
        (o) =>
          o.state !== RegistrationState.REJECTED &&
          o.state !== RegistrationState.WITHDRAWN &&
          o.state !== RegistrationState.ABSENTEX,
      ) ?? [],
    selectedRequested: moduleRegistration.selectedRequested ?? [],
    selectedConfirmed: moduleRegistration.selectedConfirmed ?? [],
    selectedRejected: moduleRegistration.selectedRejected ?? [],
    selectedWithdrawn: moduleRegistration.selectedWithdrawn ?? [],
    selectedAbsentex: moduleRegistration.selectedAbsentex ?? [],
    selectedOthers: moduleRegistration.selectedOthers ?? [],
    capacities: moduleRegistration.capacities ?? [],
    labels: profileSelection.labels ?? [],
    currentUser: auth.account,
    currentPhaseIsOpen: currentPhase?.isOpen ?? false,
    currentPhase,
  };
}

const mapDispatchToProps = (dispatch) => {
  const actions = prioritizationModule.initActions(dispatch);
  return {
    ...actions,
    addExceptionNotification: (
      e: any,
      message?: string,
      onClose?: (event: React.SyntheticEvent<any, Event>, reason: CloseReason) => void,
    ) => dispatch(addExceptionNotification(e, message, onClose)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Modules);
