import { loadingBarReducer as loadingBar } from 'react-redux-loading-bar';
import { combineReducers } from 'redux';

import application from 'app/modules/application/index';
import auth from 'app/modules/login/auth/index';
import notification from 'app/modules/notification/index';

import moduleManagement from 'app/modules/module-management/modules';
import moduleRegistration from 'app/modules/module-registrations/prioritization/index';
import moduleRegistrationExport from 'app/modules/module-registrations/registration-export';
import summary from 'app/modules/module-registrations/summary/index';
import uasRegistration from 'app/modules/module-registrations/uas-registrations/index';
import priorityManagement from 'app/modules/profile-management/priorities/index';
import profileManagement from 'app/modules/profile-management/profiles/index';
import registrationImport from 'app/modules/registration-import/file-upload/index';
import importRequests from 'app/modules/registration-import/import-requests/index';
import importsManagement from 'app/modules/registration-import/imports/index';
import advisorSelection from 'app/modules/selections/advisor-selection';
import moduleSelection from 'app/modules/selections/module-selection';
import profileSelection from 'app/modules/selections/profile-selection';
import semesterSelection from 'app/modules/selections/semester-selection/index';
import semesterManagement from 'app/modules/semester-management/semesters';
import studentSearch from 'app/modules/student-management/search';
import studentManagement from 'app/modules/student-management/students';
import studentTabs from 'app/modules/student-management/tabs';
import uasManagement from 'app/modules/uas-management/uas';
import userManagement from 'app/modules/user-management/user-management';
import { TypedUseSelectorHook, useSelector as useReduxSelector } from 'react-redux';

const rootReducer = combineReducers({
  notification,
  auth: auth.reducer,
  application: application.reducer,
  userManagement: userManagement.reducer,
  moduleRegistration: moduleRegistration.reducer,
  registrationImport: registrationImport.reducer,
  importRequests: importRequests.reducer,
  semesterSelection: semesterSelection.reducer,
  moduleSelection: moduleSelection.reducer,
  moduleManagement: moduleManagement.reducer,
  uasRegistration: uasRegistration.reducer,
  profileManagement: profileManagement.reducer,
  priorityManagement: priorityManagement.reducer,
  importsManagement: importsManagement.reducer,
  semesterManagement: semesterManagement.reducer,
  studentManagement: studentManagement.reducer,
  profileSelection: profileSelection.reducer,
  advisorSelection: advisorSelection.reducer,
  studentSearch: studentSearch.reducer,
  studentTabs: studentTabs.reducer,
  uasManagement: uasManagement.reducer,
  moduleRegistrationExport: moduleRegistrationExport.reducer,
  summary: summary.reducer,
  loadingBar,
});

export type IRootState = ReturnType<typeof rootReducer>;

export default rootReducer;

export const useRootSelector: TypedUseSelectorHook<IRootState> = useReduxSelector;
