import React from 'react';

import { Badge } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link } from 'react-router-dom';

export interface IDrawerItemProps {
  title: string;
  url: string;
  batchCount?: number;
  useBatch?: boolean;
  icon: React.ComponentType<any>;
  isActive: boolean;
}

export const DrawerItem = (props: IDrawerItemProps) => {
  return (
    <ListItem button key={props.title} component={Link} to={props.url} selected={props.isActive}>
      <ListItemIcon>
        {props.useBatch ? (
          <Badge badgeContent={props.batchCount} color="primary">
            {React.createElement(props.icon)}
          </Badge>
        ) : (
          <>{React.createElement(props.icon)}</>
        )}
      </ListItemIcon>
      <ListItemText primary={props.title} />
    </ListItem>
  );
};
