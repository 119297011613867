import { IconButton, Paper, Tooltip } from '@mui/material';
import { ICommentDto, IUserDto } from 'app/clients/services';
import React from 'react';

import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { APP_DATETIME_FORMAT } from 'app/config/constants';

import DeleteIcon from '@mui/icons-material/DeleteForever';
import PolicyIcon from '@mui/icons-material/Policy';

type Props = {
  comments: ICommentDto[];
  onRemoveComment: (comment: ICommentDto) => Promise<void>;
  currentUser: IUserDto;
  isAdmin: boolean;
};

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     root: {
//       width: '100%',
//       backgroundColor: theme.palette.background.paper,
//     },
//     inline: {
//       display: 'inline',
//     },
//   }),
// );

export const Comments = (props: Props) => {
  // const classes = useStyles();
  const createComment = (comment: ICommentDto) => {
    return (
      <>
        <ListItem alignItems="flex-start">
          <ListItemText
            primary={<>{comment.createdBy}</>}
            secondary={
              <React.Fragment>
                <Typography component="span" variant="body2" /* className={classes.inline}*/ color="textPrimary">
                  {comment.createdDate.toFormat(APP_DATETIME_FORMAT)}
                </Typography>
                {` — ${comment.comment} `}
                {props.isAdmin && comment.isInternal && (
                  <Tooltip title={<Typography>only visible for admins</Typography>}>
                    <IconButton style={{ display: 'inline-block' }}>
                      <PolicyIcon />
                    </IconButton>
                  </Tooltip>
                )}
                {props.currentUser?.id === comment.createdById && (
                  <Tooltip title={<Typography>delete comment</Typography>}>
                    <IconButton
                      style={{ display: 'inline-block' }}
                      onClick={async () => await props.onRemoveComment(comment)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </React.Fragment>
            }
          />
        </ListItem>
        <Divider variant="fullWidth" component="li" />
      </>
    );
  };

  return (
    <Paper style={{ padding: 5 }}>
      <List /* className={classes.root} */>
        {props.comments.sort((c1, c2) => (c1.createdDate < c2.createdDate ? -1 : 1)).map((c) => createComment(c))}
      </List>
    </Paper>
  );
};
