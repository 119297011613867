export default {
  SELECT_MODULE_CATEGORY: 'moduleSelection/SELECT_MODULE_CATEGORY',
  SELECT_MODULE: 'moduleSelection/SELECT_MODULE',
  SELECT_MODULE_EXECUTION: 'moduleSelection/SELECT_MODULE_EXECUTION',
  GET_MODULE_FILTER: 'moduleSelection/GET_MODULE_FILTER',
  FETCH_MODULE_EXECUTIONS: 'moduleSelection/FETCH_MODULE_EXECUTIONS',
  LOAD_MODULE_EXECUTION: 'moduleSelection/LOAD_MODULE_EXECUTION',
  RESET_EXECUTIONS: 'moduleSelection/RESET_EXECUTIONS',
  RESET: 'moduleSelection/RESET',
};
