import initActions from './actions';
import types from './types';
import reducer from './reducer';

import { IProfileClient } from 'app/clients/services';
import { resolver } from 'app/clients/service-registry';

import profileSelectionModule from '../../selections/profile-selection';

export type { ProfileManagementState } from './reducer';

export default {
  initActions: () => initActions(resolver.resolve<IProfileClient>('IProfileClient'), profileSelectionModule.initProfileActions()),
  types,
  reducer,
};
