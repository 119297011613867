import DeleteIcon from '@mui/icons-material/DeleteForever';
import SaveIcon from '@mui/icons-material/Save';
import { Grid } from '@mui/material';
import { IModuleCategoryDto } from 'app/clients/services';
import { DefaultButton } from 'app/shared/layout/ui-elements/buttons';
import { ConfirmationDialog } from 'app/shared/layout/ui-elements/confirmation-dialog';
import { Field, Form, Formik } from 'formik';
import { CheckboxWithLabel, TextField as FormikTextField } from 'formik-mui';
import React, { useState } from 'react';

type ModuleCategoryFormProps = {
  loading: boolean;
  category: IModuleCategoryDto;
  categories: IModuleCategoryDto[];
  hasModules: boolean;
  onSubmit: (category: IModuleCategoryDto) => Promise<void>;
  onCancel: () => void;
  onDelete: (category: IModuleCategoryDto) => Promise<void>;
};

export const CategoryForm = (props: ModuleCategoryFormProps) => {
  const [deleteAcademicYearOpen, setDialogDeleteCategoryOpen] = useState<boolean>(false);
  return (
    <Formik
      enableReinitialize
      initialValues={props.category}
      validate={(values) => {
        const errors: { code?: string; name?: string } = {};
        if (!values.code || values.code === 'untitled') {
          errors.code = 'Required';
        }
        if (props.categories.filter((p) => p.code === values.code && p.id !== values.id).length) {
          errors.code = 'Code already exists!';
        }

        if (values.name && props.categories.filter((p) => p.name === values.name && p.id !== values.id).length) {
          errors.name = 'Name already exists!';
        }

        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        props.onSubmit(values).then(() => setSubmitting(false));
      }}
    >
      {({ submitForm, isSubmitting }) => (
        <Form>
          <Grid container direction="row" justifyContent="flex-start" alignItems="flex-end" spacing={5}>
            <Grid item md={3}>
              <Field component={FormikTextField} name="code" type="text" label="Code" variant="standard" />
            </Grid>
            <Grid item md={3}>
              <Field component={FormikTextField} name="name" type="text" label="Name" variant="standard" />
            </Grid>
            <Grid item md={3}>
              <Field component={CheckboxWithLabel} type="checkbox" name="isActive" Label={{ label: 'Is Active' }} />
            </Grid>

            <Grid item md={3}>
              <DefaultButton
                primary
                disabled={isSubmitting || props.loading}
                onClick={submitForm}
                startIcon={<SaveIcon />}
              >
                Save
              </DefaultButton>

              {props.hasModules && (
                <>
                  {props.category.id ? (
                    <DefaultButton
                      disabled={isSubmitting || props.loading}
                      onClick={() => setDialogDeleteCategoryOpen(true)}
                      startIcon={<DeleteIcon />}
                    >
                      Delete
                    </DefaultButton>
                  ) : (
                    <DefaultButton
                      disabled={isSubmitting || props.loading}
                      onClick={() => props.onCancel()}
                      startIcon={<DeleteIcon />}
                    >
                      Cancel
                    </DefaultButton>
                  )}
                  <ConfirmationDialog
                    title="Delete Category"
                    onConfirm={() => props.onDelete(props.category)}
                    open={deleteAcademicYearOpen}
                    onClose={() => setDialogDeleteCategoryOpen(false)}
                  >
                    Do you really want to delete the academic year <strong>{props.category.code}</strong>?
                  </ConfirmationDialog>
                </>
              )}
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
