import ACTION_TYPES, { NotificationObject } from './types';

const initialState = {
  notifications: [] as NotificationObject[],
};

export type NotificationState = Readonly<typeof initialState>;

export default (state: NotificationState = initialState, action): NotificationState => {
  switch (action.type) {
    case ACTION_TYPES.ADD:
      if (state.notifications.filter(n => n.level === action.payload.level && n.message === action.payload.message).length > 0) {
        return state;
      }
      if (state.notifications.filter(m => m.options?.key === action.payload.options?.key).length > 0) {
        return {
          ...state,
          notifications: state.notifications.map(m => (m.options?.key === action.payload.options?.key ? action.payload : m)),
        };
      }
      return {
        ...state,
        notifications: [...state.notifications, action.payload],
      };
    case ACTION_TYPES.REMOVE: {
      return {
        ...state,
        notifications: state.notifications.filter(n => n.options.key !== action.payload),
      };
    }
    case ACTION_TYPES.CLOSE:
      return {
        ...state,
        notifications: state.notifications.map(n => (n.options.key === action.payload ? { ...n, dismissed: true } : { ...n })),
      };
    case ACTION_TYPES.CLOSE_ALL:
      return {
        ...state,
        notifications: state.notifications.map(n => ({ ...n, dismissed: true })),
      };
    case ACTION_TYPES.CLEAR:
      return initialState;
    default:
      return state;
  }
};
