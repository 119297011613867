import SearchIcon from '@mui/icons-material/Search';
import { Box, IconButton, TextField } from '@mui/material';
import React, { useState } from 'react';

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     root: {
//       width: '100%',
//       flexGrow: 1,
//     },
//     input: {
//       marginLeft: theme.spacing(1),
//       flex: 1,
//     },
//     iconButton: {
//       padding: 10,
//     },
//   }),
// );

type Props = {
  onSearch: (searchString: string) => void;
  onChange?: (searchString: string) => void;
  searchString?: string;
  title?: string;
};

export const Search = (props: Props) => {
  // const classes = useStyles();
  const [searchString, setSearchString] = useState<string>(props.searchString ?? '');

  const keyPress = (keyEvent: React.KeyboardEvent<HTMLDivElement>) => {
    if (keyEvent.key === 'Enter') {
      props.onSearch(searchString);
    }
  };

  const onSearchStringChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearchString(event.target.value);
    if (props.onChange) {
      props.onChange(event.target.value);
    }
  };

  return (
    <Box sx={{ padding: 1, display: 'flex', width: '100%', justifyContent: 'flex-start' }}>
      <IconButton sx={{ paddingRight: 1 }} aria-label="search" onClick={() => props.onSearch(searchString)}>
        <SearchIcon />
      </IconButton>
      <TextField
        onKeyDown={keyPress}
        onChange={onSearchStringChange}
        value={searchString ?? ''}
        variant="standard"
        sx={{ width: '100%', padding: 1 }}
        placeholder={props.title ?? 'Search'}
        inputProps={{ 'aria-label': 'search' }}
      />
    </Box>
  );
};
