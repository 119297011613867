import { blue, grey, red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  // overrides: {
  //   MuiCssBaseline: {
  //     '@global': {
  //       html: {
  //         WebkitFontSmoothing: 'auto',
  //       },
  //     },
  //   },
  // },
  palette: {
    primary: {
      main: blue[500],
    },
    secondary: {
      main: grey[600],
    },
    error: {
      main: red.A400,
    },
  },
  // props: {
  //   MuiTypography: {
  //     variantMapping: {
  //       h1: 'h2',
  //       h2: 'h2',
  //       h3: 'h3',
  //       h4: 'h2',
  //       h5: 'h2',
  //       h6: 'h2',
  //       subtitle1: 'h2',
  //       subtitle2: 'h2',
  //       body1: 'span',
  //       body2: 'span',
  //     },
  //   },
  // },
  typography: {
    h1: {
      fontSize: '2rem',
      marginBottom: 20,
    },
    h2: {
      fontSize: '1.5rem',
      marginBottom: 10,
    },
    h3: {
      fontSize: '1.2rem',
      marginBottom: 10,
    },
  },
});

export const drawerWidth: number = 240;

export default theme;
