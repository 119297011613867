import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, LinearProgress } from '@mui/material';
import { CommentDto, ICommentDto, IRegistrationStateChangeDto, RoleType } from 'app/clients/services';
import { useAccount, useHasRole } from 'app/modules/login/hooks';
import { FastField, Form, Formik } from 'formik';
import { CheckboxWithLabel, TextField as FormikTextField } from 'formik-mui';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { Comments } from './comments';
import { StateChanges } from './state-changes';

type Props = {
  open: boolean;
  onClose: () => void;
  onAddComment: (comment: ICommentDto) => Promise<void>;
  onRemoveComment: (comment: ICommentDto) => Promise<void>;
  registrationInfo: {
    id: number;
    moduleExecutionId: number;
    history?: IRegistrationStateChangeDto[];
    comments?: ICommentDto[];
    moduleExecutionCode: string;
  };
  student: { firstName: string; familyName: string; id: string };
  loading: boolean;
};

type CommentDialogDynamicProps = Omit<Props, 'loading' | 'registrationInfo'> & {
  refresh: (id: number | undefined) => Promise<{
    history?: IRegistrationStateChangeDto[];
    comments?: ICommentDto[];
  }>;
  registrationInfo: {
    id: number;
    moduleExecutionId: number;
    moduleExecutionCode: string;
  };
};

export const CommentDialogDynamic = (props: CommentDialogDynamicProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [stateChanges, setStateChanges] = useState<IRegistrationStateChangeDto[] | null>(null);
  const [comments, setComments] = useState<ICommentDto[]>(null);

  useEffect(() => {
    const loadStateChanges = async () => {
      setLoading(true);
      try {
        const result = await props.refresh(props.registrationInfo.id);
        setStateChanges(result?.history ?? []);
        setComments(result?.comments ?? []);
        setLoading(false);
      } catch {
        setStateChanges([]);
        setLoading(false);
      }
    };
    loadStateChanges();
  }, [props.open]);

  <CommentDialog
    {...props}
    registrationInfo={{
      id: props.registrationInfo.id,
      moduleExecutionId: props.registrationInfo.moduleExecutionId,
      moduleExecutionCode: props.registrationInfo.moduleExecutionCode,
      history: stateChanges,
      comments,
    }}
    loading={loading}
  />;
  return;
};

export const CommentDialog = (props: Props) => {
  const isAdmin = useHasRole(RoleType.REGISTRATIONADMIN);
  const currentUser = useAccount();

  const id =
    props.registrationInfo.id ??
    `${props.registrationInfo?.moduleExecutionCode}-${props.student?.firstName?.replace(
      ' ',
      '_',
    )}-${props.student?.familyName?.replace(' ', '_')}`;

  return (
    props.registrationInfo.id && (
      <Formik
        enableReinitialize
        initialValues={
          new CommentDto({
            id: 0,
            registrationId: props.registrationInfo.id,
            comment: '',
            answerToId: undefined,
            showInOverview: true,
            isInternal: false,
            createdDate: DateTime.local(),
            createdBy: currentUser ? currentUser.firstName + ' ' + currentUser.lastName : 'me',
            createdById: currentUser?.id,
          })
        }
        onSubmit={(values, { setSubmitting }) => {
          props
            .onAddComment(values)
            .then(() => {
              setSubmitting(false);
              props.onClose();
            })
            .catch(() => setSubmitting(false));
        }}
        validationSchema={Yup.object({
          comment: Yup.string().required('required'),
        })}
      >
        {({ submitForm, isSubmitting, resetForm }) => (
          <Form id={'comment-dialog-' + id}>
            <Dialog
              maxWidth="lg"
              open={props.open}
              onClose={() => props.onClose()}
              aria-labelledby={`comment-dialog-${id}`}
            >
              <DialogTitle id={`comment-dialog-title-${id}`}>Comments</DialogTitle>
              <DialogContent>
                {props.loading ? (
                  <LinearProgress></LinearProgress>
                ) : (
                  <>
                    Comments for the module registration <strong>{props.registrationInfo?.moduleExecutionCode}</strong>{' '}
                    {props.student && (
                      <>
                        of student{' '}
                        <strong>
                          <Link to={`/student-management/student/${props.student.id}`}>
                            {props.student.firstName} {props.student.familyName}
                          </Link>
                        </strong>
                      </>
                    )}
                    .<br />
                    <br />
                    {props.registrationInfo.history?.length > 0 && (
                      <>
                        <strong>History:</strong>
                        <StateChanges
                          changes={props.registrationInfo.history}
                          moduleExecutionId={props.registrationInfo.moduleExecutionId}
                        />
                      </>
                    )}
                    {props.registrationInfo.comments && props.registrationInfo.comments?.length > 0 && (
                      <>
                        <strong>Comments:</strong>
                        <Comments
                          comments={props.registrationInfo.comments ?? []}
                          onRemoveComment={async (comment: ICommentDto) => {
                            await props.onRemoveComment(comment);
                            props.onClose();
                          }}
                          currentUser={currentUser}
                          isAdmin={isAdmin}
                        />
                      </>
                    )}
                    <Grid container>
                      <Grid item xs={12}>
                        <FastField
                          component={FormikTextField}
                          variant="standard"
                          name="comment"
                          disabled={isSubmitting}
                          label="New Comment"
                          multiline
                          rows={4}
                          style={{ width: '100%' }}
                          type="text"
                        />
                      </Grid>
                      {isAdmin && (
                        <Grid item xs={6}>
                          <FastField
                            component={CheckboxWithLabel}
                            type="checkbox"
                            name="isInternal"
                            Label={{ label: 'Only For Admins' }}
                          />
                        </Grid>
                      )}
                      {isSubmitting && <LinearProgress></LinearProgress>}
                    </Grid>
                  </>
                )}
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    resetForm();
                    props.onClose();
                  }}
                  variant="contained"
                  disabled={isSubmitting}
                  color="inherit"
                >
                  Close
                </Button>
                <Button onClick={submitForm} color="primary" variant="contained" disabled={isSubmitting} type="submit">
                  Save
                </Button>
              </DialogActions>
            </Dialog>
          </Form>
        )}
      </Formik>
    )
  );
};
