import { IModuleCategoryDto, IModuleDto } from 'app/clients/services';
import ACTION_TYPES from './types';

// State
const initialState = {
  loading: false,
  newModuleCategory: null as IModuleCategoryDto,
  newModule: null as IModuleDto,
  moduleLoading: -1,
  moduleExecutionLoading: -1,
};

export type ModuleManagementState = Readonly<typeof initialState>;

// Reducer
export default (state: ModuleManagementState = initialState, action): ModuleManagementState => {
  switch (action.type) {
    case ACTION_TYPES.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case ACTION_TYPES.SET_MODULE_LOADING:
      return {
        ...state,
        moduleLoading: action.payload,
      };
    case ACTION_TYPES.SET_MODULE_EXECUTION_LOADING:
      return {
        ...state,
        moduleExecutionLoading: action.payload,
      };
    case ACTION_TYPES.ADD_MODULE_CATEGORY:
      return {
        ...state,
        newModuleCategory: action.payload,
        newModule: null,
      };
    case ACTION_TYPES.ADD_MODULE:
      return {
        ...state,
        newModule: action.payload,
        newModuleCategory: null,
      };
    default:
      return state;
  }
};
