import { Alert, AlertTitle } from '@mui/material';
import { RoleType } from 'app/clients/services';
import { IRootState } from 'app/config/root.reducer';
import ErrorBoundary from 'app/shared/error/error-boundary';
import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TextContent } from '../layout/ui-elements/component-layout';
import { hasAnyRole } from './roles';

export type GuardedProps = StateProps & { requiresRole?: RoleType[]; children: React.ReactNode };

export const GuardedComponent = ({ children, requiresRole, account, sessionHasBeenFetched }: GuardedProps) => {
  const navigate = useNavigate();

  if (!account && !sessionHasBeenFetched) {
    navigate('/api/login');
  }

  const isAuthorized = (requiresRole ?? []).length === 0 || hasAnyRole(account?.roles, requiresRole ?? []);
  return (
    <>
      {isAuthorized ? (
        <>
          <ErrorBoundary>{children}</ErrorBoundary>
        </>
      ) : (
        <TextContent>
          <Alert severity="error">
            <AlertTitle>Not Authorized</AlertTitle>
            You are not authorized to access this page.
          </Alert>
        </TextContent>
      )}
    </>
  );
};

const mapStateToProps = ({ auth: { account, sessionHasBeenFetched } }: IRootState) => ({
  account,
  sessionHasBeenFetched,
});

type StateProps = ReturnType<typeof mapStateToProps>;

/**
 * A route wrapped in an authentication check so that routing happens only when you are authenticated.
 * Accepts same props as React router Route.
 * The route also checks for authorization if hasAnyAuthorities is specified.
 */

const Guarded = connect(mapStateToProps)(GuardedComponent);
export default Guarded;
