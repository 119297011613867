import {
  FileResponse,
  IAcademicYearDto,
  IFileImportDto,
  IImportClient,
  IPhaseDto,
  ISemesterDto,
  ISemesterInfoDto,
  RegistrationImport,
  RegistrationValidationDto,
  RoleType,
} from 'app/clients/services';
import { SemesterSelectionActions } from 'app/modules/selections/semester-selection/actions';
import { hasAnyRole } from 'app/shared/auth/roles';
import { AsyncThunk } from 'app/shared/util/action-type.util';
import { saveAs } from 'file-saver';
import ACTION_TYPES from './types';

const initActions = (importClient: IImportClient, selectionActions: SemesterSelectionActions) => {
  const selectImport = (id: number) => ({
    type: ACTION_TYPES.SELECT_IMPORT,
    payload: id,
  });

  const selectImportAsync: (id: number) => AsyncThunk<RegistrationValidationDto> = (id) => async (dispatch) => {
    dispatch(selectImport(id));
    if (id > 0) {
      const result = await dispatch({
        type: ACTION_TYPES.GET_VALIDATION,
        payload: importClient.getImport(id),
      });
      return result.value;
    }
    return null;
  };

  const countPendingImportsAsync: () => AsyncThunk = () => async (dispatch) => {
    const result = await importClient.countPendingImports();
    dispatch({
      type: ACTION_TYPES.UPDATE_PENDING_COUNT,
      payload: result,
    });
  };

  const getPendingImportsAsync: (academicYear?: IAcademicYearDto, semester?: ISemesterDto, phase?: IPhaseDto) => AsyncThunk<IFileImportDto[]> =
    (academicYear, semester, phase) => async (dispatch) => {
      const result = await dispatch({
        type: ACTION_TYPES.GET_PENDING_IMPORTS,
        payload: importClient.getPendingImports(academicYear?.id ?? 0, semester?.id ?? 0, phase?.id ?? 0),
      });
      return result.value;
    };

  const downloadAsync: (id: number) => AsyncThunk<FileResponse> = (id) => async (dispatch) => {
    dispatch(selectImportAsync(id));

    const result = await dispatch({
      type: ACTION_TYPES.DOWNLOAD,
      payload: importClient.getOriginalFile(id),
    });

    const fileResponse = result.value;
    saveAs(fileResponse.data, fileResponse.fileName);
    return fileResponse;
  };

  const rejectAsync: (id: number) => AsyncThunk<boolean> = (id) => async (dispatch) => {
    dispatch(selectImport(0));

    const success = await importClient.reject(id);

    await dispatch(getPendingImportsAsync());
    return success;
  };

  const updateRegistrationImport = (registrationImport: RegistrationImport) => ({
    type: ACTION_TYPES.UPDATE_VALIDATION_PREVIEW,
    payload: registrationImport,
  });

  const reduceWarningCount = () => ({
    type: ACTION_TYPES.REDUCE_WARNING_COUNT,
  });

  const setMainContentLoading = (loading: boolean) => ({
    type: ACTION_TYPES.SET_MAIN_CONTENT_LOADING,
    payload: loading,
  });

  const importAsync: (id: number) => AsyncThunk<RegistrationValidationDto> = (id) => async (dispatch, getState) => {
    dispatch(selectImport(id));

    const result = await dispatch({
      type: ACTION_TYPES.IMPORT,
      payload: importClient.import(id),
    });

    if (result.value.canSubmit) {
      if (hasAnyRole(getState().auth.account?.roles, [RoleType.REGISTRATIONADMIN])) {
        await Promise.all([dispatch(getPendingImportsAsync()), dispatch(countPendingImportsAsync())]);
      } else {
        await dispatch(getPendingImportsAsync());
      }
      dispatch(selectImport(0));
    }

    return result.value;
  };

  const resetValidationAsync: (id: number) => AsyncThunk<RegistrationValidationDto> = (id) => async (dispatch) => {
    dispatch(selectImport(id));
    const result = await dispatch({
      type: ACTION_TYPES.GET_VALIDATION,
      payload: importClient.resetValidation(id),
    });

    dispatch({
      type: ACTION_TYPES.SET_IS_UNSAVED,
      payload: true,
    });

    return result.value;
  };

  const revalidateSubmittedAsync: (registrationImport: RegistrationImport) => AsyncThunk<RegistrationValidationDto> = (registrationImport) => async (dispatch) => {
    dispatch(selectImport(registrationImport.importDbId));
    const result = await dispatch({
      type: ACTION_TYPES.GET_VALIDATION,
      payload: importClient.revalidateSubmitted(registrationImport),
    });

    if (result.value.canSubmit) {
      dispatch({
        type: ACTION_TYPES.SET_IS_UNSAVED,
        payload: false,
      });
    }
    return result.value;
  };

  const setFilterAsync: (academicYear?: IAcademicYearDto, semester?: ISemesterInfoDto, phase?: IPhaseDto) => AsyncThunk<IFileImportDto[]> =
    (academicYear, semester, phase) => async (dispatch) => {
      if (phase) {
        dispatch({ type: ACTION_TYPES.SELECT_PHASE, payload: phase });
        const result = dispatch(selectionActions.selectSemesterById(phase.semesterId));
        return await dispatch(getPendingImportsAsync(result.academicYear, result.semester, phase));
      }

      dispatch({ type: ACTION_TYPES.SELECT_PHASE, payload: null });
      if (semester) {
        const result = dispatch(selectionActions.selectSemester(semester));
        return await dispatch(getPendingImportsAsync(result.academicYear, result.semester));
      }

      if (academicYear) {
        const result = dispatch(selectionActions.selectAcademicYear(academicYear));
        return await dispatch(getPendingImportsAsync(result.academicYear));
      }

      return await dispatch(getPendingImportsAsync());
    };

  return Object.freeze({
    setMainContentLoading,
    reduceWarningCount,
    resetValidationAsync,
    revalidateSubmittedAsync,
    countPendingImportsAsync,
    selectImportAsync,
    getPendingImportsAsync,
    downloadAsync,
    rejectAsync,
    importAsync,
    updateRegistrationImport,
    setFilterAsync,
  });
};

export default initActions;

export type UserManagementActions = ReturnType<typeof initActions>;
