import { IAcademicYearDto, IPhaseDto, ISemesterInfoDto, ISemesterSelectionFilter } from 'app/clients/services';
import { FAILURE, REQUEST, SUCCESS } from '../../../shared/util/action-type.util';
import { getAcademicYear, getOpenSemesters, getPhaseForSemester, getSemester } from './methods';
import ACTION_TYPES from './types';

const initialState = {
  loading: false,
  isLoaded: false,
  semesterFilter: null as ISemesterSelectionFilter,
  errorMessage: null as string,
  selectedAcademicYear: null as IAcademicYearDto,
  selectedSemester: null as ISemesterInfoDto,
  selectedPhase: null as IPhaseDto,
  openSemesters: [] as ISemesterInfoDto[],
};

export type SemesterSelectionState = Readonly<typeof initialState>;

// Reducer
export default (state: SemesterSelectionState = initialState, action): SemesterSelectionState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.GET_SEMESTER_FILTER):
      return {
        ...state,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.GET_SEMESTER_FILTER):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.GET_SEMESTER_FILTER): {
      const semesterFilter = action.payload as ISemesterSelectionFilter;
      if (!semesterFilter) {
        return state;
      }
      const academicYear =
        state.selectedAcademicYear && semesterFilter.academicYears.some((a) => a.id === state.selectedAcademicYear?.id)
          ? state.selectedAcademicYear
          : getAcademicYear(state, null);
      const semester = getSemester(state, academicYear?.id);
      const phase = getPhaseForSemester(state, semester);
      const openSemesters = getOpenSemesters(semesterFilter);
      return {
        ...state,
        loading: false,
        semesterFilter: action.payload,
        selectedAcademicYear: academicYear,
        selectedSemester: semester,
        selectedPhase: phase,
        isLoaded: true,
        openSemesters,
      };
    }
    case ACTION_TYPES.SELECT_ACADEMIC_YEAR: {
      return {
        ...state,
        selectedAcademicYear: action.payload,
      };
    }
    case ACTION_TYPES.SELECT_SEMESTER: {
      return {
        ...state,
        selectedSemester: action.payload,
      };
    }
    case ACTION_TYPES.SELECT_PHASE: {
      return {
        ...state,
        selectedPhase: action.payload,
      };
    }
    default:
      return state;
  }
};
