import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';

export interface ConfirmationDialogProps {
  id?: string;
  title: string;
  open: boolean;
  children: React.ReactNode;
  minWidth?: number;
  onCancel?: () => void;
  onConfirm: () => void;
  onClose: () => void;
  confirmation?: string;
  confirmDisabled?: boolean;
  cancelLabel?: string;
  hideCancel?: boolean;
  additionalActions?: React.ReactNode;
}

// const useStyles = makeStyles((_theme: Theme) =>
//   createStyles({
//     paper: {
//       width: '80%',
//       maxHeight: 435,
//     },
//   }),
// );

export const ConfirmationDialog = (props: ConfirmationDialogProps) => {
  // const classes = useStyles();
  // const radioGroupRef = React.useRef<HTMLElement>(null);

  const id = props.id ?? 'confirmation-dialog';
  // const handleEntering = () => {
  //   if (radioGroupRef.current != null) {
  //     radioGroupRef.current.focus();
  //   }
  // };

  const handleCancel = () => {
    if (props.onCancel) {
      props.onCancel();
    }
    props.onClose();
  };

  const handleConfirm = () => {
    props.onConfirm();
    props.onClose();
  };

  return (
    <Dialog
      // disableBackdropClick
      disableEscapeKeyDown
      maxWidth="lg"
      // onEntering={handleEntering}
      aria-labelledby={id + '-title'}
      open={props.open}
      style={props.minWidth ? { minWidth: props.minWidth } : {}}
    >
      <DialogTitle id={id + '-title'}>{props.title}</DialogTitle>
      <DialogContent dividers>{props.children}</DialogContent>
      <DialogActions>
        {!props.hideCancel && (
          <Button autoFocus onClick={handleCancel} variant="contained" color="inherit">
            {props.cancelLabel ?? 'Cancel'}
          </Button>
        )}
        <Button onClick={handleConfirm} color="primary" variant="contained" disabled={props.confirmDisabled}>
          {props.confirmation ?? 'OK'}
        </Button>
        {props.additionalActions && props.additionalActions}
      </DialogActions>
    </Dialog>
  );
};
