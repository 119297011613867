import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import { EmailDto, IEmailDto, IStudentDto } from 'app/clients/services';
import { ConfirmationDialog } from 'app/shared/layout/ui-elements/confirmation-dialog';
import { FormikDatePicker } from 'app/shared/layout/ui-elements/date-picker';
import { Field, FieldArray, Form, Formik } from 'formik';
import { CheckboxWithLabel, TextField as FormikTextField } from 'formik-mui';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import * as Yup from 'yup';

type StudentFormProps = {
  loading: boolean;
  student: IStudentDto;
  disabled?: boolean;
  onSubmit: (student: IStudentDto) => Promise<void>;
  onCancel: () => void;
  onDelete: (student: IStudentDto) => Promise<void>;
  onDeleteEmail: (email: IEmailDto) => Promise<void>;
};

export const StudentForm = (props: StudentFormProps) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);

  return (
    <Formik
      enableReinitialize
      initialValues={props.student}
      validationSchema={Yup.object({
        firstName: Yup.string().min(2).required('required'),
        familyName: Yup.string().min(2).required('required'),
        birthDate: Yup.date()
          .max(DateTime.local().plus({ years: -16 }).toJSDate(), 'Student has to be older than 16!')
          .nullable()
          .default(null),
      })}
      onSubmit={(values, { setSubmitting }) => {
        props.onSubmit(values).then(() => setSubmitting(false));
      }}
    >
      {({ values, submitForm, isSubmitting }) => (
        <Form>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} md={3}>
              <Field
                disabled={props.disabled}
                variant="standard"
                component={FormikTextField}
                name="firstName"
                type="text"
                label="First Name"
                style={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Field
                disabled={props.disabled}
                variant="standard"
                component={FormikTextField}
                name="familyName"
                type="text"
                label="Family Name"
                style={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Field
                disabled={props.disabled}
                component={FormikDatePicker}
                name="birthDate"
                label="Birth Date"
                disableFuture
                clearable
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Field
                disabled={props.disabled}
                component={FormikTextField}
                variant="standard"
                name="matriculationNr"
                type="text"
                label="Matriculation Number"
                style={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                disabled={props.disabled}
                variant="standard"
                component={FormikTextField}
                name="previousDegree"
                type="text"
                label="Previous Degree"
                style={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography variant="h3">Emails:</Typography>
              <FieldArray
                name="emails"
                render={(arrayHelpers) => (
                  <Paper sx={{ padding: 2 }}>
                    {values.emails.map((e, k) => (
                      <Box key={'email-' + k} sx={{ paddingTop: 1 }}>
                        <Grid container direction="row" spacing={1}>
                          <Grid item xs={12} md={4}>
                            <Field
                              disabled={props.disabled}
                              variant="standard"
                              component={FormikTextField}
                              name={`emails.${k}.name`}
                              type="text"
                              label="Email"
                              style={{ width: '100%' }}
                            />
                          </Grid>
                          <Grid item xs={12} md={2}>
                            <Field
                              disabled={props.disabled}
                              component={CheckboxWithLabel}
                              type="checkbox"
                              name={`emails.${k}.isActive`}
                              Label={{ label: 'Is Active' }}
                            />
                          </Grid>
                          <Grid item xs={12} md={2}>
                            <Field
                              disabled={props.disabled}
                              component={CheckboxWithLabel}
                              type="checkbox"
                              name={`emails.${k}.isDefault`}
                              Label={{ label: 'Is Default' }}
                            />
                          </Grid>
                          {e.id > 0 && (
                            <Grid item xs={12} md={2}>
                              <Field
                                component={CheckboxWithLabel}
                                type="checkbox"
                                name={`emails.${k}.isPrivate`}
                                Label={{ label: 'Is Private' }}
                                disabled
                              />
                            </Grid>
                          )}
                          <Grid item xs={12} md={1}>
                            {!props.disabled && (
                              <>
                                {!e.id ? (
                                  <Button
                                    variant="contained"
                                    color="inherit"
                                    disabled={props.disabled || isSubmitting || props.loading}
                                    onClick={() => arrayHelpers.remove(k)}
                                    startIcon={<DeleteIcon />}
                                  >
                                    Cancel
                                  </Button>
                                ) : (
                                  <Button
                                    variant="contained"
                                    color="inherit"
                                    disabled={props.disabled || isSubmitting || props.loading}
                                    onClick={() => props.onDeleteEmail(e)}
                                    startIcon={<DeleteIcon />}
                                  >
                                    Delete
                                  </Button>
                                )}
                              </>
                            )}
                          </Grid>
                        </Grid>
                      </Box>
                    ))}
                    {!props.disabled && (
                      <Button
                        variant="contained"
                        color="inherit"
                        disabled={props.disabled || props.loading}
                        startIcon={<AddIcon />}
                        style={{ marginTop: 20 }}
                        onClick={() =>
                          arrayHelpers.push(
                            new EmailDto({
                              id: 0,
                              userId: props.student.id,
                              name: '',
                              isActive: true,
                              isPrivate: false,
                              isDefault: false,
                            } as IEmailDto),
                          )
                        }
                      >
                        Add Email
                      </Button>
                    )}
                  </Paper>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              {!props.disabled && (
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={props.disabled || isSubmitting || props.loading}
                  onClick={submitForm}
                  startIcon={<SaveIcon />}
                >
                  {props.student.id ? <>Update Student Information</> : <>Add Student</>}
                </Button>
              )}

              {!props.disabled &&
                props.student.semesterRegistrations.length === 0 &&
                props.student.emails.length === 0 &&
                !props.student.hasHiddenInformation && (
                  <>
                    {props.student.id ? (
                      <Button
                        variant="contained"
                        color="inherit"
                        disabled={props.disabled || isSubmitting || props.loading}
                        onClick={() => setDeleteDialogOpen(true)}
                        startIcon={<DeleteIcon />}
                      >
                        Delete
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="inherit"
                        disabled={props.disabled || isSubmitting || props.loading}
                        onClick={() => props.onCancel()}
                        startIcon={<DeleteIcon />}
                      >
                        Cancel
                      </Button>
                    )}
                    <ConfirmationDialog
                      title="Delete Student Information"
                      onConfirm={() => props.onDelete(props.student)}
                      open={deleteDialogOpen}
                      onClose={() => setDeleteDialogOpen(false)}
                    >
                      Do you really want to delete all information about the student{' '}
                      <strong>
                        {props.student.firstName} {props.student.familyName}
                      </strong>
                      ?
                    </ConfirmationDialog>
                  </>
                )}
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
