import { IUasDomainDto } from 'app/clients/services';
import React from 'react';
import { ChipSelectionSection } from 'app/shared/layout/ui-elements/chip-selection';

type Props = {
  loading: boolean;
  selectedDomains: IUasDomainDto[];
  domains: IUasDomainDto[];
  disabled: boolean;
  removeDomainAsync: (uas: IUasDomainDto) => Promise<void>;
  addDomainAsync: (uas: IUasDomainDto) => Promise<void>;
};

export const DomainRelationsForm = (props: Props) => {
  return (
    <ChipSelectionSection
      title="Email Domains"
      actionTitle="Add Domain"
      loading={props.loading}
      selectedItems={props.selectedDomains}
      getItemKey={i => i.id}
      getItemTitle={i => i.name}
      onDelete={d => props.removeDomainAsync(d)}
      getColor={d => (d.preferedForStudents ? 'primary' : 'default')}
      onAdd={domain => props.addDomainAsync(domain)}
      items={props.domains}
    />
  );
};
