import AddIcon from '@mui/icons-material/Add';
import { Grid, Paper, Typography } from '@mui/material';
import {
  IAcademicYearDto,
  ICopyModuleStructureDto,
  IModuleCategoryDto,
  IModuleDto,
  IModuleExecutionDto,
} from 'app/clients/services';
import { IRootState } from 'app/config/root.reducer';
import { DefaultButton } from 'app/shared/layout/ui-elements/buttons';
import { MainContent, SideBar, SplitLayout } from 'app/shared/layout/ui-elements/component-layout';
import { NavigationList } from 'app/shared/layout/ui-elements/navigation-list';
import { SelectBox } from 'app/shared/layout/ui-elements/select-box';
import { combineUrlParams, getBasePath } from 'app/shared/util/url-utils';
import React, { useEffect } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import moduleSelectionModule from '../selections/module-selection';
import moduleManagementModule from './modules';
import { CategoryForm } from './parts/category-form';
import { ModuleExecutionsForm } from './parts/module-executions-form';
import { ModuleForm } from './parts/module-form';

export type Props = PropsFromRedux;

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     root: {
//       width: '100%',
//       flexGrow: 1,
//     },
//     formControl: {
//       margin: theme.spacing(1),
//     },
//     selectEmpty: {
//       marginTop: theme.spacing(2),
//     },
//     dataGrid: {
//       '& .MuiDataGrid-cellEditing': {
//         backgroundColor: 'rgb(255,215,115, 0.19)',
//         color: '#1a3e72',
//       },
//       '& .Mui-error': {
//         backgroundColor: `rgb(126,10,15, 0.1)`,
//         color: '#750f0f',
//       },
//     },
//     table: {
//       minWidth: 650,
//     },
//     form: {
//       '& > *': {
//         margin: theme.spacing(1),
//         width: '25ch',
//       },
//     },
//     button: {
//       margin: theme.spacing(1),
//     },
//   }),
// );

const Modules = (props: Props) => {
  const { categoryId, moduleId, academicYearId } = useParams<{
    categoryId: string;
    moduleId: string;
    academicYearId: string;
  }>();
  const navigate = useNavigate();
  const basePath = getBasePath(useLocation(), useParams());

  const refreshUrl = (category: IModuleCategoryDto, module: IModuleDto, academicYear: IAcademicYearDto) => {
    if (category && !!category.id && module && !!module.id && academicYear && !!academicYear.id) {
      navigate(
        basePath +
          combineUrlParams({
            categoryId: category.id,
            moduleId: module.id,
            academicYearId: props.selectedAcademicYear.id,
          }),
      );
    } else if (category && !!category.id && module && !!module.id) {
      navigate(basePath + combineUrlParams({ categoryId: category.id, moduleId: module.id }));
    } else if (category && !!category.id) {
      navigate(basePath + combineUrlParams({ categoryId: category.id }));
    } else {
      navigate(basePath);
    }
  };

  useEffect(() => {
    const loadInitialData = async () => {
      const academicYear = academicYearId
        ? props.academicYears.find((a) => a.id === Number(academicYearId))
        : props.selectedAcademicYear;
      const moduleCategory = categoryId
        ? props.categories.find((c) => c.id === Number(categoryId))
        : props.selectedModuleCategory;
      const module = moduleId ? props.modules.find((c) => c.id === Number(moduleId)) : props.selectedModule;
      await props.setFilterAsync(moduleCategory, module, academicYear);
      refreshUrl(moduleCategory, module, academicYear);
    };

    loadInitialData();
  }, [props.academicYears, props.categories, props.modules]);

  return (
    <SplitLayout>
      <SideBar>
        <NavigationList
          title="Module Structure"
          selectedItem={props.selectedModuleCategory}
          items={props.categories.filter((c) => c.isActive)}
          createUrl={(category) => basePath + combineUrlParams({ categoryId: category.id })}
          onClick={async (category) => {
            props.cancelAdding();
            await props.selectModuleCategoryAsync(category);
            await props.selectModuleAsync(null);
          }}
          getItemKey={(category) => category.id}
          getItemTitle={(category) => category.code}
          renderSubList={(category) => (
            <>
              {props.selectedModuleCategory?.id === category.id &&
                props.modules.filter((m) => m.categoryId === props.selectedModuleCategory.id && m.isActive).length >
                  0 && (
                  <div style={{ paddingLeft: 10, borderLeft: '1px solid lightgrey' }}>
                    <NavigationList
                      title={'Modules-' + category.code}
                      hideTitle
                      selectedItem={props.newModule ? null : props.selectedModule}
                      items={props.modules.filter(
                        (m) => m.categoryId === props.selectedModuleCategory.id && m.isActive,
                      )}
                      onClick={async (module) => {
                        props.cancelAdding();
                        await props.selectModuleAsync(module);
                      }}
                      createUrl={(module) =>
                        basePath +
                        combineUrlParams({
                          categoryId: category.id,
                          moduleId: module.id,
                          academicYearId: props.selectedAcademicYear?.id,
                        })
                      }
                      getItemKey={(module) => module.id}
                      getItemTitle={(module) => module.code}
                    ></NavigationList>
                    <DefaultButton
                      placement="side-bar"
                      disabled={props.loading}
                      startIcon={<AddIcon />}
                      onClick={() => props.addModule(category)}
                    >
                      Add Module
                    </DefaultButton>
                  </div>
                )}
            </>
          )}
        />
        <DefaultButton
          placement="side-bar"
          disabled={props.loading}
          startIcon={<AddIcon />}
          onClick={() => props.addModuleCategory()}
        >
          Add Category
        </DefaultButton>
        {(props.categories.some((c) => !c.isActive) || props.modules.some((c) => !c.isActive)) && (
          <NavigationList
            title="Inactive Modules"
            selectedItem={props.selectedModuleCategory}
            items={props.categories.filter(
              (c) => !c.isActive || props.modules.some((m) => m.categoryId === c.id && !m.isActive),
            )}
            createUrl={(category) => basePath + combineUrlParams({ categoryId: category.id })}
            onClick={async (category) => {
              props.cancelAdding();
              await props.selectModuleCategoryAsync(category);
              await props.selectModuleAsync(null);
            }}
            getItemKey={(category) => category.id}
            getItemTitle={(category) => category.code}
            renderSubList={(category) => (
              <>
                {props.selectedModuleCategory?.id === category.id &&
                  props.modules.filter((m) => m.categoryId === props.selectedModuleCategory.id && !m.isActive).length >
                    0 && (
                    <div style={{ paddingLeft: 10, borderLeft: '1px solid lightgrey' }}>
                      <NavigationList
                        title={'Modules-' + category.code}
                        hideTitle
                        selectedItem={props.newModule ? null : props.selectedModule}
                        items={props.modules.filter(
                          (m) => m.categoryId === props.selectedModuleCategory.id && !m.isActive,
                        )}
                        onClick={async (module) => {
                          props.cancelAdding();
                          await props.selectModuleAsync(module);
                        }}
                        createUrl={(module) =>
                          basePath +
                          combineUrlParams({
                            categoryId: category.id,
                            moduleId: module.id,
                            academicYearId: props.selectedAcademicYear?.id,
                          })
                        }
                        getItemKey={(module) => module.id}
                        getItemTitle={(module) => module.code}
                      ></NavigationList>
                    </div>
                  )}
              </>
            )}
          />
        )}
      </SideBar>
      <MainContent loading={props.loading}>
        {props.newCategory || props.newModule ? (
          <>
            {props.newCategory && !props.newModule && (
              <>
                <Typography variant="h1">New Module Category</Typography>
                <Grid container>
                  <Grid item xs={12} component={Paper} style={{ marginBottom: 20, padding: 10 }}>
                    <CategoryForm
                      category={props.newCategory}
                      onSubmit={async (changedCategory) => {
                        props.cancelAdding();
                        await props.saveModuleCategoryAsync(changedCategory);
                      }}
                      loading={props.loading}
                      onCancel={props.cancelAdding}
                      hasModules={false}
                      categories={props.categories}
                      onDelete={props.deleteModuleCategoryAsync}
                    ></CategoryForm>
                  </Grid>
                </Grid>
              </>
            )}
            {props.newModule && (
              <Grid container>
                <Typography variant="h1">New Module</Typography>
                <Grid item xs={12} component={Paper} style={{ marginBottom: 20, padding: 10 }}>
                  <ModuleForm
                    isNew={true}
                    module={props.newModule}
                    category={props.categories.find((c) => c.id === props.newModule.categoryId)}
                    onSubmit={async (module) => {
                      props.cancelAdding();
                      await props.insertModuleAsync(module);
                    }}
                    loading={props.loading || props.moduleLoading === 0}
                    onCancel={props.cancelAdding}
                    hasRelatedData={false}
                    modules={props.modules.filter((m) => m.categoryId === props.selectedModuleCategory.id)}
                    onDelete={props.deleteModuleAsync}
                  ></ModuleForm>
                </Grid>
              </Grid>
            )}
          </>
        ) : (
          <>
            {props.selectedModule && (
              <>
                <Typography variant="h1">Module: {props.selectedModule.code}</Typography>
                <Grid container>
                  <Grid item xs={12} component={Paper} style={{ marginBottom: 20, padding: 10 }}>
                    <ModuleForm
                      isNew={false}
                      module={props.selectedModule}
                      category={props.categories.find((c) => c.id === props.selectedModule.categoryId)}
                      onSubmit={props.updateModuleAsync}
                      loading={props.loading || props.moduleLoading === props.selectedModule.id}
                      onCancel={props.cancelAdding}
                      hasRelatedData={props.selectedModule.hasRelatedData}
                      modules={props.modules.filter((m) => m.categoryId === props.selectedModuleCategory.id)}
                      onDelete={props.deleteModuleAsync}
                    ></ModuleForm>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <SelectBox
                      items={props.academicYears}
                      selectedItem={props.selectedAcademicYear}
                      title="Academic Year"
                      getItemTitle={(academicYear) => academicYear.name}
                      getItemKey={(academicYear) => academicYear.id}
                      onChange={props.changeAcademicYearAsync}
                    ></SelectBox>
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: 10 }}>
                    <Typography variant="h3">
                      Module Executions: {props.selectedModule.code} - {props.selectedAcademicYear.name}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} component={Paper} style={{ marginBottom: 20, padding: 10 }}>
                    <ModuleExecutionsForm
                      locations={props.locations}
                      semesters={props.semesters.filter((s) => s.academicYearId === props.selectedAcademicYear.id)}
                      executions={props.executions.filter((e) => e.moduleId === props.selectedModule.id)}
                      module={props.selectedModule}
                      category={props.categories.find((c) => c.id === props.selectedModule.categoryId)}
                      onSubmit={(values) =>
                        props.saveModuleExecutionsAsync(
                          values.executions,
                          props.selectedModule,
                          props.selectedAcademicYear,
                        )
                      }
                      moduleLoading={props.loading || props.moduleLoading === props.selectedModule.id}
                      moduleExecutionLoading={props.moduleExecutionLoading}
                      onDelete={props.deleteModuleExecutionAsync}
                    ></ModuleExecutionsForm>
                  </Grid>
                </Grid>
              </>
            )}
            {!props.selectedModule && props.selectedModuleCategory && (
              <>
                <Typography variant="h1">Module Category: {props.selectedModuleCategory.code}</Typography>
                <Grid container>
                  <Grid item xs={12} component={Paper} style={{ marginBottom: 20, padding: 10 }}>
                    <CategoryForm
                      category={props.selectedModuleCategory}
                      onSubmit={(changedCategory) => props.saveModuleCategoryAsync(changedCategory)}
                      loading={props.loading}
                      onCancel={props.cancelAdding}
                      hasModules={
                        props.modules.filter((m) => m.categoryId === props.selectedModuleCategory.id).length > 0
                      }
                      categories={props.categories}
                      onDelete={props.deleteModuleCategoryAsync}
                    ></CategoryForm>
                  </Grid>
                </Grid>
              </>
            )}
          </>
        )}
      </MainContent>
    </SplitLayout>
  );
};

const mapStateToProps = ({ moduleManagement, moduleSelection, semesterSelection }: IRootState) => ({
  loading: moduleManagement.loading,
  selectedAcademicYear: semesterSelection.selectedAcademicYear,
  selectedModuleCategory: moduleSelection.selectedCategory,
  selectedModule: moduleSelection.selectedModule,
  executions:
    semesterSelection.selectedAcademicYear &&
    moduleSelection.selectedCategory &&
    moduleSelection.selectedModule &&
    moduleSelection.executions &&
    moduleSelection.executions[semesterSelection.selectedAcademicYear.id]
      ? moduleSelection.executions[semesterSelection.selectedAcademicYear.id].filter(
          (e) =>
            e.categoryId === moduleSelection.selectedCategory?.id && e.moduleId === moduleSelection.selectedModule.id,
        )
      : [],
  academicYears:
    semesterSelection.semesterFilter?.academicYears?.sort((a1, a2) => (a1.startYear < a2.startYear ? 1 : -1)) ?? [],
  semesters: semesterSelection.selectedAcademicYear
    ? semesterSelection.semesterFilter?.semesters?.filter(
        (s) => s.academicYearId === semesterSelection.selectedAcademicYear?.id,
      ) ?? []
    : [],
  categories: moduleSelection.moduleCategories,
  modules: moduleSelection.modules,
  newCategory: moduleManagement.newModuleCategory,
  newModule: moduleManagement.newModule,
  moduleLoading: moduleManagement.moduleLoading,
  moduleExecutionLoading: moduleManagement.moduleExecutionLoading,
  locations: moduleSelection.locations,
});

const mapDispatchToProps = (dispatch) => {
  const actions = moduleManagementModule.initActions();
  const selectionActions = moduleSelectionModule.initActions();
  return {
    setFilterAsync: async (category: IModuleCategoryDto, module: IModuleDto, academicYear: IAcademicYearDto) =>
      await dispatch(selectionActions.setFilterAsync(category, module, null, academicYear)),
    changeAcademicYearAsync: async (academicYear: IAcademicYearDto) =>
      await dispatch(selectionActions.changeSemesterFilterAsync(academicYear, null)),
    cancelAdding: () => dispatch(actions.cancelAdding()),

    selectModuleCategoryAsync: async (category: IModuleCategoryDto) =>
      await dispatch(selectionActions.selectModuleCategoryAsync(category)),
    addModuleCategory: () => dispatch(actions.addModuleCategory()),
    saveModuleCategoryAsync: async (category: IModuleCategoryDto) =>
      await dispatch(actions.saveModuleCategoryAsync(category)),
    deleteModuleCategoryAsync: async (category: IModuleCategoryDto) =>
      await dispatch(actions.deleteModuleCategoryAsync(category)),

    selectModuleAsync: async (module: IModuleDto) => await dispatch(selectionActions.selectModuleAsync(module)),
    selectModule: (module: IModuleDto) => dispatch(selectionActions.selectModuleAsync(module)),
    addModule: (module: IModuleDto) => dispatch(actions.addModule(module)),

    insertModuleAsync: async (module: IModuleDto) => await dispatch(actions.insertModuleAsync(module)),
    updateModuleAsync: async (module: IModuleDto) => await dispatch(actions.updateModuleAsync(module)),
    deleteModuleAsync: async (module: IModuleDto) => await dispatch(actions.deleteModuleAsync(module)),

    copyAcademicYearAsync: async (options: ICopyModuleStructureDto) =>
      await dispatch(actions.copyAcademicYearAsync(options)),

    saveModuleExecutionsAsync: async (
      moduleExecutions: IModuleExecutionDto[],
      module: IModuleDto,
      academicYear: IAcademicYearDto,
    ) => await dispatch(actions.saveModuleExecutionsAsync(moduleExecutions, module, academicYear)),
    deleteModuleExecutionAsync: async (moduleExecution: IModuleExecutionDto) =>
      await dispatch(actions.deleteModuleExecutionAsync(moduleExecution)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Modules);
