import {
  Alert,
  AlertTitle,
  Button,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  LinearProgress,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import {
  DatabaseMappingOfNullableLong,
  DatabaseMappingOfString,
  ExecutedSolutionOfNullableLong,
  ExecutedSolutionOfString,
  IDatabaseMappingOfNullableLong,
  IDatabaseMappingOfString,
  MappingActionType,
  MappingPossibilityOfNullableLong,
  MappingPossibilityOfString,
  ValidationLevel,
} from 'app/clients/services';
import { APP_DATE_FORMAT } from 'app/config/constants';
import {
  getValidationLevelColor as getValidationSeverity,
  isHigherOrEqual,
} from 'app/shared/util/validation-level.util';
import React, { useState } from 'react';
import { DbMapping } from './types';

type DbMappingProps = {
  dbMapping: DbMapping<string | number>;
  disabled?: boolean;
  onConfirmLongMapping?: (newMapping: IDatabaseMappingOfNullableLong) => void | Promise<void>;
  onConfirmStringMapping?: (newMapping: IDatabaseMappingOfString) => void | Promise<void>;
  isRegistrationAdmin?: boolean;
  isLoading: boolean;
  filter: ValidationLevel;
  id: string;
  title: string;
};

export const DbMappingForm = (props: DbMappingProps) => {
  const getCurrentSolution = () => {
    if (props.dbMapping?.executedSolution) {
      return props.dbMapping.solutions.find((s) => s.type === props.dbMapping.executedSolution.type);
    }
    return null;
  };

  const getCurrentPossibility = () => {
    const solution = props.dbMapping?.solutions?.find((s) => s.type === MappingActionType.SelectPossibility);
    if (solution && props.dbMapping?.executedSolution?.type === MappingActionType.SelectPossibility) {
      return props.dbMapping.possibilities?.find((p) => p.databaseId === props.dbMapping.databaseId);
    }
    return null;
  };

  const [comment, setComment] = useState(props.dbMapping?.confirmationComment);
  const [selectedSolution, setSelectedSolution] = useState(getCurrentSolution());
  const [selectedPossibility, setSelectedPossibility] = useState(getCurrentPossibility());

  React.useEffect(() => {
    setComment(props.dbMapping?.confirmationComment);
    setSelectedSolution(getCurrentSolution());
    setSelectedPossibility(getCurrentPossibility());
  }, [props.dbMapping]);

  const canConfirm = () => {
    if (props.disabled || props.isLoading) {
      return false;
    }

    if (selectedSolution) {
      if (selectedSolution.type !== MappingActionType.SelectPossibility) {
        return true;
      }

      if (selectedPossibility) {
        return true;
      }
    }

    return false;
  };

  const handleConfirm = () => {
    if (canConfirm()) {
      if (props.onConfirmLongMapping) {
        const newDbMapping = new DatabaseMappingOfNullableLong({
          ...props.dbMapping,
          databaseId: props.dbMapping.databaseId ? Number(props.dbMapping.databaseId) : null,
          oldDatabaseId: props.dbMapping.oldDatabaseId ? Number(props.dbMapping.oldDatabaseId) : null,
          identifier: props.dbMapping.identifier?.toString(),
          oldIdentifier: props.dbMapping.oldIdentifier?.toString(),
          isUserConfirmed: true,
          validationLevel: ValidationLevel.Info,
          executedSolution: new ExecutedSolutionOfNullableLong({
            type: selectedSolution.type,
            databaseId: selectedPossibility?.databaseId ? Number(selectedPossibility?.databaseId) : null,
          }),
          possibilities: props.dbMapping.possibilities.map(
            (p) =>
              new MappingPossibilityOfNullableLong({
                distance: p.distance,
                display: p.display,
                databaseId: Number(p.databaseId),
              }),
          ),
          wasJustConfirmed: true,
          confirmationComment: comment,
          isNew: selectedSolution.type === MappingActionType.ConfirmNew,
        });

        if (selectedPossibility) {
          newDbMapping.databaseId = selectedPossibility?.databaseId ? Number(selectedPossibility?.databaseId) : null;
          newDbMapping.identifier = selectedPossibility?.display;
        }
        props.onConfirmLongMapping(newDbMapping);
      } else if (props.onConfirmStringMapping) {
        const newDbMapping = new DatabaseMappingOfString({
          ...props.dbMapping,
          databaseId: props.dbMapping.databaseId?.toString(),
          oldDatabaseId: props.dbMapping.oldDatabaseId?.toString(),
          identifier: props.dbMapping.identifier?.toString(),
          oldIdentifier: props.dbMapping.oldIdentifier?.toString(),
          isUserConfirmed: true,
          validationLevel: ValidationLevel.Info,
          executedSolution: new ExecutedSolutionOfString({
            type: selectedSolution.type,
            databaseId: selectedPossibility?.databaseId?.toString(),
          }),
          possibilities: props.dbMapping.possibilities.map(
            (p) =>
              new MappingPossibilityOfString({
                distance: p.distance,
                display: p.display,
                databaseId: p.databaseId.toString(),
              }),
          ),
          wasJustConfirmed: true,
          confirmationComment: comment,
          isNew: selectedSolution.type === MappingActionType.ConfirmNew,
        });

        if (selectedPossibility) {
          newDbMapping.databaseId = selectedPossibility?.databaseId?.toString();
          newDbMapping.identifier = selectedPossibility?.display;
        }
        props.onConfirmStringMapping(newDbMapping);
      }
    }
  };

  const handleChange = (_event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    if (value) {
      if (isNaN(value as any)) {
        const solution = props.dbMapping.solutions.find((s) => s.type.toString() === value);
        if (solution) {
          setSelectedPossibility(null);
          setSelectedSolution(solution);
          return;
        }
      }

      const possibility =
        props.dbMapping.possibilities.find((p) => p.databaseId === Number(value) || p.databaseId === value) ?? null;
      if (possibility) {
        setSelectedPossibility(possibility);
        const solution = props.dbMapping.solutions.find((s) => s.type === MappingActionType.SelectPossibility);
        setSelectedSolution(solution);
        return;
      }
    }
    setSelectedSolution(null);
    setSelectedPossibility(null);
  };

  if (
    !props.dbMapping ||
    (!props.dbMapping.wasJustConfirmed && !isHigherOrEqual(props.dbMapping.validationLevel, props.filter)) ||
    !(props.dbMapping.conflictMessage ?? props.dbMapping.confirmationComment)
  ) {
    return <></>;
  }

  if ((props.dbMapping.wasJustConfirmed || props.dbMapping.isUserConfirmed) && selectedSolution) {
    return (
      <>
        <Paper>
          <Alert severity={getValidationSeverity(ValidationLevel.Info)}>
            <AlertTitle>
              {props.title}
              {': '}
              {props.dbMapping.code > 0 && <>Confirmation {props.dbMapping.code}</>}
            </AlertTitle>
            {props.dbMapping.conflictMessage ?? props.dbMapping.confirmationComment}
          </Alert>
          <Typography>
            <div style={{ margin: 10 }}>
              <FormControl component="fieldset" disabled>
                <RadioGroup
                  id={props.id + '-db-mapping-options'}
                  value={
                    selectedSolution.type === MappingActionType.SelectPossibility
                      ? selectedPossibility?.databaseId
                      : selectedSolution.type
                  }
                >
                  {selectedSolution.type === MappingActionType.SelectPossibility ? (
                    <FormControlLabel
                      key={selectedPossibility?.databaseId}
                      value={selectedPossibility?.databaseId}
                      control={
                        <Radio disabled id={props.id + '-db-mapping-option-' + selectedPossibility?.databaseId} />
                      }
                      label={selectedSolution.message.replace('{possibility}', selectedPossibility?.display)}
                    />
                  ) : (
                    <FormControlLabel
                      key={selectedSolution.type}
                      value={selectedSolution.type}
                      control={<Radio disabled id={props.id + '-db-mapping-option-' + selectedSolution.type} />}
                      label={selectedSolution.message}
                    />
                  )}
                </RadioGroup>
              </FormControl>
              {props.dbMapping.confirmationComment && (
                <FormControl style={{ marginBottom: 5, width: '100%' }}>
                  <InputLabel htmlFor={props.id + '-db-mapping-comment'}>Comment (optional)</InputLabel>
                  <Input
                    id={props.id + '-db-mapping-comment'}
                    value={props.dbMapping.confirmationComment}
                    onChange={(event) => {
                      setComment(event.target.value);
                    }}
                    disabled
                  />
                </FormControl>
              )}
              {props.dbMapping.isUserConfirmed && props.dbMapping.confirmedByName && (
                <div>
                  Confirmed By: {props.dbMapping.confirmedByName}
                  <br />
                  Confirmed Date: {props.dbMapping.confirmedDate?.toFormat(APP_DATE_FORMAT)}
                </div>
              )}
            </div>
            {props.isLoading && <LinearProgress />}
          </Typography>
        </Paper>
      </>
    );
  }

  return (
    <>
      <Paper>
        <Alert severity={getValidationSeverity(props.dbMapping.validationLevel)}>
          <AlertTitle>
            {props.title}
            {': '}
            {props.dbMapping.code > 0 && (
              <>
                {props.dbMapping.validationLevel.toString()} {props.dbMapping.code}
              </>
            )}
          </AlertTitle>
          {props.dbMapping.conflictMessage}
        </Alert>
        <Typography>
          {props.dbMapping.solutions && props.dbMapping.solutions.length > 0 && (
            <div style={{ margin: 10 }}>
              <FormControl component="fieldset" disabled={props.disabled}>
                <RadioGroup
                  id={props.id + '-db-mapping-options'}
                  value={(selectedPossibility ? selectedPossibility.databaseId : selectedSolution?.type) ?? null}
                  onChange={handleChange}
                >
                  {props.dbMapping.solutions.map((s, k) => (
                    <>
                      {s.type !== MappingActionType.SelectPossibility ? (
                        (!s.needsAdminRights || (props.isRegistrationAdmin && props.dbMapping)) && (
                          <FormControlLabel
                            key={k}
                            value={s.type}
                            control={
                              <Radio
                                disabled={props.disabled || props.isLoading}
                                id={props.id + '-db-mapping-option-' + s.type}
                              />
                            }
                            label={s.message}
                          />
                        )
                      ) : (
                        <>
                          {props.dbMapping.possibilities?.map((p, i) => (
                            <FormControlLabel
                              key={k + i * 20}
                              value={p.databaseId}
                              control={<Radio disabled={props.disabled || props.isLoading} />}
                              id={props.id + '-db-mapping-option-' + p.databaseId}
                              label={s.message.replace('{possibility}', p.display)}
                            />
                          ))}
                        </>
                      )}
                    </>
                  ))}
                </RadioGroup>
              </FormControl>
              {(!props.dbMapping.isUserConfirmed || props.dbMapping.confirmationComment) && (
                <FormControl style={{ width: '100%' }}>
                  <InputLabel htmlFor={props.id + '-db-mapping-comment'}>Comment (optional)</InputLabel>
                  <Input
                    id={props.id + '-db-mapping-comment'}
                    value={props.dbMapping.confirmationComment}
                    onChange={(event) => {
                      setComment(event.target.value);
                    }}
                    disabled={props.disabled}
                  />
                </FormControl>
              )}
              {!props.dbMapping.isUserConfirmed && (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!canConfirm()}
                  onClick={handleConfirm}
                  style={{ marginTop: 10, marginBottom: 5 }}
                >
                  Confirm
                </Button>
              )}

              {props.dbMapping.isUserConfirmed && props.dbMapping.confirmedByName && (
                <div>
                  Confirmed By: {props.dbMapping.confirmedByName}
                  <br />
                  Confirmed Date: {props.dbMapping.confirmedDate?.toFormat(APP_DATE_FORMAT)}
                </div>
              )}
            </div>
          )}
          {props.isLoading && <LinearProgress />}
        </Typography>
      </Paper>
    </>
  );
};
