import AddIcon from '@mui/icons-material/Add';
import { Button, Grid, Paper, Typography } from '@mui/material';
import { IUasDto, UasDto } from 'app/clients/services';
import { IRootState } from 'app/config/root.reducer';
import { MainContent, SideBar, SplitLayout } from 'app/shared/layout/ui-elements/component-layout';
import { NavigationList } from 'app/shared/layout/ui-elements/navigation-list';
import { combineUrlParams, getBasePath } from 'app/shared/util/url-utils';
import React, { useEffect } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { DomainRelationsForm } from './parts/domain-relations-form';
import { UasForm } from './parts/uas-form';
import uasManagementModule from './uas';

export type Props = PropsFromRedux;

export const Uas = (props: Props) => {
  const { uasId } = useParams<{ uasId: string }>();
  const navigate = useNavigate();
  const basePath = getBasePath(useLocation(), useParams());

  useEffect(() => {
    const loadInitialData = async () => {
      await props.loadAllDomainsAsync();
      if (uasId && Number(uasId) !== props.selectedUas?.id) {
        props.selectUas(props.uas.find((u) => u.id === Number(uasId)) ?? null);
      }
    };
    loadInitialData();
  }, [uasId]);

  const handleUasChange = async (uas: IUasDto) => {
    if (uas) {
      navigate(basePath + combineUrlParams({ id: uas.id }));
      await props.loadDomainsForUasAsync(uas);
      props.selectUas(uas);
    } else {
      navigate(basePath);
      props.selectUas(null);
    }
  };

  return (
    <SplitLayout>
      <SideBar>
        <NavigationList
          title="UAS"
          selectedItem={props.selectedUas}
          items={props.uas?.filter((p) => p.isActive) ?? []}
          createUrl={(item) => basePath + combineUrlParams({ uasId: item.id })}
          onClick={(item) => handleUasChange(item)}
          getItemKey={(item) => item.id}
          getItemTitle={(item) => item.code}
        />
        {props.uas && props.uas.filter((p) => !p.isActive).length > 0 && (
          <NavigationList
            title="Inactive UAS"
            selectedItem={props.selectedUas}
            items={props.uas.filter((p) => !p.isActive)}
            createUrl={(item) => basePath + combineUrlParams({ uasId: item.id })}
            onClick={(item) => handleUasChange(item)}
            getItemKey={(item) => item.id}
            getItemTitle={(item) => item.code}
          />
        )}
        <Button
          variant="contained"
          disabled={props.loading}
          startIcon={<AddIcon />}
          sx={{ padding: 1, marginLeft: 2, marginBottom: 2 }}
          color="inherit"
          onClick={async () => {
            navigate(basePath);
            const newUas = new UasDto({ id: 0, isActive: true, code: '', hasDependentData: false });
            props.selectUas(newUas);
            await props.loadDomainsForUasAsync(newUas);
          }}
        >
          Add
        </Button>
      </SideBar>
      <MainContent loading={props.loading}>
        {props.selectedUas && (
          <>
            <Typography variant={'h1'}>UAS: {props.selectedUas.code}</Typography>
            <Grid container>
              <Grid item xs={12} component={Paper} style={{ marginBottom: 20, padding: 10 }}>
                <UasForm
                  loading={props.loading}
                  uas={props.selectedUas}
                  allUas={props.uas}
                  onSubmit={async (uas) => {
                    await props.saveUasAsync(uas);
                  }}
                  onDelete={async (uas) => {
                    await props.deleteUasAsync(uas);
                  }}
                  onCancel={() => {
                    navigate(basePath);
                  }}
                />
              </Grid>
              {props.selectedUas.id > 0 && (
                <Grid item xs={12}>
                  <DomainRelationsForm
                    loading={props.domainsLoading}
                    selectedDomains={props.domainsForUas}
                    domains={props.allDomains}
                    disabled={false}
                    addDomainAsync={async (domain) => {
                      await props.addDomainAsync(props.selectedUas, domain);
                    }}
                    removeDomainAsync={async (domain) => {
                      await props.removeDomainAsync(props.selectedUas, domain);
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </>
        )}
      </MainContent>
    </SplitLayout>
  );
};

const mapStateToProps = ({ uasManagement, auth }: IRootState) => ({
  loading: uasManagement.loading,
  domainsLoading: uasManagement.domainsLoading,
  uas: auth.allowedUas,
  selectedUas: uasManagement.selectedUas,
  allDomains: uasManagement.domains,
  domainsForUas:
    uasManagement.selectedUas && uasManagement.domainsForUas[uasManagement.selectedUas.id]
      ? uasManagement.domainsForUas[uasManagement.selectedUas.id]
      : [],
});

const mapDispatchToProps = (dispatch) => {
  const actions = uasManagementModule.initActions(dispatch);
  return actions;
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Uas);
