import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Checkbox, Grid, List, ListItem, Typography } from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import { IAdvisorDto, IUasDto, RoleType } from 'app/clients/services';
import { IRootState } from 'app/config/root.reducer';
import { StudentManagementRoute } from 'app/routes';
import { hasAnyRole } from 'app/shared/auth/roles';
import { ChipList } from 'app/shared/layout/ui-elements/chip-list';
import { FullSizeLayout, MainContent, SideBar, SplitLayout } from 'app/shared/layout/ui-elements/component-layout';
import { NavigationList } from 'app/shared/layout/ui-elements/navigation-list';
import { StandardDataGrid } from 'app/shared/layout/ui-elements/standard-data-grid';
import { combineUrlParams, getBasePath } from 'app/shared/util/url-utils';
import React, { useEffect } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { AdvisorDto } from '../../clients/services';
import { AdvisorForm } from './parts/advisor-form';
import { UasRelationsForm } from './parts/uas-relations-form';
import uasManagementModule from './uas';

export type Props = PropsFromRedux;

export const Advisors = (props: Props) => {
  const { uasId, advisorId } = useParams<{ uasId?: string; advisorId?: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const basePath = getBasePath(location, useParams());

  const isNoUas = props.isAdmin && location.pathname.startsWith(basePath + combineUrlParams({ uasId: 'no-uas' }));

  useEffect(() => {
    const loadInitialData = async () => {
      if (props.allowedUas?.length === 1) {
        const onlyUas = props.allowedUas[0];

        await props.getAdvisorsAsync(onlyUas);
        props.selectUas(onlyUas);
        return;
      }

      if (isNoUas) {
        const advisors = await props.loadAdvisorsWithNoUasAsync();
        props.selectUas(null);
        if (advisorId && advisorId !== props.selectedAdvisor?.id) {
          props.selectAdvisor(advisors.find((a) => a.id === advisorId) ?? null);
        }
        return;
      }

      if (uasId && Number(uasId) !== props.selectedUas?.id) {
        const uas = props.allowedUas.find((u) => u.id === Number(uasId)) ?? null;
        const advisors = await props.getAdvisorsAsync(uas);
        props.selectUas(uas);
        if (advisorId && advisorId !== props.selectedAdvisor?.id) {
          props.selectAdvisor(advisors.find((a) => a.id === advisorId) ?? null);
        }
      }
    };
    loadInitialData();
  }, []);

  const handleUasChange = async (uas: IUasDto) => {
    if (props.allowedUas?.length === 1) {
      const onlyUas = props.allowedUas[0];
      await props.getAdvisorsAsync(onlyUas);
      props.selectUas(onlyUas);
      props.selectAdvisor(null);
      navigate(basePath + combineUrlParams({ uasId: onlyUas.id }));
      return;
    }

    if (uas) {
      await props.getAdvisorsAsync(uas);
      props.selectUas(uas);
      props.selectAdvisor(null);
      navigate(basePath + combineUrlParams({ uasId: uas.id }));
    } else {
      props.selectUas(null);
      props.selectAdvisor(null);
      navigate(basePath);
    }
  };

  const setWithoutUas = async () => {
    if (props.isAdmin) {
      navigate(basePath + combineUrlParams({ uasId: 'no-uas' }));
      props.selectUas(null);
      props.selectAdvisor(null);
      await props.loadAdvisorsWithNoUasAsync();
    }
  };

  const handleAdvisorChange = (selectedAdvisorId: string) => {
    if (selectedAdvisorId) {
      if (props.selectedAdvisor?.id !== selectedAdvisorId) {
        const advisor = props.selectedUas
          ? props.advisors.find((a) => a.id === selectedAdvisorId)
          : props.advisorsWithNoUasRelation.find((a) => a.id === selectedAdvisorId);
        props.selectAdvisor(advisor ?? null);
        navigate(
          basePath + combineUrlParams({ uasId: props.selectedUas?.id ?? 'no-uas', advisorId: selectedAdvisorId }),
        );
      }
    } else {
      if (props.selectedAdvisor) {
        if (props.selectedAdvisor.id) {
          props.selectAdvisor(null);
        } else {
          return;
        }
      }

      navigate(basePath + combineUrlParams({ uasId: props.selectedUas?.id ?? 'no-uas' }));
    }
  };

  const getStudents = (advisor: IAdvisorDto) => {
    const mappedIds =
      advisor.advisorOf?.filter((s) => isNoUas || s.uasId === props.selectedUas?.id).map((s) => s.studentId) ?? [];
    const uniqueIds = Array.from(new Set(mappedIds));
    return uniqueIds.map((id) => {
      const first = advisor.advisorOf?.find((s) => s.studentId === id);
      return {
        name: first?.studentName,
        id,
        isActive: advisor.advisorOf?.filter((s) => s.studentId === id && s.isActive).length,
      };
    });
  };

  const columns: GridColDef[] = [
    { field: 'firstName', headerName: 'First Name', width: 200, sortable: true },
    { field: 'familyName', headerName: 'Family Name', width: 200, sortable: true },
    {
      field: 'isActive',
      headerName: 'Is Active',
      width: 120,
      sortable: true,
      renderCell: (params: GridCellParams) => <Checkbox checked={params.value === true} disabled />,
    },
  ];

  const rows = location.pathname.startsWith(`${basePath}no-uas`)
    ? props.advisorsWithNoUasRelation
    : props.advisors ?? [];

  const mainContent = (
    <>
      {(props.selectedUas || isNoUas) && (
        <>
          <Typography variant={'h1'}>Advisors: {props.selectedUas?.code && <>{props.selectedUas?.code}</>}</Typography>
          <Grid container>
            <Grid item xs={12} md={props.selectedAdvisor ? 4 : 12}>
              <StandardDataGrid
                rows={rows}
                columns={columns}
                loading={props.loading}
                selectionModel={props.selectedAdvisor ? [props.selectedAdvisor.id] : []}
                onSelectionModelChange={(newSelection) => {
                  const selectedAdvisorId = newSelection[0] ? (newSelection[0] as string) : '';
                  handleAdvisorChange(selectedAdvisorId);
                }}
                columnVisibilityModel={{}}
              />
              <Button
                variant="contained"
                color="inherit"
                disabled={props.loading}
                style={{ marginTop: 20 }}
                startIcon={<AddIcon />}
                onClick={() => {
                  const newAdvisor = new AdvisorDto({
                    id: '',
                    isActive: true,
                    firstName: '',
                    familyName: '',
                    advisorOf: [],
                    emails: [],
                    uas: [],
                  });
                  props.selectAdvisor(newAdvisor);
                }}
              >
                Add
              </Button>
            </Grid>
            {props.selectedAdvisor && (
              <Grid item xs={12} md={8}>
                <Grid container>
                  <Grid item xs={12} style={{ marginBottom: 20, padding: 10 }}>
                    <AdvisorForm
                      loading={props.loading}
                      advisor={props.selectedAdvisor}
                      onSubmit={async (advisor: IAdvisorDto) => {
                        await props.saveAdvisorAsync(props.selectedUas, advisor);
                      }}
                      onDelete={async (advisor: IAdvisorDto) => {
                        await props.deleteAdvisorAsync(props.selectedUas, advisor);
                      }}
                      validateEmailAsync={async (email: string) =>
                        await props.emailAlreadyExistsAsync(props.selectedAdvisor.id, email)
                      }
                    />
                  </Grid>
                  {props.allowedUas.length > 1 && props.selectedAdvisor.id && (
                    <Grid item xs={12} style={{ marginBottom: 20, padding: 10 }}>
                      <UasRelationsForm
                        loading={props.domainsLoading}
                        selectedUas={props.allowedUas.filter(
                          (u) => props.selectedAdvisor.uas?.filter((au) => au.id === u.id).length,
                        )}
                        allUas={props.allowedUas}
                        disabled={props.allowedUas.length < 2}
                        removeUasAsync={async (uas) => {
                          await props.removeUasRelationFromAdvisorAsync(uas, props.selectedAdvisor);
                        }}
                        addUasAsync={async (uas) => {
                          await props.addUasRelationToAdvisorAsync(uas, props.selectedAdvisor);
                        }}
                      />
                    </Grid>
                  )}
                  {props.selectedAdvisor && props.selectedAdvisor.advisorOf?.length > 0 && (
                    <Grid item xs={12} style={{ marginBottom: 20, padding: 10 }}>
                      <ChipList
                        title="Students"
                        items={getStudents(props.selectedAdvisor)}
                        getColor={(s) => (s.isActive ? 'primary' : 'default')}
                        createUrl={(s) => `${StudentManagementRoute}/student/${s.id}`}
                        onClick={() => {}}
                        getItemTitle={(s) => s.name}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </>
      )}
    </>
  );

  const sidebar = (
    <SideBar>
      <NavigationList
        title="UAS"
        selectedItem={!location.pathname.startsWith(`${basePath}no-uas`) ? props.selectedUas : null}
        items={props.allowedUas?.filter((p) => p.isActive) ?? []}
        createUrl={(item) => basePath + combineUrlParams({ uasId: item.id })}
        onClick={(item) => handleUasChange(item)}
        getItemKey={(item) => item.id}
        getItemTitle={(item) => item.code}
      />
      {props.isAdmin && (
        <List style={{ marginTop: 10 }}>
          <ListItem
            component={Link}
            to={`${basePath}no-uas`}
            type="submit"
            button
            selected={location.pathname.startsWith(`${basePath}no-uas`)}
            onClick={setWithoutUas}
          >
            <Box display="flex">
              <Box flexGrow={1}>
                <Typography style={{ fontSize: 14, fontWeight: 400 }}>Advisors without Relation</Typography>
              </Box>
            </Box>
          </ListItem>
        </List>
      )}
    </SideBar>
  );

  return (
    <>
      {props.allowedUas && (
        <>
          {props.allowedUas.length > 1 ? (
            <SplitLayout>
              {sidebar}
              <MainContent loading={props.loading}>{mainContent}</MainContent>
            </SplitLayout>
          ) : (
            <FullSizeLayout loading={props.loading}>{mainContent}</FullSizeLayout>
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = ({ advisorSelection, uasManagement, auth }: IRootState) => ({
  loading: uasManagement.loading,
  domainsLoading: uasManagement.domainsLoading,
  allowedUas: auth.allowedUas,
  selectedAdvisor: uasManagement.selectedAdvisor,
  selectedUas: uasManagement.selectedUas,
  advisors:
    uasManagement.selectedUas && advisorSelection.advisors[uasManagement.selectedUas.id]
      ? advisorSelection.advisors[uasManagement.selectedUas.id]
      : [],
  advisorsWithNoUasRelation: uasManagement.advisorsWithNoUasRelation,
  isAdmin: hasAnyRole(auth.account?.roles, [RoleType.REGISTRATIONADMIN, RoleType.STUDENTADMIN]),
});

const mapDispatchToProps = (dispatch) => {
  const actions = uasManagementModule.initActions(dispatch);
  return {
    selectUas: actions.selectUas,
    selectAdvisor: actions.selectAdvisor,
    saveAdvisorAsync: actions.saveAdvisorAsync,
    deleteAdvisorAsync: actions.deleteAdvisorAsync,
    addUasRelationToAdvisorAsync: actions.addUasRelationToAdvisorAsync,
    removeUasRelationFromAdvisorAsync: actions.removeUasRelationFromAdvisorAsync,
    getAdvisorsAsync: actions.loadAdvisorsAsync,
    emailAlreadyExistsAsync: actions.emailAlreadyExistsAsync,
    loadAdvisorsWithNoUasAsync: actions.loadAdvisorsWithNoUasAsync,
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Advisors);
