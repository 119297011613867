import { RoleType } from 'app/clients/services';
import Guarded from 'app/shared/auth/guarded-component';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import RegistrationExport from './export';
import ModuleRegistrationTabs from './module-registrations-tabs';
import Prioritization from './prioritization';
import Summary from './summary';
import UasRegistrations from './uas-registrations';

const ModuleRegistrationRoutes = ({ baseUrl }) => {
  return (
    <>
      <ModuleRegistrationTabs baseUrl={baseUrl} />
      <div style={{ marginTop: 5 }}>
        <Routes>
          <Route
            path={'modules/:id?'}
            element={
              <Guarded requiresRole={[RoleType.REGISTRATIONADMIN]}>
                <Prioritization />
              </Guarded>
            }
          />
          <Route
            path={'uas/:sId?/:uasId?'}
            element={
              <Guarded requiresRole={[RoleType.REGISTRATIONADMIN, RoleType.MASTEROFFICE]}>
                <UasRegistrations />
              </Guarded>
            }
          />
          <Route
            path={'export/:id?'}
            element={
              <Guarded requiresRole={[RoleType.REGISTRATIONADMIN, RoleType.MASTEROFFICE]}>
                <RegistrationExport />
              </Guarded>
            }
          />
          <Route
            path={'summary'}
            element={
              <Guarded requiresRole={[RoleType.REGISTRATIONADMIN]}>
                <Summary />
              </Guarded>
            }
          />
        </Routes>
      </div>
    </>
  );
};

export default ModuleRegistrationRoutes;
