export function updateItem<T extends { id?: any }>(array: T[], changedItem: T) {
  return array.map(item => {
    return item.id !== changedItem.id ? item : changedItem;
  });
}

export function insertItem<T extends { id?: any }>(array: T[], newItem: T) {
  return [...array, newItem];
}

export function removeItem<T extends { id?: any }>(array: T[], idToRemove?: any) {
  return array.filter(item => item.id !== idToRemove);
}
