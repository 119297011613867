import { Box, Checkbox, Chip, FormControlLabel, Grid, LinearProgress, Paper, Typography } from '@mui/material';
import { IAccountInfoDto, IRoleDto, IUasDto, IUserRoleDto, RoleType } from 'app/clients/services';
import { SelectBox } from 'app/shared/layout/ui-elements/select-box';
import React from 'react';
import { IRoleInfoDto } from '../../../clients/services';

type RolesFormProps = {
  loading: boolean;
  account: IAccountInfoDto;
  roleInfo: IRoleInfoDto;
  roles: IRoleDto[];
  uas: IUasDto[];
  roleLoading: RoleType | null;
  actions: {
    deleteRoleAsync: (account: IAccountInfoDto, roleType: RoleType) => Promise<void>;
    addRoleAsync: (account: IAccountInfoDto, roleType: RoleType) => Promise<void>;
    deactivateRoleAsync: (role: IUserRoleDto) => Promise<void>;
    activateRoleAsync: (role: IUserRoleDto) => Promise<void>;
    addUasAsync: (role: IUserRoleDto, uas: IUasDto) => Promise<void>;
    removeUasAsync: (role: IUserRoleDto, uas: IUasDto) => Promise<void>;
  };
};

export const RolesForm = (props: RolesFormProps) => {
  return (
    <div>
      <Typography variant="h3">Roles:</Typography>
      <Grid container component={Paper}>
        <Grid item xs={12}>
          {props.roleInfo.otherRoles.map((r, k) => (
            <Grid key={k} item xs={12} sx={{ padding: 1 }}>
              <Grid container component={Paper} sx={{ padding: 2 }}>
                <Grid item xs={12} md={2}>
                  <Chip
                    sx={{ margin: 0.5 }}
                    label={r.name}
                    onDelete={() => props.actions.deleteRoleAsync(props.account, r.roleType)}
                    color={r.isActive ? 'primary' : 'secondary'}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={r.isActive}
                        inputProps={{ 'aria-label': 'default checkbox' }}
                        onChange={async () => {
                          if (r.isActive) {
                            await props.actions.deactivateRoleAsync(r);
                          } else {
                            await props.actions.activateRoleAsync(r);
                          }
                        }}
                      />
                    }
                    label={'Is Active'}
                  />
                </Grid>
              </Grid>
              {props.roleLoading === r.roleType && <LinearProgress></LinearProgress>}
            </Grid>
          ))}
          {props.roleInfo.masterOfficeRole && (
            <Grid item xs={12} sx={{ padding: 1 }}>
              <Grid container component={Paper} sx={{ padding: 2 }}>
                <Grid item xs={6} md={2}>
                  <Chip
                    sx={{ margin: 0.5 }}
                    label={'Master Office'}
                    onDelete={() => props.actions.deleteRoleAsync(props.account, RoleType.MASTEROFFICE)}
                    color={'primary'}
                  />
                </Grid>
                <Grid item xs={6} md={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={props.roleInfo.masterOfficeRole.isActive}
                        inputProps={{ 'aria-label': 'default checkbox' }}
                        onChange={async () => {
                          if (props.roleInfo.masterOfficeRole.isActive) {
                            await props.actions.deactivateRoleAsync(props.roleInfo.masterOfficeRole);
                          } else {
                            await props.actions.activateRoleAsync(props.roleInfo.masterOfficeRole);
                          }
                        }}
                      />
                    }
                    label={'Is Active'}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <SelectBox
                    items={props.uas}
                    title="Add UAS"
                    selectedItem={null}
                    getItemKey={(item) => item.id}
                    getItemTitle={(item) => item.code}
                    onChange={(u) => props.actions.addUasAsync(props.roleInfo.masterOfficeRole, u)}
                    allowEmpty
                  ></SelectBox>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Box
                    component="ul"
                    sx={{
                      display: 'flex',
                      justifyContent: 'left',
                      flexWrap: 'wrap',
                      listStyle: 'none',
                      padding: 0.5,
                      margin: 0,
                      marginTop: 2,
                    }}
                  >
                    {props.roleInfo.masterOfficeRole.forUas.map((u, k) => {
                      return (
                        <Box component="li" key={k} sx={{ padding: 0.5 }}>
                          <Chip
                            label={props.uas.find((uas) => uas.id === u.id).code}
                            onDelete={() => props.actions.removeUasAsync(props.roleInfo.masterOfficeRole, u)}
                            color={'primary'}
                          />
                        </Box>
                      );
                    })}
                  </Box>
                </Grid>
              </Grid>
              {props.roleLoading === RoleType.MASTEROFFICE && <LinearProgress></LinearProgress>}
            </Grid>
          )}
          <Grid item xs={12} md={2}>
            <SelectBox
              items={props.roles.filter((r) => !props.account.roles.includes(r.roleType))}
              title="Add a Role"
              selectedItem={null}
              getItemKey={(item) => item.roleType}
              getItemTitle={(item) => item.name}
              onChange={(r) => props.actions.addRoleAsync(props.account, r.roleType)}
              allowEmpty
            ></SelectBox>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
