import { Grid } from '@mui/material';
import { IAcademicYearDto, IPhaseDto, ISemesterInfoDto, ISemesterSelectionFilter } from 'app/clients/services';
import { SelectBox } from 'app/shared/layout/ui-elements/select-box';
import React from 'react';

export type Props = {
  hidePhase?: boolean;
  allowEmpty?: 'all' | 'semester' | 'phase' | 'none';
  academicYear?: IAcademicYearDto;
  semester?: ISemesterInfoDto;
  phase?: IPhaseDto;
  filter: ISemesterSelectionFilter;
  filterAcademicYears?: (academicYear: IAcademicYearDto) => boolean;
  filterSemesters?: (semester: ISemesterInfoDto) => boolean;
  filterPhases?: (phase: IPhaseDto) => boolean;
  onChange: (academicYear?: IAcademicYearDto, semester?: ISemesterInfoDto, phase?: IPhaseDto) => Promise<void> | void;
  gridRow?: boolean;
};

export const SemesterSelection = (props: Props) => {
  const allowAcademicYearEmpty = !!props.filterAcademicYears || props.allowEmpty === 'all';
  const allowSemesterEmpty = !!props.filterSemesters || props.allowEmpty === 'all' || props.allowEmpty === 'semester';
  const allowPhaseEmpty =
    !!props.filterPhases ||
    props.allowEmpty === 'all' ||
    props.allowEmpty === 'semester' ||
    props.allowEmpty === 'phase';

  const getAcademicYears = () => {
    return props.filterAcademicYears
      ? props.filter?.academicYears?.filter(props.filterAcademicYears)
      : props.filter?.academicYears;
  };

  const getSemesters = (newAcademicYear?: IAcademicYearDto) => {
    const semesterOfYear = props.filter?.semesters?.filter((s) => s.academicYearId === newAcademicYear?.id);
    return props.filterSemesters ? semesterOfYear?.filter(props.filterSemesters) : semesterOfYear;
  };

  const getPhases = (newSemester?: ISemesterInfoDto) => {
    const phasesOfSemester = newSemester?.phases?.filter((s) => s.semesterId === newSemester?.id) ?? [];
    return props.filterPhases ? phasesOfSemester.filter(props.filterPhases) : phasesOfSemester;
  };

  const handleAcademicYearChanged = (newAcademicYear?: IAcademicYearDto) => {
    if (!newAcademicYear && allowAcademicYearEmpty) {
      props.onChange();
      return;
    }

    if (props.academicYear?.id === newAcademicYear?.id) {
      return;
    }

    const newSemester = allowSemesterEmpty ? undefined : getSemesters(newAcademicYear)[0];
    const newPhase = allowPhaseEmpty
      ? undefined
      : getPhases(newSemester).find((p) => p.id === newSemester?.currentPhaseId)[0];
    props.onChange(newAcademicYear, newSemester, newPhase);
  };

  const handleSemesterChanged = (newSemester: ISemesterInfoDto) => {
    if (props.semester?.id === newSemester?.id) {
      return;
    }

    if (!newSemester && allowSemesterEmpty) {
      props.onChange(props.academicYear);
      return;
    }

    const newAcademicYear = getAcademicYears()?.find((a) => a.id === newSemester.academicYearId);
    const newPhase = allowPhaseEmpty
      ? null
      : getPhases(newSemester).find((p) => p.id === newSemester.currentPhaseId)[0];
    props.onChange(newAcademicYear, newSemester, newPhase);
  };

  const handlePhaseChanged = (newPhase: IPhaseDto) => {
    if (props.phase?.id === newPhase?.id) {
      return;
    }

    if (!newPhase && allowPhaseEmpty) {
      props.onChange(props.academicYear, props.semester);
      return;
    }
    const newSemester = props.filter?.semesters?.find((s) => s.id === newPhase.semesterId);
    const newAcademicYear = props.filter?.academicYears?.find((a) => a.id === newSemester?.id);
    props.onChange(newAcademicYear, newSemester, newPhase);
  };

  if (props.gridRow) {
    return (
      <>
        <Grid item xs={3}>
          <SelectBox
            items={getAcademicYears() ?? []}
            selectedItem={props.academicYear}
            allowEmpty={allowAcademicYearEmpty}
            onChange={handleAcademicYearChanged}
            title="Academic Year"
            getItemKey={(a: IAcademicYearDto) => a.id}
            getItemTitle={(a: IAcademicYearDto) => a.name}
          />
        </Grid>
        <Grid item xs={3}>
          <SelectBox
            items={getSemesters(props.academicYear) ?? []}
            selectedItem={props.semester}
            onChange={handleSemesterChanged}
            allowEmpty={allowSemesterEmpty}
            title="Semester"
            getItemKey={(s: ISemesterInfoDto) => s.id}
            getItemTitle={(s: ISemesterInfoDto) => s.name}
          />
        </Grid>
        <Grid item xs={3}>
          {!props.hidePhase && (
            <SelectBox
              items={getPhases(props.semester)}
              selectedItem={props.phase}
              onChange={handlePhaseChanged}
              allowEmpty={allowPhaseEmpty}
              title="Phase"
              itemSorter={(p1, p2) => (p1.keyDate > p2.keyDate ? 1 : -1)}
              getItemKey={(p: IPhaseDto) => p.id}
              getItemTitle={(p: IPhaseDto) => p.name}
            />
          )}
        </Grid>
        <Grid item xs={3}></Grid>
      </>
    );
  }

  return (
    <>
      <SelectBox
        items={getAcademicYears() ?? []}
        selectedItem={props.academicYear}
        allowEmpty={allowAcademicYearEmpty}
        onChange={handleAcademicYearChanged}
        title="Academic Year"
        getItemKey={(a: IAcademicYearDto) => a.id}
        getItemTitle={(a: IAcademicYearDto) => a.name}
      />
      <SelectBox
        items={getSemesters(props.academicYear) ?? []}
        selectedItem={props.semester}
        onChange={handleSemesterChanged}
        allowEmpty={allowSemesterEmpty}
        title="Semester"
        getItemKey={(s: ISemesterInfoDto) => s.id}
        getItemTitle={(s: ISemesterInfoDto) => s.name}
      />
      {!props.hidePhase && (
        <SelectBox
          items={getPhases(props.semester)}
          selectedItem={props.phase}
          onChange={handlePhaseChanged}
          allowEmpty={allowPhaseEmpty}
          title="Phase"
          itemSorter={(p1, p2) => (p1.keyDate > p2.keyDate ? 1 : -1)}
          getItemKey={(p: IPhaseDto) => p.id}
          getItemTitle={(p: IPhaseDto) => p.name}
        />
      )}
    </>
  );
};
