import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { RoleType } from './clients/services';
import Logout from './modules/login/logout';
import Register from './modules/login/register';
import ModuleManagement from './modules/module-management';
import ModuleRegistrations from './modules/module-registrations';
import ProfileManagement from './modules/profile-management';
import RegistrationImport from './modules/registration-import';
import SemesterManagement from './modules/semester-management';
import StudentManagement from './modules/student-management';
import UasManagement from './modules/uas-management';
import UserManagement from './modules/user-management';
import Guarded from './shared/auth/guarded-component';
import PageNotFound from './shared/error/page-not-found';
import Home from './shared/layout/home';

export const UserManagementRoute = '/user-management';
export const RegistrationImportRoute = '/registration-import';
export const ModuleRegistrationsRoute = '/module-registrations';
export const ModuleManagementRoute = '/module-management';
export const SemesterManagementRoute = '/semester-management';
export const ProfileManagementRoute = '/profile-management';
export const StudentManagementRoute = '/student-management';
export const UasManagementRoute = '/uas-management';

const AppRoutes = () => (
  <Routes>
    <Route path="/logout" element={<Logout />} />
    <Route path="/register" element={<Register />} />
    <Route
      path={UserManagementRoute + '/*'}
      element={
        <Guarded requiresRole={[RoleType.ADMIN, RoleType.USERADMIN]}>
          <UserManagement baseUrl={UserManagementRoute} />
        </Guarded>
      }
    />
    <Route
      path={SemesterManagementRoute + '/*'}
      element={
        <Guarded requiresRole={[RoleType.PROCESSADMIN]}>
          <SemesterManagement baseUrl={SemesterManagementRoute} />
        </Guarded>
      }
    />
    <Route
      path={ProfileManagementRoute + '/*'}
      element={
        <Guarded requiresRole={[RoleType.MODULEADMIN]}>
          <ProfileManagement baseUrl={ProfileManagementRoute} />
        </Guarded>
      }
    />
    <Route
      path={ModuleManagementRoute + '/*'}
      element={
        <Guarded requiresRole={[RoleType.MODULEADMIN]}>
          <ModuleManagement baseUrl={ModuleManagementRoute} />
        </Guarded>
      }
    />
    <Route
      path={UasManagementRoute + '/*'}
      element={
        <Guarded requiresRole={[RoleType.MASTEROFFICE, RoleType.REGISTRATIONADMIN]}>
          <UasManagement baseUrl={UasManagementRoute} />
        </Guarded>
      }
    />
    <Route
      path={StudentManagementRoute + '/*'}
      element={
        <Guarded requiresRole={[RoleType.STUDENTADMIN, RoleType.MASTEROFFICE]}>
          <StudentManagement baseUrl={StudentManagementRoute} />
        </Guarded>
      }
    />
    <Route
      path={RegistrationImportRoute + '/*'}
      element={
        <Guarded>
          <RegistrationImport baseUrl={RegistrationImportRoute} />
        </Guarded>
      }
    />
    <Route
      path={ModuleRegistrationsRoute + '/*'}
      element={
        <Guarded requiresRole={[RoleType.REGISTRATIONADMIN, RoleType.MASTEROFFICE]}>
          <ModuleRegistrations baseUrl={ModuleRegistrationsRoute} />
        </Guarded>
      }
    />
    <Route index element={<Home />} />
    <Route path="*" element={<PageNotFound />} />
  </Routes>
);

export default AppRoutes;
