import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import SaveIcon from '@mui/icons-material/Save';
import { Alert, Button, Grid, Paper, Typography } from '@mui/material';
import { EmailDto, IAdvisorDto, IEmailDto } from 'app/clients/services';
import { Field, FieldArray, Form, Formik } from 'formik';
import { CheckboxWithLabel, TextField as FormikTextField } from 'formik-mui';
import React from 'react';
import * as Yup from 'yup';
type Props = {
  loading: boolean;
  advisor: IAdvisorDto;
  onSubmit: (advisor: IAdvisorDto) => Promise<void>;
  onDelete: (advisor: IAdvisorDto) => Promise<void>;
  validateEmailAsync: (email: string) => Promise<boolean>;
};

export const AdvisorForm = (props: Props) => {
  const updateDefaultEmails = (
    a: IAdvisorDto,
    isDefaultEmail: string,
    key: number,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  ) => {
    if (isDefaultEmail === 'false') {
      for (let i = 0; i < a.emails.length; i++) {
        setFieldValue(`emails.${i}.isDefault`, key === i);
      }
    }
  };

  return (
    <Paper style={{ margin: 5, padding: 20 }}>
      <Formik
        enableReinitialize
        initialValues={props.advisor}
        validationSchema={Yup.object({
          firstName: Yup.string().min(2).required('required'),
          familyName: Yup.string().min(2).required('required'),
          emails: Yup.array()
            .of(
              Yup.object({
                name: Yup.string()
                  .required('Email is required')
                  .email('Email is not vaild.')
                  .test('email-backend-validation', 'Email is already taken by another user!', async (name) => {
                    if (name && (await Yup.string().email().isValid(name))) {
                      return await props.validateEmailAsync(name);
                    }
                    return true;
                  }),
              }),
            )
            .test('unique', 'Duplicate email!', (emails) => {
              if (!emails) {
                return true;
              }
              return emails?.length === new Set(emails?.map((e) => e.name) ?? []).size;
            }),
        })}
        onSubmit={(values, { setSubmitting }) => {
          props.onSubmit(values).then(() => setSubmitting(false));
        }}
      >
        {({ values, submitForm, isSubmitting, setFieldValue, errors }) => (
          <Form>
            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-end" spacing={2}>
              <Grid item xs={12} md={3}>
                <Field
                  component={FormikTextField}
                  variant="standard"
                  name="firstName"
                  type="text"
                  label="First Name"
                  style={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Field
                  variant="standard"
                  component={FormikTextField}
                  name="familyName"
                  type="text"
                  label="Family Name"
                  style={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Field
                  variant="standard"
                  onChange={() => {
                    setFieldValue('isActive', !values.isActive);
                    submitForm();
                  }}
                  component={CheckboxWithLabel}
                  type="checkbox"
                  name={`isActive`}
                  Label={{ label: 'Is Active' }}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container component={Paper} sx={{ padding: 2 }}>
                  <Grid item xs={12}>
                    <Typography variant="h3">Emails:</Typography>
                    <FieldArray
                      name="emails"
                      render={(arrayHelpers) => (
                        <div>
                          {values.emails.map((e, k) => (
                            <Grid
                              container
                              key={'email-' + k}
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="flex-end"
                              spacing={5}
                            >
                              <Grid item xs={12} md={5}>
                                <Field
                                  component={FormikTextField}
                                  variant="standard"
                                  name={`emails.${k}.name`}
                                  type="text"
                                  label="Email"
                                  style={{ width: '100%' }}
                                />
                              </Grid>
                              <Grid item xs={12} md={2}>
                                <Field
                                  component={CheckboxWithLabel}
                                  type="checkbox"
                                  name={`emails.${k}.isActive`}
                                  Label={{ label: 'Is Active' }}
                                  disabled={e.isEduIdEmail}
                                />
                              </Grid>
                              <Grid item xs={12} md={2}>
                                <Field
                                  component={CheckboxWithLabel}
                                  type="checkbox"
                                  name={`emails.${k}.isDefault`}
                                  Label={{ label: 'Is Default' }}
                                  onChange={(event) =>
                                    updateDefaultEmails(values, event.target.value, k, setFieldValue)
                                  }
                                />
                              </Grid>
                              <Grid item xs={12} md={2}>
                                {!e.id ? (
                                  <Button
                                    variant="contained"
                                    color="inherit"
                                    disabled={isSubmitting || props.loading}
                                    onClick={() => arrayHelpers.remove(k)}
                                    startIcon={<DeleteIcon />}
                                  >
                                    Cancel
                                  </Button>
                                ) : (
                                  <Button
                                    variant="contained"
                                    color="inherit"
                                    disabled={e.isEduIdEmail || isSubmitting || props.loading}
                                    onClick={() => arrayHelpers.remove(k)}
                                    startIcon={<DeleteIcon />}
                                  >
                                    Delete
                                  </Button>
                                )}
                              </Grid>
                            </Grid>
                          ))}
                          {errors['emails'] && typeof errors['emails'] === 'string' && (
                            <>
                              <Alert severity="error" style={{ marginTop: 10 }}>
                                {errors['emails']}
                              </Alert>
                            </>
                          )}
                          <Button
                            variant="contained"
                            color="inherit"
                            disabled={props.loading}
                            startIcon={<AddIcon />}
                            style={{ marginTop: 20 }}
                            onClick={() =>
                              arrayHelpers.push(
                                new EmailDto({
                                  id: 0,
                                  userId: props.advisor.id,
                                  name: '',
                                  isActive: true,
                                  isPrivate: false,
                                  isDefault: false,
                                  isEduIdEmail: false,
                                } as IEmailDto),
                              )
                            }
                          >
                            Add Email
                          </Button>
                        </div>
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting || props.loading}
                  onClick={submitForm}
                  startIcon={<SaveIcon />}
                >
                  {props.advisor.id ? <>Save</> : <>Add</>}
                </Button>
                {props.advisor.id && props.advisor.advisorOf.length === 0 && props.advisor.uas.length === 0 && (
                  <Button
                    variant="contained"
                    color="inherit"
                    disabled={isSubmitting || props.loading}
                    onClick={() => props.onDelete(props.advisor)}
                    startIcon={<CancelIcon />}
                  >
                    Delete
                  </Button>
                )}
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Paper>
  );
};
