import { ICommentDto, IModuleRegistrationInfoDto, IUasDto } from 'app/clients/services';
import ACTION_TYPES from './types';
import { REQUEST, FAILURE, SUCCESS } from 'app/shared/util/action-type.util';
import { addCommentToRegistrationInfo, removeCommentFromRegistrationInfo } from 'app/shared/util/reducer-helpers';

const initialState = {
  loading: false,
  exportLoading: false,
  errorMessage: null,
  registrations: [] as IModuleRegistrationInfoDto[],
  selectedUas: null as IUasDto,
  mainContentLoading: true,
};

export type UasRegistrationState = Readonly<typeof initialState>;

// Reducer
export default (state: UasRegistrationState = initialState, action): UasRegistrationState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_UAS_REGISTRATIONS):
      return {
        ...state,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_UAS_REGISTRATIONS):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_UAS_REGISTRATIONS): {
      return {
        ...state,
        loading: false,
        registrations: action.payload,
      };
    }
    case REQUEST(ACTION_TYPES.DELETE_UAS_REGISTRATIONS):
      return {
        ...state,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.DELETE_UAS_REGISTRATIONS):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.DELETE_UAS_REGISTRATIONS): {
      return {
        ...state,
        loading: false,
        registrations: [],
      };
    }
    case REQUEST(ACTION_TYPES.DOWNLOAD):
      return {
        ...state,
        exportLoading: true,
      };
    case FAILURE(ACTION_TYPES.DOWNLOAD):
      return {
        ...state,
        exportLoading: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.DOWNLOAD): {
      return {
        ...state,
        exportLoading: false,
      };
    }
    case ACTION_TYPES.SET_MAIN_CONTENT_LOADING: {
      return {
        ...state,
        mainContentLoading: action.payload,
      };
    }
    case ACTION_TYPES.SELECT_UAS: {
      return {
        ...state,
        selectedUas: action.payload,
      };
    }
    case ACTION_TYPES.ADD_REGISTRATION_COMMENT: {
      const comment = action.payload as ICommentDto;

      return {
        ...state,
        registrations: addCommentToRegistrationInfo(state.registrations, comment),
      };
    }
    case ACTION_TYPES.REMOVE_REGISTRATION_COMMENT: {
      const comment = action.payload as ICommentDto;

      return {
        ...state,
        registrations: removeCommentFromRegistrationInfo(state.registrations, comment),
      };
    }
    default:
      return state;
  }
};
