import { ISemesterDto, IStudentClient, IStudentDto, IStudentPreviewDto, IUasDto, PersonSearchDto } from 'app/clients/services';
import { addExceptionNotification } from 'app/modules/notification';
import { AsyncThunk } from 'app/shared/util/action-type.util';
import ACTION_TYPES from './types';

const initActions = (componentDispatch: any, studentManagementClient: IStudentClient) => {
  const setSearchLoading = (loading: boolean) => ({
    type: ACTION_TYPES.SET_SEARCH_LOADING,
    payload: loading,
  });

  const setSearchResult = (students: IStudentPreviewDto[]) => ({
    type: ACTION_TYPES.SET_SEARCH_RESULT,
    payload: students,
  });

  const addUasFilter = (uas: IUasDto) => ({
    type: ACTION_TYPES.ADD_UAS_FILTER,
    payload: uas,
  });

  const removeUasFilter = (uas: IUasDto) => ({
    type: ACTION_TYPES.REMOVE_UAS_FILTER,
    payload: uas,
  });

  const addSemesterFilter = (semester: ISemesterDto) => ({
    type: ACTION_TYPES.ADD_SEMESTER_FILTER,
    payload: semester,
  });

  const removeSemesterFilter = (semester: ISemesterDto) => ({
    type: ACTION_TYPES.REMOVE_SEMESTER_FILTER,
    payload: semester,
  });

  const setSearchString = (searchString: string) => ({
    type: ACTION_TYPES.SET_SEARCH_STRING,
    payload: searchString,
  });

  const setResultSearchString = (searchString: string) => ({
    type: ACTION_TYPES.SET_RESULT_SEARCH_STRING,
    payload: searchString,
  });

  const searchAsync: (searchString: string, uas: IUasDto[], semester: ISemesterDto[]) => AsyncThunk<IStudentPreviewDto[]> =
    (searchString, uas, semester) => async dispatch => {
      dispatch(setSearchLoading(true));
      try {
        const searchResult = await studentManagementClient.search(
          new PersonSearchDto({ uasIds: uas.map(u => u.id), semesterIds: semester.map(s => s.id), searchString })
        );
        dispatch(setSearchResult(searchResult));
        dispatch(setResultSearchString(searchString));
        dispatch(setSearchLoading(false));
        return searchResult;
      } catch (e) {
        dispatch(addExceptionNotification(e, 'An unknown error occured while searching.'));
        dispatch(setSearchLoading(false));
        return [];
      }
    };

  return Object.freeze({
    addUasFilter: (uas: IUasDto) => componentDispatch(addUasFilter(uas)),
    removeUasFilter: (uas: IUasDto) => componentDispatch(removeUasFilter(uas)),
    addSemesterFilter: (semester: ISemesterDto) => componentDispatch(addSemesterFilter(semester)),
    removeSemesterFilter: (semester: ISemesterDto) => componentDispatch(removeSemesterFilter(semester)),
    searchAsync: async (searchString: string, uas: IUasDto[], semester: ISemesterDto[]): Promise<IStudentDto[]> =>
      await componentDispatch(searchAsync(searchString, uas, semester)),
    setSearchString: (searchString: string) => componentDispatch(setSearchString(searchString)),
  });
};
export default initActions;

export type StudentSearchActions = ReturnType<typeof initActions>;
