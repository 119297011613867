import { IStudentDto } from 'app/clients/services';
import ACTION_TYPES from './types';

// State
const initialState = {
  tabs: [] as IStudentDto[],
  selectedTab: null as IStudentDto,
};

export type StudentTabsState = Readonly<typeof initialState>;

// Reducer
export default (state: StudentTabsState = initialState, action): StudentTabsState => {
  switch (action.type) {
    case ACTION_TYPES.ADD_TAB:
      return {
        ...state,
        tabs: [...state.tabs, action.payload],
      };
    case ACTION_TYPES.REMOVE_TAB: {
      const tabs = state.tabs.filter(t => t.id !== action.payload?.id) ?? [];
      return {
        ...state,
        tabs,
        selectedTab: null,
      };
    }
    case ACTION_TYPES.SELECT_TAB: {
      const tabs = !action.payload || state.tabs.find(t => t.id === action.payload.id) ? [...state.tabs] : [...state.tabs, action.payload];
      return {
        ...state,
        selectedTab: action.payload,
        tabs,
      };
    }

    case ACTION_TYPES.CLOSE_ALL_TABS:
      return {
        ...state,
        tabs: [],
      };
    default:
      return state;
  }
};
