import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import {
  Button,
  ButtonGroup,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  LinearProgress,
  Typography,
} from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import { IRootState } from 'app/config/root.reducer';
import { IAccountRequestDto } from '../../clients/services';

import DeleteIcon from '@mui/icons-material/DeleteForever';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { FullSizeLayout } from 'app/shared/layout/ui-elements/component-layout';
import { dateFormatter, dateSorter, StandardDataGrid } from 'app/shared/layout/ui-elements/standard-data-grid';
import { StandardDialog } from 'app/shared/layout/ui-elements/standard-dialog';
import { useNavigate } from 'react-router-dom';
import userManagementModule from './user-management';

export const AccountRequests = (props: PropsFromRedux) => {
  useEffect(() => {
    const init = async () => {
      await props.loadPendingRequestsAsync();
      await props.loadRejectedRequestsAsync();
    };
    init();
  }, []);

  const [selectedAccountRequest, setSelectedAccountRequest] = React.useState<IAccountRequestDto>(null);
  const [selectedRoles, setSelectedRoles] = React.useState<string[]>([]);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const navigate = useNavigate();

  const handleActivate = async () => {
    const roleTypes = props.roles
      .filter((r) => selectedRoles.filter((id) => id === r.id).length > 0)
      .map((r) => r.roleType);
    if (roleTypes.length > 0) {
      await props.activateAsync(selectedAccountRequest.id, roleTypes);
      setDialogOpen(false);
      navigate('/user-management');
    }
  };

  const handleCancel = () => {
    setDialogOpen(false);
  };

  const roleIsSelected = (id: string) => {
    return selectedRoles.filter((r) => id === r).length > 0;
  };

  const changeRoleSelection = (id: string) => {
    const roles = roleIsSelected(id) ? selectedRoles.filter((r) => r !== id) : [...selectedRoles, id];
    setSelectedRoles(roles);
  };

  const handleOpenActivateWindow = async (requestId: number) => {
    setDialogOpen(true);
    await props.loadExistingUserAsync(requestId);
  };

  const columns: GridColDef[] = [
    { field: 'firstName', headerName: 'First Name', width: 180 },
    { field: 'familyName', headerName: 'Family Name', width: 180 },
    {
      field: 'email',
      headerName: 'Email',
      width: 180,
    },
    {
      field: 'createdDate',
      headerName: 'Date Applied',
      width: 160,
      sortComparator: dateSorter,
      valueFormatter: dateFormatter,
    },
    {
      field: 'id',
      headerName: 'Actions',
      width: 300,
      editable: false,
      renderCell: (params: GridCellParams) => (
        <ButtonGroup size="small" aria-label="small outlined button group">
          {params.row['isBlocked'] && (
            <Button
              variant="contained"
              color="inherit"
              disabled={props.loading}
              startIcon={<LockOpenIcon />}
              onClick={() => props.unblockRequestAsync(params.value as number)}
            >
              Unblock
            </Button>
          )}
          {!params.row['isBlocked'] && (
            <Button
              variant="contained"
              color="inherit"
              disabled={props.loading}
              startIcon={<PersonAddIcon />}
              onClick={() => handleOpenActivateWindow(params.value as number)}
            >
              Activate
            </Button>
          )}
          {!params.row['isBlocked'] && (
            <Button
              variant="contained"
              color="inherit"
              disabled={props.loading}
              startIcon={<LockIcon />}
              onClick={() => props.blockRequestAsync(params.value as number)}
            >
              Block
            </Button>
          )}
          {params.row['isBlocked'] && (
            <Button
              variant="contained"
              color="inherit"
              disabled={props.loading}
              startIcon={<DeleteIcon />}
              onClick={() => props.deleteRequestAsync(params.value as number)}
            >
              Delete
            </Button>
          )}
        </ButtonGroup>
      ),
    },
  ];

  return (
    <FullSizeLayout>
      <Typography variant="h3">Pending Requests</Typography>
      <StandardDataGrid
        rows={props.pendingRequests ?? []}
        columns={columns}
        loading={props.loading}
        onSelectionModelChange={(newSelection) => {
          setSelectedAccountRequest(props.pendingRequests.find((a) => a.id === newSelection[0]));
        }}
        columnVisibilityModel={{}}
      />
      <StandardDialog
        title="Activate Account"
        open={dialogOpen}
        onConfirm={handleActivate}
        confirmName="Activate"
        onCancel={handleCancel}
        confirmDisabled={selectedRoles.length === 0}
      >
        {props.existingUserLoading ? (
          <LinearProgress></LinearProgress>
        ) : (
          <>
            {props.existingUser && (
              <>
                <Typography>
                  There is already an existing user{' '}
                  <strong>{`${props.existingUser?.firstName} ${props.existingUser?.familyName}`}</strong> with the same
                  email address or the same identifier in the database.
                  <br />
                </Typography>
                {(props.existingUser.firstName !== selectedAccountRequest?.firstName ||
                  props.existingUser.familyName !== selectedAccountRequest?.familyName) && (
                  <Typography>
                    If you procede, the user will be renamed from{' '}
                    <strong>{`${props.existingUser?.firstName} ${props.existingUser?.familyName}`}</strong> to{' '}
                    <strong>{`${selectedAccountRequest?.firstName} ${selectedAccountRequest?.familyName}`}</strong>.
                    <br />
                  </Typography>
                )}
                {props.existingUser.roles?.length > 0 && (
                  <Typography>
                    The existing user has already the following existing roles:
                    <br />
                    {props.existingUser.roles.map((r) => r.toString()).join(', ')}
                    <br />
                  </Typography>
                )}
                {props.existingUser.activated ? (
                  <Typography>
                    The user account is <strong>already activated</strong>! But you can add some additional roles.
                    <br />
                  </Typography>
                ) : (
                  <Typography>
                    The user account is <strong>not activated yet</strong>! By proceding you will activate the existing
                    user account.
                    <br />
                  </Typography>
                )}
              </>
            )}
            <>
              <Typography>
                Please select the roles you want to assign to{' '}
                {`${selectedAccountRequest?.firstName} ${selectedAccountRequest?.familyName}`}:
              </Typography>
              <FormControl required component="fieldset" /* className={classes.formControl} */>
                <FormGroup>
                  {props.roles?.map((r) => (
                    <FormControlLabel
                      key={r.id}
                      control={
                        <Checkbox
                          checked={roleIsSelected(r.id) || props.existingUser?.roles.includes(r.roleType)}
                          onChange={() => changeRoleSelection(r.id)}
                          name={r.id}
                        />
                      }
                      label={r.name}
                      disabled={props.existingUser?.roles.includes(r.roleType)}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </>
          </>
        )}
      </StandardDialog>
      <Typography variant="h3" sx={{ paddingTop: 2 }}>
        Blocked Requests
      </Typography>
      <StandardDataGrid
        rows={props.blockedRequests ?? []}
        columns={columns}
        loading={props.loading}
        columnVisibilityModel={{}}
      />
    </FullSizeLayout>
  );
};

const mapStateToProps = ({ userManagement, application }: IRootState) => ({
  pendingRequests: userManagement.pendingRequests,
  blockedRequests: userManagement.rejectedRequests,
  loading: userManagement.requestsLoading || !application.isLoaded,
  existingUser: userManagement.existingUser,
  existingUserLoading: userManagement.existingUserLoading,
  roles: application.roles,
});

const mapDispatchToProps = (dispatch) => {
  const userManagementActions = userManagementModule.initActions(dispatch);
  return userManagementActions;
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AccountRequests);
