import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { PickersActionBarAction } from '@mui/x-date-pickers/PickersActionBar';
import { APP_DATE_FORMAT } from 'app/config/constants';
import { DateTime } from 'luxon';
import React from 'react';

export type ButtonAction = 'preview' | 'delete' | 'download';

type MyDatePickerProps = {
  label: string;
  name?: string;
  value: DateTime;
  onChange?: (date: DateTime, value?: string | null) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  error?: boolean;
  helperText?: string;
  clearable: boolean;
  style?: React.CSSProperties;
  disableFuture?: boolean;
  disabled?: boolean;
};

export const DatePicker = (props: MyDatePickerProps) => {
  const handleChange: (date: DateTime | null, keyboardInputValue?: string) => void = (date) => {
    props.onChange && props.onChange(date);
  };

  const handleBlur: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => void = (event) => {
    props.onBlur && props.onBlur(event);
  };

  const actions: PickersActionBarAction[] = props.clearable ? ['clear'] : [];

  return (
    <MuiDatePicker
      label={props.label}
      name={props.name}
      disabled={props.disabled}
      disableFuture={props.disableFuture}
      value={props.value}
      format={APP_DATE_FORMAT}
      onChange={(date) => handleChange(date)}
      slotProps={{
        actionBar: {
          actions,
        },
        textField: {
          variant: 'standard',
          error: props.error,
          style: props.style,
          onBlur: handleBlur,
          placeholder: '',
          helperText: props.helperText,
        },
      }}
    />
  );
};

type FormikProps = Omit<
  {
    field: {
      name: string;
      value: DateTime;
      onChange: (e: React.ChangeEvent<any>) => void;
      onBlur: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    };
    form: {
      touched: { [name: string]: boolean };
      errors: { [name: string]: string };
      setFieldValue: (name: string, value?) => void;
    };
  } & MyDatePickerProps,
  'value' | 'onChange' | 'onBlur' | 'helperText' | 'error'
>;

export const FormikDatePicker = (props: FormikProps) => {
  const handleChange: (date: DateTime | null, value?: string | null) => void = (date) => {
    props.form.setFieldValue(props.field.name, date);
  };

  return (
    <DatePicker
      {...props}
      value={props.field.value ?? null}
      onChange={handleChange}
      onBlur={props.field.onBlur}
      error={props.form.touched[props.field.name] && !!props.form.errors[props.field.name]}
      helperText={props.form.touched[props.field.name] ? props.form.errors[props.field.name] : ''}
    />
  );
};
