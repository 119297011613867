import {
  CommentDto,
  FileResponse,
  FilterPresetDto,
  IAdvisorClient,
  IAdvisorDto,
  ICommentDto,
  IExcelColumn,
  IFilterPresetDto,
  IModuleCategoryDto,
  IModuleDto,
  IModuleRegistrationInfoDto,
  IPhaseDto,
  IRegistrationEditClient,
  IRegistrationExportClient,
  IRegistrationFilterPresetClient,
  IRegistrationFilterSelectionDto,
  IRegistrationStateChangeDto,
  IRoleDto,
  ISelectionOfLong,
  ISemesterDto,
  IStudentClient,
  IStudentDto,
  IStudentPreviewDto,
  IUasDto,
  PersonSearchDto,
  RegistrationFilterSelectionDto,
  RegistrationState,
  SelectionOfString,
  StudentType,
} from 'app/clients/services';
import { addExceptionNotification, addSuccess } from 'app/modules/notification';
import { AsyncThunk, Thunk } from 'app/shared/util/action-type.util';
import { saveAs } from 'file-saver';
import { DateTime } from 'luxon';
import ACTION_TYPES from './types';

export const initActions = (
  componentDispatch,
  registrationExportClient: IRegistrationExportClient,
  presetClient: IRegistrationFilterPresetClient,
  registrationEditClient: IRegistrationEditClient,
  studentClient: IStudentClient,
  advisorClient: IAdvisorClient,
) => {
  const setExportLoading = (loading: boolean) => ({
    type: ACTION_TYPES.SET_LOADING,
    payload: loading,
  });

  const setStaticFiltersLoading = (loading: boolean) => ({
    type: ACTION_TYPES.SET_STATIC_FILTERS_LOADING,
    payload: loading,
  });

  const setModuleFiltersLoading = (loading: boolean) => ({
    type: ACTION_TYPES.SET_MODULE_FILTERS_LOADING,
    payload: loading,
  });

  const setFileImportFiltersLoading = (loading: boolean) => ({
    type: ACTION_TYPES.SET_FILEIMPORT_FILTERS_LOADING,
    payload: loading,
  });

  const setPresetsLoading = (loading: boolean) => ({
    type: ACTION_TYPES.SET_PRESETS_LOADING,
    payload: loading,
  });

  const setPresets = (presets: IFilterPresetDto[]) => ({
    type: ACTION_TYPES.SET_PRESETS,
    payload: presets,
  });

  const setFilterSelection = (filterSelection: IRegistrationFilterSelectionDto) => ({
    type: ACTION_TYPES.SET_FILTER_SELECTION,
    payload: filterSelection,
  });

  const setPreview = (rows: IModuleRegistrationInfoDto[]) => ({
    type: ACTION_TYPES.SET_PREVIEW,
    payload: rows,
  });

  const setPreviewLoading = (loading: boolean) => ({
    type: ACTION_TYPES.SET_PREVIEW_LOADING,
    payload: loading,
  });

  // Thunks
  const exportAsync: (filterSelection: IRegistrationFilterSelectionDto) => AsyncThunk<FileResponse> =
    (filterSelection) => async (dispatch) => {
      dispatch(setExportLoading(true));
      try {
        const result = await registrationExportClient.exportRegistrations(
          new RegistrationFilterSelectionDto(filterSelection),
        );
        saveAs(result.data, result.fileName);
        dispatch(setExportLoading(false));
        return result;
      } catch (e) {
        dispatch(addExceptionNotification(e));
        dispatch(setExportLoading(false));
      }

      return null;
    };

  const loadFiltersAsync: () => AsyncThunk = () => async (dispatch) => {
    dispatch(setStaticFiltersLoading(true));
    try {
      const result = await registrationExportClient.getStaticFilters();
      dispatch({
        type: ACTION_TYPES.SET_STATIC_FILTERS,
        payload: result,
      });

      const a = dispatch(updateModuleFilterAsync());
      const b = dispatch(updateFileImportFilterAsync());
      await Promise.all([a, b]);

      dispatch(setStaticFiltersLoading(false));
    } catch (e) {
      dispatch(addExceptionNotification(e));
      dispatch(setStaticFiltersLoading(false));
    }
  };

  const updateFileImportFilterAsync: (filter?: IRegistrationFilterSelectionDto) => AsyncThunk =
    (filter: IRegistrationFilterSelectionDto) => async (dispatch, getState) => {
      dispatch(setFileImportFiltersLoading(true));
      try {
        const theFilter = filter ?? getState().moduleRegistrationExport.filterSelection;
        const result = await registrationExportClient.getFileImportSelection(
          new RegistrationFilterSelectionDto(theFilter),
        );
        dispatch({
          type: ACTION_TYPES.SET_FILEIMPORT_FILTERS,
          payload: result,
        });
        dispatch(setFileImportFiltersLoading(false));
      } catch (e) {
        dispatch(addExceptionNotification(e));
        dispatch(setFileImportFiltersLoading(false));
      }
    };

  const updateModuleFilterAsync: (filter?: IRegistrationFilterSelectionDto) => AsyncThunk<string[]> =
    (filter) => async (dispatch, getState) => {
      dispatch(setModuleFiltersLoading(true));
      try {
        const theFilter = filter ?? getState().moduleRegistrationExport.filterSelection;
        const result = await registrationExportClient.getModuleExecutionCodes(
          new RegistrationFilterSelectionDto(theFilter),
        );
        dispatch({
          type: ACTION_TYPES.SET_MODULE_FILTERS,
          payload: result,
        });
        dispatch(setModuleFiltersLoading(false));
        return result;
      } catch (e) {
        dispatch(addExceptionNotification(e));
        dispatch(setModuleFiltersLoading(false));
      }
      return [];
    };

  const loadPresetsAsync: () => AsyncThunk<IFilterPresetDto[]> = () => async (dispatch) => {
    dispatch(setPresetsLoading(true));
    try {
      const result = await presetClient.list();
      dispatch(setPresets(result));
      dispatch(setPresetsLoading(false));
      return result;
    } catch (e) {
      dispatch(addExceptionNotification(e));
      dispatch(setPresetsLoading(false));
    }

    return [];
  };

  const addUasFilterAsync: (uas: IUasDto) => AsyncThunk = (uas) => async (dispatch) => {
    dispatch({
      type: ACTION_TYPES.ADD_UAS_FILTER,
      payload: uas,
    });
    await dispatch(updateFileImportFilterAsync());
  };

  const removeUasFilterAsync: (uas: IUasDto) => AsyncThunk = (uas) => async (dispatch) => {
    dispatch({
      type: ACTION_TYPES.REMOVE_UAS_FILTER,
      payload: uas,
    });
    const a = dispatch(updateModuleFilterAsync());
    const b = dispatch(updateFileImportFilterAsync());
    await Promise.all([a, b]);
  };

  const addSemesterFilterAsync: (semester: ISelectionOfLong) => AsyncThunk = (semester) => async (dispatch) => {
    dispatch({
      type: ACTION_TYPES.ADD_SEMESTER_FILTER,
      payload: semester,
    });

    const a = dispatch(updateModuleFilterAsync());
    const b = dispatch(updateFileImportFilterAsync());
    await Promise.all([a, b]);
  };

  const removeSemesterFilterAsync: (semester: ISelectionOfLong) => AsyncThunk = (semester) => async (dispatch) => {
    dispatch({
      type: ACTION_TYPES.REMOVE_SEMESTER_FILTER,
      payload: semester,
    });

    const a = dispatch(updateModuleFilterAsync());
    const b = dispatch(updateFileImportFilterAsync());
    await Promise.all([a, b]);
  };

  const addColumn = (column: IExcelColumn) => ({
    type: ACTION_TYPES.ADD_COLUMN,
    payload: column,
  });

  const removeColumn = (column: IExcelColumn) => ({
    type: ACTION_TYPES.REMOVE_COLUMN,
    payload: column,
  });

  const setPhaseFilterAsync: (phase: IPhaseDto) => AsyncThunk = (phase) => async (dispatch) => {
    dispatch({
      type: ACTION_TYPES.ADD_PHASE_FILTER,
      payload: !phase?.id ? undefined : phase.id,
    });

    await dispatch(updateFileImportFilterAsync());
  };

  const addLabelFilter = (label: ISelectionOfLong) => ({
    type: ACTION_TYPES.ADD_LABEL_FILTER,
    payload: label,
  });

  const removeLabelFilter = (label: ISelectionOfLong) => ({
    type: ACTION_TYPES.REMOVE_LABEL_FILTER,
    payload: label,
  });

  const addStudentTypeFilter = (studentType: StudentType) => ({
    type: ACTION_TYPES.ADD_STUDENT_TYPE_FILTER,
    payload: studentType,
  });

  const removeStudentTypeFilter = (studentType: StudentType) => ({
    type: ACTION_TYPES.REMOVE_STUDENT_TYPE_FILTER,
    payload: studentType,
  });

  const addProfileFilter = (profile: ISelectionOfLong) => ({
    type: ACTION_TYPES.ADD_PROFILE_FILTER,
    payload: profile,
  });

  const removeProfileFilter = (profile: ISelectionOfLong) => ({
    type: ACTION_TYPES.REMOVE_PROFILE_FILTER,
    payload: profile,
  });

  const addLocationFilter = (location: ISelectionOfLong) => ({
    type: ACTION_TYPES.ADD_LOCATION_FILTER,
    payload: location,
  });

  const removeLocationFilter = (location: ISelectionOfLong) => ({
    type: ACTION_TYPES.REMOVE_LOCATION_FILTER,
    payload: location,
  });

  const addStateFilter = (registrationState: RegistrationState) => ({
    type: ACTION_TYPES.ADD_STATE_FILTER,
    payload: registrationState,
  });

  const removeStateFilter = (registrationState: RegistrationState) => ({
    type: ACTION_TYPES.REMOVE_STATE_FILTER,
    payload: registrationState,
  });

  const setStudent = (student: IStudentPreviewDto) => ({
    type: ACTION_TYPES.SET_STUDENT,
    payload: student
      ? new SelectionOfString({ id: student.id, display: `${student.firstName} ${student.familyName}` })
      : null,
  });

  const setAdvisor = (advisor: IAdvisorDto) => ({
    type: ACTION_TYPES.SET_ADVISOR,
    payload: advisor
      ? new SelectionOfString({ id: advisor.id, display: `${advisor.firstName} ${advisor.familyName}` })
      : null,
  });

  const setModuleCategoryAsync: (moduleCategory: IModuleCategoryDto) => AsyncThunk =
    (moduleCategory) => async (dispatch, getState) => {
      const currentModuleId = getState().moduleRegistrationExport.filterSelection.moduleId;
      const currentModule = getState().moduleSelection.modules.find((m) => m.id === currentModuleId);
      const currentModuleExecutionCode = getState().moduleRegistrationExport.filterSelection.moduleExecutionCode;

      dispatch({
        type: ACTION_TYPES.SET_MODULE_CATEGORY,
        payload: moduleCategory,
      });

      if (currentModule?.categoryId === moduleCategory?.id) {
        dispatch({
          type: ACTION_TYPES.SET_MODULE,
          payload: currentModule,
        });
      }

      const result = await dispatch(updateModuleFilterAsync());
      if (currentModuleExecutionCode && result.includes(currentModuleExecutionCode)) {
        dispatch({
          type: ACTION_TYPES.SET_MODULE_EXECUTION_CODE,
          payload: currentModuleExecutionCode,
        });
      } else if (result.length === 1) {
        dispatch({
          type: ACTION_TYPES.SET_MODULE_EXECUTION_CODE,
          payload: result[0],
        });
      }
    };

  const setModuleAsync: (module: IModuleDto) => AsyncThunk = (module) => async (dispatch, getState) => {
    dispatch({
      type: ACTION_TYPES.SET_MODULE,
      payload: module,
    });

    const currentModuleExecutionCode = getState().moduleRegistrationExport.filterSelection.moduleExecutionCode;
    const result = await dispatch(updateModuleFilterAsync());
    if (currentModuleExecutionCode && result.includes(currentModuleExecutionCode)) {
      dispatch({
        type: ACTION_TYPES.SET_MODULE_EXECUTION_CODE,
        payload: currentModuleExecutionCode,
      });
    } else if (result.length === 1) {
      dispatch({
        type: ACTION_TYPES.SET_MODULE_EXECUTION_CODE,
        payload: result[0],
      });
    }
  };
  const setModuleExecutionCode: (code: string) => Thunk = (code) => (dispatch, getState) => {
    const currentModuleId = getState().moduleRegistrationExport.filterSelection.moduleId;
    const splits = code?.split('_') ?? [];
    if (splits.length > 1) {
      const moduleCode = `${splits[0]}_${splits[1]}`;
      const currentModule = getState().moduleSelection.modules.find((m) => m.code === moduleCode);
      if (currentModule && currentModuleId !== currentModule.id) {
        dispatch({
          type: ACTION_TYPES.SET_MODULE,
          payload: currentModule,
        });
      } else if (!currentModule) {
        dispatch({
          type: ACTION_TYPES.SET_MODULE,
          payload: null,
        });
      }
    }

    dispatch({
      type: ACTION_TYPES.SET_MODULE_EXECUTION_CODE,
      payload: code,
    });
  };

  const setFileImport = (fileImport: ISelectionOfLong) => ({
    type: ACTION_TYPES.SET_FILEIMPORT,
    payload: fileImport,
  });

  const setPresetAsync: (preset: IFilterPresetDto) => AsyncThunk = (preset) => async (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SET_PRESET,
      payload: preset,
    });

    const a = dispatch(updateModuleFilterAsync(preset?.filter));
    const b = dispatch(updateFileImportFilterAsync(preset?.filter));
    await Promise.all([a, b]);
  };

  const setStateAt = (date: DateTime | null) => ({
    type: ACTION_TYPES.SET_STATE_AT,
    payload: date,
  });

  const setNewerThen = (date: DateTime | null) => ({
    type: ACTION_TYPES.SET_NEWER_THEN,
    payload: date,
  });

  const setHideRegistrations = (hide: boolean) => ({
    type: ACTION_TYPES.SET_HIDE_REGISTRATIONS,
    payload: hide,
  });

  const setShowDeregistrations = (show: boolean) => ({
    type: ACTION_TYPES.SET_SHOW_DEREGISTRATIONS,
    payload: show,
  });

  const setHasComment = (hasComment: boolean) => ({
    type: ACTION_TYPES.SET_HAS_COMMENT,
    payload: hasComment,
  });

  const setHasInternalComment = (hasInternalComment: boolean) => ({
    type: ACTION_TYPES.SET_HAS_INTERNAL_COMMENT,
    payload: hasInternalComment,
  });

  const setHasNoUasEmail = (hasNoUasEmail: boolean) => ({
    type: ACTION_TYPES.SET_HAS_NO_UAS_EMAIL,
    payload: hasNoUasEmail,
  });

  const setAdvisorSearchLoading = (loading: boolean) => ({
    type: ACTION_TYPES.SET_ADVISOR_SEARCH_LOADING,
    payload: loading,
  });

  const setStudentSearchLoading = (loading: boolean) => ({
    type: ACTION_TYPES.SET_STUDENT_SEARCH_LOADING,
    payload: loading,
  });

  const setHasCompensationForDisadvantages = (hasCompensationForDisadvantages: boolean) => ({
    type: ACTION_TYPES.SET_HAS_COMPENSATION_FOR_DISADVANTAGES,
    payload: hasCompensationForDisadvantages,
  });

  const loadPreviewAsync: (
    filterSelection: IRegistrationFilterSelectionDto,
  ) => AsyncThunk<IModuleRegistrationInfoDto[]> = (filterSelection) => async (dispatch) => {
    dispatch(setPreviewLoading(true));
    dispatch(setPreview([]));
    try {
      const result = await registrationExportClient.getRegistrationExportPreview(
        new RegistrationFilterSelectionDto(filterSelection),
      );
      dispatch(setPreview(result));
      dispatch(setFilterSelection(filterSelection));
      dispatch(setPreviewLoading(false));
      return result;
    } catch (e) {
      dispatch(addExceptionNotification(e));
      dispatch(setPreviewLoading(false));
    }

    return null;
  };

  const setStudentSearchResult = (students: IStudentPreviewDto[]) => ({
    type: ACTION_TYPES.SET_STUDENT_SEARCH_RESULT,
    payload: students,
  });

  const setAdvisorSearchResult = (advisors: IAdvisorDto[]) => ({
    type: ACTION_TYPES.SET_ADVISOR_SEARCH_RESULT,
    payload: advisors,
  });

  const searchStudentAsync: (searchString: string) => AsyncThunk<IStudentPreviewDto[]> =
    (searchString) => async (dispatch, getState) => {
      dispatch(setStudentSearchLoading(true));
      try {
        const filter = getState().moduleRegistrationExport.filterSelection;
        const semesterIds = filter?.semesterIds;
        const uasIds = filter?.uasIds;
        const searchResult = await studentClient.search(new PersonSearchDto({ uasIds, semesterIds, searchString }));
        dispatch(setStudentSearchResult(searchResult));
        if (searchResult.length === 1) {
          dispatch(setStudent(searchResult[0]));
        }
        dispatch(setStudentSearchLoading(false));
        return searchResult;
      } catch (e) {
        dispatch(addExceptionNotification(e, 'An unknown error occured while searching.'));
        dispatch(setStudentSearchLoading(false));
        return [];
      }
    };

  const searchAdvisorAsync: (searchString: string) => AsyncThunk<IAdvisorDto[]> =
    (searchString) => async (dispatch, getState) => {
      dispatch(setAdvisorSearchLoading(true));
      try {
        const filter = getState().moduleRegistrationExport.filterSelection;
        const semesterIds = filter?.semesterIds;
        const uasIds = filter?.uasIds;
        const searchResult = await advisorClient.search(new PersonSearchDto({ uasIds, semesterIds, searchString }));
        dispatch(setAdvisorSearchResult(searchResult));
        if (searchResult.length === 1) {
          dispatch(setAdvisor(searchResult[0]));
        }
        dispatch(setAdvisorSearchLoading(false));
        return searchResult;
      } catch (e) {
        dispatch(addExceptionNotification(e, 'An unknown error occured while searching.'));
        dispatch(setAdvisorSearchLoading(false));
        return [];
      }
    };

  const createFilterPresetAsync: (name: string, roles: IRoleDto[]) => AsyncThunk<IFilterPresetDto> =
    (name, roles) => async (dispatch, getState) => {
      dispatch(setPresetsLoading(true));
      try {
        const filter = getState().moduleRegistrationExport.filterSelection;
        const id = await presetClient.add(
          new FilterPresetDto({
            filter: new RegistrationFilterSelectionDto(filter),
            name,
            forRoles: roles.map((r) => r.roleType),
            id: 0,
            isOwner: true,
          }),
        );
        const presets = await presetClient.list();
        dispatch(setPresets(presets));
        const createdPreset = presets.find((p) => p.id === id);
        await dispatch(setPresetAsync(createdPreset));
        dispatch(setPresetsLoading(false));
        return createdPreset;
      } catch (e) {
        dispatch(addExceptionNotification(e, 'An unknown error occured while saving the filter preset.'));
        dispatch(setPresetsLoading(false));
      }
      return null;
    };

  const updateFilterPresetAsync: (
    preset: IFilterPresetDto,
    name: string,
    roles: IRoleDto[],
  ) => AsyncThunk<IFilterPresetDto> = (preset, name, roles) => async (dispatch, getState) => {
    dispatch(setPresetsLoading(true));
    try {
      const filter = getState().moduleRegistrationExport.filterSelection;
      await presetClient.update(
        new FilterPresetDto({
          ...preset,
          name,
          forRoles: roles.map((r) => r.roleType),
          filter: new RegistrationFilterSelectionDto(filter),
        }),
      );
      const presets = await presetClient.list();
      dispatch(setPresets(presets));
      const updatedPreset = presets.find((p) => p.id === preset.id);
      await dispatch(setPresetAsync(updatedPreset));
      dispatch(setPresetsLoading(false));
      return updatedPreset;
    } catch (e) {
      dispatch(addExceptionNotification(e, 'An unknown error occured while saving the filter preset.'));
      dispatch(setPresetsLoading(false));
    }
    return null;
  };

  const deleteFilterPresetAsync: (preset: IFilterPresetDto) => AsyncThunk = (preset) => async (dispatch) => {
    dispatch(setPresetsLoading(true));
    try {
      await presetClient.delete(preset.id);
      const presets = await presetClient.list();
      dispatch(setPresets(presets));
      await dispatch(setPresetAsync(null));
      dispatch(setPresetsLoading(false));
    } catch (e) {
      dispatch(addExceptionNotification(e, 'An unknown error occured while deleting the filter preset.'));
      dispatch(setPresetsLoading(false));
    }
  };

  const resetFiltersAsync: () => AsyncThunk = () => async (dispatch) => {
    dispatch({
      type: ACTION_TYPES.RESET,
    });
    await dispatch(loadFiltersAsync());
    await dispatch(loadPresetsAsync());
  };

  const changeAdvisorSearchString = (searchString: string) => ({
    type: ACTION_TYPES.CHANGE_ADVISOR_SEARCH_STRING,
    payload: searchString,
  });

  const changeStudentSearchString = (searchString: string) => ({
    type: ACTION_TYPES.CHANGE_STUDENT_SEARCH_STRING,
    payload: searchString,
  });

  const addCommentAsync: (comment: ICommentDto) => AsyncThunk = (comment) => async (dispatch) => {
    try {
      const id = await registrationEditClient.addComment(new CommentDto(comment));
      const newComment = new CommentDto({ ...comment, id });
      dispatch({
        type: ACTION_TYPES.ADD_REGISTRATION_COMMENT,
        payload: newComment,
      });
      dispatch(addSuccess(`Successfully added comment.`));
    } catch (e) {
      dispatch(addExceptionNotification(e, 'An unknown error occured while saving comment.'));
    }
  };

  const removeCommentAsync: (comment: ICommentDto) => AsyncThunk = (comment) => async (dispatch) => {
    try {
      await registrationEditClient.deleteComment(comment.id);

      dispatch({
        type: ACTION_TYPES.REMOVE_REGISTRATION_COMMENT,
        payload: comment,
      });
      dispatch(addSuccess(`Successfully deleted comment.`));
    } catch (e) {
      dispatch(addExceptionNotification(e, 'An unknown error occured while deleting comment.'));
    }
  };

  const getHistoryAsync = async (moduleRegistrationId: number): Promise<IRegistrationStateChangeDto[]> => {
    return await registrationEditClient.getHistory(moduleRegistrationId);
  };

  return Object.freeze({
    exportAsync: async (filterSelection: IRegistrationFilterSelectionDto): Promise<FileResponse> =>
      await componentDispatch(exportAsync(filterSelection)),
    loadFiltersAsync: async (): Promise<void> => await componentDispatch(loadFiltersAsync()),
    loadPresetsAsync: async (): Promise<IFilterPresetDto[]> => await componentDispatch(loadPresetsAsync()),
    addUasFilterAsync: async (uas: IUasDto): Promise<void> => await componentDispatch(addUasFilterAsync(uas)),
    removeUasFilterAsync: async (uas: IUasDto): Promise<void> => await componentDispatch(removeUasFilterAsync(uas)),
    addSemesterFilterAsync: async (semester: ISemesterDto): Promise<void> =>
      await componentDispatch(addSemesterFilterAsync(semester)),
    removeSemesterFilterAsync: async (semester: ISemesterDto): Promise<void> =>
      await componentDispatch(removeSemesterFilterAsync(semester)),
    addColumn: (column: IExcelColumn): void => componentDispatch(addColumn(column)),
    removeColumn: (column: IExcelColumn): void => componentDispatch(removeColumn(column)),
    setPhaseFilterAsync: async (phase: IPhaseDto): Promise<void> => await componentDispatch(setPhaseFilterAsync(phase)),
    addStateFilter: (state: RegistrationState): void => componentDispatch(addStateFilter(state)),
    removeStateFilter: (state: RegistrationState): void => componentDispatch(removeStateFilter(state)),
    addLabelFilter: (label: ISelectionOfLong): void => componentDispatch(addLabelFilter(label)),
    removeLabelFilter: (label: ISelectionOfLong): void => componentDispatch(removeLabelFilter(label)),
    addProfileFilter: (profile: ISelectionOfLong): void => componentDispatch(addProfileFilter(profile)),
    removeProfileFilter: (profile: ISelectionOfLong): void => componentDispatch(removeProfileFilter(profile)),
    addLocationFilter: (location: ISelectionOfLong): void => componentDispatch(addLocationFilter(location)),
    removeLocationFilter: (location: ISelectionOfLong): void => componentDispatch(removeLocationFilter(location)),
    setStudent: (student: IStudentPreviewDto): void => componentDispatch(setStudent(student)),
    setAdvisor: (advisor: IAdvisorDto): void => componentDispatch(setAdvisor(advisor)),
    setModuleCategoryAsync: async (moduleCategory: IModuleCategoryDto): Promise<void> =>
      await componentDispatch(setModuleCategoryAsync(moduleCategory)),
    setModuleAsync: async (module: IModuleDto): Promise<void> => await componentDispatch(setModuleAsync(module)),
    setModuleExecutionCode: (code: string): void => componentDispatch(setModuleExecutionCode(code)),
    setFileImport: (fileImport: ISelectionOfLong): void => componentDispatch(setFileImport(fileImport)),
    setStateAt: (date: DateTime | null): void => componentDispatch(setStateAt(date)),
    setNewerThen: (date: DateTime | null): void => componentDispatch(setNewerThen(date)),
    setPresetAsync: async (preset: IFilterPresetDto): Promise<void> => await componentDispatch(setPresetAsync(preset)),
    setHideRegistrations: (hide: boolean): void => componentDispatch(setHideRegistrations(hide)),
    setShowDeregistrations: (show: boolean): void => componentDispatch(setShowDeregistrations(show)),
    setHasComment: (hasComment: boolean): void => componentDispatch(setHasComment(hasComment)),
    setHasInternalComment: (hasInternalComment: boolean): void =>
      componentDispatch(setHasInternalComment(hasInternalComment)),
    setHasCompensationForDisadvantages: (hasCompensationForDisadvantages: boolean): void =>
      componentDispatch(setHasCompensationForDisadvantages(hasCompensationForDisadvantages)),
    setHasNoUasEmail: (hasNoUasEmail: boolean): void => componentDispatch(setHasNoUasEmail(hasNoUasEmail)),
    loadPreviewAsync: async (filterSelection: IRegistrationFilterSelectionDto): Promise<IModuleRegistrationInfoDto[]> =>
      await componentDispatch(loadPreviewAsync(filterSelection)),
    searchStudentAsync: async (searchString: string): Promise<IStudentDto> =>
      await componentDispatch(searchStudentAsync(searchString)),
    searchAdvisorAsync: async (searchString: string): Promise<IAdvisorDto> =>
      await componentDispatch(searchAdvisorAsync(searchString)),
    createFilterPresetAsync: async (name: string, roles: IRoleDto[]): Promise<IFilterPresetDto> =>
      await componentDispatch(createFilterPresetAsync(name, roles)),
    updateFilterPresetAsync: async (preset: IFilterPresetDto, name: string, roles: IRoleDto[]): Promise<void> =>
      await componentDispatch(updateFilterPresetAsync(preset, name, roles)),
    deleteFilterPresetAsync: async (preset: IFilterPresetDto): Promise<void> =>
      await componentDispatch(deleteFilterPresetAsync(preset)),

    resetFiltersAsync: async (): Promise<void> => await componentDispatch(resetFiltersAsync()),
    changeAdvisorSearchString: (searchString: string) => componentDispatch(changeAdvisorSearchString(searchString)),
    changeStudentSearchString: (searchString: string) => componentDispatch(changeStudentSearchString(searchString)),
    addCommentAsync: async (comment: ICommentDto) => await componentDispatch(addCommentAsync(comment)),
    removeCommentAsync: async (comment: ICommentDto) => await componentDispatch(removeCommentAsync(comment)),
    addStudentTypeFilter: (studentType: StudentType) => componentDispatch(addStudentTypeFilter(studentType)),
    removeStudentTypeFilter: (studentType: StudentType) => componentDispatch(removeStudentTypeFilter(studentType)),
    getHistoryAsync,
  });
};

export default initActions;

export type ModuleRegistrationExportActions = ReturnType<typeof initActions>;
