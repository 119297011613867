import initActions from './actions';
import types from './types';
import reducer from './reducer';
import { IModuleSelectionClient } from 'app/clients/services';
import { resolver } from 'app/clients/service-registry';
import semesterSelectionModule from '../semester-selection';

export type { ModuleSelectionState } from './reducer';

export default {
  initActions: () => initActions(resolver.resolve<IModuleSelectionClient>('IModuleSelectionClient'), semesterSelectionModule.initActions()),
  types,
  reducer,
};
