import EditIcon from '@mui/icons-material/Edit';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import PolicyIcon from '@mui/icons-material/Policy';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { ChangeType, IRegistrationStateChangeDto } from 'app/clients/services';
import { APP_DATETIME_FORMAT } from 'app/config/constants';
import React from 'react';
import { Link } from 'react-router-dom';
import { StandardChip } from '../ui-elements/chip';

type Props = {
  changes: IRegistrationStateChangeDto[];
  moduleExecutionId: number;
};

export const StateChanges = (props: Props) => {
  const Comment = (params: { stateChange: IRegistrationStateChangeDto; moduleExecutionId: number }) => {
    if (params.stateChange.fileImportId) {
      return (
        <Link
          to={'/registration-import/list/' + params.stateChange.fileImportId}
          style={{ textDecoration: 'none', verticalAlign: 'top' }}
        >
          <Typography>
            <InboxIcon style={{ verticalAlign: 'bottom', paddingRight: 5 }} />
            File Import: {params.stateChange.fileImport}
            {params.stateChange.comment && (
              <>
                <br />
                Comment: {params.stateChange.comment}
              </>
            )}
          </Typography>
        </Link>
      );
    }

    if (params.stateChange.comment) {
      return (
        <Typography>
          <EditIcon style={{ verticalAlign: 'bottom', paddingRight: 5 }} />
          {params.stateChange.comment}
        </Typography>
      );
    }

    if (params.stateChange.changeType === ChangeType.Prioritization) {
      return (
        <Link
          to={'/module-registrations/modules/' + props.moduleExecutionId}
          style={{ textDecoration: 'none', verticalAlign: 'top' }}
        >
          <Typography>
            <PlaylistAddCheckIcon style={{ verticalAlign: 'bottom', paddingRight: 5 }} />
            updated in registration process
          </Typography>
        </Link>
      );
    }

    if (params.stateChange.changeType === ChangeType.Manual) {
      return (
        <Typography>
          <EditIcon style={{ verticalAlign: 'bottom', paddingRight: 5 }} />
          manually changed
        </Typography>
      );
    }

    return (
      <Typography>
        <PolicyIcon style={{ verticalAlign: 'bottom', paddingRight: 5 }} />
        manually changed by an admin
      </Typography>
    );
  };

  return (
    <TableContainer component={Paper} style={{ padding: 5, margin: 5 }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>To State</TableCell>
            <TableCell>By</TableCell>
            <TableCell>Phase</TableCell>
            <TableCell>Comment</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.changes &&
            props.changes?.map((c, k) => (
              <TableRow key={k}>
                <TableCell>{c.date.toFormat(APP_DATETIME_FORMAT)}</TableCell>
                <TableCell>
                  <StandardChip label={c.newState.toString()} />
                </TableCell>
                <TableCell>{c.uploadedBy ? c.uploadedBy : c.createdBy}</TableCell>
                <TableCell>{c.phase}</TableCell>
                <TableCell>
                  <Comment stateChange={c} moduleExecutionId={props.moduleExecutionId} />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
