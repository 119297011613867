import Lock from '@mui/icons-material/Lock';
import LockOpen from '@mui/icons-material/LockOpen';
import { IconButton } from '@mui/material';
import { getLoginUrl } from 'app/shared/util/url-utils';
import React from 'react';
import { NavLink as Link } from 'react-router-dom';

const accountMenuItemsAuthenticated = (
  <Link to="/logout">
    <IconButton aria-label="Logout" aria-controls="menu-appbar" aria-haspopup="true">
      <LockOpen />
    </IconButton>
  </Link>
);

const accountMenuItems = (
  <IconButton aria-label="Login" aria-controls="menu-appbar" aria-haspopup="true">
    <a href={getLoginUrl()} style={{ color: 'inherit', textDecoration: 'none', marginTop: -8 }}>
      <Lock />
    </a>
  </IconButton>
);

export const AccountMenu = ({ isAuthenticated = false }) => (
  <div>{isAuthenticated ? accountMenuItemsAuthenticated : accountMenuItems}</div>
);

export default AccountMenu;
