import DeleteIcon from '@mui/icons-material/DeleteForever';
import SaveIcon from '@mui/icons-material/Save';
import { Button, FormControl, Grid, InputLabel, LinearProgress, MenuItem, Typography } from '@mui/material';
import {
  ChangeType,
  IAdvisorDto,
  ICommentDto,
  ILabelDto,
  IModuleExecutionDto,
  IModuleRegistrationDto,
  IProfileDto,
  IRegistrationStateChangeDto,
  ISemesterDto,
  ISemesterRegistrationDto,
  IUasDto,
  IUserDto,
  ModuleRegistrationDto,
  RegistrationState,
} from 'app/clients/services';
import { FastField, Field, Form, Formik } from 'formik';
import { CheckboxWithLabel, Select } from 'formik-mui';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';

import AddIcon from '@mui/icons-material/Add';
import { studentTypes } from 'app/clients/data/student-types';
import { EditModuleRegistrationDialog } from 'app/shared/layout/parts/edit-module-registration-dialog';
import { DateTime } from 'luxon';
import { ModuleRegistrations } from '../../../shared/layout/parts/module-registrations';
import { ConfirmDeleteSemesterRegistration } from './confirm-delete-semester';

type SemesterRegistrationFormProps = {
  loading: boolean;
  isAdmin: boolean;
  semesterRegistration: ISemesterRegistrationDto;
  semesters: ISemesterDto[];
  uas: IUasDto[];
  profiles: IProfileDto[];
  labels: ILabelDto[];
  fetchModuleExecutions: (semesterId: number) => Promise<IModuleExecutionDto[]>;
  fetchAdvisors: (uasId: number) => Promise<IAdvisorDto[]>;
  moduleRegistrationLoading?: number;
  onSubmit: (semesterRegistration: ISemesterRegistrationDto) => Promise<void>;
  onCancel: () => void;
  onDelete: (semesterRegistration: ISemesterRegistrationDto) => Promise<void>;
  onDeleteModuleRegistration: (moduleRegistration: IModuleRegistrationDto) => Promise<void>;
  onSaveModuleRegistration: (moduleRegistration: IModuleRegistrationDto, comment: ICommentDto) => Promise<void>;
  onAddComment: (c: ICommentDto) => Promise<void>;
  onRemoveComment: (c: ICommentDto) => Promise<void>;
  getHistoryAsync: (registrationId: number) => Promise<IRegistrationStateChangeDto[]>;
  currentUser: IUserDto;
  moduleRegistrationErrors: { key: number; message: string }[];
};

export const SemesterRegistrationForm = (props: SemesterRegistrationFormProps) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [addNewRegistrationOpen, setAddNewRegistrationOpen] = useState<IModuleRegistrationDto>(null);
  const [advisors, setAdvisors] = useState<IAdvisorDto[]>([]);
  const [advisorsLoading, setAdvisorsLoading] = useState<boolean>(false);
  const [moduleExecutions, setModuleExecutions] = useState<IModuleExecutionDto[]>([]);
  const [moduleExecutionsLoading, setModuleExecutionsLoading] = useState<boolean>(false);

  const fetchAdvisors = async (uasId: number) => {
    if (uasId) {
      setAdvisorsLoading(true);
      try {
        const loadedAdvisors = await props.fetchAdvisors(uasId);
        setAdvisors(loadedAdvisors);
        setAdvisorsLoading(false);
      } catch (e) {
        setAdvisorsLoading(false);
        throw e;
      }
    }
  };

  const fetchModuleExecutions = async (semesterId: number) => {
    if (semesterId) {
      setModuleExecutionsLoading(true);
      try {
        const loadedExecutions = await props.fetchModuleExecutions(semesterId);
        setModuleExecutions(loadedExecutions);
        setModuleExecutionsLoading(false);
      } catch (e) {
        setModuleExecutionsLoading(false);
        throw e;
      }
    }
  };

  useEffect(() => {
    fetchAdvisors(props.semesterRegistration?.registeredAtId);
    fetchModuleExecutions(props.semesterRegistration?.semesterId);
  }, []);

  return (
    <div style={{ width: '100%' }}>
      {!props.loading && (
        <Formik
          enableReinitialize
          initialValues={props.semesterRegistration}
          validationSchema={Yup.object({
            registeredAtId: Yup.number().required('required'),
            profileId: Yup.number().required('required'),
          })}
          onSubmit={(values, { setSubmitting }) => {
            props.onSubmit(values).then(() => setSubmitting(false));
          }}
        >
          {({ submitForm, isSubmitting, setFieldValue }) => (
            <Form>
              <Grid container direction="row" justifyContent="flex-start" alignItems="flex-end" spacing={5}>
                <Grid item xs={3}>
                  <FormControl sx={{ width: '100%' }} variant="standard">
                    <InputLabel
                      id={`semesterId-label-${props.semesterRegistration?.id ?? 0}`}
                      htmlFor={`semesterId`}
                      shrink
                    >
                      Semester
                    </InputLabel>
                    <Field
                      component={Select}
                      variant="standard"
                      name={`semesterId`}
                      inputProps={{
                        id: `semesterId`,
                      }}
                      labelId={`semesterId-label-${props.semesterRegistration?.id ?? 0}`}
                      disabled={!props.isAdmin || props.semesterRegistration.id > 0}
                      onChange={async (e) => {
                        const semesterId = Number(e.target?.value);
                        await fetchModuleExecutions(semesterId);
                        setFieldValue('semesterId', semesterId);
                      }}
                    >
                      {props.semesters.map((s, sk) => (
                        <MenuItem key={sk} value={s.id}>
                          {s.name}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl sx={{ width: '100%' }} variant="standard">
                    <InputLabel
                      htmlFor={`registeredAtId`}
                      id={`registeredAtId-label-${props.semesterRegistration?.id ?? 0}`}
                      shrink
                    >
                      University of Applied Science
                    </InputLabel>
                    <Field
                      component={Select}
                      variant="standard"
                      name={`registeredAtId`}
                      inputProps={{
                        id: `registeredAtId`,
                      }}
                      labelId={`registeredAtId-label-${props.semesterRegistration?.id ?? 0}`}
                      disabled={!props.isAdmin || props.semesterRegistration.id > 0 || props.uas.length === 1}
                      onChange={async (e) => {
                        const registeredAtId = Number(e.target.value);
                        await fetchAdvisors(registeredAtId);
                        setFieldValue('registeredAtId', registeredAtId);
                      }}
                    >
                      {props.semesterRegistration.id === 0 ? (
                        props.uas
                          .filter((u) => u.isActive || props.semesterRegistration.registeredAtId === u.id)
                          .map((s, sk) => (
                            <MenuItem key={sk} value={s.id}>
                              {s.code}
                            </MenuItem>
                          ))
                      ) : (
                        <MenuItem value={props.semesterRegistration.registeredAtId}>
                          {props.semesterRegistration.registeredAt}
                        </MenuItem>
                      )}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl sx={{ width: '100%' }} variant="standard">
                    <InputLabel
                      htmlFor={`studentType`}
                      id={`studentType-label-${props.semesterRegistration?.id ?? 0}`}
                      shrink
                    >
                      Student Type
                    </InputLabel>
                    <Field
                      component={Select}
                      labelId={`studentType-label-${props.semesterRegistration?.id ?? 0}`}
                      variant="standard"
                      name={`studentType`}
                      inputProps={{
                        id: `studentType`,
                      }}
                      disabled={!props.isAdmin}
                    >
                      {studentTypes.map((s, sk) => (
                        <MenuItem key={sk} value={s.key}>
                          {s.title}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                </Grid>

                <Grid item xs={3}>
                  <Field
                    disabled={!props.isAdmin}
                    component={CheckboxWithLabel}
                    type="checkbox"
                    name="isActive"
                    Label={{ label: 'Is Active' }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControl style={{ width: '100%' }} variant="standard">
                    <InputLabel
                      htmlFor={`profileId`}
                      id={`profile-label-${props.semesterRegistration?.id ?? 0}`}
                      shrink
                    >
                      Profile
                    </InputLabel>
                    <FastField
                      labelId={`profile-label-${props.semesterRegistration?.id ?? 0}`}
                      disabled={!props.isAdmin}
                      variant="standard"
                      component={Select}
                      name={`profileId`}
                      inputProps={{
                        id: `profileId`,
                      }}
                    >
                      {props.profiles &&
                        props.profiles
                          .filter(
                            (p) => p.isActive || p.inSemesters.some((s) => props.semesterRegistration.semesterId === s),
                          )
                          .map((p, k) => (
                            <MenuItem key={k + 1} value={p.id}>
                              {p.code}
                            </MenuItem>
                          ))}
                    </FastField>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  {advisorsLoading ? (
                    <LinearProgress></LinearProgress>
                  ) : (
                    <FormControl
                      style={{ width: '100%' }}
                      variant="standard"
                      id={`advisorId-label-${props.semesterRegistration?.id ?? 0}`}
                    >
                      <InputLabel htmlFor={`advisorId`} shrink id={`advisor-${props.semesterRegistration?.id ?? 0}`}>
                        Advisor
                      </InputLabel>
                      <Field
                        component={Select}
                        variant="standard"
                        labelId={`advisorId-label-${props.semesterRegistration?.id ?? 0}`}
                        name={`advisorId`}
                        inputProps={{
                          id: `advisorId`,
                        }}
                        disabled={!props.isAdmin}
                      >
                        <MenuItem key={0} value={null}>
                          None
                        </MenuItem>
                        {advisors
                          .filter((a) => a.isActive)
                          .map((a, ak) => (
                            <MenuItem key={ak + 1} value={a.id}>
                              {a.familyName}, {a.firstName}
                            </MenuItem>
                          ))}
                      </Field>
                    </FormControl>
                  )}
                </Grid>
                {/* <Grid item xs={3}>
                  <FormControl style={{ width: '100%' }} variant="standard">
                    <InputLabel htmlFor={`labelId`}>Label</InputLabel>
                    <FastField
                      component={Select}
                      name={`labelId`}
                      variant="standard"
                      inputProps={{
                        id: `labelId`,
                      }}
                      disabled={!props.isAdmin}
                    >
                      <MenuItem key={0} value={null}></MenuItem>
                      {props.labels &&
                        props.labels
                          .filter((p) => p.isActive)
                          .map((p, k) => (
                            <MenuItem key={k + 1} value={p.id}>
                              {p.name}
                            </MenuItem>
                          ))}
                    </FastField>
                  </FormControl>
                </Grid> */}
                {props.isAdmin && props.semesterRegistration.studentId && (
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting || props.loading}
                      type="submit"
                      onClick={submitForm}
                      startIcon={<SaveIcon />}
                    >
                      Update Semester Registration
                    </Button>
                    {props.isAdmin && props.semesterRegistration.moduleRegistrations?.length === 0 && (
                      <>
                        {props.semesterRegistration.id ? (
                          <Button
                            variant="contained"
                            color="inherit"
                            disabled={isSubmitting || props.loading}
                            onClick={() => setDeleteDialogOpen(true)}
                            startIcon={<DeleteIcon />}
                          >
                            Delete Semester Registration
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            color="inherit"
                            disabled={isSubmitting || props.loading}
                            onClick={() => props.onCancel()}
                            startIcon={<DeleteIcon />}
                          >
                            Cancel Adding Semester Registration
                          </Button>
                        )}
                        <ConfirmDeleteSemesterRegistration
                          semesterRegistration={props.semesterRegistration}
                          open={deleteDialogOpen}
                          onDelete={(s) => props.onDelete(s)}
                          onClose={() => setDeleteDialogOpen(false)}
                        />
                      </>
                    )}
                  </Grid>
                )}
              </Grid>
            </Form>
          )}
        </Formik>
      )}
      {props.semesterRegistration.id > 0 && (
        <div style={{ width: '100%', marginTop: 20, padding: 5 }}>
          <Typography>Module Registrations: </Typography>
          <ModuleRegistrations
            isAdmin={props.isAdmin}
            columnFilter={{
              isAdmin: props.isAdmin,
              removeColumns: ['uas', 'semester', 'academicYear', 'studentFamilyName', 'studentFirstName'],
              sortable: true,
            }}
            currentUser={props.currentUser}
            loading={moduleExecutionsLoading || props.loading}
            moduleExecutions={moduleExecutions}
            rows={props.semesterRegistration.moduleRegistrations}
            labels={props.labels}
            delete={async (m) => await props.onDeleteModuleRegistration(m)}
            save={async (m, comment) => await props.onSaveModuleRegistration(m, comment)}
            onAddComment={async (m) => await props.onAddComment(m)}
            onRemoveComment={async (m) => await props.onRemoveComment(m)}
            getHistory={props.getHistoryAsync}
          />
          <EditModuleRegistrationDialog
            open={!!addNewRegistrationOpen}
            moduleExecutions={moduleExecutions.filter(
              (m) =>
                props.semesterRegistration.moduleRegistrations.filter((r) => r.moduleId === m.moduleId).length === 0,
            )}
            moduleRegistration={addNewRegistrationOpen}
            states={[RegistrationState.REQUESTED]}
            onSubmit={async (m, comment) => await props.onSaveModuleRegistration(m, comment)}
            onClose={() => setAddNewRegistrationOpen(null)}
            getHistory={props.getHistoryAsync}
            isAdmin={props.isAdmin}
            student={null}
            labels={props.labels}
          />
          {props.isAdmin && (
            <Button
              sx={{ marginTop: 1 }}
              variant="contained"
              color="inherit"
              disabled={props.loading}
              onClick={() =>
                setAddNewRegistrationOpen(
                  new ModuleRegistrationDto({
                    id: 0,
                    moduleExecution: '',
                    moduleExecutionId: 0,
                    moduleId: 0,
                    semesterRegistrationId: props.semesterRegistration.id,
                    state: RegistrationState.REQUESTED,
                    dateOfRegistration: DateTime.local(),
                    createdDate: DateTime.local(),
                    compensationForDisadvantages: false,
                    specialLabelId: null,
                    changeType: props.isAdmin ? ChangeType.ManualByAdmin : ChangeType.Manual,
                    hasSpecialLabel: false,
                  }),
                )
              }
              startIcon={<AddIcon />}
            >
              Add New Module Registration
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
