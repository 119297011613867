import { IRootState } from 'app/config/root.reducer';
import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import AddIcon from '@mui/icons-material/Add';
import { Button, Grid, Paper, Typography } from '@mui/material';
import { IUasDomainDto, UasDomainDto } from 'app/clients/services';
import { MainContent, SideBar, SplitLayout } from 'app/shared/layout/ui-elements/component-layout';
import { NavigationList } from 'app/shared/layout/ui-elements/navigation-list';
import { combineUrlParams, getBasePath } from 'app/shared/util/url-utils';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { DomainForm } from './parts/domain-form';
import { UasRelationsForm } from './parts/uas-relations-form';
import uasModule from './uas';

export type Props = PropsFromRedux;

export const UasDomains = (props: Props) => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const basePath = getBasePath(useLocation(), { id });

  const selectDomain = (domains: IUasDomainDto[], domainId: number) => {
    const domain = domains.find((d) => d.id === domainId) ?? null;
    props.selectDomain(domain);
    return domain;
  };

  const handleDomainChange = (domain: IUasDomainDto) => {
    if (domain) {
      navigate(basePath + combineUrlParams({ id: domain.id }));
    } else {
      navigate(basePath);
    }
  };

  useEffect(() => {
    const loadData = async () => {
      const domains = await props.loadAllDomainsAsync();
      if (id && props.selectedDomain?.id !== Number(id)) {
        selectDomain(domains, Number(id));
      }
    };
    loadData();
  }, [id]);

  return (
    <SplitLayout>
      <SideBar>
        <NavigationList
          title="Domain"
          selectedItem={props.selectedDomain}
          items={props.domains?.filter((p) => p.isActive) ?? []}
          createUrl={(item) => basePath + combineUrlParams({ domainId: item.id })}
          onClick={(item) => handleDomainChange(item)}
          getItemKey={(item) => item.id}
          getItemTitle={(item) => item.name}
        />
        {props.domains.filter((p) => !p.isActive).length > 0 && (
          <NavigationList
            title="Inactive Domains"
            selectedItem={props.selectedDomain}
            items={props.domains.filter((p) => !p.isActive)}
            createUrl={(item) => basePath + combineUrlParams({ uasId: item.id })}
            onClick={(item) => handleDomainChange(item)}
            getItemKey={(item) => item.id}
            getItemTitle={(item) => item.name}
          />
        )}
        <Button
          variant="contained"
          color="inherit"
          sx={{ padding: 1, marginLeft: 2, marginBottom: 2 }}
          disabled={props.loading}
          startIcon={<AddIcon />}
          onClick={() => {
            navigate(basePath);
            const newDomain = new UasDomainDto({
              id: 0,
              isActive: true,
              name: '',
              preferedForStudents: false,
              blockedEmails: [],
              uasIds: [],
            });
            props.selectDomain(newDomain);
          }}
        >
          Add
        </Button>
      </SideBar>
      <MainContent loading={props.loading}>
        <Grid container>
          {props.selectedDomain && (
            <>
              <Grid item xs={12}>
                <Typography variant={'h1'}>Domain: {props.selectedDomain.name}</Typography>
              </Grid>

              <Grid item xs={12} component={Paper} sx={{ padding: 5 }}>
                <DomainForm
                  loading={props.loading}
                  allDomains={props.domains}
                  domain={props.selectedDomain}
                  onSubmit={async (domain) => {
                    await props.saveDomainAsync(domain);
                  }}
                  onDelete={async (domain) => {
                    await props.deleteDomainAsync(domain);
                  }}
                  onCancel={async () => {
                    await props.loadAllDomainsAsync();
                  }}
                  validateEmailAsync={async (email: string) => await props.emailAlreadyExistsAsync('system', email)}
                ></DomainForm>
              </Grid>
            </>
          )}
          {props.selectedDomain?.id > 0 && (
            <Grid item xs={12} style={{ marginTop: 20, padding: 10 }}>
              <UasRelationsForm
                loading={props.loading}
                selectedUas={props.allowedUas?.filter((u) => props.selectedDomain.uasIds?.includes(u.id)) ?? []}
                allUas={props.allowedUas ?? []}
                disabled={false}
                addUasAsync={async (uas) => {
                  await props.addDomainAsync(uas, props.selectedDomain);
                }}
                removeUasAsync={async (uas) => {
                  await props.removeDomainAsync(uas, props.selectedDomain);
                }}
              />
            </Grid>
          )}
        </Grid>
      </MainContent>
    </SplitLayout>
  );
};

const mapDispatchToProps = (dispatch) => {
  const actions = uasModule.initActions(dispatch);
  return actions;
};

const mapStateToProps = ({ uasManagement, auth }: IRootState) => ({
  loading: uasManagement.domainsLoading,
  domains: uasManagement.domains,
  allowedUas: auth.allowedUas,
  selectedDomain: uasManagement.selectedDomain,
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(UasDomains);
