import { StudentType } from '../services';

export const studentTypes = [
  { title: 'Student', key: StudentType.Student },
  { title: 'Exchange', key: StudentType.Exchange },
  { title: 'Erasmus', key: StudentType.Erasmus },
  { title: 'Incoming', key: StudentType.Incoming },
  { title: 'Guest Auditor', key: StudentType.GuestAuditor },
  { title: 'Unknown', key: StudentType.Unknown },
];
