import {
  ICommentDto,
  IModuleRegistrationDto,
  ISemesterRegistrationDto,
  IStudentDto,
  ModuleRegistrationDto,
  SemesterRegistrationDto,
  StudentDto,
} from 'app/clients/services';
import { addCommentToRegistration, removeCommentFromRegistration } from 'app/shared/util/reducer-helpers';
import ACTION_TYPES from './types';

// State
const initialState = {
  studentLoading: false,
  loadedStudent: null as IStudentDto | null,
  moduleRegistrationErrors: [] as { key: number; message: string }[],
};

export type StudentManagementState = Readonly<typeof initialState>;

// Reducer
export default (state: StudentManagementState = initialState, action): StudentManagementState => {
  switch (action.type) {
    case ACTION_TYPES.SET_STUDENT_LOADING:
      return {
        ...state,
        studentLoading: action.payload,
      };
    case ACTION_TYPES.SET_STUDENT_INFO: {
      const studentInfo = action.payload as IStudentDto;
      if (studentInfo) {
        const student = new StudentDto(studentInfo);
        return {
          ...state,
          loadedStudent: student,
        };
      }
      return {
        ...state,
        loadedStudent: null,
      };
    }
    case ACTION_TYPES.ADD_SEMESTER_REGISTRATION: {
      const semesterRegistration = action.payload as ISemesterRegistrationDto;
      if (semesterRegistration && state.loadedStudent?.id === semesterRegistration.studentId) {
        const semesterRegistrations = [...state.loadedStudent?.semesterRegistrations ?? [], new SemesterRegistrationDto(semesterRegistration)];
        const updatedStudent = new StudentDto({ ...state.loadedStudent, semesterRegistrations } as IStudentDto);
        return {
          ...state,
          loadedStudent: updatedStudent,
        };
      }
      return state;
    }
    case ACTION_TYPES.CANCEL_ADDING_SEMESTER_REGISTRATION: {
      if (state.loadedStudent) {
        const semesterRegistrations = state.loadedStudent.semesterRegistrations?.filter(s => s.id !== 0);
        const updatedStudent = new StudentDto({ ...state.loadedStudent, semesterRegistrations } as IStudentDto);
        return {
          ...state,
          loadedStudent: updatedStudent,
        };
      }

      return state;
    }
    case ACTION_TYPES.UPDATE_SEMESTER_REGISTRATION: {
      const semesterRegistration = action.payload as ISemesterRegistrationDto;
      if (semesterRegistration && state.loadedStudent?.id === semesterRegistration.studentId) {
        const semesterRegistrations = state.loadedStudent?.semesterRegistrations?.map(s =>
          s.id !== semesterRegistration.id ? s : new SemesterRegistrationDto(semesterRegistration)
        ) ?? [];
        const updatedStudent = new StudentDto({ ...state.loadedStudent, semesterRegistrations } as IStudentDto);
        return {
          ...state,
          loadedStudent: updatedStudent,
        };
      }

      return state;
    }
    case ACTION_TYPES.REMOVE_SEMESTER_REGISTRATION: {
      const semesterRegistration = action.payload as ISemesterRegistrationDto;
      if (semesterRegistration && state.loadedStudent?.id === semesterRegistration.studentId) {
        const semesterRegistrations = state.loadedStudent?.semesterRegistrations?.filter(s => s.id !== semesterRegistration.id) ?? [];
        const updatedStudent =new StudentDto({ ...state.loadedStudent, semesterRegistrations } as IStudentDto);
        return {
          ...state,
          loadedStudent: updatedStudent,
        };
      }

      return state;
    }
    case ACTION_TYPES.ADD_MODULE_REGISTRATION: {
      const moduleRegistration = action.payload as IModuleRegistrationDto;
      if (
        moduleRegistration &&
        state.loadedStudent?.semesterRegistrations?.filter(s => s.id === moduleRegistration.semesterRegistrationId).length
      ) {
        const semesterRegistrations = state.loadedStudent.semesterRegistrations.map(s =>
          s.id === moduleRegistration.semesterRegistrationId
            ? new SemesterRegistrationDto({
                ...s,
                moduleRegistrations: [...s.moduleRegistrations ?? [], new ModuleRegistrationDto(moduleRegistration)],
              })
            : s
        );
        const updatedStudent = { ...state.loadedStudent, semesterRegistrations };
        return {
          ...state,
          loadedStudent: updatedStudent,
        };
      }
      return state;
    }
    case ACTION_TYPES.UPDATE_MODULE_REGISTRATION: {
      const moduleRegistration = action.payload as IModuleRegistrationDto;
      if (
        moduleRegistration &&
        state.loadedStudent?.semesterRegistrations?.filter(s => s.id === moduleRegistration.semesterRegistrationId).length
      ) {
        const semesterRegistrations = state.loadedStudent.semesterRegistrations.map(s =>
          s.id === moduleRegistration.semesterRegistrationId
            ? new SemesterRegistrationDto({
                ...s,
                moduleRegistrations: s.moduleRegistrations?.map(m =>
                  m.id === moduleRegistration.id ? new ModuleRegistrationDto(moduleRegistration) : m
                ) ?? [],
              })
            : s
        );
        const updatedStudent = { ...state.loadedStudent, semesterRegistrations };
        return {
          ...state,
          loadedStudent: updatedStudent,
        };
      }
      return state;
    }
    case ACTION_TYPES.REMOVE_MODULE_REGISTRATION: {
      const moduleRegistration = action.payload as IModuleRegistrationDto;
      if (
        moduleRegistration &&
        state.loadedStudent?.semesterRegistrations?.filter(s => s.id === moduleRegistration.semesterRegistrationId).length
      ) {
        const semesterRegistrations = state.loadedStudent.semesterRegistrations.map(s =>
          s.id === moduleRegistration.semesterRegistrationId
            ? new SemesterRegistrationDto({
                ...s,
                moduleRegistrations: s.moduleRegistrations?.filter(m => m.id !== moduleRegistration.id) ?? [],
              })
            : s
        );
        const updatedStudent = { ...state.loadedStudent, semesterRegistrations };
        return {
          ...state,
          loadedStudent: updatedStudent,
        };
      }
      return state;
    }
    case ACTION_TYPES.ADD_REGISTRATION_COMMENT: {
      const comment = action.payload as ICommentDto;
      return {
        ...state,
        loadedStudent: state.loadedStudent
          ? {
              ...state.loadedStudent,
              semesterRegistrations: state.loadedStudent.semesterRegistrations?.map(s =>
                s.moduleRegistrations?.find(m => m.id === comment.registrationId)
                  ? new SemesterRegistrationDto({
                      ...s,
                      moduleRegistrations: addCommentToRegistration(s.moduleRegistrations, comment),
                    })
                  : s
              ) ?? [],
            }
          : null,
      };
    }
    case ACTION_TYPES.REMOVE_REGISTRATION_COMMENT: {
      const comment = action.payload as ICommentDto;
      return {
        ...state,
        loadedStudent: state.loadedStudent
          ? {
              ...state.loadedStudent,
              semesterRegistrations: state.loadedStudent.semesterRegistrations?.map(s =>
                s.moduleRegistrations?.find(m => m.id === comment.registrationId)
                  ? new SemesterRegistrationDto({
                      ...s,
                      moduleRegistrations: removeCommentFromRegistration(s.moduleRegistrations, comment),
                    })
                  : s
              ) ?? [],
            }
          : null,
      };
    }
    default:
      return state;
  }
};
