import { RoleType } from "app/clients/services";
import { IRootState } from "app/config/root.reducer";
import { hasAnyRole } from "app/shared/auth/roles";
import { ITabModel, NavigationTabs } from "app/shared/layout/ui-elements/navigation-tabs";
import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { rememberOpenTab } from "../application/actions";

export type Props = PropsFromRedux & { baseUrl: string };

const ProfilesManagementTabs = (props: Props) => {
  useEffect(() => {}, []);

  const tabs = [];

  if (props.isProfileAdmin) {
    tabs.push({
      label: "Profiles",
      url: "profiles",
    });
    tabs.push({
      label: "Priorities",
      url: "priorities",
    });
    // tabs.push({
    //   label: 'Labels',
    //   url: 'labels',
    // });
  }

  return <NavigationTabs tabs={tabs} baseUrl={props.baseUrl} openTab={props.openTabs[props.baseUrl]} onChange={(tab: ITabModel) => props.rememberOpenTab(props.baseUrl, tab)} />;
};

function mapStateToProps({ auth, application }: IRootState) {
  return {
    isProfileAdmin: hasAnyRole(auth.account?.roles, [RoleType.ADMIN, RoleType.MODULEADMIN]),
    openTabs: application.openTabs,
  };
}

const mapDispatchToProps = (dispatch) => ({
  rememberOpenTab: (baseUrl: string, tab: ITabModel) => dispatch(rememberOpenTab(baseUrl, tab)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ProfilesManagementTabs);
