import { IAccountClient, IAuthClient, IUasDto, IUserDto } from 'app/clients/services';
import { AsyncThunk, Thunk } from 'app/shared/util/action-type.util';
import ACTION_TYPES from './types';
import { addError } from 'app/modules/notification';

const initActions = (accountClient: IAccountClient, authClient: IAuthClient) => {
  const refreshAllowedUasAsync: () => AsyncThunk<IUasDto[]> = () => async dispatch => {
    const currentUser = await accountClient.getCurrentUserInfo();
    const allowedUas = currentUser?.allowedUas ?? [];
    dispatch({
      type: ACTION_TYPES.SET_ALLOWED_UAS,
      payload: allowedUas,
    });
    return allowedUas;
  };

  const getSessionAsync: () => AsyncThunk<IUserDto> = () => async dispatch => {
    const result = await dispatch({
      type: ACTION_TYPES.GET_SESSION,
      payload: accountClient.getCurrentUserInfo(),
    });
    return result.value;
  };

  const logoutAsync: () => AsyncThunk<string> = () => async dispatch => {
    const result = await dispatch({
      type: ACTION_TYPES.LOGOUT,
      payload: authClient.logOut(),
    });

    // fetch new csrf token
    await dispatch({
      type: ACTION_TYPES.GET_SESSION,
      payload: accountClient.getCurrentUserInfo(),
    });
    return (result.value.data as any).logoutUrl;
  };

  const clearAuthenticationThunk: (message) => Thunk = message => dispatch => {
    dispatch(addError(message));
    dispatch({
      type: ACTION_TYPES.CLEAR_AUTH,
    });
  };

  return Object.freeze({
    refreshAllowedUasAsync,
    getSessionAsync,
    logoutAsync,
    clearAuthenticationThunk,
  });
};

export default initActions;

export type AuthActions = ReturnType<typeof initActions>;
