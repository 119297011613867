import { Box, Chip, Grid, GridSize, LinearProgress, Paper, Typography } from '@mui/material';
import { SelectBox } from 'app/shared/layout/ui-elements/select-box';
import React from 'react';

type Props = {
  loading?: boolean;
  selectedItems: any[];
  items: any[];
  title?: string;
  actionTitle: string;
  getItemTitle: (item: any) => string;
  getItemKey: (item: any) => string;
  getColor?: (item: any) => 'primary' | 'secondary' | 'default';
  onDelete: (item: any) => void | Promise<void>;
  disabled?: boolean;
  hideSelectBox?: boolean;
  onAdd: (uas: any) => void | Promise<void>;
  selectBoxSize?: GridSize;
};

export const ChipSelectionRow = (props: Props) => {
  const items = props.items.filter(
    (i) => !props.selectedItems.map((si) => props.getItemKey(si)).includes(props.getItemKey(i)),
  );

  const selectboxSize = props.selectBoxSize ?? 2;
  const chipContainerSize = Number(selectboxSize) > 6 ? 12 : ((12 - Number(selectboxSize)) as GridSize);
  return (
    <>
      {!props.hideSelectBox && (
        <Grid item md={selectboxSize} xs={12}>
          <SelectBox
            items={items}
            title={props.actionTitle}
            selectedItem={null}
            getItemKey={props.getItemKey}
            getItemTitle={props.getItemTitle}
            onChange={props.onAdd}
            allowEmpty
            disabled={props.disabled || items.length === 0}
          ></SelectBox>
        </Grid>
      )}
      <Grid item md={chipContainerSize} xs={12}>
        <Box
          component="ul"
          style={{
            display: 'flex',
            justifyContent: 'left',
            flexWrap: 'wrap',
            listStyle: 'none',
            padding: 5,
          }}
        >
          {props.selectedItems.map((item, k) => (
            <Box component="li" key={k} style={{ listStyle: 'none' }}>
              <Chip
                label={props.getItemTitle(item)}
                onDelete={!props.disabled ? () => props.onDelete(item) : undefined}
                sx={{ padding: 1, marginRight: 1 }}
                color={props.getColor ? props.getColor(item) : 'default'}
              />
            </Box>
          ))}
        </Box>
      </Grid>
    </>
  );
};

export const ChipSelectionSection = (props: Props) => {
  return (
    <>
      {props.title && <Typography variant="h3">{props.title}:</Typography>}
      {props.loading ? (
        <LinearProgress></LinearProgress>
      ) : (
        <Grid container component={Paper} sx={{ padding: 1 }}>
          <ChipSelectionRow {...props} />
        </Grid>
      )}
    </>
  );
};
