import EditIcon from '@mui/icons-material/Edit';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { ChangeType } from 'app/clients/services';
import React from 'react';

type Props = {
  loading: boolean;
  onClick: () => void;
  data: {
    specialLabelId?: number;
    compensationForDisadvantages?: boolean;
    changeType?: ChangeType;
  };
};

export const RegistrationEditIcon = (props: Props) => {
  return (
    <Tooltip title={<Typography>Edit Registration</Typography>}>
      <IconButton
        style={{ display: 'inline-block' }}
        disabled={props.loading}
        onClick={(event) => {
          props.onClick();
          event.stopPropagation();
        }}
        color={
          props.data?.specialLabelId ||
          props.data.compensationForDisadvantages ||
          props.data.changeType === ChangeType.Manual ||
          props.data.changeType === ChangeType.ManualByAdmin
            ? 'primary'
            : 'secondary'
        }
      >
        <EditIcon />
      </IconButton>
    </Tooltip>
  );
};
