import { Typography } from '@mui/material';
import { IAdvisorImport } from 'app/clients/services';
import React from 'react';
import { DbMapping } from './db-mapping';
import { StringValueChange } from './value-change';

type Props = {
  advisor: IAdvisorImport;
};

export const AdvisorInfo = ({ advisor }: Props) => {
  return (
    <>
      <Typography variant="h6">
        <StringValueChange value={advisor.firstName} valueChange={advisor.firstNameChange} />{' '}
        <StringValueChange value={advisor.familyName} valueChange={advisor.familyNameChange} />
        <DbMapping dbMapping={advisor.dbMapping} entity="Advisor" showIconOnly></DbMapping>
      </Typography>
      <Typography>
        <strong>Uas Email</strong>:{' '}
        {
          <>
            <StringValueChange value={advisor.uasEmail?.name ?? '-'} valueChange={advisor.uasEmail?.nameChange} />
            <DbMapping dbMapping={advisor.uasEmail?.dbMapping} entity="UAS Email" showIconOnly />
          </>
        }
      </Typography>
    </>
  );
};
