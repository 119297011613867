import initActions from './actions';
import reducer from './reducer';
import types from './types';

import { resolver } from 'app/clients/service-registry';
import { IApplicationProfileClient, IRoleSelectionClient } from 'app/clients/services';

export type { ApplicationState } from './reducer';
export default {
  initActions: () =>
    initActions(
      resolver.resolve<IApplicationProfileClient>('IApplicationProfileClient'),
      resolver.resolve<IRoleSelectionClient>('IRoleSelectionClient')
    ),
  types,
  reducer,
};
