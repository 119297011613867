import DeleteIcon from '@mui/icons-material/DeleteForever';
import SaveIcon from '@mui/icons-material/Save';
import { Grid, Typography } from '@mui/material';
import { IModuleCategoryDto, IModuleDto } from 'app/clients/services';
import { DefaultButton } from 'app/shared/layout/ui-elements/buttons';
import { ConfirmationDialog } from 'app/shared/layout/ui-elements/confirmation-dialog';
import { Field, Form, Formik } from 'formik';
import { CheckboxWithLabel, TextField as FormikTextField } from 'formik-mui';
import React, { useState } from 'react';

type ModuleFormProps = {
  loading: boolean;
  module: IModuleDto;
  modules: IModuleDto[];
  hasRelatedData: boolean;
  category: IModuleCategoryDto;
  isNew: boolean;
  onSubmit: (module: IModuleDto) => Promise<void>;
  onCancel: () => void;
  onDelete: (module: IModuleDto) => Promise<void>;
};

export const ModuleForm = (props: ModuleFormProps) => {
  const [deleteModuleDialogOpen, setDeleteModuleDialogOpen] = useState<boolean>(false);
  return (
    <Formik
      enableReinitialize
      initialValues={props.module}
      validate={(values) => {
        const errors: { id?: string; partialCode?: string } = {};
        if (!values.id) {
          errors.id = 'Required';
        } else if (isNaN(values.id) || Number(values.id) <= 0) {
          errors.id = 'Needs to be a number larger than 0!';
        } else if (props.isNew && props.modules.filter((m) => m.id === Number(values.id)).length) {
          errors.id = 'Id already exists!';
        }

        if (!values.partialCode || values.partialCode === 'untitled') {
          errors.partialCode = 'Required';
        }
        if (
          props.modules.filter(
            (p) => p.partialCode === values.partialCode && p.categoryId === values.categoryId && p.id !== values.id,
          ).length > 0
        ) {
          errors.partialCode = 'Code already exists!';
        }

        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        props.onSubmit(values).then(() => setSubmitting(false));
      }}
    >
      {({ submitForm, isSubmitting }) => (
        <Form>
          <Grid container direction="row" justifyContent="flex-start" alignItems="flex-end" spacing={5}>
            <Grid item xs={12} md={1}>
              <Field
                component={FormikTextField}
                name="id"
                type="text"
                label="ID"
                disabled={!props.isNew}
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <div style={{ marginTop: 18, padding: 2, display: 'inline-block', backgroundColor: 'lightgrey' }}>
                <Typography>
                  <strong>{props.category.code}_</strong>
                </Typography>
              </div>
              <Field component={FormikTextField} name="partialCode" type="text" label="Code" variant="standard" />
            </Grid>
            <Grid item xs={12} md={3}>
              <Field component={FormikTextField} name="name" type="text" label="Name" variant="standard" />
            </Grid>
            <Grid item xs={12} md={3}>
              <Field
                component={FormikTextField}
                name="recommendedMaxParticipants"
                type="text"
                label="Standard Capacity"
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Field component={CheckboxWithLabel} type="checkbox" name="isActive" Label={{ label: 'Is Active' }} />
            </Grid>

            <Grid item xs={12} md={3}>
              <DefaultButton
                primary
                disabled={isSubmitting || props.loading}
                onClick={submitForm}
                startIcon={<SaveIcon />}
              >
                Save Module
              </DefaultButton>

              {!props.hasRelatedData && (
                <>
                  {!props.isNew ? (
                    <DefaultButton
                      disabled={isSubmitting || props.loading}
                      onClick={() => setDeleteModuleDialogOpen(true)}
                      startIcon={<DeleteIcon />}
                    >
                      Delete
                    </DefaultButton>
                  ) : (
                    <DefaultButton
                      disabled={isSubmitting || props.loading}
                      onClick={() => props.onCancel()}
                      startIcon={<DeleteIcon />}
                    >
                      Cancel
                    </DefaultButton>
                  )}
                  <ConfirmationDialog
                    title="Delete Category"
                    onConfirm={() => props.onDelete(props.module)}
                    open={deleteModuleDialogOpen}
                    onClose={() => setDeleteModuleDialogOpen(false)}
                  >
                    Do you really want to delete the module{' '}
                    <strong>
                      {props.category.code}_{props.module.partialCode}
                    </strong>
                    ?
                  </ConfirmationDialog>
                </>
              )}
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
