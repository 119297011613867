import initActions from './actions';
import types from './types';
import reducer from './reducer';

export type { StudentTabsState } from './reducer';
export type { StudentTabActions } from './actions';
export default {
  initActions: dispatch => initActions(dispatch),
  types,
  reducer,
};
