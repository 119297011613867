export const getLoginUrl = () => {
  const port = location.port ? `:${location.port}` : '';
  return `//${location.hostname}${port}/api/login?returnUri=${encodeURIComponent(location.pathname)}`;
};
export const combineUrlParams = (params: any) => {
  return Object.values(params)
    .filter((v) => !!v)
    .join('/');
};

export const getBasePath = (location: { pathname: string }, params: Record<string, string>) => {
  const values = Object.entries(params)
    .filter((e) => e[0] !== '*')
    .map((e) => e[1]);
  const result = values.reduce((path, param) => path.replace('/' + param, ''), location.pathname);
  if (!result.endsWith('/')) {
    return result + '/';
  }
  return result;
};

export const trimSlashes = (url: string) => {
  let trimmedUrl = url;
  if (url.startsWith('/')) {
    trimmedUrl = trimmedUrl.substr(1);
  }

  if (url.endsWith('/')) {
    trimmedUrl = trimmedUrl.slice(0, url.lastIndexOf('/'));
  }
  return trimmedUrl;
};
