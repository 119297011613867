import { IAdvisorDto, IUasDomainDto, IUasDto } from 'app/clients/services';
import ACTION_TYPES from './types';

const initialState = {
  loading: false,
  selectedUas: null as IUasDto | null,
  domainsLoading: false,
  domains: [] as IUasDomainDto[],
  domainsForUas: {} as { [id: number]: IUasDomainDto[] },
  selectedDomain: null as IUasDomainDto | null,
  advisorsLoading: false,
  selectedAdvisor: null as IAdvisorDto  | null,
  advisorsWithNoUasRelation: [] as IAdvisorDto[],
};

export type UasManagementState = Readonly<typeof initialState>;

// Reducer
export default (state: UasManagementState = initialState, action): UasManagementState => {
  switch (action.type) {
    case ACTION_TYPES.SET_LOADING: {
      return { ...state, loading: action.payload };
    }
    case ACTION_TYPES.SET_SELECTED_UAS: {
      return { ...state, selectedUas: action.payload };
    }
    case ACTION_TYPES.SET_DOMAINS_LOADING: {
      return { ...state, domainsLoading: action.payload };
    }
    case ACTION_TYPES.SET_SELECTED_DOMAIN: {
      return { ...state, selectedDomain: action.payload };
    }
    case ACTION_TYPES.SET_ADVISORS_WITHOUT_UAS: {
      return { ...state, advisorsWithNoUasRelation: action.payload };
    }
    case ACTION_TYPES.SET_DOMAINS: {
      const domains = action.payload as IUasDomainDto[];
      const selectedDomain = state.selectedDomain?.id ? domains.find(d => d.id === state.selectedDomain.id) ?? null : null;
      const domainsForUas = {};
      for (const [key, value] of Object.entries(state.domainsForUas)) {
        domainsForUas[key] = value.map(ud => domains.find(d => d.id === ud.id)).filter(ud => !!ud);
      }
      return {
        ...state,
        domains,
        selectedDomain,
        domainsForUas,
      };
    }
    case ACTION_TYPES.SET_DOMAINS_FOR_UAS: {
      const uasDomains = action.payload as { uas: IUasDto; domains: IUasDomainDto[] };
      const domainsForUas = { ...state.domainsForUas };
      domainsForUas[uasDomains.uas.id] = uasDomains.domains;
      return { ...state, domainsForUas };
    }
    case ACTION_TYPES.SET_SELECTED_ADVISOR: {
      return { ...state, selectedAdvisor: action.payload };
    }

    default:
      return state;
  }
};
