import { ImportState } from 'app/clients/services';
import React from 'react';
import { DeletedIcon, ImportedIcon, RejectedIcon, RequestedIcon, UploadedIcon, WithdrawnIcon } from './icons';

type Props = {
  state: ImportState;
};

export const ImportStateIcon = (props: Props) => {
  if (props.state === ImportState.IMPORTED) {
    return <ImportedIcon title="Imported"></ImportedIcon>;
  }
  if (props.state === ImportState.REJECTED) {
    return <RejectedIcon title="Rejected"></RejectedIcon>;
  }
  if (props.state === ImportState.UPLOADED) {
    return <UploadedIcon title="Uploaded"></UploadedIcon>;
  }
  if (props.state === ImportState.REQUESTED) {
    return <RequestedIcon title="Requested"></RequestedIcon>;
  }
  if (props.state === ImportState.WITHDRAWN) {
    return <WithdrawnIcon title="Withdrawn"></WithdrawnIcon>;
  }
  return <DeletedIcon title="Deleted"></DeletedIcon>;
};
