import {
  AcademicYearDto,
  CopyAcademicYearOptionsDto,
  CopySemesterOptionsDto,
  IAcademicYearDto,
  ICopyAcademicYearOptionsDto,
  ICopySemesterOptionsDto,
  IPhaseDto,
  ISemesterDto,
  ISemesterInfoDto,
  ISemesterManagementClient,
  ISemesterSelectionFilter,
  SemesterInfoDto,
} from 'app/clients/services';
import { addExceptionNotification, addSuccess } from 'app/modules/notification';
import { AsyncThunk, Thunk } from 'app/shared/util/action-type.util';
import { DateTime } from 'luxon';
import ACTION_TYPES from './types';

const initActions = (
  semesterManagementClient: ISemesterManagementClient,
  selectionActions: {
    getSemesterFilterAsync: () => AsyncThunk<ISemesterSelectionFilter>;
  },
) => {
  const setLoading = (loading: boolean) => ({
    type: ACTION_TYPES.SET_LOADING,
    payload: loading,
  });

  const setSemesterLoading = (semester?: ISemesterInfoDto) => ({
    type: ACTION_TYPES.SET_SEMESTER_LOADING,
    payload: semester ? semester.id : -1,
  });

  const setPhaseLoading = (phase?: IPhaseDto) => ({
    type: ACTION_TYPES.SET_PHASE_LOADING,
    payload: phase ? phase.id : -1,
  });

  const selectAcademicYear: (academicYear?: IAcademicYearDto | null) => Thunk<void> =
    (academicYear) => (dispatch, getState) => {
      dispatch({
        type: ACTION_TYPES.SELECT_ACADEMIC_YEAR,
        payload: academicYear,
      });

      if (!academicYear) {
        dispatch({
          type: ACTION_TYPES.SELECT_ACADEMIC_YEAR,
          payload: getState().semesterSelection.selectedAcademicYear,
        });
      }
    };

  const addAcademicYear: () => Thunk = () => (dispatch, getState) => {
    const newestYear = getState().semesterSelection.semesterFilter?.academicYears.sort((s1, s2) =>
      s1.startYear < s2.startYear ? 1 : -1,
    )[0];
    const startYear = newestYear.startYear + 1;
    const academicYear = new AcademicYearDto({
      id: 0,
      name: `${startYear}/${startYear + 1}`,
      startYear,
      hasSemesters: false,
    });

    dispatch({
      type: ACTION_TYPES.ADD_ACADEMIC_YEAR,
      payload: academicYear,
    });
  };

  const refreshFiltersAsync: () => AsyncThunk = () => async (dispatch) => {
    try {
      await dispatch(selectionActions.getSemesterFilterAsync());
    } catch (err) {
      dispatch(addExceptionNotification(err, `Error while refreshing the semester filter!`));
    }
  };

  const copyAcademicYearAsync: (options: ICopyAcademicYearOptionsDto) => AsyncThunk<number> =
    (options) => async (dispatch, getState) => {
      dispatch(setLoading(true));
      try {
        const result = await semesterManagementClient.copyAcademicYear(new CopyAcademicYearOptionsDto(options));
        await dispatch(refreshFiltersAsync());
        dispatch(setLoading(false));
        const academicYears = getState().semesterSelection.semesterFilter.academicYears;
        const newAcademicYear = academicYears.find((a) => a.id === result);
        const oldAcademicYear = academicYears.find((a) => a.id === options.copyFromId);
        dispatch(selectAcademicYear(newAcademicYear));
        dispatch(addSuccess(`Academic year was successfully copied from ${oldAcademicYear.name} to ${options.name}.`));
        return result;
      } catch (err) {
        const academicYears = getState().semesterSelection.semesterFilter.academicYears;
        const oldAcademicYear = academicYears.find((a) => a.id === options.copyFromId);
        dispatch(
          addExceptionNotification(
            err,
            `Error while copying academic year ${oldAcademicYear.name} to ${options.name}!`,
          ),
        );
        dispatch(setLoading(false));
      }
    };

  const copySemesterAsync: (options: ICopySemesterOptionsDto) => AsyncThunk<number> =
    (options) => async (dispatch, getState) => {
      const oldSemester = getState().semesterSelection.semesterFilter.semesters.find(
        (s) => s.id === options.copyFromId,
      );
      const academicYears = getState().semesterSelection.semesterFilter.academicYears;
      const academicYear = academicYears.find((a) => a.id === options.toAcademicYearId);
      dispatch(setLoading(true));
      try {
        const result = await semesterManagementClient.copySemester(new CopySemesterOptionsDto(options));
        await dispatch(refreshFiltersAsync());
        dispatch(setLoading(false));
        const semester = getState().semesterSelection.semesterFilter.semesters.find((s) => s.id === result);
        dispatch(
          addSuccess(
            `Semester ${oldSemester.name} was successfully copied to academic year ${academicYear.name} with the new name ${semester.name}.`,
          ),
        );
        return result;
      } catch (err) {
        dispatch(
          addExceptionNotification(err, `Error while copying semester ${oldSemester.name} to ${academicYear.name}!`),
        );
        dispatch(setLoading(false));
      }
    };

  const saveAcademicYearAsync: (academicYear: IAcademicYearDto) => AsyncThunk<number> =
    (academicYear) => async (dispatch) => {
      dispatch(setLoading(true));
      try {
        let selectedAcademicYear = academicYear;
        if (academicYear.id) {
          await semesterManagementClient.updateAcademicYear(new AcademicYearDto(academicYear));
        } else {
          selectedAcademicYear = await semesterManagementClient.addAcademicYear(new AcademicYearDto(academicYear));
        }
        await dispatch(refreshFiltersAsync());
        dispatch(setLoading(false));
        dispatch(selectAcademicYear(selectedAcademicYear));
        dispatch(addSuccess(`Academic year ${selectedAcademicYear.name} was successfully saved.`));
        return selectedAcademicYear.id;
      } catch (err) {
        dispatch(addExceptionNotification(err, `Error while saving academic year ${academicYear.name}!`));
        dispatch(setLoading(false));
      }
    };

  const deleteAcademicYearAsync: (academicYear: IAcademicYearDto) => AsyncThunk<IAcademicYearDto> =
    (academicYear) => async (dispatch) => {
      dispatch(setLoading(true));
      try {
        await semesterManagementClient.deleteAcademicYear(academicYear.id);
        await dispatch(refreshFiltersAsync());
        dispatch(selectAcademicYear(null));

        dispatch(setLoading(false));
        dispatch(addSuccess(`Academic year ${academicYear.name} was successfully deleted.`));
        return academicYear;
      } catch (err) {
        dispatch(addExceptionNotification(err, `Error while deleting academic year ${academicYear.name}!`));
        dispatch(setLoading(false));
      }
    };

  const addSemester = (academicYear: IAcademicYearDto) => {
    const start = DateTime.local().set({ year: academicYear.startYear });
    const end = start.plus({ weeks: 14 });
    const semester = new SemesterInfoDto({
      id: 0,
      name: '',
      nameLong: '',
      academicYearId: academicYear.id,
      start,
      end,
      earliestRegistrationDate: start.startOf('year'),
      phases: [],
      registrationOpen: true,
      isPast: end < DateTime.local(),
      hasRelatedData: false,
    });

    return {
      type: ACTION_TYPES.ADD_SEMESTER,
      payload: semester,
    };
  };

  const cancelAddingSemester = () => {
    return {
      type: ACTION_TYPES.ADD_SEMESTER,
      payload: null,
    };
  };

  const updateSemesterAsync: (semester: ISemesterInfoDto) => AsyncThunk<ISemesterDto> =
    (semester) => async (dispatch) => {
      dispatch(setSemesterLoading(semester));
      try {
        await semesterManagementClient.updateOrInsertSemester(new SemesterInfoDto(semester));
        await dispatch(refreshFiltersAsync());
        dispatch(setSemesterLoading());
        dispatch(addSuccess(`Semester ${semester.name} was successfully saved.`));
        return semester;
      } catch (err) {
        dispatch(setSemesterLoading());
        dispatch(addExceptionNotification(err, `Error while saving semester ${semester.name}!`));
      }
    };

  const deleteSemesterAsync: (semester: ISemesterInfoDto) => AsyncThunk<ISemesterDto> =
    (semester) => async (dispatch) => {
      dispatch(setSemesterLoading(semester));
      try {
        await semesterManagementClient.deleteSemester(semester?.id);
        await dispatch(refreshFiltersAsync());
        dispatch(setSemesterLoading());
        dispatch(addSuccess(`Semester ${semester.name} was successfully deleted.`));
        return semester;
      } catch (err) {
        dispatch(setSemesterLoading());
        dispatch(addExceptionNotification(err, `Error while deleting semester ${semester.name}!`));
      }
    };

  const closePhaseAsync: (phase: IPhaseDto) => AsyncThunk = (phase) => async (dispatch) => {
    dispatch(setPhaseLoading(phase));
    try {
      await semesterManagementClient.closePhase(phase.id);
      await dispatch(refreshFiltersAsync());
      dispatch(setPhaseLoading());
      dispatch(addSuccess(`Phase ${phase.name} was successfully closed.`));
    } catch (err) {
      dispatch(setPhaseLoading());
      dispatch(addExceptionNotification(err, `Error while closing phase ${phase.name}!`));
    }
  };

  const reopenPhaseAsync: (phase: IPhaseDto) => AsyncThunk = (phase) => async (dispatch) => {
    dispatch(setPhaseLoading(phase));
    try {
      await semesterManagementClient.reopenPhase(phase.id);
      await dispatch(refreshFiltersAsync());
      dispatch(setPhaseLoading());
      dispatch(addSuccess(`Phase ${phase.name} was successfully reopened.`));
    } catch (err) {
      dispatch(setPhaseLoading());
      dispatch(addExceptionNotification(err, `Error while reopening phase ${phase.name}!`));
    }
  };

  const deletePhaseAsync: (phase: IPhaseDto) => AsyncThunk = (phase) => async (dispatch) => {
    dispatch(setPhaseLoading(phase));
    try {
      await semesterManagementClient.deletePhase(phase.id);
      await dispatch(refreshFiltersAsync());
      dispatch(setPhaseLoading());
      dispatch(addSuccess(`Phase ${phase.name} was successfully deleted.`));
    } catch (err) {
      dispatch(setPhaseLoading());
      dispatch(addExceptionNotification(err, `Error while deleting phase ${phase.name}!`));
    }
  };

  return Object.freeze({
    cancelAddingSemester,
    addSemester,
    addAcademicYear,
    selectAcademicYear,
    saveAcademicYearAsync,
    copyAcademicYearAsync,
    copySemesterAsync,
    deleteAcademicYearAsync,
    updateSemesterAsync,
    deleteSemesterAsync,
    deletePhaseAsync,
    closePhaseAsync,
    reopenPhaseAsync,
  });
};

export default initActions;

export type SemesterManagementActions = ReturnType<typeof initActions>;
