import { Paper } from '@mui/material';
import { IExcelColumn, IRegistrationSheet, IValidationMessage, ValidationLevel } from 'app/clients/services';
import { isHigherOrEqual } from 'app/shared/util/validation-level.util';
import React from 'react';
import { RegistrationTable } from './registration-table';
import ValidationMessage from './validation-message';

export interface Props {
  messages: IValidationMessage[];
  columnInfo: IExcelColumn[];
  registrations: IRegistrationSheet;
  showTableView: boolean;
  filter: ValidationLevel;
  showEverything?: boolean;
}

export const Messages = (props: Props) => {
  const messagesToShow = props.messages?.filter((m) => isHigherOrEqual(m.level, props.filter)) ?? [];
  const hasTableInfo =
    props.messages?.filter((m) => m.cells?.length > 0).length > 0 && !!props.columnInfo && !!props.registrations;
  const messagesWithRequiredAction =
    props.messages?.filter((m) => isHigherOrEqual(m.level, ValidationLevel.Warning)) ?? [];

  const messagesShownInTable = messagesToShow?.filter((m) => m.cells?.length > 0) ?? [];

  return (
    <div style={{ paddingLeft: 10, marginRight: 10 }}>
      {props.showTableView && hasTableInfo && (
        <Paper style={{ maxHeight: '100%', overflow: 'auto' }}>
          <RegistrationTable
            showEverything={props.showEverything}
            messages={messagesShownInTable}
            columnInfo={props.columnInfo}
            registrations={props.registrations}
          />
        </Paper>
      )}
      {props.showTableView
        ? messagesWithRequiredAction.map((m, key) => (
            <ValidationMessage
              key={key}
              message={m}
              hideTitle
              columnInfo={props.columnInfo}
              registrations={props.registrations}
            ></ValidationMessage>
          ))
        : messagesToShow.map((m, key) => (
            <ValidationMessage
              key={key}
              message={m}
              hideTitle
              columnInfo={props.columnInfo}
              registrations={props.registrations}
            ></ValidationMessage>
          ))}
    </div>
  );
};

export default Messages;
