import { GridCellParams, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { IRootState } from 'app/config/root.reducer';
import React, { useEffect } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { RoleType } from '../../clients/services';

import { Box, Checkbox, Typography } from '@mui/material';
import { FullSizeLayout } from 'app/shared/layout/ui-elements/component-layout';
import { StandardDataGrid, dateFormatter, dateSorter } from 'app/shared/layout/ui-elements/standard-data-grid';
import { combineUrlParams, getBasePath } from 'app/shared/util/url-utils';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AccountForm } from './parts/account-form';
import { RolesForm } from './parts/roles-form';
import userManagementModule from './user-management';

export type Props = PropsFromRedux;

const UserAccounts = (props: Props) => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const basePath = getBasePath(useLocation(), { id });

  useEffect(() => {
    const init = async () => {
      if (id && props.account?.id !== id) {
        await props.loadAccountAsync(id);
      }
    };
    init();
  }, [id]);

  useEffect(() => {
    const init = async () => {
      await props.loadAccountsAsync();
    };
    init();
  }, []);

  const columns: GridColDef[] = [
    {
      field: 'activated',
      headerName: '',
      width: 40,
      renderCell: (params: GridCellParams) => <Checkbox checked={params.value === true} disabled />,
    },
    { field: 'firstName', headerName: 'First Name', width: 180 },
    { field: 'familyName', headerName: 'Family Name', width: 180 },
    {
      field: 'email',
      headerName: 'Email',
      width: 180,
    },
    {
      field: 'createdDate',
      headerName: 'Date Created',
      width: 160,
      sortComparator: dateSorter,
      valueFormatter: dateFormatter,
    },
    {
      field: 'roles',
      headerName: 'Roles',
      width: 200,
      valueGetter: (params: GridValueGetterParams) => (params.value as RoleType[]).join(', '),
    },
  ];

  return (
    <FullSizeLayout>
      <StandardDataGrid
        rows={props.userAccounts}
        columns={columns}
        loading={props.loading}
        selectionModel={props.account ? [props.account.id] : []}
        onSelectionModelChange={(newSelection) => {
          const accountId = newSelection[0]?.toString();
          navigate(basePath + combineUrlParams({ id: accountId }));
        }}
        columnVisibilityModel={{}}
      />
      {props.account && props.roleInfo && (
        <>
          <Typography variant={'h1'} sx={{ paddingTop: 2 }}>
            Account: {props.account.firstName} {props.account.familyName}
          </Typography>

          <AccountForm
            loading={props.accountLoading}
            account={props.account}
            onSubmit={async (account) => {
              await props.updateAsync(account);
            }}
            onCancel={async () => {
              await props.loadAccountAsync(props.account.id);
            }}
            onDelete={async () => {
              await props.deleteAsync(props.account);
            }}
            hasLogin
            validateEmailAsync={async (email: string) => await props.emailAlreadyExistsAsync(props.account.id, email)}
            actions={props}
          />

          <Box sx={{ paddingTop: 1 }}>
            <RolesForm
              loading={props.accountLoading}
              roleLoading={props.roleLoading}
              account={props.account}
              roleInfo={props.roleInfo.roleInfos}
              roles={props.roles}
              uas={props.uas}
              actions={props}
            />
          </Box>
        </>
      )}
    </FullSizeLayout>
  );
};

const mapDispatchToProps = (dispatch) => {
  const actions = userManagementModule.initActions(dispatch);
  return actions;
};

const mapStateToProps = ({ userManagement, application, auth }: IRootState) => ({
  roleLoading: userManagement.roleLoading,
  accountLoading: userManagement.accountLoading,
  userAccounts: userManagement.userAccounts,
  account: userManagement.selectedAccount,
  roleInfo: userManagement.roleInfo,
  loading: userManagement.accountsLoading,
  roles: application.roles,
  uas: auth.allowedUas,
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(UserAccounts);
