import {
  IPriorityAssignmentDto,
  IPriorityDto,
  IProfileClient,
  IProfileDto,
  ISemesterDto,
  PriorityAssignmentDto,
  PriorityDto,
  ProfileDto,
} from 'app/clients/services';
import { addExceptionNotification, addSuccess } from 'app/modules/notification';
import { AsyncThunk, Thunk } from 'app/shared/util/action-type.util';
import ACTION_TYPES from './types';

const initActions = (
  profileClient: IProfileClient,
  profileSelectionActions: { fetchProfilesAsync: () => AsyncThunk<IProfileDto[]> },
) => {
  // Actions
  const setLoading = (loading: boolean) => ({
    type: ACTION_TYPES.SET_LOADING,
    payload: loading,
  });

  const selectProfile = (profile: IProfileDto) => ({
    type: ACTION_TYPES.SELECT_PROFILE,
    payload: profile,
  });

  const createNewProfile: () => Thunk = () => (dispatch) => {
    const newProfile = new ProfileDto({ id: 0, code: 'untitled', isActive: true });
    dispatch({
      type: ACTION_TYPES.CREATE_PROFILE,
      payload: newProfile,
    });
  };

  // Thunks

  const updateProfileAsync: (profileDto: IProfileDto) => AsyncThunk = (profileDto) => async (dispatch) => {
    dispatch({
      type: ACTION_TYPES.MEMORIZE_PROFILE_CHANGES,
      payload: profileDto,
    });
    dispatch(setLoading(true));
    try {
      await profileClient.update(new ProfileDto(profileDto));
      const profiles = await dispatch(profileSelectionActions.fetchProfilesAsync());
      dispatch(selectProfile(profiles.find((p) => p.id === profileDto.id)));
      dispatch(setLoading(false));
      dispatch(addSuccess(`Successfully updated '${profileDto.code}'!`));
    } catch (e) {
      dispatch(setLoading(false));
      dispatch(addExceptionNotification(e, 'An unknown error occurred.'));
    }
  };

  const deleteProfileAsync: (profileDto: IProfileDto) => AsyncThunk = (profileDto) => async (dispatch) => {
    dispatch(setLoading(true));
    try {
      if (profileDto.id > 0) {
        await profileClient.delete(profileDto.id);
      }
      await dispatch(profileSelectionActions.fetchProfilesAsync());
      dispatch(selectProfile(null));
      dispatch(setLoading(false));
      dispatch(addSuccess('Successfully deleted the profile ' + profileDto.code));
    } catch (e) {
      dispatch(setLoading(false));
      dispatch(addExceptionNotification(e, 'An unknown error occurred while deleting the profile ' + profileDto.code));
    }
  };

  const addProfileAsync: (profileDto: IProfileDto) => AsyncThunk = (profileDto) => async (dispatch, getState) => {
    dispatch(setLoading(true));
    try {
      const result = await profileClient.add(new ProfileDto(profileDto));
      const profiles = await dispatch(profileSelectionActions.fetchProfilesAsync());
      dispatch(selectProfile(profiles.find((p) => p.id === result.id)));
      await dispatch({
        type: ACTION_TYPES.FETCH_PRIORITY_ASSIGNMENTS,
        payload: profileClient.getPriorityAssignments(result.id, getState().semesterSelection.selectedSemester?.id),
      });
      dispatch(setLoading(false));
      dispatch(addSuccess('Successfully added the profile ' + profileDto.code));
    } catch (e) {
      dispatch(setLoading(false));
      dispatch(addExceptionNotification(e, 'An unknown error occurred while adding the profile ' + profileDto.code));
    }
  };

  const fetchPriorityAssignmentsAsync: (profileId: number, semesterId: number) => AsyncThunk<PriorityAssignmentDto[]> =
    (profileId: number, semesterId: number) => async (dispatch) => {
      const result = await dispatch({
        type: ACTION_TYPES.FETCH_PRIORITY_ASSIGNMENTS,
        payload: profileClient.getPriorityAssignments(profileId, semesterId),
      });
      return result.value;
    };

  const copySemesterAsync: (
    profile: IProfileDto,
    fromSemester: ISemesterDto,
    toSemester: ISemesterDto,
  ) => AsyncThunk<PriorityAssignmentDto[]> = (profile, fromSemester, toSemester) => async (dispatch) => {
    await dispatch({
      type: ACTION_TYPES.COPY_FROM_SEMESTER,
      payload: profileClient.copySemester(profile.id, fromSemester.id, toSemester.id),
    });
    return await dispatch(fetchPriorityAssignmentsAsync(profile.id, toSemester.id));
  };

  const assignAllPrioritiesAsync: (
    profile: IProfileDto,
    semester: ISemesterDto,
    priority: IPriorityDto,
  ) => AsyncThunk<PriorityAssignmentDto[]> = (profile, semester, priority) => async (dispatch) => {
    await dispatch({
      type: ACTION_TYPES.COPY_FROM_SEMESTER,
      payload: profileClient.assignAllPriorities(profile.id, semester.id, priority.id),
    });
    return await dispatch(fetchPriorityAssignmentsAsync(profile.id, semester.id));
  };

  const removeAllPrioritiesAsync: (
    profile: IProfileDto,
    semester: ISemesterDto,
  ) => AsyncThunk<PriorityAssignmentDto[]> = (profile, semester) => async (dispatch) => {
    await dispatch({
      type: ACTION_TYPES.REMOVE_ALL_PRIORITIES,
      payload: profileClient.removeAllPriorities(profile.id, semester.id),
    });
    return await dispatch(fetchPriorityAssignmentsAsync(profile.id, semester.id));
  };

  const changePriorityAssignmentAsync: (
    priorityAssignment: IPriorityAssignmentDto,
    priority: IPriorityDto,
  ) => AsyncThunk = (priorityAssignment, priority) => async (dispatch) => {
    if (!priority) {
      return;
    }
    const changedAssignment = {
      ...priorityAssignment,
    };
    changedAssignment.priority = new PriorityDto(priority);
    changedAssignment.priorityId = priority.id;
    dispatch({
      type: ACTION_TYPES.CHANGE_PRIORITYASSIGNMENT,
      payload: changedAssignment,
    });
    await profileClient.assignPriority(changedAssignment.profileId, priorityAssignment.moduleExecution.id, priority.id);
  };

  return Object.freeze({
    selectProfile,
    createNewProfile,
    fetchProfilesAsync: profileSelectionActions.fetchProfilesAsync,
    assignAllPrioritiesAsync,
    updateProfileAsync,
    deleteProfileAsync,
    addProfileAsync,
    fetchPriorityAssignmentsAsync,
    removeAllPrioritiesAsync,
    copySemesterAsync,
    changePriorityAssignmentAsync,
  });
};

export default initActions;

export type ProfileManagementActions = ReturnType<typeof initActions>;
