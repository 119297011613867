import AllInboxIcon from '@mui/icons-material/AllInbox';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ErrorIcon from '@mui/icons-material/Error';
import ForumIcon from '@mui/icons-material/Forum';
import InfoIcon from '@mui/icons-material/Info';
import PersonIcon from '@mui/icons-material/Person';
import TocIcon from '@mui/icons-material/Toc';
import WarningIcon from '@mui/icons-material/Warning';
import { Badge, Box, Button, Paper, Tooltip } from '@mui/material';
import { IValidationCountDto, ValidationLevel } from 'app/clients/services';
import { COLOR_ERROR, COLOR_INFO, COLOR_SUCCESS, COLOR_WARNING } from 'app/config/constants';
import { isLowerOrEqual } from 'app/shared/util/validation-level.util';
import React from 'react';

export type ChangeFilter =
  | 'unconfirmed'
  | 'confirmations'
  | 'student-changes'
  | 'advisor-changes'
  | 'registration-changes';

export interface Props {
  levelFilter: ValidationLevel;
  changeFilter: ChangeFilter[];
  count: IValidationCountDto;
  hasTableInfo: boolean;
  showTableView: boolean;
  onFilterChange: (levelFilter: ValidationLevel, changeFilter: ChangeFilter[]) => void;
  onViewChange: (showTableView: boolean) => void;
}

// eslint-disable-next-line complexity
export const MessageFilter = (props: Props) => {
  if (
    !props.count ||
    (props.count.errors === 0 &&
      props.count.warnings === 0 &&
      props.count.infos === 0 &&
      props.count.unconfirmedConfirmations === 0 &&
      props.count.userConfirmations === 0 &&
      props.count.advisorChanges === 0 &&
      props.count.studentChanges === 0 &&
      props.count.registrationChanges === 0)
  ) {
    return <></>;
  }

  const hasFilter = (filter: ChangeFilter) => {
    return props.levelFilter === ValidationLevel.Success || props.changeFilter.filter((f) => f === filter).length > 0;
  };

  // const addOrRemoveFilter = (filter: ChangeFilter) => {
  //   return props.changeFilter.filter(f => f === filter).length > 0
  //     ? props.changeFilter.filter(f => f !== filter)
  //     : [...props.changeFilter, filter];
  // };

  const showLevel = (currentLevel: ValidationLevel, level: ValidationLevel, title: string) => {
    return isLowerOrEqual(currentLevel, level) ? <span>Hide {title}</span> : <span>Show {title}</span>;
  };

  // const getLevelForFilter = (
  //   currentLevel: ValidationLevel,
  //   currentFilter: ChangeFilter[],
  //   count: IValidationCountDto,
  //   filter: ChangeFilter
  // ) => {
  //   if (
  //     isLowerOrEqual(currentLevel, ValidationLevel.Info) &&
  //     currentFilter.filter(f => f !== 'unconfirmed').length === 1 &&
  //     hasFilter(filter) &&
  //     count.infos === 0
  //   ) {
  //     return ValidationLevel.Warning;
  //   }
  //   return ValidationLevel.Info;
  // };

  return (
    <Box style={{ paddingLeft: 10, marginRight: 10 }}>
      <Paper style={{ backgroundColor: '#EEEEEE', textAlign: 'right' }}>
        {props.count.errors > 0 && (
          <Tooltip title={<span>Show errors only</span>}>
            <Button
              onClick={() => props.onFilterChange(ValidationLevel.Error, [])}
              style={{ backgroundColor: COLOR_ERROR, border: '1px solid red' }}
              color="inherit"
            >
              <Badge badgeContent={props.count.errors} color="primary">
                <ErrorIcon />
              </Badge>
            </Button>
          </Tooltip>
        )}
        {props.count.warnings > 0 && (
          <Tooltip title={showLevel(props.levelFilter, ValidationLevel.Warning, 'warnings.')}>
            <Button
              onClick={() =>
                isLowerOrEqual(props.levelFilter, ValidationLevel.Warning)
                  ? props.onFilterChange(ValidationLevel.Error, [])
                  : props.onFilterChange(ValidationLevel.Warning, ['unconfirmed'])
              }
              style={{
                backgroundColor: isLowerOrEqual(props.levelFilter, ValidationLevel.Warning) ? COLOR_WARNING : '#EEEEEE',
                border: isLowerOrEqual(props.levelFilter, ValidationLevel.Warning) ? '1px solid yellow' : 'none',
              }}
              color="inherit"
            >
              <Badge badgeContent={props.count.warnings} color="primary">
                <WarningIcon />
              </Badge>
            </Button>
          </Tooltip>
        )}
        {props.count.infos > 0 && (
          <Tooltip title={showLevel(props.levelFilter, ValidationLevel.Info, 'special information.')}>
            <Button
              onClick={() =>
                isLowerOrEqual(props.levelFilter, ValidationLevel.Info)
                  ? props.onFilterChange(ValidationLevel.Warning, ['unconfirmed'])
                  : props.onFilterChange(ValidationLevel.Info, ['unconfirmed', 'confirmations'])
              }
              style={{
                backgroundColor: isLowerOrEqual(props.levelFilter, ValidationLevel.Info) ? COLOR_INFO : '#EEEEEE',
                border: isLowerOrEqual(props.levelFilter, ValidationLevel.Info) ? '1px solid blue' : 'none',
              }}
              color="inherit"
            >
              <Badge badgeContent={props.count.infos} color="primary">
                <InfoIcon />
              </Badge>
            </Button>
          </Tooltip>
        )}
        {(props.count.userConfirmations > 0 || props.count.unconfirmedConfirmations > 0) && (
          <Tooltip
            title={
              <span>
                {props.count.userConfirmations > 0 && (
                  <>{props.count.userConfirmations} confirmed change confirmations. </>
                )}
                {props.count.unconfirmedConfirmations > 0 && (
                  <>{props.count.unconfirmedConfirmations} unconfirmed change confirmations. </>
                )}
              </span>
            }
          >
            <Button
              // onClick={() =>
              //   props.onFilterChange(
              //     getLevelForFilter(props.levelFilter, props.changeFilter, props.count, 'confirmations'),
              //     addOrRemoveFilter('confirmations')
              //   )
              // }
              color="inherit"
              style={{
                backgroundColor: hasFilter('confirmations') ? COLOR_INFO : '#EEEEEE',
                border: hasFilter('confirmations') ? '1px solid blue' : 'none',
              }}
            >
              <Badge badgeContent={props.count.userConfirmations} color="primary">
                <DoneAllIcon />
              </Badge>
            </Button>
          </Tooltip>
        )}
        {props.count.studentChanges > 0 && (
          <Tooltip title={<span>{props.count.studentChanges} new or changed students.</span>}>
            <Button
              // onClick={() =>
              //   props.onFilterChange(
              //     getLevelForFilter(props.levelFilter, props.changeFilter, props.count, 'student-changes'),
              //     addOrRemoveFilter('student-changes')
              //   )
              // }
              color="inherit"
              style={{
                backgroundColor: hasFilter('student-changes') ? COLOR_INFO : '#EEEEEE',
                border: hasFilter('student-changes') ? '1px solid blue' : 'none',
              }}
            >
              <Badge badgeContent={props.count.studentChanges} color="primary">
                <PersonIcon />
              </Badge>
            </Button>
          </Tooltip>
        )}
        {props.count.advisorChanges > 0 && (
          <Tooltip title={<span>{props.count.advisorChanges} new or changed advisors.</span>}>
            <Button
              // onClick={() =>
              //   props.onFilterChange(
              //     getLevelForFilter(props.levelFilter, props.changeFilter, props.count, 'advisor-changes'),
              //     addOrRemoveFilter('advisor-changes')
              //   )
              // }
              color="inherit"
              style={{
                backgroundColor: hasFilter('advisor-changes') ? COLOR_INFO : '#EEEEEE',
                border: hasFilter('advisor-changes') ? '1px solid blue' : 'none',
              }}
            >
              <Badge badgeContent={props.count.advisorChanges} color="primary">
                <ContactPhoneIcon />
              </Badge>
            </Button>
          </Tooltip>
        )}
        {props.count.registrationChanges > 0 && (
          <Tooltip title={<span>{props.count.registrationChanges} new or changed registrations.</span>}>
            <Button
              // onClick={() =>
              //   props.onFilterChange(
              //     getLevelForFilter(props.levelFilter, props.changeFilter, props.count, 'registration-changes'),
              //     addOrRemoveFilter('registration-changes')
              //   )
              // }
              style={{
                backgroundColor: hasFilter('registration-changes') ? COLOR_INFO : '#EEEEEE',
                border: hasFilter('registration-changes') ? '1px solid blue' : 'none',
              }}
              color="inherit"
            >
              <Badge badgeContent={props.count.registrationChanges} color="primary">
                <BorderColorIcon />
              </Badge>
            </Button>
          </Tooltip>
        )}
        <Tooltip title={showLevel(props.levelFilter, ValidationLevel.Success, ' additional information.')}>
          <Button
            color="inherit"
            onClick={() =>
              props.levelFilter === ValidationLevel.Success
                ? props.onFilterChange(ValidationLevel.Info, [
                    'unconfirmed',
                    'confirmations',
                    'student-changes',
                    'advisor-changes',
                    'registration-changes',
                  ])
                : props.onFilterChange(ValidationLevel.Success, [
                    'unconfirmed',
                    'confirmations',
                    'student-changes',
                    'advisor-changes',
                    'registration-changes',
                  ])
            }
            style={{
              backgroundColor: props.levelFilter === ValidationLevel.Success ? COLOR_SUCCESS : '#EEEEEE',
              border: props.levelFilter === ValidationLevel.Success ? '1px solid green' : 'none',
            }}
          >
            <AllInboxIcon />
          </Button>
        </Tooltip>
        {props.hasTableInfo && (
          <Tooltip
            color="inherit"
            title={
              <>
                {props.showTableView ? (
                  <span>Show messages seperately in list.</span>
                ) : (
                  <span>Show all validation messages in one table</span>
                )}
              </>
            }
          >
            <Button onClick={() => props.onViewChange(!props.showTableView)}>
              {props.showTableView ? <ForumIcon></ForumIcon> : <TocIcon></TocIcon>}
            </Button>
          </Tooltip>
        )}
      </Paper>
    </Box>
  );
};
