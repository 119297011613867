import initActions from './actions';
import types from './types';
import reducer from './reducer';
import semesterSelectionModule from '../../selections/semester-selection';

import { ISemesterManagementClient } from 'app/clients/services';
import { resolver } from 'app/clients/service-registry';

export type { SemesterManagementState } from './reducer';

export default {
  initActions: () =>
    initActions(resolver.resolve<ISemesterManagementClient>('ISemesterManagementClient'), semesterSelectionModule.initActions()),
  types,
  reducer,
};
