import DeleteIcon from '@mui/icons-material/DeleteForever';
import SaveIcon from '@mui/icons-material/Save';
import { Alert, Button, Chip, Grid, Typography } from '@mui/material';
import { IUasDomainDto } from 'app/clients/services';
import { ConfirmationDialog } from 'app/shared/layout/ui-elements/confirmation-dialog';
import { Field, FieldArray, Form, Formik } from 'formik';
import { CheckboxWithLabel as FormikCheckbox, TextField as FormikTextField } from 'formik-mui';
import React, { useState } from 'react';
import * as Yup from 'yup';

import AddIcon from '@mui/icons-material/Add';

const domainRegex = /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g;

type Props = {
  domain: IUasDomainDto;
  allDomains: IUasDomainDto[];
  onSubmit: (domain: IUasDomainDto) => Promise<void>;
  onDelete: (uas: IUasDomainDto) => Promise<void>;
  onCancel: () => void;
  validateEmailAsync: (email: string) => Promise<boolean>;
  loading: boolean;
};
export const DomainForm = (props: Props) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  return (
    <Formik
      enableReinitialize
      initialValues={{ domain: props.domain, newBlockedEmail: '' }}
      validationSchema={Yup.object({
        domain: Yup.object({
          name: Yup.string().matches(domainRegex).required('Domain has is not valid!'),
          blockedEmails: Yup.array()
            .of(Yup.string())
            .test('unique', 'Duplicate email!', (emails) => {
              if (!emails) {
                return true;
              }
              return emails?.length === new Set(emails ?? []).size;
            }),
        }),
        newBlockedEmail: Yup.string().test(
          'ends-with-domain',
          `Email has to end  with ${props.domain.name}`,
          (email, context) => {
            if (email && email.length > 0) {
              return Yup.string()
                .email('Email is not vaild.')
                .test('ends-with-domain', `Email has to end  with ${props.domain.name}`, (name) =>
                  name?.endsWith(`@${context.parent.domain.name}`),
                )
                .test('email-backend-validation', 'Email is already taken by another user!', async (name) => {
                  if (name && (await Yup.string().email().isValid(name))) {
                    return await props.validateEmailAsync(name);
                  }
                  return true;
                })
                .isValid(email);
            }
            return true;
          },
        ),
      })}
      onSubmit={(values, { setSubmitting }) => {
        props.onSubmit(values.domain).then(() => setSubmitting(false));
      }}
    >
      {({ errors, values, submitForm, isSubmitting, validateForm, setFieldValue }) => (
        <Form>
          <Grid container spacing={1}>
            <Grid item xs={12} md={2}>
              <Field
                disabled={values.domain.id}
                variant="standard"
                component={FormikTextField}
                name="domain.name"
                type="text"
                label="Domain Name"
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Field
                type="checkbox"
                component={FormikCheckbox}
                label="Is Active"
                name="domain.isActive"
                Label={{ label: 'Is Active' }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                type="checkbox"
                component={FormikCheckbox}
                label="Is prefered for Students"
                name="domain.preferedForStudents"
                Label={{ label: 'Is prefered for Students' }}
              />
            </Grid>
            {
              <Grid item xs={12}>
                <FieldArray
                  name="domain.blockedEmails"
                  render={(arrayHelpers) => (
                    <>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography variant="h3">Blocked Emails:</Typography>
                        </Grid>
                        {values.domain.blockedEmails?.length > 0 && (
                          <>
                            <Grid item xs={12} sx={{ padding: 1 }}>
                              {values.domain.blockedEmails.map((e, k) => (
                                <Chip
                                  key={k}
                                  label={e}
                                  onDelete={() => arrayHelpers.remove(k)}
                                  sx={{ margin: 1 }}
                                  color="primary"
                                />
                              ))}
                            </Grid>
                            {errors.domain?.blockedEmails?.length > 0 && (
                              <Grid item xs={12} sx={{ padding: 1 }}>
                                <Alert severity="error">{errors.domain.blockedEmails}</Alert>
                              </Grid>
                            )}
                          </>
                        )}

                        <Grid item xs={8} md={3}>
                          <Field
                            component={FormikTextField}
                            name={`newBlockedEmail`}
                            type="text"
                            label="New Email to block"
                            style={{ width: '100%' }}
                            variant="standard"
                          />
                        </Grid>
                        <Grid item xs={4} md={3}>
                          <Button
                            variant="contained"
                            color="inherit"
                            disabled={props.loading || !values.newBlockedEmail}
                            startIcon={<AddIcon />}
                            style={{ paddingLeft: 5, marginTop: 10 }}
                            onClick={async () => {
                              const validationResult = await validateForm(values);
                              values.newBlockedEmail;
                              if (values.newBlockedEmail && !validationResult.newBlockedEmail) {
                                arrayHelpers.push(values.newBlockedEmail);
                                setFieldValue('newBlockedEmail', '');
                              }
                            }}
                          >
                            Add
                          </Button>
                        </Grid>
                      </Grid>
                    </>
                  )}
                />
              </Grid>
            }
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isSubmitting || props.loading}
                onClick={submitForm}
                startIcon={<SaveIcon />}
              >
                Save
              </Button>
              {props.domain.id ? (
                <Button
                  variant="contained"
                  color="inherit"
                  disabled={isSubmitting || props.loading}
                  onClick={() => setDialogOpen(true)}
                  startIcon={<DeleteIcon />}
                >
                  Delete
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="inherit"
                  disabled={isSubmitting || props.loading}
                  onClick={() => props.onCancel()}
                  startIcon={<DeleteIcon />}
                >
                  Cancel
                </Button>
              )}
              <ConfirmationDialog
                title="Delete UAS"
                onConfirm={() => props.onDelete(values.domain)}
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
              >
                Do you really want to delete the domain <strong>{props.domain.name}</strong>?
              </ConfirmationDialog>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
