import { AxiosInstance } from 'axios';
import { IResolver, IServiceRegistry, ServiceRegistry } from './generic-service-registry';
import {
  IAccountClient,
  IAdvisorClient,
  IApplicationProfileClient,
  IAuthClient,
  IEmailClient,
  IExcelTemplateClient,
  IImportClient,
  ILabelClient,
  IModuleManagementClient,
  IModuleSelectionClient,
  IPriorityClient,
  IProfileClient,
  IRegistrationClient,
  IRegistrationEditClient,
  IRegistrationExportClient,
  IRegistrationFilterPresetClient,
  IRoleSelectionClient,
  ISemesterManagementClient,
  ISemesterSelectionClient,
  IStudentClient,
  ISummarySheetClient,
  IUasClient,
  IUasDomainClient,
} from './services';

export interface IServiceInterfaces {
  IRegistrationClient: IRegistrationClient;
  IModuleSelectionClient: IModuleSelectionClient;
  IModuleManagementClient: IModuleManagementClient;
  IPriorityClient: IPriorityClient;
  IProfileClient: IProfileClient;
  ILabelClient: ILabelClient;
  IApplicationProfileClient: IApplicationProfileClient;
  IImportClient: IImportClient;
  IAuthClient: IAuthClient;
  IAccountClient: IAccountClient;
  ISemesterSelectionClient: ISemesterSelectionClient;
  IRoleSelectionClient: IRoleSelectionClient;
  ISemesterManagementClient: ISemesterManagementClient;
  IRegistrationExportClient: IRegistrationExportClient;
  IStudentClient: IStudentClient;
  IAdvisorClient: IAdvisorClient;
  IRegistrationEditClient: IRegistrationEditClient;
  IEmailClient: IEmailClient;
  IUasClient: IUasClient;
  IUasDomainClient: IUasDomainClient;
  IExcelTemplateClient: IExcelTemplateClient;
  IRegistrationFilterPresetClient: IRegistrationFilterPresetClient;
  ISummarySheetClient: ISummarySheetClient;
  Axios: AxiosInstance;
}

const services = ServiceRegistry.init<IServiceInterfaces>();

export const resolver = services as IResolver<IServiceInterfaces>;
export const registry = services as IServiceRegistry<IServiceInterfaces>;
