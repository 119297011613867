import { Grid, Paper } from '@mui/material';
import {
  AdvisorImport,
  DatabaseMappingOfNullableLong,
  DatabaseMappingOfString,
  EmailImport,
  IAdvisorImport,
  IDatabaseMappingOfNullableLong,
  IDatabaseMappingOfString,
  IValueChange,
  IValueChangeOfString,
  RoleType,
  ValidationLevel,
  ValueChangeOfString,
} from 'app/clients/services';
import { useHasRole } from 'app/modules/login/hooks';
import React from 'react';
import { AdvisorInfo } from './advisor-info';
import { DbMappingForm } from './db-mapping-form';
import { ValueChangeForm } from './value-change-form';

type Props = {
  noRights: boolean;
  advisor: IAdvisorImport;
  levelFilter: ValidationLevel;
  isLoading: boolean;
  disabled: boolean;
  onUpdate: (advisor: AdvisorImport) => void;
};

export const AdvisorImportPreview = (props: Props) => {
  const isRegistrationAdmin = useHasRole(RoleType.REGISTRATIONADMIN);

  return (
    <div style={{ borderColor: 'lightgrey' }}>
      <Paper style={{ width: '100%', margin: 5, padding: 20 }}>
        <Grid container direction={'row'}>
          <Grid item xs={12}>
            <AdvisorInfo advisor={props.advisor} />
          </Grid>
          <Grid item xs={12} style={{ marginTop: 5 }}>
            {!props.noRights && (
              <>
                <DbMappingForm
                  id={props.advisor.guid + '-advisor'}
                  isLoading={props.isLoading}
                  dbMapping={props.advisor.dbMapping}
                  isRegistrationAdmin={isRegistrationAdmin}
                  filter={props.levelFilter}
                  onConfirmStringMapping={(mapping: IDatabaseMappingOfString) => {
                    const newAdvisor = new AdvisorImport({
                      ...props.advisor,
                      dbMapping: new DatabaseMappingOfString(mapping),
                    });
                    props.onUpdate(newAdvisor);
                  }}
                  title="Advisor"
                />
                <ValueChangeForm
                  id={props.advisor.guid + '-firstname'}
                  change={props.advisor.firstNameChange}
                  isLoading={props.isLoading}
                  disabled={props.disabled}
                  filter={props.levelFilter}
                  onConfirm={(valueChange: IValueChange) => {
                    const newAdvisor = new AdvisorImport({
                      ...props.advisor,
                      firstNameChange: new ValueChangeOfString({ ...props.advisor.firstNameChange, ...valueChange }),
                    });
                    props.onUpdate(newAdvisor);
                  }}
                  title="First Name Change"
                />
                <ValueChangeForm
                  id={props.advisor.guid + '-familyname'}
                  change={props.advisor.familyNameChange}
                  isLoading={props.isLoading}
                  disabled={props.disabled}
                  filter={props.levelFilter}
                  onConfirm={(valueChange: IValueChangeOfString) => {
                    const newAdvisor = new AdvisorImport({
                      ...props.advisor,
                      familyNameChange: new ValueChangeOfString({ ...props.advisor.familyNameChange, ...valueChange }),
                    });
                    props.onUpdate(newAdvisor);
                  }}
                  title="Family Name Change"
                />
                <DbMappingForm
                  id={props.advisor.guid + '-uas-email'}
                  isLoading={props.isLoading}
                  dbMapping={props.advisor.uasEmail?.dbMapping}
                  isRegistrationAdmin={isRegistrationAdmin}
                  disabled={props.disabled}
                  filter={props.levelFilter}
                  onConfirmLongMapping={(mapping: IDatabaseMappingOfNullableLong) => {
                    const newAdvisor = new AdvisorImport({
                      ...props.advisor,
                      uasEmail: new EmailImport({
                        ...props.advisor.uasEmail,
                        dbMapping: new DatabaseMappingOfNullableLong(mapping),
                      }),
                    });
                    props.onUpdate(newAdvisor);
                  }}
                  title="UAS Email Change"
                />
                <ValueChangeForm
                  id={props.advisor.guid + '-uas-email'}
                  change={props.advisor.uasEmail?.nameChange}
                  isLoading={props.isLoading}
                  disabled={props.disabled}
                  filter={props.levelFilter}
                  onConfirm={(valueChange: IValueChange) => {
                    const newAdvisor = new AdvisorImport({
                      ...props.advisor,
                      uasEmail: new EmailImport({
                        ...props.advisor.uasEmail,
                        nameChange: new ValueChangeOfString({ ...props.advisor.familyNameChange, ...valueChange }),
                      }),
                    });
                    props.onUpdate(newAdvisor);
                  }}
                  title="UAS Email Name"
                />
              </>
            )}
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};
