// Types
export default {
  SET_STUDENT_LOADING: 'studentManagement/SET_STUDENT_LOADING',
  SET_STUDENT_INFO: 'studentManagement/SET_STUDENT_INFO',
  ADD_NEW_SEMESTER_REGISTRATION: 'studentManagement/ADD_NEW_SEMESTER_REGISTRATION',
  CANCEL_ADDING_SEMESTER_REGISTRATION: 'studentManagement/CANCEL_ADDING_SEMESTER_REGISTRATION',

  ADD_REGISTRATION_COMMENT: 'studentManagement/ADD_REGISTRATION_COMMENT',
  REMOVE_REGISTRATION_COMMENT: 'studentManagement/REMOVE_REGISTRATION_COMMENT',

  UPDATE_MODULE_REGISTRATION: 'studentManagement/UPDATE_MODULE_REGISTRATION',
  ADD_MODULE_REGISTRATION: 'studentManagement/ADD_MODULE_REGISTRATION',
  REMOVE_MODULE_REGISTRATION: 'studentManagement/REMOVE_MODULE_REGISTRATION',

  UPDATE_SEMESTER_REGISTRATION: 'studentManagement/UPDATE_SEMESTER_REGISTRATION',
  ADD_SEMESTER_REGISTRATION: 'studentManagement/ADD_SEMESTER_REGISTRATION',
  REMOVE_SEMESTER_REGISTRATION: 'studentManagement/REMOVE_SEMESTER_REGISTRATION',
};
