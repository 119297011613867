import {
  CommentDto,
  FileResponse,
  ICommentDto,
  IModuleCategoryDto,
  IModuleExecutionDto,
  IPhaseCapacityDto,
  IRegistrationClient,
  IRegistrationEditClient,
  IRegistrationExportClient,
  IRegistrationStateChangeDto,
  ISemesterInfoDto,
  RegistrationState,
} from 'app/clients/services';
import { addExceptionNotification, addSuccess } from 'app/modules/notification';
import { ModuleSelectionActions } from 'app/modules/selections/module-selection/actions';
import { AsyncThunk } from 'app/shared/util/action-type.util';
import { saveAs } from 'file-saver';
import ACTION_TYPES from './types';

export const initActions = (
  componentDispatch,
  registrationAdminClient: IRegistrationClient,
  registrationExportClient: IRegistrationExportClient,
  registrationEditClient: IRegistrationEditClient,
  moduleSelectionActions: ModuleSelectionActions,
) => {
  const setRequestedSelected = (selected: number[]) => ({
    type: ACTION_TYPES.SET_REQUESTED_SELECTED,
    payload: selected,
  });

  const setConfirmedSelected = (selected: number[]) => ({
    type: ACTION_TYPES.SET_CONFIRMED_SELECTED,
    payload: selected,
  });

  const setAbsentexSelected = (selected: number[]) => ({
    type: ACTION_TYPES.SET_ABSENTEX_SELECTED,
    payload: selected,
  });

  const setRejectedSelected = (selected: number[]) => ({
    type: ACTION_TYPES.SET_REJECTED_SELECTED,
    payload: selected,
  });

  const setWithdrawnSelected = (selected: number[]) => ({
    type: ACTION_TYPES.SET_WITHDRAWN_SELECTED,
    payload: selected,
  });

  const setOthersSelected = (selected: number[]) => ({
    type: ACTION_TYPES.SET_OTHERS_SELECTED,
    payload: selected,
  });

  const setMainContentLoading = (loading: boolean) => ({
    type: ACTION_TYPES.SET_MAIN_CONTENT_LOADING,
    payload: loading,
  });

  // Thunks
  const changeRegistrationStatesInPrioritizationAsync: (
    registrationState: RegistrationState,
    comment: string,
    ids: number[],
  ) => AsyncThunk = (registrationState, comment, ids) => async (dispatch, getState) => {
    await dispatch({
      type: ACTION_TYPES.CHANGE_STATES,
      payload: registrationAdminClient.changeRegistrationStatesInPrioritization(registrationState, comment, ids),
    });

    const currentState = getState().moduleSelection;
    const selection = getState().semesterSelection;
    if (currentState.selectedExecution) {
      await dispatch({
        type: ACTION_TYPES.FETCH_MODULE_REGISTRATIONS,
        payload: registrationAdminClient.getPriorities(currentState.selectedExecution.id),
      });
    }

    await dispatch({
      type: ACTION_TYPES.FETCH_CAPACITIES,
      payload: registrationAdminClient.getCapacities(selection.selectedSemester?.id),
    });
  };

  const getCapacitiesAsync: (semester: ISemesterInfoDto) => AsyncThunk<IPhaseCapacityDto[]> =
    (semester) => async (dispatch) => {
      const result = await dispatch({
        type: ACTION_TYPES.FETCH_CAPACITIES,
        payload: registrationAdminClient.getCapacities(semester?.id),
      });
      return result.value;
    };

  const selectCategoryAsync: (category?: IModuleCategoryDto) => AsyncThunk = (category) => async (dispatch) => {
    dispatch(setMainContentLoading(true));
    try {
      const moduleSelectionResult = await dispatch(moduleSelectionActions.selectModuleCategoryAsync(category));
      await dispatch(getCapacitiesAsync(moduleSelectionResult.semester));
      dispatch(setMainContentLoading(false));
    } catch (err) {
      dispatch(addExceptionNotification(err));
      dispatch(setMainContentLoading(false));
    }
  };

  const selectSemesterAsync: (semester?: ISemesterInfoDto) => AsyncThunk = (semester) => async (dispatch) => {
    dispatch(setMainContentLoading(true));
    try {
      const moduleSelectionResult = await dispatch(moduleSelectionActions.changeSemesterFilterAsync(null, semester));
      await dispatch(getCapacitiesAsync(moduleSelectionResult.semester));
      if (moduleSelectionResult.execution) {
        await dispatch({
          type: ACTION_TYPES.FETCH_MODULE_REGISTRATIONS,
          payload: registrationAdminClient.getPriorities(moduleSelectionResult.execution.id),
        });
      }
      dispatch(setMainContentLoading(false));
    } catch (err) {
      dispatch(addExceptionNotification(err));
      dispatch(setMainContentLoading(false));
    }
  };

  const setFiltersAsync: (
    category?: IModuleCategoryDto,
    moduleExecution?: IModuleExecutionDto,
    semester?: ISemesterInfoDto,
  ) => AsyncThunk = (category, moduleExecution, semester) => async (dispatch) => {
    dispatch(setMainContentLoading(true));
    try {
      const moduleSelectionResult = await dispatch(
        moduleSelectionActions.setFilterAsync(category, null, moduleExecution, null, semester),
      );
      await dispatch(getCapacitiesAsync(moduleSelectionResult.semester));
      if (moduleSelectionResult.execution) {
        await dispatch({
          type: ACTION_TYPES.FETCH_MODULE_REGISTRATIONS,
          payload: registrationAdminClient.getPriorities(moduleSelectionResult.execution.id),
        });
      }

      dispatch(setMainContentLoading(false));
    } catch (err) {
      dispatch(addExceptionNotification(err));
      dispatch(setMainContentLoading(false));
    }
  };

  const setModuleExecutionIdAsync: (moduleExecutionId: number) => AsyncThunk = (id) => async (dispatch) => {
    dispatch(setMainContentLoading(true));
    try {
      const moduleSelectionResult = await dispatch(moduleSelectionActions.setFilterForExecutionIdAsync(id));
      await dispatch(getCapacitiesAsync(moduleSelectionResult.semester));
      if (moduleSelectionResult.execution) {
        await dispatch({
          type: ACTION_TYPES.FETCH_MODULE_REGISTRATIONS,
          payload: registrationAdminClient.getPriorities(moduleSelectionResult.execution.id),
        });
      }
      dispatch(setMainContentLoading(false));
    } catch (err) {
      dispatch(addExceptionNotification(err));
      dispatch(setMainContentLoading(false));
    }
  };

  const selectModuleExecutionAsync: (moduleExecution: IModuleExecutionDto) => AsyncThunk =
    (moduleExecution) => async (dispatch) => {
      dispatch(setMainContentLoading(true));
      try {
        const moduleSelectionResult = await dispatch(moduleSelectionActions.selectExecutionAsync(moduleExecution));
        await dispatch(getCapacitiesAsync(moduleSelectionResult.semester));
        if (moduleExecution) {
          await dispatch({
            type: ACTION_TYPES.FETCH_MODULE_REGISTRATIONS,
            payload: registrationAdminClient.getPriorities(moduleExecution.id),
          });
        }
        dispatch(setMainContentLoading(false));
      } catch (err) {
        dispatch(setMainContentLoading(false));
        dispatch(addExceptionNotification(err));
      }
    };

  const exportAsync: (moduleExecutionId: number) => AsyncThunk<FileResponse> =
    (moduleExecutionId) => async (dispatch) => {
      const result = await dispatch({
        type: ACTION_TYPES.LOAD_DATA,
        payload: registrationExportClient.exportRegistrationsForModuleExecution(moduleExecutionId),
      });

      const fileResponse = result.value;
      saveAs(fileResponse.data, fileResponse.fileName);
      return fileResponse;
    };

  const autoConfirmAsync: (semesterId: number, categoryId: number) => AsyncThunk =
    (semesterId, categoryId) => async (dispatch, getState) => {
      dispatch(setMainContentLoading(true));
      try {
        await dispatch({
          type: ACTION_TYPES.LOAD_DATA,
          payload: registrationAdminClient.autoConfirm(semesterId, categoryId),
        });
        const semesterSelection = getState().semesterSelection;
        await dispatch(getCapacitiesAsync(semesterSelection.selectedSemester));
        const moduleSelection = getState().moduleSelection;
        if (moduleSelection.selectedExecution) {
          await dispatch({
            type: ACTION_TYPES.FETCH_MODULE_REGISTRATIONS,
            payload: registrationAdminClient.getPriorities(moduleSelection.selectedExecution.id),
          });
        }
        dispatch(setMainContentLoading(false));
      } catch (err) {
        dispatch(addExceptionNotification(err));
        dispatch(setMainContentLoading(false));
      }
    };

  const addCommentAsync: (comment: ICommentDto) => AsyncThunk = (comment) => async (dispatch) => {
    try {
      const id = await registrationEditClient.addComment(new CommentDto(comment));
      const newComment = new CommentDto({ ...comment, id });
      dispatch({
        type: ACTION_TYPES.ADD_REGISTRATION_COMMENT,
        payload: newComment,
      });
      dispatch(addSuccess(`Successfully added comment.`));
    } catch (e) {
      dispatch(addExceptionNotification(e, 'An unknown error occured while saving comment.'));
    }
  };

  const removeCommentAsync: (comment: ICommentDto) => AsyncThunk = (comment) => async (dispatch) => {
    try {
      await registrationEditClient.deleteComment(comment.id);

      dispatch({
        type: ACTION_TYPES.REMOVE_REGISTRATION_COMMENT,
        payload: comment,
      });
      dispatch(addSuccess(`Successfully deleted comment.`));
    } catch (e) {
      dispatch(addExceptionNotification(e, 'An unknown error occured while deleting comment.'));
    }
  };

  const getHistoryAsync = async (moduleRegistrationId: number): Promise<IRegistrationStateChangeDto[]> => {
    return await registrationEditClient.getHistory(moduleRegistrationId);
  };

  return Object.freeze({
    setFiltersAsync: async (
      category?: IModuleCategoryDto,
      moduleExecution?: IModuleExecutionDto,
      semester?: ISemesterInfoDto,
    ) => await componentDispatch(setFiltersAsync(category, moduleExecution, semester)),
    setMainContentLoading: (loading: boolean) => componentDispatch(setMainContentLoading(loading)),
    changeRegistrationStatesAsync: async (state: RegistrationState, comment: string, selected: number[]) =>
      await componentDispatch(changeRegistrationStatesInPrioritizationAsync(state, comment, selected)),
    selectCategoryAsync: async (category: IModuleCategoryDto) => await componentDispatch(selectCategoryAsync(category)),
    setModuleExecutionIdAsync: async (executionId: number) =>
      await componentDispatch(setModuleExecutionIdAsync(executionId)),
    selectModuleExecutionAsync: async (moduleExecution: IModuleExecutionDto) =>
      await componentDispatch(selectModuleExecutionAsync(moduleExecution)),
    selectSemesterAsync: async (semester: ISemesterInfoDto) => await componentDispatch(selectSemesterAsync(semester)),
    setRequestedSelected: (selected: number[]) => componentDispatch(setRequestedSelected(selected)),
    setConfirmedSelected: (selected: number[]) => componentDispatch(setConfirmedSelected(selected)),
    setRejectedSelected: (selected: number[]) => componentDispatch(setRejectedSelected(selected)),
    setAbsentexSelected: (selected: number[]) => componentDispatch(setAbsentexSelected(selected)),
    setWithdrawnSelected: (selected: number[]) => componentDispatch(setWithdrawnSelected(selected)),
    setOthersSelected: (selected: number[]) => componentDispatch(setOthersSelected(selected)),
    getCapacitiesAsync: async (semester: ISemesterInfoDto) => await componentDispatch(getCapacitiesAsync(semester)),
    exportAsync: async (executionId: number) => await componentDispatch(exportAsync(executionId)),
    autoConfirmAsync: async (semesterId: number, categoryId: number) =>
      await componentDispatch(autoConfirmAsync(semesterId, categoryId)),
    addCommentAsync: async (comment: ICommentDto) => await componentDispatch(addCommentAsync(comment)),
    removeCommentAsync: async (comment: ICommentDto) => await componentDispatch(removeCommentAsync(comment)),
    getHistoryAsync,
  });
};

export default initActions;

export type ModuleRegistrationActions = ReturnType<typeof initActions>;
