import React, { useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { Alert, Grid, Typography } from '@mui/material';
import { IRootState } from 'app/config/root.reducer';
import authModule from 'app/modules/login/auth/index';
import { DefaultButton } from 'app/shared/layout/ui-elements/buttons';
import { TextContent } from 'app/shared/layout/ui-elements/component-layout';
import userManagementModule from '../user-management/user-management';

export type Props = PropsFromRedux;

export const Register = (props: Props) => {
  const [registered, setRegistered] = useState(false);

  useEffect(() => {
    const getSession = async () => {
      await props.getSessionAsync();
    };
    getSession();
  }, []);

  const handleApply = async () => {
    await props.registerAsync();
    await props.getSessionAsync();
    setRegistered(true);
  };

  return (
    <TextContent>
      <Grid container style={{ flexGrow: 1 }} spacing={2}>
        <Grid item xs={12}>
          {!props.account?.firstName && (
            <Alert color="error">You are not logged in! Please log in with your SWITCH-EduId Account.</Alert>
          )}
          {props.account && props.account?.firstName && (
            <>
              <Typography variant="h1">Welcome {props.account.firstName}!</Typography>
              <Typography>This is the module registration for MSE-CH - Region D.</Typography>

              {props.account.hasRegistration || registered ? (
                <Alert color="info">
                  Thank you for sending us an account request. Your account on our website is not activated yet.
                  <br />
                  <br />
                  Please wait until an administrator contacts you or try to logout and login again in case someone just
                  activated your account.
                </Alert>
              ) : (
                <>
                  <Alert color="info">
                    You have successfully logged in with your EduId account, but you don not have an account on our
                    website yet.
                    <br />
                    <br />
                    If you want to have access to our website, please click on &quot;Apply for an Account&quot;. An
                    administrator has to accept your application after that and will contact you as soon as your account
                    is ready.
                    <br />
                    <br />
                    <DefaultButton primary onClick={handleApply}>
                      Apply for an Account
                    </DefaultButton>
                  </Alert>
                </>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </TextContent>
  );
};

const mapStateToProps = ({ auth }: IRootState) => ({
  account: auth.account,
});

const mapDispatchToProps = (dispatch) => {
  const authActions = authModule.initActions();
  const userManagementActions = userManagementModule.initActions(dispatch);
  return {
    getSessionAsync: async () => await dispatch(authActions.getSessionAsync()),
    registerAsync: userManagementActions.registerAsync,
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Register);
