import { Alert, AlertColor, Tooltip, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { APP_DATE_FORMAT, COLOR_ERROR, COLOR_INFO, COLOR_SUCCESS, COLOR_WARNING } from 'app/config/constants';
import React from 'react';
import {
  IExcelCell,
  IExcelColumn,
  IRegistrationRow,
  IRegistrationSheet,
  IValidationMessage,
  ValidationLevel,
} from '../../../clients/services';

export interface Props {
  messages: IValidationMessage[];
  columnInfo: IExcelColumn[];
  registrations: IRegistrationSheet;
  showEverything?: boolean;
}

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     formControl: {
//       margin: theme.spacing(2),
//       minWidth: 400,
//       marginTop: 5,
//       marginBottom: 8,
//     },
//     table: {},
//   }),
// );

export const RegistrationTable = ({ columnInfo, registrations, messages, showEverything }: Props) => {
  // const classes = useStyles();

  const cellStyle = {
    A: {
      align: 'right',
      style: { width: 60 },
      format: (row: IRegistrationRow) => row.dateOfRegistration?.toFormat(APP_DATE_FORMAT),
    },
    B: { align: 'center', style: { width: 30 }, format: (row: IRegistrationRow) => (row.isDeregistration ? 'X' : '') },
    C: { align: 'center', style: { width: 30 }, format: (row: IRegistrationRow) => (row.isRegistration ? 'X' : '') },
    H: {
      align: 'right',
      style: { width: 60 },
      format: (row: IRegistrationRow) => row.birthDate?.toFormat(APP_DATE_FORMAT),
    },
  };

  const alwaysShowColumn = ['A', 'B', 'C', 'E', 'F', 'K'];

  const orderedColumns =
    columnInfo?.sort((c1, c2) => (c1.columnName > c2.columnName ? 1 : c1.columnName > c2.columnName ? -1 : 0)) ?? [];

  const getColumnAlign = (column: string) => {
    return cellStyle[column]?.align ?? 'left';
  };

  const getHeaderStyle = (column: string) => {
    return cellStyle[column]?.style ?? {};
  };

  const getValue = (column: IExcelColumn, row: IRegistrationRow) => {
    if (cellStyle[column.columnName]?.format) {
      return cellStyle[column.columnName]?.format(row);
    }
    const propertyName = column.propertyName.charAt(0).toLowerCase() + column.propertyName.slice(1);
    return row[propertyName];
  };

  const createCellsToStyle = (): { message: IValidationMessage; cell: IExcelCell }[] => {
    const cells = [];
    messages?.map((m) => m.cells?.map((c) => cells.push({ message: m, cell: c })));
    return cells;
  };

  const cellsWithAssignedLevels = createCellsToStyle();

  const columnHasMessage = (columnName: string) => {
    return cellsWithAssignedLevels?.filter((c) => c.cell.columnName === columnName).length > 0;
  };

  const getHeaders = () => {
    return orderedColumns
      .filter(
        (c) =>
          alwaysShowColumn.filter((cn) => c.columnName === cn).length > 0 ||
          columnHasMessage(c.columnName) ||
          showEverything,
      )
      .map((c, key) => (
        <TableCell key={key} align={getColumnAlign(c.columnName)} style={getHeaderStyle(c.columnName)}>
          <Tooltip title={<span>{c.fullName}</span>}>
            <span>{c.columnName}</span>
          </Tooltip>
        </TableCell>
      ));
  };

  const getMessage = (
    messagesForRow: {
      message: IValidationMessage;
      cell: IExcelCell;
    }[],
    columnName: string,
  ) => {
    const filteredCells = messagesForRow?.filter((c) => c.cell.columnName === columnName);
    if (filteredCells.length > 0) {
      const message =
        filteredCells.find((c) => c.message?.level === ValidationLevel.Error)?.message ??
        filteredCells.find((c) => c.message?.level === ValidationLevel.Warning)?.message ??
        filteredCells.find((c) => c.message?.level === ValidationLevel.Info)?.message ??
        filteredCells.find((c) => c.message?.level === ValidationLevel.Success)?.message ??
        filteredCells[0].message;

      return message;
    }
    return null;
  };

  const getCellStyle = (message: IValidationMessage) => {
    if (message) {
      switch (message.level) {
        case ValidationLevel.Error:
          return { backgroundColor: COLOR_ERROR, border: '1px double red' };
        case ValidationLevel.Warning:
          return { backgroundColor: COLOR_WARNING, border: '1px double yellow' };
        case ValidationLevel.Info:
          return { backgroundColor: COLOR_INFO, border: '1px double blue' };
        case ValidationLevel.Success:
          return { backgroundColor: COLOR_SUCCESS, border: '1px double green' };
        default:
          return { backgroundColor: COLOR_INFO, border: '1px double blue' };
      }
    }
    return {};
  };

  const getColumns = (row: IRegistrationRow) => {
    const cellsWithLevelsForRow = cellsWithAssignedLevels?.filter((c) => c.cell.row === row.rowNumber);
    return orderedColumns
      .filter(
        (c) =>
          alwaysShowColumn.filter((cn) => c.columnName === cn).length > 0 ||
          columnHasMessage(c.columnName) ||
          showEverything,
      )
      .map((c, key) => {
        const message = getMessage(cellsWithLevelsForRow, c.columnName);
        if (message) {
          return (
            <Tooltip
              key={key}
              title={
                <span>
                  <Alert style={{ marginBottom: 1 }} severity={message.level.toString().toLowerCase() as AlertColor}>
                    {message.level.toString()}
                    {message.code > 0 && <> {message.code}</>}: {message.message}
                  </Alert>
                  {message.correctionHint && (
                    <Typography
                      style={{ paddingLeft: 5, marginRight: 15, marginTop: 5, marginBottom: 10, fontSize: 14 }}
                    >
                      {message.correctionHint}
                    </Typography>
                  )}
                </span>
              }
            >
              <TableCell key={key} align={getColumnAlign(c.columnName)} style={getCellStyle(message)}>
                {getValue(c, row)}
              </TableCell>
            </Tooltip>
          );
        }
        return (
          <TableCell key={key} align={getColumnAlign(c.columnName)} style={getCellStyle(message)}>
            {getValue(c, row)}
          </TableCell>
        );
      });
  };

  return (
    <>
      {cellsWithAssignedLevels?.length > 0 && (
        <Table /* className={classes.table} */ aria-label="simple table" size="small">
          <TableHead style={{ backgroundColor: '#DDDDDD' }}>
            <TableRow>
              <TableCell align="right" style={{ width: 30 }}>
                \
              </TableCell>
              {getHeaders()}
            </TableRow>
          </TableHead>
          <TableBody>
            {registrations?.rows
              .filter(
                (r) => cellsWithAssignedLevels.filter((c) => c.cell.row === r.rowNumber).length > 0 || showEverything,
              )
              .map((row) => (
                <TableRow key={row.rowNumber} style={{ backgroundColor: '#EEEEEE' }}>
                  <TableCell component="th" scope="row" style={{ backgroundColor: '#DDDDDD' }}>
                    {row.rowNumber}
                  </TableCell>
                  {getColumns(row)}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      )}
    </>
  );
};
