import { LinearProgress } from '@mui/material';
import React from 'react';

type Props = {
  isLoading: boolean;
  children: React.ReactNode;
};

export const Spinner: React.FunctionComponent<Props> = (props: Props) => {
  return <>{props.isLoading ? <LinearProgress /> : <>{props.children}</>}</>;
};
