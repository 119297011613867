import { Alert, Typography } from '@mui/material';
import { IRootState } from 'app/config/root.reducer';
import { getLoginUrl } from 'app/shared/util/url-utils';
import React from 'react';
import { connect } from 'react-redux';
import { TextContent } from './ui-elements/component-layout';

export type IHomeProp = StateProps;

export const Home = (props: IHomeProp) => {
  const { account } = props;

  const alertLinkStyle = {
    textDecoration: 'none',
    fontWeight: 'bold',
    color: 'black',
  };

  return (
    <TextContent>
      <Typography variant="h1">Welcome{account && account.firstName && <> {account.firstName}</>}!</Typography>
      <Typography variant="body1">This is the module registration for MSE-CH - Region D.</Typography>
      {account && account.firstName && account.isAuthenticated ? (
        <Alert color="success">You are logged in as {account.roles}</Alert>
      ) : (
        <Alert color="warning">
          If you have an Account, please&nbsp;
          <a href={getLoginUrl()} style={alertLinkStyle}>
            sign in
          </a>
          &nbsp;with your SWITCH EduId-Account.
          <br />
          You can also validate excel registration files without checking sensitive data.
          <br /> Just click on&nbsp;
          <a href="./registration-import" style={alertLinkStyle}>
            Registration
          </a>
          &nbsp;to validate your registrations.
        </Alert>
      )}
    </TextContent>
  );
};

function mapStateToProps({ auth }: IRootState) {
  return {
    account: auth.account,
    isAuthenticated: auth.isLoggedIn,
  };
}

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(Home);
