import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AccountRequests from './account-requests';
import UserAccounts from './user-accounts';
import UserManagementTabs from './user-management-tabs';

const UserManagementRoutes = (props: { baseUrl: string }) => {
  return (
    <>
      <UserManagementTabs baseUrl={props.baseUrl} />
      <Routes>
        <Route path={`account-requests`} element={<AccountRequests />} />
        <Route path={`accounts/:id?`} element={<UserAccounts />} />
      </Routes>
    </>
  );
};

export default UserManagementRoutes;
