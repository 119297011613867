import ACTION_TYPES from './types';
import { saveAs } from 'file-saver';
import {
  FileResponse,
  IRegistrationExportClient,
  IRegistrationClient,
  ISemesterInfoDto,
  IUasDto,
  IAcademicYearDto,
  IPhaseDto,
  IRegistrationEditClient,
  IRegistrationStateChangeDto,
  CommentDto,
  ICommentDto,
} from 'app/clients/services';
import { AsyncThunk, Thunk } from 'app/shared/util/action-type.util';
import { getUas } from './methods';
import { addExceptionNotification, addSuccess } from 'app/modules/notification';

export const initActions = (
  componentDispatch,
  registrationAdminClient: IRegistrationClient,
  registrationExportClient: IRegistrationExportClient,
  registrationEditClient: IRegistrationEditClient,
  semesterActions: {
    selectSemester: (semester: ISemesterInfoDto) => Thunk<{ academicYear: IAcademicYearDto; semester: ISemesterInfoDto; phase: IPhaseDto }>;
  }
) => {
  const selectUas = (uas: IUasDto) => ({
    type: ACTION_TYPES.SELECT_UAS,
    payload: uas,
  });

  const setMainContentLoading = (loading: boolean) => ({
    type: ACTION_TYPES.SET_MAIN_CONTENT_LOADING,
    payload: loading,
  });

  const getUasRegistrationsAsync: (semester: ISemesterInfoDto, uasId: number) => AsyncThunk =
    (semester, uasId) => async (dispatch, getState) => {
      const authState = getState().auth;
      dispatch(semesterActions.selectSemester(semester));
      const uas = getUas(authState, uasId);
      dispatch({
        type: ACTION_TYPES.SELECT_UAS,
        payload: uas,
      });

      await dispatch({
        type: ACTION_TYPES.FETCH_UAS_REGISTRATIONS,
        payload: registrationAdminClient.getRegistrationsForUas(uas?.id, semester.id),
      });
    };

  const deleteRegistrationsForUasAsync: (semester: ISemesterInfoDto, uasId: number) => AsyncThunk = (semester, uasId) => async dispatch => {
    dispatch(setMainContentLoading(true));
    await registrationAdminClient.deleteRegistrations(semester.id, uasId);
    await dispatch(getUasRegistrationsAsync(semester, uasId));
    dispatch(setMainContentLoading(false));
  };

  const exportAsync: (uasId: number, semesterId: number) => AsyncThunk<FileResponse> = (uasId, semesterId) => async dispatch => {
    const result = await dispatch({
      type: ACTION_TYPES.DOWNLOAD,
      payload: registrationExportClient.exportRegistrationsForUas(uasId, semesterId),
    });

    const fileResponse = result.value;
    saveAs(fileResponse.data, fileResponse.fileName);
    return fileResponse;
  };

  const addCommentAsync: (comment: ICommentDto) => AsyncThunk = comment => async dispatch => {
    try {
      const id = await registrationEditClient.addComment(new CommentDto(comment));
      const newComment = new CommentDto({ ...comment, id });
      dispatch({
        type: ACTION_TYPES.ADD_REGISTRATION_COMMENT,
        payload: newComment,
      });
      dispatch(addSuccess(`Successfully added comment.`));
    } catch (e) {
      dispatch(addExceptionNotification(e, 'An unknown error occured while saving comment.'));
    }
  };

  const removeCommentAsync: (comment: ICommentDto) => AsyncThunk = comment => async dispatch => {
    try {
      await registrationEditClient.deleteComment(comment.id);

      dispatch({
        type: ACTION_TYPES.REMOVE_REGISTRATION_COMMENT,
        payload: comment,
      });
      dispatch(addSuccess(`Successfully deleted comment.`));
    } catch (e) {
      dispatch(addExceptionNotification(e, 'An unknown error occured while deleting comment.'));
    }
  };

  const getHistoryAsync = async (moduleRegistrationId: number): Promise<IRegistrationStateChangeDto[]> => {
    return await registrationAdminClient.getHistory(moduleRegistrationId);
  };

  return Object.freeze({
    setMainContentLoading: (loading: boolean) => componentDispatch(setMainContentLoading(loading)),
    selectUas: (uas: IUasDto) => componentDispatch(selectUas(uas)),
    getUasRegistrationsAsync: async (semester: ISemesterInfoDto, uasId: number) =>
      await componentDispatch(getUasRegistrationsAsync(semester, uasId)),
    deleteRegistrationsForUasAsync: async (semester: ISemesterInfoDto, uasId: number) =>
      await componentDispatch(deleteRegistrationsForUasAsync(semester, uasId)),
    exportAsync: async (uasId: number, semesterId: number) => await componentDispatch(exportAsync(uasId, semesterId)),
    addCommentAsync: async (comment: ICommentDto) => await componentDispatch(addCommentAsync(comment)),
    removeCommentAsync: async (comment: ICommentDto) => await componentDispatch(removeCommentAsync(comment)),
    getHistoryAsync,
  });
};

export default initActions;

export type UasRegistrationsActions = ReturnType<typeof initActions>;
