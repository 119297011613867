import { Grid, Typography } from '@mui/material';
import { IStudentPreviewDto } from 'app/clients/services';
import { APP_DATE_FORMAT } from 'app/config/constants';
import React from 'react';

type Props = {
  student: IStudentPreviewDto;
};

export const StudentPreview = (props: Props) => {
  return (
    <Grid container>
      <Grid item xs={12} md={3}>
        <Typography>
          First Name: <strong>{props.student.firstName}</strong>
          <br />
          Family Name: <strong>{props.student.familyName}</strong>
          <br />
          {props.student.birthDate && (
            <>
              Birth date: {props.student.birthDate.toFormat(APP_DATE_FORMAT)}
              <br />
            </>
          )}
          {props.student.matriculationNr && (
            <>
              Matriculation Number: {props.student.matriculationNr}
              <br />
            </>
          )}
          {props.student.previousDegree && (
            <>
              Previous Degree:
              <br /> {props.student.previousDegree}
            </>
          )}
        </Typography>
      </Grid>
      <Grid item xs={12} md={3}>
        <Typography>
          Emails:
          <br />
          {props.student.emails?.map((e, k) => {
            return <div key={k}>{e}</div>;
          })}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography>
          Semester Registrations:
          {props.student.semesterRegistrations?.map((s, k) => {
            return (
              <Grid container key={k}>
                <Grid item xs={3}>
                  {s.semester}
                </Grid>
                <Grid item xs={3}>
                  {s.uas}
                </Grid>
                <Grid item xs={3}>
                  {s.profile}
                </Grid>
              </Grid>
            );
          })}
        </Typography>
      </Grid>
    </Grid>
  );
};
