import { Badge, Box, InputLabel, List, ListItemButton, Typography } from '@mui/material';
import { toCamelCase } from 'app/shared/util/string-utils';
import React from 'react';
import { Link } from 'react-router-dom';

export type BadgeProps = {
  getContent: (item: any) => number;
  color: 'error' | 'primary' | 'secondary' | 'default';
};

export type Props = {
  items: any[];
  title: string;
  hideTitle?: boolean;
  createUrl: (item: any) => string;
  getItemKey: (item: any) => string | number;
  getItemTitle: (item: any) => string;
  selectedItem: any;
  onClick?: (item: any) => void | Promise<void>;
  badges?: BadgeProps[];
  renderSubList?: (item: any) => JSX.Element;
};

export const NavigationList = (props: Props) => {
  const label = toCamelCase(props.title);
  return (
    <>
      {!props.hideTitle && (
        <InputLabel htmlFor={label} sx={{ fontSize: 'small', marginTop: 2, marginLeft: 2, marginBottom: 0 }}>
          {props.title}
        </InputLabel>
      )}
      <List aria-label={label}>
        {props.items.map((item, key) => (
          <Box key={key}>
            <ListItemButton
              component={Link}
              to={props.createUrl(item) ?? '#'}
              type="submit"
              selected={props.getItemKey(item) === (props.selectedItem ? props.getItemKey(props.selectedItem) : 0)}
              onClick={() => (props.onClick ? props.onClick(item) : {})}
            >
              <Box display="flex">
                <Box flexGrow={1}>
                  <Typography style={{ fontSize: 14, fontWeight: 400 }}>
                    {props.getItemTitle(item) ?? 'untitled'}
                  </Typography>
                </Box>
                {props.badges && props.badges.length && (
                  <Box>
                    {props.badges.map((badge, badgeKey) => (
                      <Badge
                        key={badgeKey}
                        color={badge.color}
                        badgeContent={badge.getContent(item)}
                        sx={{ marginLeft: 3 }}
                      ></Badge>
                    ))}
                  </Box>
                )}
              </Box>
            </ListItemButton>
            {props.renderSubList && props.renderSubList(item)}
          </Box>
        ))}
      </List>
    </>
  );
};
