// Types
export default {
  SET_LOADING: "semesterManagement/SET_LOADING",
  SET_SEMESTER_LOADING: "semesterManagement/SET_SEMESTER_LOADING",
  SET_PHASE_LOADING: "semesterManagement/SET_PHASE_LOADING",

  SELECT_ACADEMIC_YEAR: "semesterManagement/SELECT_ACADEMIC_YEAR",

  ADD_SEMESTER: "semesterManagement/ADD_SEMESTER",
  ADD_ACADEMIC_YEAR: "semesterManagement/ADD_ACADEMIC_YEAR:",

  FILTER_REFRESHED: "semesterManagement/FILTER_REFRESHED",
};
