import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ProfilesTabs from './semester-management-tabs';
import Semesters from './semesters-component';

const SemesterManagementRoutes = ({ baseUrl }) => {
  return (
    <>
      <ProfilesTabs baseUrl={baseUrl} />
      <Routes>
        <Route path={`semesters/:academicYearId?`} element={<Semesters />} />
      </Routes>
    </>
  );
};

export default SemesterManagementRoutes;
