import { REQUEST, SUCCESS, FAILURE } from 'app/shared/util/action-type.util';
import { IUserDto, UasDto } from 'app/clients/services';
import ACTION_TYPES from './types';

const initialState = {
  loading: false,
  isLoggedIn: false,
  account: null as unknown as IUserDto,
  errorMessage: null as unknown as string, // Errors returned from server side
  redirectMessage: null as unknown as string,
  sessionHasBeenFetched: false,
  idToken: null as unknown as string,
  logoutUrl: null as unknown as string,
  allowedUas: [] as UasDto[],
};

export type AuthState = Readonly<typeof initialState>;

// Reducer

export default (state: AuthState = initialState, action): AuthState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.GET_SESSION):
      return {
        ...state,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.GET_SESSION):
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        sessionHasBeenFetched: true,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.GET_SESSION): {
      return {
        ...state,
        isLoggedIn: action.payload && (action.payload.firstName || action.payload.lastName),
        loading: false,
        sessionHasBeenFetched: true,
        account: action.payload,
        allowedUas: action.payload?.allowedUas ?? [],
      };
    }
    case SUCCESS(ACTION_TYPES.LOGOUT):
      return {
        ...initialState,
        idToken: action.payload.data.idToken,
        logoutUrl: action.payload.data.logoutUrl,
      };
    case ACTION_TYPES.SET_ALLOWED_UAS:
      return {
        ...initialState,
        allowedUas: action.payload,
      };
    case ACTION_TYPES.ERROR_MESSAGE:
      return {
        ...initialState,
        redirectMessage: action.message,
      };
    case ACTION_TYPES.CLEAR_AUTH:
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
      };
    default:
      return state;
  }
};
