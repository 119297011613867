import { IRootState } from '../../config/root.reducer';
import { AnyAction, Action } from 'redux';
import { AsyncAction as OrigAsyncAction, ActionType } from 'redux-promise-middleware';

/**
 * Appends REQUEST async action type
 */

export const REQUEST = actionType => `${actionType}_${ActionType.Pending}`;

/**
 * Appends SUCCESS async action type
 */

export const SUCCESS = actionType => `${actionType}_${ActionType.Fulfilled}`;

/**
 * Appends FAILURE async action type
 */

export const FAILURE = actionType => `${actionType}_${ActionType.Rejected}`;

declare type AsyncFunction<R = any> = () => Promise<R>;
declare type AsyncPayload<R = any> =
  | Promise<R>
  | AsyncFunction<R>
  | {
      promise: Promise<R> | AsyncFunction<R>;
      data?: any;
    };

export declare interface AsyncAction<R = any> extends OrigAsyncAction {
  payload: AsyncPayload<R>;
}

type AsyncActionResult<A> = A extends AsyncAction<infer R> ? R : never;

export type AsyncFulfilledAction<A extends AsyncAction, Type extends string = string> = Omit<A, 'type' | 'payload'> & {
  type: Type;
  payload: AsyncActionResult<A>;
};

type FulfilledDispatchResult<A extends AsyncAction> = {
  action: AsyncFulfilledAction<A>;
  value: AsyncActionResult<A>;
};

export type AsyncDispatchReturns<T> = T extends AsyncAction ? Promise<FulfilledDispatchResult<T>> : T;

// ----------------------
// redux-thunk extension
// ----------------------

export type ThunkDispatchReturns<S, E, A> = A extends ThunkAction<infer R, S, E> ? R : A;

export interface ThunkDispatch<S, E> {
  <A>(action: A): AsyncDispatchReturns<ThunkDispatchReturns<S, E, A>>;
}

export declare type ThunkAction<R, S, E = null> = (dispatch: ThunkDispatch<S, E>, getState: () => S, extraArgument: E) => R;

export type Thunk<ReturnType = void> = ThunkAction<ReturnType, IRootState, Action>;
export type AsyncThunk<ReturnType = void> = ThunkAction<Promise<ReturnType>, IRootState, AnyAction>;
