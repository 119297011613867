import { Typography } from '@mui/material';
import React from 'react';
import { IExcelColumn, IRegistrationSheet, IValidationMessage } from '../../../clients/services';
import { RegistrationTable } from './registration-table';

export interface Props {
  message: IValidationMessage;
  columnInfo?: IExcelColumn[];
  registrations?: IRegistrationSheet;
}

export const ShowCells = ({ message, columnInfo, registrations }: Props) => (
  <>
    {message.cells?.length > 0 && columnInfo && registrations && (
      <RegistrationTable messages={[message]} columnInfo={columnInfo} registrations={registrations} />
    )}
    <Typography style={{ paddingLeft: 5, marginRight: 15, marginTop: 5, marginBottom: 10, fontSize: 14 }}>
      {message.correctionHint && <>{message.correctionHint}</>}
    </Typography>
  </>
);
