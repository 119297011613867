import { Box } from '@mui/material';
import React from 'react';

export const DevRibbon = ({ profiles }) =>
  profiles.includes('dev') || profiles.includes('stag') ? (
    <Box
      sx={{
        zIndex: 2000,
        position: 'absolute',
        color: '#fff',
        display: 'block',
        top: '10px',
        left: '-80px',
        padding: '20px 80px 20px 80px',
        textAlign: 'center',
        textDecoration: 'none',
        textShadow: '0 0 5px #444',
        fontWeight: 400,
        opacity: 0.5,
        rotate: '-45deg',
        pointerEvents: 'none',
        backgroundColor: profiles.includes('dev') ? 'rgba(170, 0, 0, 0.7)' : 'rgba(0, 0, 170, 0.7)',
      }}
    >
      {profiles.includes('dev') ? 'Development' : 'Staging'}
    </Box>
  ) : (
    <></>
  );
