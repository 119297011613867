import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Alert, AlertColor, AlertTitle, IconButton, Paper } from '@mui/material';
import { IExcelColumn, IRegistrationSheet, IValidationMessage, ValidationLevel } from 'app/clients/services';
import { isHigherOrEqual } from 'app/shared/util/validation-level.util';
import React, { useState } from 'react';
import { Rejected } from './rejected';
import { ShowCells } from './show-cells';

export interface Props {
  message: IValidationMessage;
  hideTitle?: boolean;
  children?: JSX.Element;
  hideInfo?: boolean;
  openInfo?: boolean;
  columnInfo?: IExcelColumn[];
  registrations?: IRegistrationSheet;
}

const ValidationMessage = ({ message, hideTitle, children, hideInfo, openInfo, columnInfo, registrations }: Props) => {
  const showInfoButton = () => {
    return isHigherOrEqual(message.level, ValidationLevel.Warning) || message?.cells?.length > 0;
  };

  const showCorrection = () => {
    if (isHigherOrEqual(message.level, ValidationLevel.Warning)) {
      return <Rejected message={message} columnInfo={columnInfo} registrations={registrations}></Rejected>;
    }

    if (message.cells?.length > 0) {
      return <ShowCells message={message} columnInfo={columnInfo} registrations={registrations}></ShowCells>;
    }
    return <></>;
  };

  const [showInfo, setShowInfo] = useState(openInfo || !showInfoButton());

  const toggleInfo = () => {
    setShowInfo(!showInfo);
  };

  const action = !hideInfo && showInfoButton() && (
    <IconButton
      aria-label="close"
      color="inherit"
      size="small"
      onClick={() => {
        toggleInfo();
      }}
    >
      {showInfo && <KeyboardArrowUpIcon fontSize="inherit" />}
      {!showInfo && (
        <>
          <span style={{ fontSize: 12 }}>More Information</span>
          <KeyboardArrowDownIcon fontSize="inherit" />
        </>
      )}
    </IconButton>
  );

  return (
    <Paper style={{ marginTop: 20, marginBottom: 5 }}>
      <Alert
        style={{ marginBottom: 1 }}
        severity={message.level.toString().toLowerCase() as AlertColor}
        action={action}
      >
        {!hideTitle && (
          <AlertTitle>
            {message.level.toString()}
            {message.code > 0 && <> {message.code}</>}
          </AlertTitle>
        )}
        {hideTitle && (
          <strong>
            {message.level.toString()}
            {message.code > 0 && <> {message.code}</>}:{' '}
          </strong>
        )}
        {message.message}
      </Alert>
      {children}
      {showInfo && showCorrection()}
    </Paper>
  );
};

export default ValidationMessage;
