import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import { Tooltip, Typography } from '@mui/material';
import { IAdvisorImport, IStudentImport, ValidationLevel } from 'app/clients/services';
import { getLevelClass } from 'app/shared/util/validation-level.util';
import React from 'react';
import { DbMapping } from './db-mapping';
import { DateValueChange, StringValueChange } from './value-change';

type StudentInfoProps = {
  student: IStudentImport;
  advisor: IAdvisorImport;
  noRights: boolean;
};

export const StudentInfo = ({ student, advisor, noRights }: StudentInfoProps) => {
  return (
    <>
      <Typography variant="h6">
        <StringValueChange value={student.firstName} valueChange={student.firstNameChange} />{' '}
        <StringValueChange value={student.familyName} valueChange={student.familyNameChange} />{' '}
        <DbMapping dbMapping={student.dbMapping} entity="Student" showIconOnly></DbMapping>
      </Typography>
      <Typography>
        <strong>Birth Date</strong>:{' '}
        <DateValueChange value={student.birthDate} valueChange={noRights ? null : student.birthDateChange} /> <br />
        <strong>Matriculation Number</strong>:{' '}
        <StringValueChange
          value={student.matriculationNumber}
          valueChange={noRights ? null : student.matriculationNumberChange}
        />
        <br />
        <strong>UAS Email</strong>:{' '}
        <StringValueChange
          value={student.uasEmail?.name ?? '-'}
          valueChange={noRights ? null : student.uasEmail?.nameChange}
        />
        <DbMapping dbMapping={noRights ? null : student.uasEmail?.dbMapping} entity="Uas Email" showIconOnly />
        <br />
        <strong>Private Email</strong>:{' '}
        <StringValueChange value={student.privateEmail?.name ?? '-'} valueChange={student.privateEmail?.nameChange} />
        <DbMapping dbMapping={noRights ? null : student.privateEmail?.dbMapping} entity="Private Email" showIconOnly />
        <br />
        <strong>Advisor</strong>:{' '}
        {advisor && (
          <>
            {advisor.firstName} {advisor.familyName}{' '}
            <DbMapping
              dbMapping={noRights ? null : student.advisorMapping}
              entity="Advisor relation"
              showIconOnChangesOnly
              showIconOnly
            />
          </>
        )}
        <br />
        <strong>Profile</strong>:{' '}
        <DbMapping dbMapping={student.profileMapping} entity="Profile" showIconOnChangesOnly />
        <br />
        <strong>Previous Degree</strong>:{' '}
        <StringValueChange value={student.previousDegree} valueChange={student.previousDegreeChange} />
        <br />
        {!noRights && (
          <>
            <strong>Semester Registration</strong>{' '}
            {student.semesterRegistrationId ? (
              <Tooltip
                title={
                  <>
                    <Typography>The student is already registered in this semester.</Typography>
                  </>
                }
              >
                <span className={getLevelClass(ValidationLevel.Success)} style={{ paddingLeft: 5 }}>
                  <DoneOutlineIcon fontSize="small" />
                </span>
              </Tooltip>
            ) : (
              <Tooltip
                title={
                  <>
                    <Typography>The student is not registered in this semester yet.</Typography>
                  </>
                }
              >
                <span className={getLevelClass(ValidationLevel.Success)} style={{ paddingLeft: 5 }}>
                  <FiberNewIcon fontSize="small" />
                </span>
              </Tooltip>
            )}
            <br />
          </>
        )}
      </Typography>
    </>
  );
};
