export default {
  SET_LOADING: 'registrationExport/SET_LOADING',
  SET_STUDENT_SEARCH_LOADING: 'registrationExport/SET_STUDENT_SEARCH_LOADING',
  SET_ADVISOR_SEARCH_LOADING: 'registrationExport/SET_ADVISOR_SEARCH_LOADING',
  SET_PRESETS_LOADING: 'registrationExport/SET_PRESETS_LOADING',
  SET_PRESETS: 'registrationExport/SET_PRESETS',
  SET_PRESET: 'registrationExport/SET_PRESET',
  SET_STATIC_FILTERS_LOADING: 'registrationExport/SET_STATIC_FILTERS_LOADING',
  SET_MODULE_FILTERS_LOADING: 'registrationExport/SET_MODULE_FILTERS_LOADING',
  SET_FILEIMPORT_FILTERS_LOADING: 'registrationExport/SET_FILEIMPORT_FILTERS_LOADING',
  SET_STATIC_FILTERS: 'registrationExport/SET_STATIC_FILTERS',
  SET_MODULE_FILTERS: 'registrationExport/SET_MODULE_FILTERS',
  SET_FILEIMPORT_FILTERS: 'registrationExport/SET_FILEIMPORT_FILTERS',
  SET_FILTER_SELECTION: 'registrationExport/SET_FILTER_SELECTION',
  ADD_UAS_FILTER: 'registrationExport/ADD_UAS_FILTER',
  REMOVE_UAS_FILTER: 'registrationExport/REMOVE_UAS_FILTER',
  ADD_SEMESTER_FILTER: 'registrationExport/ADD_SEMESTER_FILTER',
  REMOVE_SEMESTER_FILTER: 'registrationExport/REMOVE_SEMESTER_FILTER',
  ADD_PHASE_FILTER: 'registrationExport/ADD_PHASE_FILTER',
  ADD_LABEL_FILTER: 'registrationExport/ADD_LABEL_FILTER',
  REMOVE_LABEL_FILTER: 'registrationExport/REMOVE_LABEL_FILTER',
  ADD_PROFILE_FILTER: 'registrationExport/ADD_PROFILE_FILTER',
  REMOVE_PROFILE_FILTER: 'registrationExport/REMOVE_PROFILE_FILTER',
  ADD_LOCATION_FILTER: 'registrationExport/ADD_LOCATION_FILTER',
  REMOVE_LOCATION_FILTER: 'registrationExport/REMOVE_LOCATION_FILTER',
  ADD_STATE_FILTER: 'registrationExport/ADD_STATE_FILTER',
  REMOVE_STATE_FILTER: 'registrationExport/REMOVE_STATE_FILTER',
  ADD_COLUMN: 'registrationExport/ADD_COLUMN',
  REMOVE_COLUMN: 'registrationExport/REMOVE_COLUMN',
  ADD_STUDENT_TYPE_FILTER: 'registrationExport/ADD_STUDENT_TYPE_FILTER',
  REMOVE_STUDENT_TYPE_FILTER: 'registrationExport/REMOVE_STUDENT_TYPE_FILTER',
  SET_STUDENT: 'registrationExport/SET_STUDENT',
  SET_ADVISOR: 'registrationExport/SET_ADVISOR',
  SET_MODULE: 'registrationExport/SET_MODULE',
  SET_MODULE_EXECUTION_CODE: 'registrationExport/SET_MODULE_EXECUTION_CODE',
  SET_MODULE_CATEGORY: 'registrationExport/SET_MODULE_CATEGORY',
  SET_FILEIMPORT: 'registrationExport/SET_FILEIMPORT',
  SET_STATE_AT: 'registrationExport/SET_STATE_AT',
  SET_NEWER_THEN: 'registrationExport/SET_NEWER_THEN',
  SET_HIDE_REGISTRATIONS: 'registrationExport/SET_HIDE_REGISTRATIONS',
  SET_SHOW_DEREGISTRATIONS: 'registrationExport/SET_SHOW_DEREGISTRATIONS',
  SET_HAS_COMMENT: 'registrationExport/SET_HAS_COMMENT',
  SET_HAS_INTERNAL_COMMENT: 'registrationExport/SET_HAS_INTERNAL_COMMENT',
  SET_HAS_COMPENSATION_FOR_DISADVANTAGES: 'registrationExport/SET_HAS_COMPENSATION_FOR_DISADVANTAGES',
  SET_HAS_NO_UAS_EMAIL: 'registrationExport/SET_HAS_NO_UAS_EMAIL',
  SET_PREVIEW: 'registrationExport/SET_PREVIEW',
  SET_PREVIEW_LOADING: 'registrationExport/SET_PREVIEW_LOADING',
  SET_STUDENT_SEARCH_RESULT: 'registrationExport/SET_STUDENT_SEARCH_RESULT',
  SET_ADVISOR_SEARCH_RESULT: 'registrationExport/SET_ADVISOR_SEARCH_RESULT',
  CHANGE_STUDENT_SEARCH_STRING: 'registrationExport/CHANGE_STUDENT_SEARCH_STRING',
  CHANGE_ADVISOR_SEARCH_STRING: 'registrationExport/CHANGE_ADVISOR_SEARCH_STRING',
  RESET: 'registrationExport/RESET',
  ADD_REGISTRATION_COMMENT: 'registrationExport/ADD_REGISTRATION_COMMENT',
  REMOVE_REGISTRATION_COMMENT: 'registrationExport/REMOVE_REGISTRATION_COMMENT',
};
