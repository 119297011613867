import initActions from './actions';
import types from './types';
import reducer from './reducer';

import { IAccountClient, IEmailClient } from 'app/clients/services';
import { resolver } from 'app/clients/service-registry';

export type { UserManagementState } from './reducer';
export default {
  initActions: (dispatch: any) =>
    initActions(dispatch, resolver.resolve<IAccountClient>('IAccountClient'), resolver.resolve<IEmailClient>('IEmailClient')),
  types,
  reducer,
};
