import {
  CommentDto,
  ICommentDto,
  IModuleRegistrationDto,
  IModuleRegistrationInfoDto,
  ModuleRegistrationDto,
  ModuleRegistrationInfoDto,
} from 'app/clients/services';

export const addCommentToRegistrationInfo = (registrations: IModuleRegistrationInfoDto[], comment: ICommentDto) => {
  return registrations.map(r =>
    r.id === comment.registrationId
      ? new ModuleRegistrationInfoDto({
          ...r,
          internalComment: comment.isInternal ? comment.comment : r.internalComment,
          comment: !comment.isInternal ? comment.comment : r.comment,
          comments: [...r.comments ?? [], new CommentDto(comment)],
        })
      : new ModuleRegistrationInfoDto(r)
  );
};

export const addCommentToRegistration = (registrations: IModuleRegistrationDto[], comment: ICommentDto) => {
  return registrations.map(r =>
    r.id === comment.registrationId
      ? new ModuleRegistrationDto({
          ...r,
          internalComment: comment.isInternal ? comment.comment : r.internalComment,
          comment: !comment.isInternal ? comment.comment : r.comment,
          comments: [...r.comments ?? [], new CommentDto(comment)],
        })
      : new ModuleRegistrationDto(r)
  );
};

export const removeCommentFromRegistrationInfo = (registrations: IModuleRegistrationInfoDto[], comment: ICommentDto) => {
  return registrations.map(r =>
    r.id === comment.registrationId
      ? new ModuleRegistrationInfoDto({
          ...r,
          comments: r.comments?.filter(c => c.id !== comment.id)
            .map(c => (c.answerToId === comment.id ? new CommentDto({ ...c, answerToId: undefined }) : c)) ?? [],
        })
      : new ModuleRegistrationInfoDto(r)
  );
};

export const removeCommentFromRegistration = (registrations: IModuleRegistrationDto[], comment: ICommentDto) => {
  return registrations.map(r =>
    r.id === comment.registrationId
      ? new ModuleRegistrationDto({
          ...r,
          comments: r.comments?.filter(c => c.id !== comment.id)
            .map(c => (c.answerToId === comment.id ? new CommentDto({ ...c, answerToId: undefined }) : c)) ?? [],
        })
      : new ModuleRegistrationDto(r)
  );
};
