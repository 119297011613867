import { Box, Chip, Typography } from '@mui/material';
import React from 'react';

type Props = {
  items: any[];
  title?: string;
  createUrl?: (item: any) => string;
  getItemTitle: (item: any) => string;
  getColor?: (item: any) => 'primary' | 'secondary' | 'default';
  onClick?: (item: any) => void | Promise<void>;
  onDelete?: (item: any) => void | Promise<void>;
};

export const ChipList = (props: Props) => {
  return (
    <>
      {props.title && <Typography variant="h3">{props.title}:</Typography>}
      <Box
        component="ul"
        sx={{
          display: 'flex',
          justifyContent: 'left',
          flexWrap: 'wrap',
          listStyle: 'none',
          padding: 1,
          margin: 1,
        }}
      >
        {props.items.map((item, k) => (
          <Box component="li" key={k}>
            <Chip
              label={props.getItemTitle(item)}
              sx={{ marginLeft: 1, marginTop: 1 }}
              component={props.createUrl ? 'a' : 'span'}
              href={props.createUrl ? props.createUrl(item) : ''}
              color={props.getColor ? props.getColor(item) : 'default'}
              onClick={props.onClick ? () => props.onClick(item) : undefined}
              onDelete={props.onDelete ? () => props.onDelete(item) : undefined}
            />
          </Box>
        ))}
      </Box>
    </>
  );
};
