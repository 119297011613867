import { GridColDef, GridColumnVisibilityModel, GridValueFormatterParams } from '@mui/x-data-grid';
import { IModuleRegistrationDto, IModuleRegistrationInfoDto, StudentType } from 'app/clients/services';
import { dateFormatter, dateSorter } from 'app/shared/layout/ui-elements/standard-data-grid';

export type ModuleRegistrationInfoProperties = Extract<keyof IModuleRegistrationInfoDto, string>;
export type ModuleRegistrationProperties = Extract<keyof IModuleRegistrationDto, string>;

type ModuleRegistrationGridColDef = {
  field: ModuleRegistrationInfoProperties;
} & GridColDef;

export type RegistrationColumnFilter = {
  isAdmin?: boolean;
  sortable?: boolean;
  removeColumns?: ModuleRegistrationInfoProperties[];
  unhideColumns?: ModuleRegistrationInfoProperties[];
  takeColumns?: ModuleRegistrationInfoProperties[];
  unhideAll?: boolean;
  hideColumns?: ModuleRegistrationInfoProperties[];
  hasPhaseFilter?: boolean;
};

const allColumns: ModuleRegistrationGridColDef[] = [
  {
    field: 'dateOfRegistration',
    headerName: 'Date of Registration',
    width: 140,
    sortComparator: dateSorter,
    valueFormatter: dateFormatter,
    sortable: true,
  },
  {
    field: 'priority',
    headerName: 'Priority',
    width: 140,
    sortable: true,
  },
  { field: 'priorityWeighting', headerName: 'Prority Weighting', width: 120, sortable: true },
  {
    field: 'state',
    headerName: 'State',
    width: 140,
    sortable: true,
  },
  {
    field: 'isDeregistration',
    headerName: 'Is Deregistration',
    width: 120,
    type: 'boolean',
    sortable: true,
  },
  { field: 'isRegistration', headerName: 'Is Registration', width: 120, type: 'boolean', sortable: true },
  { field: 'uas', headerName: 'UAS', width: 120, sortable: true },
  { field: 'semester', headerName: 'Semester', width: 120, sortable: true },
  { field: 'academicYear', headerName: 'AcademicYear', width: 120, sortable: true },
  { field: 'studentFamilyName', headerName: 'Family Name', width: 180, sortable: true },
  { field: 'studentFirstName', headerName: 'First Name', width: 180, sortable: true },
  { field: 'matriculationNumber', headerName: 'MatriculationNumber', width: 120, sortable: true },
  {
    field: 'studentType',
    headerName: 'StudentType',
    width: 120,
    sortable: true,
    valueFormatter: (params: GridValueFormatterParams) =>
      // eslint-disable-next-line @typescript-eslint/no-base-to-string
      (params.value as StudentType) === StudentType.Student ? '' : params.value.toString(),
  },
  {
    field: 'birthDate',
    headerName: 'Birth Date',
    width: 140,
    sortComparator: dateSorter,
    valueFormatter: dateFormatter,
    sortable: true,
  },
  { field: 'studentPrivateEmail', headerName: 'Private Email', width: 180, sortable: true },
  { field: 'studentUASEmail', headerName: 'UAS Email', width: 180, sortable: true },

  { field: 'moduleExecution', headerName: 'Module Execution', width: 180, sortable: true },
  { field: 'module', headerName: 'Module', width: 180, sortable: true },
  { field: 'moduleCategory', headerName: 'Module Category', width: 120, sortable: true },
  {
    field: 'profile',
    headerName: 'Profile',
    width: 120,
    sortable: true,
  },
  { field: 'degree', headerName: 'Degree', width: 120, sortable: true },
  { field: 'advisorFamilyName', headerName: 'Advisor Family Name', width: 120, sortable: true },
  { field: 'advisorFirstName', headerName: 'Advisor First Name', width: 120, sortable: true },
  { field: 'advisorEmail', headerName: 'Advisor Email', width: 120, sortable: true },

  {
    field: 'compensationForDisadvantages',
    headerName: 'Compensation',
    width: 120,
    type: 'boolean',
    sortable: true,
  },
  { field: 'label', headerName: 'Label', width: 120, sortable: true },
  {
    field: 'hasSpecialLabel',
    headerName: 'Has Special Label',
    width: 120,
    type: 'boolean',
    sortable: true,
  },
  { field: 'location', headerName: 'Location', width: 120, sortable: true },
  {
    field: 'createdDate',
    headerName: 'Created',
    width: 120,
    sortComparator: dateSorter,
    valueFormatter: dateFormatter,
    sortable: true,
  },
  {
    field: 'lastModifiedDate',
    headerName: 'Last Modified',
    width: 120,
    sortComparator: dateSorter,
    valueFormatter: dateFormatter,
    sortable: true,
  },
  { field: 'uploadedBy', headerName: 'Uploaded By', width: 120, sortable: true },
  {
    field: 'uploadedDate',
    headerName: 'Uploaded',
    width: 120,
    sortComparator: dateSorter,
    valueFormatter: dateFormatter,
    sortable: true,
  },
  { field: 'importedBy', headerName: 'Imported By', width: 120, sortable: true },
  {
    field: 'importedDate',
    headerName: 'Imported',
    width: 120,
    sortComparator: dateSorter,
    valueFormatter: dateFormatter,
    sortable: true,
  },
  {
    field: 'requestedDate',
    headerName: 'Requested',
    width: 120,
    sortComparator: dateSorter,
    valueFormatter: dateFormatter,
    sortable: true,
  },
  {
    field: 'requestedInPhase',
    headerName: 'Requested in Phase',
    width: 120,
    sortable: true,
  },
  {
    field: 'confirmedDate',
    headerName: 'Confirmed',
    width: 120,
    sortComparator: dateSorter,
    valueFormatter: dateFormatter,
    sortable: true,
  },
  {
    field: 'confirmedInPhase',
    headerName: 'Confirmed in Phase',
    width: 120,
    sortable: true,
  },
  {
    field: 'lastStateChangeDate',
    headerName: 'Last State Change',
    width: 120,
    sortComparator: dateSorter,
    valueFormatter: dateFormatter,
    sortable: true,
  },
  {
    field: 'lastStateChangeInPhase',
    headerName: 'Last Changed in Phase',
    width: 120,
    sortable: true,
  },
  {
    field: 'comment',
    headerName: 'Comment',
    width: 220,
    sortable: true,
  },
  {
    field: 'internalComment',
    headerName: 'Internal Comment',
    width: 220,
    sortable: true,
  },
  {
    field: 'lastStateUpdateComment',
    headerName: 'State Update Comment',
    width: 420,
    sortable: true,
  },
  {
    field: 'fileImport',
    headerName: 'File Import',
    width: 220,
    sortable: true,
  },
  {
    field: 'changeType',
    headerName: 'Change Type',
    width: 120,
    sortable: true,
  },
];

const columnVisibilityModel: GridColumnVisibilityModel = {
  priorityWeighting: true,
  isDeregistration: true,
  isRegistration: true,
  semester: true,
  academicYear: true,
  studentType: true,
  module: true,
  moduleCategory: true,
  label: true,
  hasSpecialLabel: true,
  location: true,
  createdDate: true,
  lastModifiedDate: true,
  uploadedBy: true,
  uploadedDate: true,
  importedBy: true,
  importedDate: true,
  requestedDate: true,
  confirmedDate: true,
  lastStateChangeDate: true,
  lastStateChangeInPhase: true,
  changeType: true,
};

const moduleRegistrationKeys: ModuleRegistrationProperties[] = [
  'dateOfRegistration',

  'studentFamilyName',
  'studentFirstName',

  'matriculationNumber',

  'moduleCategory',
  'module',
  'moduleExecution',

  'state',

  'label',

  'compensationForDisadvantages',
  'comment',
  'internalComment',

  'changeType',
  'fileImport',
  'lastStateUpdateComment',
];

const moduleRegistrationInfoKeys: ModuleRegistrationInfoProperties[] = [
  'dateOfRegistration',

  'priority',
  'priorityValue',
  'priorityWeighting',

  'state',

  'semester',
  'academicYear',

  'uas',

  'studentFamilyName',
  'studentFirstName',

  'matriculationNumber',
  'studentType',
  'birthDate',
  'studentPrivateEmail',
  'studentUASEmail',

  'moduleExecution',
  'module',
  'moduleCategory',

  'profile',

  'degree',

  'advisorFamilyName',
  'advisorFirstName',
  'advisorEmail',

  'label',
  'hasSpecialLabel',
  'compensationForDisadvantages',

  'location',

  'comment',
  'internalComment',

  'createdDate',
  'lastModifiedDate',

  'lastStateChangeDate',
  'lastStateChangeInPhase',
  'lastStateUpdateComment',

  'requestedDate',
  'requestedInPhase',

  'confirmedDate',
  'confirmedInPhase',

  'uploadedDate',
  'uploadedBy',

  'importedDate',
  'importedBy',

  'fileImport',
  'changeType',
];

const filterColumns = (filter: RegistrationColumnFilter) => {
  const visibilityModel = { ...columnVisibilityModel };

  let cols =
    filter.takeColumns
      ?.map((c) => allColumns.find((col) => col.field === c))
      .filter((c) => !!c && !filter.removeColumns?.includes(c.field)) ?? [];

  if (!filter.isAdmin) {
    const adminOnlyFileds: ModuleRegistrationInfoProperties[] = [
      'createdDate',
      'lastModifiedDate',
      'lastStateChangeDate',
      'lastStateChangeInPhase',
      'uploadedBy',
      'importedBy',
      'internalComment',
      'priorityColor',
      'priorityValue',
      'confirmedInPhase',
    ];

    cols = cols.filter((c) => !adminOnlyFileds.includes(c.field as ModuleRegistrationInfoProperties));
  }

  for (const column of cols) {
    if (!filter.sortable) {
      column.sortable = false;
    }

    if (filter.unhideAll) {
      visibilityModel[column.field] = true;
    } else {
      if (filter.unhideColumns?.includes(column.field as ModuleRegistrationInfoProperties)) {
        visibilityModel[column.field] = true;
      } else if (filter.hideColumns?.includes(column.field as ModuleRegistrationInfoProperties)) {
        visibilityModel[column.field] = false;
      } else {
        visibilityModel[column.field] = true;
      }
    }
  }

  return { columns: cols.map((c) => c as GridColDef), visibilityModel };
};

export const getModuleRegistrationInfoCols = (filter: RegistrationColumnFilter) => {
  filter.takeColumns = filter.takeColumns?.length ? filter.takeColumns : moduleRegistrationInfoKeys;

  return filterColumns(filter);
};

export const getModuleRegistrationCols = (filter: RegistrationColumnFilter) => {
  filter.takeColumns = filter.takeColumns?.length ? filter.takeColumns : moduleRegistrationKeys;
  return filterColumns(filter);
};
