import { AuthState } from 'app/modules/login/auth';

export const getUas = (state: AuthState, id?: number) => {
  if (!state.allowedUas) {
    return null;
  }

  if (!id) {
    return state.allowedUas[0];
  }

  return state.allowedUas.find(u => u.id === id);
};
