import initActions from './actions';
import types from './types';
import reducer from './reducer';
import {
  IAdvisorClient,
  IRegistrationEditClient,
  IRegistrationExportClient,
  IRegistrationFilterPresetClient,
  IStudentClient,
} from 'app/clients/services';
import { resolver } from 'app/clients/service-registry';

export type { ModuleRegistrationExportState } from './reducer';
export default {
  initActions: dispatch =>
    initActions(
      dispatch,
      resolver.resolve<IRegistrationExportClient>('IRegistrationExportClient'),
      resolver.resolve<IRegistrationFilterPresetClient>('IRegistrationFilterPresetClient'),
      resolver.resolve<IRegistrationEditClient>('IRegistrationEditClient'),
      resolver.resolve<IStudentClient>('IStudentClient'),
      resolver.resolve<IAdvisorClient>('IAdvisorClient')
    ),
  types,
  reducer,
};
