import { FileResponse, IPriorityClient, IPriorityDto, PriorityDto } from 'app/clients/services';
import { AsyncThunk } from 'app/shared/util/action-type.util';
import ACTION_TYPES from './types';

const initActions = (priorityClient: IPriorityClient) => {
  // Actions
  const selectPriority = (priorityId) => ({
    type: ACTION_TYPES.SELECT_PRIORITY,
    payload: priorityId,
  });

  const memorizePriorityChange = (field: string, value: any, priorityDto: PriorityDto) => {
    const changedPriority = {
      ...priorityDto,
    };
    changedPriority[field] = value;
    return {
      type: ACTION_TYPES.MEMORIZE_CHANGES,
      payload: changedPriority,
    };
  };

  const createNewPriority = () => {
    const priority = new PriorityDto({
      id: 0,
      isActive: true,
      name: '',
      weighting: 5,
      colorCode: 'rgba(255, 255, 255, 1)',
      canBeDeleted: true,
    });
    return {
      type: ACTION_TYPES.CREATE_NEW_PRIORITY,
      payload: priority,
    };
  };

  // Thunks
  const fetchPrioritiesAsync: () => AsyncThunk<IPriorityDto[]> = () => async (dispatch) => {
    const result = await dispatch({
      type: ACTION_TYPES.FETCH_PRIORITIES,
      payload: priorityClient.list(),
    });

    return result.value;
  };

  const updatePriorityAsync: (priorityDto: PriorityDto) => AsyncThunk<FileResponse | void> =
    (priorityDto) => async (dispatch) => {
      const result = await dispatch({
        type: ACTION_TYPES.UPDATE_PRIORITY,
        payload: priorityClient.update(priorityDto),
      });
      return result.value;
    };

  const deletePriorityAsync: (id: number) => AsyncThunk<FileResponse | void> = (id) => async (dispatch) => {
    const result = await dispatch({
      type: ACTION_TYPES.DELETE_PRIORITY,
      payload: priorityClient.delete(id),
    });
    await dispatch(fetchPrioritiesAsync());
    return result.value;
  };

  const addPriorityAsync: (priorityDto: PriorityDto) => AsyncThunk<PriorityDto> = (priorityDto) => async (dispatch) => {
    const result = await dispatch({
      type: ACTION_TYPES.ADD_PRIORITY,
      payload: priorityClient.add(priorityDto),
    });
    dispatch(selectPriority(result.value.id));
    return result.value;
  };

  return Object.freeze({
    selectPriority,
    memorizePriorityChange,
    createNewPriority,
    fetchPrioritiesAsync,
    updatePriorityAsync,
    deletePriorityAsync,
    addPriorityAsync,
  });
};

export default initActions;

export type PriorityManagementActions = ReturnType<typeof initActions>;
