import { IResolver, ServiceIdentifier } from 'app/clients/generic-service-registry';
import { IServiceInterfaces } from 'app/clients/service-registry';
import React, { useContext } from 'react';

const DependencyContext = React.createContext<{ resolver: IResolver<IServiceInterfaces> | null }>({ resolver: null });

type Props = {
  resolver: IResolver<IServiceInterfaces>;
  children: React.ReactNode;
};

export const ServiceProvider: React.FC<Props> = props => {
  return <DependencyContext.Provider value={{ resolver: props.resolver }}>{props.children}</DependencyContext.Provider>;
};

export function useService<T>(identifier: ServiceIdentifier<IServiceInterfaces>) {
  const { resolver } = useContext(DependencyContext);
  if (!resolver) {
    throw new Error('Tried to resolve a service but the context was not opened!');
  }
  return resolver.resolve<T>(identifier);
}
