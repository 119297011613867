import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import SaveIcon from '@mui/icons-material/Save';
import { Alert, Box, Button, Grid, Paper, Typography } from '@mui/material';
import { EmailDto, IAccountInfoDto, IEmailDto } from 'app/clients/services';
import { Field, FieldArray, Form, Formik } from 'formik';
import { CheckboxWithLabel, TextField as FormikTextField } from 'formik-mui';
import React from 'react';
import * as Yup from 'yup';

type AccountFormProps = {
  loading: boolean;
  account: IAccountInfoDto;
  actions: {
    deleteEmailAsync: (email: IEmailDto) => Promise<void>;
  };
  onSubmit: (account: IAccountInfoDto) => Promise<void>;
  onCancel: () => Promise<void>;
  onDelete: () => Promise<void>;
  validateEmailAsync: (email: string) => Promise<boolean>;
  hasLogin: boolean;
};

export const AccountForm = (props: AccountFormProps) => {
  const updateDefaultEmails = (
    a: IAccountInfoDto,
    isDefaultEmail: string,
    key: number,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  ) => {
    if (isDefaultEmail === 'false') {
      for (let i = 0; i < a.emails.length; i++) {
        setFieldValue(`emails.${i}.isDefault`, key === i);
      }
    }
  };

  const updateEduIdEmails = (
    a: IAccountInfoDto,
    isEduIdEmail: string,
    key: number,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  ) => {
    if (isEduIdEmail === 'false') {
      for (let i = 0; i < a.emails.length; i++) {
        setFieldValue(`emails.${i}.isEduIdEmail`, key === i);
        if (key === i) {
          setFieldValue(`emails.${i}.isActive`, true);
        }
      }
    } else {
      setFieldValue(`emails.${key}.isEduIdEmail`, false);
    }
  };

  return (
    <Paper sx={{ padding: 2 }}>
      <Formik
        enableReinitialize
        initialValues={props.account}
        validationSchema={Yup.object({
          firstName: Yup.string().min(2).required('required'),
          familyName: Yup.string().min(2).required('required'),
          emails: Yup.array()
            .of(
              Yup.object({
                name: Yup.string()
                  .required('Email is required')
                  .email('Email is not vaild.')
                  .test('email-backend-validation', 'Email is already taken by another user!', async (name) => {
                    if (name && (await Yup.string().email().isValid(name))) {
                      return await props.validateEmailAsync(name);
                    }
                    return true;
                  }),
              }),
            )
            .test('unique', 'Duplicate email!', (emails) => {
              if (!emails) {
                return true;
              }
              return emails?.length === new Set(emails?.map((e) => e.name) ?? []).size;
            }),
        })}
        onSubmit={(values, { setSubmitting }) => {
          props.onSubmit(values).then(() => setSubmitting(false));
        }}
        onReset={(values, { resetForm }) => resetForm()}
      >
        {({ values, submitForm, isSubmitting, setFieldValue, errors }) => (
          <Form>
            <Grid container spacing={1}>
              <Grid item xs={12} md={3}>
                <Field
                  variant="standard"
                  component={FormikTextField}
                  name="firstName"
                  type="text"
                  label="First Name"
                  style={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Field
                  variant="standard"
                  component={FormikTextField}
                  name="familyName"
                  type="text"
                  label="Family Name"
                  style={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Field
                  onClick={submitForm}
                  component={CheckboxWithLabel}
                  type="checkbox"
                  name={`activated`}
                  Label={{ label: 'Is Active' }}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="h3">Emails:</Typography>
                    <FieldArray
                      name="emails"
                      render={(arrayHelpers) => (
                        <Box>
                          {values.emails.map((e, k) => (
                            <Grid container key={'email-' + k} component={Paper} sx={{ padding: 1, margin: 1 }}>
                              <Grid item xs={12} md={4}>
                                <Field
                                  variant="standard"
                                  component={FormikTextField}
                                  name={`emails.${k}.name`}
                                  type="text"
                                  label="Email"
                                  style={{ width: '100%' }}
                                />
                              </Grid>
                              <Grid item xs={6} md={2}>
                                <Field
                                  component={CheckboxWithLabel}
                                  type="checkbox"
                                  name={`emails.${k}.isActive`}
                                  Label={{ label: 'Is Active' }}
                                  disabled={e.isEduIdEmail}
                                />
                                <Field
                                  component={CheckboxWithLabel}
                                  type="checkbox"
                                  name={`emails.${k}.isDefault`}
                                  Label={{ label: 'Is Default' }}
                                  onChange={(event) =>
                                    updateDefaultEmails(values, event.target.value, k, setFieldValue)
                                  }
                                />
                              </Grid>
                              <Grid item xs={6} md={2}>
                                <Field
                                  component={CheckboxWithLabel}
                                  type="checkbox"
                                  name={`emails.${k}.isPrivate`}
                                  Label={{ label: 'Is Private' }}
                                  disabled
                                />
                                <Field
                                  component={CheckboxWithLabel}
                                  type="checkbox"
                                  name={`emails.${k}.isEduIdEmail`}
                                  Label={{ label: 'Is Login Email' }}
                                  onChange={(event) => updateEduIdEmails(values, event.target.value, k, setFieldValue)}
                                />
                              </Grid>
                              <Grid item xs={12} md={2}>
                                {!e.id ? (
                                  <Button
                                    variant="contained"
                                    color="inherit"
                                    disabled={isSubmitting || props.loading}
                                    onClick={() => arrayHelpers.remove(k)}
                                    startIcon={<DeleteIcon />}
                                  >
                                    Cancel
                                  </Button>
                                ) : (
                                  <Button
                                    variant="contained"
                                    color="inherit"
                                    disabled={e.isEduIdEmail || isSubmitting || props.loading}
                                    onClick={() => props.actions.deleteEmailAsync(e)}
                                    startIcon={<DeleteIcon />}
                                  >
                                    Delete
                                  </Button>
                                )}
                              </Grid>
                            </Grid>
                          ))}
                          {errors['emails'] && typeof errors['emails'] === 'string' && (
                            <>
                              <Alert severity="error" style={{ marginTop: 10 }}>
                                {errors['emails']}
                              </Alert>
                            </>
                          )}
                          <Button
                            variant="contained"
                            disabled={props.loading}
                            startIcon={<AddIcon />}
                            style={{ marginTop: 20 }}
                            color="inherit"
                            onClick={() =>
                              arrayHelpers.push(
                                new EmailDto({
                                  id: 0,
                                  userId: props.account.id,
                                  name: '',
                                  isActive: true,
                                  isPrivate: false,
                                  isDefault: false,
                                  isEduIdEmail: false,
                                } as IEmailDto),
                              )
                            }
                          >
                            Add Email
                          </Button>
                        </Box>
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting || props.loading}
                  onClick={submitForm}
                  startIcon={<SaveIcon />}
                >
                  Save
                </Button>
                {props.account.roles?.length === 0 && props.account.emails?.length === 0 && (
                  <Button
                    variant="contained"
                    color="inherit"
                    type="submit"
                    disabled={
                      isSubmitting ||
                      props.loading ||
                      props.account.roles?.length !== 0 ||
                      props.account.emails?.length !== 0
                    }
                    onClick={() => props.onDelete()}
                    startIcon={<SaveIcon />}
                  >
                    Delete
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="inherit"
                  type="submit"
                  disabled={isSubmitting || props.loading}
                  onClick={() => props.onCancel()}
                  startIcon={<CancelIcon />}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Paper>
  );
};
