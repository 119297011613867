import React, { useEffect } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { Grid, LinearProgress, Paper, Typography } from '@mui/material';
import { IAcademicYearDto, ISemesterInfoDto, IUasDto, RegistrationState, RoleType, UasDto } from 'app/clients/services';
import { IRootState } from 'app/config/root.reducer';
import { hasAnyRole } from 'app/shared/auth/roles';
import { SemesterSelection } from 'app/shared/layout/semester-selection';
import { DefaultButton } from 'app/shared/layout/ui-elements/buttons';
import { MainContent, SideBar, SplitLayout } from 'app/shared/layout/ui-elements/component-layout';
import { SelectBox } from 'app/shared/layout/ui-elements/select-box';
import { combineUrlParams, getBasePath } from 'app/shared/util/url-utils';
import { CloseReason } from 'notistack';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ModuleRegistrations } from '../../shared/layout/parts/module-registrations';
import { addExceptionNotification } from '../notification';
import uasRegistrationModule from './uas-registrations/index';

export type Props = PropsFromRedux;

const UasRegistrations = (props: Props) => {
  const { sId, uasId } = useParams<{ sId: string; uasId: string }>();
  const navigate = useNavigate();
  const basePath = getBasePath(useLocation(), useParams());

  useEffect(() => {
    const loadInitialData = async () => {
      try {
        props.setMainContentLoading(true);
        if (sId && uasId) {
          const semester = props.semesterFilter.semesters?.find((s) => s.id === Number(sId));
          await props.getUasRegistrationsAsync(semester, Number(uasId));
        } else {
          const selectedUas = props.selectedUas ?? props.allowedUas[0];
          if (selectedUas) {
            await props.getUasRegistrationsAsync(props.semester, selectedUas.id);
            navigate(basePath + combineUrlParams({ sId: props.semester.id, uasId: selectedUas.id }));
          }
        }
        props.setMainContentLoading(false);
      } catch (err) {
        props.setMainContentLoading(false);
        props.addExceptionNotification(err);
      }
    };

    loadInitialData();
  }, []);

  const handleSemesterChange = async (_academicYear: IAcademicYearDto, semester: ISemesterInfoDto) => {
    try {
      if (props.semester?.id !== semester?.id) {
        props.setMainContentLoading(true);
        const selectedUas = props.selectedUas ?? (props.allowedUas?.length === 1 ? props.allowedUas[0] : null);
        await props.getUasRegistrationsAsync(semester, selectedUas?.id);
        navigate(basePath + combineUrlParams({ sId: semester.id, uasId: selectedUas.id }));
        props.setMainContentLoading(false);
      }
    } catch (err) {
      props.setMainContentLoading(false);
      props.addExceptionNotification(err);
    }
  };

  const handleUasChange = async (uas: IUasDto) => {
    try {
      props.setMainContentLoading(true);
      if (props.semester) {
        await props.getUasRegistrationsAsync(props.semester, uas?.id);
        navigate(basePath + combineUrlParams({ sId: props.semester.id, uasId: uas?.id }));
      }
      props.setMainContentLoading(false);
    } catch (err) {
      props.setMainContentLoading(false);
      props.addExceptionNotification(err);
    }
  };

  return (
    <SplitLayout>
      <SideBar>
        <SemesterSelection
          filter={props.semesterFilter}
          academicYear={props.academicYear}
          semester={props.semester}
          hidePhase
          onChange={handleSemesterChange}
        ></SemesterSelection>
        {props.allowedUas.length > 1 && (
          <SelectBox
            items={props.allowedUas ?? []}
            selectedItem={props.selectedUas}
            onChange={handleUasChange}
            title="University Of Applied Science"
            getItemKey={(uas: UasDto) => uas.id}
            getItemTitle={(uas: UasDto) => uas.code}
          />
        )}
      </SideBar>
      <MainContent loading={props.mainContentLoading}>
        {props.selectedUas && props.semester && (
          <>
            <Typography variant="h1">
              Registrations: {props.selectedUas?.code} ({props.semester?.name})
            </Typography>
            <Paper sx={{ padding: 1 }}>
              <DefaultButton onClick={() => props.exportAsync(props.selectedUas.id, props.semester.id)}>
                Excel Export
              </DefaultButton>
              {/* {props.isAdmin && props.registrations && props.registrations.length && (
                  <>
                    <DefaultButton
                      onClick={() => props.deleteRegistrationsForUasAsync(props.semester, props.selectedUas?.id)}
                      disabled={props.registrations.length === 0 || props.loading}
                      style={{ marginLeft: 20 }}
                    >
                      Delete Registrations ({props.registrations.length})
                    </DefaultButton>
                  </>
                )} */}
              {props.exportLoading && <LinearProgress></LinearProgress>}
            </Paper>
            <Grid container spacing={1}>
              <Grid item xs={12} style={{ marginTop: 10 }}>
                <Typography variant="h3">
                  <strong>Confirmed: </strong>
                  {props.registrations?.filter((r) => r.state === RegistrationState.CONFIRMED).length}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <ModuleRegistrations
                  isAdmin={props.isAdmin}
                  rows={props.registrations?.filter((r) => r.state === RegistrationState.CONFIRMED)}
                  loading={props.loading}
                  columnFilter={{
                    isAdmin: props.isAdmin,
                    removeColumns: ['state', 'uas', 'semester', 'academicYear'],
                    sortable: true,
                  }}
                  onAddComment={props.addCommentAsync}
                  onRemoveComment={props.removeCommentAsync}
                  currentUser={props.currentUser}
                  getHistory={props.getHistoryAsync}
                />
              </Grid>
              <Grid item xs={12} style={{ marginTop: 10 }}>
                <Typography variant="h3">
                  <strong>Requested: </strong>
                  {props.registrations?.filter((r) => r.state === RegistrationState.REQUESTED).length}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <ModuleRegistrations
                  isAdmin={props.isAdmin}
                  labels={props.labels}
                  rows={props.registrations?.filter((r) => r.state === RegistrationState.REQUESTED)}
                  moduleExecutions={[]}
                  loading={props.loading}
                  columnFilter={{
                    isAdmin: props.isAdmin,
                    removeColumns: ['state', 'uas', 'semester', 'academicYear'],
                    sortable: true,
                  }}
                  onAddComment={props.addCommentAsync}
                  onRemoveComment={props.removeCommentAsync}
                  currentUser={props.currentUser}
                  getHistory={props.getHistoryAsync}
                />
              </Grid>
              <Grid item xs={12} style={{ marginTop: 10 }}>
                <Typography variant="h3">
                  <strong>Rejected: </strong>
                  {props.registrations.filter((r) => r.state === RegistrationState.REJECTED).length}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <ModuleRegistrations
                  isAdmin={props.isAdmin}
                  labels={props.labels}
                  rows={props.registrations?.filter((r) => r.state === RegistrationState.REJECTED)}
                  moduleExecutions={[]}
                  loading={props.loading}
                  columnFilter={{
                    isAdmin: props.isAdmin,
                    removeColumns: ['state', 'uas', 'semester', 'academicYear'],
                    sortable: true,
                  }}
                  onAddComment={props.addCommentAsync}
                  onRemoveComment={props.removeCommentAsync}
                  currentUser={props.currentUser}
                  getHistory={props.getHistoryAsync}
                />
              </Grid>
              <Grid item xs={12} style={{ marginTop: 10 }}>
                <Typography variant="h3">
                  <strong>Withdrawn: </strong>
                  {props.registrations.filter((r) => r.state === RegistrationState.WITHDRAWN).length}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <ModuleRegistrations
                  isAdmin={props.isAdmin}
                  labels={props.labels}
                  rows={props.registrations?.filter((r) => r.state === RegistrationState.WITHDRAWN)}
                  moduleExecutions={[]}
                  loading={props.loading}
                  columnFilter={{
                    isAdmin: props.isAdmin,
                    removeColumns: ['state', 'uas', 'semester', 'academicYear'],
                    sortable: true,
                  }}
                  onAddComment={props.addCommentAsync}
                  onRemoveComment={props.removeCommentAsync}
                  currentUser={props.currentUser}
                  getHistory={props.getHistoryAsync}
                />
              </Grid>
              <Grid item xs={12} style={{ marginTop: 10 }}>
                <Typography variant="h3">
                  <strong>Absentex: </strong>
                  {props.registrations.filter((r) => r.state === RegistrationState.ABSENTEX).length}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <ModuleRegistrations
                  isAdmin={props.isAdmin}
                  labels={props.labels}
                  rows={props.registrations?.filter((r) => r.state === RegistrationState.ABSENTEX)}
                  moduleExecutions={[]}
                  loading={props.loading}
                  columnFilter={{
                    isAdmin: props.isAdmin,
                    removeColumns: ['state', 'uas', 'semester', 'academicYear'],
                    sortable: true,
                  }}
                  onAddComment={props.addCommentAsync}
                  onRemoveComment={props.removeCommentAsync}
                  currentUser={props.currentUser}
                  getHistory={props.getHistoryAsync}
                />
              </Grid>
              {props.registrations.filter(
                (r) =>
                  r.state !== RegistrationState.WITHDRAWN &&
                  r.state !== RegistrationState.REJECTED &&
                  r.state !== RegistrationState.REQUESTED &&
                  r.state !== RegistrationState.ABSENTEX &&
                  r.state !== RegistrationState.CONFIRMED,
              ).length > 0 && (
                <>
                  <Grid item xs={12} style={{ marginTop: 10 }}>
                    <Typography variant="h3">
                      <strong>Others: </strong>
                      {
                        props.registrations?.filter(
                          (r) =>
                            r.state !== RegistrationState.WITHDRAWN &&
                            r.state !== RegistrationState.REJECTED &&
                            r.state !== RegistrationState.REQUESTED &&
                            r.state !== RegistrationState.ABSENTEX &&
                            r.state !== RegistrationState.CONFIRMED,
                        ).length
                      }
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <ModuleRegistrations
                      isAdmin={props.isAdmin}
                      labels={props.labels}
                      rows={props.registrations?.filter((r) => r.state === RegistrationState.REJECTED)}
                      moduleExecutions={[]}
                      loading={props.loading}
                      columnFilter={{
                        isAdmin: props.isAdmin,
                        removeColumns: ['state', 'uas', 'semester', 'academicYear'],
                        sortable: true,
                      }}
                      onAddComment={props.addCommentAsync}
                      onRemoveComment={props.removeCommentAsync}
                      currentUser={props.currentUser}
                      getHistory={props.getHistoryAsync}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </>
        )}
      </MainContent>
    </SplitLayout>
  );
};

function mapStateToProps({ semesterSelection, uasRegistration, auth }: IRootState) {
  return {
    mainContentLoading: uasRegistration.mainContentLoading,
    semesterFilter: semesterSelection.semesterFilter,
    isAdmin: hasAnyRole(auth.account?.roles, [RoleType.REGISTRATIONADMIN]),
    allowedUas: auth.allowedUas,
    selectedUas: auth.allowedUas.length === 1 ? auth.allowedUas[0] : uasRegistration.selectedUas,
    registrations: uasRegistration.registrations,
    loading: uasRegistration.loading,
    academicYear: semesterSelection.selectedAcademicYear,
    semester: semesterSelection.selectedSemester,
    exportLoading: uasRegistration.exportLoading,
    labels: [],
    currentUser: auth.account,
  };
}

const mapDispatchToProps = (dispatch) => {
  const uasActions = uasRegistrationModule.initActions(dispatch);
  return {
    ...uasActions,
    addExceptionNotification: (
      e: any,
      message?: string,
      onClose?: (event: React.SyntheticEvent<any, Event>, reason: CloseReason) => void,
    ) => dispatch(addExceptionNotification(e, message, onClose)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(UasRegistrations);
