import ACTION_TYPES from './types';
import {
  IAcademicYearDto,
  IModuleDto,
  IModuleExecutionDto,
  ModuleCategoryDto,
  IModuleCategoryDto,
  ModuleDto,
  IModuleManagementClient,
  ModuleExecutionDto,
  ICopyModuleStructureDto,
  CopyModuleStructureDto,
} from 'app/clients/services';
import { AsyncThunk, Thunk } from 'app/shared/util/action-type.util';
import { addExceptionNotification, addSuccess } from 'app/modules/notification';
import { ModuleSelectionActions } from 'app/modules/selections/module-selection/actions';

const initActions = (moduleManagementClient: IModuleManagementClient, moduleSelectionActions: ModuleSelectionActions) => {
  const setLoading = (loading: boolean) => ({
    type: ACTION_TYPES.SET_LOADING,
    payload: loading,
  });

  const setModuleLoading = (module?: IModuleDto) => ({
    type: ACTION_TYPES.SET_MODULE_LOADING,
    payload: module ? module.id : -1,
  });

  const setModuleExecutionLoading = (moduleExecution?: IModuleExecutionDto) => ({
    type: ACTION_TYPES.SET_MODULE_EXECUTION_LOADING,
    payload: moduleExecution ? moduleExecution.id : -1,
  });

  const addModuleCategory = () => {
    const moduleCategory = new ModuleCategoryDto({ code: '', name: '', isActive: true, id: 0 });
    return {
      type: ACTION_TYPES.ADD_MODULE_CATEGORY,
      payload: moduleCategory,
    };
  };

  const cancelAddingModuleCategory = () => {
    return {
      type: ACTION_TYPES.ADD_MODULE_CATEGORY,
      payload: null,
    };
  };

  const addModule = (moduleCategory: IModuleCategoryDto) => {
    const module = new ModuleDto({
      code: '',
      name: '',
      isActive: true,
      id: 0,
      categoryId: moduleCategory.id,
      partialCode: '',
      hasRelatedData: false,
      recommendedMaxParticipants: 120,
    });
    return {
      type: ACTION_TYPES.ADD_MODULE,
      payload: module,
    };
  };

  const cancelAddingModule = () => {
    return {
      type: ACTION_TYPES.ADD_MODULE,
      payload: null,
    };
  };

  const cancelAdding: () => Thunk = () => dispatch => {
    dispatch(cancelAddingModule());
    dispatch(cancelAddingModuleCategory());
  };

  const refreshFiltersAsync: () => AsyncThunk = () => async dispatch => {
    try {
      await dispatch(moduleSelectionActions.loadStructureAsync());
    } catch (err) {
      dispatch(addExceptionNotification(err, `Error while refreshing the module filter!`));
    }
  };

  const saveModuleCategoryAsync: (moduleCategory: IModuleCategoryDto) => AsyncThunk<IModuleCategoryDto> =
    moduleCategory => async dispatch => {
      try {
        dispatch(setLoading(true));
        const result = await moduleManagementClient.insertOrUpdateCategory(new ModuleCategoryDto(moduleCategory));
        await dispatch(refreshFiltersAsync());
        dispatch(moduleSelectionActions.selectModuleCategoryAsync(result));
        dispatch(setLoading(false));
        return result;
      } catch (err) {
        dispatch(addExceptionNotification(err, `Error while saving module category '${moduleCategory.code}'!`));
        dispatch(setLoading(false));
      }
      return null;
    };

  const copyAcademicYearAsync: (options: ICopyModuleStructureDto) => AsyncThunk = options => async (dispatch, getState) => {
    dispatch(setLoading(true));
    try {
      const result = await moduleManagementClient.copyAcademicYear(new CopyModuleStructureDto(options));
      await dispatch(refreshFiltersAsync());
      const academicYears = getState().semesterSelection.semesterFilter.academicYears;
      const from = academicYears.find(a => a.id === options.fromAcademicYearId);
      const to = academicYears.find(a => a.id === options.toAcademicYearId);
      dispatch(setLoading(false));
      dispatch(addSuccess(`Academic year was successfully copied from '${from.name}' to '${to.name}'.`));
      return result;
    } catch (err) {
      const academicYears = getState().semesterSelection.semesterFilter.academicYears;
      const from = academicYears.find(a => a.id === options.fromAcademicYearId);
      const to = academicYears.find(a => a.id === options.toAcademicYearId);
      dispatch(addExceptionNotification(err, `Error while copying academic year '${from.name}' to '${to.name}'!`));
      dispatch(setLoading(false));
    }
  };

  const deleteModuleCategoryAsync: (category: IModuleCategoryDto) => AsyncThunk = category => async dispatch => {
    dispatch(setLoading(true));
    try {
      await moduleManagementClient.deleteCategory(category.id);
      await dispatch(refreshFiltersAsync());
      dispatch(setLoading(false));
      dispatch(addSuccess(`Category '${category.code}' was successfully deleted.`));
    } catch (err) {
      dispatch(addExceptionNotification(err, `Error while deleting category '${category.code}'!`));
      dispatch(setLoading(false));
    }
  };

  const updateModuleAsync: (module: IModuleDto) => AsyncThunk<IModuleDto> = module => async dispatch => {
    dispatch(setModuleLoading(module));
    try {
      const result = await moduleManagementClient.updateModule(new ModuleDto(module));
      await dispatch(refreshFiltersAsync());
      dispatch(setModuleLoading());
      dispatch(addSuccess(`Module '${result.code}' was successfully updated.`));
      return result;
    } catch (err) {
      dispatch(setModuleLoading());
      dispatch(addExceptionNotification(err, `Error while updating module '${module.code}'!`));
    }
  };

  const insertModuleAsync: (module: IModuleDto) => AsyncThunk<IModuleDto> = module => async dispatch => {
    dispatch(setModuleLoading(module));
    try {
      const result = await moduleManagementClient.insertModule(new ModuleDto(module));
      await dispatch(refreshFiltersAsync());
      dispatch(setModuleLoading());
      dispatch(addSuccess(`Module '${result.code}' was successfully saved.`));
      dispatch(moduleSelectionActions.selectModuleAsync(result));
      return result;
    } catch (err) {
      dispatch(setModuleLoading());
      dispatch(addExceptionNotification(err, `Error while inserting module!`));
    }
  };

  const saveModuleExecutionsAsync: (executions: IModuleExecutionDto[], module: IModuleDto, academicYear: IAcademicYearDto) => AsyncThunk =
    (executions, module, academicYear) => async dispatch => {
      dispatch(setModuleLoading(module));
      try {
        const result = await moduleManagementClient.updateModuleExecutions(
          module.id,
          academicYear.id,
          executions.map(e => new ModuleExecutionDto(e))
        );
        dispatch(moduleSelectionActions.resetExecutions());
        await dispatch(refreshFiltersAsync());
        await dispatch(moduleSelectionActions.getExecutionsForAcademicYearAsync(academicYear.id));
        dispatch(setModuleLoading());
        dispatch(addSuccess(`Module executions of '${module.code}' were successfully saved.`));
        return result;
      } catch (err) {
        dispatch(setModuleLoading());
        dispatch(addExceptionNotification(err, `Error while saving module executions of '${module.code}'!`));
      }
    };

  const deleteModuleAsync: (module: IModuleDto) => AsyncThunk = module => async dispatch => {
    dispatch(setModuleLoading(module));
    try {
      await moduleManagementClient.deleteModule(module?.id);
      await dispatch(refreshFiltersAsync());
      dispatch(setModuleLoading());
      dispatch(addSuccess(`Module '${module.code}' was successfully deleted.`));
    } catch (err) {
      dispatch(setModuleLoading());
      dispatch(addExceptionNotification(err, `Error while deleting module '${module.code}'!`));
    }
  };

  const deleteModuleExecutionAsync: (execution: IModuleExecutionDto) => AsyncThunk = execution => async (dispatch, getState) => {
    dispatch(setModuleExecutionLoading(execution));
    try {
      await moduleManagementClient.deleteModuleExecution(execution.id);
      await dispatch(refreshFiltersAsync());
      dispatch(moduleSelectionActions.resetExecutions());
      const academicYearId = getState().semesterSelection.selectedAcademicYear.id;
      await dispatch(moduleSelectionActions.getExecutionsForAcademicYearAsync(academicYearId));
      dispatch(setModuleExecutionLoading());
      dispatch(addSuccess(`Execution '${execution.code}' was successfully deleted.`));
    } catch (err) {
      dispatch(setModuleExecutionLoading());
      dispatch(addExceptionNotification(err, `Error while deleting execution '${execution.code}'!`));
    }
  };

  return Object.freeze({
    cancelAdding,
    addModuleCategory,
    saveModuleCategoryAsync,
    deleteModuleCategoryAsync,
    addModule,
    updateModuleAsync,
    insertModuleAsync,
    deleteModuleAsync,
    saveModuleExecutionsAsync,
    deleteModuleExecutionAsync,
    copyAcademicYearAsync,
  });
};

export default initActions;

export type ModuleManagementActions = ReturnType<typeof initActions>;
