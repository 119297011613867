import { Grid, LinearProgress, Paper, Typography } from '@mui/material';
import { ISemesterDto, IUasDto } from 'app/clients/services';
import { IRootState } from 'app/config/root.reducer';
import { ChipSelectionRow } from 'app/shared/layout/ui-elements/chip-selection';
import { FullSizeLayout } from 'app/shared/layout/ui-elements/component-layout';
import { Search } from 'app/shared/layout/ui-elements/search';
import React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { StudentPreview } from '../../shared/layout/parts/student-preview';
import searchModule from './search';
import studentsModule from './students';

export type Props = PropsFromRedux;

export const StudentSearch = (props: Props) => {
  const search = async (searchString: string, filterByUas: IUasDto[], filterBySemester: ISemesterDto[]) => {
    const result = await props.searchAsync(searchString, filterByUas, filterBySemester);
    if (result.length === 1) {
      await props.loadStudentAsync(result[0].id);
    }
  };

  const onSearchStringChange = (searchString: string) => {
    props.setSearchString(searchString);
  };

  const addSemesterFilter = async (semester: ISemesterDto) => {
    if (semester) {
      props.addSemesterFilter(semester);
      if (props.searchString?.length > 2) {
        await props.searchAsync(props.searchString, props.filterByUas, [...props.filterBySemester, semester]);
      }
    }
  };

  const removeSemesterFilter = async (semester: ISemesterDto) => {
    if (semester) {
      props.removeSemesterFilter(semester);
      if (props.searchString?.length > 2) {
        await props.searchAsync(
          props.searchString,
          props.filterByUas,
          props.filterBySemester.filter((u) => u.id !== semester.id),
        );
      }
    }
  };

  const addUasFilter = async (uas: IUasDto) => {
    if (uas) {
      props.addUasFilter(uas);
      if (props.searchString?.length > 2) {
        await props.searchAsync(props.searchString, [...props.filterByUas, uas], props.filterBySemester);
      }
    }
  };

  const removeUasFilter = async (uas: IUasDto) => {
    if (uas) {
      props.removeUasFilter(uas);
      if (props.searchString?.length > 2) {
        await props.searchAsync(
          props.searchString,
          props.filterByUas.filter((u) => u.id !== uas.id),
          props.filterBySemester,
        );
      }
    }
  };

  return (
    <FullSizeLayout>
      <Paper style={{ width: '100%', margin: 5, padding: 5 }}>
        <Search
          onSearch={() => search(props.searchString, props.filterByUas, props.filterBySemester)}
          onChange={onSearchStringChange}
          searchString={props.searchString}
        />
      </Paper>
      <Paper style={{ width: '100%', margin: 5, padding: 5 }}>
        <Grid container>
          {props.allowedUas.length > 1 && (
            <ChipSelectionRow
              onAdd={(u) => addUasFilter(u as unknown as IUasDto)}
              onDelete={(u) => removeUasFilter(u as unknown as IUasDto)}
              actionTitle="Filter by UAS"
              getItemKey={(item) => item.id}
              getItemTitle={(item) => item.code}
              items={props.allowedUas}
              selectedItems={props.filterByUas}
            />
          )}
          <ChipSelectionRow
            onAdd={(u) => addSemesterFilter(u as unknown as ISemesterDto)}
            onDelete={(u) => removeSemesterFilter(u as unknown as ISemesterDto)}
            actionTitle="Filter by Semester"
            getItemKey={(item) => item.id}
            getItemTitle={(item) => item.name}
            items={props.semesters}
            selectedItems={props.filterBySemester}
          />
        </Grid>
      </Paper>
      {props.searchString?.length > 2 && props.resultSearchString === props.searchString && (
        <div style={{ width: '100%', margin: 5, paddingTop: 5 }}>
          {props.loading && <LinearProgress></LinearProgress>}
          {props.students.length === 0 ? (
            <Paper sx={{ width: '100%', padding: 2 }}>
              <Typography variant="h3">No students found...</Typography>
            </Paper>
          ) : (
            <>
              <Paper sx={{ width: '100%', padding: 2 }}>
                <Typography variant="h3">Found {props.students.length} Students...</Typography>
              </Paper>

              {props.students.map((s, k) => (
                <Paper
                  key={k}
                  sx={{
                    width: '100%',
                    marginTop: 2,
                    padding: 2,
                    '&:hover': {
                      cursor: 'pointer',
                    },
                  }}
                  onClick={() => props.loadStudentAsync(s.id)}
                >
                  <StudentPreview student={s}></StudentPreview>
                </Paper>
              ))}
            </>
          )}
        </div>
      )}
    </FullSizeLayout>
  );
};

const mapStateToProps = ({ auth, studentSearch, semesterSelection }: IRootState) => ({
  loading: studentSearch.searchLoading,
  searchString: studentSearch.searchString,
  resultSearchString: studentSearch.resultSearchString,
  filterByUas: studentSearch.filterByUas,
  filterBySemester: studentSearch.filterBySemester,
  students: studentSearch.searchResult,
  allowedUas: auth.allowedUas,
  semesters: semesterSelection.semesterFilter?.semesters ?? [],
});

const mapDispatchToProps = (dispatch) => {
  const searchActions = searchModule.initActions(dispatch);
  const studentActions = studentsModule.initActions(dispatch);
  return {
    loadStudentAsync: studentActions.loadStudentAsync,
    searchAsync: searchActions.searchAsync,
    addUasFilter: searchActions.addUasFilter,
    removeUasFilter: searchActions.removeUasFilter,
    addSemesterFilter: searchActions.addSemesterFilter,
    removeSemesterFilter: searchActions.removeSemesterFilter,
    setSearchString: searchActions.setSearchString,
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(StudentSearch);
