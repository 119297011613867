import { IImportClient, RegistrationImport, RegistrationValidationDto } from 'app/clients/services';
import { AsyncThunk } from 'app/shared/util/action-type.util';
import ACTION_TYPES from './types';

const initActions = (importClient: IImportClient) => {
  // Actions
  const nextStep = () => ({
    type: ACTION_TYPES.NEXT_STEP,
  });

  const lastStep = () => ({
    type: ACTION_TYPES.LAST_STEP,
  });

  const reset = () => ({
    type: ACTION_TYPES.RESET,
  });

  const selectFile = (file: File) => ({
    type: ACTION_TYPES.SELECT_FILE,
    payload: file,
  });

  const resetFileSelection = () => ({
    type: ACTION_TYPES.SELECT_FILE,
    payload: null,
  });

  const selectUas = (id: number) => ({
    type: ACTION_TYPES.SELECT_UAS,
    payload: id,
  });

  const goToStep = (step: number) => ({
    type: ACTION_TYPES.GOTO_STEP,
    payload: step,
  });

  const reduceWarningCount = () => ({
    type: ACTION_TYPES.REDUCE_WARNING_COUNT,
  });

  const updateRegistrationImport = (registrationImport: RegistrationImport) => ({
    type: ACTION_TYPES.UPDATE_REGISTRATION_IMPORT,
    payload: registrationImport,
  });

  // Thunks
  const validateAsync: (currentStep: number, semesterId: number | null, file: File) => AsyncThunk<RegistrationValidationDto> =
    (currentStep, semesterId, file) => async (dispatch) => {
      const result = await dispatch({
        type: ACTION_TYPES.VALIDATE,
        payload: importClient.validate(semesterId, {
          data: file,
          fileName: file?.name,
        }),
      });

      const validationResult = result.value;
      if (validationResult.fileIsReadable) {
        if (currentStep === 1) {
          dispatch({
            type: ACTION_TYPES.NEXT_STEP,
          });
        }

        const partOfUasId = validationResult?.registrationImport?.uasId;

        if (partOfUasId) {
          dispatch({
            type: ACTION_TYPES.SELECT_UAS,
            payload: partOfUasId,
          });
        }
      }

      return result.value;
    };

  const revalidateAsync: (currentStep: number, registrationImport: RegistrationImport) => AsyncThunk<RegistrationValidationDto> =
    (currentStep, registrationImport) => async (dispatch) => {
      const result = await dispatch({
        type: ACTION_TYPES.VALIDATE_NORMALIZED,
        payload: importClient.revalidate(registrationImport),
      });

      const validationResult = result.value;
      if (validationResult) {
        if (currentStep === 1) {
          dispatch({
            type: ACTION_TYPES.NEXT_STEP,
          });
        }

        const partOfUasId = validationResult?.registrationImport?.uasId;
        if (partOfUasId) {
          dispatch({
            type: ACTION_TYPES.SELECT_UAS,
            payload: partOfUasId,
          });
        }
      }

      return result.value;
    };

  const submitAsync: (registrationImport: RegistrationImport, file: File) => AsyncThunk<RegistrationValidationDto> = (input, file) => async (dispatch) => {
    const result = await dispatch({
      type: ACTION_TYPES.SUBMIT,
      payload: importClient.submit(input),
    });

    const validationResult = result.value;
    if (validationResult.registrationImport?.importDbId > 0) {
      await dispatch({
        type: ACTION_TYPES.ADD_FILE,
        payload: importClient.addFile(validationResult.registrationImport.importDbId, {
          data: file,
          fileName: file?.name,
        }),
      });

      dispatch({
        type: ACTION_TYPES.NEXT_STEP,
      });
    }

    return result.value;
  };

  return Object.freeze({
    nextStep,
    lastStep,
    reset,
    selectFile,
    resetFileSelection,
    selectUas,
    goToStep,
    reduceWarningCount,
    updateRegistrationImport,
    validateAsync,
    revalidateAsync,
    submitAsync,
  });
};

export default initActions;

export type RegistrationImportsActions = ReturnType<typeof initActions>;
