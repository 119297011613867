export default {
  GET_IMPORTS: 'registration-import/imports/GET_IMPORTS',
  DOWNLOAD: 'registration-import/imports/DOWNLOAD',
  GET_VALIDATION: 'registration-import/imports/GET_VALIDATION',
  SELECT_IMPORT: 'registration-import/imports/SELECT_IMPORT',
  SELECT_PHASE: 'registration-import/imports/SELECT_PHASE',
  SET_MAIN_CONTENT_LOADING: 'registration-import/imports/SET_MAIN_CONTENT_LOADING',
  CHANGE_STATE: 'registration-import/imports/CHANGE_STATE',

  LOAD_MODULE_REGISTRATION: 'registration-import/LOAD_MODULE_REGISTRATION',
  CLEAR_MODULE_REGISTRATION: 'registration-import/CLEAR_MODULE_REGISTRATION',
};
