import ACTION_TYPES, { NotificationLevel } from './types';
import { CloseReason } from 'notistack';
import { Thunk } from 'app/shared/util/action-type.util';
import * as Sentry from '@sentry/browser';

export const removeNotification = (key: string) => ({
  type: ACTION_TYPES.REMOVE,
  payload: key,
});

export const closeNotification = (key: string) => ({
  type: ACTION_TYPES.CLOSE,
  payload: key,
});

export const closeAllNotifications = () => ({
  type: ACTION_TYPES.CLOSE_ALL,
});

export const clearNotifications = () => ({
  type: ACTION_TYPES.CLEAR,
});

export const addNotification = (
  message: string,
  level: NotificationLevel,
  onClose?: (event: React.SyntheticEvent<any, Event>, reason: CloseReason) => void
) => ({
  type: ACTION_TYPES.ADD,
  payload: {
    level: 'error',
    message,
    options: {
      onClose,
      variant: level,
      key: new Date().getTime() + Math.random(),
    },
  },
});

export const addExceptionNotification: (
  e: any,
  message?: string,
  onClose?: (event: React.SyntheticEvent<any, Event>, reason: CloseReason) => void
) => Thunk = (e, message, onClose) => (dispatch, getState) => {
  if (e.type === 'MessageException') {
    if (!getState().notification.notifications.find(n => n.options?.key === e.id)) {
      dispatch({
        type: ACTION_TYPES.ADD,
        payload: {
          level: e.level,
          message: e.message,
          options: {
            onClose,
            variant: e.level,
            key: e.id,
          },
        },
      });
    }
    return;
  }

  if (e.type === 'IdentifiableApiException') {
    dispatch({
      type: ACTION_TYPES.ADD,
      payload: {
        level: e.level ?? 'error',
        message: message ?? e.message,
        options: {
          onClose,
          variant: e.level ?? 'error',
          key: e.id ?? new Date().getTime() + Math.random(),
        },
      },
    });
    return;
  }

  if (process.env.NODE_ENV === 'development') {
    console.error(e);
  } else {
    Sentry.captureException(e);
  }
};

export const addError = (message: string, onClose?: (event: React.SyntheticEvent<any, Event>, reason: CloseReason) => void) =>
  addNotification(message, 'error', onClose);

export const addInfo = (message: string, onClose?: (event: React.SyntheticEvent<any, Event>, reason: CloseReason) => void) =>
  addNotification(message, 'info', onClose);

export const addWarning = (message: string, onClose?: (event: React.SyntheticEvent<any, Event>, reason: CloseReason) => void) =>
  addNotification(message, 'warning', onClose);

export const addSuccess = (message: string, onClose?: (event: React.SyntheticEvent<any, Event>, reason: CloseReason) => void) =>
  addNotification(message, 'success', onClose);
