import { Box, Grid, LinearProgress, Paper } from '@mui/material';
import ErrorBoundary from 'app/shared/error/error-boundary';
import React from 'react';

export type SideBarProps = {
  children: React.ReactNode;
  loading?: boolean;
};

export const SideBar = (props: SideBarProps) => {
  if (props.loading) {
    return (
      <Grid
        item
        xs={12}
        md={2}
        sx={{
          flexGrow: 1,
          borderRight: '1px solid lightgrey',
          backgroundColor: '#FAFAFA',
        }}
      >
        <LinearProgress />
      </Grid>
    );
  }

  return (
    <Grid
      item
      xs={12}
      md={2}
      sx={{
        flexGrow: 1,
        borderRight: '1px solid lightgrey',
        backgroundColor: '#FAFAFA',
      }}
    >
      {props.children}
    </Grid>
  );
};

export type LayoutProps = {
  children: JSX.Element | JSX.Element[];
  loading?: boolean;
};

export const MainContent = (props: LayoutProps) => {
  return (
    <Grid
      item
      xs={12}
      md={10}
      sx={{
        padding: 1,
        overflow: 'auto',
        minHeight: 'calc(100vh - 190px)',
      }}
    >
      {props.loading ? <LinearProgress /> : <>{props.children ? props.children : <></>}</>}
    </Grid>
  );
};

export const SplitLayout = (props: { loading?: boolean; children }) => {
  if (props.loading) {
    return <LinearProgress></LinearProgress>;
  }

  return (
    <Grid
      container
      sx={{
        flexGrow: 1,
        borderBottom: '1px solid lightgrey',
      }}
    >
      <ErrorBoundary>{props.children}</ErrorBoundary>
    </Grid>
  );
};

export const FullSizeLayout = (props: LayoutProps) => {
  return (
    <Box sx={{ padding: 1 }}>
      {props.loading ? <LinearProgress></LinearProgress> : <ErrorBoundary>{props.children}</ErrorBoundary>}
    </Box>
  );
};

export const TextContent = (props: LayoutProps) => {
  return (
    <Grid
      container
      spacing={2}
      sx={{
        flexGrow: 1,
        borderBottom: '1px solid lightgrey',
        padding: 1,
      }}
    >
      {props.loading ? (
        <Grid item xs={12}>
          <LinearProgress></LinearProgress>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <ErrorBoundary>
            <Paper sx={{ margin: 1, padding: 2 }}>{props.children}</Paper>
          </ErrorBoundary>
        </Grid>
      )}
    </Grid>
  );
};
