import { IValueChangeOfString } from 'app/clients/services';
import React from 'react';
import { MappingInfoIcon } from './mapping-info-icon';
import { MappingConfirmation } from './types';
import { StringValueChange } from './value-change';

type DbMappingProps = {
  dbMapping: MappingConfirmation;
  entity: string;
  showIconOnChangesOnly?: boolean;
  showIconOnly?: boolean;
};

export const DbMapping = (props: DbMappingProps) => {
  if (!props.dbMapping) {
    return <></>;
  }

  if (props.showIconOnly) {
    return <MappingInfoIcon dbMapping={props.dbMapping} entity={props.entity} />;
  }

  if (props.dbMapping.mappingChanged) {
    if (props.dbMapping.oldIdentifier) {
      const message = `${props.entity} changes from '${props.dbMapping.oldIdentifier}' to '${props.dbMapping.identifier}'.`;
      return (
        <>
          <StringValueChange
            value={props.dbMapping.identifier}
            valueChange={
              {
                newValue: props.dbMapping.identifier,
                oldValue: props.dbMapping.oldIdentifier,
                validationLevel: props.dbMapping.validationLevel,
                conflictMessage: message,
              } as IValueChangeOfString
            }
          />
          <MappingInfoIcon dbMapping={props.dbMapping} entity={props.entity} />
        </>
      );
    }

    return (
      <>
        {props.dbMapping.identifier && <span>{props.dbMapping.identifier}</span>}
        <MappingInfoIcon dbMapping={props.dbMapping} entity={props.entity} />
      </>
    );
  }

  if (props.showIconOnChangesOnly) {
    if (props.dbMapping.identifier) {
      return <span>{props.dbMapping.identifier}</span>;
    }
    return <></>;
  }

  return (
    <>
      {props.dbMapping.identifier && <span>{props.dbMapping.identifier}</span>}
      <MappingInfoIcon dbMapping={props.dbMapping} entity={props.entity} />
    </>
  );
};
