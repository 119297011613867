import { PriorityDto, ProfileDto } from 'app/clients/services';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/util/action-type.util';
import { updateItem } from 'app/shared/util/immutable.util';
import { PriorityAssignmentDto } from '../../../clients/services';
import ACTION_TYPES from './types';

// State
const initialState = {
  loading: false,
  errorMessage: null as string,
  selectedProfile: null as ProfileDto,
  priorityAssignments: [] as PriorityAssignmentDto[],
  selectedPriority: null as PriorityDto,
};

export type ProfileManagementState = Readonly<typeof initialState>;

// Reducer
export default (state: ProfileManagementState = initialState, action): ProfileManagementState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_PRIORITY_ASSIGNMENTS):
      return {
        ...state,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_PRIORITY_ASSIGNMENTS):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_PRIORITY_ASSIGNMENTS): {
      return {
        ...state,
        loading: false,
        priorityAssignments: action.payload,
      };
    }
    case REQUEST(ACTION_TYPES.ASSIGN_PRIORITY):
      return {
        ...state,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.ASSIGN_PRIORITY):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.ASSIGN_PRIORITY): {
      return {
        ...state,
        loading: false,
      };
    }
    case REQUEST(ACTION_TYPES.REMOVE_ALL_PRIORITIES):
      return {
        ...state,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.REMOVE_ALL_PRIORITIES):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.REMOVE_ALL_PRIORITIES): {
      return {
        ...state,
        loading: false,
      };
    }
    case REQUEST(ACTION_TYPES.COPY_FROM_SEMESTER):
      return {
        ...state,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.COPY_FROM_SEMESTER):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.COPY_FROM_SEMESTER): {
      return {
        ...state,
        loading: false,
      };
    }
    case ACTION_TYPES.CREATE_PROFILE: {
      return {
        ...state,
        priorityAssignments: [],
        selectedProfile: action.payload,
      };
    }
    case ACTION_TYPES.CHANGE_PRIORITYASSIGNMENT: {
      const priorityAssignments = updateItem(state.priorityAssignments, action.payload);
      return {
        ...state,
        priorityAssignments,
      };
    }
    case ACTION_TYPES.SELECT_PROFILE: {
      return {
        ...state,
        selectedProfile: action.payload,
      };
    }
    default:
      return state;
  }
};
