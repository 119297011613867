import { IAcademicYearDto, ISemesterInfoDto } from "app/clients/services";
import ACTION_TYPES from "./types";

// State
const initialState = {
  loading: false,
  errorMessage: null as string,
  selectedAcademicYear: null as IAcademicYearDto,
  newSemester: null as ISemesterInfoDto,
  semesterLoading: -1,
  phaseLoading: -1,
};

export type SemesterManagementState = Readonly<typeof initialState>;

// Reducer
export default (state: SemesterManagementState = initialState, action): SemesterManagementState => {
  switch (action.type) {
    case ACTION_TYPES.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case ACTION_TYPES.SET_SEMESTER_LOADING:
      return {
        ...state,
        semesterLoading: action.payload,
        newSemester: null,
      };
    case ACTION_TYPES.SET_PHASE_LOADING:
      return {
        ...state,
        phaseLoading: action.payload,
      };
    case ACTION_TYPES.SELECT_ACADEMIC_YEAR:
      return {
        ...state,
        selectedAcademicYear: action.payload,
        newSemester: null,
      };
    case ACTION_TYPES.FILTER_REFRESHED:
      return {
        ...state,
        loading: false,
        selectedAcademicYear: state.selectedAcademicYear.id ? action.payload?.academicYears?.find((a) => a.id === state.selectedAcademicYear.id) : null,
      };
    case ACTION_TYPES.ADD_ACADEMIC_YEAR: {
      return {
        ...state,
        selectedAcademicYear: action.payload,
      };
    }

    case ACTION_TYPES.ADD_SEMESTER:
      return {
        ...state,
        newSemester: action.payload,
      };
    default:
      return state;
  }
};
