import React, { useEffect } from 'react';
import { IRootState } from 'app/config/root.reducer';
import { connect, ConnectedProps } from 'react-redux';
import { ITabModel, NavigationTabs } from 'app/shared/layout/ui-elements/navigation-tabs';
import { RoleType } from 'app/clients/services';
import { hasAnyRole } from 'app/shared/auth/roles';
import { rememberOpenTab } from '../application/actions';

export type Props = PropsFromRedux & { baseUrl: string };

const ModuleManagementTabs = (props: Props) => {
  useEffect(() => {}, []);

  const tabs = [];

  if (props.isModuleAdmin) {
    tabs.push({
      label: 'Modules',
      url: 'modules',
      isDefault: true,
    });
  }

  return (
    <NavigationTabs
      tabs={tabs}
      baseUrl={props.baseUrl}
      openTab={props.openTabs[props.baseUrl]}
      onChange={(tab: ITabModel) => props.rememberOpenTab(props.baseUrl, tab)}
    />
  );
};

function mapStateToProps({ auth, application }: IRootState) {
  return {
    isModuleAdmin: hasAnyRole(auth.account?.roles, [RoleType.MODULEADMIN]),
    openTabs: application.openTabs,
  };
}

const mapDispatchToProps = dispatch => ({
  rememberOpenTab: (baseUrl: string, tab: ITabModel) => dispatch(rememberOpenTab(baseUrl, tab)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ModuleManagementTabs);
