/* eslint-disable complexity */
import {
  IAdvisorDto,
  ICommentDto,
  IExcelColumn,
  IFilterPresetDto,
  IRegistrationFilterSelectionDto,
  ISelectionOfLong,
  IStaticRegistrationFilterDto,
  IStudentPreviewDto,
  RegistrationFilterSelectionDto,
  RegistrationState,
} from 'app/clients/services';
import { addCommentToRegistrationInfo, removeCommentFromRegistrationInfo } from 'app/shared/util/reducer-helpers';
import { IModuleRegistrationInfoDto } from '../../../clients/services';
import ACTION_TYPES from './types';

const initialState = {
  loading: false,
  staticFiltersLoading: false,
  moduleFiltersLoading: false,
  fileImportFiltersLoading: false,
  presetsLoading: false,
  previewLoading: false,
  presets: [] as IFilterPresetDto[],
  selectedPreset: null as IFilterPresetDto,
  filterSelection: new RegistrationFilterSelectionDto() as IRegistrationFilterSelectionDto,
  selectableColumns: [] as IExcelColumn[],
  selectableProfiles: [] as ISelectionOfLong[],
  selectableLabels: [] as ISelectionOfLong[],
  selectableLocations: [] as ISelectionOfLong[],
  selectableStates: [] as RegistrationState[],
  selectableFileImports: [] as ISelectionOfLong[],
  selectableModuleExecutionCodes: [] as string[],
  preview: [] as IModuleRegistrationInfoDto[],
  previewLoaded: false,
  studentSearchLoading: false,
  advisorSearchLoading: false,
  studentSearchResult: [] as IStudentPreviewDto[],
  studentSearchLoaded: false,
  advisorSearchResult: [] as IAdvisorDto[],
  advisorSearchLoaded: false,
};

export type ModuleRegistrationExportState = Readonly<typeof initialState>;

// Reducer
export default (state: ModuleRegistrationExportState = initialState, action): ModuleRegistrationExportState => {
  switch (action.type) {
    case ACTION_TYPES.SET_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case ACTION_TYPES.SET_STATIC_FILTERS_LOADING: {
      return {
        ...state,
        staticFiltersLoading: action.payload,
      };
    }
    case ACTION_TYPES.SET_MODULE_FILTERS_LOADING: {
      return {
        ...state,
        moduleFiltersLoading: action.payload,
      };
    }
    case ACTION_TYPES.SET_FILEIMPORT_FILTERS_LOADING: {
      return {
        ...state,
        fileImportFiltersLoading: action.payload,
      };
    }
    case ACTION_TYPES.SET_PRESETS_LOADING: {
      return {
        ...state,
        presetsLoading: action.payload,
      };
    }
    case ACTION_TYPES.SET_PRESETS: {
      return {
        ...state,
        presets: action.payload,
      };
    }
    case ACTION_TYPES.SET_PRESET: {
      const preset = action.payload as IFilterPresetDto;
      if (!preset) {
        return {
          ...state,
          filterSelection: { ...state.filterSelection, presetId: null },
          selectedPreset: null,
          previewLoaded: false,
        };
      }
      return {
        ...state,
        selectedPreset: preset,
        filterSelection: { ...preset.filter, presetId: preset.id },
        previewLoaded: false,
      };
    }
    case ACTION_TYPES.SET_STATIC_FILTERS: {
      const filters = action.payload as IStaticRegistrationFilterDto;
      const columns = state.filterSelection?.columnNames?.length
        ? [...state.filterSelection.columnNames]
        : filters.defaultColumns.map((c) => c.propertyName);
      return {
        ...state,
        selectableColumns: filters.columns,
        selectableProfiles: filters.profiles,
        selectableStates: filters.states,
        selectableLocations: filters.locations,
        selectableLabels: filters.labels,
        filterSelection: {
          ...state.filterSelection,
          columnNames: columns,
        },
      };
    }
    case ACTION_TYPES.SET_MODULE_FILTERS: {
      const filters = action.payload as string[];
      return {
        ...state,
        selectableModuleExecutionCodes: filters,
      };
    }
    case ACTION_TYPES.SET_FILEIMPORT_FILTERS: {
      const filters = action.payload as ISelectionOfLong[];
      return {
        ...state,
        selectableFileImports: filters,
      };
    }
    case ACTION_TYPES.SET_FILTER_SELECTION: {
      const filterSelection = action.payload as IRegistrationFilterSelectionDto;
      const columns = filterSelection?.columnNames?.length
        ? [...filterSelection.columnNames]
        : state.selectableColumns?.map((c) => c.propertyName) ?? [];

      return {
        ...state,
        filterSelection: {
          ...action.payload,
          columnNames: columns,
        },
      };
    }
    case ACTION_TYPES.ADD_UAS_FILTER: {
      return {
        ...state,
        filterSelection: {
          ...state.filterSelection,
          uasIds: [...(state.filterSelection.uasIds ?? []), action.payload.id],
        },
        previewLoaded: false,
      };
    }
    case ACTION_TYPES.REMOVE_UAS_FILTER: {
      return {
        ...state,
        filterSelection: {
          ...state.filterSelection,
          uasIds: state.filterSelection.uasIds?.filter((id) => id !== action.payload.id) ?? [],
        },
        previewLoaded: false,
      };
    }
    case ACTION_TYPES.ADD_SEMESTER_FILTER: {
      return {
        ...state,
        filterSelection: {
          ...state.filterSelection,
          semesterIds: [...(state.filterSelection.semesterIds ?? []), action.payload.id],
          phaseId: undefined,
        },
        previewLoaded: false,
      };
    }
    case ACTION_TYPES.REMOVE_SEMESTER_FILTER: {
      return {
        ...state,
        filterSelection: {
          ...state.filterSelection,
          semesterIds: state.filterSelection.semesterIds?.filter((id) => id !== action.payload.id) ?? [],
        },
        previewLoaded: false,
      };
    }
    case ACTION_TYPES.ADD_PHASE_FILTER: {
      return {
        ...state,
        filterSelection: {
          ...state.filterSelection,
          phaseId: action.payload ? action.payload : undefined,
        },
        previewLoaded: false,
      };
    }
    case ACTION_TYPES.ADD_STATE_FILTER: {
      return {
        ...state,
        filterSelection: {
          ...state.filterSelection,
          states: [...(state.filterSelection.states ?? []), action.payload],
        },
        previewLoaded: false,
      };
    }
    case ACTION_TYPES.REMOVE_STATE_FILTER: {
      return {
        ...state,
        filterSelection: {
          ...state.filterSelection,
          states: state.filterSelection.states?.filter((id) => id !== action.payload) ?? [],
        },
        previewLoaded: false,
      };
    }
    case ACTION_TYPES.ADD_COLUMN: {
      return {
        ...state,
        filterSelection: {
          ...state.filterSelection,
          columnNames: [...(state.filterSelection.columnNames ?? []), action.payload.propertyName],
        },
      };
    }
    case ACTION_TYPES.REMOVE_COLUMN: {
      const newColumns = state.filterSelection.columnNames?.filter((id) => id !== action.payload.propertyName) ?? [];
      const columns = newColumns?.length > 0 ? newColumns : state.selectableColumns?.map((c) => c.propertyName) ?? [];
      return {
        ...state,
        filterSelection: {
          ...state.filterSelection,
          columnNames: columns,
        },
      };
    }
    case ACTION_TYPES.ADD_LABEL_FILTER: {
      return {
        ...state,
        filterSelection: {
          ...state.filterSelection,
          labelIds: [...(state.filterSelection.labelIds ?? []), action.payload.id],
        },
        previewLoaded: false,
      };
    }
    case ACTION_TYPES.REMOVE_LABEL_FILTER: {
      return {
        ...state,
        filterSelection: {
          ...state.filterSelection,
          labelIds: state.filterSelection.labelIds?.filter((id) => id !== action.payload.id) ?? [],
        },
        previewLoaded: false,
      };
    }
    case ACTION_TYPES.ADD_PROFILE_FILTER: {
      return {
        ...state,
        filterSelection: {
          ...state.filterSelection,
          profileIds: [...(state.filterSelection.profileIds ?? []), action.payload.id],
        },
        previewLoaded: false,
      };
    }
    case ACTION_TYPES.REMOVE_PROFILE_FILTER: {
      return {
        ...state,
        filterSelection: {
          ...state.filterSelection,
          profileIds: state.filterSelection.profileIds?.filter((id) => id !== action.payload.id) ?? [],
        },
        previewLoaded: false,
      };
    }
    case ACTION_TYPES.ADD_LOCATION_FILTER: {
      return {
        ...state,
        filterSelection: {
          ...state.filterSelection,
          locationIds: [...(state.filterSelection.locationIds ?? []), action.payload.id],
        },
        previewLoaded: false,
      };
    }
    case ACTION_TYPES.REMOVE_LOCATION_FILTER: {
      return {
        ...state,
        filterSelection: {
          ...state.filterSelection,
          locationIds: state.filterSelection.locationIds?.filter((id) => id !== action.payload.id) ?? [],
        },
        previewLoaded: false,
      };
    }
    case ACTION_TYPES.ADD_STUDENT_TYPE_FILTER: {
      return {
        ...state,
        filterSelection: {
          ...state.filterSelection,
          studentTypes: [...(state.filterSelection.studentTypes ?? []), action.payload],
        },
        previewLoaded: false,
      };
    }
    case ACTION_TYPES.REMOVE_STUDENT_TYPE_FILTER: {
      return {
        ...state,
        filterSelection: {
          ...state.filterSelection,
          studentTypes:
            state.filterSelection.studentTypes?.filter((studentType) => studentType !== action.payload) ?? [],
        },
        previewLoaded: false,
      };
    }
    case ACTION_TYPES.SET_STUDENT: {
      return {
        ...state,
        filterSelection: {
          ...state.filterSelection,
          student: action.payload,
          advisor: null,
          moduleCategoryId: 0,
          moduleId: 0,
          moduleExecutionCode: null,
          locationIds: [],
        },
        previewLoaded: false,
        studentSearchLoaded: false,
        studentSearchResult: [],
      };
    }
    case ACTION_TYPES.SET_ADVISOR: {
      return {
        ...state,
        filterSelection: {
          ...state.filterSelection,
          advisor: action.payload,
          student: null,
          moduleCategoryId: 0,
          moduleId: 0,
          moduleExecutionCode: null,
          locationIds: [],
        },
        previewLoaded: false,
        advisorSearchLoaded: false,
        advisorSearchResult: [],
      };
    }
    case ACTION_TYPES.SET_MODULE_CATEGORY: {
      return {
        ...state,
        filterSelection: {
          ...state.filterSelection,
          moduleCategoryId: action.payload?.id ?? 0,
          moduleId: 0,
          student: null,
          advisor: null,
        },
        previewLoaded: false,
      };
    }
    case ACTION_TYPES.SET_MODULE: {
      return {
        ...state,
        filterSelection: {
          ...state.filterSelection,
          moduleId: action.payload?.id ?? 0,
          moduleCategoryId: action.payload ? action.payload.categoryId : state.filterSelection.moduleCategoryId,
          student: null,
          advisor: null,
        },
        previewLoaded: false,
      };
    }
    case ACTION_TYPES.SET_MODULE_EXECUTION_CODE: {
      return {
        ...state,
        filterSelection: {
          ...state.filterSelection,
          moduleExecutionCode: action.payload ?? '',
          student: null,
          advisor: null,
        },
        previewLoaded: false,
      };
    }
    case ACTION_TYPES.SET_FILEIMPORT: {
      return {
        ...state,
        filterSelection: {
          ...state.filterSelection,
          fileImport: action.payload,
        },
        previewLoaded: false,
      };
    }
    case ACTION_TYPES.SET_STATE_AT: {
      return {
        ...state,
        filterSelection: {
          ...state.filterSelection,
          stateAt: action.payload,
        },
        previewLoaded: false,
      };
    }
    case ACTION_TYPES.SET_NEWER_THEN: {
      return {
        ...state,
        filterSelection: {
          ...state.filterSelection,
          newerThen: action.payload,
        },
        previewLoaded: false,
      };
    }
    case ACTION_TYPES.SET_HIDE_REGISTRATIONS: {
      return {
        ...state,
        filterSelection: {
          ...state.filterSelection,
          hideRegistrations: action.payload,
        },
        previewLoaded: false,
      };
    }
    case ACTION_TYPES.SET_SHOW_DEREGISTRATIONS: {
      return {
        ...state,
        filterSelection: {
          ...state.filterSelection,
          hideRegistrations: action.payload,
        },
        previewLoaded: false,
      };
    }
    case ACTION_TYPES.SET_HAS_COMMENT: {
      return {
        ...state,
        filterSelection: {
          ...state.filterSelection,
          hasComment: action.payload,
        },
        previewLoaded: false,
      };
    }
    case ACTION_TYPES.SET_HAS_INTERNAL_COMMENT: {
      return {
        ...state,
        filterSelection: {
          ...state.filterSelection,
          hasInternalComment: action.payload,
        },
        previewLoaded: false,
      };
    }
    case ACTION_TYPES.SET_HAS_COMPENSATION_FOR_DISADVANTAGES: {
      return {
        ...state,
        filterSelection: {
          ...state.filterSelection,
          hasCompensationForDisadvantages: action.payload,
        },
        previewLoaded: false,
      };
    }
    case ACTION_TYPES.SET_HAS_NO_UAS_EMAIL: {
      return {
        ...state,
        filterSelection: {
          ...state.filterSelection,
          hasNoUasEmail: action.payload,
        },
        previewLoaded: false,
      };
    }
    case ACTION_TYPES.SET_PREVIEW: {
      return {
        ...state,
        preview: action.payload,
        previewLoaded: true,
      };
    }
    case ACTION_TYPES.SET_PREVIEW_LOADING: {
      return {
        ...state,
        previewLoading: action.payload,
      };
    }
    case ACTION_TYPES.SET_STUDENT_SEARCH_LOADING: {
      return {
        ...state,
        studentSearchLoading: action.payload,
      };
    }
    case ACTION_TYPES.SET_STUDENT_SEARCH_RESULT: {
      return {
        ...state,
        studentSearchResult: action.payload,
        studentSearchLoaded: true,
      };
    }
    case ACTION_TYPES.CHANGE_STUDENT_SEARCH_STRING: {
      return {
        ...state,
        studentSearchResult: [],
        studentSearchLoaded: false,
      };
    }
    case ACTION_TYPES.SET_ADVISOR_SEARCH_LOADING: {
      return {
        ...state,
        advisorSearchLoading: action.payload,
      };
    }
    case ACTION_TYPES.SET_ADVISOR_SEARCH_RESULT: {
      return {
        ...state,
        advisorSearchResult: action.payload,
        advisorSearchLoaded: true,
      };
    }
    case ACTION_TYPES.CHANGE_ADVISOR_SEARCH_STRING: {
      return {
        ...state,
        advisorSearchResult: [],
        advisorSearchLoaded: false,
      };
    }
    case ACTION_TYPES.ADD_REGISTRATION_COMMENT: {
      const comment = action.payload as ICommentDto;

      return {
        ...state,
        preview: addCommentToRegistrationInfo(state.preview, comment),
      };
    }
    case ACTION_TYPES.REMOVE_REGISTRATION_COMMENT: {
      const comment = action.payload as ICommentDto;

      return {
        ...state,
        preview: removeCommentFromRegistrationInfo(state.preview, comment),
      };
    }
    case ACTION_TYPES.RESET: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};
