import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Search from './search-component';
import Student from './student';
import StudentManagementTabs from './student-management-tabs';

const StudentManagementRoutes = ({ baseUrl }) => {
  return (
    <>
      <StudentManagementTabs baseUrl={baseUrl} />
      <div style={{ marginTop: 5 }}>
        <Routes>
          <Route index element={<Search />} />
          <Route path={`search`} element={<Search />} />
          <Route path={`/student/:id`} element={<Student />} />
        </Routes>
      </div>
    </>
  );
};

export default StudentManagementRoutes;
