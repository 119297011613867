import initActions from './actions';
import reducer from './reducer';
import types from './types';

import { resolver } from 'app/clients/service-registry';
import { IAccountClient, IAuthClient } from 'app/clients/services';

export type { AuthState } from './reducer';
export default {
  initActions: () =>
    initActions(resolver.resolve<IAccountClient>('IAccountClient'), resolver.resolve<IAuthClient>('IAuthClient')),
  types,
  reducer,
};
